import React, { useCallback, useMemo } from 'react';
import { HomeQueryData } from '@data';
import { Widget, ComplexRow } from '@uikit';
import { getCarrierAsset, capitalize, lowercase, safeFormatDate } from '@util';
import { navigate } from '@navigate';
import { sortPolicies } from '@app/partial/policies/PoliciesList';
import { Stack } from '@app/layouts';
import { Route } from '@app/types';

interface HealthWidgetProps {
  data?: HomeQueryData;
}

const filterPolicies = (policies) => {
  const filtered = (policies || []).filter((policy) => /ACTIVE|PENDING/.test(policy?.policyStatus));
  return sortPolicies({ policies: [...filtered] });
};

const shouldDisplay = (data?: HomeQueryData): boolean => {
  return filterPolicies(data?.viewerTwo?.health?.policies)?.length > 0;
};

const HealthWidget = ({ data }: HealthWidgetProps) => {
  const policies = useMemo(
    () => filterPolicies(data?.viewerTwo?.health?.policies),
    [data?.viewerTwo?.health?.policies],
  );

  const statusText = useCallback((policy) => {
    const today = safeFormatDate(new Date(), 'yyyy-MM-dd'); // e.g. 2022-12-01

    if (policy.startDate > today) {
      return `Starts ${safeFormatDate(policy.startDate, 'MMM d, yyyy')}`;
    }

    if (policy.policyStatus === 'ACTIVE_PENDING_PAYMENT') return 'Payment pending';

    const status = policy?.policyStatus?.split('_').join(' ');
    return capitalize(status);
  }, []);

  if (!shouldDisplay(data)) {
    return null;
  }

  return (
    <Widget
      title="Health & Dental"
      isListBased
      subtitleValue={policies?.length || 0}
      subtitleSuffix="policies"
      onPress={() => navigate(Route.COVERAGE)}
    >
      <Stack topSpace>
        {policies?.map((policy) => (
          <ComplexRow
            key={policy.id}
            asset={getCarrierAsset(policy.carrier, policy.productType, 'md')}
            label={policy?.shorthand}
            sublabel={statusText(policy)}
            onPress={() =>
              navigate(Route.COVERAGE_DETAIL, {
                type: lowercase(policy?.productType?.replace('PLANTYPE_', '')),
                policyID: policy?.id,
                enrollId: policy?.enrollmentID,
              })
            }
          />
        ))}
      </Stack>
    </Widget>
  );
};

HealthWidget.shouldDisplay = shouldDisplay;
export default HealthWidget;
