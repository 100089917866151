import { isBefore } from 'date-fns';
import { optionPresets } from '@app/forms';
import {
  toDate,
  sixtyFiveYearsAgo,
  eighteenYearsAgo,
  getFullName,
  todayISO,
  twelveYearsAgo,
  lastFour,
} from '@app/utils';
import { Gender, SexAssignedAtBirth, SexualOrientation } from '@app/types';

const isValidDate = (date) => {
  const d = new Date(date);
  return /^\d{4}-\d{2}-\d{2}$/.test(date) && !isNaN(d.getTime());
};

const isOlderThan12 = (dob) => isBefore(toDate(dob), twelveYearsAgo());

const copyGA = (id, isGA) => ({ id: `${id}${isGA ? '_GA' : ''}` });

export const fields = [
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'name',
    type: 'legalName',
    required: true,
    label: { id: '4_Q' },
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      nameSuffix: true,
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'dob',
    type: 'date',
    dob: true,
    required: true,
    label: { id: '33_L' },
    format: 'YYYY-MM-DD',
    dependencies: ['dob', 'name'],
    fieldAddition: ({ dob, name }) => {
      if (isValidDate(dob) && isBefore(toDate(dob), sixtyFiveYearsAgo())) {
        return { component: 'APPLICANT_AGE_WARNING', props: name };
      }
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isSmoker',
    type: 'radio',
    required: true,
    dependencies: ['relation', 'name', 'dob', 'isRequestingCoverage'],
    // show if valid dob and more than 12 years old
    display: ({ isRequestingCoverage, dob }) =>
      isRequestingCoverage === 'YES' &&
      isValidDate(dob) &&
      isBefore(toDate(dob), eighteenYearsAgo()),
    options: optionPresets.YES_NO_TRINARY,
    label: (values) => ({
      id: '145_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
    help: { id: '145_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'lastTobaccoUseDate',
    type: 'date',
    format: 'YYYY-MM-DD',
    required: true,
    dependencies: ['isSmoker'],
    display: (values) => values.isSmoker === 'YES',
    label: { id: '146_Q' },
    help: { id: '146_T' },
    before: { date: todayISO(), message: 'Must be before today' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'filerRelation',
    type: 'dropdown',
    required: true,
    dependencies: ['relation', 'filerName'],
    display: (values) => values.relation === 'CHILD',
    label: (values) => ({
      id: '28_Q',
      data: { fullName: values.filerName },
    }),
    options: optionPresets.CHILD_RELATION,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'spouseRelation',
    type: 'dropdown',
    required: true,
    dependencies: ['relation', 'spouseName'],
    display: (values) => values.relation === 'CHILD' && !!values.spouseName,
    label: (values) => ({ id: '28_Q', data: { fullName: values.spouseName } }),
    options: optionPresets.CHILD_RELATION,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'sex',
    type: 'dropdown',
    required: true,
    label: { id: '31_Q' },
    options: optionPresets.SEX,
    help: { id: '31_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'ssn',
    type: 'ssn',
    dependencies: ['isRequestingCoverage', 'relation', 'name', 'id', 'SSNs'],
    optional: (values) => values.isRequestingCoverage === 'NO',
    label: (values) => ({ id: values.isRequestingCoverage === 'YES' ? '32_L' : '34_Q' }),
    help: (values) => ({
      id: values.isRequestingCoverage === 'YES' ? '32_T' : '34_T',
      data: { name: getFullName(values.name, 'this person') },
    }),
    doesNotExistIn: ({ SSNs, id }) => ({
      array: SSNs.filter((member) => !!member.ssn && member.id !== id).map((member) => member.ssn),
      mask: (value) => `***-**-${lastFour(value)}`,
      message: 'This SSN matches another member on the application',
    }),
    editable: true,
  },
  {
    testID: '32_SKIP',
    copy: 'catch.ede.HealthEnrollment',
    name: 'confirmMissingSSN',
    type: 'checkbox',
    dependencies: ['isRequestingCoverage', 'ssn', 'name'],
    display: ({ ssn, isRequestingCoverage }) => !ssn && isRequestingCoverage === 'YES',
    label: (values) => ({
      id: '32_CONFIRM',
      data: { fullName: getFullName(values?.name, 'this person') },
    }),
    required: ({ ssn }) => !ssn,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isCitizen',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isRequestingCoverage', 'relation', 'name'],
    display: (values) => values.isRequestingCoverage === 'YES',
    label: (values) => ({
      id: '38_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
    help: { id: '38_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isSameNameApplicationAndDocument',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['ssn', 'relation', 'name'],
    display: (values) => !!values.ssn,
    label: (values) => ({
      id: '36_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'ssnAlternateName',
    prefix: true,
    type: 'legalName',
    required: true,
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      suffix: true,
    },
    dependencies: ['isSameNameApplicationAndDocument'],
    display: (values) => values.isSameNameApplicationAndDocument === 'NO',
    label: { id: '37_Q' },
    help: { id: '37_T' },
  },
  {
    type: 'section',
    title: 'Optional demographic information',
    fields: [
      {
        optional: true,
        copy: 'catch.ede.HealthEnrollment',
        name: 'isBlindOrDisabled',
        type: 'radio',
        options: optionPresets.YES_NO_TRINARY,
        dependencies: [
          'isRequestingCoverage',
          'isRequestingFinancialAssistance',
          'relation',
          'name',
        ],
        display: (values) =>
          values.isRequestingCoverage === 'YES' && values.isRequestingFinancialAssistance,
        required: false,
        label: (values) => ({
          id: '131_Q',
          data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
        }),
        help: { id: '131_T' },
      },
      {
        optional: true,
        copy: 'catch.ede.HealthEnrollment',
        name: 'isLongTermCare',
        type: 'radio',
        options: optionPresets.YES_NO_TRINARY,
        dependencies: [
          'isRequestingCoverage',
          'isRequestingFinancialAssistance',
          'relation',
          'name',
        ],
        display: (values) =>
          values.isRequestingCoverage === 'YES' && values.isRequestingFinancialAssistance,
        required: false,
        label: (values) => ({
          id: '132_Q',
          data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
        }),
        help: { id: '132_T' },
      },

      {
        copy: 'catch.ede',
        name: 'sexAssignedAtBirth',
        type: 'dropdown',
        options: optionPresets.SEX_ASSIGNED_AT_BIRTH,
        required: false,
        optional: true,
        grouped: true,
        dependencies: ['dob', 'name', 'coverageYear', 'isGA'],
        display: ({ dob, coverageYear, isGA }) =>
          !isGA && isOlderThan12(dob) && coverageYear >= 2024,
        label: (values) => ({
          id: 'HealthEnrollment.306_Q',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
        help: (values) => ({
          id: 'HealthEnrollment.306_T',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
      },
      {
        copy: 'catch.ede',
        name: 'sexAssignedAtBirthOtherText',
        type: 'text',
        dependencies: ['sexAssignedAtBirth', 'isGA'],
        display: (values) => !values.isGA && values.sexAssignedAtBirth === SexAssignedAtBirth.OTHER,
        placeholder: { id: 'enums.ASSIGNED_SEX' },
        regex: {
          value: /^[A-Za-z0-9 '.-]+$/,
          message: 'No special characters',
        },
      },
      {
        copy: 'catch.ede',
        name: 'gender',
        type: 'dropdown',
        options: optionPresets.GENDER,
        required: false,
        optional: true,
        grouped: true,
        dependencies: ['dob', 'name', 'coverageYear', 'isGA'],
        display: ({ dob, coverageYear, isGA }) =>
          !isGA && isOlderThan12(dob) && coverageYear >= 2024,
        label: (values) => ({
          id: 'HealthEnrollment.307_Q',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
        help: (values) => ({
          id: 'HealthEnrollment.306_T',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
      },
      {
        copy: 'catch.ede',
        name: 'genderDifferentTermText',
        type: 'text',
        dependencies: ['gender', 'isGA'],
        display: (values) => !values.isGA && values.gender === Gender.DIFFERENT_TERM,
        placeholder: { id: 'enums.GENDER' },
        regex: {
          value: /^[A-Za-z0-9 '.-]+$/,
          message: 'No special characters',
        },
      },
      {
        copy: 'catch.ede',
        name: 'sexualOrientation',
        type: 'dropdown',
        options: optionPresets.SEXUAL_ORIENTATION,
        required: false,
        optional: true,
        dependencies: ['dob', 'name', 'coverageYear', 'isGA'],
        grouped: true,
        display: ({ dob, coverageYear, isGA }) =>
          !isGA && isOlderThan12(dob) && coverageYear >= 2024,
        label: (values) => ({
          id: 'HealthEnrollment.308_Q',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
        help: (values) => ({
          id: 'HealthEnrollment.306_T',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
      },
      {
        copy: 'catch.ede',
        name: 'sexualOrientationDifferentTermText',
        type: 'text',
        dependencies: ['sexualOrientation'],
        display: (values) => values.sexualOrientation === SexualOrientation.DIFFERENT_TERM,
        placeholder: { id: 'enums.SEXUAL_ORIENTATION' },
        regex: {
          value: /^[A-Za-z0-9 '.-]+$/,
          message: 'No special characters',
        },
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'isHispanicOrigin',
        type: 'radio',
        optional: true,
        dependencies: ['relation', 'name', 'isGA'],
        options: optionPresets.YES_NO_TRINARY,
        required: false,
        label: (values) => ({
          id: '128_L',
          data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
        }),
        help: ({ isGA }) => copyGA('128_T', isGA),
      },
      {
        optional: true,
        copy: 'catch.ede.HealthEnrollment',
        name: 'ethnicity',
        type: 'dropdown',
        options: optionPresets.ETHNICITY,
        dependencies: ['isHispanicOrigin'],
        display: (values) => values.isHispanicOrigin === 'YES',
        label: { id: '129_Q' },
        help: { id: '129_T' },
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'otherEthnicity',
        type: 'text',
        dependencies: ['ethnicity'],
        display: (values) => values.ethnicity === 'OTHER',
        placeholder: 'Other ethnicity',
      },
      {
        optional: true,
        copy: 'catch.ede.HealthEnrollment',
        name: 'race',
        type: 'multiSelect',
        style: 'picker',
        label: { id: '130_Q' },
        required: false,
        options: optionPresets.RACE,
      },
      {
        name: 'otherRaceText',
        type: 'text',
        dependencies: ['race'],
        display: ({ race }) => race?.includes('OTHER'),
        placeholder: 'Race description',
      },
    ],
  },
];
