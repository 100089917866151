import React from 'react';
import { optionPresets } from '@app/forms';
import {
  personFullName,
  sixtyDaysFromNow,
  sixtyDaysAgo,
  todayISO,
  sixtyDaysAgoISO,
  sixtyDaysFromNowISO,
  useCopy,
} from '@util';

import UserMinus from '@svg/user-minus.svg';
import CalendarMinus from '@svg/calendar-minus.svg';
import Linked from '@svg/linked.svg';
import Gavel from '@svg/gavel.svg';
import Map from '@svg/map.svg';
import UserPlus from '@svg/user-plus.svg';
import Flag from '@svg/flag.svg';
import { Link } from '@app/_ui-kit';
import { sheets, useSheet } from '@app/navigate';

export enum SEPType {
  isLostCoverageLast60Days = 'isLostCoverageLast60Days',
  isWillLoseCoverageNext60Days = 'isWillLoseCoverageNext60Days',
  isMarriedLast60Days = 'isMarriedLast60Days',
  isReleasedFromIncarceration = 'isReleasedFromIncarceration',
  isMoved = 'isMoved',
  isGainDependent = 'isGainDependent',
  isGainedLawfulPresence = 'isGainedLawfulPresence',
}

const LearnMore = () => {
  const { c } = useCopy();
  const { open } = useSheet();
  const handlePress = (e) => {
    e.stopPropagation();
    open(sheets.HELP_TEXT, { subtitle: c('ede.HealthEnrollment.213_T') });
  };
  return <Link onPress={handlePress}>Learn more</Link>;
};

export const introOptions: Array<{ svg: any; label: string; sublabel?: string; value: SEPType }> = [
  {
    svg: UserMinus,
    label: 'Lost qualifiying health coverage',
    sublabel: (
      <>
        <LearnMore /> about qualifying health coverage
      </>
    ),
    value: SEPType.isLostCoverageLast60Days,
  },
  {
    svg: CalendarMinus,
    label: 'Will lose coverage',
    sublabel: `Before ${sixtyDaysFromNow()}`,
    value: SEPType.isWillLoseCoverageNext60Days,
  },
  {
    svg: Linked,
    label: 'Got married',
    value: SEPType.isMarriedLast60Days,
  },
  {
    svg: Gavel,
    label: 'Released from incarceration (detention or jail)',
    value: SEPType.isReleasedFromIncarceration,
  },
  {
    svg: Map,
    label: 'Moved',
    value: SEPType.isMoved,
  },
  {
    svg: UserPlus,
    label: 'Gained a dependent or became a dependent',
    sublabel: 'Due to an adoption, foster care placement, or court order',
    value: SEPType.isGainDependent,
  },
  {
    svg: Flag,
    label: 'Gained eligible immigration status',
    value: SEPType.isGainedLawfulPresence,
  },
];

export const questions = [
  {
    copy: 'catch.ede',
    name: 'isLostCoverageLast60Days',
    dependencies: ['isGA'],
    title: { id: 'SEP.lost_title' },
    label: { id: 'SEP.lost_q1', data: { sixtyDaysAgo: sixtyDaysAgo() } },
    sublabel: { id: 'SEP.docWarning' },
    help: ({ isGA }) => ({
      id: isGA ? 'HealthEnrollment.213_T_GA' : 'HealthEnrollment.213_T',
      data: { sixtyDaysAgo: sixtyDaysAgo() },
    }),
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isLostCoverageLast60Days'],
      subfields: [
        {
          testID: 'lost_q1',
          name: 'isLostCoverageLast60Days',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => personFullName(values),
        },
        {
          testID: '214_L',
          copy: 'catch.ede.HealthEnrollment',
          name: 'coverageLostDate',
          type: 'date',
          dependencies: ['isLostCoverageLast60Days', 'givenName'],
          format: 'YYYY-MM-DD',
          display: ({ isLostCoverageLast60Days }) => !!isLostCoverageLast60Days,
          before: { date: todayISO(), message: 'Must be before today' },
          after: { date: sixtyDaysAgoISO(), message: 'Must be in the last 60 days' },
          label: ({ givenName }) => ({
            id: '214_L',
            data: { fullName: givenName },
          }),
          required: true,
        },
        {
          testID: '215_Q',
          copy: 'catch.ede.HealthEnrollment',
          name: 'planName',
          type: 'text',
          optional: true,
          dependencies: ['isLostCoverageLast60Days', 'givenName'],
          display: ({ isLostCoverageLast60Days }) => !!isLostCoverageLast60Days,
          label: { id: '215_Q' },
          maxLength: 50,
        },
      ],
    },
    filterMembers: (members) => !members?.isUnwindingSEP,
  },
  {
    copy: 'catch.ede',
    name: 'isWillLoseCoverageNext60Days',
    title: { id: 'SEP.willlose_title' },
    label: { id: 'SEP.willlose_q1', data: { sixtyDaysFromNow: sixtyDaysFromNow() } },
    sublabel: { id: 'SEP.docWarning' },
    help: {
      id: 'HealthEnrollment.218_T',
      data: { sixtyDaysFromNow: sixtyDaysFromNow() },
    },
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isWillLoseCoverageNext60Days'],
      subfields: [
        {
          testID: 'willlose_q1',
          name: 'isWillLoseCoverageNext60Days',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => personFullName(values),
        },
        {
          testID: '219_L',
          copy: 'catch.ede.HealthEnrollment',
          name: 'coverageLostDateFuture',
          type: 'date',
          dependencies: ['isWillLoseCoverageNext60Days', 'givenName'],
          format: 'YYYY-MM-DD',
          display: ({ isWillLoseCoverageNext60Days }) => !!isWillLoseCoverageNext60Days,
          after: { date: todayISO(), message: 'Must be after today' },
          before: { date: sixtyDaysFromNowISO(), message: 'Must be in the next 60 days' },
          label: ({ givenName }) => ({
            id: '219_L',
            data: { fullName: givenName },
          }),
          required: true,
        },
        {
          testID: '220_L',
          copy: 'catch.ede.HealthEnrollment',
          name: 'planName',
          type: 'text',
          optional: true,
          dependencies: ['isWillLoseCoverageNext60Days', 'givenName'],
          display: ({ isWillLoseCoverageNext60Days }) => !!isWillLoseCoverageNext60Days,
          label: { id: '220_L' },
          maxLength: 50,
        },
      ],
    },
    filterMembers: (members) => members,
  },
  {
    copy: 'catch.ede',
    name: 'isMarriedLast60Days',
    title: { id: 'SEP.marriage_title' },
    label: { id: 'SEP.marriage_q1', data: { sixtyDaysAgo: sixtyDaysAgo() } },
    sublabel: { id: 'SEP.docWarning' },
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isMarriedLast60Days'],
      subfields: [
        {
          testID: 'marriage_q1',
          name: 'isMarriedLast60Days',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => personFullName(values),
        },
        {
          testID: '225_L',
          copy: 'catch.ede.HealthEnrollment',
          name: 'whenMarried',
          type: 'date',
          dependencies: ['isMarriedLast60Days', 'givenName'],
          format: 'YYYY-MM-DD',
          display: ({ isMarriedLast60Days }) => !!isMarriedLast60Days,
          before: { date: todayISO(), message: 'Must be before today' },
          after: { date: sixtyDaysAgoISO(), message: 'Must be in the last 60 days' },
          label: ({ givenName }) => ({
            id: '225_L',
            data: { fullName: givenName },
          }),
          required: true,
        },
        {
          testID: '226_Q',
          copy: 'catch.ede.HealthEnrollment',
          name: 'hasCoverage60DaysBeforeMarriage',
          type: 'option',
          options: optionPresets.YES_NO_TRINARY,
          dependencies: ['isMarriedLast60Days', 'givenName', 'sex'],
          display: ({ isMarriedLast60Days }) => !!isMarriedLast60Days,
          label: ({ givenName, sex }) => ({ id: '226_Q', data: { fullName: givenName, sex } }),
          required: true,
        },
        {
          testID: '227_Q',
          copy: 'catch.ede.HealthEnrollment',
          name: 'hasLivedOutsideUSLast60DaysBeforeMarriage',
          type: 'option',
          options: optionPresets.YES_NO_TRINARY,
          dependencies: [
            'isMarriedLast60Days',
            'givenName',
            'hasCoverage60DaysBeforeMarriage',
            'sex',
          ],
          display: ({ isMarriedLast60Days, hasCoverage60DaysBeforeMarriage }) =>
            !!isMarriedLast60Days && hasCoverage60DaysBeforeMarriage === 'NO',
          label: ({ givenName, sex }) => ({ id: '227_Q', data: { fullName: givenName, sex } }),
          required: true,
        },
      ],
    },
    filterMembers: (members) => members,
  },
  {
    copy: 'catch.ede',
    name: 'isGainedLawfulPresence',
    title: { id: 'SEP.immigration_title' },
    label: { id: 'SEP.immigration_q1', data: { sixtyDaysAgo: sixtyDaysAgo() } },
    sublabel: { id: 'SEP.docWarning' },
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isGainedLawfulPresence'],
      subfields: [
        {
          testID: 'immigration_q1',
          name: 'isGainedLawfulPresence',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => personFullName(values),
        },
        {
          testID: '230_Q',
          copy: 'catch.ede.HealthEnrollment',
          name: 'whenLawfulPresence',
          type: 'date',
          dependencies: ['isGainedLawfulPresence', 'givenName'],
          format: 'YYYY-MM-DD',
          display: ({ isGainedLawfulPresence }) => !!isGainedLawfulPresence,
          before: { date: todayISO(), message: 'Must be before today' },
          after: { date: sixtyDaysAgoISO(), message: 'Must be in the last 60 days' },
          label: ({ givenName }) => ({
            id: '230_Q',
            data: { fullName: givenName },
          }),
          required: true,
        },
      ],
    },
    filterMembers: (members) => members,
  },
  {
    copy: 'catch.ede',
    name: 'isMoved',
    title: { id: 'SEP.relocation_title' },
    label: { id: 'SEP.relocation_q1', data: { sixtyDaysAgo: sixtyDaysAgo() } },
    sublabel: { id: 'SEP.docWarning' },
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isMoved'],
      subfields: [
        {
          testID: 'relocation_q1',
          name: 'isMoved',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => personFullName(values),
        },
        {
          testID: '233_L',
          copy: 'catch.ede.HealthEnrollment',
          name: 'whenRelocated',
          type: 'date',
          dependencies: ['isMoved', 'givenName'],
          format: 'YYYY-MM-DD',
          display: ({ isMoved }) => !!isMoved,
          before: { date: todayISO(), message: 'Must be before today' },
          after: { date: sixtyDaysAgoISO(), message: 'Must be in the last 60 days' },
          label: ({ givenName }) => ({
            id: '233_L',
            data: { fullName: givenName },
          }),
          required: true,
        },
        {
          testID: '234_Q',
          copy: 'catch.ede.HealthEnrollment',
          name: 'hasCoverageLast60DaysBeforeRelocation',
          type: 'option',
          options: optionPresets.YES_NO_TRINARY,
          dependencies: ['isMoved', 'givenName', 'sex'],
          display: ({ isMoved }) => !!isMoved,
          label: ({ givenName, sex }) => ({
            id: '234_Q',
            data: {
              fullName: givenName,
              sex,
            },
          }),
          required: true,
        },
        {
          testID: '232_Q',
          copy: 'catch.ede.HealthEnrollment',
          name: 'isMovedFromOutsideUs',
          type: 'option',
          options: optionPresets.YES_NO_TRINARY,
          dependencies: ['isMoved', 'givenName'],
          display: ({ isMoved }) => !!isMoved,
          label: ({ givenName }) => ({
            id: '232_Q',
            data: {
              fullName: givenName,
            },
          }),
          required: true,
        },
        {
          testID: '231_L',
          copy: 'catch.ede.HealthEnrollment',
          name: 'previousZipcode',
          type: 'zip',
          required: true,
          dependencies: ['isMoved', 'givenName', 'isMovedFromOutsideUs'],
          display: ({ isMoved, isMovedFromOutsideUs }) =>
            !!isMoved && isMovedFromOutsideUs === 'NO',
          label: { id: '231_L' },
          help: { id: '231_T' },
        },
      ],
    },
    filterMembers: (members) => members,
  },
  {
    copy: 'catch.ede',
    name: 'isReleasedFromIncarceration',
    title: { id: 'SEP.incarceration_title' },
    label: { id: 'SEP.incarceration_q1', data: { sixtyDaysAgo: sixtyDaysAgo() } },
    sublabel: { id: 'SEP.docWarning' },
    help: { id: 'HealthEnrollment.250_T' },
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isReleasedFromIncarceration'],
      subfields: [
        {
          testID: 'incarceration_q1',
          name: 'isReleasedFromIncarceration',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => personFullName(values),
        },
        {
          testID: '235_L',
          copy: 'catch.ede.HealthEnrollment',
          name: 'whenReleasedFromIncarceration',
          type: 'date',
          dependencies: ['isReleasedFromIncarceration', 'givenName'],
          format: 'YYYY-MM-DD',
          display: ({ isReleasedFromIncarceration }) => !!isReleasedFromIncarceration,
          before: { date: todayISO(), message: 'Must be before today' },
          after: { date: sixtyDaysAgoISO(), message: 'Must be in the last 60 days' },
          label: ({ givenName }) => ({
            id: '235_Q',
            data: { fullName: givenName },
          }),
          required: true,
        },
      ],
    },
    filterMembers: (members) => members,
  },
  {
    copy: 'catch.ede.SEP',
    name: 'isGainDependent',
    title: { id: 'custody_title' },
    label: { id: 'custody_q1', data: { sixtyDaysAgo: sixtyDaysAgo() } },
    sublabel: { id: 'docWarning' },
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isGainDependent'],
      subfields: [
        {
          testID: 'custody_q1',
          name: 'isGainDependent',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => personFullName(values),
        },
        {
          testID: '229_Q',
          copy: 'catch.ede.HealthEnrollment',
          name: 'whenBecomeADependent',
          type: 'date',
          dependencies: ['isGainDependent', 'givenName'],
          format: 'YYYY-MM-DD',
          display: ({ isGainDependent }) => !!isGainDependent,
          before: { date: todayISO(), message: 'Must be before today' },
          after: { date: sixtyDaysAgoISO(), message: 'Must be in the last 60 days' },
          label: ({ givenName }) => ({
            id: '229_Q',
            data: { fullName: givenName },
          }),
          required: true,
        },
      ],
    },
    filterMembers: (members) => members,
  },
];
