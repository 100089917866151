import React from 'react';

import { Copy } from '@types';
import { Layout } from '@layouts';
import { OptionGroup } from '@uikit/components/OptionGroup';

interface ItemTriageBlueprintProps {
  header?: React.ReactNode;
  title?: Copy;
  question?: any;
  options?: any;
  onPress?: any;
}

/**
 * @description triages an item (paycheck, goal, etc.) with options
 */
export function ItemTriageBlueprint({
  loading,
  header,
  options,
  question,
  onPress,
}: ItemTriageBlueprintProps) {
  return (
    <Layout.Scroll topSpace={false} fullWidth color="sheet">
      <Layout margins>
        {header}
        <Layout.Header light sheet bottomSpace align="center" title={question} />
        <OptionGroup
          options={options}
          onPress={(val) => {
            if (onPress) onPress(val);
          }}
        />
      </Layout>
    </Layout.Scroll>
  );
}

export default ItemTriageBlueprint;
