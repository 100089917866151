// @ts-nocheck
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation, mutations, updates, HealthExplorerQuery } from '@data';
import { useMarketplace } from '@hooks';
import { Badge, BadgeColor, Button, ComplexRow } from '@uikit';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { pop, ScreenDefinition } from '@navigate';
import { BasicLayout, Stack, Toolbar } from '@layouts';
import { Route } from '@types';
import { formatMedicalName } from '@catch-co/health-utils';
import { SearchSelection, SearchResults } from '../components';
import Search from '@svg/search.svg';
import Pill from '@svg/pill.svg';
import { styled } from 'styled-components';
import { TextInput } from '@app/forms';

type Drug = {
  rxcui: string;
  name: string;
};

const BadgeContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 250px;
  justify-content: flex-end;
  margin-right: -4px;
  margin-bottom: -4px;

  > * {
    margin-right: 4px;
    margin-bottom: 4px;
  }
`;

const defaultRXCUI = '000000';

const ExplorerRX = ({ handleNext }) => {
  const { c } = useCopy('catch');

  const [drugs, setDrugs] = useState<Array<Drug>>();

  const { loading, data } = useQuery(HealthExplorerQuery, { fetchPolicy: 'cache-first' });

  const [upsert] = useMutation(mutations.UPSERT_HEALTH_PREFERENCE, {
    update: updates.UPSERT_HEALTH_PREFERENCE,
  });

  const explorer = useMemo(() => {
    return data?.viewerTwo?.healthExplorerData;
  }, [data?.viewerTwo?.healthExplorerData]);

  useEffect(() => {
    const drugsWanted = data?.viewerTwo?.health?.healthPreference?.drugsWanted || [];
    const formatted = drugsWanted.map((d) => ({ name: d.name, rxcui: d.rxcui }));
    setDrugs(formatted);
  }, [data?.viewerTwo?.health?.healthPreference]);

  const { searchTerm, handleSearch, clear, results, searching } = useMarketplace({
    type: 'drugs',
    zip: explorer?.zipcode,
  });

  const updateDrugs = (drugs: Array<Drug>) => {
    const drugsWanted = drugs.length === 0 ? null : drugs;

    upsert({
      variables: { input: { drugsWanted } },
      optimisticResponse: {
        upsertHealthPreference: {
          ...data?.viewerTwo.health?.healthPreference,
          drugsWanted,
        },
      },
    });
  };

  const addDrug = (drug: Drug) => {
    updateDrugs([...drugs, drug]);
  };

  const removeDrug = (drug: Drug) => {
    updateDrugs(
      [...drugs].filter((d) => {
        return drug.rxcui === defaultRXCUI ? d.name !== drug.name : d.rxcui !== drug.rxcui;
      }),
    );
  };

  const isSelected = useCallback(
    (rxcui: string) => {
      return drugs?.findIndex((d) => d.rxcui === rxcui) >= 0;
    },
    [drugs],
  );

  const handleSubmit = () => {
    const missing = drugs?.filter((drug) => drug.rxcui === defaultRXCUI);

    Segment.track(SegmentEvent.HX_PRESCRIPTIONS_COMPLETED, {
      skipped: drugs?.length === 0,
      total_prescriptions: drugs?.length || 0,
      prescriptions_not_found: missing?.length || 0,
      coverage_year: data?.viewerTwo?.healthExplorerData?.coverageYear,
    });

    handleNext();
  };

  return (
    <BasicLayout
      loading={loading}
      pretitle={c('basics.optional')}
      title={c('healthExplorer.explorerRX.title')}
      toolbar={
        <Toolbar type="flow" onBack={pop}>
          <Button testID="next" inherit onPress={handleSubmit}>
            {drugs?.length === 0 ? 'Skip' : 'Next'}
          </Button>
        </Toolbar>
      }
    >
      <Stack spacing="3" separatorComponent>
        <SearchSelection<Drug>
          title={c('healthExplorer.explorerRX.searchTitle')}
          empty={c('healthExplorer.explorerRX.searchEmpty')}
          icon={Pill}
          data={drugs}
          getKey={(drug) => `${drug.rxcui}-${drug.name}`}
          getLabel={(drug) => drug.name}
          handleRemove={removeDrug}
        />
        <Stack spacing="2">
          <TextInput
            sensitive
            fullWidth
            label={c('healthExplorer.explorerRX.label')}
            placeholder={c('healthExplorer.explorerRX.placeholder')}
            icon={Search}
            value={searchTerm}
            onChange={handleSearch}
          />

          <SearchResults searching={searching}>
            {searchTerm && (
              <ComplexRow
                bg="mediumLight"
                selectedBg="mediumLight"
                label="Don't see your prescription?"
                sublabel={`Add "${searchTerm}"`}
                accessory="add"
                onPress={() => {
                  addDrug({
                    rxcui: defaultRXCUI,
                    name: searchTerm,
                  });

                  clear();
                }}
              />
            )}
            {results?.map((drug) => (
              <ComplexRow
                key={drug?.name}
                sensitive
                disabled={searching}
                label={formatMedicalName(drug?.name)}
                sublabel={drug?.route}
                accessory={
                  <BadgeContainer>
                    {drug?.strengths.map(({ strength, rxcui }) => {
                      const selected = isSelected(rxcui);
                      const formatted = {
                        name: `${formatMedicalName(drug?.name)} (${strength})`,
                        rxcui,
                      };

                      return (
                        <Badge
                          key={rxcui}
                          label={strength}
                          color={selected ? BadgeColor.success : undefined}
                          onPress={() => (selected ? removeDrug(formatted) : addDrug(formatted))}
                        />
                      );
                    })}
                  </BadgeContainer>
                }
              />
            ))}
          </SearchResults>
        </Stack>
      </Stack>
    </BasicLayout>
  );
};

export const ExplorerRXView: ScreenDefinition = {
  name: Route.EXPLORER_PRESCRIPTIONS,
  component: ExplorerRX,
  options: {},
};
