import React from 'react';
import { Button } from '@uikit';
import { Toolbar } from '@layouts';
import { ScreenDefinition, pop } from '@navigate';
import { useCopy, sixtyDaysAgo } from '@app/utils';
import { Route } from '@types';
import { BasicFormBlueprint } from '@app/blueprints';

const QLEOptions = [
  'COVERAGE_LOSS',
  'MOVE',
  'ELIGIBILITY',
  'BIRTH',
  'COVERAGE_OPTIONS',
  'DIVORCE',
  'DEATH',
  'DISABILITY',
  'ADOPTION',
  'CUSTODY',
  'FILING_STATUS',
  'CITIZENSHIP',
  'INCARCERATION',
  'TRIBE_MEMBERSHIP',
];

const ExplorerQLE = ({ handleNext }) => {
  const { c } = useCopy('catch');

  return (
    <BasicFormBlueprint
      loading={false}
      submitting={false}
      title={c('qle-title', { sixtyDaysAgo: sixtyDaysAgo() })}
      onBack={pop}
      toolbar={() => (
        <Toolbar type="stack">
          <Button testID="no-QLE" wide onPress={() => handleNext({ hasQLE: false })}>
            {c('basics.noneAbove', {})}
          </Button>
        </Toolbar>
      )}
      formConfig={{
        initialValues: {},
        fields: [
          {
            type: 'option',
            name: 'qle',
            options: QLEOptions.map((option) => ({
              id: option,
              label: c(`healthBasics.QLE.${option}`, {}) || '',
              value: option,
            })),
          },
        ],
        onSubmit: () => handleNext({ hasQLE: true }),
      }}
    />
  );
};

export const ExplorerQLEView: ScreenDefinition = {
  name: Route.EXPLORER_QLE,
  component: ExplorerQLE,
  options: {},
};
