import { gql } from '../generated';

gql(`
  fragment BankingSetupUser on User {
    id
    givenName
    middleName
    familyName
    nameSuffix
    ssn
    dob
    phoneNumber
    occupation
    legalAddress {
      street1
      street2
      city
      state
      zip
      country
    }
    agreements {
      ...Agreements 
    }
  }
`);

export const GET_BANKING_SETUP_DETAILS = gql(`
  query GetBankingSetupDetails {
    me {
      user {
        ...BankingSetupUser 
      }
    }
  }
`);

export const UPDATE_BANKING_SETUP_USER = gql(`
  mutation UpdateBankingSetupUser($input: UpdateUserInput!) {
    updateUserNew(input: $input) {
      user {
        ...BankingSetupUser
      }
    }
  }
`);

export const CREATE_BANK_APPLICATION = gql(`
  mutation CreateBankApplication {
    createBankApplication {
      id
      status
    }
  }
`);

export const GET_BANK_APPLICATION = gql(`
  query GetBankApplication {
    bankApplication {
      id
      status
    }
  }
`);

export const CREATE_BANK_ACCOUNT = gql(`
  mutation CreateBankAccount {
    createBankAccount {
      id
    }
  }
`);
