import { Color } from '@app/styles';

interface SchemeConfig {
  bg: string;
  hover: string;
  text: string;
  gradient?: string[];
}

interface Scheme {
  regular: SchemeConfig;
  light: SchemeConfig;
}

const primaryScheme: Scheme = {
  regular: {
    bg: Color.fg,
    hover: Color.fg,
    text: Color.bg,
  },
  light: {
    bg: Color.bg3,
    hover: Color.bg2,
    text: Color.fg,
  },
};

const brandScheme: Scheme = {
  regular: {
    gradient: ['#9C11D9', '#8516DB'],
    bg: Color.brand,
    hover: Color.brand,
    text: Color.snow,
  },
  light: {
    bg: Color.brandLight,
    hover: Color.brandLight,
    text: Color.fg,
  },
};

const coverageScheme: Scheme = {
  regular: {
    gradient: ['#9C11D9', '#8516DB'],
    bg: Color.coverage,
    hover: Color.coverage,
    text: Color.snow,
  },
  light: {
    bg: Color.coverageLight,
    hover: Color.coverageLight,
    text: Color.fg,
  },
};

const aetnaScheme: Scheme = {
  regular: {
    gradient: ['#7D3F98', '#7D3F98'],
    bg: Color.aetna,
    hover: Color.aetna,
    text: Color.snow,
  },
  light: {
    bg: Color.aetnaLight,
    hover: Color.aetnaLight,
    text: Color.fg,
  },
};

export const schemes = {
  primary: primaryScheme,
  brand: brandScheme,
  coverage: coverageScheme,
  aetna: aetnaScheme,
};
