import { React } from 'react';
import { default as RLottie } from 'react-lottie-player';

export const Lottie = ({ size, animation, repeat }) => {
  return (
    <RLottie width={size} height={size} loop={repeat} play speed={1} animationData={animation} />
  );
};

export default Lottie;
