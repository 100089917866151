import { gql } from '../generated';

export const PreviousApplicationsQuery = gql(`
  query PreviousApplications($input: PreviousApplicationsInput) {
    viewerTwo {
      id
      health {
        previousApplications(input: $input) {
          existingApplicationsInformation {
            givenName
            familyName
            applicationIdentifier
            coverageYearNumber
            state
            applicationVersion
            lastUpdated
            applicationStatus
            hasExistingPersonTrackingNumbersTiedToApplication
            isPriorYear
          }
        }
      }
    }
  }
`);
