import React from 'react';
import { View } from 'react-native';

import { lowercase } from '@app/utils';
import { useTheme } from '@uikit/hooks/useTheme';
import { Asset, Pressable } from '@app/_ui-kit';
import { Text } from '../Text';

import Close from '@svg/close.svg';
import Back from '@svg/chevron-left.svg';
import More from '@svg/dots-horizontal.svg';

/**
 * Modal navigation icon
 * - handles displaying icon for close/back, etc.
 * @deprecated for sheetnav?
 */
const ModalNavIcon = ({ icon, color }) => {
  const icons = {
    close: Close,
    back: Back,
    more: More,
  };

  const svg = icons[lowercase[icon]];

  return svg ? <Asset svg={svg} size="xs" color={color} /> : null;
};

/**
 * @deprecated, see SheetNav
 */
const ModalNavText = ({ text, color }) => {
  if (!text) return null;
  return (
    <Text color={color} weight="medium">
      {text}
    </Text>
  );
};

const ModalNav = ({
  title,
  color,
  textColor = 'text',
  onLeft,
  left,
  onRight,
  right,
  disabledRight,
}) => {
  const { theme } = useTheme();

  return (
    <View style={[styles.nav, theme[`${color}Bg`]]}>
      <View style={styles.left}>
        <Pressable handleOnPress={onLeft}>
          <ModalNavIcon icon={left?.icon} color={left?.color || textColor} />
          <ModalNavText text={left?.text} color={left?.color || textColor} />
        </Pressable>
      </View>
      <View style={styles.center}>
        <Text align="center" weight="medium" style={styles.titleText} color={textColor}>
          {title}
        </Text>
      </View>
      <View style={styles.right}>
        <Pressable
          handleOnPress={onRight}
          disabled={disabledRight}
          style={disabledRight && theme.disabled}
        >
          <ModalNavIcon icon={right?.icon} color={right?.color || textColor} />
          <ModalNavText text={right?.text} color={right?.color || textColor} />
        </Pressable>
      </View>
    </View>
  );
};

const styles = {
  center: {
    zIndex: -1, // so the title goes behind the buttons
    position: 'absolute', // for absolute center of the title
    left: 0, // for absolute center of the title
    right: 0, // for absolute center of the title
    width: '100%',
  },
  titleText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  nav: {
    zIndex: 999,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 56,
  },
  left: {
    position: 'absolute',
    left: 16,
  },
  right: {
    position: 'absolute',
    right: 16,
  },
};

export default ModalNav;
