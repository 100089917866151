import { gql } from '../generated';

gql(`
  fragment IncomeSource on IncomeSource {
    id
    subtext
    text
    transactionTag {
      id
      logoUrl
      name
    }
  }
`);

gql(`
  fragment Income on Income {
    id
    status
    name
    originalDescription
    amount
    transactionDate
    incomeSource {
      ...IncomeSource
    }
  }
`);

export const GET_PAGED_INCOME = gql(`
  query PagedIncome($first: Int!) {
    pagedIncome(first: $first, filter: { statuses: [READY] }) {
      edges {
        ...Income
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`);

export const GET_INCOME = gql(`
  query Income($incomeId: ID!) {
    income(id: $incomeId) {
      ...Income
    }
  }
`);

export const SKIP_INCOME = gql(`
  mutation SkipIncome($input: SkipIncomeInput!) {
    skipIncome(input: $input) {
      ...Income
    }
  }
`);

export const APPROVE_INCOME = gql(`
  mutation ApproveIncome($input: ApproveIncomeInput!) {
    approveIncome(input: $input) {
      ...Income
    }
  }
`);
