import { Banner, Link, Text } from '@uikit';
import { open } from '@navigate';
import { FontWeight, Route, TextAlign } from '@types';
import { useCopy } from '@app/utils';

export interface WithholdingBannerProps {
  grossIncome?: number;
  paycheckPercentage?: number;
}

/**
 * Displays a banner for "thats about $X per year based on your annual income of $Y"
 */
export function WithholdingBanner({ grossIncome, paycheckPercentage }: WithholdingBannerProps) {
  const { c, $ } = useCopy('catch');

  // don't display anything if inputs are missing
  if (!grossIncome || !paycheckPercentage) {
    console.warn('Fields not passed to WithholdingBanner', { grossIncome, paycheckPercentage });
    return null;
  }

  const withholdingAmount = (grossIncome * paycheckPercentage) / 100;

  // allow users to edit income without going back a few screens
  const onEditIncome = () => open(Route.EDIT_USER_DETAILS, { field: 'ESTIMATED_INCOME' });

  return (
    <Banner
      title={
        <Text color="subtle" align={TextAlign.center}>
          {c('goal.withholding.banner', {
            withholding: (
              <Text weight={FontWeight.semibold} color="text">
                {$(withholdingAmount, { whole: true })}
              </Text>
            ),
            income: (
              <Link testID="estimated-income" weight={FontWeight.semibold} onPress={onEditIncome}>
                {$(grossIncome || 0, { whole: 2 })}
              </Link>
            ),
          })}
        </Text>
      }
      style={{ marginTop: 24 }}
    />
  );
}
