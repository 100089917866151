import React from 'react';
import { stacks, Stack } from '@navigate';
import { CoverageView } from './routes/CoverageView';
import { CoverageDetailView } from './routes/CoverageDetailView';

const config = {
  stackName: stacks.COVERAGE_STACK,
  options: { largeTitle: true, drawBehindNav: true, accentColor: 'coverage' },
  screens: [CoverageView, CoverageDetailView],
  tabIcon: 'COVERAGE',
  title: 'Coverage',
};

const CoverageStack = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

CoverageStack.config = config;
export default CoverageStack;
