import React, { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Loading } from '@uikit/components/Loading';
import { SheetsProvider } from '@sheets';
import { getRouteFromURL } from '@navigate';
import AuthenticatedSupport from './AuthenticatedSupport';
import MagicModals from './MagicModals';
import MagicNav from './MagicNav';
import { ErrorBoundary } from '@app/_common';
import { ReviewPrompt } from '@app/components/review';
import { useAgreements } from '@app/hooks';

const MainLayout = () => {
  const location = useLocation();

  /**
   * Adds data prefetching
   * - Ensures that certain queries are already in cache when needed
   */
  useAgreements();

  return (
    <>
      <MagicNav layout="page" route={getRouteFromURL(location)} />
      <Suspense fallback={<Loading full page />}>
        <ErrorBoundary route={getRouteFromURL(location)}>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
      <MagicModals />
      <SheetsProvider />
      <AuthenticatedSupport />
      <ReviewPrompt />
    </>
  );
};

export default MainLayout;
