import React from 'react';
import { optionPresets } from '@app/forms';
import IncomeDetailsCard from './IncomeDetailsCard';
import IncomeDetailsFooter from './IncomeDetailsFooter';

const subfields = ({ deductions }: { deductions: boolean }) => [
  {
    copy: 'catch.ede',
    name: 'incomeFrequencyType',
    type: 'dropdown',
    required: true,
    dependencies: ['type'],
    options: ({ type }) =>
      type && type === 'JOB'
        ? [
            ...optionPresets.FREQUENCY_TYPES,
            { value: 'DAILY', label: { id: 'enums.DAILY' } },
            { value: 'HOURLY', label: { id: 'enums.HOURLY' } },
          ]
        : optionPresets.FREQUENCY_TYPES, // job frequency types
    label: { id: 'HealthEnrollment.1542_L', data: { deductions } },
  },
  {
    copy: 'catch.ede',
    name: 'incomeAmount',
    type: 'amount',
    amountType: 'decimal',
    required: true,
    dependencies: ['type', 'incomeFrequencyType'],
    display: ({ incomeFrequencyType }) => !!incomeFrequencyType,
    label: ({ incomeFrequencyType }) => ({
      id: 'HealthEnrollment.1541_L_DUPE',
      data: {
        deductions,
        frequency: { id: 'IncomeConfirm.frequency', data: { frequency: incomeFrequencyType } },
      },
    }),
    // allow negative values for certain types of income
    allowNegative: ({ type }) =>
      ['SELF_EMPLOYMENT', 'FARMING_OR_FISHING_INCOME', 'RENTAL_OR_ROYALTY_INCOME'].includes(type),
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'averageWeeklyWorkHours',
    type: 'number',
    numberType: 'integer',
    required: true,
    max: 168, // 24 * 7
    dependencies: ['type', 'incomeFrequencyType'],
    display: ({ type, incomeFrequencyType }) => type === 'JOB' && incomeFrequencyType === 'HOURLY',
    label: { id: '156_L', data: { unit: 'hours' } },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'averageWeeklyWorkDays',
    type: 'number',
    numberType: 'integer',
    max: 7,
    required: true,
    dependencies: ['type', 'incomeFrequencyType'],
    display: ({ type, incomeFrequencyType }) => type === 'JOB' && incomeFrequencyType === 'DAILY',
    label: { id: '156_L', data: { unit: 'days' } },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'employerName',
    type: 'text',
    required: true,
    dependencies: ['type'],
    display: ({ type }) => type === 'JOB',
    label: { id: '155_L' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'employerIdentificationNumber',
    type: 'text',
    dependencies: ['type', 'PARENT:slasher'],
    display: ({ type, slasher }) => type === 'JOB' && !slasher,
    optional: true,
    label: { id: '209_EIN' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'employerAddress',
    type: 'address',
    optional: true,
    dependencies: ['type'],
    display: ({ type }) => type === 'JOB',
    label: { id: '155_' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'selfEmploymentIncomeDescription',
    type: 'text',
    required: true,
    dependencies: ['type'],
    display: ({ type }) => type === 'SELF_EMPLOYMENT',
    label: { id: '159_L' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'incomeDescription',
    type: 'text',
    dependencies: ['type'],
    display: ({ type }) => type === 'UNEMPLOYMENT',
    label: { id: '1651_L' },
    optional: true,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'otherIncomeDescription',
    type: 'text',
    dependencies: ['type'],
    display: ({ type }) => type === 'OTHER_INCOME',
    label: { id: '168_L' },
    optional: true,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'expirationDate',
    type: 'date',
    format: 'YYYY-MM-DD',
    dependencies: ['type', 'PARENT:slasher'],
    display: ({ type, slasher }) => /UNEMPLOY/.test(type) && !slasher,
    label: { id: '1652_L' },
    optional: true,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'educationExpenses',
    type: 'text',
    required: true,
    dependencies: ['type', 'PARENT:slasher'],
    display: ({ type, slasher }) => /SCHOLARSHIP/.test(type) && !slasher,
    label: { id: '169a_L' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'otherDeductionDescription',
    type: 'text',
    required: true,
    dependencies: ['type'],
    display: ({ type }) => type === 'OTHER_DEDUCTION',
    label: { id: '178_L' },
  },
];

export const fields = [
  {
    testID: '154',
    copy: 'catch.ede',
    name: 'incomeSources',
    type: 'array',
    required: true,
    grouped: false,
    dependencies: [
      'incomeThisMonth',
      'slasher',
      'relation',
      'fullName',
      'SUBFIELD:type',
      'SUBFIELD:employerName',
    ],
    label: { id: 'IncomeDetails.incomeTitle' },
    help: ({ fullName }) => ({
      id: 'HealthEnrollment.154_T',
      data: { fullName },
    }),
    sublabel: ({ relation, fullName }) => ({
      id: 'IncomeDetails.incomeSubtitle',
      data: { relation, fullName },
    }),
    display: ({ incomeThisMonth, slasher }) => !!incomeThisMonth || !!slasher,
    Component: IncomeDetailsCard,
    Footer: (props) => <IncomeDetailsFooter {...props} type="income" />,
    subfields: subfields({ deductions: false }),
  },
  {
    testID: '175',
    copy: 'catch.ede',
    name: 'deductions',
    type: 'array',
    grouped: false,
    dependencies: [
      'hasDeductions',
      'coverageYear',
      'fullName',
      'SUBFIELD:type',
      'SUBFIELD:employerName',
    ],
    label: ({ coverageYear }) => ({ id: 'IncomeDetails.deductionsTitle', data: { coverageYear } }),
    sublabel: { id: 'IncomeDetails.deductionsSubtitle' },
    help: ({ fullName }) => ({
      id: 'HealthEnrollment.175_T',
      data: { fullName },
    }),
    optional: true,
    display: ({ hasDeductions }) => !!hasDeductions,
    Component: IncomeDetailsCard,
    Footer: (props) => <IncomeDetailsFooter {...props} type="deduction" />,
    subfields: subfields({ deductions: true }),
  },
];
