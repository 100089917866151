import React from 'react';
import { Proofing } from '@app/partial';
import { Loading } from '@uikit';
import { useExistingApps } from '@app/hooks/useExistingApps';
import { Route } from '@app/types';

/**
 * EDE Application Identity
 */
const ApplicationIdentity = ({ applicantID, applicationID, coverageYear }) => {
  const { checking, checkForExistingApplications } = useExistingApps(applicationID);

  // don't show the proofing pages until we're sure we have an applicant ID
  if (!applicantID) {
    return <Loading />;
  }

  return (
    <Proofing
      disabled={checking}
      canSearch
      shouldHandoff
      applicantID={applicantID}
      handleSuccess={checkForExistingApplications}
      coverageYear={coverageYear}
    />
  );
};

export const ApplicationIdentityView = {
  name: Route.EDE_IDENTITY,
  component: ApplicationIdentity,
  options: {},
};
