import { ScheduleWidget } from '@app/components/schedule';
import { Route } from '@types';

const ExplorerSchedule = () => {
  return <ScheduleWidget />;
};

export const ExplorerScheduleView = {
  name: Route.EXPLORER_SCHEDULE,
  component: ExplorerSchedule,
  options: {},
};
