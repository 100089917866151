import { gql } from '../generated';

export const ExplorerEligibilityQuery = gql(`
  query ExplorerEligibility {
    viewerTwo {
      healthExplorerData {
        id
        pathwayType
        dependents {
          id
          age
          sex
          relation
        }
        marketplaceEligibility {
          aptc
          csr
          isMedicaidChip
        }
        healthPlans {
          plans {
            id
          }
        }
      }
    }
  }
`);
