import { env } from '@app/global';

const DEV_ALIAS_KEY = 'devAlias';

const getAlias = (prefix: string) =>
  !env.IS_PROD ? `${prefix || 'user'}+${Date.now()}_test@catch.co` : '';

/**
 * in the development environment, we check for an alias in local storage
 * if it's set, then we prefill the signup credentials
 */
export const useDevAlias = () => {
  const alias = localStorage.getItem(DEV_ALIAS_KEY);
  const shouldPrompt = !env.IS_PROD && !alias;

  const createAlias = (name?: string) => {
    name = name || prompt('What is your first name?') || '';
    localStorage.setItem(DEV_ALIAS_KEY, name);
    return getAlias(name);
  };

  const generateAlias = () => {
    return getAlias(alias || 'user');
  };

  return {
    shouldPrompt,
    generateAlias,
    createAlias,
  };
};
