// TODO: separate these out into different files for the different formatting
// purposes.

import isNaN from 'lodash.isnan';
import { onlyNums } from './strings';

function noSpaces(value = '') {
  return value.replace(/[\s]/g, '');
}

// allows only letters and - (hyphenated names)
function legalName(value = '') {
  return value.replace(/[^A-Za-z-' ]+/g, '');
}

export function formatLegalName(value) {
  if (!value) {
    return value;
  }
  return legalName(value);
}

export function formatPhone(value) {
  if (!value) {
    return value;
  }

  const nums = onlyNums(value);

  if (nums.length <= 3) {
    return nums;
  }

  if (nums.length <= 7) {
    return `${nums.slice(0, 3)}-${nums.slice(3)}`;
  }

  return `${nums.slice(0, 3)}-${nums.slice(3, 6)}-${nums.slice(6, 10)}`;
}

export function formatNoSpaces(value) {
  if (typeof value !== 'string') {
    return value;
  }

  return noSpaces(value);
}

// idk, returns 2019043020 ????
export function formatDate(value, isDeleting = false) {
  if (!value) return value;

  let output = value
    .replace(/^(\d\d)(\d)$/g, '$1/$2')
    .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
    .replace(/[^\d\/]/g, '');

  return output.substr(0, 10);
}

export function formatSSN(value) {
  if (!value) {
    return value;
  }
  const nums = onlyNums(value);

  if (nums.length <= 3) {
    return nums;
  }
  if (nums.length <= 5) {
    return `${nums.slice(0, 3)}-${nums.slice(3)}`;
  }
  return `${nums.slice(0, 3)}-${nums.slice(3, 5)}-${nums.slice(5, 9)}`;
}

export function formatEIN(value) {
  if (!value) {
    return value;
  }
  const nums = onlyNums(value);

  if (nums.length <= 2) {
    return nums;
  }

  return `${nums.slice(0, 2)}-${nums.slice(2, 9)}`;
}

export function formatNumber(value) {
  if (!value) {
    return value;
  }
  return onlyNums(value);
}

// preceisionRound allows you to round a number to a specific decimal place.
export function precisionRound(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export function ensureZero(value) {
  return isNaN(value) || value === '' ? 0 : parseInt(value);
}

export function ensureZeroFloat(value) {
  return isNaN(value) || value === '' ? 0 : value;
}

export function lastFour(accountNumber) {
  if (!accountNumber) return '';
  return accountNumber.slice(accountNumber.length - 4);
}

export function truncate(value, length) {
  if (!value) return value;
  return value.length > length ? `${value.substring(0, length)}...` : value;
}

export function boolToTri(val) {
  if (val === true) {
    return 'YES';
  }
  if (val === false) {
    return 'NO';
  }
  return val;
}

export function triToBool(val) {
  if (val === 'YES') {
    return true;
  }
  if (val === 'NO') {
    return false;
  }
  return val;
}
