import { FieldsConfig } from '@types';

// set of form fields that we want to be able to reuse across multiple screens
type FormType = 'CMS_PRIVACY';

const questions = {
  GA: [{ label: '3A_Q', help: '3A_T_GA' }, { label: '3B_Q', help: '3B_T' }, { label: '3C_Q' }],
  CA: [{ label: '3A_Q_CA' }, { label: '3B_Q' }, { label: '3C_Q_CA' }],
  NJ: [{ label: '3A_Q_NJ' }, {}, { label: '3C_Q_NJ' }],
};

const getQuestionCopy = ({ index, key, coverageState, fallback }) => {
  if (coverageState in questions) {
    const id = questions[coverageState]?.[index]?.[key];
    return id ? { id } : undefined;
  }

  return { id: fallback };
};

const hideTitles = ['NJ'];

export const forms: Record<FormType, FieldsConfig> = {
  CMS_PRIVACY: [
    {
      copy: 'catch.ede.HealthEnrollment',
      type: 'checkbox',
      name: 'isPrivacyDataUseAgree',
      required: true,
      dependencies: ['coverageState'],
      title: ({ coverageState }) => {
        return hideTitles.includes(coverageState) ? '' : 'Catch can securely handle my info';
      },
      label: ({ coverageState }) =>
        getQuestionCopy({ index: 0, key: 'label', coverageState, fallback: '3A_Q' }),
      help: ({ coverageState }) =>
        getQuestionCopy({ index: 0, key: 'help', coverageState, fallback: '3A_T' }),
    },
    {
      copy: 'catch.ede.HealthEnrollment',
      type: 'checkbox',
      name: 'isPrivacyTruthfulAgree',
      required: true,
      title: ({ coverageState }) => {
        return hideTitles.includes(coverageState) ? '' : "I'll be honest";
      },
      dependencies: ['coverageState'],
      label: ({ coverageState }) =>
        getQuestionCopy({ index: 1, key: 'label', coverageState, fallback: '3B_Q' }),
      help: ({ coverageState }) =>
        getQuestionCopy({ index: 1, key: 'help', coverageState, fallback: '3B_T' }),
      display: ({ coverageState }) => coverageState !== 'NJ',
    },
    {
      copy: 'catch.ede.HealthEnrollment',
      type: 'checkbox',
      name: 'isPrivacyAuthorizeAgree',
      required: true,
      dependencies: ['coverageState'],
      title: ({ coverageState }) => {
        return hideTitles.includes(coverageState) ? '' : 'Catch can submit on my behalf';
      },
      label: ({ coverageState }) =>
        getQuestionCopy({ index: 2, key: 'label', coverageState, fallback: '3C_Q' }),
    },
  ],
};
