import { gql } from '../generated';

export const ResendConsentRequest = gql(`
  mutation ResendConsentRequest($input: ResendConsentRequestInput!) {
    resendConsentRequest(input: $input) {
        id
        sentAt
    }
  }
`);
