import React from 'react';
import { Stack } from '@app/layouts';
import { safeFormatDate, useCopy } from '@app/utils';
import EligibilityItem from './EligibilityItem';

import Clock from '@svg/clock.svg';
import Warning from '@svg/warning-triangle.svg';

const items = {
  DMI: {
    icon: Clock,
    title: 'tempElig.p1',
    description: 'tempElig.p2',
  },
  SVI: {
    icon: Warning,
    title: 'sepEligibleTitle',
    description: 'sepEligibleSubmitDocuments',
  },
};

const EligibilityIssuesList = ({ issues }) => {
  const { c } = useCopy('catch.ede.EligibilityResults');
  if (issues?.length === 0) return null;

  return (
    <Stack>
      {issues.map((issue) => (
        <EligibilityItem
          key={issue.type}
          icon={items[issue.type].icon}
          title={c(items[issue.type].title)}
          description={c(items[issue.type].description, {
            date: safeFormatDate(issue.date),
            sviReason: c(`sepReason_${issue.qle}`),
          })}
        />
      ))}
    </Stack>
  );
};

export default EligibilityIssuesList;
