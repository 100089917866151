import { useMemo } from 'react';
import { sortPlans, SortMethod, NetworkType as PlanType, MetalLevel } from '@catch-co/health-utils';
import { create } from 'zustand';
import { lowercase, capitalize } from '@app/utils';
import { ActionRowProps } from '@uikit';
import { ApolloScoredHealthPlan } from '@app/data';

type OptionType = 'sort' | 'planType' | 'metalLevel' | 'other';
type Other = 'HSA_eligible';

const generateActions = (
  values,
  currentValue: string | Array<string>,
  handleValue,
  reset,
  closeSheet,
): Array<ActionRowProps> => {
  return [
    ...values.map((val) => ({
      testID: val,
      label: capitalize(val.replace('_', ' '), false),
      interaction: 'radio',
      selectedByDefault: currentValue === val || currentValue.includes(val),
      onAction: (selected) => {
        if (typeof currentValue === 'string') {
          handleValue(val);
        } else {
          let set = new Set([...currentValue]);
          selected ? set.add(val) : set.delete(val);
          handleValue(Array.from(set));
        }
      },
    })),
    {
      testID: 'reset',
      label: 'Reset',
      onAction: () => reset(),
    },
    {
      testID: 'done',
      label: 'Done',
      button: true,
      onAction: () => closeSheet(),
    },
  ];
};

export const applyHealthPlanFilters = (
  plan: ApolloScoredHealthPlan,
  { metalLevels, planTypes, other },
) => {
  if (metalLevels.length > 0) {
    const foundMatch = metalLevels.some((level) => {
      return lowercase(plan.healthPlan.metalLevel).includes(lowercase(level));
    });

    if (!foundMatch) {
      return false;
    }
  }

  if (planTypes.length > 0) {
    const foundMatch = planTypes.some((planType) => {
      return plan.healthPlan.type === planType;
    });

    if (!foundMatch) {
      return false;
    }
  }

  if (other.length > 0) {
    // if HSA selected, must match
    if (other?.includes('HSA_eligible')) {
      return plan.healthPlan.hsaEligible;
    }

    if (other?.includes('Easy_Pricing')) {
      return plan.healthPlan.isStandard;
    }
  }

  return true;
};

export const sortHealthPlans = (plans: Array<ApolloScoredHealthPlan>, type: SortMethod) => {
  return sortPlans(plans, type);
};

interface SortAndFilterState {
  sheet?: OptionType;
  openSheet: (type: OptionType) => void;
  closeSheet: () => void;
  sortMethod: SortMethod;
  setSortMethod: (method: SortMethod) => void;
  metalLevels: Array<MetalLevel>;
  setMetalLevel: (metal: Array<MetalLevel>) => void;
  planTypes: Array<PlanType>;
  setPlanType: (type: Array<PlanType>) => void;
  other: Array<Other>;
  setOther: (other: Array<Other>) => void;
  resetFilters: () => void;
}

export const useSortAndFilter = create<SortAndFilterState>((set, get) => ({
  sheet: undefined,
  openSheet: (type: OptionType) => set({ sheet: type }),
  closeSheet: () => set({ sheet: undefined }),
  sortMethod: 'recommended',
  setSortMethod: (sortMethod) => set({ sortMethod }),
  metalLevels: [],
  setMetalLevel: (metalLevels) => set({ metalLevels }),
  planTypes: [],
  setPlanType: (planTypes) => set({ planTypes }),
  other: [],
  setOther: (other) => set({ other }),
  resetFilters: () => set({ metalLevels: [], planTypes: [], other: [] }),
}));

export const useHealthPlansSortAndFilter = () => {
  const {
    sheet,
    sortMethod,
    setSortMethod,
    metalLevels,
    setMetalLevel,
    planTypes,
    setPlanType,
    other,
    setOther,
    openSheet,
    closeSheet,
    resetFilters,
  } = useSortAndFilter();

  const sortActions: Array<ActionRowProps> = useMemo(() => {
    return generateActions(
      ['recommended', 'lowest_premium', 'lowest_deductible'],
      sortMethod,
      setSortMethod,
      () => setSortMethod('recommended'),
      closeSheet,
    );
  }, [sortMethod, setSortMethod]);

  const planTypeActions: Array<ActionRowProps> = useMemo(() => {
    return generateActions(
      ['HMO', 'PPO', 'EPO'],
      planTypes,
      setPlanType,
      () => setPlanType([]),
      closeSheet,
    );
  }, [planTypes, setPlanType]);

  const metalLevelActions: Array<ActionRowProps> = useMemo(() => {
    return generateActions(
      ['bronze', 'silver', 'gold', 'platinum'],
      metalLevels,
      setMetalLevel,
      () => setMetalLevel([]),
      closeSheet,
    );
  }, [metalLevels]);

  const otherActions: Array<ActionRowProps> = useMemo(() => {
    return generateActions(
      ['HSA_eligible', 'Easy_Pricing'],
      other,
      setOther,
      () => setOther([]),
      closeSheet,
    );
  }, [other]);

  const actions: Record<OptionType, Array<ActionRowProps>> = useMemo(
    () => ({
      sort: sortActions,
      planType: planTypeActions,
      metalLevel: metalLevelActions,
      other: otherActions,
    }),
    [sortActions, planTypeActions, metalLevelActions, otherActions],
  );

  return {
    sheet,
    openSheet,
    closeSheet,
    sortMethod,
    metalLevels,
    planTypes,
    other,
    actions,
    resetFilters,
  };
};
