import React from 'react';
import { Layout } from '@layouts';
import { Loading } from '@uikit';

const LoaderSheet = ({ accentColor, title, subtitle }) => {
  return (
    <Layout topSpace center margins>
      <Layout.Header align="center" title={title} subtitle={subtitle} />
      <Loading accentColor={accentColor} />
    </Layout>
  );
};

export default LoaderSheet;
