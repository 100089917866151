import { gql } from '../generated';

export const NudgesQuery = gql(`
  query Nudges {
    viewer {
      id
      nudges {
        nudgeIdentifier
        isDismissed
        lastUpdated
      }
    }
  }
`);
