import gql from 'graphql-tag';

export const ConfirmIdentityOffline = gql`
  mutation ConfirmIdentityOffline {
    confirmVerifiedOffline {
      status
      idStatus
    }
  }
`;
