import { useCallback, useState, useEffect } from 'react';

import { ExistingApplicationInfo, ExistingApplicationStatus, Trinary } from '@types';
import { useQuery, useMutation, mutations, HealthLinkQueryData, queries, updates } from '@data';
import { createLogger, Segment, SegmentEvent } from '@app/utils';

import { useHealthIdentity } from './useHealthIdentity';

const Log = createLogger('health-link');

interface HealthLinkReturn {
  status: ExistingApplicationStatus;
  loading?: any;
  error?: string;
  refetch: (force?: boolean) => any;

  /**
   * Performs initial exchange lookup
   * @param force - force call even if we have results
   */
  lookup: (force?: boolean) => any;
  searching?: any;

  /** Signs CMS Privacy Agreements */
  agree: () => any;
  agreeing?: any;

  /** Submits initial idProof via useHealthIdentity() */
  confirmIdentity: () => any;

  /** Imports all exchange findings as enrollments */
  importAll: ({ onCompleted }) => any;
  importing?: any;
}

interface HealthLinkFlags {
  canSearch: boolean; // experimental
  needsAgreements: boolean;
  needsIdentity: boolean;
  callConfirmIdentity: boolean;
  shouldPromptLink: boolean;
  coverageState: any;
  isInExchange?: boolean;
  isImported?: boolean;
  hasApplications?: boolean;
  hasSearched?: boolean;
  hasActivePolicy?: Trinary;
  hasPreviousActivePolicy?: Trinary;
  hasSubmittedApplication?: Trinary;
  applications?: Array<ExistingApplicationInfo>;
  isSlasherFlow?: boolean;
}

interface UseHealthLinkReturn extends HealthLinkReturn, HealthLinkFlags {}

/** TEMP! @todo */
const _fix_trinary = (item, data) => {
  if (!data) return null;
  else {
    return !!item ? 'YES' : 'NO';
  }
};

export const createHealthLinkFlags = ({
  existingAppInfo,
  viewer,
  user,
  hasSearched,
}): HealthLinkFlags => {
  const _existn = existingAppInfo;
  const _identy = viewer?.identity;
  const _userif = user;

  // in order to search we need:
  // name, DOB, ssn, address and one of email|phone
  const canSearch =
    !!_userif?.ssn &&
    !!_userif?.dob &&
    !!_userif?.legalAddress?.street1 &&
    !!_userif?.givenName &&
    !!_userif?.familyName &&
    (!!_userif?.phoneNumber || !!_userif?.email);

  const agreements = viewer?.agreements;
  const applications = _existn?.existingApplications;
  const hasApplications = !!applications && applications?.length > 0;
  const isInExchange = !!hasSearched && !!hasApplications;
  const coverageState = applications?.[0]?.state;
  const callConfirmIdentity = !viewer?.identity;
  const needsIdentity = !viewer?.identity || viewer?.identity?.status !== 'VERIFIED';
  const idpDenied = /PENDING|DENIED/.test(_identy?.status);
  const needsAgreements = !agreements?.isPrivacyDataUseAgree || !agreements?.isPrivacyTruthfulAgree;
  const isImported = hasApplications && !needsIdentity && !needsAgreements;
  const isSlasherFlow = _userif?.signupContext === 'PREMIUM_SLASHER';

  return {
    canSearch,
    applications,
    coverageState,
    hasApplications,
    hasSearched,
    isInExchange,
    isImported,
    shouldPromptLink: (!!isInExchange && !isImported && !idpDenied) || !hasSearched,
    hasActivePolicy: _fix_trinary(_existn?.hasActivePolicy, viewer),
    hasPreviousActivePolicy: _fix_trinary(_existn?.hasPreviousActivePolicy, viewer),
    hasSubmittedApplication: _fix_trinary(_existn?.hasSubmittedApplication, viewer),
    needsIdentity,
    callConfirmIdentity,
    needsAgreements,
    isSlasherFlow,
  };
};

/**
 *
 * Fastlink
 * useHealthLink hook
 * @author @aa-io
 * @see https://www.notion.so/catchco/Fastlink-133739dc638944599fe5721aefc76909
 *
 */
export const useHealthLink = ({ lookupOnMount = false } = {}): UseHealthLinkReturn => {
  const [error, setError] = useState('');

  const { data, loading, refetch } = useQuery<HealthLinkQueryData>(queries.HEALTH_LINK, {
    fetchPolicy: 'cache-and-network',
  });

  // new as of E-3773
  const hasSearched = !!data?.viewerTwo?.existingApplicationInfo?.existingApplications;
  const explorerIncome = data?.viewerTwo?.health?.healthExplorerData?.income;

  const flags = createHealthLinkFlags({
    existingAppInfo: data?.viewerTwo?.existingApplicationInfo,
    viewer: data?.viewerTwo,
    user: data?.viewer?.user,
    hasSearched,
  });

  /** @private */
  const _error = (err) => {
    setError(err);
    Log.warn(err);
  };

  /** @private */
  const { confirmIdentity: _confirmIdentity, status: idpStatus } = useHealthIdentity();

  /** @private */
  const [_lookup, { loading: searching }] = useMutation(mutations.HEALTH_LINK_LOOKUP, {
    update: updates.UPDATE_EXISTING_APPLICATIONS,
    refetchQueries: [queries.HEALTH_LINK],
    onCompleted: (res) => {
      Log.debug('Search complete.');
      Segment.simpleTrack(SegmentEvent.HEALTH_LINK_LOOKUP);
    },
  });

  /** @private */
  const [_importAll, { loading: importing }] = useMutation(mutations.HEALTH_LINK_IMPORT, {
    update: updates.UPDATE_EXISTING_APPLICATIONS,
    refetchQueries: [queries.HEALTH_LINK],
  });

  /**
   *
   * 01
   * Exchange Lookup
   * @public
   *
   */
  const lookup = useCallback(
    (force) => {
      if (force || !hasSearched) {
        Log.info('Searching exchange...');
        return _lookup();
      } else Log.debug('Skipped exchange search');
    },
    [_lookup, hasSearched],
  );

  /**
   *
   * 02
   * Sign CMS Privacy Agreements
   * @public
   *
   * action: agree
   * loading: agreeing
   */
  const [agree, { loading: agreeing }] = useMutation(mutations.HEALTH_INITIAL_AGREE, {
    update: updates.UPDATE_AGREEMENTS,
    refetchQueries: [queries.HEALTH_LINK],
    onCompleted: (res) => {
      Log.debug('Health agreements complete.');
    },
    variables: {
      input: {
        isPrivacyDataUseAgree: 'YES',
        isPrivacyTruthfulAgree: 'YES',
        isPrivacyAuthorizeAgree: 'YES',
      },
    },
  });

  /**
   *
   * 03
   * ID Proof Callback
   * @public
   *
   * action: checkIdentity
   */
  const confirmIdentity = useCallback(() => {
    // only call confirm identity if there is no identity status at all
    if (flags?.callConfirmIdentity) {
      Log.debug('Checking with Experian...');
      _confirmIdentity();
    } else {
      Log.debug('Skipped IDP');
    }
  }, [_confirmIdentity, flags]);

  /**
   *
   * 04
   * Import All as Enrollments
   * @public
   *
   * action: importAll
   * loading: importing
   */
  const importAll = ({ onCompleted }) => {
    Log.info('Importing policies...');
    if (flags?.needsIdentity) {
      _error(`Cannot import policies. IDP ${idpStatus?.toLowerCase()}.`);
    } else if (flags?.needsAgreements) {
      _error('Cannot import policies. Agreements needed.');
    }
    _importAll({
      onCompleted: (res) => {
        const _flags = createHealthLinkFlags({
          existingAppInfo: res?.importAllExistingApplications,
          viewer: data?.viewerTwo,
          user: data?.viewer?.user,
          hasSearched,
        });
        if (!_flags?.isInExchange) {
          Segment.simpleTrack(SegmentEvent.HEALTH_LINK_EXITED);
          _error('Nothing to import');
        } else {
          Log.debug('Import complete.');
        }

        onCompleted(res);
      },
    });
  };

  /**
   * Handle hook param lookupOnMount
   */
  useEffect(() => {
    if (!!lookupOnMount && !hasSearched && !!flags?.canSearch) lookup(true);
  }, [lookupOnMount, hasSearched, flags]);

  /**
   * Return
   */
  return {
    status: 'UNKNOWN',
    error,
    refetch,
    explorerIncome,
    ...flags,

    // Actions
    lookup,
    confirmIdentity,
    importAll,
    agree,

    // Loadings
    loading,
    searching,
    importing,
    agreeing,

    // reference
    isOpenEnrollment: data?.reference?.health?.openEnrollmentDates?.isOpenEnrollment,
  };
};
