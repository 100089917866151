// applies either boolean value or function
const booleanOrFunction = (val, dependentValues = {}, defaultValue) => {
  switch (typeof val) {
    case 'boolean':
      return val;
    case 'function':
      return val(dependentValues);
    default:
      return defaultValue; // default to true otherwise
  }
};

const valOrFunction = (val, dependentValues = {}) => {
  switch (typeof val) {
    case 'function':
      return val(dependentValues);
    default:
      return val;
  }
};

// determines whether the form element should be visible or hidden
const display = (config, dependentValues = {}) =>
  booleanOrFunction(config.display, dependentValues, true);

// determines whether the form element should be required or not
const required = (config, dependentValues = {}) =>
  booleanOrFunction(config.required, dependentValues, false);

const optional = (config, dependentValues = {}) =>
  booleanOrFunction(config.optional, dependentValues, false);

const label = (config, dependentValues = {}, hideLabel = false) => {
  if (hideLabel) return '';
  return valOrFunction(config.label, dependentValues);
};

const sublabel = (config, dependentValues = {}, hideLabel = false) => {
  if (hideLabel) return '';
  return valOrFunction(config.sublabel, dependentValues);
};

const help = (config, dependentValues = {}) => {
  return valOrFunction(config.help, dependentValues);
};

const doesNotExistIn = (config, dependentValues = {}) => {
  return valOrFunction(config.doesNotExistIn, dependentValues);
};

const secondary = (config, dependentValues = {}) => {
  return {
    checked: valOrFunction(config?.secondary?.checked, dependentValues),
    unchecked: valOrFunction(config?.secondary?.unchecked, dependentValues),
  };
};

const filePrefix = (config, dependentValues = {}) => {
  return valOrFunction(config.filePrefix, dependentValues);
};

const value = (config, dependentValues = {}) => {
  return valOrFunction(config.value, dependentValues);
};

export const dynamic = {
  display,
  required,
  optional,
  label,
  sublabel,
  help,
  secondary,
  doesNotExistIn,
  filePrefix,
  value,
};
