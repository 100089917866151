import createIconComponent from '../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const DocumentUploadSuccess = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M8.7707 0C7.91467 0 7.2207 0.693952 7.2207 1.55V14.5181C7.62862 14.4577 8.04601 14.4265 8.4707 14.4265C8.89539 14.4265 9.31279 14.4577 9.7207 14.5181V2.5H26.0442V14.1265C26.0442 14.9825 26.7382 15.6765 27.5942 15.6765H37.3383V37.6471H9.7207V31.2761C9.31279 31.3364 8.89539 31.3676 8.4707 31.3676C8.04601 31.3676 7.62862 31.3364 7.2207 31.2761V38.5971C7.2207 39.4531 7.91466 40.1471 8.7707 40.1471H38.2883C39.1444 40.1471 39.8383 39.4531 39.8383 38.5971V14.4265C39.8383 14.1281 39.7316 13.8395 39.5374 13.613L28.2433 0.436511C28.0058 0.159453 27.6591 0 27.2942 0H8.7707ZM35.8706 13.1765L28.5442 4.62907V13.1765H35.8706Z',
      fill: '#A9C0C4',
    },
    {
      type: 'path',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M1.88235 22.8983C1.88235 26.5369 4.83201 29.4866 8.47059 29.4866C12.1092 29.4866 15.0588 26.5369 15.0588 22.8983C15.0588 19.2597 12.1092 16.3101 8.47059 16.3101C4.83201 16.3101 1.88235 19.2597 1.88235 22.8983ZM8.47059 14.4277C3.79241 14.4277 0 18.2201 0 22.8983C0 27.5765 3.79241 31.3689 8.47059 31.3689C13.1488 31.3689 16.9412 27.5765 16.9412 22.8983C16.9412 18.2201 13.1488 14.4277 8.47059 14.4277Z',
      fill: '#A9C0C4',
    },
    {
      type: 'path',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M12.3349 21.8521C12.672 21.5022 12.6715 20.9353 12.3339 20.5859C11.9962 20.2366 11.4492 20.237 11.1121 20.587L7.66805 24.1622L6.47531 22.924C6.13821 22.5741 5.59121 22.5736 5.25355 22.923C4.91588 23.2723 4.91543 23.8392 5.25253 24.1892L7.0547 26.06C7.11292 26.1204 7.1774 26.1704 7.24598 26.21C7.57534 26.4017 8.00005 26.3521 8.28 26.0615L12.3349 21.8521Z',
      fill: '#A9C0C4',
      stroke: '#A9C0C4',
      strokeWidth: '0.2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    {
      type: 'path',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M12.2619 20.6553C11.9636 20.3467 11.4818 20.3471 11.184 20.6563L7.73997 24.2315C7.72112 24.2511 7.69512 24.2621 7.66795 24.2621C7.64078 24.2621 7.61478 24.2511 7.59593 24.2315L6.4032 22.9933C6.10538 22.6842 5.62366 22.6837 5.32535 22.9924C5.02511 23.303 5.0247 23.8085 5.32445 24.1197L7.12662 25.9905C7.17834 26.0442 7.2354 26.0884 7.29587 26.1233L7.29617 26.1235C7.58622 26.2923 7.96032 26.249 8.20788 25.992L12.2628 21.7827C12.5625 21.4715 12.5621 20.966 12.2619 20.6553ZM11.04 20.5175C11.4164 20.1268 12.0286 20.1263 12.4057 20.5163C12.7807 20.9044 12.7812 21.5327 12.4068 21.9214L8.35192 26.1307C8.03963 26.4549 7.56439 26.5109 7.19574 26.2965C7.1191 26.2522 7.04726 26.1964 6.98258 26.1293L5.18041 24.2585C4.80596 23.8697 4.80646 23.2415 5.18154 22.8534C5.55857 22.4633 6.17085 22.4638 6.54724 22.8546L7.66795 24.018L11.04 20.5175Z',
      fill: '#A9C0C4',
      stroke: '#A9C0C4',
      strokeWidth: '0.2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  defs: {},
  name: 'Success',
  width: '40',
  height: '41',
  viewBox: '0 0 40 41',
});
DocumentUploadSuccess.displayName = 'DocumentUploadSuccess';

/** @deprecated */
export default DocumentUploadSuccess;
