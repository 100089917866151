import { themeColorsDark, themeColorsLight } from './colors';
import { elements } from './elements';
import { spacing } from './spacing';
import { theme as newTheme } from '@app/_ui-kit/themes/catch';
import { typeSizeSmall, typeLineHeight, fontFallback } from './_constants';

export const fontFamilyFix = (size, signature = false) => {
  return {
    fontFamily: `${
      signature
        ? newTheme.font.face.Hand
        : /h0|h1|xl|xxl|lg|page/.test(size) || size >= 24
        ? newTheme.font.face.Title
        : /h2|h3|h4|form/.test(size) || size > 17
        ? newTheme.font.face.Mid
        : newTheme.font.face.Body
    }, ${fontFallback}`,
  };
};

const getWeight = (weight, size) => {
  return {
    fontWeight: weight,
    maxWidth: '100%',
  };
};

const getRules = (size, lineHeight, weight) => ({
  fontSize: size,
  lineHeight: `${lineHeight}px`,
  ...getWeight(weight, size),
});

export const typography = {
  // auto curry if weight is missing
  get: (size, lineHeight, weight) =>
    !weight ? (weight) => getRules(size, lineHeight, weight) : getRules(size, lineHeight, weight),
  getWeight,
};

const base = {
  lightText: typography.getWeight('300'),
  regularText: typography.getWeight('400'),
  mediumText: typography.getWeight('500'),
  semiboldText: typography.getWeight('600'),
  boldText: typography.getWeight('700'),
  extraBoldText: typography.getWeight('800'),
  signatureText: typography.getWeight('HW'),
  monoText: typography.getWeight('CODE'),
};

export class Theme {
  loadRules(rules) {
    Object.keys(rules).forEach((key) => {
      this[key] = rules[key];
    });
  }
  loadSpacing(spacing) {
    this.loadRules(spacing);
    return this;
  }
  loadBase(elements) {
    this.loadRules(elements);
    return this;
  }
  loadTypes(types) {
    Object.keys(types).forEach((key) => {
      // Only add a gutter on headings
      this[key] = // TODO: refactor this more elegantly
        key === 'h6'
          ? {
              ...types[key],
              ...this.spacedText,
            }
          : key === 'label'
          ? {
              ...types[key],
            }
          : /h/.test(key)
          ? {
              ...types[key],
            }
          : types[key];
    });
    return this;
  }
  loadLayouts() {
    this.header = {
      ...this.topSpace,
      ...this.margins,
      // TODO: check the responsiveness of this value with Zack
      paddingBottom: 40,
      width: '100%',
    };
    this.section = {
      ...this.topSpace,
      ...this.margins,
    };
    return this;
  }
}

const createTypeRules = ({
  size: { h0, h1, h2, h3, h4, h5, h6, btn, p, fp, l, sm, xs, xxl },
  lineHeight,
}) => {
  const getH0 = typography.get(h0, lineHeight.h0);
  const getH1 = typography.get(h1, lineHeight.h1);
  const getH2 = typography.get(h2, lineHeight.h2);
  const getH3 = typography.get(h3, lineHeight.h3);
  const getH4 = typography.get(h4, lineHeight.h4);
  const getH5 = typography.get(h5, lineHeight.h5);

  return {
    h0: getH0('700'),
    h0s: getH0('700S'),
    h1: getH1('700'),
    h2: getH2('700'),
    h2s: getH2('700S'),
    h3: getH3('700'),
    h3s: getH3('700S'),
    h4: getH4('700'),
    h5: getH5('700'),
    h6: {
      ...typography.get(h6, lineHeight.h6, '500'),
      textTransform: 'uppercase',
      letterSpacing: 0.75,
    },
    btn: typography.get(btn, lineHeight.btn, '500'),
    p: typography.get(p, lineHeight.p, '400'),
    pl: {
      ...typography.get(p, lineHeight.p, '500'),
    },
    fp: typography.get(fp, lineHeight.fp, '400'),
    fpl: {
      ...typography.get(fp, lineHeight.fp, '500'),
    },
    label: typography.get(l, lineHeight.l, '500'),
    sm: typography.get(sm, lineHeight.sm, '400'),
    lg: typography.get(h4, lineHeight.h4, '400'),
    xl: typography.get(h0, lineHeight.h0, '700'),
    xs: typography.get(xs, lineHeight.xs, '400'),
    xxl: typography.get(xxl, lineHeight.xxl, '500'),
  };
};

const singleType = createTypeRules({
  size: typeSizeSmall,
  lineHeight: typeLineHeight,
});

const light = new Theme()
  .loadSpacing(spacing)
  .loadTypes(singleType)
  .loadBase({ ...base, ...elements(themeColorsLight) })
  .loadLayouts();

const dark = new Theme()
  .loadSpacing(spacing)
  .loadTypes(singleType)
  .loadBase({ ...base, ...elements(themeColorsDark) })
  .loadLayouts();

export const lightThemes = {
  xsTheme: light,
  sTheme: light,
  mTheme: light,
  lTheme: light,
};

export const darkThemes = {
  xsTheme: dark,
  sTheme: dark,
  mTheme: dark,
  lTheme: dark,
};
