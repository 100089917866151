import createIconComponent from '../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Trash = createIconComponent({
  content: [
    {
      type: 'title',
      children: 'Delete',
    },
    {
      type: 'path',
      d: 'M15.3718 21.3748C17.3381 21.3748 18.9222 20.4455 18.9998 18.6417L19.5418 6.91632H21.4823C21.7669 6.91632 21.9998 6.7027 21.9998 6.44161C21.9998 6.18052 21.7669 5.9669 21.4823 5.9669H16.049V4.89881C16.049 3.85445 15.1176 2.99997 13.9792 2.99997H10.0465C8.90806 2.99997 7.97663 3.85445 7.97663 4.89881V5.9669H2.51744C2.23284 5.9669 1.99998 6.18052 1.99998 6.44161C1.99998 6.7027 2.23284 6.91632 2.51744 6.91632H4.45791L4.99995 18.6417C5.07757 20.4455 6.65 21.3748 8.61634 21.3748H15.3718ZM9.01155 4.89881C9.01155 4.37663 9.47727 3.94939 10.0465 3.94939H13.9792C14.5484 3.94939 15.0141 4.37663 15.0141 4.89881V5.9669H9.01155V4.89881ZM6.06074 18.5942L5.5187 6.89259H18.5328L17.9649 18.5942C17.9131 19.8759 16.7689 20.4254 15.3718 20.4254C15.3718 20.4254 10.0394 20.4254 8.64222 20.4254C7.24508 20.4254 6.11249 19.8996 6.06074 18.5942Z',
      fill: '#1F2533',
      stroke: '#1F2533',
      strokeWidth: '0.5',
    },
    {
      type: 'path',
      d: 'M12.0139 17.8587C12.2985 17.8587 12.5314 17.6451 12.5314 17.384V10.2871C12.5314 10.026 12.2985 9.8124 12.0139 9.8124C11.7293 9.8124 11.4965 10.026 11.4965 10.2871V17.384C11.4965 17.6451 11.7293 17.8587 12.0139 17.8587Z',
      fill: '#1F2533',
      stroke: '#1F2533',
      strokeWidth: '0.5',
    },
    {
      type: 'path',
      d: 'M8.90849 17.8587C9.19309 17.8587 9.42595 17.6451 9.42595 17.384V10.2871C9.42595 10.026 9.19309 9.8124 8.90849 9.8124C8.62389 9.8124 8.39103 10.026 8.39103 10.2871V17.384C8.39103 17.6451 8.62389 17.8587 8.90849 17.8587Z',
      fill: '#1F2533',
      stroke: '#1F2533',
      strokeWidth: '0.5',
    },
    {
      type: 'path',
      d: 'M15.1169 17.8587C15.4015 17.8587 15.6344 17.6451 15.6344 17.384V10.2871C15.6344 10.026 15.4015 9.8124 15.1169 9.8124C14.8323 9.8124 14.5995 10.026 14.5995 10.2871V17.384C14.5995 17.6451 14.8323 17.8587 15.1169 17.8587Z',
      fill: '#1F2533',
      stroke: '#1F2533',
      strokeWidth: '0.5',
    },
  ],
  id: '4845%3A114',
  name: 'Trash',
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  colorable: true,
});
Trash.displayName = 'Trash';

/** @deprecated */
export default Trash;
