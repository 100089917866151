const fragmentTypes = {
  ErrorItem: ['DocumentUploadError', 'Error', 'EDEValidationErrorItem'],
  Event: [
    'TransferEvent',
    'DividendReceivedEvent',
    'FeePaidEvent',
    'RolloverEvent',
    'GoalPausedEvent',
    'GoalResumedEvent',
    'GoalUpdatedEvent',
    'GoalAddedEvent',
    'GoalMetEvent',
    'GoalRemovedEvent',
    'StatementAvailableEvent',
    'AutopilotRuleResumedEvent',
    'AutopilotRuleAddedEvent',
    'AutopilotRulePausedEvent',
    'AutopilotRuleRemovedEvent',
    'CatchFeePaymentEvent',
    'PaymentEvent',
    'HealthApplicationEvent',
    'HealthEnrollmentEvent',
  ],
  Goal: [
    'EmergencySavingsGoal',
    'FamilyLeaveGoal',
    'HealthExpensesGoal',
    'PTOGoal',
    'RetirementGoal',
    'TaxGoal',
    'CustomGoal',
  ],
  HealthApp: ['HealthInsuranceApplication', 'HealthApplication', 'PartnerHealthApplication'],
  InsuranceEnrollment: ['HealthInsuranceEnrollment'],
  InsurancePolicy: ['HealthPolicy'],
  Statement: ['BankStatement', 'WealthStatement'],
  Upload: ['Document', 'DocumentUpload'],
  WalletItem: ['DentalWalletItem', 'VisionWalletItem', 'LifeWalletItem', 'HealthWalletItem'],
};

export default fragmentTypes;
