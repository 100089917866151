import React from 'react';
import { Route } from '@types';
import { useCopy } from '@app/utils';
import { BasicFormBlueprint } from '@blueprints';
import { navigate, ScreenDefinition } from '@app/navigate';
import { useAgreement } from '@app/hooks';
import { AgreementType, GET_FEATURE_FLAGS, SIGN_AGREEMENTS, useMutation } from '@data';
import { Button } from '@app/_ui-kit';
import { HXAetnaCvsHealthLogo } from '@app/features/onboarding/AetnaAttestationView';

const ExplorerAetnaAttestation = () => {
  const { c } = useCopy('catch.aetnaAttestation');

  const [signAgreements, { loading }] = useMutation(SIGN_AGREEMENTS, {
    refetchQueries: [GET_FEATURE_FLAGS],
    awaitRefetchQueries: true,
    onCompleted: () => navigate(Route.GOALS),
  });
  const consent = useAgreement(AgreementType.AttestedAetnaCoverage);

  const consentFields = [
    {
      type: 'fieldset',
      name: 'agreementFieldset',
      fields: [
        {
          type: 'checkbox',
          name: 'alreadyAetna',
          required: true,
          plain: true,
          label: c('checkboxLabel'),
        },
      ],
    },
  ];

  const handleSubmit = () => {
    const input = [
      {
        agreementType: consent.agreement?.agreementType as AgreementType,
        version: consent.agreement?.version as string,
      },
    ];
    signAgreements({ variables: { input } });
  };

  return (
    <BasicFormBlueprint
      loading={consent.loading || loading}
      precomponent={
        <div style={styles.container}>
          <HXAetnaCvsHealthLogo aria-label="Aetna CVS Health®" />
        </div>
      }
      title={c('title')}
      subtitle={c('subtitle')}
      actions={({ handleSubmit, disabled }) => [
        <Button key="submit" testID="aetna-attestation" onPress={handleSubmit} disabled={disabled}>
          Next
        </Button>,
      ]}
      onBack={() => navigate(Route.EXPLORER_STATE_HANDOFF)}
      formConfig={{
        fields: consentFields,
        onSubmit: handleSubmit,
      }}
    />
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',
  },
};

export const ExplorerAetnaAttestationView: ScreenDefinition = {
  name: Route.AETNA_ATTESTATION,
  component: ExplorerAetnaAttestation,
  options: {},
};
