import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation, HealthApplicationQuery, getMembers } from '@data';
import { SplitFormBlueprint } from '@blueprints';
import { Segment, SegmentEvent, useCopy } from '@util';
import { fields } from './deductionListFields';
import { formatPayload, getInitialValues } from './deductionListUtils';
import { Route } from '@app/types';

const PREFIX = 'catch.ede.DeductionList';

const UpsertDeductionListMembers = gql`
  mutation UpsertDeductionListMembers($input: UpsertApplicationMembersInput!) {
    upsertApplicationMembers(input: $input) {
      id
      hasDeductions
    }
  }
`;

const DeductionList = ({ applicationID, handleNext }) => {
  const { c } = useCopy(PREFIX);

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { members, coverageYear } = useMemo(
    () => ({
      coverageYear: data?.viewerTwo?.health?.application?.coverageYearNumber,
      members: getMembers(data),
    }),
    [data],
  );

  const initialValues = useMemo(() => {
    return { members: getInitialValues(members) };
  }, [members]);

  const [upsert, { loading: upserting }] = useMutation<
    {
      upsertApplicationMembers: Array<{
        id: string;
        hasDeductions: boolean;
        __typename: 'ApplicationMember';
      }>;
    },
    {
      input: {
        applicationID: string;
        applicationMembersInput: Array<{ id: string; hasDeductions: boolean }>;
      };
    }
  >(UpsertDeductionListMembers);

  const onSubmit = async (values) => {
    try {
      upsert({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: formatPayload(values),
          },
        },
        optimisticResponse: {
          upsertApplicationMembers: values?.members?.map((m) => ({
            id: m.id,
            hasDeductions: m.hasDeductions,
            __typename: 'ApplicationMember',
          })),
        },
      });

      const hasAnyIncome = members?.some((m) => m?.incomeThisMonth);
      const hasAnyDeductions = values?.members?.some((m) => m.hasDeductions); // use the form values

      Segment.track(SegmentEvent.APPLICATION_DEDUCTIONS_ASKED, {
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
        has_any_deductions: hasAnyDeductions,
      });

      handleNext({ hasAnyIncome, hasAnyDeductions });
    } catch (e) {}
  };

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={upserting}
      title={c('title')}
      subtitle={c('subtitle', { coverageYear })}
      formConfig={{
        initialValues,
        fields,
        onSubmit,
      }}
    ></SplitFormBlueprint>
  );
};

export const DeductionListView = {
  name: Route.EDE_MEMBER_DEDUCTIONS_LIST,
  component: DeductionList,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Deductions',
  },
};
