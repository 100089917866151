import createIconComponent from '../../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const DistrictofColumbia = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      d: 'M39.7292 114.043L39.7314 114.116L35.5407 113.573L28.0969 110.803L24.8367 108.754L17.8546 102.492L15.809 100.323L13.1099 92.8572L6.49718 85.5107L3.52109 81.0541L2.3065 76.8364L0.445557 74.5484L73.7397 0.591797L196.548 124.545L76.1974 246.55L75.9204 235.349L78.8042 218.368L76.6662 217.522L75.7357 210.539L76.6662 201.865L75.6434 197.532L73.7824 195.726L72.4755 195.602L71.6374 196.328L70.3376 194.518L70.2381 189.942L70.9839 187.897H73.3137L81.6027 186.087L82.6255 175.493L82.6515 175.473L75.5725 158.856L75.9489 157.051L75.7643 154.28L74.365 150.55L71.8506 147.177L69.8973 145.73L68.7822 146.093L69.7126 148.381L69.0592 150.426L66.4524 151.152L64.4068 148.138L64.5915 145.73L61.8001 141.035L60.7701 139.95L57.8864 139.877L53.0493 129.64L49.505 116.873L48.674 116.151L46.8983 115.067L39.8238 114.162L39.7292 114.043Z',
      fill: '#1F2533',
      colorable: true,
    },
  ],
  name: 'DistrictofColumbia',
  width: '197',
  height: '247',
  viewBox: '0 0 197 247',
});
DistrictofColumbia.displayName = 'DistrictofColumbia';

/** @deprecated */
export default DistrictofColumbia;
