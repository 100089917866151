import { MaritalStatus } from './../../../../data/generated/graphql';
import { getMembers, UpsertHealthApplicationInput } from '@app/data';
import { HealthMaritalStatus, HealthTaxReturnFilingStatusType, Trinary } from '@app/types';
import { boolToTri } from '@app/utils';

interface FormValues {
  members: Array<{
    id: string;
    fullName: string;
    relation: 'SELF' | 'SPOUSE' | 'CHILD';
    isRequestingCoverage: boolean;
  }>;

  isSelfCoverage: boolean;
  isSpouseCoverage: boolean;
  numTaxDependents: number;
  numDependentsRequestCoverage: number;
  maritalStatus?: HealthMaritalStatus;
  numApplicants: number;
}

interface PayloadValues {
  input: {
    applicationID: string;
    applicationMembersInput: Array<{
      id?: string;
      relation: 'SELF' | 'SPOUSE' | 'CHILD';
      isRequestingCoverage: Trinary;
      isTaxDependent: Trinary;
      isTaxFiler: Trinary;
      maritalStatus?: HealthMaritalStatus;
      taxReturnFilingStatusType?: HealthTaxReturnFilingStatusType;
      isClaimsDependent?: Trinary;
      isOfferedIchra: Trinary;
      isEnrolledInIchra: Trinary;
    }>;
  };
}

// helper function to see if the user should re-answer screening questions
export const checkForRescreening = ({ values }): boolean => {
  const hasSpouse = values?.members?.some((m) => m.relation === 'SPOUSE');
  const numTaxDependents = values?.members?.filter((m) => m.relation === 'CHILD')?.length;

  // if marital status was SINGLE and now theres a spouse, rescreen
  if (values.maritalStatus === 'SINGLE' && hasSpouse) {
    return true;
  }

  // if tax dependents has increased, rescreen
  if (numTaxDependents > values.numTaxDependents) {
    return true;
  }

  return false;
};

export const getInitialValues = (data?: HealthApplicationQuery): FormValues => {
  const members = getMembers(data);

  const {
    id: applicationID,
    maritalStatus,
    isSelfCoverage,
    isSpouseCoverage,
    numTaxDependents,
    numDependentsRequestCoverage,
  } = data?.viewerTwo.health.application || {};

  const numApplicants =
    (!!isSelfCoverage ? 1 : 0) + (!!isSpouseCoverage ? 1 : 0) + (numDependentsRequestCoverage || 0);

  return {
    members: members.map((m) => ({
      id: m.id,
      applicationID,
      fullName: m.legalName || '',
      relation: m.relation || 'SELF',
      isRequestingCoverage: !!m.isRequestingCoverage,
    })),
    isSelfCoverage: !!isSelfCoverage,
    isSpouseCoverage: !!isSpouseCoverage,
    maritalStatus,
    numTaxDependents: numTaxDependents || 0,
    numDependentsRequestCoverage: numDependentsRequestCoverage || 0,
    numApplicants,
  };
};

export const formatMembersPayload = ({
  values,
  applicationID,
}: {
  values: FormValues;
  applicationID: string;
}): PayloadValues => {
  const hasSpouse = values?.members?.some((m) => m.relation === 'SPOUSE');
  const numDependents = values?.members?.filter((m) => m.relation === 'CHILD')?.length;

  // only use these fields for self/spouse
  const filers = {
    maritalStatus: hasSpouse ? 'MARRIED' : 'SINGLE',
    taxReturnFilingStatusType: hasSpouse ? 'MARRIED_FILING_JOINTLY' : 'SINGLE_FILER',
    isClaimsDependent: numDependents > 0 ? 'YES' : 'NO',
    isTaxDependent: 'NO',
    isTaxFiler: 'YES',
  };

  return {
    input: {
      applicationID,
      applicationMembersInput: values.members.map((m) => {
        const type = /SELF|SPOUSE/.test(m.relation) ? filers : {};

        return {
          ...type,
          id: m?.id,
          relation: m.relation,
          isRequestingCoverage: boolToTri(m.isRequestingCoverage),
          isTaxDependent: /CHILD/.test(m.relation) ? 'YES' : 'NO',
          isTaxFiler: /CHILD/.test(m.relation) ? 'NO' : 'YES',
          isOfferedIchra: 'NO',
          isEnrolledInIchra: 'NO',
        };
      }),
    },
  };
};

export const formatApplicationPayload = ({
  values,
  applicationID,
}: {
  values: FormValues;
  applicationID: string;
}): UpsertHealthApplicationInput => {
  const dependents = values?.members?.filter((m) => m.relation === 'CHILD');
  const self = values?.members?.find((m) => m.relation === 'SELF');
  const spouse = values?.members?.find((m) => m.relation === 'SPOUSE');

  return {
    id: applicationID,
    maritalStatus: !!spouse ? MaritalStatus.Married : MaritalStatus.Single,
    isSelfCoverage: boolToTri(!!self?.isRequestingCoverage),
    isSpouseCoverage: boolToTri(!!spouse?.isRequestingCoverage),
    numTaxDependents: dependents.length,
    numDependentsRequestCoverage: dependents?.filter((m) => m.isRequestingCoverage)?.length,
  };
};
