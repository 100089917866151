import React from 'react';

interface Props {
  loading: Boolean;
  url?: string;
  saml?: string;
  onReturn: () => void;
  children: ({ redirect }) => React.ReactElement;
}

const PaymentRedirectForm: React.FC<Props> = ({ loading, url, saml, onReturn, children }) => {
  const redirect = () => {
    // @ts-ignore
    document.getElementById('paymentRedirect').submit();
    onReturn();
  };

  return (
    <form id="paymentRedirect" method="post" target="_blank" action={url}>
      <input id="saml" type="hidden" name="SAMLResponse" value={saml} />
      {children({ redirect })}
    </form>
  );
};

export default PaymentRedirectForm;
