import React from 'react';
import { SheetComponentProps } from '@navigate';
import { SheetBlueprint } from '@blueprints';
import { useCopy } from '@app/utils';

const VoterInfoSheet: React.FC<SheetComponentProps['VOTER_INFO']> = ({ handleNext }) => {
  const { c } = useCopy('catch.ede');

  return (
    <SheetBlueprint
      subtitle={c('HealthEnrollment.310_Q')}
      actions={[
        {
          testID: 'continue',
          type: 'CUSTOM',
          onAction: () => handleNext(),
          label: c('HealthEnrollment.310_btn'),
          button: true,
        },
      ]}
    />
  );
};

export default VoterInfoSheet;
