import React, { useMemo } from 'react';
import { ScreenDefinition } from '@navigate';
import { RootLayout } from '@layouts';
import { Prompts } from '@common';

import { currentYear } from '@util';
import { useQuery, useFintechQuery, GET_PAGED_INCOME, GET_HOME } from '@data';
import { getPrompts } from '@app/utils';
import { useDebug } from '@app/hooks';

const HomeComponent = () => {
  const { loading, data } = useQuery(GET_HOME, {
    variables: {
      year: currentYear,
      taxYear: 2024,
      prevTaxYear: 2023,
      coverageYears: [currentYear, currentYear + 1],
    },
  });

  const income = useFintechQuery(GET_PAGED_INCOME, {
    variables: { first: 10 },
  });

  const { showLayout } = useDebug();

  const prompts = useMemo(() => {
    return data ? getPrompts(data, income?.data, { isDebug: showLayout }) : [];
  }, [data, income?.data, showLayout]);

  return (
    <RootLayout full loading={loading || income.loading} title="Home" color="pageBg">
      <Prompts prompts={prompts} data={data} />
    </RootLayout>
  );
};

export const HomeView: ScreenDefinition = {
  name: 'HOME',
  component: HomeComponent,
  options: {
    title: 'Home',
    largeTitle: true,
    drawBehindNav: true,
    bg: 'page',
    buttons: {
      account: true,
      activity: true,
    },
  },
};

export default HomeView;
