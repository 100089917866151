import React from 'react';

import { RootLayout, Stack, Layout } from '@layouts';
import type { Action } from '@types';
import { SheetConfig } from '@navigate';
import PlanDetailTabs from './PlanDetailTabs';
import PlanSummary, { PlanSummaryProps } from './PlanSummary';

interface Props {
  loading: boolean;
  title: string;
  subtitle: string;
  color: string;
  controls: React.ReactElement;
  extra: React.ReactElement;
  menuItems?: Array<Action>;
  planSummary: PlanSummaryProps;
  leftColumnComponent: React.ReactElement;
  topRightComponent: React.ReactElement;
  goal: any;
  tabKey: any;
  details: any;
  additionalDetailTab: any;
  enrollmentID: string;
  content: React.ReactElement;
  sheetConfig: SheetConfig;
}

const PlanDetailLayout: React.FC<Props> = ({
  loading,
  title, // Copy
  subtitle,
  color,
  controls,
  extra,
  menuItems,
  planSummary,
  leftColumnComponent,
  topRightComponent,
  goal,
  tabKey,
  details,
  additionalDetailTab,
  enrollmentID,
  content,
  sheetConfig, // named so that it's clear we're not looking for a sheet name only
}) => {
  const hasPlanSummary =
    !!planSummary && (planSummary?.banners?.length > 0 || !!planSummary?.title);
  const shouldLiftPlanSummary = !!hasPlanSummary && !content;
  return (
    <RootLayout
      color={color}
      loading={loading}
      title={title}
      subtitle={subtitle}
      controls={controls}
      sheetConfig={sheetConfig}
      menuItems={menuItems}
      dynamicContentHeight={shouldLiftPlanSummary}
      content={
        content ? (
          content
        ) : hasPlanSummary && shouldLiftPlanSummary ? (
          <PlanSummary loading={loading} {...planSummary} />
        ) : undefined
      }
      extra={
        !!leftColumnComponent || !!extra || (hasPlanSummary && !shouldLiftPlanSummary) ? (
          <Stack spacing="1">
            {!!leftColumnComponent && leftColumnComponent}
            {!!extra && <Layout debuggable>{extra}</Layout>}
          </Stack>
        ) : undefined
      }
    >
      <Stack spacing="2">
        {!!topRightComponent && topRightComponent}
        <PlanDetailTabs
          benefit={goal}
          details={details}
          additionalDetailTab={additionalDetailTab}
          tabKey={tabKey}
          enrollmentID={enrollmentID}
          loading={loading}
          accentColor={color}
        />
      </Stack>
    </RootLayout>
  );
};

export default PlanDetailLayout;
