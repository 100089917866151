import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { env, getAuthToken } from '@global';

/**
 * Attaches tokens to the GQL headers
 * Note: this can be shared across multiple clients as needed
 */
const withToken = setContext(async () => {
  const token = getAuthToken();
  let headers = {};
  if (token) headers['Authorization'] = `Bearer ${token}`;
  return { headers };
});

const fintechLink = new HttpLink({ uri: env.FINTECH_URL, fetch });
const healthLink = new HttpLink({ uri: env.HEALTH_URL, fetch });

/**
 * Fintech client
 */
export const fintechClient = new ApolloClient({
  name: 'fintech',
  link: from([withToken, fintechLink]),
  cache: new InMemoryCache(),
  devtools: {
    name: 'fintech',
  },
});

/**
 * Health client
 */
export const healthClient = new ApolloClient({
  name: 'health',
  link: from([withToken, healthLink]),
  cache: new InMemoryCache(),
  devtools: {
    name: 'health',
  },
});

/**
 * Public client
 */
export const publicClient = new ApolloClient({
  name: 'public',
  uri: env.PUBLIC_URL,
  cache: new InMemoryCache(),
  devtools: {
    name: 'public',
  },
});

/**
 * Content client
 */
export const contentClient = new ApolloClient({
  name: 'hygraph',
  uri: env.GRAPHCMS_URL,
  cache: new InMemoryCache(),
  headers: {
    Authorization: env.HYGRAPH_TOKEN ? `Bearer ${env.HYGRAPH_TOKEN}` : '',
  },
  devtools: {
    name: 'hygraph',
  },
});
