import React from 'react';
import { Button } from '@uikit';
import { Toolbar, CalloutLayout } from '@layouts';
import { useCopy } from '@app/utils';

const PREFIX = 'catch.ede.CIC.govHandoff';

const CICGovHandoff = () => {
  const { c } = useCopy(PREFIX);

  return (
    <CalloutLayout
      render="coverage"
      title={c('title')}
      subtitles={[c('subtitle1'), c('subtitle2')]}
      toolbar={
        <Toolbar>
          <Button href="https://www.healthcare.gov/">{c('button')}</Button>
        </Toolbar>
      }
    />
  );
};

export const CICGovHandoffView = {
  name: 'CIC_GOV_HANDOFF',
  component: CICGovHandoff,
  options: {},
};
