import React, { useContext } from 'react';
import { FontSize, FontWeight, TextColor } from '@app/types';

interface TextContextProps {
  size?: FontSize | number;
  weight?: FontWeight;
  color?: TextColor;
}

const TextContext = React.createContext({
  size: undefined,
  weight: undefined,
  color: undefined,
});

/**
 * The text context is a way for us to inherit text properties from parents
 * For example: if we place a standard <Text> within a <Text size="fp">, then
 * the child should inherit the size (and same for properties for weight + color)
 */
export const TextProvider: React.FC<TextContextProps> = ({ size, weight, color, children }) => {
  return <TextContext.Provider value={{ size, weight, color }}>{children}</TextContext.Provider>;
};

export const useTextContext = () => {
  return useContext(TextContext);
};

export default TextProvider;
