import { close, ScreenDefinition } from '@navigate';
import { Layout, Toolbar, Stack, Page } from '@layouts';
import { Button, LineItem, Text } from '@uikit';
import { ConfirmationBlueprint } from '@blueprints';
import { safeFormatDate, useCopy } from '@util';
import { FontSize, FontWeight, Route, TextAlign } from '@types';
import { TransferAccount } from '../TransferStack';

import Check from '@svg/check-circle.svg';
import { CREATE_BANK_TRANSFER, CREATE_GOAL_TRANSFER, useFintechMutation } from '@app/data';

/**
 * View for showing transfer funds confirmation
 * - this page acts as confirmation before user decides to move money
 * - handles for either direction of movement + any goal type
 *
 * - NOTE: by the time we get to this page, transferType, transferAmount, and
 *   goal should all be specified
 */
const TransferConfirm = ({ source, destination, amount, direction }) => {
  const { c, $ } = useCopy('catch');

  const isGoal = (account: TransferAccount) => account?.__typename === 'Goal';

  const [createBankTransfer, bankTransfer] = useFintechMutation(CREATE_BANK_TRANSFER, {
    onError: () => console.warn('Error completing transfer'),
  });
  const [createGoalTransfer, goalTransfer] = useFintechMutation(CREATE_GOAL_TRANSFER, {
    onError: () => console.warn('Error completing transfer'),
  });

  const handleTransfer = () => {
    const transferAmount = amount * 100;

    // handle transferring between goals
    if (isGoal(source) && isGoal(destination)) {
      createGoalTransfer({
        variables: {
          input: {
            amount: transferAmount,
            fromGoalId: source.id,
            toGoalId: destination.id,
          },
        },
      });
    } else {
      const goal = isGoal(source) ? source : destination;
      const linkedAccount = !isGoal(source) ? source : destination;

      createBankTransfer({
        variables: {
          input: {
            goalBreakdown: [
              {
                amount: transferAmount,
                goalId: goal.id,
              },
            ],
            linkedAccountId: linkedAccount.id,
            totalAmount: transferAmount,
            direction,
          },
        },
      });
    }
  };

  return (
    <ConfirmationBlueprint
      called={bankTransfer.called || goalTransfer.called}
      loading={bankTransfer.loading || goalTransfer.loading}
      error={!!(bankTransfer.error || goalTransfer.error)}
      onSuccess={() => close()}
      titles={{
        error: 'Unable to complete transfer',
        loading: 'Loading',
        done: `$${amount} transfer complete`,
      }}
      subtitles={{
        error: 'Contact support for more information',
        done: 'Funds have been transferred ',
      }}
    >
      <Page color="sheet">
        <Layout.Scroll>
          <Layout margins topSpace bottomSpace>
            <Layout.Header
              align="center"
              svg={Check}
              title={`${$(amount)}`}
              subtitle="Transfer"
              titleSize="form"
            />
          </Layout>

          <Layout margins>
            <Stack separatorComponent spacing="1">
              <LineItem
                left={c('basics.from')}
                right={
                  <Stack>
                    <Text color="text" weight={FontWeight.medium} align={TextAlign.right}>
                      {source?.name}
                    </Text>
                    <Text weight={FontWeight.light} align={TextAlign.right}>
                      ending in {source?.accountMask}
                    </Text>
                  </Stack>
                }
              />
              <LineItem
                left={c('basics.to')}
                right={
                  <Stack>
                    <Text color="text" weight={FontWeight.medium} align={TextAlign.right}>
                      {destination?.name}
                    </Text>
                    <Text weight={FontWeight.light} align={TextAlign.right}>
                      ending in {destination?.accountMask || '****'}
                    </Text>
                  </Stack>
                }
              />
              <LineItem
                left="Date"
                right={
                  <Stack>
                    <Text color="text" weight={FontWeight.medium} align={TextAlign.right}>
                      {safeFormatDate(new Date())}
                    </Text>
                    <Text weight={FontWeight.light} align={TextAlign.right}>
                      Funds will be available in 3-5 days
                    </Text>
                  </Stack>
                }
              />
            </Stack>
          </Layout>
        </Layout.Scroll>
        <Toolbar type="stack">
          <Text size={FontSize.xs} color="subtle">
            By clicking the “Authorize” button, I authorize Catch to debit the following amount from
            my bank account and on the date as set forth above.
          </Text>
          <Button testID="transfer" onPress={handleTransfer} disabled={amount === 0}>
            Authorize
          </Button>
        </Toolbar>
      </Page>
    </ConfirmationBlueprint>
  );
};

export const TransferConfirmView: ScreenDefinition = {
  name: Route.TRANSFER_CONFIRM,
  component: TransferConfirm,
  options: {
    title: 'Confirm',
  },
};
