import Log from '@app/utils/logger';
import { DisplayOptions } from '../types';

/**
 * @alias navOptions
 */
export const options = {
  /**
   * Calculates the title or subtitle
   * - This will either return a string or use the passed data to compute a string
   */
  getTitle: (
    title: DisplayOptions['title'] | DisplayOptions['subtitle'],
    data: object = {},
  ): string => {
    if (typeof title === 'string') return title; // if a string, return that
    if (typeof title === 'function') return title(data); // if a fn, apply the data
    return ''; // otherwise, empty
  },

  /**
   * Computes the color of the navbar:
   * - Defaults the nav color if a layout is specified (sheet vs page)
   * - Lastly, if a bg param is specified use the color name or computed color name
   * - We pass the theme colors object so that we can lookup the hex value of the color
   */
  getNavColor: (
    options: DisplayOptions = {},
    themeColors: object = {},
    data: object = {},
  ): string | undefined => {
    let colorName = options?.layout ? `${options?.layout}Color` : undefined;

    if (options.bg) {
      if (typeof options.bg === 'string') colorName = `${options.bg}Color`;
      if (typeof options.bg === 'function') colorName = `${options.bg(data)}Color`;
    }

    if (!colorName) return;

    if (!(colorName in themeColors)) {
      Log.warn(`Attempting to apply ${colorName} as nav bg, but not defined`);
      return;
    }

    // otherwise return the color found
    return themeColors[colorName];
  },

  /**
   * Computes the color of the page:
   * - If a layout is specified, we use that to set the page color
   * - Otherwise, don't return anything
   */
  getPageColor: (options: DisplayOptions = {}, themeColors: object = {}): string | undefined => {
    if (!options.layout) return; // no layout specified? nothing to apply

    const colorName = `${options.layout}Color`; // page color or sheet color

    if (!(colorName in themeColors)) {
      Log.warn(`Attempting to apply ${colorName} as page bg, but not defined`);
      return;
    }

    // otherwise return the color lookup
    return themeColors[colorName];
  },
};
