import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
  useMutation as useApolloMutation,
  DefaultContext,
  ApolloCache,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { contentClient, publicClient, fintechClient, healthClient } from '@app/lib/apollo';
import { removeTypename } from './utils';

// exports base hooks from apollo client
export { useQuery, useLazyQuery } from '@apollo/client';

export function useMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  const [mutateFunction, result] = useApolloMutation<TData, TVariables, TContext, TCache>(
    mutation,
    options,
  );

  // return a wrapped mutation function
  const formattedMutateFunction = async (options) => {
    if (options?.variables) {
      const cleanedVariables = removeTypename(options.variables); // Automatically apply removeTypename
      return mutateFunction({ ...options, variables: cleanedVariables });
    }

    return mutateFunction(options);
  };

  return [formattedMutateFunction, result];
}

/**
 * Hooks for data for fintech client:
 * - useFintechQuery and useFintechMutation
 * - Identical to the root useQuery/useMutation but points at fintech client
 */
export function useFintechQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> {
  return useQuery(query, { ...options, client: fintechClient });
}
export function useFintechMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  return useMutation(mutation, { ...options, client: fintechClient });
}

/**
 * Hooks for data for fintech client:
 * - useFintechQuery and useFintechMutation
 * - Identical to the root useQuery/useMutation but points at fintech client
 */

export function useHealthQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> {
  return useQuery(query, { ...options, client: healthClient });
}
export function useHealthMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  return useMutation(mutation, { ...options, client: healthClient });
}

/**
 * Hooks for data for public clients:
 * - usePublicQuery and usePublicMutation
 * - Identical to the root useQuery/useMutation but points at correct client
 */

export function usePublicQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> {
  return useQuery(query, { ...options, client: publicClient });
}
export function usePublicMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  return useMutation(mutation, { ...options, client: publicClient });
}

/**
 * Hooks for data for content clients:
 * - useContentQuery and useContentMutation
 * - Identical to the root useQuery/useMutation but points at correct client
 */

export function useContentQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> {
  return useQuery(query, { ...options, client: contentClient });
}

export function useContentMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  return useMutation(mutation, { ...options, client: contentClient });
}
