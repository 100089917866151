import React from 'react';
import styled from 'styled-components';
import { applyFontStyles, useTheme } from '@app/styles';
import { FontSize, FontFace, Option } from '@app/types';
import { ErrorMessage } from '../ErrorMessage';
import ChevronDown from '@svg/chevron-down.svg';

export interface DropdownProps {
  testID: string;
  name: string;
  value?: string;
  placeholder?: string;
  options: Array<Option>;
  onChange: (val: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled: boolean;
  keyboard?: any; // @todo
  autoFocus?: boolean;
  error?: string;
  fullWidth?: boolean;
}

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSelect = styled.select<{ value?: string; $disabled: boolean; $error: boolean }>`
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: 1;

  ${({ theme }) => applyFontStyles({ theme, size: FontSize.p, family: FontFace.body })}
  border-radius: 12px;
  padding: 12px 56px 12px 12px;
  border: 1.5px solid transparent;
  background-color: ${({ theme }) => theme.colors.inputBg};

  ${({ theme, value }) => `color: ${value ? theme.colors.fg : theme.colors.fg2};`}

  &:focus {
    background-color: ${({ theme }) => theme.colors.bg};
    border-color: ${({ theme }) => theme.colors.fg};
    box-shadow: 0 2px 6px 0px #0000001a;
  }

  &:hover {
    cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  }

  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.5;
  `}

  ${({ theme, $error }) =>
    $error &&
    `
    background-color: ${theme.colors.negative}1A;
    color: ${theme.colors.negative};
  `}
`;

const StyledIcon = styled.div<{ $disabled: boolean }>`
  z-index: 0;
  position: absolute;
  right: 12px;
  top: 7px;
  bottom: 7px;
  display: flex;
  align-items: center;
  pointer-events: none;

  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.5;
  `}
`;

export const Dropdown = React.forwardRef(function Dropdown(
  {
    testID,
    placeholder = 'Select one',
    name,
    value,
    options,
    onChange,
    onFocus,
    onBlur,
    error,
    disabled,
    autoFocus,
  }: DropdownProps,
  ref,
) {
  const { theme } = useTheme();

  return (
    <>
      <StyledContainer>
        <StyledSelect
          ref={ref}
          data-testid={testID}
          name={name}
          defaultValue=""
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          autoFocus={autoFocus}
          $error={!!error}
          $disabled={!!disabled}
        >
          <option key="placeholder" value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={`${option.value}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
        <StyledIcon $disabled={!!disabled}>
          <ChevronDown color={theme.colors.fg} />
        </StyledIcon>
      </StyledContainer>
      {error && <ErrorMessage error={error} />}
    </>
  );
});
