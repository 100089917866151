import React, { useMemo } from 'react';
import { useCopy, BENEFITS, safeFormatDate } from '@app/utils';
import { useQuery, HealthApplicationQuery, getMembers } from '@data';
import { sheets, useSheet } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { Route } from '@types';

const CICCancelPolicy = ({ applicationID, step: screenStep }) => {
  const { c } = useCopy('catch.ede.CIC.policyCancelPrompt');
  const { c: cDental } = useCopy('catch.health.manageCoverage.dentalWarning');
  const step = 'policyCancelPrompt';
  const { open } = useSheet();

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { policy, members, enrollmentID, legalName, enrollmentType, hasAssociatedDentalPlan } =
    useMemo(() => {
      const application = data?.viewerTwo?.health?.application;

      return {
        policy: application?.policies?.[0],
        policies: application?.policies || [],
        members: getMembers(data),
        legalName: application?.applicant?.legalName || '',
        enrollmentID: application?.enrollment?.id,
        enrollmentType: BENEFITS[application?.enrollment?.enrollmentType],
        hasAssociatedDentalPlan: application?.enrollment?.hasAssociatedDentalPlan,
      };
    }, [data]);

  const year = new Date(policy?.endDate).getFullYear() + 1;
  const onSubmit = (values) => {
    open(sheets.CANCEL_POLICY, {
      enrollmentID,
      enrollmentType,
      actionEffectiveDate: values?.date,
    });
  };

  return (
    <BasicFormBlueprint
      loading={!data}
      title={
        hasAssociatedDentalPlan
          ? c('healthDentalTitle')
          : c('title', { step, enrollmentType: enrollmentType?.toLowerCase() })
      }
      subtitles={[
        c('subtitle', { step, year }),
        c('emphasis', {
          memberCount: members.length - 1,
          step,
          date: safeFormatDate(policy?.endDate),
        }),
        hasAssociatedDentalPlan && cDental('subtitle'),
      ]}
      button={c('confirm', { step })}
      // precontent={
      //     <Stack>
      //       {policies.map((p) => (
      //         <PolicyRow key={p.id} interactive={false} policy={p} />
      //       ))}
      //       <PeopleList members={members} sublabel={(m) => capitalize(m?.relation)} />
      //     </Stack>
      // }
      formConfig={{
        initialValues: {},
        fields: [
          {
            name: 'date',
            type: 'date',
            format: 'YYYY-MM-DD',
            required: true,
            label: c('dateLabel'),
            before: {
              inclusive: true,
              date: safeFormatDate(policy?.endDate, 'yyyy-MM-dd'),
              message: `Must be on or before the policy end date (${safeFormatDate(
                policy?.endDate,
                'MM/dd/yyyy',
              )})`,
            },
            after: {
              inclusive: true,
              date: safeFormatDate(policy?.startDate, 'yyyy-MM-dd'),
              message: `Must be on or after the policy start date (${safeFormatDate(
                policy?.startDate,
                'MM/dd/yyyy',
              )})`,
            },
          },
          {
            name: 'signature',
            type: 'signature',
            label: c('signLabel'),
            legalName,
            required: true,
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const CICCancelPolicyView = {
  name: Route.CIC_CANCEL_POLICY,
  component: CICCancelPolicy,
  options: {},
};
