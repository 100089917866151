import { useState, useEffect, useCallback } from 'react';
import { ColorScheme } from '../types';

const query =
  window && window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)') : undefined;

export const useColorScheme = () => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    query?.matches ? ColorScheme.dark : ColorScheme.light,
  );

  const handleChange = useCallback(
    (query) => {
      setColorScheme(query?.matches ? ColorScheme.dark : ColorScheme.light);
    },
    [setColorScheme],
  );

  useEffect(() => {
    query?.addEventListener('change', handleChange);

    return () => {
      query?.removeEventListener('change', handleChange);
    };
  });

  return {
    colorScheme,
  };
};
