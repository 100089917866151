import { create } from 'zustand';
import { DEFAULT_LOCALE } from './i18n';

type LocaleType = 'en' | 'es';

/** Get locale from cookie */
const getCookieLocale = (): LocaleType | null => {
  if (typeof document === 'undefined') return null;

  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    const [name, value] = cookie.trim().split('=');
    if (name === 'locale') {
      return value as LocaleType;
    }
  }
  return null;
};

/** Get locale from localStorage */
const getLocalStorageLocale = (): LocaleType | null => {
  if (typeof window === 'undefined') return null;
  return localStorage.getItem('locale') as LocaleType;
};

const getInitialLocale = (): LocaleType => {
  // Prefer cookie value if available
  const savedLocale = getCookieLocale() || getLocalStorageLocale();
  return savedLocale || DEFAULT_LOCALE;
};

const setPersistentLocale = (locale: LocaleType) => {
  const domainParts = window.location.hostname.split('.');
  const domain = domainParts.slice(-2).join('.');
  document.cookie = `locale=${locale}; domain=${domain}; path=/; max-age=31536000; SameSite=Lax`;
  localStorage.setItem('locale', locale);
};

interface LocaleStore {
  locale: LocaleType;
  changeLocale: (locale: LocaleType) => void;
  nextLanguage: () => string;
}

/**
 * Stores the current user locale
 */
const store = create<LocaleStore>((set, get) => ({
  locale: getInitialLocale(),
  changeLocale: (locale) => {
    set({ locale });
    document.documentElement.lang = locale;
    setPersistentLocale(locale);
  },
  nextLanguage: () => (get().locale === 'en' ? 'Español' : 'English'),
}));

export const useLocale = store;

export const toggleLanguage = () => {
  const { locale, changeLocale } = store.getState();
  changeLocale(locale === 'en' ? 'es' : 'en');
};
