import { useCopy } from '@app/utils';
import { date as fDate } from '@app/utils/format/date';
import { BasicLayout, Toolbar } from '@layouts';
import { Button, ComplexRow } from '@uikit';
import { open, close } from '@app/navigate';
import File from '@svg/file.svg';
import Plus from '@svg/plus.svg';
import { Route } from '@app/types';
import { DMI, HealthDocType, SVI } from '@app/data';

const HealthDocsUploaded = ({ issue }: { issue: DMI | SVI }) => {
  const { c } = useCopy('catch');

  return (
    <BasicLayout
      title={c('ede.DMI.uploadedDocsTitle', {
        issue:
          issue.docType === HealthDocType.SVI
            ? c(`ede.DMI.${issue.statusCode}.title`)
            : c(`ede.enums.${issue.subType}`),
      })}
      subtitles={[c('ede.DMI.uploadedDocs.p1')]}
      toolbar={
        <Toolbar>
          <Button
            testID="add-more"
            alt
            svg={Plus}
            onPress={() => open(Route.HEALTH_DOCS_UPLOAD, { ...issue })}
          >
            Add additional docs
          </Button>
          <Button testID="close" onPress={() => close()}>
            Done
          </Button>
        </Toolbar>
      }
    >
      {issue?.uploads?.map((doc) => (
        <ComplexRow
          key={doc?.key}
          label={c(`ede.enums.${doc.type}`)}
          sublabel={c('ede.DMI.uploadedDocs.date', {
            date: fDate(doc?.createdOn, 'RELATIVE'),
          })}
          asset={
            !/PDF/.test(doc?.url)
              ? { uri: doc?.url, shape: 'squircle', size: 'md' }
              : { svg: File, shape: 'squircle', bg: 'disabled', containerSize: 'md' }
          }
          accessory={
            <Button alt small onPress={() => (window.location.href = doc.url)}>
              {c('basics.view')}
            </Button>
          }
        />
      ))}
    </BasicLayout>
  );
};

export const HealthDocsUploadedView = {
  name: Route.HEALTH_DOCS_UPLOADED,
  component: HealthDocsUploaded,
  options: { layout: 'sheet', accentColor: 'coverage' },
};

export default HealthDocsUploaded;
