import { ApplicationMemberInput, IncarcerationType } from '@app/data';
import { boolToTri, integerize, normalize } from '@app/utils';

const formatFosterCare = (values): ApplicationMemberInput => ({
  isFosterCare1825: boolToTri(!!values?.isFosterCare1825),
  fosterCareEndAge: values?.isFosterCare1825 ? integerize(values?.fosterCareEndAge) : null,
  fosterCareState: values?.isFosterCare1825 ? normalize(values?.fosterCareState) : null,
  isMedicaidDuringFosterCare: values?.isFosterCare1825
    ? normalize(values?.isMedicaidDuringFosterCare)
    : null,
});

const formatIncarceration = (values): ApplicationMemberInput => ({
  incarcerationType: !values?.isIncarcerated
    ? null
    : values?.incarcerationType === 'YES'
    ? IncarcerationType.PendingDisposition
    : values?.incarcerationType === 'NO'
    ? IncarcerationType.Incarcerated
    : null,
});

const formatPregnancy = (values): ApplicationMemberInput => ({
  isPregnant: !!values?.isPregnant,
  babyDueQuantity: values?.isPregnant ? integerize(values?.babyDueQuantity) : null,
});

const formatFullTimeStudent = (values): ApplicationMemberInput => ({
  isFullTimeStudentStatus: boolToTri(!!values?.isFullTimeStudentStatus),
});

export const getInitialValues = (m) => ({
  id: m?.id,
  givenName: m?.givenName,
  familyName: m?.familyName,
  uiQuestionsToDisplay: m?.uiQuestionsToDisplay,
  isFosterCare1825: m?.isFosterCare1825,
  fosterCareState: m?.fosterCareState,
  fosterCareEndAge: m?.fosterCareEndAge,
  isMedicaidDuringFosterCare: boolToTri(m?.isMedicaidDuringFosterCare),
  isIncarcerated:
    m?.incarcerationType === 'PENDING_DISPOSITION' || m?.incarcerationType === 'INCARCERATED'
      ? true
      : undefined,
  incarcerationType:
    m?.incarcerationType === 'PENDING_DISPOSITION'
      ? 'YES'
      : m?.incarcerationType === 'INCARCERATED'
      ? 'NO'
      : undefined,
  isPregnant: m?.isPregnant,
  babyDueQuantity: m?.babyDueQuantity || undefined, // allows field to handle defaultValue
  isFullTimeStudentStatus: m?.isFullTimeStudentStatus,
});

const formatters = {
  149: formatFosterCare,
  250: formatIncarceration,
  147: formatPregnancy,
  144: formatFullTimeStudent,
};

export const formatPayload = ({ members, applicationID, questionID }) => {
  const formatter = formatters[questionID];
  if (!formatter) return {};

  return {
    variables: {
      input: {
        applicationID: applicationID,
        applicationMembersInput: members?.map((m) => ({
          id: m?.id,
          ...formatter(m),
        })),
      },
    },
  };
};
