import createIconComponent from '../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Info = createIconComponent({
  content: [
    {
      type: 'title',
      children: 'More info',
    },
    {
      type: 'circle',
      cx: '12',
      cy: '12',
      r: '7.5',
      stroke: '#1365FF',
      fill: 'none',
      colorable: true,
    },
    {
      type: 'rect',
      x: '11',
      y: '11',
      width: '2',
      height: '5.5',
      rx: '0.5',
      fill: '#1365FF',
      colorable: true,
    },
    {
      type: 'rect',
      x: '11',
      y: '7.5',
      width: '2',
      height: '2',
      rx: '1',
      fill: '#1365FF',
      colorable: true,
    },
  ],
  contentDark: [
    {
      type: 'title',
      children: 'More info',
    },
    {
      type: 'circle',
      cx: '12',
      cy: '12',
      r: '7.5',
      stroke: '#638DFF',
      fill: 'none',
      colorable: true,
    },
    {
      type: 'rect',
      x: '11',
      y: '11',
      width: '2',
      height: '5.5',
      rx: '0.5',
      fill: '#638DFF',
      colorable: true,
    },
    {
      type: 'rect',
      x: '11',
      y: '7.5',
      width: '2',
      height: '2',
      rx: '1',
      fill: '#638DFF',
      colorable: true,
    },
  ],
  id: '1874%3A46',
  name: 'Info',
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
});
Info.displayName = 'Info';

/** @deprecated */
export default Info;
