import { useQuery, HealthApplicationQuery, PreviousApplicationsQuery, useLazyQuery } from '@data';
import { navigate } from '@navigate';
import { Route } from '@types';

export const useExistingApps = (applicationID) => {
  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
    fetchPolicy: 'cache-first',
  });

  // On good responses, we want to check previous applications before routing to next
  const [fetch, { loading }] = useLazyQuery(PreviousApplicationsQuery, {
    variables: {
      input: {
        coverageYear: data?.viewerTwo?.health?.application?.coverageYearNumber,
        state: data?.viewerTwo?.health?.application?.coverageState,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      navigate(
        res?.viewerTwo?.health?.previousApplications?.existingApplicationsInformation?.length > 0
          ? Route.EDE_APPLICATION_IMPORT
          : Route.EDE_CONFIRM_APPLICANTS,
      );
    },
  });

  const checkForExistingApplications = () => {
    // check if this application is already identified with CMS
    if (!!data?.viewerTwo?.health?.application?.insuranceApplicationIdentifier) {
      navigate(Route.EDE_CONFIRM_APPLICANTS);
    } else {
      fetch();
    }
  };

  return {
    checking: loading,
    checkForExistingApplications,
  };
};
