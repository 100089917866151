import createIconComponent from '../../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Nevada = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      d: 'M55.2498 10.5039H199.862L199.705 202.635C199.705 202.635 198.194 205.067 197.89 206.804C197.589 208.542 195.774 209.932 194.263 208.888C192.752 207.845 193.357 207.151 192.448 206.11C191.542 205.067 189.426 205.763 189.426 205.763C185.799 206.11 186.705 207.154 185.799 206.11C184.892 205.067 182.776 206.457 182.776 206.457C183.077 209.932 183.986 210.279 183.381 212.363C182.776 214.448 183.381 212.363 182.776 214.448C182.171 216.532 182.776 217.226 182.776 219.66C182.776 222.094 183.381 222.091 183.986 224.176C184.591 226.26 184.892 223.829 185.196 227.997C185.497 232.166 185.497 232.166 185.497 232.166C185.497 232.166 187.614 235.294 186.708 237.725C185.801 240.156 185.497 237.031 185.801 240.156C186.103 243.284 186.103 243.284 186.103 243.284L55.8105 110.581L55.2498 10.5039Z',
      colorable: true,
      fill: '#1F2533',
    },
  ],
  name: 'Nevada',
  width: '255',
  height: '254',
  viewBox: '0 0 255 254',
});
Nevada.displayName = 'Nevada';

/** @deprecated */
export default Nevada;
