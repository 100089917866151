import { useCallback } from 'react';
import { useCopy, DEFAULT_PREFIX } from './useCopy';

// Recursively resolve copy if needed:
// Examples:
//
// input                                       | output
// -----------------------------------------------------------------------------------------
// hello                                       | hello
// { id: 'x' }                                 | {something from copy sheet under key 'x'}
// { id: 'x', data: { y: 'hi' } }              | {something from copy sheet under key 'x', with templated 'y' var}
// { id: 'x', data: { y: { id: 'z' } }         | {something from copy sheet under key 'x', with templated 'y' var from the copy sheet under the key 'z'}

export function useApplyCopy(prefix = DEFAULT_PREFIX) {
  const { c } = useCopy(prefix);

  const resolveCopy = useCallback(
    (copy) => {
      if (!copy || typeof copy === 'string') {
        return copy;
      }

      if (copy?.id) {
        // We have a reference to copy sheet
        // Recursively resolve `data`
        const data = copy.data
          ? Object.fromEntries(
              Object.entries(copy.data).map(([key, value]) => [key, resolveCopy(value, c)]),
            )
          : {};
        return c(copy.id, data);
      }

      return copy;
    },
    [c],
  );

  return resolveCopy;
}
