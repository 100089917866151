import { BankApplicationStatus } from '@app/data';

export const BANK_APPLICATION_STATUSES_IN_PROGRESS = [
  BankApplicationStatus.AwaitingDocuments,
  BankApplicationStatus.InReview,
  BankApplicationStatus.NotStartedIdentityCheckFailed,
  BankApplicationStatus.Pending,
];

export const BANK_APPLICATION_STATUSES_NEGATIVE = [
  BankApplicationStatus.Canceled,
  BankApplicationStatus.Denied,
  BankApplicationStatus.Failed,
];
