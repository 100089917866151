import { useState } from 'react';

/**
 * Helper hook for creating lazy queries using the skip option
 * - To use, set the skip option on a useQuery
 * - Then invoke, fetch() when you want to make the call
 */
export function useSkip(initial = true) {
  const [skip, setSkip] = useState(initial);
  const fetch = () => setSkip(false);
  return { skip, fetch };
}
