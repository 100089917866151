import React from 'react';

import { Loading, Profile, Banner, ComplexRow } from '@uikit';
import { useCopy } from '@app/utils';
import { EligibilityResultsQuery, useQuery } from '@data';
import { navigate } from '@navigate';
import { Narrow, FlowLayout } from '@layouts';
import { useEnrollmentGroups } from '@hooks';
import { Route } from '@types';

const EligibleMemberRow = ({ member, eligibility, isLast }) => (
  <ComplexRow
    asset={{ icon: Profile, size: 'xl' }}
    label={`${member?.givenName} ${member?.familyName}`}
    accessory={<Banner bg="confirmedCallout" title={eligibility} />}
  />
);

const SplitEligibility = ({ applicationID, enrollId }) => {
  const { c } = useCopy('catch.health.splitEligibility');
  const { c: basics } = useCopy('catch.basics');

  const { loading, data } = useQuery(EligibilityResultsQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const members = data?.viewerTwo?.health?.application?.allMembers;

  // once applicationID resolves, sets the last used route
  useEnrollmentGroups({ applicationID }); // handles prefetch

  const CHIPEligibleMembers = members?.filter((m) => !!m?.chipEligibility);

  const MedicaidEligibleMembers = members?.filter((m) => !!m?.medicaidEligibility);
  const MedicareEligibleMembers = members?.filter((m) => !!m?.initialEnrollmentEligibility);

  let eligibility = '';
  if (MedicareEligibleMembers?.length > 0) eligibility = 'Medicare';
  if (MedicaidEligibleMembers?.length > 0 && CHIPEligibleMembers?.length > 0) {
    eligibility = 'Medicaid and CHIP';
  } else {
    if (MedicaidEligibleMembers?.length > 0) eligibility = 'Medicaid';
    if (CHIPEligibleMembers > 0) eligibility = 'CHIP';
  }

  const isMarketplaceEligible = members?.some(
    (m) => !!m?.exchangeEligibility || !!m?.sepEligibility,
  );

  return (
    <FlowLayout
      onNext={() => (isMarketplaceEligible ? navigate(Route.EDE_PLANS) : navigate(Route.HOME))}
      nextButtonText={isMarketplaceEligible ? basics('soundsGood') : basics('exit')}
      canClickNext={!loading}
      title={c('title', { eligibility })}
      subtitles={[c('subtitle1', { eligibility }), c('subtitle2')]}
      centerContainer
      loading={loading}
    >
      {loading ? (
        <Loading accentColor="coverage" />
      ) : (
        <>
          <Narrow>
            {MedicaidEligibleMembers?.map((m, i) => (
              <EligibleMemberRow
                key={m?.id || i}
                member={m}
                eligibility="Medicaid"
                isLast={MedicaidEligibleMembers?.length}
              />
            ))}
            {CHIPEligibleMembers?.map((m, i) => (
              <EligibleMemberRow
                key={m?.id || i}
                member={m}
                eligibility="CHIP"
                isLast={CHIPEligibleMembers?.length}
              />
            ))}
            {eligibility === 'Medicare' ? (
              <Banner bg="importantCallout" title={c('Medicare.flag', { eligibility })} />
            ) : (
              <Banner bg="importantCallout" title={c('MedicaidCHIP.flag', { eligibility })} />
            )}
          </Narrow>
        </>
      )}
    </FlowLayout>
  );
};
/** @todo title */
export const SplitEligibilityView = {
  name: 'EDE_SPLIT_ELIGIBILITY',
  component: SplitEligibility,
  options: {
    title: 'Eligibility',
  },
};
