import React from 'react';
import { CatchTheme, UIColorName } from '@uikit';
import BaseDonut from './base/Donut';

interface DonutProps {
  label?: string;
  percent: number;
  color?: UIColorName;
}

export function Donut({
  label,
  percent,
  size = CatchTheme.constants.rootHeaderHeight / 2,
  color,
  gauge,
}: DonutProps) {
  const data = [
    { x: 'Q', y: percent, color: color || 'taxes' },
    { x: 'L', y: 1 - percent, color: 'border' },
  ];

  return (
    <BaseDonut
      data={data}
      text={label}
      size={size}
      startAngle={gauge ? -125 : undefined}
      endAngle={gauge ? 125 : undefined}
    />
  );
}

export default Donut;
