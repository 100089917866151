import { gql } from '../generated';

export const CREATE_GOAL_TRANSFER = gql(`
  mutation CreateGoalTransfer($input: CreateGoalTransferInput!) {
    createGoalTransfer(input: $input) {
      id
      amount
    }
  }
`);

export const CREATE_BANK_TRANSFER = gql(`
  mutation CreateBankTransfer($input: CreateBankTransferInput!) {
    createBankTransfer(input: $input) {
      id
      totalAmount
    }
  }
`);
