import { stacks } from '@navigate';

export const white_label_param = 'aetna';

export const AETNA_UNAVAILABLE_STATES = {
  CA: true,
  AZ: true,
  GA: true,
};

export const ALREADY_AETNA = 'already_aetna';

export const AETNA_ENABLED_STACKS = [
  stacks.ONBOARDING_STACK,
  stacks.HEALTH_EXPLORER_STACK,
  stacks.HEALTH_APPLICATION_STACK,
  stacks.GOALS_SETUP_STACK,
  stacks.BANKING_SETUP_STACK,
];
