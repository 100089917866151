import React, { useMemo } from 'react';
import {
  useQuery,
  useMutation,
  mutations,
  UpsertExplorerApplicantsResponse,
  UpsertExplorerApplicantsVars,
  updates,
  HealthExplorerQuery,
} from '@data';
import { pop, ScreenDefinition } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { fields } from './explorerApplicantsFields';
import { Route } from '@types';

const ExplorerApplicants = ({ handleNext }) => {
  const { c } = useCopy('catch.healthExplorer.explorerApplicants');

  const { data } = useQuery(HealthExplorerQuery);

  const initialValues = useMemo(() => {
    const dependents = data?.viewerTwo?.healthExplorerData?.dependents || [];
    const self = { relation: 'SELF', age: data?.viewer?.user?.age || undefined };
    return { applicants: dependents.length > 0 ? dependents : [self] };
  }, [data]);

  const [upsert, { loading: upserting }] = useMutation<
    UpsertExplorerApplicantsResponse,
    UpsertExplorerApplicantsVars
  >(mutations.UPSERT_EXPLORER_APPLICANTS, {
    update: updates.UPSERT_EXPLORER_APPLICANTS,
  });

  const onSubmit = (values) => {
    upsert({
      variables: {
        applicants: values.applicants.map((applicant) => ({
          id: applicant.id,
          relation: applicant.relation,
          age: applicant.age,
          sex: applicant.sex,
          isSmoker: !!applicant.isSmoker,
          isApplying: true,
        })),
      },
    });

    const self = values?.applicants?.find((m) => m.relation === 'SELF');
    const spouse = values?.applicants?.find((m) => m.relation === 'SPOUSE');
    const dependents = values?.applicants?.filter((m) => m.relation === 'CHILD');
    const smokers = values?.applicants?.filter((m) => m.isSmoker);

    Segment.track(SegmentEvent.HX_APPLICANTS_COMPLETED, {
      applicant_age: self.age,
      applicant_sex: self.sex,
      total_applicants: values?.applicants?.length || 0,
      has_spouse: !!spouse,
      num_dependents: dependents?.length || 0,
      has_any_smoker: smokers.length > 0,
      coverage_year: data?.viewerTwo?.healthExplorerData?.coverageYear,
    });

    handleNext({
      applicants: values.applicants,
    });
  };

  return (
    <BasicFormBlueprint
      loading={!data}
      submitting={upserting}
      title={c('title')}
      subtitle={c('subtitle')}
      onBack={pop}
      formConfig={{
        initialValues,
        fields,
        onSubmit,
      }}
    />
  );
};

export const ExplorerApplicantsView: ScreenDefinition = {
  name: Route.EXPLORER_APPLICANTS,
  component: ExplorerApplicants,
  options: {},
};
