import {
  AgreementType,
  GET_LEGAL_AGREEMENTS,
  LegalAgreementFragment,
  useContentQuery,
} from '@app/data';
import { useMemo } from 'react';

// the full set of agreements that we're currently using
const AGREEMENTS = Object.values(AgreementType);

/**
 * Given the body of the agreement and optional data,
 * replaces any of the wildcard fields with actual values
 *
 * This behaves somewhat similar to
 */
export const formatAgreement = (agreement?: LegalAgreementFragment, data: object = {}) => {
  const content = agreement?.body || '';
  return content.replace(/{(.*?)}/g, (_, key) => data[key] || '');
};

/**
 * Given an agreement type, returns the agreement from hygraph
 */
export const useAgreement = (type: AgreementType) => {
  // note: this is prefetched in main layout, so we expect it to already be in cache
  const { loading, data } = useContentQuery(GET_LEGAL_AGREEMENTS, {
    variables: { where: { agreementType_in: AGREEMENTS } },
  });

  const agreement = useMemo(() => {
    return (data?.agreements || []).find((a) => a.agreementType === type);
  }, [data, type]);

  return {
    loading,
    agreement,
  };
};

/**
 * Given a set of agreement types,
 * returns a list of those agreements
 */
export const useAgreements = (types?: Array<AgreementType>) => {
  // note: this is prefetched in main layout, so we expect it to already be in cache
  const { loading, data } = useContentQuery(GET_LEGAL_AGREEMENTS, {
    variables: { where: { agreementType_in: AGREEMENTS } },
  });

  const agreements = useMemo(() => {
    return (data?.agreements || []).filter(
      (a) => a.agreementType && types?.includes(a.agreementType),
    );
  }, [data, types]);

  return { loading, agreements };
};
