import React from 'react';
import { LineItem, Text } from '@uikit';
import { safeFormatDate, useCopy } from '@app/utils';

interface CoverageFooterProps {
  type: 'DEDUCTIBLE' | 'DATES';

  originalPremium?: number;
  premiumWithCredit?: number;

  // for dates
  startDate?: string;
  endDate?: string;

  // for pricing
  deductible?: number;
}

/**
 * Coverage footer is a component that allows us to render
 * different sets of details of a policy or health plan
 *
 * This components helps us keep things like sizing
 * consistent across all variations of the footer
 */
const CoverageFooter: React.FC<CoverageFooterProps> = ({
  type,
  deductible,
  premiumWithCredit,
  originalPremium,
  startDate,
  endDate,
}) => {
  const { $, c } = useCopy('catch');

  return (
    <LineItem
      left={
        type === 'DEDUCTIBLE' ? (
          deductible !== undefined ? (
            <Text size="fp" weight="medium" color="text">
              {$(deductible, { whole: true })}{' '}
              <Text size="fp" weight="regular">
                {c('total-deductible')}
              </Text>
            </Text>
          ) : null
        ) : (
          <Text size="fp" weight="medium" color="text">
            {safeFormatDate(startDate, 'MMM dd, yyyy')} - {safeFormatDate(endDate, 'MMM dd, yyyy')}{' '}
          </Text>
        )
      }
      right={
        premiumWithCredit !== undefined && originalPremium !== undefined ? (
          <Text size="fp" weight="medium" color="text">
            {premiumWithCredit < originalPremium && (
              <Text gutter="right" size="fp" color="subtle" strike>
                {$(originalPremium, { whole: true })}
              </Text>
            )}
            {$(premiumWithCredit)}
            <Text size="fp" weight="regular">
              /{c('month-abbr')}
            </Text>
          </Text>
        ) : null
      }
    />
  );
};

export default CoverageFooter;
