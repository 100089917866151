import { links } from '@app/config/links';
import { popToast, ToastType, Link, Asset } from '@uikit';
import { Color } from '@app/styles';
import ArrowRight from '@svg/arrow-right.svg';

export function popReviewToast() {
  popToast({
    autoCloseIn: false,
    title: 'Enjoying Catch?',
    msg: (
      <Link testID="trustpilot-link" color={Color.snow} href={links.trustpilot}>
        Leave us a review on Trustpilot <Asset svg={ArrowRight} size="glyph" color={Color.snow} />
      </Link>
    ),
    type: ToastType.important,
  });
}
