import React, { useMemo } from 'react';

import { useQuery, getRemainingWalletTypes, GET_COVERAGE } from '@data';
import { ScreenDefinition, sheets } from '@navigate';
import { RootLayout, Stack, Section, Carousel } from '@layouts';
import { PromptCard } from '@common';
import { CoverageList, WalletCard } from '@app/partial';
import { Env, currentYear } from '@app/utils';
import { Prompts } from '@app/config';
import { Route } from '@types';

const SHOW_MENU = !Env.isProd;

const options = {
  bg: 'coverageLight',
  title: 'Coverage',
  buttons: {
    sheetMenu: SHOW_MENU ? sheets.COVERAGE_MENU : undefined,
    account: true,
    activity: true,
    accentColor: 'coverage',
  },
};

const CoverageComponent = () => {
  const { loading, data, refetch } = useQuery(GET_COVERAGE, {
    variables: { coverageYears: [currentYear, currentYear + 1] },
  });

  const { policies, applications, wallet, hasMoreOutsideCoverage, oeCoverageYear } = useMemo(() => {
    return {
      policies: data?.viewerTwo?.health?.policies,
      applications: data?.viewerTwo?.health?.applications,
      isOpenEnrollment: data?.reference?.health?.openEnrollmentDates?.isOpenEnrollment,
      isWindowShopping: data?.reference?.health?.openEnrollmentDates?.isWindowShopping,
      oeCoverageYear: data?.reference?.health?.openEnrollmentDates?.oeCoverageYear,
      wallet: data?.viewer?.wallet || [],
      hasMoreOutsideCoverage: getRemainingWalletTypes(data?.viewer?.wallet)?.length > 0,
    };
  }, [data?.viewerTwo?.health, data?.reference?.health]);

  return (
    <RootLayout
      title={options.title}
      loading={loading && !data}
      color={options.bg}
      onRefresh={refetch}
      sheetConfig={SHOW_MENU ? { name: sheets.COVERAGE_MENU } : undefined}
    >
      <Stack mobileGap spacing="2">
        {CoverageList.shouldDisplay({ policies, applications }) ? (
          <CoverageList policies={policies} applications={applications} />
        ) : (
          <Section title={oeCoverageYear}>
            <PromptCard key={Prompts.COVERAGE_SETUP} wide {...Prompts.COVERAGE_SETUP} />
          </Section>
        )}

        {/**
         * WALLET
         * */}
        <Section title="Wallet" subtitle="Coverage outside Catch">
          <Carousel spacing="1">
            {wallet?.map((item) => <WalletCard key={item?.id} item={item} />)}
            {hasMoreOutsideCoverage && <WalletCard key="empty" />}
          </Carousel>
        </Section>
      </Stack>
    </RootLayout>
  );
};

export const CoverageView: ScreenDefinition = {
  name: Route.COVERAGE,
  component: CoverageComponent,
  options: {
    title: 'Coverage',
  },
};

export default CoverageView;
