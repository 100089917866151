/**
 * Removes any non-digit characters
 */
export const onlyNums = (value = '') => {
  return (value || '').replace(/[^\d]/g, '');
};

export const formatList = (list, formatter, fallback = 'None') => {
  const numItems = list?.length || 0;

  switch (numItems) {
    case 0:
      return fallback;
    case 1:
      return formatter(list[0]);
    case 2:
      return `${formatter(list[0])} and ${formatter(list[1])}`;
    case 3:
      return `${formatter(list[0])}, ${formatter(list[1])}, and ${formatter(list[2])}`;
    default:
      // otherwise split onto multiple lines
      return list.reduce((acc, item) => `${acc}${formatter(item)}\n`);
  }
};

export const formatMaskedPayload = (value: string) => {
  return value?.includes('*') ? undefined : value || undefined;
};
