import React, { useMemo } from 'react';
import { ScreenDefinition } from '@navigate';
import { formatCurrency } from '@app/utils/format/currency';
import { Route } from '@app/types';
import { BankTransferDirection, GET_MONEY_PAGE, GoalFragment, useFintechQuery } from '@app/data';
import { BasicFormBlueprint } from '@app/blueprints';
import { TransferAccountRow } from '../components';
import { TransferAccount } from '../TransferStack';

export interface TransferAmountProps {
  goal?: GoalFragment;
  direction: BankTransferDirection;
}

const TransferAmount = ({ direction, source, destination, amount, handleNext }) => {
  const { loading, data } = useFintechQuery(GET_MONEY_PAGE);
  const goals = data?.goals || [];
  const linkedAccounts = data?.linkedAccounts || [];

  const joined: Array<TransferAccount> = [...goals, ...linkedAccounts];

  const fields = useMemo(() => {
    // not specified to any goal
    const isGenericTransfer = !source && !destination;

    const goalOptions = goals?.map((goal) => ({
      label: goal?.name,
      value: goal?.id,
    }));

    const accountOptions = linkedAccounts?.map((account) => ({
      label: account?.name,
      value: account?.id,
    }));

    const getAccount = (id?: string) => {
      return joined.find((account) => account?.id === id);
    };

    return [
      {
        name: 'amount',
        type: 'amount',
        required: true,
        large: true,
        label: 'Amount',
        amountType: 'decimal',
      },
      {
        name: 'source',
        type: 'row',
        required: true,
        label: 'From',
        dependencies: ['destination'],
        placeholder: 'Select source',
        options: ({ destination }) => {
          const genericOptions =
            direction === BankTransferDirection.DepositIntoCatch ? accountOptions : goalOptions;

          const options = isGenericTransfer ? genericOptions : [...goalOptions, ...accountOptions];

          // filter out the destination from list of possible values
          return options.filter((goal) => goal.value !== destination);
        },
        renderRow: ({ value, onPress }) => (
          <TransferAccountRow account={getAccount(value)} onPress={onPress} />
        ),
      },
      {
        name: 'destination',
        type: 'row',
        required: true,
        label: 'To',
        dependencies: ['source'],
        options: ({ source }) => {
          const genericOptions =
            direction === BankTransferDirection.DepositIntoCatch ? goalOptions : accountOptions;

          const options = isGenericTransfer ? genericOptions : [...goalOptions, ...accountOptions];

          // filter out the source from list of possible values
          return options.filter((goal) => goal.value !== source);
        },
        renderRow: ({ value, onPress }) => (
          <TransferAccountRow account={getAccount(value)} onPress={onPress} />
        ),
      },
    ];
  }, [data]);

  const handleSubmit = (values) => {
    const source = joined.find((element) => element.id === values.source);
    const destination = joined.find((element) => element.id === values.destination);

    handleNext({
      source,
      destination,
      amount: values.amount,
    });
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      title={
        direction === BankTransferDirection.DepositIntoCatch
          ? 'Transfer into Catch'
          : 'Transfer to your bank'
      }
      button="Continue"
      formConfig={{
        fields,
        initialValues: {
          amount,
          source: source?.id,
          destination: destination?.id,
        },
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const TransferAmountView: ScreenDefinition = {
  name: Route.TRANSFER_AMOUNT,
  component: TransferAmount,
  options: {
    // when goal is specified, e.g. "From Taxes"
    title: ({ goal, direction }) => {
      if (goal) {
        return `${direction === BankTransferDirection.DepositIntoCatch ? 'From' : 'To'} ${
          goal.name
        }`;
      }
    },
    // for withdrawals, show available balance
    subtitle: ({ goal, direction }) => {
      if (direction === BankTransferDirection.WithdrawFromCatch && goal) {
        return `${formatCurrency(goal?.availableBalance)} available`;
      }
    },
  },
};
