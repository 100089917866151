import React, { useRef, useEffect, useReducer } from 'react';
import { createPortal } from 'react-dom';

import { Env } from '@app/utils';

const Portal = ({ children }) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const mountNode = useRef();
  const portalNode = useRef();
  useEffect(() => {
    if (mountNode.current) {
      const ownerDocument = mountNode.current.ownerDocument;
      portalNode.current = ownerDocument.createElement('catch-portal');
      ownerDocument.body.appendChild(portalNode.current);
      forceUpdate();
      return () => {
        portalNode.current.ownerDocument.body.removeChild(portalNode.current);
      };
    }
  }, []);

  if (Env.isTest) {
    return children;
  }

  return portalNode.current ? createPortal(children, portalNode.current) : <div ref={mountNode} />;
};

export default Portal;
