import { Income } from '@app/data';
import { SimpleRow } from '@uikit';
import { getIncomeLabel, getIncomeLogo } from './helpers';

export function IncomeLogo({ income }: { income: Income }) {
  return (
    <SimpleRow
      noPadding
      testID="income-logo"
      asset={{
        shape: 'squircle',
        size: 'sm',
        uri: getIncomeLogo(income),
      }}
      label={getIncomeLabel(income)}
    />
  );
}
