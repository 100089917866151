import React, { useState } from 'react';
import { Route } from '@app/types';
import {
  useMutation,
  useQuery,
  GET_IDENTITY_UPLOADS,
  REQUEST_MANUAL_REVIEW,
  HealthPathwayType,
} from '@app/data';
import { HiccupBlueprint } from '@app/blueprints';
import { Fields, useForm } from '@app/forms';
import { navigate } from '@app/navigate';
import { ComplexRow, Link } from '@uikit';
import { safeFormatDate, Segment, SegmentEvent } from '@app/utils';
import { Layout, Stack } from '@app/layouts';

import Checkmark from '@svg/checkmark.svg';
import Upload from '@svg/upload.svg';

const uploadFields = [
  {
    name: 'documentType',
    type: 'dropdown',
    label: 'Select document type',
    required: true,
    options: [
      { label: "Driver's license", value: 'PHOTO_ID_FRONT' },
      { label: 'Passport', value: 'PASSPORT' },
    ],
  },
  {
    name: 'upload',
    type: 'file',
    label: 'Upload your document',
    dependencies: ['documentType'],
    required: true,
    documentType: ({ documentType }) => documentType,
  },
];

const IdUpload = ({ pathway }: { pathway: HealthPathwayType }) => {
  const [hasUploads, setHasUploads] = useState(false);

  const { loading, data } = useQuery(GET_IDENTITY_UPLOADS, {
    context: { failQuietly: true },
    onCompleted: (res) => {
      if (res?.viewerTwo?.documentUploads?.length > 0) {
        setHasUploads(true);
      }
    },
  });

  const [requestManualReview] = useMutation(REQUEST_MANUAL_REVIEW);

  const uploadForm = useForm({
    loading,
    disabled: false,
    initialValues: {
      documentType: '',
    },
    fields: uploadFields,
    onSubmit: () => {
      Segment.simpleTrack(SegmentEvent.EDE_IDENTITY_DOCS_UPLOADED);
      const identityId = data?.viewerTwo?.identity?.id || '';
      requestManualReview({ variables: { identityId } });
      setHasUploads(true);
    },
  });

  if (!hasUploads) {
    return (
      <HiccupBlueprint
        loading={loading}
        // disabled={disabled}
        asset={{ svg: Upload, gradient: 'coverage' }}
        title="Identity documentation"
        subtitles={["Please provide a driver's license or passport to verify your identity."]}
        actions={[
          {
            testID: 'answer',
            type: 'CUSTOM',
            label: 'Upload',
            disabled: uploadForm.disableSubmit,
            onAction: uploadForm.submitForm,
          },
        ]}
      >
        <Fields form={uploadForm} fields={uploadFields} />
      </HiccupBlueprint>
    );
  }

  return (
    <HiccupBlueprint
      loading={loading}
      // disabled={disabled}
      asset={{ svg: Checkmark, gradient: 'coverage' }}
      title="Your documents are uploaded"
      subtitles={[]}
      actions={
        pathway === HealthPathwayType.StateApp
          ? [
              {
                testID: 'continue',
                type: 'CUSTOM',
                label: 'Continue',
                onAction: () => navigate(Route.EDE_CONFIRM_APPLICANTS),
              },
            ]
          : []
      }
    >
      <Stack separatorComponent>
        {(data?.viewerTwo?.documentUploads || [])?.map((upload) => (
          <ComplexRow
            key={upload.id}
            testID={`upload-${upload.id}`}
            asset={{ img: upload.url, shape: 'squircle' }}
            label={upload?.name}
            sublabel={`Uploaded ${safeFormatDate(upload.createdOn)}`}
          />
        ))}
      </Stack>
      <Layout center>
        <Link
          testID="upload-more"
          onPress={() => {
            setHasUploads(false);
          }}
        >
          Upload another document
        </Link>
      </Layout>
    </HiccupBlueprint>
  );
};

export const IdUploadView = {
  name: Route.EDE_ID_UPLOAD,
  component: IdUpload,
  options: {},
};
