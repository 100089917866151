// on web, we should probably keep track of the initial referrer
// in case we want to return to the original page on close
import { history } from '../history';
import { paths, Route } from '../paths';

/**
 * Removes any query params and returns to normal
 * Same thing as exit in this case
 */
export const exit = (route?: Route) => {
  if (route) {
    // @ts-ignore
    history.push(paths[route]);
  } else {
    // @ts-ignore
    history.push(history.location.pathname);
  }
};
