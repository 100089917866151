import { ScreenDefinition, paths, prefixes, TabComponent, StackComponent } from '@navigate';

// stack imports
import AuthStack from './auth/AuthStack';
import HomeStack from './home/HomeStack';
import CoverageStack from './coverage/CoverageStack';
import MeStack from './me/MeStack';
import HealthExplorerStack from './explorer/HealthExplorerStack';
import HealthLinkStack from './link/HealthLinkStack';
import HealthApplicationStack from './application/HealthApplicationStack';
import CICStack from './cic/CICStack';
import BankingSetupStack from './banking/BankingSetupStack';
import GoalSetupStack from './goals/GoalSetupStack';
import GoalsSetupStack from './goals/GoalsSetupStack';
import MoneyStack from './money/MoneyStack';
import TransferStack from './transfers/TransferStack';
import OnboardingStack from './onboarding/OnboardingStack';
import PaycheckStack from './income/PaycheckStack';

// view imports
import { HealthResumeView } from './application/routes/HealthResumeView';
import { EditUserDetailsView } from './me/partials/EditUserDetailsView';
import DevView from '@app/dev/DevView';
import { WalletItemView } from './misc/WalletItemView';
import { HealthDocsUploadView } from './application/routes/docs/HealthDocsUploadView';
import { HealthDocsUploadedView } from './application/routes/docs/HealthDocsUploadedView';
import { AddBankLinkView } from './misc/AddBankLinkView';
import { WelcomeView } from './onboarding/WelcomeView';
import { Route } from '@app/types';
import { DocumentUploadView } from './misc/DocumentUploadView';
import { AetnaAttestationView } from './onboarding/AetnaAttestationView';

// tab declarations
export const tabs: Array<TabComponent> = [HomeStack, MoneyStack, CoverageStack];

// layers that open above main app
export const layers: Array<StackComponent> = [
  MeStack,
  HealthExplorerStack,
  HealthLinkStack,
  HealthApplicationStack,
  CICStack,
  BankingSetupStack,
  GoalSetupStack,
  GoalsSetupStack,
  TransferStack,
  PaycheckStack,
];

// stack for unauthed users
export const guest: StackComponent = AuthStack;

export const onboarding: StackComponent = OnboardingStack;

// standalone pages that can be opened from anywhere
export const standalone: Array<ScreenDefinition> = [
  WelcomeView,
  AetnaAttestationView,
  AddBankLinkView,
  DocumentUploadView,
  HealthResumeView, // @todo E-3445 typescript
  HealthDocsUploadView,
  HealthDocsUploadedView,
  WalletItemView,
  EditUserDetailsView,
  DevView,
];

/**
 * Heads up!
 * -------------------------
 * Everything below is just a consolidated mapping of stack/screens
 * IF a screen/stack is missing, add it to the corresponding configs above instead
 * -------------------------
 */

// consolidated definition for all stacks
export const stacks: Array<StackComponent> = [guest, onboarding, ...tabs, ...layers];

/**
 * Builds a single array of all screens
 * Note: do not remove sort -- it's in place to ensure that web routing happens in correct order
 * so that a url like /plan/:slug/intro gets matched before /plan
 */
export const screens: Array<ScreenDefinition> = stacks
  .reduce((acc: Array<ScreenDefinition>, stack: StackComponent) => {
    return [...acc, ...stack.config.screens];
  }, [])
  .concat(standalone)
  .sort(({ name: pageName }, { name: secondPageName }) => {
    return paths[pageName] > paths[secondPageName] ? -1 : 1;
  });

// lil helper fn to better compare paths across different types
const getPath = (obj) => {
  switch (obj.type) {
    case 'redirect':
      return obj.from;
    case 'handler':
      return obj.path;
    default: {
      // standalone screen or stack?
      if (obj.config?.stackName) {
        return prefixes[obj.config.stackName];
      } else {
        return paths[obj.name];
      }
    }
  }
};

// creates a fully sorted comparative list of screens
export const sorted = [
  ...tabs,
  ...layers,
  // note: welcome screen is handled as a singleton, so we don't want to include it here!
  ...standalone.filter((s) => !s.guest && s.name !== Route.WELCOME),
].sort((a, b) => {
  return getPath(a) > getPath(b) ? -1 : 1;
});
