import { datadogRum } from '@datadog/browser-rum';
import { RumFetchResourceEventDomainContext } from '@datadog/browser-rum';
import { ExperimentalFeature } from '@datadog/browser-core/cjs/tools/experimentalFeatures';
import { CatchEnv, env } from '@app/global';
import { Segment, SegmentEvent } from '@app/utils';
import { AnalyticsClient } from './types';

const config: Record<CatchEnv, { applicationId: string; env: string }> = {
  dev: { applicationId: 'cf80e8fb-c1d0-4e7a-a309-96252ebdc9f5', env: 'development' },
  staging: { applicationId: 'd60484a5-8e9b-46b6-9c87-ff7201dd0e06', env: 'staging' },
  prod: { applicationId: 'bf01a6ef-8195-4bcc-a73b-c90293a23344', env: 'production' },
};

export const Datadog: AnalyticsClient = {
  init: () => {
    const ddconfig = config[env.CATCH_ENV];

    datadogRum.init({
      enableExperimentalFeatures: [ExperimentalFeature.WRITABLE_RESOURCE_GRAPHQL],
      env: ddconfig.env,
      applicationId: ddconfig.applicationId,
      clientToken: env.DATADOG_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'app',
      version: env.VERSION_NUMBER,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackSessionAcrossSubdomains: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [
        { match: /https:\/\/api.*\.catch\.co/, propagatorTypes: ['tracecontext'] },
      ],
      beforeSend: (event, context) => {
        //ignore certain types of requests - don't send to DD
        if (['xhr'].includes(event.type) === true) {
          //console.log(`skipping event ${event.type}`);
          return false;
        }

        // enahnce the data collected
        if (event.type === 'resource' && event.resource.type === 'fetch' && context !== null) {
          // is there a better way to force the type hinting? We know what this is
          // when we're here
          const ctx: RumFetchResourceEventDomainContext = context;
          // these are sparse
          if (ctx.response!.headers) {
            event!.context!.responseHeaders = Object.fromEntries(ctx.response!.headers);
          }
          // these are full
          if (ctx!.requestInit!.headers) {
            event!.context!.requestHeaders = ctx!.requestInit!.headers;
          }
        }
        return true;
      },
    });

    /**
     * Tracks the session event in analytics
     */
    const sessionURL = datadogRum.getSessionReplayLink();
    if (sessionURL) {
      Segment.track(SegmentEvent.BROWSER_REPLAY, { sessionURL });
    }
  },
  identify: (userId, { givenName, familyName, email, phoneNumber }) => {
    datadogRum.setUser({
      id: userId,
      name: `${givenName} ${familyName}`,
      email: email,
      phone: phoneNumber,
    });
  },
  page: () => {},
};
