import createIconComponent from '../../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const NorthDakota = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      d: 'M243.649 184.491C243.516 182.75 243.649 183.287 242.846 181.141C242.043 178.998 242.043 176.587 242.043 175.784C242.043 174.982 242.176 174.312 240.169 173.105C238.159 171.9 237.489 168.418 237.625 167.612C237.758 166.809 237.625 164.398 236.152 163.595C234.68 162.792 236.152 160.65 236.285 159.041C236.418 157.433 236.017 157.433 235.482 155.827C234.945 154.219 236.152 154.487 236.955 152.746C237.758 151.005 236.418 151.807 235.347 149.665C234.275 147.522 235.347 146.719 235.078 144.978C234.81 143.237 235.211 142.165 234.408 140.557C233.605 138.948 234.275 137.611 234.81 136.271C235.347 134.931 234.408 135.601 234.007 133.591C233.605 131.582 234.408 131.717 234.007 129.976C233.605 128.235 233.738 127.966 233.874 125.959C234.007 123.949 232.133 123.014 231.598 122.075C231.061 121.137 230.796 118.592 230.394 117.388C229.993 116.184 229.592 116.317 229.054 114.977C228.517 113.637 228.653 113.368 227.983 112.566C227.313 111.763 226.912 109.753 227.044 109.083C227.177 108.413 226.375 107.209 225.572 106.002C224.769 104.798 224.368 103.859 224.099 102.519C223.83 101.179 225.708 99.3051 225.303 98.098C224.902 96.8938 224.099 96.4895 224.633 95.0167C225.17 93.544 225.17 93.1426 224.633 91.5341C224.096 89.9256 224.766 89.1228 224.365 87.7829C223.963 86.443 225.705 84.8374 225.973 84.0317C226.242 83.2289 225.436 81.4876 224.5 80.4162C223.562 79.3449 223.296 78.8077 223.16 78.0049C223.028 77.2022 223.16 77.0664 223.16 76.3964C223.16 75.7265 222.49 75.1923 222.089 74.5223C221.688 73.8523 221.419 72.9138 221.419 71.4411C221.419 71.4115 221.422 71.385 221.422 71.3555H12.4667V190.568H243.702C243.413 189.443 243.469 188.998 243.245 187.428C242.979 185.562 243.782 186.232 243.649 184.491Z',
      colorable: true,
      fill: '#1F2533',
    },
  ],
  name: 'NorthDakota',
  width: '255',
  height: '255',
  viewBox: '0 0 255 255',
});
NorthDakota.displayName = 'NorthDakota';

/** @deprecated */
export default NorthDakota;
