import React, { useEffect, useMemo } from 'react';
import { Button, Instructions, Link, Loading, stateIcons, Text } from '@uikit';
import { HiccupBlueprint } from '@blueprints';
import {
  useQuery,
  queries,
  StateReferenceQueryData,
  StateReferenceQueryVars,
  HealthExplorerQuery,
  WhiteLabelOption,
  useMutation,
  UpsertConversionStatus,
  ConversionStatusEnum,
  ConversionStatusType,
} from '@data';
import { currentYear, STATES, useCopy } from '@app/utils';
import { links, navigate, ScreenDefinition } from '@navigate';
import { Route } from '@types';
import { Stack } from '@app/layouts';
import { STATE_BASED_EXCHANGES, STATE_INSTRUCTIONS } from '../config/stateInstructions';
import { useCurrentUser } from '@app/auth/useCurrentUser';

const isStateBasedExchange = (state) => STATE_BASED_EXCHANGES.includes(state);

const ExplorerStateHandoff = ({ userID }) => {
  const { c } = useCopy('catch');
  const { user } = useCurrentUser();
  const [upsertConversion] = useMutation(UpsertConversionStatus);
  const { data } = useQuery(HealthExplorerQuery);

  const { state, year } = useMemo(() => {
    return {
      state: data?.viewerTwo?.healthExplorerData?.state || '',
      year: data?.viewerTwo?.healthExplorerData?.coverageYear || currentYear,
    };
  }, [data?.viewerTwo?.healthExplorerData]);

  const { loading, data: stateData } = useQuery<StateReferenceQueryData, StateReferenceQueryVars>(
    queries.STATE_REFERENCE,
    {
      variables: { state },
      skip: !state,
    },
  );

  const isStateExchange = isStateBasedExchange(state);

  useEffect(() => {
    if (!year) return;

    upsertConversion({
      variables: {
        input: { status: ConversionStatusEnum.StateDiy, type: ConversionStatusType.Health, year },
      },
    });
  }, [year]);

  const handleAetnaAttestation = () => {
    if (user?.whiteLabel === WhiteLabelOption.Aetna) {
      navigate(Route.AETNA_ATTESTATION);
    }
  };

  /** After choosing a plan, navigate to state exchange on click.
   * If whiteLabel, navigate to Aetna attestation,
   * Otherwise, if not whiteLabel or state exchange, navigate home */

  const handleButtonPress = () => {
    if (isStateExchange) {
      window.open(url, '_blank');
    } else if (user?.whiteLabel !== WhiteLabelOption.Aetna) {
      navigate(Route.HOME);
    }
    handleAetnaAttestation();
  };

  const { name, url } = stateData?.reference?.stateHealthReference || {};

  const healthPlan = useMemo(() => {
    return data?.viewerTwo?.healthExplorerData?.healthPlanDetails;
  }, [data?.viewerTwo?.healthExplorerData]);

  const { exchangeLink, planId, planName, steps } = useMemo(() => {
    const config = STATE_INSTRUCTIONS[state] || {};

    return {
      concierge: config.concierge || false,
      exchangeLink: (
        <Link href={url} testID="exchange-link" onPress={() => handleAetnaAttestation()}>
          {name}
        </Link>
      ),
      planId: healthPlan?.id || 'Not selected',
      planName: healthPlan?.name || 'Not selected',
      steps: config.steps || [],
    };
  }, [state, healthPlan, url, name]);

  // adding this here because otherwise its jumpy
  if (loading || !state) {
    return <Loading />;
  }

  const title = isStateExchange ? `Apply on ${name}` : 'Thanks for selecting your plan!';
  const subtitle = isStateExchange
    ? `${STATES[state]} requires you to apply through your state exchange. Click below to continue to ${name}.`
    : 'Look out for an email from Catch with next steps on how to submit your application through your state exchange.';

  const instructions = isStateExchange ? (
    <Instructions
      state={state}
      steps={steps.map((step) => ({
        id: step.title,
        title: c(step.title, { exchangeLink }),
        subtitle: c(step.subtitle, { planId, planName }),
      }))}
      onClick={handleAetnaAttestation}
    />
  ) : null;

  return (
    <HiccupBlueprint
      loading={!state || loading}
      asset={{ icon: stateIcons[state], color: 'text' }}
      title={title}
      subtitles={[subtitle]}
    >
      <Stack spacing="3">
        <Stack spacing="3" separatorComponent>
          <Button testID="apply" onPress={handleButtonPress}>
            {isStateExchange ? c('basics.applyNow') : c('basics.soundsGood')}
          </Button>
          {isStateExchange && instructions}
        </Stack>

        {isStateExchange ? (
          <Text color="subtle">
            Need help?{' '}
            <Link href={links.CONTACT} testID="contact">
              Contact us
            </Link>{' '}
            for assistance.
          </Text>
        ) : undefined}
      </Stack>
    </HiccupBlueprint>
  );
};

export const ExplorerStateHandoffView: ScreenDefinition = {
  name: Route.EXPLORER_STATE_HANDOFF,
  component: ExplorerStateHandoff,
  options: {},
};
