import React from 'react';
import styled from 'styled-components';
import { Loading } from '@uikit';

const Container = styled.div`
  position: relative;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.acrylic};
  backdrop-filter: ${({ theme }) => theme.blur};
  width: calc(100% + 48px);
  height: 100%;
  padding-top: 150px;
  margin-left: -24px;
  margin-right: -24px;
`;

export interface SearchResultsProps {
  searching: boolean;
  children: React.ReactNode;
}

export function SearchResults({ searching, children }: SearchResultsProps): React.ReactNode {
  return (
    <Container>
      {searching && (
        <LoadingOverlay>
          <Loading />
        </LoadingOverlay>
      )}
      <div>{children}</div>
    </Container>
  );
}
