import { GOAL_TYPE_TO_NAME } from '@app/constants/goals';
import { GoalType } from '@app/data';
import { FontSize } from '@app/types';
import { ComplexRow, Text } from '@uikit';

export interface AddGoalRowProps {
  goalType: GoalType;
  addGoal: (goalType: GoalType) => void;
}

export function AddGoalRow({ goalType, addGoal }: AddGoalRowProps) {
  return (
    <ComplexRow
      testID={goalType}
      interaction="navigation"
      label={GOAL_TYPE_TO_NAME[goalType]}
      onPress={() => addGoal(goalType)}
      accessory={
        <Text size={FontSize.sm} color="subtle">
          Add
        </Text>
      }
      asset={{
        size: 'xs',
        containerSize: 'lg',
        shape: 'circle',
        render: goalType,
        bg: 'bg',
        borderColor: 'border',
        style: {
          borderStyle: 'dashed',
          borderWidth: 0.5,
        },
      }}
    />
  );
}
