export const fields = [
  // {
  //   name: 'incomeSources',
  //   type: 'array',
  //   // Component @todo don't allow removing, add title for the income source
  //   Footer: () => null,
  //   subfields: [
  //     {
  //       copy: 'catch.ede.HealthEnrollment',
  //       testID: '185',
  //       name: 'incomeDifferenceReason',
  //       type: 'option',
  //       dependencies: ['employerName', 'jobIncomeExplanationRequired'],
  //       display: (values) => {
  //         return values?.jobIncomeExplanationRequired;
  //       },
  //       options: (values) => [
  //         { label: `Hours at ${values?.employerName} were reduced`, value: 'DECREASE_HOURS' },
  //         { label: `Cut wages or salary at ${values?.employerName}`, value: 'WAGE_CUT' },
  //         { label: `Stopped working at ${values?.employerName}`, value: 'STOP_WORKING' },
  //         { label: 'A reason not listed above', value: 'OTHER' },
  //       ],
  //       label: (values) => ({ id: '185_Q', data: { employer: values?.employerName } }),
  //       help: { id: '185_T' },
  //     },
  //     {
  //       // display for each income source
  //       testID: '185_EXPLANATION',
  //       name: 'incomeDifferenceReasonExplanation',
  //       type: 'text',
  //       dependencies: ['incomeDifferenceReason'],
  //       display: (values) => values.incomeDifferenceReason === 'OTHER',
  //       label: 'Please explain',
  //     },
  //   ],
  // },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '186',
    name: 'taxHouseholdIncomeDifferenceReasonType',
    type: 'multiSelect',
    dependencies: ['hasDiscrepancy', 'coverageYear', 'self', 'spouse'],
    display: (values) => values.hasDiscrepancy,
    label: ({ coverageYear, self, spouse }) => ({
      id: '186_Q',
      data: {
        fullName: spouse ? `${self?.givenName} and ${spouse?.givenName}` : self?.givenName,
        coverageYear,
      },
    }),
    options: [
      {
        label: 'My household members have changed.',
        value: 'HOUSEHOLD_MEMBER_CHANGE',
      },
      {
        label:
          'My income will be lower due to a change in my, for reasons such as a new job, a decrease in hours, loss of employment, use of Family Medical Leave Act (FMLA), disability, retirement, or sabbatical.',
        value: 'JOB_CHANGE',
      },
      {
        label: 'My income fluctuates due to self-employment.',
        value: 'SELF_EMPLOYMENT_INCOME_FLUCTUATION',
      },
      { label: 'A reason not listed above', value: 'OTHER' },
    ],
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '186_OTHER',
    name: 'taxHouseholdIncomeDiscrepancyDescriptionText',
    type: 'text',
    dependencies: ['taxHouseholdIncomeDifferenceReasonType', 'coverageYear'],
    display: (values) => values.taxHouseholdIncomeDifferenceReasonType?.includes('OTHER'),
    label: { id: '186_OTHER_Q' },
    help: ({ coverageYear }) => ({ id: '186_OTHER_T', data: { coverageYear } }),
  },
  // {
  // @todo
  // testID: '187'
  // }
];
