import React, { useMemo } from 'react';
import {
  useQuery,
  HealthApplicationQuery,
  useMutation,
  mutations,
  UpsertApplicationMembersVars,
  UpsertApplicationMembersResponse,
  updates,
  UpsertHealthApplication,
} from '@data';
import { optionPresets } from '@f';
import {
  generateMembers,
  boolToTri,
  useCopy,
  calcPovertyLine,
  Segment,
  SegmentEvent,
} from '@app/utils';
import { Link } from '@uikit';
import { SplitFormBlueprint } from '@blueprints';
import { sheets, useSheet } from '@app/navigate';

const Household = ({ applicationID, handleNext }) => {
  const { c, $ } = useCopy('catch.ede');
  const { replace } = useSheet();

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [updateApplication, { loading: updatingApplication }] =
    useMutation(UpsertHealthApplication);

  const [updateMembers, { loading: updatingMembers }] = useMutation<
    UpsertApplicationMembersResponse,
    UpsertApplicationMembersVars
  >(mutations.UPSERT_APPLICATION_MEMBERS, {
    update: updates.UPDATE_APPLICATION_MEMBERS,
  });

  const {
    // applicationID,
    coverageState,
    coverageYear,
    maritalStatus,
    numTaxDependents,
    isSpouseCoverage,
    numDependentsRequestCoverage,
    householdIncome,
  } = useMemo(() => {
    const application = data?.viewerTwo.health.application;

    const normalizedStatus = /MARRIED/.test(data?.viewer.user.filingStatus || '')
      ? 'MARRIED'
      : /SINGLE/.test(data?.viewer.user.filingStatus || '')
      ? 'SINGLE'
      : undefined;

    return {
      applicationID: application?.id,
      coverageState: application?.coverageState,
      coverageYear: application?.coverageYearNumber,
      maritalStatus:
        application?.maritalStatus !== 'UNKNOWN' ? application?.maritalStatus : normalizedStatus,
      numTaxDependents: application?.numTaxDependents || data?.viewer.user.numTaxDependents,
      isSelfCoverage: application?.isSelfCoverage,
      isSpouseCoverage: application?.isSpouseCoverage,
      numDependentsRequestCoverage: application?.numDependentsRequestCoverage,
      householdIncome: application?.householdIncome,
    };
  }, [data?.viewerTwo.health.application, data?.viewer.user]);

  const handleSubmit = async (values) => {
    await Promise.all([
      updateApplication({
        variables: {
          input: {
            id: applicationID || '',
            numTaxDependents: values.numTaxDependents,
            maritalStatus: values.maritalStatus,
            householdIncome: Number(values.householdIncome),
            isSelfCoverage: boolToTri(values.isSelfCoverage || false),
            isSpouseCoverage: boolToTri(values.isSpouseCoverage || false),
            numDependentsRequestCoverage: Number(values.numDependentsRequestCoverage || 0),
          },
        },
      }),

      // handle creating members
      updateMembers({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: generateMembers({
              applicant: data?.viewerTwo.health.application.applicant,
              members: data?.viewerTwo.health.application.members,
              numTaxDependents: values.numTaxDependents,
              maritalStatus: values.maritalStatus,
              isSelfCoverage: boolToTri(values.isSelfCoverage || false),
              isSpouseCoverage: boolToTri(values.isSpouseCoverage || false),
              numDependentsRequestCoverage: Number(values.numDependentsRequestCoverage || 0),
            }),
          },
        },
      }),
    ]);

    Segment.track(SegmentEvent.APPLICATION_HOUSEHOLD_COMPLETED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    handleNext();
  };

  const taxReturns = {
    title: c('HealthEnrollment.S2_tax_returns_title'),
    subtitle: c('HealthEnrollment.S2_tax_returns_content', { coverageYear }),
  };

  const taxDependents = {
    title: c('HealthEnrollment.S2_tax_dependents_title'),
    subtitle: c('HealthEnrollment.S2_tax_dependents_content'),
    accentColor: 'coverage',
  };

  const fields = [
    {
      testID: 'S1',
      name: 'maritalStatus',
      required: true,
      type: 'radio',
      options: optionPresets.MARITAL_STATUS,
      label: c('HealthEnrollment.S1_Q'),
      help: c('HealthEnrollment.S1_T'),
    },
    {
      testID: 'S2',
      name: 'numTaxDependents',
      required: true,
      type: 'stepper',
      headerText: c('HealthEnrollment.S2_Q', { coverageYear }),
      label: c('HealthEnrollment.S2_L'),
      help: c('HealthEnrollment.S2_T', {
        link2: (
          <Link testID="tax-returns" onPress={() => replace(sheets.HELP_TEXT, taxReturns)}>
            {c('HealthEnrollment.S2_T_link2')}
          </Link>
        ),
        link3: (
          <Link testID="tax-dependents" onPress={() => replace(sheets.HELP_TEXT, taxDependents)}>
            {c('HealthEnrollment.S2_T_link3')}
          </Link>
        ),
        coverageYear,
        coverageYearNext: coverageYear ? coverageYear + 1 : '',
      }),
    },
    {
      type: 'section',
      fields: [
        {
          testID: 'S3',
          name: 'S3',
          type: 'label',
          label: c('HealthEnrollment.S3_L'),
          optional: false,
          help: c('HealthEnrollment.S3_T', {
            link: (
              <Link testID="hc.gov" key="link" href="https://healthcare.gov">
                {c('HealthEnrollment.S3_H')}
              </Link>
            ),
          }),
        },
        {
          name: 'isSelfCoverage',
          type: 'checkbox',
          label: 'Me',
        },
        {
          name: 'isSpouseCoverage',
          dependencies: ['maritalStatus'],
          display: ({ maritalStatus }) => maritalStatus === 'MARRIED',
          type: 'checkbox',
          label: 'My spouse',
        },
        {
          name: 'numDependentsRequestCoverage',
          type: 'stepper',
          inline: true,
          dependencies: ['numTaxDependents'],
          display: ({ numTaxDependents }) => numTaxDependents && numTaxDependents > 0,
          max: ({ numTaxDependents }) => numTaxDependents,
          label: 'My dependents',
        },
      ],
    },
    {
      testID: 'S4',
      name: 'householdIncome',
      type: 'option',
      label: ({ maritalStatus, numTaxDependents, coverageYear }) => {
        // set defaults
        const { fpls420 } = calcPovertyLine({
          coverageYear,
          maritalStatus: maritalStatus || 'SINGLE',
          numTaxDependents: numTaxDependents || 0,
          state: coverageState,
        });

        return c('HealthEnrollment.S4_Q', { amount: $(fpls420, { whole: true }) });
      },
      dependencies: ['maritalStatus', 'numTaxDependents', 'coverageYear'],
      options: ({ maritalStatus, numTaxDependents, coverageYear }) => {
        const { fpls420 } = calcPovertyLine({
          coverageYear,
          maritalStatus: maritalStatus || 'SINGLE',
          numTaxDependents: numTaxDependents || 0,
          state: coverageState,
        });

        return [
          {
            key: 'yes',
            label: 'Yes',
            value: householdIncome && householdIncome < fpls420 ? householdIncome : fpls420 - 1000,
          },
          {
            key: 'no',
            label: 'No',
            value: householdIncome && householdIncome > fpls420 ? householdIncome : fpls420 + 1000,
          },
          { key: 'notSure', label: 'Not sure', value: 0 },
        ];
      },
    },
  ];

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={updatingApplication || updatingMembers}
      title={c('Household.title')}
      subtitle={c('Household.subtitle')}
      formConfig={{
        initialValues: {
          coverageYear,
          maritalStatus,
          numTaxDependents,
          isSelfCoverage: true,
          isSpouseCoverage,
          numDependentsRequestCoverage,
          householdIncome: householdIncome > 0 ? householdIncome : undefined,
        },
        fields,
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const HouseholdView = {
  name: 'EDE_HOUSEHOLD',
  component: Household,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Household size',
  },
};
