export const workHoursFields = [
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'parent1WeeklyWorkHourQuantity',
    type: 'number',
    required: true,
    dependencies: ['parent1'],
    label: ({ parent1 }) => ({ id: '242_L', data: { person: parent1 || 'parent' } }),
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'parent2WeeklyWorkHourQuantity',
    type: 'number',
    required: true,
    dependencies: ['parent2'],
    label: ({ parent2 }) => ({ id: '242_L', data: { person: parent2 || 'other parent' } }),
  },
];
