import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@uikit/hooks/useTheme';
import { theme } from '@app/_ui-kit/themes/catch';

export const StyledSearch = styled.input`
  font-family: ${theme.font.face.Body}, sans-serif;
  border: none;
  padding: 16px 16px 16px 24px;
  font-size: 21px;
  font-weight: 500;
  color: white;
  border-bottom: 2px solid #88888822;
  font-family: 'Jetbrains Mono', monospace;
  display: block;
  flex: 0;
  opacity: 0.5;
  background-color: transparent;
  color: ${(p) => p.color};
  &:focus {
    background-color: ${(p) => p.bg};
    opacity: 1;
  }
`;

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000011;
  z-index: 999999;
`;

export const StyledMenuItem = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 999px;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0 0 1px 1px ${(p) => p.border};
  cursor: pointer;
`;

export const StyledDevMenuItem = styled(StyledMenuItem)`
  background-color: ${(p) => (p.isHovered ? p.backgroundHover : p.background)};
  background-image: url(/debug/menu${(p) => (p.dark ? '_dark' : '')}.svg);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: center;
`;

export const StyledHeading = styled.div`
  font-family: ${theme.font.face.Body}, sans-serif;
  color: ${(p) => p.color};
  opacity: 0.5;
  font-size: 12px;
  font-weight: 500;
  margin: 32px 16px 12px;
`;

export const StyledAction = styled.div`
  font-size: 13px;
  font-weight: 400;
  padding: 4px 16px;
  color: ${(p) => p.color};
  cursor: pointer;
  font-family: ${theme.font.face.Body}, sans-serif;
  border-radius: ${theme.corners.sm + 2}px;
  display: flex;
  justify-content: flex-start;
  background-color: ${(p) => (p.selected ? p.backgroundColor : 'transparent')};
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  .mono {
    font-family: 'Jetbrains Mono', monospace;
    margin-left: 12px;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0px;
    text-align: center;
    border-radius: ${theme.corners.sm / 2}px;
    width: 36px;
    height: 26px;
    flex-shrink: 0;
    background: ${(p) => p.hoverBackgroundColor};
  }
  .text {
    flex: 1;
    line-height: 1;
    max-height: 28px;
    word-break: keep-all;
    overflow-wrap: normal;
    white-space: nowrap;
    font-size: 13px;
  }
  .child {
    flex: 0;
  }
  &:hover {
    color: ${(p) => p.hoverColor};
    .mono {
      color: ${(p) => p.hoverColor};
      opacity: 1;
    }
  }
  .flex {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .value {
    font-weight: 500;
  }
  .type {
    opacity: 0.5;
    font-weight: 400;
    margin-right: 4px;
  }
  .workflow,
  .provider {
    opacity: 0.5;
    font-weight: 300;
    margin-right: 8px;
  }
  .outcome {
    margin-right: 8px;
  }
`;

export const DevMenuAction = ({ text, onPress, loading, mono, k, selected, children }) => {
  const { themeColors } = useTheme();
  return (
    <StyledAction
      onClick={onPress}
      color={themeColors.textColor}
      hoverColor={themeColors.secondaryColor}
      backgroundColor={themeColors.skeletonColor}
      hoverBackgroundColor={themeColors.borderColor}
      loading={loading}
      selected={selected}
      mono={mono}
    >
      <div className="text">{text}</div>
      <div className="child">{children}</div>
      <div className="mono">{k}</div>
    </StyledAction>
  );
};

export const DevMenuNew = ({
  onPress,
  loading,
  selected,
  mono,
  provider,
  workflow,
  outcome,
  type,
  value,
  k,
}) => {
  const { themeColors } = useTheme();
  return (
    <StyledAction
      onClick={onPress}
      color={themeColors.textColor}
      hoverColor={themeColors.secondaryColor}
      backgroundColor={themeColors.skeletonColor}
      hoverBackgroundColor={themeColors.borderColor}
      loading={loading}
      selected={selected}
      mono={mono}
    >
      <div className="flex">
        <div>
          <span className="type">{type}</span>
          <span className="value">{value}</span>
        </div>
        <div className="sub">
          <span className="outcome">{outcome}</span>{' '}
          <span className="workflow">
            {provider} {workflow}
          </span>
        </div>
      </div>

      <div className="mono">{k}</div>
    </StyledAction>
  );
};
