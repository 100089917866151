import React, { useMemo } from 'react';
import { Text, Button, Link } from '@uikit';
import { Layout, Stack, Card, ButtonGroup } from '@layouts';
import { useCopy } from '@app/utils';
import { FontSize } from '@app/types';

// has any

interface Props {
  hasAvailablePlans: boolean;
  numCanEnroll: number;
  allMedicaidOrChip: boolean;

  makeChanges: () => void;
  getNotice: () => void;
  gettingNotice: boolean;
  handleNext: () => void;
}

type NextStepsType =
  | 'NO_AVAILABLE_PLANS'
  | 'NOT_ELIGIBLE'
  | 'ALL_MEDICAID_OR_CHIP'
  | 'MARKETPLACE_ELIGIBLE';

/**
 * Handles next steps
 * Shows the following flavors:
 *   - No available plans
 *   - All medicaid/CHIP eligible
 *   - Eligible to enroll
 *   - No eligibility
 */
const EligibilityNextSteps: React.FC<Props> = ({
  hasAvailablePlans,
  numCanEnroll,
  allMedicaidOrChip,
  makeChanges,
  getNotice,
  gettingNotice,
  handleNext,
}) => {
  const { c } = useCopy('catch.ede.EligibilityResults');

  const copy: Record<NextStepsType, { title: string; description: string }> = {
    MARKETPLACE_ELIGIBLE: {
      title: c('notFinished'),
      description: c('MARKETPLACE_ELIGIBLE.description'),
    },
    NO_AVAILABLE_PLANS: {
      title: c('nextSteps'),
      description: c('NO_AVAILABLE_PLANS.description'),
    },
    NOT_ELIGIBLE: {
      title: c('nextSteps'),
      description: c('NOT_ELIGIBLE.description'),
    },
    ALL_MEDICAID_OR_CHIP: {
      title: c('nextSteps'),
      description: c('ALL_MEDICAID_OR_CHIP.description'),
    },
  };

  const type: NextStepsType = useMemo(() => {
    if (numCanEnroll > 0) {
      return hasAvailablePlans ? 'MARKETPLACE_ELIGIBLE' : 'NO_AVAILABLE_PLANS';
    }

    if (allMedicaidOrChip) {
      return 'ALL_MEDICAID_OR_CHIP';
    }

    return 'NOT_ELIGIBLE';
  }, [hasAvailablePlans, numCanEnroll]);

  return (
    <Stack spacing="1">
      {numCanEnroll > 0 && (
        <Text size="fp" align="right">
          {c('eligibleToEnroll', { numCanEnroll })}
        </Text>
      )}

      <Card gradient="coverageLight">
        <Stack spacing="1">
          <Layout.Header title={copy[type].title} subtitle={copy[type].description} />
          <Link testID="cic-trigger" size={FontSize.fp} onPress={makeChanges}>
            {c('CICtrigger')}
          </Link>
          <ButtonGroup>
            <Button
              testID="view-notice"
              alt
              onPress={getNotice}
              wide
              loading={gettingNotice}
              disabled={gettingNotice}
            >
              {c('viewEligibleNoticeBtn')}
            </Button>
            {numCanEnroll > 0 && hasAvailablePlans && (
              <Button testID="enroll-now" onPress={handleNext} wide reverse>
                {c('CTA')}
              </Button>
            )}
          </ButtonGroup>
        </Stack>
      </Card>
    </Stack>
  );
};

export default EligibilityNextSteps;
