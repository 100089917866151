import { useMemo } from 'react';
import { Goal } from '@app/types';

/**
 * This is the total amount of current withholding excluding this goal
 * e.g. a user could could set this estimator to 25 if the existing percentage was 75
 */
export default function useTotalPaycheckPercentage(goals: Goal[], goal?: Goal) {
  return useMemo(() => {
    return goals.reduce((total, g) => {
      return total + (g.id === goal?.id ? 0 : g.withholdingPercentage);
    }, 0);
  }, [goals]);
}
