import React, { useCallback, useMemo, useState } from 'react';

import {
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  useCurrentRoute,
  useCurrentParams,
  navigate,
  close,
} from '@navigate';

import { Route } from '@app/types';
import { GET_INCOME, useFintechQuery } from '@app/data';
import { Loading } from '@uikit';
import { PaycheckBreakdownView, PaycheckSkippedView, PaycheckIncomeView } from './paycheck';
import { ApprovalType } from './types';

const config: StackDefinition = {
  stackName: stacks.PAYCHECK_STACK,
  options: { layout: 'sheet' },
  screens: [PaycheckBreakdownView, PaycheckSkippedView, PaycheckIncomeView], // @todo E-3445 typescript
};

interface HandleNextProps {
  id?: string;
  paycheck?: any;
  approvalType?: ApprovalType;
}

const PaycheckStack: StackComponent = () => {
  const route = useCurrentRoute();
  const { incomeId } = useCurrentParams();

  const [approvalType, setApprovalType] = useState<ApprovalType>();

  // @todo replace with a lookup by income ID
  const { loading, data } = useFintechQuery(GET_INCOME, { variables: { incomeId } });
  const income = data?.income;

  const handleNext = useCallback(
    (updates?: HandleNextProps) => {
      if (updates?.approvalType) {
        setApprovalType(updates.approvalType);
      }

      switch (route) {
        case Route.PAYCHECK_INCOME:
          navigate(Route.PAYCHECK_BREAKDOWN, { done: false });
          break;
        case Route.PAYCHECK_BREAKDOWN:
        case Route.PAYCHECK_SKIPPED:
          close();
          break;
        default:
          console.log({ route });
          break;
      }
    },
    [route, incomeId],
  );

  const stackData = useMemo(() => {
    return { income, approvalType, handleNext };
  }, [income, approvalType, handleNext]);

  if (loading) {
    return <Loading full />;
  }

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={stackData}
    />
  );
};

PaycheckStack.config = config;
export default PaycheckStack;
