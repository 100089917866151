import { Card, Stack } from '@app/layouts';
import { Text, Asset } from '@uikit';
import { useCopy } from '@app/utils';
import { FontSize, FontWeight } from '@app/types';
import Close from '@svg/close.svg';

const HealthDocsUploadCard = ({ children, values, remove }) => {
  const { c } = useCopy('catch.ede.enums');

  return (
    <Card border>
      <Asset
        onPress={remove}
        svg={Close}
        size="glyph"
        containerSize="sm"
        shape="circle"
        bg="skeleton"
        absolute
      />
      <Stack spacing="2">
        <Text size={FontSize.lg} weight={FontWeight.medium}>
          {c(values.dmiDocumentType || values.sviDocumentType)}
        </Text>
        <Stack spacing="2">
          <div>{children}</div>
        </Stack>
      </Stack>
    </Card>
  );
};

export default HealthDocsUploadCard;
