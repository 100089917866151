import gql from 'graphql-tag';

export interface ImportExplorerFromAppVars {
  applicationID: string;
  coverageYear: number;
}

export interface ImportExplorerFromAppResponse {
  importExplorerFromApplication: {
    id: string;
    pathwayType?: string; // @todo
    zipcode?: string;
    countyfips?: string;
    state?: string;
    coverageYear?: number;
    providerPlanID?: string;
    income?: number;
  };
}

export const ImportExplorerFromApp = gql`
  mutation ImportExplorerFromApplication($applicationID: ID!, $coverageYear: Int!) {
    importExplorerFromApplication(id: $applicationID, coverage_year: $coverageYear) {
      id
      pathwayType
      zipcode
      countyfips
      state
      coverageYear
      providerPlanID
      income
    }
  }
`;
