import React from 'react';
import { ComplexRow, Stepper, Text, FormLabel } from '@uikit';
import { Stack } from '@layouts';
import { InputProps } from '../types';
import { FontSize } from '@app/types';

interface StepperInputProps<T> extends InputProps<T> {
  label: string | ((value: any) => string);
  stepperType: 'percentage' | 'integer';
  increment?: number;
  min?: number;
  max?: number;
  headerText?: string;
  help?: string;
  inline?: boolean;
  ariaLabelDecrease?: string;
  ariaLabelIncrease?: string;
}

const StepperInput = React.forwardRef(
  <T extends any>(
    {
      value,
      stepperType,
      onChange,
      onBlur,
      increment,
      min,
      max,
      inline,
      label,
      headerText,
      help,
      optional,
      required,
      testID,
      disabled,
      error,
      ariaLabelDecrease,
      ariaLabelIncrease,
    }: StepperInputProps<T>,
    ref,
  ) => {
    const stepper = (
      <Stack spacing="1">
        {!inline && (
          <FormLabel label={headerText} help={help} optional={optional} required={required} />
        )}

        <Stepper
          testID={testID}
          value={value}
          onChange={(val) => {
            onChange(val);
            onBlur();
          }}
          step={increment}
          min={min}
          max={max}
          suffix={stepperType === 'percentage' ? '%' : ''}
          large={inline ? false : true}
          disabled={disabled}
          ariaLabelDecrease={ariaLabelDecrease}
          ariaLabelIncrease={ariaLabelIncrease}
        />

        {inline ? null : (
          <Text fluid align="center" color="subtle">
            {label}
          </Text>
        )}

        {error && (
          <Text fluid align="center" color="negative" size={FontSize.sm}>
            {error}
          </Text>
        )}
      </Stack>
    );

    return inline ? (
      <ComplexRow testID="" label={headerText} sublabel={label} accessory={stepper} />
    ) : (
      stepper
    );
  },
);

StepperInput.displayName = 'StepperInput';
export default StepperInput;
