import React from 'react';
import { View } from 'react-native';
import { Text } from './Text';
import { DefinitionListItem } from './DefinitionList';
import { Stack } from '@app/layouts';

interface LineItemProps {
  thin?: boolean;
  right: React.ReactNode;
  left: React.ReactNode;
  prominent?: boolean;
  loading?: boolean;
  testID?: string;
  mono?: boolean;
  asDefinitionList?: boolean;
}

export const LineItem = ({
  prominent,
  loading,
  left,
  right,
  testID,
  mono,
  asDefinitionList = false,
}: LineItemProps) => {
  /** Common Text props */
  const textProps = {
    mono,
    weight: prominent ? 'semibold' : 'medium',
    color: prominent ? 'text' : 'secondary',
    size: prominent ? 'p' : 'fp',
  };

  /** Render semantic dt and dd elements inside a dl. Keep the same style props as LineItem */
  if (asDefinitionList) {
    return (
      <DefinitionListItem term={left} description={right} testID={testID} textProps={textProps} />
    );
  }

  return (
    <Stack horizontal flexItem={0} testID={testID}>
      <View>
        <Text {...textProps}>{left}</Text>
      </View>
      <View>
        <Text
          {...textProps}
          style={{ display: 'flex', alignItems: 'center', alignSelf: 'stretch' }}
          tabular
          align="right"
        >
          {right}
        </Text>
      </View>
    </Stack>
  );
};

export default LineItem;
