import React from 'react';
import { PopupModal } from 'react-calendly';
import { CALENDLY_LINK } from '../config';
import { Button } from '@app/_ui-kit';
import { useCalendly } from '../useCalendly';
import { useToggle } from '@app/hooks';
import { ConciergeType, RequestConcierge, ScheduleConcierge, useMutation } from '@app/data';

export interface ScheduleButtonProps {
  onPress?: () => void;
  onScheduled?: () => void;
  children: string;
}

export function ScheduleButton({
  onPress,
  onScheduled,
  children,
}: ScheduleButtonProps): React.ReactNode {
  const toggle = useToggle();

  const [requestConcierge] = useMutation(RequestConcierge, {
    variables: { input: { conciergeType: ConciergeType.Phone } },
  });

  const [scheduleConcierge] = useMutation(ScheduleConcierge, {
    variables: { input: { conciergeType: ConciergeType.Phone } },
  });

  const handleRequest = () => {
    toggle.open();
    requestConcierge();
    if (onPress) onPress();
  };

  const handleScheduled = () => {
    scheduleConcierge();
    if (onScheduled) onScheduled();
  };

  const { prefill, pageSettings } = useCalendly({ onScheduled: handleScheduled });

  return (
    <>
      <Button testID="open-calendly" onPress={handleRequest}>
        {children}
      </Button>
      <PopupModal
        url={CALENDLY_LINK}
        onModalClose={toggle.close}
        open={toggle.isOpen}
        rootElement={document.getElementById('app') as HTMLElement}
        prefill={prefill}
        pageSettings={pageSettings}
      />
    </>
  );
}
