import React from 'react';
import { View, ScrollView } from 'react-native';
import { Stack } from '@layouts';
import { CatchTheme } from '@uikit/themes/catch';
import { spacing as _oldSpacing } from '@uikit/theme/spacing';
import { useResponsive } from '@uikit/hooks/useResponsive';

type SnapMode = 'paged' | 'guided' | 'none';

interface CarouselProps {
  children?: any;
  itemWidth?: number;
  spacing?: string; // as defined here in theme/spacing.js, should change
  snapMode?: SnapMode;
  fullWidth?: boolean; //if you need this to be the full with on desktop web
}

const Carousel = ({
  children,
  itemWidth,
  spacing = '0',
  snapMode = 'guided',
  fullWidth,
}: CarouselProps) => {
  const { res } = useResponsive(fullWidth ? carouselSpacing : normalSpacing);
  const canSnap = !!itemWidth && itemWidth > 0;
  const shouldSnap = !!canSnap && snapMode !== 'none';

  return (
    <View style={res.mobileSnap}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        snapToInterval={!shouldSnap ? undefined : itemWidth + _oldSpacing[spacing] * 2}
        decelerationRate={!!shouldSnap ? 'fast' : 'normal'}
        style={res.margins}
        alwaysBounceVertical={false}
        disableIntervalMomentum={!!shouldSnap && snapMode === 'paged'}
      >
        <Stack horizontal spacing={spacing} style={stackStyle}>
          {children}
        </Stack>
      </ScrollView>
    </View>
  );
};

const stackStyle = { alignItems: 'flex-start', flex: 1, height: '100%' };

const normalSpacing = {
  margins: {
    paddingHorizontal: 0,
  },
  mobileSnap: {
    margin: -CatchTheme.spacing.mobile.margins,
    width: 'auto',
  },
};

const carouselSpacing = {
  margins: {
    paddingHorizontal: `max(0px, calc(0.5 * calc(100vw - ${CatchTheme.containers.page}px)))`,
  },
  mobileSnap: {
    marginHorizontal: `min(${-CatchTheme.spacing.desktop.margins}px, calc(-0.5 * calc(100vw - ${
      CatchTheme.containers.page - CatchTheme.spacing.desktop.margins * 2
    }px) ))`,
    width: 'auto',
    marginTop: -CatchTheme.spacing.mobile.margins,
    marginBottom: -CatchTheme.spacing.mobile.margins * 1.5,
  },
};

export default Carousel;
