import { boolToTri, currentYear, lastTuesdayOfJuly, safeFormatDate } from '@util';
import { isBefore } from 'date-fns';

export const getInitialValues = (application, isResubmit, isGA) => {
  // for question #236, we need to use earlier dates until the IRS
  // updates data on the last Tuesday in July
  const useEarlierYears = isBefore(new Date(), lastTuesdayOfJuly());
  const year1 = currentYear - (useEarlierYears ? 3 : 2);

  return {
    isGA,
    year1,
    year2: year1 + 1,
    isResubmit,
    coverageState: application?.coverageState,
    preliminaryAPTCStatus: application?.applicant?.preliminaryAPTCStatus,
    isRequestingFinancialAssistance: application?.screening?.isRequestingFinancialAssistance,
    members: [application?.applicant, ...(application?.members || [])],
    legalName: application?.applicant?.legalName,
    isRenewalAgreement: boolToTri(application?.isRenewalAgreement || (isGA ? true : undefined)),
    renewEligibilityYearQuantity:
      application?.renewEligibilityYearQuantity || (isGA ? 5 : undefined),
    isMedicaidRequirementAgreement: boolToTri(application?.isMedicaidRequirementAgreement),
    isAbsentParentAgreement: boolToTri(application?.isAbsentParentAgreement),
    isTerminateCoverageOtherMecFoundAgreement: boolToTri(
      application?.isTerminateCoverageOtherMecFoundAgreement,
    ),
    isChangeInformationAgreement: boolToTri(application?.isChangeInformationAgreement),
    isPenaltyOfPerjuryAgreement: application?.isPenaltyOfPerjuryAgreement,
    isTaxCreditReconciled: application?.isTaxCreditReconciled,
  };
};

export const formatPayload = ({ applicationID, values, isResubmit, preliminaryAPTCStatus }) => {
  const safeBoolToTri = (val) => {
    return typeof val === 'boolean' ? boolToTri(val) : val;
  };

  return {
    variables: {
      applicationID,
      minimalEnsure: isResubmit,
      input: {
        id: values?.id,
        lastUsedRoute: 'agreements',
        isRenewalAgreement: values?.isRenewalAgreement,
        renewEligibilityYearQuantity: !values?.isRenewalAgreement
          ? null
          : values?.isRenewalAgreement === 'NO'
          ? parseInt(values?.renewEligibilityYearQuantity, 10)
          : 5,
        isTaxCreditReconciled:
          preliminaryAPTCStatus !== 'YES' ? null : values?.isTaxCreditReconciled ? 'YES' : 'NO',
        isMedicaidRequirementAgreement: values?.isMedicaidRequirementAgreement,
        isAbsentParentAgreement: values?.isAbsentParentAgreement,
        isTerminateCoverageOtherMecFoundAgreement: safeBoolToTri(
          values?.isTerminateCoverageOtherMecFoundAgreement,
        ),
        isChangeInformationAgreement: safeBoolToTri(values?.isChangeInformationAgreement),
        isPenaltyOfPerjuryAgreement: boolToTri(values?.isPenaltyOfPerjuryAgreement),
        signatureText: values?.signatureText,
        signatureDate: safeFormatDate(new Date(), 'yyyy-MM-dd'),
        signatureType: 'APPLICANT',
      },
    },
  };
};
