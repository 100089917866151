import { useMemo } from 'react';
import { differenceInMinutes } from 'date-fns';
import {
  useQuery,
  useMutation,
  FIND_OR_CREATE_USER,
  GET_CURRENT_USER,
  CACHE_UPDATE_CURRENT_USER,
} from '@data';
import { useToken } from '@global';
import { useSignupParams } from './useSignupParams';

export const useCurrentUser = () => {
  const { token } = useToken();
  const { markAsOnboarding } = useSignupParams();

  const [findOrCreateUser] = useMutation(FIND_OR_CREATE_USER, {
    onCompleted: (data) => {
      const now = new Date();
      const createdOn = new Date(data?.createUser?.createdOn);
      const justRegistered = differenceInMinutes(now, createdOn) === 0;

      if (justRegistered) {
        markAsOnboarding();
      }
    },
    update: (cache, result) => CACHE_UPDATE_CURRENT_USER(cache, result),
  });

  const { data } = useQuery(GET_CURRENT_USER, {
    skip: !token,
  });

  /**
   * Logic for determining whether a user has fully onboarded
   * Currently, there are no requirements for onboarding
   */
  const isOnboarded = useMemo(() => {
    return true;
  }, [data]);

  return {
    user: data?.me.user,
    findOrCreateUser,
    isOnboarded,
  };
};
