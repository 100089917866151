import React, { useState } from 'react';

import { Text } from '../Text';
import { Pressable } from '../Pressable';
import { HelpSheetBlueprint } from '@app/blueprints/HelpSheetBlueprint';
import { Dialog } from '../Dialog';

/**
 * Shows the tooltip details in a modal or action sheet
 * - Always opened by an onPress
 */
const ContentTooltip = ({ content, label, title, inline, style, triggerStyle, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const open = () => setIsVisible(true);
  const close = () => setIsVisible(false);

  const details = content || label;

  const text =
    details?.type === 'rich'
      ? {
          title: title || details?.title,
          label: details?.p,
          sub: details?.sub,
        }
      : {
          title: title || '',
          label: details,
          sub: '',
        };

  return (
    <>
      {inline ? (
        React.cloneElement(children, { onPress: open })
      ) : (
        <Pressable accessibilityRole="button" handleOnPress={open} style={[triggerStyle, style]}>
          {children}
        </Pressable>
      )}

      <Dialog isOpen={isVisible} onRequestClose={close}>
        <HelpSheetBlueprint title={text.title}>
          <Text size="fp" color="secondary">
            {text.sub}
          </Text>
          <Text size="fp" color="secondary">
            {text.label}
          </Text>
        </HelpSheetBlueprint>
      </Dialog>
    </>
  );
};

export default ContentTooltip;
