// @ts-nocheck
import React, { useMemo, useEffect } from 'react';
import { HiccupBlueprint } from '@blueprints';
import { useQuery, queries, HealthExplorerQuery } from '@data';
import { useCopy } from '@app/utils';
import { useNudge } from '@app/hooks';
import { open, ScreenDefinition } from '@navigate';
import { Loading, Link, stateIcons } from '@uikit';
import { Route } from '@types';

const ExplorerStateReturn = () => {
  const { c } = useCopy('catch.healthExplorer.explorerStateReturn');

  const { data } = useQuery(HealthExplorerQuery);

  const { coverageYear, state } = useMemo(() => {
    return {
      coverageYear: data?.viewerTwo?.healthExplorerData?.coverageYear,
      state: data?.viewerTwo?.healthExplorerData?.state || '',
    };
  }, [data?.viewerTwo?.healthExplorerData]);

  const { dismissNudge: toggleExited } = useNudge(`healthEnrollmentIsNotExited${coverageYear}`);

  const { loading, data: stateData } = useQuery<StateReferenceQueryData, StateReferenceQueryVars>(
    queries.STATE_REFERENCE,
    {
      variables: { state },
      skip: !state,
    },
  );

  const { url, name } = stateData?.reference?.stateHealthReference || {};

  const openURL = () => {
    window.open(url);
  };

  useEffect(() => {
    if (coverageYear) toggleExited();
  }, [coverageYear]);

  if (!state || loading) return <Loading accentColor="coverage" />;

  return (
    <HiccupBlueprint
      title={c('title')}
      align="center"
      subtitles={[
        c('description'),
        <Link testID="state-exchange-link" key="state-exchange-link" onPress={openURL}>
          Back to {name}
        </Link>,
      ]}
      onPress={() => open(Route.WALLET_ITEM, { item: { vertical: 'HEALTH' } })}
      buttonText={c('btnAddToWallet')}
      asset={{ icon: stateIcons[state], color: 'text' }}
    />
  );
};

export const ExplorerStateReturnView: ScreenDefinition = {
  name: Route.STATE_RETURN,
  component: ExplorerStateReturn,
  options: {},
};
