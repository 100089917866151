import { useEffect } from 'react';

import { useAuth } from '@auth';
import { ScreenDefinition } from '@navigate';
import { Route } from '@types';

const LoginComponent = () => {
  const { login } = useAuth();
  useEffect(() => login(), []);

  // @todo - what do we want to show here (it should be pretty quick)
  return null;
};

export const LoginView: ScreenDefinition = {
  name: Route.LOGIN,
  component: LoginComponent,
  guest: true,
  options: {
    title: 'Sign In',
    largeTitle: true,
    drawBehindNav: true,
  },
};
