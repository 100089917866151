import gql from 'graphql-tag';

const TERMINATE_HEALTH_ENROLLMENT = gql`
  mutation TerminateHealthEnrollment(
    $id: ID!
    $cancelActiveCoverage: Boolean
    $actionEffectiveDate: Date
  ) {
    terminateHealthInsuranceEnrollment(
      id: $id
      cancelActiveCoverage: $cancelActiveCoverage
      actionEffectiveDate: $actionEffectiveDate
    ) {
      InsuranceEnrollment {
        id
        status
      }
      error {
        field
        message
        code
      }
    }
  }
`;

export default {
  document: TERMINATE_HEALTH_ENROLLMENT,
  getErrors: (data) =>
    data?.terminateHealthInsuranceEnrollment?.error
      ? [data?.terminateHealthInsuranceEnrollment?.error]
      : null,
};
