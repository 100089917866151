import React from 'react';
import {
  Copy,
  TextColor,
  BackgroundColor,
  AssetProps,
  AccessoryType,
  InteractionAccessoryType,
} from '@types';
import { Text } from '../Text';
import { Pressable } from '../Pressable';

/**
 * parent component
 */
import BaseRow from './BaseRow';

interface Props {
  loading?: boolean;
  testID: string;
  textColor?: TextColor;
  asset?: AssetProps;
  label: Copy;
  interaction?: InteractionAccessoryType;
  disabled?: boolean;
  onPress?: any;
  onLabelPress?: any;
  bg?: BackgroundColor;
  selectedBg?: BackgroundColor;
  selectedByDefault?: boolean;
  bold?: boolean;
  light?: boolean;
  fp?: boolean;
  inset?: boolean;
  accessory?: AccessoryType;
  sensitive?: boolean;
  noPadding?: boolean;
  raised?: boolean;
}

/**
 * The base row only hold the common aspects of different types of row.
 * Not designed be used in UI Kit, only use as base for other row components.
 *
 * @todo should the typing be more hierarchical to reflect this^?
 * (e.g. BaseRowProps, ComplexRowProps extends that, etc...)
 */
const SimpleRow = ({
  loading,
  testID,
  textColor = 'text',
  label,
  asset,
  accessory,
  inset,
  interaction,
  disabled,
  onPress,
  onLabelPress,
  bg,
  selectedBg,
  selectedByDefault,
  bold,
  light,
  fp,
  sensitive,
  noPadding,
  raised,
}: Props) => {
  return (
    <BaseRow
      inset={inset}
      loading={loading}
      testID={testID}
      accessory={accessory}
      asset={asset}
      selectedByDefault={selectedByDefault}
      selectedBg={selectedBg}
      noPadding={noPadding}
      bg={bg}
      raised={raised}
      content={
        <Pressable
          testID={`${testID}-label`}
          handleOnPress={onLabelPress}
          style={[
            // ensures that the text only takes up as much space as it needs
            { alignSelf: 'flex-start' },
            // for when we want content to have opacity, but still be interactable
          ]}
        >
          <Text
            inline
            loading={loading}
            color={textColor}
            size={fp ? 'fp' : 'p'}
            weight={bold ? 'semibold' : light ? 'regular' : 'medium'}
            underline={!!onLabelPress}
          >
            {label}
          </Text>
        </Pressable>
      }
      interaction={interaction}
      disabled={disabled}
      onPress={onPress}
      sensitive={sensitive}
    />
  );
};

export default SimpleRow;
