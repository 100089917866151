import { boolToTri } from '@app/utils';
import { Trinary, HealthMaritalStatus, HealthTaxReturnFilingStatusType } from '@app/types';

interface MemberPayload {
  id?: string;
  relation: 'SELF' | 'SPOUSE' | 'CHILD';
  isTaxFiler: Trinary;
  isTaxDependent: Trinary;
  maritalStatus?: HealthMaritalStatus; // only for self/spouse
  taxReturnFilingStatusType?: HealthTaxReturnFilingStatusType; // only for self/spouse
  isClaimsDependent?: Trinary;
  isEnrolledInIchra: Trinary;
  isOfferedIchra: Trinary;
  isRequestingCoverage: Trinary;
}

interface SelfOrSpousePayload extends MemberPayload {
  maritalStatus: HealthMaritalStatus; // only for self/spouse
  taxReturnFilingStatusType: HealthTaxReturnFilingStatusType; // only for self/spouse
}

export const generateMembers = ({
  applicant,
  members,
  maritalStatus,
  numTaxDependents,
  isSelfCoverage,
  isSpouseCoverage,
  numDependentsRequestCoverage,
}: {
  applicant: { id: string; relation: 'SELF' | null };
  members: Array<{ relation: 'SELF' | 'SPOUSE' | 'CHILD' }>;
  maritalStatus: 'SINGLE' | 'MARRIED';
  numTaxDependents: number;
  isSelfCoverage: Trinary;
  isSpouseCoverage: Trinary;
  numDependentsRequestCoverage: number;
}): Array<MemberPayload> => {
  const prepopulateMembers: Array<MemberPayload> = [
    {
      id: applicant?.id || undefined,
      ...selfPayload({
        maritalStatus: maritalStatus,
        numTaxDependents: numTaxDependents,
        isRequestingCoverage: isSelfCoverage,
      }),
    },
  ];

  if (members?.length === 0) {
    if (maritalStatus === 'MARRIED') {
      prepopulateMembers.push(
        spousePayload({
          numTaxDependents,
          isRequestingCoverage: isSpouseCoverage,
        }),
      );
    }
    if (numTaxDependents > 0) {
      for (let i = 0; i < numTaxDependents; i++) {
        prepopulateMembers.push(
          childPayload({
            isRequestingCoverage: boolToTri(i < numDependentsRequestCoverage),
          }),
        );
      }
    }
  } else {
    const { spouse, dependents } = members.reduce(
      (acc, member) => {
        if (member?.relation === 'SELF') return { ...acc, self: member };
        if (member?.relation === 'SPOUSE') return { ...acc, spouse: member };
        return { ...acc, dependents: [...acc.dependents, member] };
      },
      { self: null, spouse: null, dependents: [] },
    );

    if (!!spouse) {
      prepopulateMembers.push({
        id: spouse?.id,
        ...spousePayload({
          numTaxDependents: numTaxDependents,
          isRequestingCoverage: isSpouseCoverage,
        }),
      });
    } else {
      // no spouse on members, but marked as
      if (maritalStatus === 'MARRIED') {
        prepopulateMembers.push(
          spousePayload({
            numTaxDependents,
            isRequestingCoverage: isSpouseCoverage,
          }),
        );
      }
    }

    // upserts existing dependents
    if (dependents?.length > 0) {
      dependents.forEach((member, i) => {
        prepopulateMembers.push({
          id: member?.id,
          ...childPayload({
            isRequestingCoverage: boolToTri(i < numDependentsRequestCoverage),
          }),
        });
      });
    }

    // if more dependents than already on application,
    // we need to add them
    for (let i = 0; i < numTaxDependents - dependents?.length; i++) {
      prepopulateMembers.push(
        childPayload({
          isRequestingCoverage: boolToTri(i + dependents?.length < numDependentsRequestCoverage),
        }),
      );
    }
  }

  return prepopulateMembers;
};

/**
 * Infers a marital status for a self member
 * - Can provide EITHER maritalStatus or hasSpouse
 * - If maritalStatus is properly defined, we return that
 * - Otherwise, we return based on the hasSpouse boolean
 */
export const inferMaritalStatus = ({
  maritalStatus,
  hasSpouse = false,
}: {
  maritalStatus?: HealthMaritalStatus;
  hasSpouse?: boolean;
}) => {
  // if the marital status is already single or married, use that
  if (maritalStatus && /SINGLE|MARRIED/.test(maritalStatus)) return maritalStatus;

  // otherwise check the boolean has spouse
  return hasSpouse ? 'MARRIED' : 'SINGLE';
};

/**
 * Infers filing status based on num tax dependents and marital status
 * Note: the marital status should be inferred as well
 */
export const inferFilingStatus = ({
  numTaxDependents = 0,
  maritalStatus,
  hasSpouse,
}: {
  numTaxDependents: number;
  maritalStatus?: HealthMaritalStatus;
  hasSpouse?: boolean;
}): HealthTaxReturnFilingStatusType => {
  const inferredStatus = inferMaritalStatus({ maritalStatus, hasSpouse });

  if (inferredStatus === 'MARRIED') {
    return 'MARRIED_FILING_JOINTLY';
  } else if (numTaxDependents > 0) {
    return 'HEAD_OF_HOUSEHOLD';
  } else {
    return 'SINGLE_FILER';
  }
};

/**
 * Creates a payload for adding self
 * Assumes no spouse or dependents and is requested coverage
 * Note: one of maritalStatus or hasSpouse should be defined
 * If it's not, we default back to single type person
 */

export const selfPayload = ({
  isRequestingCoverage = 'YES',
  numTaxDependents = 0,

  // one of these should be defined
  maritalStatus,
  hasSpouse,
}: {
  isRequestingCoverage: Trinary;
  numTaxDependents: number;
  maritalStatus?: HealthMaritalStatus;
  hasSpouse?: boolean;
}): SelfOrSpousePayload => ({
  relation: 'SELF',
  isTaxFiler: 'YES',
  isTaxDependent: 'NO',
  maritalStatus: inferMaritalStatus({ maritalStatus, hasSpouse }),
  taxReturnFilingStatusType: inferFilingStatus({
    numTaxDependents,
    maritalStatus,
    hasSpouse,
  }),
  isClaimsDependent: boolToTri(numTaxDependents > 0),
  isEnrolledInIchra: 'NO',
  isOfferedIchra: 'NO',
  isRequestingCoverage,
});

/**
 * Creates a payload for adding a spouse as health application member
 * Assumes is requesting coverage and num tax dependents is 0 (unless otherwise specified)
 * Given num tax dependents is 0
 */

export const spousePayload = ({
  isRequestingCoverage = 'YES',
  numTaxDependents = 0,
}: {
  isRequestingCoverage: Trinary;
  numTaxDependents: number;
}): SelfOrSpousePayload => ({
  relation: 'SPOUSE',
  maritalStatus: 'MARRIED',
  isTaxFiler: 'YES',
  isTaxDependent: 'NO',
  taxReturnFilingStatusType: 'MARRIED_FILING_JOINTLY',
  isClaimsDependent: boolToTri(numTaxDependents > 0),
  isEnrolledInIchra: 'NO',
  isOfferedIchra: 'NO',
  isRequestingCoverage,
});

/**
 * Creates a payload for adding a child as health application member
 * Assumes is requesting coverage by default
 */
export const childPayload = ({
  isRequestingCoverage = 'YES', // Assumes child is requesting coverage
}: {
  isRequestingCoverage: Trinary;
}): MemberPayload => ({
  relation: 'CHILD',
  isTaxDependent: 'YES',
  isTaxFiler: 'NO',
  isEnrolledInIchra: 'NO',
  isOfferedIchra: 'NO',
  isRequestingCoverage,
});
