import React from 'react';
import { fields } from '@app/config';
import { GOAL_TYPE_TO_NAME } from '@app/constants/goals';
import useTaxRecommendations from '@app/features/goals/hooks/useTaxRecommendations';
import useTotalPaycheckPercentage from '@app/features/goals/hooks/useTotalPaycheckPercentage';
import { EstimatorValues } from '@app/features/goals/routes/GoalEstimatorView';
import { FormConfig } from '@app/forms';
import { useCopy } from '@app/utils';
import { BasicFormBlueprint, SheetBlueprint } from '@blueprints';
import {
  GET_GOAL_ESTIMATOR_PAGE,
  GET_GOAL_SETUP_DETAILS,
  GoalType,
  UPDATE_GOAL,
  useFintechMutation,
  useFintechQuery,
  useQuery,
} from '@data';
import { SheetComponentProps } from '@navigate';

const WithholdingEditSheet: React.FC<SheetComponentProps['WITHHOLDING_EDIT']> = ({
  closeSheet,
  goal,
}) => {
  const { c, $ } = useCopy('catch.goal.withholding.edit');
  const [updateGoal, { loading: updating }] = useFintechMutation(UPDATE_GOAL, {
    onCompleted: closeSheet,
  });
  const estimator = useFintechQuery(GET_GOAL_ESTIMATOR_PAGE);
  const setup = useQuery(GET_GOAL_SETUP_DETAILS);

  const goals = estimator?.data?.goals || [];
  const loading = setup.loading || estimator.loading;
  const user = setup?.data?.me?.user;
  const grossIncome = user?.income?.estimatedGrossIncome;

  const taxRecommendations = useTaxRecommendations(user);
  const totalPaycheckPercentage = useTotalPaycheckPercentage(goals, goal);

  const formConfig: FormConfig<EstimatorValues> = {
    initialValues: {
      grossIncome,
      paycheckPercentage: goal.withholdingPercentage,
      totalPaycheckPercentage,
    },
    fields: [fields.PAYCHECK_PERCENTAGE],
    onSubmit: (values) => {
      if (!goal) {
        console.warn('Goal not defined when updating withholding');
      } else {
        updateGoal({
          variables: {
            input: {
              id: goal?.id || '',
              withholdingPercentage: values.paycheckPercentage,
            },
          },
        });
      }
    },
  };

  const goalType = goal.type || GoalType.Custom;
  const goalName = goal.name || GOAL_TYPE_TO_NAME[goalType];

  const subtitle =
    goal.type === GoalType.TaxSavings
      ? c(`${GoalType.TaxSavings}.subtitle`, {
          income: $(grossIncome || 0, { whole: 2 }),
          withholdingPercentageRecommended: Math.round(
            (taxRecommendations?.roundedPaycheckPercentageFederal || 0) * 100,
          ),
        })
      : c('subtitle', { goalName });

  return (
    <SheetBlueprint title={c('title')} subtitle={subtitle} hasBottomActions>
      <BasicFormBlueprint
        button="Confirm"
        formConfig={formConfig}
        loading={loading}
        submitting={updating}
      />
    </SheetBlueprint>
  );
};

export default WithholdingEditSheet;
