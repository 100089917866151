import React, { useEffect } from 'react';
import { InlineWidget } from 'react-calendly';
import { ConciergeType, RequestConcierge, ScheduleConcierge, useMutation } from '@app/data';
import { CALENDLY_LINK } from '../config';
import { useCalendly } from '../useCalendly';

export function ScheduleWidget(): React.ReactNode {
  const [requestConcierge] = useMutation(RequestConcierge);
  const [scheduleConcierge] = useMutation(ScheduleConcierge);

  useEffect(() => {
    requestConcierge({ variables: { input: { conciergeType: ConciergeType.Phone } } });
  }, []);

  const { prefill, pageSettings } = useCalendly({
    onScheduled: () => {
      scheduleConcierge({ variables: { input: { conciergeType: ConciergeType.Phone } } });
    },
  });

  return (
    <InlineWidget url={CALENDLY_LINK} prefill={prefill} pageSettings={pageSettings} onComplet />
  );
}
