import { exit } from '@navigate';
import { HiccupBlueprint } from '@blueprints';
import { Route } from '@types';
import { HealthApplicationQuery, useQuery } from '@app/data';

import Checklist from '@svg/checklist.svg';
import Mailbox from '@svg/mailbox.svg';

const AppSubmitted = ({ applicationID }) => {
  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const failedScreeners = data?.viewerTwo?.health?.application?.screening?.failedScreeners || [];
  const hasFailed = failedScreeners.length > 0;

  return (
    <HiccupBlueprint
      asset={{ svg: hasFailed ? Mailbox : Checklist }}
      title={"You're almost finished! Look out for an email from your Catch Benefits Advisor."}
      subtitles={
        hasFailed
          ? [
              'We will not be able to finalize your application without a bit more information from you. ',
            ]
          : [
              "They'll let you know if we need any additional details to complete your enrollment.",
              "If we're all set with the information you've already shared, your Benefits Advisor will confirm once your enrollment has been finalized.",
            ]
      }
      actions={[
        {
          testID: 'go-home',
          type: 'CUSTOM',
          label: 'Return home',
          onAction: () => exit(Route.HOME),
        },
      ]}
    >
      <i>
        <b>
          Please note, you are not enrolled in health insurance until you receive confirmation from
          us!
        </b>
      </i>
    </HiccupBlueprint>
  );
};

export const AppSubmittedView = {
  name: Route.EDE_SUBMITTED,
  component: AppSubmitted,
  options: { title: 'Submitted!' },
};
