import React from 'react';
import { useCopy, todayISO, sixtyDaysAgoISO } from '@app/utils';
import { useMutation, mutations, useQuery, HealthApplicationQuery } from '@data';
import { navigate } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { Route } from '@types';

const PREFIX = 'catch.ede.CIC.moveDateEntry';

const CICMoveDateEntry = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const onSubmit = async (values) => {
    try {
      await upsert({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: [
              {
                id: data?.viewerTwo?.health?.application?.applicant?.id,
                whenRelocated: values.whenRelocated,
              },
            ],
          },
        },
      });

      navigate(Route.CIC_NEW_PLANS_AVAILABLE, { date: values.whenRelocated });
    } catch (e) {}
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={upserting}
      title={c('title')}
      subtitle={c('subtitle')}
      formConfig={{
        initialValues: {},
        fields: [
          {
            testID: '233_L',
            name: 'whenRelocated',
            type: 'date',
            format: 'YYYY-MM-DD',
            before: { date: todayISO(), message: 'Must be before today' },
            after: { date: sixtyDaysAgoISO(), message: 'Must be in the last 60 days' },
            required: true,
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const CICMoveDateEntryView = {
  name: Route.CIC_MOVE_DATE_ENTRY,
  component: CICMoveDateEntry,
  options: {},
};
