import gql from 'graphql-tag';

const UPSERT_LEGAL = gql`
  mutation UpsertLegal($input: UpdateUserInput!) {
    updateUserNew(input: $input) {
      user {
        id
        givenName
        familyName
        phoneNumber
        dob
        ssn
        legalAddress {
          street1
          street2
          city
          state
          zip
          country
          coordinates {
            latitude
            longitude
          }
        }
        kycSavings {
          status
          needed
        }
      }
      error {
        code
        field
        message
      }
    }
  }
`;

export default {
  document: UPSERT_LEGAL,
  getErrors: (data) => {
    const error = data?.updateUserNew?.error;
    return error?.code === 'INVALID_ARGUMENT' ? [error] : null;
  },
};
