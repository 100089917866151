import gql from 'graphql-tag';
import { ApolloEnrollmentGroup, EnrollmentGroupFragment, PlanSearchFragment } from '../fragments';

export interface DeleteEnrollmentGroupVars {
  id: string;
}

export interface DeleteEnrollmentGroupResponse {
  deleteEnrollmentGroups: {
    enrollmentGroups: Array<ApolloEnrollmentGroup>;
    error: {
      code?: string;
      field?: string;
      message?: string;
    };
  };
}

export const DeleteEnrollmentGroup = gql`
  mutation DeleteEnrollmentGroup($id: ID!) {
    deleteEnrollmentGroups(ids: [$id]) {
      enrollmentGroups {
        id
        ...EnrollmentGroup
      }
      error {
        code
        field
        message
      }
    }
  }
  ${EnrollmentGroupFragment}
  ${PlanSearchFragment}
`;
