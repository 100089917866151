import React, { useMemo } from 'react';
import { useCopy } from '@app/utils';
import { useQuery, ApplicationIssuesQuery, getApplicationIssues, WhiteLabelOption } from '@data';
import { exit, navigate } from '@navigate';
import { Layout, FlowLayout, Section, Stack } from '@layouts';
import { Text, NumberedBullets, Bullet, Loading } from '@uikit';
import SuccessDMITodos from './SuccessDMITodos';
import SuccessSVITodos from './SuccessSVITodos';
import { Route } from '@types';
import { popReviewToast } from '@app/components/review';
import { useCurrentUser } from '@app/auth/useCurrentUser';
import { useFlags } from '@app/hooks/useFlags';

const Success = ({ applicationID }) => {
  const { c } = useCopy('catch.ede.SuccessView');
  const { c: basics } = useCopy('catch.basics');
  const { c: fees } = useCopy('catch.fees');

  const { loading, data } = useQuery(ApplicationIssuesQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { user } = useCurrentUser();
  const { hasAccessToFintech } = useFlags();

  const { pathwayType, savings, svis, dmis } = useMemo(() => {
    const application = data?.viewerTwo?.health?.application;
    const issues = getApplicationIssues(application || undefined);

    return {
      savings: application?.aptcHouseholdToApply ?? application?.aptcHouseholdAmount,
      pathwayType: application?.pathwayType,
      svis: issues.svis,
      dmis: issues.dmis,
    };
  }, [data]);

  const aptcSuccessCopy = () =>
    c('aptcTitle', {
      amount: (
        <Text size="h3" color="algae">
          ${savings}
          {fees('MONTHLY')}
        </Text>
      ),
    });

  const hasSvi = svis?.length > 0;
  const hasDmi = dmis?.length > 0;
  const isEffecutationReady = !hasSvi;
  const requiresAnyDocs = !!hasSvi || !!hasDmi;
  const requiresBothDocs = !!hasSvi && !!hasDmi;

  const HeaderTitle = () => {
    if (pathwayType === 'CALIFORNIA') {
      return `Congrats! You've submitted your application for CA insurance coverage through Catch.`;
    } else if (savings) {
      return aptcSuccessCopy();
    } else {
      return c('title');
    }
  };

  const handleNext = () => {
    if (user?.whiteLabel === WhiteLabelOption.Aetna && hasAccessToFintech) {
      navigate(Route.AETNA_EDE_PREFILL_ATTESTATION);
    } else {
      popReviewToast();
      exit(Route.COVERAGE);
    }
  };

  return (
    <FlowLayout
      narrow
      bare
      hideProgress
      nextButtonText={basics('soundsGood')}
      onNext={handleNext}
      canGoBack={false}
    >
      {loading ? (
        <Loading accentColor="coverage" />
      ) : (
        <>
          <Layout.Header asset={{ render: 'coverage' }} titleSize="form" title={HeaderTitle()} />

          {!!hasSvi && (
            <Section>
              <Text color="secondary" size="fp">
                {c('subtitle.svi')}
              </Text>
            </Section>
          )}
          <Stack spacing="2" separatorComponent>
            <Section title={c('now.title')}>
              {!!isEffecutationReady && <Bullet text={c('now.pay')} />}

              {/* Upload documents */}
              {requiresAnyDocs && (
                <>
                  <NumberedBullets number={isEffecutationReady ? 2 : 1}>
                    <Stack spacing="1">
                      <Text>{c('now.docs.title')}</Text>
                      {!!hasSvi ? (
                        <SuccessSVITodos sviTodos={svis} />
                      ) : (
                        <SuccessDMITodos dmiTodos={dmis} />
                      )}
                      <Stack spacing="0b">
                        <Text color="subtle" size="fp">
                          {c('now.docs.how')}
                        </Text>
                        {!!hasDmi && !hasSvi && (
                          <Text color="subtle" size="fp">
                            {c('now.docs.dmi.warning')}
                          </Text>
                        )}
                      </Stack>
                    </Stack>
                  </NumberedBullets>

                  <NumberedBullets number={hasSvi > 0 ? 2 : 3}>
                    <Text size="fp">{c('now.notice')}</Text>
                  </NumberedBullets>

                  {hasSvi && (
                    <NumberedBullets number={3}>
                      <Text size="fp">{c('now.pay.after')}</Text>
                    </NumberedBullets>
                  )}
                </>
              )}
            </Section>

            {!!requiresBothDocs && (
              <Section title={c('dmi.title')} subtitle={c('now.docs.title')}>
                <Stack spacing="2">
                  <SuccessDMITodos dmiTodos={dmis} />
                  <Stack spacing="0b">
                    <Text color="subtle" size="fp">
                      {c('now.docs.how')}
                    </Text>
                    <Text color="subtle" size="fp">
                      {c('dmi.warning')}
                    </Text>
                  </Stack>
                </Stack>
              </Section>
            )}

            <Section title={c('comms.title')}>
              <Stack spacing="1">
                <Bullet text={c('comms.read')} />
                <Bullet text={c('comms.notices')} />
                {!!requiresAnyDocs && <Bullet text={c('comms.docs')} />}
                <Bullet text={c('comms.taxForms')} />
              </Stack>
            </Section>

            <Section title={c('update.title')}>
              <Stack spacing="1">
                <Text size="fp">{c('update.change')}</Text>
                <Text size="fp" color="subtle">
                  {c('update.how')}
                </Text>
              </Stack>
            </Section>
          </Stack>
        </>
      )}
    </FlowLayout>
  );
};

export const SuccessView = {
  name: 'EDE_SUCCESS',
  component: Success,
  options: {},
};
