import { format, parseISO, formatRelative, formatDistanceToNowStrict } from 'date-fns';
import { isDate } from 'lodash';
import { enUS } from 'date-fns/locale';

export type DateFormat =
  | 'RELATIVE' /* yesterday */
  | 'DURATION' /* one day (ago) */
  | 'SHORT' /* Jan 1 */
  | 'SHORT_WITH_YEAR' /* Jan 1, 2021 */
  | 'FULL' /* January 1, 2021 */
  | 'NUMERIC' /* 1/1/2021 */
  | 'YEAR'
  | 'RELATIVE_TIME' /* 3h */
  | 'MONTH_YEAR'; /* January 2021 */

export type DateUnit = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';

export interface DateFormatOptions {
  showTime?: boolean;
  addSuffix?: boolean;
  ignoreTime?: boolean;
  unit?: DateUnit;
}

export const DateFormats: Record<DateFormat, string> = {
  SHORT: 'MMM d',
  SHORT_WITH_YEAR: 'MMM d, yyyy',
  FULL: 'MMMM d, yyyy',
  NUMERIC: 'M/d/yyyy',
  DURATION: 'MMMM d, yyyy',
  RELATIVE: 'MMM d',
  YEAR: 'yyyy',
  RELATIVE_TIME: 'hh:mm',
  MONTH_YEAR: 'MMMM yyyy',
};

const formatRelativeLocale = {
  lastWeek: "'last' eeee",
  yesterday: "'yesterday'",
  today: "'today'",
  tomorrow: "'tomorrow'",
  nextWeek: 'eeee',
  other: DateFormats.SHORT,
  otherWithYear: DateFormats.SHORT_WITH_YEAR,
};

const customLocale = {
  ...enUS,
  formatRelative: (token, date, _baseDate, _options) => {
    if (token === 'other' && date.getFullYear() !== new Date().getFullYear()) {
      return formatRelativeLocale.otherWithYear;
    }
    return formatRelativeLocale[token];
  },
};

export const date = (value: any, dateFormat: DateFormat, options?: DateFormatOptions) => {
  if (!value) return value;

  const parsed = parseISO(value);
  if (!isDate(parsed)) return value;

  const wantsRelative = /RELATIVE/.test(dateFormat);
  const wantsDuration = /DURATION/.test(dateFormat);

  return wantsRelative
    ? formatRelative(parsed, Date.now(), { locale: customLocale })
    : wantsDuration
    ? formatDistanceToNowStrict(parsed, {
        unit: options?.unit,
        addSuffix: options?.addSuffix,
        locale: customLocale,
      })
    : format(parsed, DateFormats[dateFormat]);
};
