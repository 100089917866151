import React from 'react';
import { View } from 'react-native';

import { Copy, AssetProps, InteractionAccessoryType, Action } from '@types';
import { useTheme } from '../hooks/useTheme';
import { Progress } from './Progress';
import { Text } from './Text';
import { Pressable } from './Pressable';
import { Flag } from './Flag';
import { Stack } from '@layouts';
import { ComplexRow } from './Row';
import { BottomActions } from '@app/_common';

interface ComplexCardProps {
  asset?: AssetProps;
  title: Copy;
  subtitle?: Copy;
  detail?: Copy;
  status?: string;
  accessory?: InteractionAccessoryType;
  onPress?: any;
  onDismiss?: any;
  testID: string;
  progress?: number;
  children?: any;
  actions?: Array<Action>;
  flat?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

export const ComplexCard = ({
  title,
  subtitle,
  detail,
  status,
  onPress,
  testID,
  progress,
  asset,
  children,
  actions,
  flat,
  loading,
  disabled,
}: ComplexCardProps) => {
  const { theme } = useTheme();

  return (
    //@ts-ignore
    <Pressable
      testID={testID}
      handleOnPress={onPress}
      disabled={disabled}
      style={[
        theme.cardBg,
        !!flat ? theme.borderColor : theme.majorShadow,
        theme.largeCorners,
        { overflow: 'visible' },
      ]}
    >
      <View
        style={[
          theme.largeCorners,
          theme.xSpace2,
          theme.topSpace2,
          !actions && theme.bottomSpace2,
          { overflow: 'hidden' },
        ]}
      >
        <Stack>
          <Stack spacing="0">
            {(!!status || !!progress) && (
              <Stack horizontal flexItem={1}>
                {!!status && <Flag>{status}</Flag>}
                <View style={{ alignItems: 'flex-end' }}>
                  {!!progress && <Progress progress={progress} />}
                </View>
              </Stack>
            )}
            <>
              <ComplexRow
                asset={asset}
                label={title}
                sublabel={subtitle}
                actions={[{ label: 'Hello' }]}
                loading={loading}
              />
              <Text size="fp" color="secondary">
                {detail}
              </Text>
            </>
            {children}
          </Stack>

          <BottomActions actions={actions} />
        </Stack>
      </View>
    </Pressable>
  );
};

export default ComplexCard;
