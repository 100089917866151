import React from 'react';
import { SheetBlueprint } from '@blueprints';
import { open } from '@app/navigate';
import { Route } from '@types';

const CoverageMenuSheet = () => {
  return (
    <SheetBlueprint
      title="Health Insurance"
      actions={[
        {
          type: 'CUSTOM',
          label: 'Explore Plans',
          onAction: () => open(Route.EXPLORER_INTRO),
        },
        {
          type: 'CUSTOM',
          label: 'Begin Application',
          onAction: () => open(Route.EDE_INTRO),
        },
      ]}
    />
  );
};

export default CoverageMenuSheet;
