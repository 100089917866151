import React, { useMemo } from 'react';
import { HealthApplicationQuery, useQuery, useMutation, UpsertHealthApplication } from '@data';
import { navigate, ScreenDefinition } from '@navigate';
import { Segment, SegmentEvent, useCopy } from '@util';
import { formatPayload, getInitialValues } from './proAssistanceUtils';
import { SplitFormBlueprint } from '@blueprints';
import fields from './proAssistanceFields';
import { Route } from '@types';

const ProAssistance = ({ applicationID, isGA }) => {
  const { c } = useCopy('catch.ede.ProAssistance');

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(() => {
    return getInitialValues(data?.viewerTwo?.health?.application);
  }, [data?.viewerTwo?.health?.application]);

  const [upsert, { loading: upserting }] = useMutation(UpsertHealthApplication);

  const onSubmit = (values) => {
    upsert({ variables: { input: formatPayload({ applicationID, values }) } });

    Segment.track(SegmentEvent.APPLICATION_ASSISTANCE_COMPLETED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    navigate(Route.EDE_ID_INFO);
  };

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={upserting}
      title={c('title')}
      subtitle={c('subtitle')}
      formConfig={{
        fields,
        initialValues: { isGA, ...initialValues },
        onSubmit,
      }}
    />
  );
};

export const ProAssistanceView: ScreenDefinition = {
  name: 'EDE_PRO_ASSISTANCE',
  component: ProAssistance,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Professional assistance',
  },
};
