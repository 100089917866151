import React, { createContext } from 'react';
import { useColorScheme } from '@app/styles';
import { lightThemes, darkThemes } from '../theme/themes';
import { themeColorsLight, themeColorsDark } from '../theme/colors';

const overrides = ['auto'];

const noop = () => {};

const initialTheme = {
  system: 'light',
  navMode: 'visible',
  setOverride: noop,
  theme: {},
  themeColors: {},
};

const modes = {
  dark: {
    color: themeColorsDark,
    theme: darkThemes,
  },
  light: {
    color: themeColorsLight,
    theme: lightThemes,
  },
};

export const Theme = createContext(initialTheme);

export const ThemeProvider = ({ children, ...rest }) => {
  const { colorScheme } = useColorScheme();
  const systemColorScheme = colorScheme;
  const appColorScheme = colorScheme;

  const currentMode = modes[appColorScheme || 'light'].theme;
  const currentColors = modes[appColorScheme || 'light'].color || themeColorsLight;
  const theme = currentMode.sTheme;

  const api = {
    system: systemColorScheme,
    theme,
    themeColors: currentColors,
    colorScheme: appColorScheme,
    themes: overrides,
    ...rest,
  };

  return <Theme.Provider value={api}>{children}</Theme.Provider>;
};
