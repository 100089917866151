import React, { ReactNode, ComponentType, useState } from 'react';
import { View } from 'react-native';
import { useTheme } from '@uikit';
import { StyledOverlay } from '../../dev/DevStyles';
import NavButton from './NavButton';

interface MenuProps {
  icon: ReactNode;
  iconComponent?: ComponentType<any>;
  children: ReactNode;
  ariaLabel?: string;
}

const Menu: React.FC<MenuProps> = ({ icon, iconComponent, children, ariaLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();

  return (
    <>
      <NavButton
        icon={icon}
        iconComponent={iconComponent}
        isActive={isOpen}
        onPress={(e) => {
          setIsOpen(!isOpen);
        }}
        ariaLabel={ariaLabel}
      />
      {!!isOpen && (
        <StyledOverlay
          onClick={(e) => {
            setIsOpen(false);
          }}
        >
          <View
            style={[
              theme.acrylic,
              theme.fadeInUp,
              theme.webpop,
              theme.mdCorners,
              theme.xSpace2b,
              theme.ySpace1b,
              { width: 200, border: 'none' },
            ]}
          >
            {children}
          </View>
        </StyledOverlay>
      )}
    </>
  );
};

export default Menu;
