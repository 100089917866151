import React, { useState } from 'react';
import styled from 'styled-components';
import { Color, applyFontStyles } from '@app/styles';
import { FontSize, FontFace, TextAlign, FontWeight } from '@app/types';
import { ErrorMessage } from '../ErrorMessage';
import { Link } from '../Link';
import { IconSvg } from '../Asset';

export interface InputProps {
  testID: string;
  name: string;
  value?: string;
  placeholder?: string;
  id?: string;
  onChange: (val: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled: boolean;
  signature?: boolean;
  sensitive?: boolean;
  secure?: boolean;
  keyboard?: any; // @todo
  autoFocus?: boolean;
  large?: boolean;
  error?: string;
  fullWidth?: boolean;
  sub?: React.ReactNode;
  icon?: React.Component;
}

const StyledContainer = styled.div`
  position: relative;
`;

const StyledInput = styled.input<{
  $disabled: boolean;
  $error: boolean;
  $sensitive: boolean;
  $large: boolean;
  $signature: boolean;
  $icon: boolean;
}>`
  ${({ theme, $large, $signature }) =>
    applyFontStyles({
      theme,
      size: $signature || $large ? FontSize.h2 : FontSize.p,
      family: $signature ? FontFace.signature : FontFace.body,
      weight: $large ? FontWeight.medium : FontWeight.regular,
      align: $large ? TextAlign.center : undefined,
    })}

  ::placeholder {
    color: ${({ theme }) => theme.colors.fg3};
    opacity: 1;
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: ${({ theme }) => theme.colors.fg3};
  }

  color: ${({ theme }) => theme.colors.fg};
  background-color: ${({ theme }) => theme.colors.inputBg};
  padding: ${({ $large }) => ($large ? 16 : 12)}px;

  ${({ $secure, $icon }) =>
    $secure ? 'padding-right: 56px;' : $icon ? 'padding-right: 48px;' : ''}
  border-radius: ${({ $large }) => ($large ? 16 : 12)}px;
  border: 1.5px solid transparent;

  width: 100%;
  z-index: 1;
  box-shadow: none;

  &:focus {
    background-color: ${({ theme, $error }) =>
      $error ? `${theme.colors.negative}1A` : theme.colors.bg};
    border-color: ${({ theme, $error }) => ($error ? theme.colors.negative : theme.colors.fg)};
    box-shadow: 0 2px 6px 0px #0000001a;
  }

  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.5;
  `}

  ${({ theme, $error }) =>
    $error &&
    `
    background-color: ${theme.colors.negative}1A;
    color: ${theme.colors.negative};
  `}
`;

const StyledToggle = styled.div`
  position: absolute;
  right: 12px;
  top: 7px;
  bottom: 7px;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 7px;
  bottom: 7px;
  display: flex;
  align-items: center;
  pointer-events: none;
`;

const StyledFooter = styled.div`
  padding-top: 8px;
`;

export const Input = React.forwardRef(function Dropdown(
  {
    testID,
    placeholder,
    id,
    name,
    value,
    onChange,
    onFocus,
    onBlur,
    error,
    large,
    sensitive,
    signature,
    disabled,
    autoFocus,
    secure,
    sub,
    icon,
  }: InputProps,
  ref,
) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <StyledContainer>
        <StyledInput
          ref={ref}
          aria-labelledby={`${testID}-label`}
          data-testid={testID}
          data-private={sensitive}
          type={secure && !visible ? 'password' : undefined}
          placeholder={placeholder}
          id={id}
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          autoFocus={autoFocus}
          $error={!!error}
          $disabled={!!disabled}
          $large={!!large}
          $secure={!!secure}
          $signature={!!signature}
          $icon={!!icon}
        />
        {secure && (
          <StyledToggle>
            <Link
              testID="visibility-toggle"
              color={Color.subtle}
              size={FontSize.fp}
              onPress={() => setVisible(!visible)}
            >
              {visible ? 'Hide' : 'Show'}
            </Link>
          </StyledToggle>
        )}

        {icon && (
          <StyledIcon>
            <IconSvg svgComponent={icon} size="xs" color="fg3" />
          </StyledIcon>
        )}
      </StyledContainer>
      {error && <ErrorMessage error={error} />}
      {sub && <StyledFooter>{sub}</StyledFooter>}
    </>
  );
});
