import gql from 'graphql-tag';

const REMOVE_CIC_PREFILL = gql`
  mutation RemoveCICPrefill($id: ID!) {
    removeHealthInsuranceEnrollmentPrefill(id: $id)
  }
`;

export default {
  document: REMOVE_CIC_PREFILL,
};
