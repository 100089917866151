import React, { useCallback, useMemo } from 'react';
import { Route } from '@types';
import { FintechWrapper } from '@app/components/wrappers';
import { env } from '@app/global';
import { GET_BANKING_SETUP_DETAILS, useQuery } from '@app/data';
import {
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  useCurrentRoute,
  navigate,
  replace,
} from '@navigate';

import { BankingSetupIntroView } from './routes/BankingSetupIntroView';
import { BankingSetupConfirmView } from './routes/BankingSetupConfirmView';
import { BankingSetupAddBankLinkView } from './routes/BankingSetupAddBankLinkView';
import {
  BankingSetupNameView,
  BankingSetupDOBView,
  BankingSetupAddressView,
  BankingSetupSSNView,
  BankingSetupOccupationView,
  BankingSetupPhoneView,
} from './routes/BankingSetupInfoView';
import { BankingSetupDocumentUploadView } from './routes/BankingSetupDocumentUploadView';
import { GOALS_STORAGE_FLOW_KEY } from '@app/constants/goals';
import { Storage } from '@app/utils';
import { useStackAccentColor } from '@app/hooks/useStackAccentColor';

const config: StackDefinition = {
  stackName: stacks.BANKING_SETUP_STACK,
  options: {
    layout: 'page',
    navMode: 'flow',
    presentation: 'largeSheet',
    buttons: { help: true },
  },
  screens: [
    BankingSetupIntroView,
    BankingSetupNameView,
    BankingSetupDOBView,
    BankingSetupPhoneView,
    BankingSetupAddressView,
    BankingSetupOccupationView,
    BankingSetupSSNView,
    BankingSetupAddBankLinkView,
    BankingSetupConfirmView,
    BankingSetupDocumentUploadView,
  ],
};

const BankingSetupStack: StackComponent = () => {
  const route = useCurrentRoute();
  const accentColor = useStackAccentColor(config.stackName);

  const { data } = useQuery(GET_BANKING_SETUP_DETAILS);
  const hasPhoneNumber = !!data?.me?.user?.phoneNumber;

  const handleNext = useCallback(() => {
    switch (route) {
      case Route.BANKING_SETUP_INTRO:
        navigate(Route.BANKING_SETUP_NAME);
        break;
      case Route.BANKING_SETUP_NAME:
        navigate(Route.BANKING_SETUP_DOB);
        break;
      case Route.BANKING_SETUP_DOB:
        const shouldAddPhone = !env.IS_PROD && !hasPhoneNumber;
        navigate(shouldAddPhone ? Route.BANKING_SETUP_PHONE : Route.BANKING_SETUP_ADDRESS);
        break;
      case Route.BANKING_SETUP_PHONE:
        navigate(Route.BANKING_SETUP_ADDRESS);
        break;
      case Route.BANKING_SETUP_ADDRESS:
        navigate(Route.BANKING_SETUP_OCCUPATION);
        break;
      case Route.BANKING_SETUP_OCCUPATION:
        navigate(Route.BANKING_SETUP_SSN);
        break;
      case Route.BANKING_SETUP_SSN:
        navigate(Route.BANKING_SETUP_ADD_BANK_LINK);
        break;
      case Route.BANKING_SETUP_ADD_BANK_LINK:
        // once linked, we don't want the user backwards navigating to bank link page
        if (Storage.getItem(GOALS_STORAGE_FLOW_KEY)) {
          replace(Route.GOALS_ADDITIONAL);
        } else {
          replace(Route.BANKING_SETUP_CONFIRM);
        }
        break;
      case Route.BANKING_SETUP_CONFIRM:
        replace(Route.MONEY);
        break;
      default:
        console.log('Route not handled', route);
        break;
    }
  }, [route, hasPhoneNumber]);

  const stackData = useMemo(() => {
    return { handleNext };
  }, [handleNext]);

  const stackOptions = {
    ...config.options,
    ...(accentColor && { accentColor }),
  };

  return (
    <FintechWrapper>
      <Stack
        stackName={config.stackName}
        screens={config.screens}
        options={stackOptions}
        data={stackData}
      />
    </FintechWrapper>
  );
};

BankingSetupStack.config = config;
export default BankingSetupStack;
