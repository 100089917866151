import { gql } from '../generated';

export const ImportPreviousApplication = gql(`
  mutation ImportPreviousApplication(
    $id: ID!
    $isPriorYear: Boolean
    $enrollmentId: ID
    $coverageYear: Int
  ) {
    importPreviousApplication(
      id: $id
      isFromPriorYear: $isPriorYear
      enrollmentId: $enrollmentId
      coverageYear: $coverageYear
    ) {
      id
      status
      applicationContext
      lastUsedRoute
      coverageState
      coverageYearNumber
      pathwayType
      enrollment {
        id
      }
      members {
        id
        givenName
      }
    }
  }
`);
