import { useMutation as useApolloMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import * as MUTATIONS from './deprecated/mutations';

/** @deprecated */
type DeprecatedMutationReturn = [
  /** @deprecated */
  mutation: (options?: any) => Promise<any>,
  response: {
    /** @deprecated */
    data?: any;
    /** @deprecated */
    loading?: boolean;
    /** @deprecated */
    error?: boolean;
  },
];

/**
 *
 * @deprecated consider moving to useMutation with directly imported typed mutations
 *
 */
function useDeprecatedMutation(mut, options): DeprecatedMutationReturn {
  /**
   * destructures the mutation properties
   * - document is the graphql tag declaration
   * - updateCache is helper function to update apollo cache
   * - refetchQueries is a list of queries to refetch after completion
   * - awaitRefetch is boolean whether to wait for refetches to finish
   */
  const { document, updateCache, refetchQueries, awaitRefetch, formatter, getErrors } =
    MUTATIONS[mut];

  const formatResults = (data) => (formatter ? formatter(cloneDeep(data)) : cloneDeep(data));

  /**
   * Special handler for onCompleted
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  const handleCompleted = (data) => {
    const errors = !!getErrors ? getErrors(data) : null;

    if (errors && errors.length > 0 && !!options?.onCatchError) {
      return options?.onCatchError(errors);
    }

    if (options?.onCompleted) {
      const formatted = formatResults ? formatResults(data) : data;
      options.onCompleted(formatted);
    }
  };

  const refetched = refetchQueries?.map((refetch) => {
    if (typeof refetch.variables === 'function') {
      return {
        ...refetch,
        variables: refetch.variables(options),
      };
    } else {
      return refetch;
    }
  });

  const [mutation, { loading, ...rest }] = useApolloMutation(document, {
    update: (cache, data) => {
      try {
        updateCache && updateCache(cache, data, options);
      } catch (e) {}
    },
    refetchQueries: refetched,
    awaitRefetchQueries: awaitRefetch,
    ...options,
    onCompleted: handleCompleted,
  });

  return [mutation, { loading, ...rest }];
}

export default useDeprecatedMutation;
