import { useCopy } from '@app/utils';
import { ItemTriageBlueprint } from '@blueprints';
import { Route } from '@app/types';
import { IncomeHeader } from '@app/components/income';

const PaycheckIncome = ({ income, handleNext }) => {
  const { c } = useCopy('catch.sequences.Paycheck.triage');

  return (
    <ItemTriageBlueprint
      header={<IncomeHeader income={income} />}
      question={c('title')}
      onPress={(approvalType) => handleNext({ approvalType })}
      options={[
        {
          key: '1099',
          label: c('ctTitle'),
          description: c('ctSubtitle'),
          value: '1099',
        },
        {
          key: 'W2',
          label: c('w2Title'),
          description: c('w2Subtitle'),
          value: 'W2',
        },
      ]}
    />
  );
};

export const PaycheckIncomeView = {
  name: Route.PAYCHECK_INCOME,
  component: PaycheckIncome,
  options: {},
};
