import createIconComponent from '../../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Delaware = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      d: 'M78.8289 37.988L81.733 37.7469C81.733 37.7469 89.2355 21.5956 103.998 20.1492C118.761 18.7028 127.958 25.2115 127.958 25.2115C127.958 25.2115 122.149 30.515 120.939 33.1667C119.729 35.8184 119.729 39.1933 117.793 39.9165C115.857 40.6397 115.131 41.3629 114.405 43.2914C113.679 45.2199 112.953 46.1842 111.501 47.3895C110.049 48.5948 105.934 51.0055 108.113 53.4161C110.291 55.8268 114.163 58.7196 114.405 59.6838C114.647 60.6481 114.647 62.5766 112.953 64.023C111.259 65.4694 111.353 66.5512 112.395 68.9031C113.437 71.2549 112.953 73.1834 111.259 73.4245C109.565 73.6656 106.66 74.8709 109.081 77.5226C111.501 80.1743 110.775 84.0314 113.679 88.3705C116.583 92.7097 113.921 94.6382 118.277 96.5667C122.633 98.4953 126.264 102.834 127.474 107.415C128.684 111.995 131.588 115.611 130.136 118.986C128.684 122.361 125.78 123.566 128.2 127.905C130.62 132.244 126.022 137.066 132.314 140.199C138.606 143.333 138.848 144.298 139.332 147.431C139.816 150.565 143.931 150.806 142.963 153.94C141.994 157.074 139.09 158.761 143.931 163.101C148.771 167.44 150.949 169.127 152.643 172.02C154.337 174.913 154.821 177.806 160.146 180.457C165.47 183.109 166.922 183.35 168.132 181.422C169.342 179.493 170.552 179.493 170.552 182.386C170.552 185.279 169.584 188.171 171.762 192.993C173.94 197.814 174.424 200.948 173.214 202.635C172.004 204.323 171.52 205.046 170.794 201.912C170.068 198.778 167.406 195.403 166.196 197.573C164.986 199.742 164.744 201.43 162.808 202.153C160.872 202.876 159.177 202.394 164.26 204.805C169.342 207.215 168.374 206.492 165.228 208.18C162.082 209.867 160.146 210.831 163.292 212.76C166.438 214.688 167.406 215.412 169.826 213.001C172.246 210.59 171.52 205.287 173.214 207.215C174.908 209.144 173.214 213.483 173.698 217.099C174.182 220.715 175.634 226.019 175.15 228.67C174.666 231.322 178.297 235.42 174.182 233.492C170.068 231.563 171.036 233.25 167.89 233.974C164.744 234.697 91.1716 233.009 91.1716 233.009L78.8289 37.988Z',
      fill: '#1F2533',
      colorable: true,
    },
  ],
  name: 'Delaware',
  width: '255',
  height: '254',
  viewBox: '0 0 255 254',
});
Delaware.displayName = 'Delaware';

/** @deprecated */
export default Delaware;
