import { device } from '@app/utils';

export interface ShareParams {
  message: string;
  url: string;
  options?: {
    copyLinkOnly?: boolean;
  };
}

const _share = (content: { title?: string; message?: string; url?: string }) => {
  if (window.navigator.share !== undefined) {
    return window.navigator.share({
      title: content.title,
      text: content.message,
      url: content.url,
    });
  }
};

export const copyToClipboard = (content?: string | null) => {
  if (content) {
    navigator.clipboard.writeText(content);
  }
};

/**
 *
 * Share
 * @see https://reactnative.dev/docs/share
 *
 */
export const share = ({ message, url, options }: ShareParams) => {
  if (device.canShare) {
    _share({ message, url });
  } else {
    copyToClipboard(options?.copyLinkOnly ? url : `${message}: ${url}`);
  }
};
