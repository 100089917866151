import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { Route } from '@types';
import { FintechWrapper } from '@app/components/wrappers';
import {
  navigate,
  Stack,
  StackComponent,
  StackDefinition,
  stacks,
  useCurrentRoute,
} from '@navigate';

import { GoalsEstimatorView } from './routes/GoalsEstimatorView';
import {
  GoalsEmploymentTypeView,
  GoalsEstimatedIncomeView,
  GoalsTaxFilingStatusView,
  GoalsDependentsView,
  GoalsWorkStateView,
  GoalsAdditionalView,
  GoalsSpouseIncomeView,
} from './routes/GoalsSetupInfoView';

import { GoalsConfirmView } from './routes/GoalsConfirmView';
import { GET_GOAL_SETUP_DETAILS, useQuery, FilingStatus, WorkType } from '@app/data';
import { Storage } from '@app/utils';
import {
  GOALS_STORAGE_ADDITIONAL_DATA_KEY,
  GOALS_STORAGE_ADDITIONAL_SEEN_KEY,
  GOALS_STORAGE_BANKING_SEEN_KEY,
  GOALS_STORAGE_TRUE_VALUE,
} from '@app/constants/goals';
import { useStackAccentColor } from '@app/hooks/useStackAccentColor';

const config: StackDefinition = {
  stackName: stacks.GOALS_SETUP_STACK,
  options: {
    layout: 'page',
    navMode: 'flow',
    buttons: { help: true },
  },
  screens: [
    GoalsEmploymentTypeView,
    GoalsEstimatedIncomeView,
    GoalsWorkStateView,
    GoalsTaxFilingStatusView,
    GoalsSpouseIncomeView,
    GoalsDependentsView,
    GoalsEstimatorView,
    GoalsAdditionalView,
    GoalsConfirmView,
  ],
};

export interface HandleNextUpdates {
  filingStatus?: FilingStatus;
  workType?: WorkType;
}

const GoalsSetupStack: StackComponent = () => {
  const route = useCurrentRoute();
  const accentColor = useStackAccentColor(config.stackName);

  // prefetches data that is going to be used throughout the stack
  const setup = useQuery(GET_GOAL_SETUP_DETAILS);

  const isGoalOnboarded =
    setup.data?.me?.user?.filingStatus &&
    setup.data?.me?.user?.workState &&
    setup.data?.me?.user?.workType &&
    setup.data?.me?.user?.income?.estimatedGrossIncome;

  const handleNext = useCallback(
    (updates?: HandleNextUpdates) => {
      switch (route) {
        case Route.GOALS:
          navigate(Route.GOALS_ESTIMATED_INCOME);
          break;
        case Route.GOALS_ESTIMATED_INCOME:
          navigate(Route.GOALS_WORK_STATE);
          break;
        case Route.GOALS_WORK_STATE:
          navigate(Route.GOALS_TAX_FILING_STATUS);
          break;
        case Route.GOALS_TAX_FILING_STATUS:
          if (!updates?.filingStatus) {
            console.error('Filing status not passed to handleNext');
            break;
          }

          const isMarried = [FilingStatus.Married, FilingStatus.MarriedSeparately].includes(
            updates?.filingStatus,
          );

          navigate(isMarried ? Route.GOALS_SPOUSE_INCOME : Route.GOALS_DEPENDENTS);

          break;
        case Route.GOALS_SPOUSE_INCOME:
          navigate(Route.GOALS_DEPENDENTS);
          break;
        case Route.GOALS_DEPENDENTS:
          navigate(Route.GOALS_ESTIMATOR);
          break;
        case Route.GOALS_ESTIMATOR:
          // can't check bank account/application because we'll be back through here for each additional goal
          if (!Storage.getItem(GOALS_STORAGE_BANKING_SEEN_KEY)) {
            Storage.setItem(GOALS_STORAGE_BANKING_SEEN_KEY, GOALS_STORAGE_TRUE_VALUE);
            navigate(Route.BANKING_SETUP_INTRO);
            // haven't seen additional
          } else if (!Storage.getItem(GOALS_STORAGE_ADDITIONAL_SEEN_KEY)) {
            navigate(Route.GOALS_ADDITIONAL);
            // have additional goals remaining
          } else if (!isEmpty(Storage.getItem(GOALS_STORAGE_ADDITIONAL_DATA_KEY))) {
            navigate(Route.GOALS_ESTIMATOR);
            // have no additional goals remaining
          } else {
            navigate(Route.GOALS_CONFIRM);
          }
          break;
        case Route.GOALS_ADDITIONAL:
          if (!isEmpty(Storage.getItem(GOALS_STORAGE_ADDITIONAL_DATA_KEY))) {
            navigate(Route.GOALS_ESTIMATOR);
          } else {
            navigate(Route.GOALS_CONFIRM);
          }
          break;
        case Route.GOALS_CONFIRM:
          navigate(Route.MONEY);
          break;

        default:
          console.log({ route });
          break;
      }
    },
    [route, isGoalOnboarded],
  );

  const stackOptions = {
    ...config.options,
    ...(accentColor && { accentColor }),
  };

  return (
    <FintechWrapper>
      <Stack
        stackName={config.stackName}
        screens={config.screens}
        options={stackOptions}
        data={{ handleNext }}
      />
    </FintechWrapper>
  );
};

GoalsSetupStack.config = config;
export default GoalsSetupStack;
