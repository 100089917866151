import React from 'react';
import { styled } from 'styled-components';

export interface DividerProps {
  spacing?: boolean;
  height?: number;
}

const StyledDivider = styled.div<{ height: number; spacing: boolean }>`
  background-color: ${({ theme }) => theme.colors.border};
  height: ${({ height }) => height}px;

  ${({ spacing }) =>
    spacing &&
    `
    margin-top: 16px;
    margin-bottom: 16px;
  `}
`;

export function Divider({ height = 1, spacing = false }: DividerProps): React.ReactNode {
  return <StyledDivider data-testid="divider" height={height} spacing={spacing} />;
}
