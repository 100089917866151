import React, { useState } from 'react';
import { Pressable as RNPressable, AccessibilityRole, View } from 'react-native'; // eslint-disable-line
import { PressableProps, Corners, QAProps, href } from '@types';
import { handleHref } from './handleHref';
import { s } from '@app/_ui-kit/theme/static';
import { theme } from '@app/_ui-kit/themes/catch';
import { useHover } from '@app/_ui-kit/hooks/useHover';

interface Props extends PressableProps, QAProps {
  handleOnPress?: () => void;
  handlePressIn?: () => void;
  handlePressOut?: () => void;
  onHover?: () => void;
  onLayout?: () => void;
  onFocus?: () => void;
  href?: href; // external urls only
  role?: AccessibilityRole;
  ariaLabel?: string;
  corners?: Corners;
  disabled?: boolean;
  hitSlop?: number;
  selected?: boolean; // don't apply hover state if already selected
  children: any;
}

/**
 * Pressable components are ANY components in Catch that the user
 * interacts with by pressing/touching/clicking. We use this to
 * standardize some core functionality for interactions:
 *
 * - State + handlers for hovered/focused/pressed and associated styles
 * - Handling presses, including onPress fns, hrefs and haptic feedback
 */

const Pressable = React.forwardRef(
  (
    {
      href, // external href
      ariaLabel,
      handleOnPress,
      disabled,
      selected,
      corners,
      hitSlop,
      children,
      testID,
      onHover,
      onFocus,
      onLayout,
      ...props
    }: Props,
    ref,
  ) => {
    const { isHovered, isPressed, handlers } = useHover(false, { useNewObjectApi: true });

    const [focused, setFocused] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [pressed, setPressed] = useState(false);

    const interactionState = {
      focused,
      pressed,
      hovered,
    };

    const { role, externalHref, target } = handleHref(href || '');

    /**
     * Press handler for all pressables. Supports:
     * - haptic feedback
     * - external linking
     * - interal navigate
     * - onPress (in combination with any links!)
     */
    const handlePress = (e) => {
      // Note: external links on web are handled by the combo href + target
      // For native however, we must use the RN Linking library to open links
      if ((!!externalHref || !!href) && !disabled) {
        window.open(externalHref || href);
      }

      // lastly, if the on press is defined, do it!
      if (handleOnPress && !disabled) {
        handleOnPress(e);
      }
    };

    // Computes default styels for disabled state and corner radii
    const disabledStyle = props.disabledStyle || s?.disabled;

    /**
     * If neither onPress or href are defined, this is
     * not actually pressable and we should not render the
     * the pressable or anything relating to interaction state
     */
    if (!handleOnPress && !href) {
      return (
        <View
          {...handlers}
          style={[
            props.style,
            // @ts-ignore
            !!isHovered && props.hoveredStyle,
            !!isPressed && props.pressedStyle,
            !!disabled && disabledStyle,
          ]}
        >
          {typeof children === 'function' ? children({ intrx: interactionState }) : children}
        </View>
      );
    }

    return (
      <RNPressable
        ref={ref}
        nativeID={testID}
        testID={testID}
        accessibilityRole={role}
        aria-label={ariaLabel}
        tabIndex={0}
        target={target}
        hitSlop={hitSlop}
        disabled={disabled}
        onPress={handlePress}
        onLayout={onLayout}
        onHoverIn={() => {
          if (onHover) onHover();
          setHovered(true);
        }}
        onHoverOut={() => setHovered(false)}
        onFocus={() => {
          if (!!theme.settings.focusLock) {
            setFocused(true);
          }

          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={() => setFocused(false)}
        // @ts-ignore
        onPressIn={() => {
          if (!disabled) {
            setPressed(true);
          }
          !!props.handlePressIn && props.handlePressIn();
        }}
        onPressOut={() => {
          if (!disabled) {
            setPressed(false);
          }
          !!props.handlePressOut && props.handlePressOut();
        }}
        // @ts-ignore
        style={[
          props.style,
          { cursor: !!handlePress ? 'pointer' : 'default' },
          // @ts-ignore
          !!hovered && !disabled && props.hoveredStyle,
          // @ts-ignore
          !!focused && props.focusedStyle,
          !!pressed && props.pressedStyle,
          !!disabled && disabledStyle,
        ]}
      >
        {typeof children === 'function' ? children({ intrx: interactionState }) : children}
      </RNPressable>
    );
  },
);

Pressable.displayName = 'Pressable';
export default Pressable;
