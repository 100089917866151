import gql from 'graphql-tag';

export interface EnsureHealthApplicationVars {
  applicationID: string;
}

export interface EnsureHealthApplicationResponse {
  ensureHealthApplication: {
    currApplication: {
      id: string;
    };
  };
}

export const EnsureHealthApplication = gql`
  mutation EnsureHealthApplication($applicationID: ID!) {
    ensureHealthApplication(id: $applicationID) {
      currApplication {
        id
        uiQuestionsToDisplay
        incomeExplanationRequiredIndicator
        stateDeprivationRequirementRetained
        allMembers {
          id
          dob
          age
          sex
          ssn
          isCitizen
          isPregnant
          relation
          hasAbsentParent
          isRequestingCoverage
          uiQuestionsToDisplay
          isLawfulPresenceStatus
          annualTaxIncome {
            annualIncomeExplanationRequired
            isVariableIncome
            isUnknownIncome
            attestedAnnualizedAPTCIndividualIncomeAmount
            incomeAmount
          }
          incomeSources {
            jobIncomeExplanationRequired
            type
          }
          preliminaryQHPStatus
          preliminaryAPTCStatus
          preliminaryMedicaidStatus
          preliminaryEmergencyMedicaidStatus
        }
      }
      responseResultType
      errors {
        code
        applicationMemberID
        path
      }
      error {
        code
        field
        message
      }
    }
  }
`;
