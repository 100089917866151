import gql from 'graphql-tag';

export const UPSERT_ENROLLMENT_FROM_EXPLORER = gql`
  mutation UpsertEnrollmentFromExplorer {
    upsertEnrollmentFromExplorer {
      id
      pathwayType
      healthApplication {
        id
        ... on HealthApplication {
          id
          lastUsedRoute
          pathwayType
          coverageState
        }
      }
    }
  }
`;

const formatter = (data) => data.upsertEnrollmentFromExplorer;

export default {
  document: UPSERT_ENROLLMENT_FROM_EXPLORER,
  formatter,
};
