import React, { useMemo } from 'react';
import {
  BANK_APPLICATION_STATUSES_IN_PROGRESS,
  BANK_APPLICATION_STATUSES_NEGATIVE,
} from '@app/features/banking/utils/bankApplication';
import { Fields, useForm } from '@app/forms';
import { ConfirmationBlueprint } from '@blueprints/ConfirmationBlueprint';
import {
  ACTIVATE_GOALS,
  AgreementType,
  BankApplicationStatus,
  GET_BANK_APPLICATION,
  GET_BANKING_SETUP_DETAILS,
  GET_GOALS,
  GoalStatus,
  SIGN_AGREEMENTS,
  useFintechMutation,
  useFintechQuery,
  useMutation,
  useQuery,
} from '@data';
import { useAgreements } from '@hooks';
import { BasicLayout, Section, Stack, Toolbar } from '@layouts';
import { sheets, useSheet } from '@navigate';
import Close from '@svg/close.svg';
import Timer from '@svg/timer.svg';
import { FieldsConfig, GoalSlug } from '@types';
import { Button, ComplexRow, Link } from '@uikit';
import { useCopy } from '@util';
import { LegalAgreement } from '../agreements';

interface FintechConfirmPageProps {
  slug: GoalSlug;
  handleNext: (updates?: object) => void;
  doneMessage?: string;
}

export const FintechConfirmPage = ({ slug, handleNext, doneMessage }: FintechConfirmPageProps) => {
  const { agreements } = useAgreements([
    AgreementType.UnitElectronicDisclosuresConsent,
    AgreementType.UnitDepositTermsAndConditions,
  ]);
  const { c } = useCopy('catch');
  const [activateGoals, { called, error, loading: creating }] = useFintechMutation(ACTIVATE_GOALS, {
    onError: () => {
      // prevent throwing
    },
    refetchQueries: [GET_BANK_APPLICATION],
  });
  const { data: bankApplicationData } = useFintechQuery(GET_BANK_APPLICATION);
  const fintech = useFintechQuery(GET_GOALS);
  const [signAgreements] = useMutation(SIGN_AGREEMENTS);
  const setup = useQuery(GET_BANKING_SETUP_DETAILS);
  const { close: closeSheet, open: openSheet } = useSheet();

  const fields: FieldsConfig = [
    {
      type: 'checkbox',
      name: 'unitAccountDisclosures',
      required: true,
      display: !setup?.data?.me?.user?.agreements?.unitAccountDisclosures,
      grouped: true,
      label: c('module.disclosures.unit', {
        electronic: <LegalAgreement type={AgreementType.UnitElectronicDisclosuresConsent} />,
        terms: <LegalAgreement type={AgreementType.UnitDepositTermsAndConditions} />,
      }),
    },
  ];

  const shouldDisplayAgreements = fields.some((field) => field.display);

  /**
   * We have two variations of this page that should be handled:
   * 1) We are on an individual goal setup (via slug) and should only show that goal
   * 2) We are in bulk setup and should show all DRAFT goals
   */
  const visibleGoals = useMemo(() => {
    const goals = fintech?.data?.goals || [];

    if (slug) {
      const currentGoal = goals?.find((goal) => goal.slug === slug);
      return currentGoal ? [currentGoal] : [];
    }

    // otherwise, return all draft goals
    return goals
      ?.filter((goal) => goal.status === GoalStatus.Draft)
      ?.sort((a, b) => (a.withholdingPercentage > b.withholdingPercentage ? -1 : 1));
  }, [fintech]);

  const bankApplicationStatus =
    bankApplicationData?.bankApplication?.status || error?.cause?.extensions?.status;

  let confirmationIcon;
  let confirmationIconStatus;
  let confirmationTitle = 'You’ve successfully created your Catch Banking Account!';

  if (BANK_APPLICATION_STATUSES_NEGATIVE.includes(bankApplicationStatus)) {
    confirmationIcon = Close;
    confirmationIconStatus = 'negative';
    confirmationTitle = 'We weren’t able to open up a Catch account for you.';
  }

  if (BANK_APPLICATION_STATUSES_IN_PROGRESS.includes(bankApplicationStatus)) {
    confirmationIcon = Timer;
    confirmationTitle =
      'We’re working on opening your Catch Money account, you’ll get an update from us soon!';
  }

  if (bankApplicationStatus === BankApplicationStatus.AwaitingDocuments) {
    confirmationIcon = Timer;
    confirmationTitle = 'We’re working on opening your Catch Money account.';
  }

  const loading = fintech.loading || setup.loading;

  const form = useForm<{ unitAccountDisclosures: boolean }>({
    loading,
    disabled: creating,
    initialValues: {},
    fields,
    onSubmit: async (values) => {
      if (values.unitAccountDisclosures) {
        const input = agreements.map((agreement) => ({
          agreementType: agreement.agreementType as AgreementType,
          version: agreement.version as string,
        }));

        await signAgreements({ variables: { input } });
      }

      activateGoals({
        variables: {
          input: {
            goalIds: visibleGoals?.map((g) => g.id || ''),
          },
        },
      });
    },
  });

  return (
    <ConfirmationBlueprint
      titles={
        !slug
          ? {
              done: doneMessage || confirmationTitle,
            }
          : undefined
      }
      called={called}
      error={false}
      icon={confirmationIcon}
      iconStatus={confirmationIconStatus}
      loading={creating}
      onSuccess={handleNext}
    >
      <BasicLayout
        loading={loading}
        variant="confirm"
        title="Confirm your withholding"
        toolbar={
          <Toolbar type="stack">
            <Button
              testID="confirm"
              loading={loading}
              inherit
              disabled={form.disableSubmit}
              onPress={form.submitForm}
            >
              Confirm
            </Button>
          </Toolbar>
        }
      >
        <Stack spacing="2">
          {visibleGoals.length > 0 && (
            <Section title="Your goals" subtitle="You can adjust this at any time.">
              <Stack separatorComponent border>
                {visibleGoals.map((goal) => (
                  <ComplexRow
                    testID={goal.id || ''}
                    inset
                    key={goal.id}
                    label={goal.name}
                    accessory={
                      <Link
                        testID="edit-percentage"
                        onPress={() =>
                          openSheet(sheets.WITHHOLDING_EDIT, {
                            closeSheet,
                            goal,
                          })
                        }
                      >
                        {goal.withholdingPercentage}%
                      </Link>
                    }
                    asset={{ render: goal.type, size: 'sm' }}
                  />
                ))}
              </Stack>
            </Section>
          )}

          {shouldDisplayAgreements && (
            <Section title="Legal">
              <Stack spacing="3">
                <Fields form={form} fields={fields} stackProps={{ spacing: '+gap' }} />
              </Stack>
            </Section>
          )}
        </Stack>
      </BasicLayout>
    </ConfirmationBlueprint>
  );
};
