import { gql } from '../generated';

export const GET_HOME = gql(`
  query GetHome($year: Int!, $taxYear: Int!, $prevTaxYear: Int!, $coverageYears: [Int!]) {
    viewer {
      id
      user {
        id
        joinDate: createdOn
        email
        supportedPathways
      }
      nudges {
        id: nudgeIdentifier
        isDismissed
      }
    }
    viewerTwo {
      id
      healthExplorerData {
        id
        state
        pathway
        pathwayType
        providerPlanID
      }
      cmsIdentity: identity(providerType: CMS) {
        id
        status
        providerType
      }
      existingApplicationInfo {
        existingApplications {
          id
        }
      }
      agreements {
        id
        acknowledgedUnitAccountDisclosures
        authorizedAboundEftpsEnrollment
        isPrivacyDataUseAgree
        isPrivacyTruthfulAgree
      }
      health {
        policies(input: { coverageYearNumber: $coverageYears }) {
          id
          policyStatus
          productType
          startDate
          endDate
          dueDate @client
          coverageYear @client
          carrier
          planName
          shorthand @client
          policyNumber
          subscriberID
          exchangeSubscriberID
          enrollmentID
          providerPlan {
            id
            name
            type
            issuer {
              id
              name
              tollFree
            }
          }
        }
        applications(input: { coverageYearNumber: $coverageYears }) {
          id
          status
          applicationContext
          lastUsedRoute
          coverageState
          coverageYearNumber
          pathway
          pathwayType
          enrollment {
            id
          }
          policies {
            id
            policyStatus
            productType
            startDate
            endDate
            dueDate @client
            carrier
            planName
            policyNumber
            subscriberID
            exchangeSubscriberID
            providerPlan {
              id
              name
              issuer {
                id
                name
                tollFree
              }
            }
          }
          dmis {
            id
            status
            resolveBy
          }
          svis {
            id
            status
            resolveBy
          }
        }
      }
      documents {
        taxDocuments(
          input: { endMonth: 3, endYear: $taxYear, startMonth: 3, startYear: $prevTaxYear }
        ) {
          id
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
`);
