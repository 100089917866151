import React from 'react';

import { useCopy } from '@app/utils';
import { navigate } from '@navigate';
import { HealthApplicationQuery, useQuery } from '@data';
import { BasicLayout } from '@layouts';
import { nextCICRoute } from '@navigate/CICNavigationGuards';
import { BottomActions } from '@app/_common';
import { Route } from '@types';

const copyConfig = {
  ADD_MEMBER: 'addMemberReason',
  REMOVE_MEMBER: 'removeMemberReason',
};

const CICSelection = ({ applicationID, step }) => {
  const { c } = useCopy(`catch.ede.CIC.selection`);

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const hasSSN = !!data?.viewerTwo?.health?.application?.applicant?.ssn;

  const screenStep = copyConfig[step] || step;

  // Cap enums as they are also sent as events
  const cicOptions = {
    changeSelection: [
      'INCOME',
      'ADDRESS',
      'MARRIAGE',
      'PREGNANCY',
      'BIRTH',
      'COVERAGE',
      'DIVORCE',
      'DEATH',
      'DISABILITY',
      'ADOPTION',
      'CUSTODY',
      'FILING_STATUS',
      'CITIZENSHIP',
      'INCARCERATION',
      'TRIBE_MEMBERSHIP',
    ],
    addMemberReason: [
      'MARRIAGE',
      'BIRTH',
      'COVERAGE_LOSS',
      'DISABILITY',
      'ADOPTION',
      'CITIZENSHIP',
      'INCARCERATION',
    ],
    removeMemberReason: [
      'CHILD_26',
      'COVERAGE_GAIN_JOB',
      'COVERAGE_GAIN',
      'DIVORCE',
      'DEATH',
      'DISABILITY',
      'CUSTODY',
      'FILING_STATUS',
      'INCARCERATION',
      'OTHER',
    ],
    coverageOptionsIntro: [
      'COVERAGE_LOSS',
      'FUTURE_COVERAGE_LOSS',
      'COVERAGE_GAIN_JOB',
      'COVERAGE_GAIN',
    ],
  };

  const onSubmit = (cic) => {
    /**
     * @workaround
     * currently all CIC pages depend on different
     * ways of enums used to passs around clean up w/ using enums
     * only
     */
    navigate(`${nextCICRoute(cic, hasSSN)}`, {
      screenStep: 'memberStatusChange',
      change: cic,
    });
  };

  return (
    <BasicLayout
      loading={!data}
      title={c(`${screenStep}.title`)}
      subtitles={[
        c(`${screenStep}.subtitle`, {
          link: c('qleLink'),
        }),
      ]}
    >
      <BottomActions
        actions={cicOptions[screenStep]?.map((cic) => ({
          label: c(cic),
          onAction: () => onSubmit(cic),
        }))}
      />
    </BasicLayout>
  );
};
export const CICSelectionView = {
  name: Route.CIC_CHANGE_SELECTION,
  component: CICSelection,
  options: {},
};
