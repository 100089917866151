import { gql } from '../generated';

export const RequestConcierge = gql(`
  mutation RequestConcierge($input: RequestConciergeInput!) {
    requestConcierge(input: $input) {
        id
        eventType
        metadata
    }
  }
`);

export const ScheduleConcierge = gql(`
  mutation ScheduleConcierge($input: ScheduleConciergeInput!) {
    scheduleConcierge(input: $input) {
        id
        eventType
        metadata
    }
  }
`);
