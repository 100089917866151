import { VoterRegistrationAnswerType } from '@app/data';

const options = [
  VoterRegistrationAnswerType.Yes,
  VoterRegistrationAnswerType.No,
  VoterRegistrationAnswerType.PreferNotToAnswer,
];

export const fields = [
  {
    copy: 'catch.ede',
    testID: '309',
    name: 'voterRegistrationAnswerType',
    type: 'radio',
    label: { id: 'HealthEnrollment.309_Q' },
    help: { id: 'HealthEnrollment.309_T' },
    options: options.map((value) => ({
      label: { id: `enums.${value}` },
      value,
    })),
  },
];
