import { useHealthStore } from '@hooks/useHealthStore';

type HealthContext = 'FFM' | 'GA' | 'CA' | 'NJ';

const marketplaceNames: Record<
  HealthContext,
  { name: string; the: string; full: string; site: string }
> = {
  FFM: {
    name: 'Marketplace',
    the: 'the Marketplace',
    full: 'the Health Insurance Marketplace',
    site: 'Healthcare.gov',
  },
  GA: {
    name: 'Georgia Access',
    the: 'Georgia Access',
    full: 'Georgia Access',
    site: 'Georgia Access',
  },
  CA: {
    name: 'Covered California',
    the: 'Covered California',
    full: 'Covered California',
    site: 'Covered California',
  },
  NJ: {
    name: 'Get Covered NJ',
    the: 'Get Covered NJ',
    full: 'Get Covered NJ',
    site: 'Get Covered NJ',
  },
};

const providers: Record<HealthContext, string> = {
  FFM: 'Marketplace',
  GA: 'Catch',
  CA: 'Covered California',
  NJ: 'Get Covered NJ',
};

const chipNames: Record<HealthContext, string> = {
  FFM: 'CHIP',
  GA: 'PeachCare for Kids®',
  CA: 'Medi-Cal (CHIP)',
  NJ: 'NJ FamilyCare',
};

const fullChipNames: Record<HealthContext, string> = {
  FFM: 'the Children’s Health Insurance Prorgam (CHIP)',
  GA: 'PeachCare for Kids®',
  CA: 'Medi-Cal (CHIP)',
  NJ: 'NJ FamilyCare',
};
const medicaidNames: Record<HealthContext, string> = {
  FFM: 'Medicaid',
  GA: 'Georgia Medicaid',
  CA: 'Medi-Cal (Medicaid)',
  NJ: 'NJ FamilyCare',
};

const callCenter: Record<HealthContext, string> = {
  FFM: 'the Marketplace Call Center at 1-800-318-2596 (TTY: 1-855-889-4325)',
  GA: 'Catch at (833) 309-0500',
  CA: 'Covered California Service Center at (800) 300-1506',
  NJ: 'Get Covered NJ at 1-833-677-1010',
};

const assistanceURL: Record<HealthContext, string> = {
  FFM: 'LocalHelp.HealthCare.gov',
  GA: 'https://georgiaaccess.gov/resources-and-support/find-a-local-assister',
  CA: 'https://www.coveredca.com/support/',
  NJ: 'https://www.nj.gov/getcoverednj/help/local/',
};

const agentLookupURL: Record<HealthContext, string> = {
  FFM: 'LocalHelp.HealthCare.gov',
  GA: 'https://GeorgiaAccess.gov/get-covered/agent',
  CA: 'https://www.coveredca.com/support/',
  NJ: 'https://www.nj.gov/getcoverednj/help/local/',
};

const nativeMedicaidChip: Record<HealthContext, string> = {
  FFM: 'https://HealthCare.gov/American-Indians-Alaska-natives/Medicaid-chip/',
  GA: 'https://help.georgiaaccess.gov/hc/en-us/articles/16717985630356-Does-Georgia-Medicaid-and-PeachCare-for-Kids-offer-health-coverage-for-American-Indians-and-Alaska-Natives',
  CA: 'https://www.coveredca.com/learning-center/american-indians-and-alaskan-natives/',
  NJ: 'https://www.nj.gov/getcoverednj/findanswers/faqs/',
};

const householdSize: Record<HealthContext, string> = {
  FFM: 'https://www.healthcare.gov/income-and-household-information/household-size/',
  GA: 'https://help.georgiaaccess.gov/hc/en-us/articles/16717957110292-Who-do-I-include-in-my-household',
  CA: 'https://www.coveredca.com/support/financial-help/household/',
  NJ: 'https://www.nj.gov/getcoverednj/findanswers/faqs/',
};

export const useUniversalCopy = () => {
  const { context } = useHealthStore();

  return {
    marketplaceName: marketplaceNames[context].name,
    theMarketplace: marketplaceNames[context].full,
    theShortMarketplace: marketplaceNames[context].the,
    marketplaceSite: marketplaceNames[context].site,
    provider: providers[context],
    callCenter: callCenter[context],
    chipName: chipNames[context],
    fullChipName: fullChipNames[context],
    medicaidName: medicaidNames[context],
    assistanceURL: assistanceURL[context],
    agentLookupURL: agentLookupURL[context],
    nativeMedicaidChip: nativeMedicaidChip[context],
    householdSize: householdSize[context],
    isGA: context === 'GA' ? 'YES' : 'NO',
  };
};
