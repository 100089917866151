import { FinePrint } from '@uikit';
import { useCopy } from '@app/utils';
import { Color } from '@app/styles';
import { AgreementType } from '@app/data';
import { LegalAgreement } from './LegalAgreement';

export const RegistrationDisclosures = () => {
  const { c } = useCopy('catch.module.disclosures');

  return (
    <FinePrint
      items={[
        c('register', {
          terms: <LegalAgreement type={AgreementType.CatchTermsOfUse} color={Color.subtle} />,
          privacy: <LegalAgreement type={AgreementType.CatchPrivacyPolicy} color={Color.subtle} />,
        }),
      ]}
    />
  );
};
