import { Route } from '@types';
import { FintechConfirmPage } from '@app/components/fintech';
import { GOALS_STORAGE_KEYS } from '@app/constants/goals';
import { Storage } from '@app/utils';

export const GoalsConfirmView = {
  name: Route.GOALS_CONFIRM,
  component: (props) => {
    GOALS_STORAGE_KEYS.map((key) => {
      Storage.removeItem(key);
    });

    return <FintechConfirmPage {...props} />;
  },
  options: {},
};
