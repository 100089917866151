import React from 'react';

import { Button, Text, Link, ComplexRow } from '@uikit';
import { Toolbar, BasicLayout } from '@layouts';
import { useCopy } from '@app/utils';
import { navigate, close, useSheet } from '@navigate';
import { useMutation, mutations } from '@app/data';
import { Route } from '@types';

const CICRemovingMembers = ({ members, change }) => {
  const { c } = useCopy('catch.ede');

  const { open } = useSheet();

  const [ensure] = useMutation(mutations.ENSURE_HEALTH_APPLICATION);

  const [report, { loading }] = useMutation(mutations.REPORT_HEALTH_ENROLLMENT_CHANGES, {
    onCompleted: (data) => {
      const { applicationID } = data?.reportHealthEnrollmentChanges?.insuranceEnrollment || {};
      ensure({ variables: { applicationID } });
      navigate(Route.CIC_APP_REVIEW_INTRO, { applicationID });
    },
  });

  const handleRemove = () => {
    const removeReason = change === 'DEATH' || change === 'DIVORCE' ? change : 'OTHER';

    report({
      variables: {
        input: {
          memberInputs: members.map((m) => ({
            applicationMember: {
              id: m.id,
            },
            removeMember: true,
            removeReason: removeReason,
            removeDate: m?.removeDate,
            isDeath: /DEATH/.test(change),
          })),
        },
      },
    });
  };

  return (
    <BasicLayout
      title={c('CIC.removingMembers.title')}
      subtitles={[
        c('CIC.removingMembers.subtitle', { change }),
        c('RemoveDependentPrompt.moreInfo', {
          name: members?.map((m) => m?.name).join(', '),
        }),
        c('ConfirmApplicants.aboutInfoNeeded', {
          link: (
            <Link
              testID="removing-members"
              onPress={() =>
                open('HELP_TEXT', {
                  title: 'Removing members',
                  subtitle: c('HealthEnrollment.273'),
                })
              }
            >
              {c('ConfirmApplicants.infoLink')}
            </Link>
          ),
        }),
        <Text weight="medium" key="emphasis" color="credit">
          {c('CIC.removingMembers.emphasis')}
        </Text>,
      ]}
      toolbar={
        <Toolbar>
          <Button testID="cancel" alt onPress={() => close()} disabled={loading}>
            {c('CIC.removingMembers.dismiss')}
          </Button>
          <Button testID="remove" onPress={handleRemove} loading={loading} disabled={loading}>
            {c('CIC.removingMembers.confirm')}
          </Button>
        </Toolbar>
      }
    >
      {members.map((m) => (
        <ComplexRow key={m.id} asset={{ render: 'person' }} label={m.name} />
      ))}
    </BasicLayout>
  );
};

export const CICRemovingMembersView = {
  name: Route.CIC_REMOVING_MEMBERS,
  component: CICRemovingMembers,
  options: {},
};
