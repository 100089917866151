import React, { useMemo } from 'react';
import { useCopy, toGQLDate } from '@app/utils';
import { useMutation, mutations, useQuery, HealthApplicationQuery, getMembers } from '@data';
import { navigate } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { Route } from '@types';

const PREFIX = 'catch.ede.CIC.memberMarriage';

const CICMemberMarriage = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  // Filter only non married members
  const members = useMemo(
    () => getMembers(data)?.filter((m) => m?.maritalStatus !== 'MARRIED'),
    [data],
  );

  const [report, { loading: reporting }] = useMutation(mutations.REPORT_HEALTH_ENROLLMENT_CHANGES);

  const onSubmit = async ({ marriedMember }) => {
    /**@todo check marriedMember */
    try {
      // If the applicant got married we report the change
      if (marriedMember.relation === 'SELF') {
        await report({
          variables: {
            input: {
              memberInputs: [
                {
                  applicationMember: {
                    id: marriedMember.id,
                    isMarriedLast60Days: 'YES',
                    whenMarried: toGQLDate(marriedMember.date),
                  },
                },
              ],
            },
          },
        });
      }
      if (marriedMember.relation !== 'SELF' && !marriedMember?.removeMember) {
        navigate(Route.CIC_GOV_HANDOFF, { marriedMember });
      } else if (marriedMember.relation === 'SELF') {
        navigate(Route.CIC_APP_REVIEW_INTRO, { change: 'MARRIAGE' });
      } else {
        navigate(Route.CIC_REMOVING_MEMBERS, { members: [marriedMember] });
      }
    } catch (e) {}
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={reporting}
      title={c('title')}
      formConfig={{
        initialValues: {
          members,
        },
        fields: [
          {
            name: 'members',
            type: 'array',
            Component: ({ children }) => children,
            Footer: () => null,
            subfields: [
              {
                name: 'isMarried',
                type: 'person',
                dependencies: ['legalName'],
                bare: true,
                label: ({ legalName }) => legalName,
                secondary: {
                  unchecked: 'Single',
                  checked: 'Married',
                },
              },
              {
                name: 'whenMarried',
                type: 'date',
                format: 'YYYY-MM-DD',
                dependencies: ['isMarried', 'relation'],
                display: ({ isMarried, relation }) => isMarried && relation === 'SELF',
                label: c('dateLabel'),
                required: true,
              },
              {
                name: 'removeMember',
                type: 'checkbox',
                dependencies: ['isMarried', 'relation'],
                display: ({ isMarried, relation }) => isMarried && relation !== 'SELF',
                label: c('planLabel'),
              },
              {
                name: 'removeFromHousehold',
                type: 'checkbox',
                dependencies: ['isMarried', 'relation'],
                display: ({ isMarried, relation }) => isMarried && relation !== 'SELF',
                label: c('householdLabel'),
              },
              {
                name: 'removeDate',
                type: 'date',
                format: 'YYYY-MM-DD',
                dependencies: ['isMarried', 'relation'],
                display: ({ isMarried, relation }) => isMarried && relation !== 'SELF',
                label: c('dateLabel'),
                required: true,
              },
            ],
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const CICMemberMarriageView = {
  name: Route.CIC_MEMBER_MARRIAGE,
  component: CICMemberMarriage,
  options: {},
};
