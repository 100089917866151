const createScale = (unit) => {
  return {
    get: (level) => {
      return unit * level;
    },
  };
};
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const position = createScale(8);

const POSITIONS = 10;

export const spacing = range(0, POSITIONS, 1).reduce(
  (acc, i) => {
    // Start at 0 if we want reset rules
    const increment = position.get(i);
    const halfsie = position.get(i + 1 / 2);

    return {
      maxWidth: 1112,
      ...acc,
      [`${i}`]: increment,
      [`${i}b`]: halfsie,

      [`allGutter${i}`]: {
        margin: increment,
      },
      [`xGutter${i}`]: {
        marginHorizontal: increment,
      },
      [`yGutter${i}`]: {
        marginVertical: increment,
      },
      [`topGutter${i}`]: {
        marginTop: increment,
      },
      [`rightGutter${i}`]: {
        marginRight: increment,
      },
      [`bottomGutter${i}`]: {
        marginBottom: increment,
      },
      [`leftGutter${i}`]: {
        marginLeft: increment,
      },
      [`topNegativeGutter${i}`]: {
        marginTop: -1 * increment,
      },
      [`topNegativeGutter${i}b`]: {
        marginTop: -1 * halfsie,
      },
      [`rightNegativeGutter${i}`]: {
        marginRight: -1 * increment,
      },
      [`bottomNegativeGutter${i}`]: {
        marginBottom: -1 * increment,
      },
      [`leftNegativeGutter${i}`]: {
        marginLeft: -1 * increment,
      },
      [`xNegativeGutter${i}`]: {
        marginHorizontal: -1 * increment,
      },
      [`yNegativeGutter${i}`]: {
        marginVertical: -1 * increment,
      },
      [`allNegativeGutter${i}`]: {
        margin: -1 * increment,
      },
      // Not sure how to best name baseline increments
      [`allGutter${i}b`]: {
        margin: halfsie,
      },
      [`xGutter${i}b`]: {
        marginHorizontal: halfsie,
      },
      [`yGutter${i}b`]: {
        marginVertical: halfsie,
      },
      [`topGutter${i}b`]: {
        marginTop: halfsie,
      },
      [`rightGutter${i}b`]: {
        marginRight: halfsie,
      },
      [`bottomGutter${i}b`]: {
        marginBottom: halfsie,
      },
      [`leftGutter${i}b`]: {
        marginLeft: halfsie,
      },
      [`allSpace${i}`]: {
        padding: increment,
      },
      [`xSpace${i}`]: {
        paddingHorizontal: increment,
      },
      [`ySpace${i}`]: {
        paddingVertical: increment,
      },
      [`topSpace${i}`]: {
        paddingTop: increment,
      },
      [`rightSpace${i}`]: {
        paddingRight: increment,
      },
      [`bottomSpace${i}`]: {
        paddingBottom: increment,
      },
      [`jumboBottomSpace`]: {
        paddingBottom: 150,
      },
      [`leftSpace${i}`]: {
        paddingLeft: increment,
      },
      // Not sure how to best name baseline increments
      [`allSpace${i}b`]: {
        padding: halfsie,
      },
      [`xSpace${i}b`]: {
        paddingHorizontal: halfsie,
      },
      [`ySpace${i}b`]: {
        paddingVertical: halfsie,
      },
      [`topSpace${i}b`]: {
        paddingTop: halfsie,
      },
      [`rightSpace${i}b`]: {
        paddingRight: halfsie,
      },
      [`bottomSpace${i}b`]: {
        paddingBottom: halfsie,
      },
      [`leftSpace${i}b`]: {
        paddingLeft: halfsie,
      },
      [`flex${i}`]: {
        flex: i,
      },
    };
  },
  // only need these added once
  {
    'topGutter+gap': {
      marginTop: 2,
    },
    'bottomGutter+gap': {
      marginBottom: 2,
    },
    'leftGutter+gap': {
      marginLeft: 2,
    },
    'rightGutter+gap': {
      marginRight: 2,
    },
  },
);
