import { ApolloApplicationMember } from '@app/data';
import { Trinary } from '@app/types';
import { getFullName, boolToTri } from '@app/utils';

type MemberFormData = {
  members: Array<{
    id: string;
    incomeThisMonth: boolean;
    fullName: string;
  }>;
};

type MemberPayload = Array<{ id: string; incomeThisMonth: Trinary }>;

export const getInitialValues = (members: Array<ApolloApplicationMember>) => {
  return members.map((member) => ({
    id: member.id,
    incomeThisMonth: member.incomeThisMonth,
    fullName: getFullName(member),
  }));
};

export const formatPayload = (values: MemberFormData): MemberPayload => {
  return values.members.map((m) => ({
    id: m.id,
    incomeThisMonth: boolToTri(m.incomeThisMonth || false),
  }));
};
