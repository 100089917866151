interface LinkResponse {
  role: 'link' | 'button';
  externalHref?: string;
  internalHref?: string;
  target?: '_blank';
}

/**
 * Computes some values based off of an href
 * - Checks the href and returns it either as externalHref or internalHref
 * - Uses the existence of an href to determine if role is link or button
 * - Specifies a blank target for external links
 */
export const handleHref = (href: string): LinkResponse => {
  // existence of http determines internal vs. external
  const external = /http/.test(href);

  return {
    role: !!href && external ? 'link' : 'button',
    target: external ? '_blank' : undefined,
    externalHref: external ? href : undefined,
    internalHref: external ? undefined : href,
  };
};
