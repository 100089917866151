import { Route } from '../paths';

type Tab = 'HOME' | 'INCOME' | 'TAXES' | 'PLAN' | 'COVERAGE' | 'ACCOUNT' | 'MONEY';
type TabRoute = Tab & Route;

// Pages that are the native bottom tabs
export const TAB_NAMES: Array<TabRoute> = ['HOME', 'MONEY', 'COVERAGE'];

// Pages that are web tabs
export const TAB_MONEY = 'MONEY';
export const TABS: Array<TabRoute> = ['HOME', TAB_MONEY, 'COVERAGE'];

export const NEW_TAB_NAMES = {
  HOME: 'Home',
  MONEY: 'Money',
  COVERAGE: 'Coverage',
};
