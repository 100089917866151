import gql from 'graphql-tag';

const SUBMIT_ENROLLMENT = gql`
  mutation SubmitEnrollment($applicationID: ID!) {
    submitEnrollment(id: $applicationID) {
      currentAppStatus
      policyResponseCode
      policyResponseSubcode
      memberResponseCode
      memberResponseSubcode
      error {
        code
        field
        message
      }
    }
  }
`;

export default {
  document: SUBMIT_ENROLLMENT,
  awaitRefetch: true,
  getErrors: (data) => (data?.submitEnrollment?.error ? [data?.submitEnrollment?.error] : null),
};
