import styled from 'styled-components';
import { FontSize, FontWeight } from '@app/types';
import { Text } from '../Text';

const StyledError = styled(Text)`
  display: block;
  padding-top: 4px;
`;

export function ErrorMessage({ error }: { error: string }) {
  return (
    <StyledError color="error" size={FontSize.sm} weight={FontWeight.medium}>
      {error}
    </StyledError>
  );
}
