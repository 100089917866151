import React from 'react';

import { FormattedMessage } from 'react-intl';

/**
 * @deprecated no need for a component here
 */
const LabelText = ({ fieldName, lowercase }) => (
  <FormattedMessage id={`catch.user.${fieldName}.label${lowercase ? '.lowercase' : ''}`} />
);

export default LabelText;
