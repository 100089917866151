import { BasicFormBlueprint } from '@app/blueprints';
import { constants, getFullName, lowercase, useCopy } from '@app/utils';
import { Link } from '@app/_ui-kit';
import { Route } from '@app/types';
import { docUploadfields } from './healthDocsUploadFields';
import {
  useMutation,
  UploadHealthIssueDocument,
  DMI,
  HealthDocType,
  SVI,
  SviStatusCode,
  ApplicationIssuesQuery,
} from '@app/data';
import { open, exit } from '@app/navigate';
import { useMemo } from 'react';

const HealthDocsUpload = ({
  applicationID,
  issue,
}: {
  applicationID: string;
  issue: DMI | SVI;
}) => {
  const { c } = useCopy('catch');
  const fields = useMemo(() => docUploadfields(issue), [issue]);
  const linkType = issue.docType === HealthDocType.DMI ? issue.verificationType : issue.statusCode;

  const [uploadDocs, { loading: uploading }] = useMutation(UploadHealthIssueDocument, {
    onCompleted: () => exit(),
    refetchQueries: [ApplicationIssuesQuery],
  });

  const uploadIssueDocuments = (values) => {
    const input = values.documents.map((doc) => ({
      issueID: issue.id,
      sviDocumentType: doc.sviDocumentType,
      dmiDocumentType: doc.dmiDocumentType,
      key: doc.upload.key,
      filetype: doc.upload.filetype,
    }));

    uploadDocs({ variables: { input } });
  };

  return (
    <BasicFormBlueprint
      loading={uploading}
      submitting={uploading}
      title={c('ede.DMI.title')}
      subtitles={[
        c(`ede.DMI.docUploadSubtitle`, {
          category: 'name',
          name:
            issue.docType === HealthDocType.SVI && issue.statusCode === SviStatusCode.Adoption
              ? issue.members[0].givenName
              : getFullName(issue.members),
          issueType: issue.docType,
          type: lowercase(
            c(
              issue.docType === HealthDocType.SVI
                ? `ede.DMI.${issue.statusCode}.title`
                : `ede.enums.${issue.subType}`,
            ),
          ),
          verificationType: issue.docType === HealthDocType.DMI ? issue.verificationType : '',
          update: (
            <Link testID="update-link" onPress={() => open(Route.EDE_REVIEW, { applicationID })}>
              {c('basics.here')}
            </Link>
          ),
        }),
        <Link href={constants.helpUrls[linkType]} target="_blank" gutter="top" align="right" inline>
          {c('ede.DMI.docHelpLink')} →
        </Link>,
      ]}
      formConfig={{
        initialValues: { documents: [] },
        fields,
        onSubmit: uploadIssueDocuments,
      }}
    />
  );
};

export const HealthDocsUploadView = {
  name: Route.HEALTH_DOCS_UPLOAD,
  component: HealthDocsUpload,
  options: {
    layout: 'sheet',
    accentColor: 'coverage',
    presentation: 'largeSheet',
  },
};

export default HealthDocsUpload;
