import React from 'react';
import { shallow } from 'zustand/shallow';
import { EmptyState } from '@uikit';
import { useCopy } from '@app/utils';
import { open, sheets, useSheet } from '@app/navigate';
import { Route } from '@types';
import WalletIcon from '@svg/wallet.svg';

interface WalletCardProps {
  item?: {
    vertical: 'VISION' | 'LIFE' | 'DENTAL' | 'HEALTH';
    id?: string;
    carrier?: string;
    planName?: string;
    source?: string;
    groupNumber?: string;
    policyNumber?: string;
    notes?: string;
  };
}

const WalletCard: React.FC<WalletCardProps> = ({ item }) => {
  const { c } = useCopy('catch.benefits');
  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);

  const onPress = () => {
    if (item) {
      open(Route.WALLET_ITEM, { item });
    } else {
      openSheet(sheets.ADD_TO_WALLET);
    }
  };

  return (
    <EmptyState
      onPress={onPress}
      icon={WalletIcon}
      title={c(item?.vertical) || 'Already covered?'}
      subtitle={item?.carrier || 'Add info'}
    />
  );
};

export default WalletCard;
