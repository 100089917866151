/**
 * @todo move to @catch-co/health-utils
 * eligibility diff states
 */
export const HIGHER_APTC = 'higherAPTC';
export const GAINED_MEDICAID = 'gainedMedicaid';
export const GAINED_CHIP = 'gainedCHIP';
export const LOWER_APTC = 'lowerAPTC';
export const LOST_MEDICAID = 'lostMedicaid';
export const LOST_CHIP = 'lostCHIP';
export const NO_CHANGE = 'noChange';

export const compareElig = (elig, parent) => {
  if (!('totalAptc' in elig)) {
    return null;
  }

  if (elig.totalAptc > parent.totalAptc) {
    return HIGHER_APTC;
  }
  if (elig.isMedicaid && !parent.isMedicaid) {
    return GAINED_MEDICAID;
  }
  if (elig.isChip && !parent.isChip) {
    return GAINED_CHIP;
  }
  if (elig.totalAptc < parent.totalAptc) {
    return LOWER_APTC;
  }
  if (!elig.isMedicaid && parent.isMedicaid) {
    return LOST_MEDICAID;
  }
  if (!elig.isChip && parent.isChip) {
    return LOST_CHIP;
  }
  return NO_CHANGE;
};
