import React, { useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { View } from 'react-native';

import { useAuth } from '@auth';
import { UpdateUser, UserQuery, useMutation, useQuery } from '@data';
import { Env, openIntercom, replaceAll, copyToClipboard } from '@util';
import { open } from '@navigate';
import { useTheme, Link, Icon } from '@uikit';
import { useDebug } from '@app/hooks/useDebug';
import { Actions } from '@app/config/actions';
import { Route } from '@types';
import { Color } from '@app/styles';

import { StyledSearch, StyledOverlay, StyledHeading, DevMenuAction, DevMenuNew } from './DevStyles';
import { routeMatch } from './utils';
import { HEALTH_SSNS, TEST_DATA } from './data';
import { ID_PROOF_USER_INFO } from './idProofData';

export const dRoutes = [
  { key: 'h+r', name: 'Health Resume', path: Route.HEALTH_RESUME },
  { key: 'h+l', name: 'Health Link', path: Route.HEALTH_LINK_INTRO },
  { key: 'h+e', name: 'Health EDE', path: Route.EDE_INTRO },
  { key: 'h+x', name: 'Health Explorer', path: Route.EXPLORER_INTRO },
  { key: 'h+c', name: 'Health CIC', path: Route.CIC_CHANGE_SELECTION },
  { key: '1', name: 'Home', path: Route.HOME },
  { key: '5', name: 'Coverage', path: Route.COVERAGE },
  { key: '6', name: 'Me', path: Route.ACCOUNT_INFO },
];

export const keys = dRoutes.map((item, idx) => item.key).join(',');

const HealthSSNs = () => {
  return (
    <>
      <StyledHeading>EDE HOUSEHOLDS</StyledHeading>
      {Object.keys(HEALTH_SSNS)?.map((section) => (
        <>
          {HEALTH_SSNS[section].map((item) => (
            <DevMenuNew
              key={item}
              onPress={() => copyToClipboard(replaceAll(item, '-', ''))}
              value={item}
              outcome={section}
              type="SSN"
              k="COPY"
            />
          ))}
        </>
      ))}
    </>
  );
};

const DevMenu = ({}) => {
  const field = useRef();
  const { themeColors, theme } = useTheme();
  const { showLayout, showTestIds, toggle } = useDebug();
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [index, setIndex] = useState(0);
  const m = routeMatch(search);
  const { token } = useAuth();

  const { data } = useQuery(UserQuery);
  const [updateUser] = useMutation(UpdateUser);

  const { userID, email, phoneNumber } = useMemo(() => {
    return {
      userID: data?.viewer?.user?.id,
      email: data?.viewer?.user?.email,
      phoneNumber: data?.viewer?.user?.phoneNumber,
    };
  }, [data]);

  const [nested, setNested] = useState();

  const _search = (term, e) => {
    setIsOpen(true);
    setSearch(term);
    setTimeout(() => {
      field.current?.focus();
    }, 50);
  };

  const _data = (e) => {
    setIsOpen(true);
    setNested(true);
  };

  const _clearSearch = (close) => {
    setSearch('');
    setTimeout(() => {
      field.current?.blur();
    }, 50);
    if (close) setIsOpen(false);
  };

  /** OPEN MENU  */
  useHotkeys('cmd+k,shift+/,ctrl+k,option+k', (e) => {
    e.stopPropagation();
    setIsOpen(true);
    return false;
  });

  /** SEARCH (OR OPEN WITH SEARCH) */
  useHotkeys('c+i', (e) => copyToClipboard(userID), [userID]);
  useHotkeys('c+e', (e) => copyToClipboard(email), [email]);
  useHotkeys('c+p', (e) => copyToClipboard(phoneNumber), [phoneNumber]);

  /** SEARCH (OR OPEN WITH SEARCH) */
  useHotkeys('/', (e) => _search('', e), {}, [search, field]);
  useHotkeys('d', (e) => _data('', e), {}, []);

  /** CLOSE/BACK */
  useHotkeys(
    'esc',
    () => {
      if (nested) {
        setNested(false);
      } else if (isOpen) {
        _clearSearch(!search || search === '');
      }
    },
    {},
    [isOpen, search, field, nested],
  );

  useHotkeys('s+n', () => {
    setIsOpen(true);
    setNested('health-ssns');
  });

  /** OPEN HELP (WILL BE HOTKEYS) */
  useHotkeys('cmd+/', openIntercom);

  /** DEBUG LAYERS */
  useHotkeys('.', () => toggle('showLayout'), {}, [showLayout]);
  useHotkeys(',', () => toggle('showTestIds'), {}, [showTestIds]);

  /** OPEN ADMIN PAGE */
  useHotkeys('\\', () => window.open(`${Env.adminUrl}/users/${userID}`, '_blank'), {}, [userID]);

  /** JUMP ROUTES */
  useHotkeys(keys, (event, handler) => {
    const r = dRoutes.filter((item) => item.key === handler.key)?.[0];
    if (r?.path) {
      open(r.path);
      setIsOpen(false);
    }
  });

  const isSearching = !!isOpen && (document?.activeElement === field.current || !!search);

  return (
    <>
      <Icon
        type="Command"
        label="Dev Menu"
        onPress={() => setIsOpen(!isOpen)}
        color={Color.negative}
      />
      {!!isOpen && (
        <StyledOverlay
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <View
            style={[
              theme.acrylic,
              theme.webpop,
              theme.midCorners,
              {
                width: 550,
                maxWidth: '100%',
                border: 'none',
                bottom: 24,
                maxHeight: '100%',
                flex: 0,
                top: 0,
              },
            ]}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {nested ? (
              <View
                style={[
                  {
                    overflow: 'scroll',
                    transformOrigin: 'top',
                    flex: 1,
                  },
                ]}
              >
                <Link testID="back" onPress={() => setNested()}>
                  Back
                </Link>
                {TEST_DATA?.map((row, idx) => (
                  <DevMenuNew
                    key={idx}
                    provider={row?.provider}
                    value={row?.value}
                    outcome={row?.outcome}
                    workflow={row?.workflow}
                    type={row?.type}
                    onPress={() => copyToClipboard(row?.value)}
                    k="COPY"
                  />
                ))}
                <StyledHeading>IDP CASES</StyledHeading>
                <DevMenuNew
                  value={'passid'}
                  outcome="pass"
                  type="IDP"
                  k="upsert"
                  onPress={() =>
                    updateUser({
                      variables: {
                        input: {
                          middleName: 'passid',
                        },
                      },
                    })
                  }
                />
                {ID_PROOF_USER_INFO?.filter((row) => !!row.ssn)?.map((row, idx) => (
                  <DevMenuNew
                    key={idx}
                    value={row?.description}
                    type="IDP"
                    onPress={() =>
                      updateUser({
                        variables: {
                          input: {
                            givenName: row.givenName,
                            middleName: row.middleName,
                            familyName: row.familyName,
                            legalAddress: {
                              street1: row.street1,
                              city: row.city,
                              state: row.state,
                              zip: row.zip?.toString(),
                              zip4: row.zip4?.toString(),
                            },
                            ssn: `${row.ssn.toString().slice(0, 3)}-${row.ssn
                              .toString()
                              .slice(3, 5)}-${row.ssn.toString().slice(5, 9)}`,
                            dob: row.dob,
                            phoneNumber: row.phoneNumber?.toString(),
                          },
                        },
                      })
                    }
                    k="upsert"
                  />
                ))}
                <HealthSSNs />
              </View>
            ) : (
              <>
                <StyledSearch
                  bg={themeColors.dodgeColor}
                  color={themeColors.textColor}
                  ref={field}
                  type="text"
                  label="search"
                  placeholder={
                    document?.activeElement === field?.current || (!!search && search !== '')
                      ? 'esc'
                      : '/'
                  }
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value?.replace('/', ''));
                  }}
                  onKeyUp={(e) => {
                    if (!isOpen) {
                      e.stopPropagation();
                      return;
                    }
                    if (e.keyCode === 27) {
                      _clearSearch();
                      return;
                    }
                    if (e.keyCode === 38) {
                      setIndex(Math.max(0, index - 1));
                    }
                    if (e.keyCode === 40) {
                      setIndex(Math.min(m?.length - 1, index + 1));
                    }
                    if (e.keyCode === 13) {
                      open(m?.[index], { slug: 'taxes' });
                      setSearch(null);
                      field.current?.blur();
                      setIsOpen(false);
                      return;
                    }
                  }}
                />

                <View
                  style={[
                    {
                      overflow: 'scroll',
                      transformOrigin: 'top',
                      flex: 1,
                    },
                  ]}
                >
                  {isSearching ? (
                    m?.map((rt, i) => (
                      <DevMenuAction
                        onPress={() => open(rt)}
                        text={rt}
                        selected={index === i ? 'A' : ''}
                        k={''}
                        key={rt || i}
                      />
                    ))
                  ) : (
                    <>
                      <br />
                      <DevMenuAction text="Open Menu" k="⌘K" />
                      <DevMenuAction text="Search/GoTo" k="/" />
                      <DevMenuAction text="Exit or pop" k="esc" />

                      <DevMenuNew
                        onPress={() => toggle('showLayout')}
                        type="Toggle"
                        value={Actions.DEBUG_LAYOUT.label}
                        k="."
                      />
                      <DevMenuNew
                        onPress={() => toggle('showTestIds')}
                        type="Toggle"
                        value={Actions.DEBUG_TESTIDS.label}
                        k=","
                      />
                      <DevMenuNew
                        onPress={() => copyToClipboard(token)}
                        type="Copy"
                        value="Token"
                        k="C+T"
                      />

                      <StyledHeading color={themeColors.textColor}>USER</StyledHeading>
                      <DevMenuNew
                        onPress={() => copyToClipboard(userID)}
                        type="Copy"
                        value="User ID"
                        k="C+I"
                      />
                      <DevMenuNew
                        onPress={() => copyToClipboard(email)}
                        type="Copy"
                        value="Email"
                        k="C+E"
                      />
                      <DevMenuNew
                        onPress={() => copyToClipboard(email)}
                        type="Copy"
                        value="Phone"
                        k="C+P"
                      />
                      <DevMenuAction
                        onPress={() => window.open(`${Env.adminUrl}/users/${userID}`, '_blank')}
                        text="Open in Admin"
                        k="\"
                      />

                      <StyledHeading color={themeColors.textColor}>DATA</StyledHeading>
                      <DevMenuAction
                        onPress={() => setNested('health-ssns')}
                        text="Open Sample Data"
                        k="d"
                      />
                      <DevMenuAction
                        onPress={() => setNested('health-ssns')}
                        text="Health SSNs"
                        key="s+n"
                      />
                      <StyledHeading color={themeColors.textColor}>GOTO</StyledHeading>
                      {dRoutes.map((seq, _) => (
                        <DevMenuNew
                          onPress={() => open(seq.path)}
                          type="Go to "
                          value={seq.name}
                          k={seq.key}
                          key={seq.name}
                        />
                      ))}
                    </>
                  )}
                </View>
              </>
            )}
          </View>
        </StyledOverlay>
      )}
    </>
  );
};

export default DevMenu;
