import React, { useMemo } from 'react';
import { Button } from '@uikit';
import Plus from '@svg/plus.svg';
import { Stack } from '@app/layouts';
import { useCopy } from '@app/utils';

const ConfirmApplicantsFooter: React.FC = ({ values, append, disabled }) => {
  const { c } = useCopy();
  const hasSpouse = useMemo(() => {
    return values?.members?.some((m) => m.relation === 'SPOUSE');
  }, [values]);

  const addSpouse = () => {
    append({ relation: 'SPOUSE', isRequestingCoverage: true });
  };

  const addChild = () => {
    append({ relation: 'CHILD', isRequestingCoverage: true });
  };

  return (
    <Stack horizontal spacing="1">
      {!hasSpouse && (
        <Button onPress={addSpouse} small testID="add-spouse" svg={Plus} disabled={disabled}>
          {c('add-spouse')}
        </Button>
      )}
      <Button onPress={addChild} small testID="add-child" svg={Plus} disabled={disabled}>
        {c('add-child')}
      </Button>
    </Stack>
  );
};

export default ConfirmApplicantsFooter;
