import React from 'react';
import { useCopy } from '@app/utils';
import { Toolbar } from '@layouts';
import { Button } from '@uikit';
import { SheetBlueprint } from '@app/blueprints';

const types = {
  HEALTHCARE_GOV: {
    title: 'HealthCare.gov',
    url: 'https://www.healthcare.gov/',
  },
  HEALTHSHERPA: {
    title: 'Health Center',
    url: 'https://www.healthsherpa.com/sessions/new?_agent_id=catch',
  },
  OSCAR: {
    title: 'Health Center',
    url: 'https://www.hioscar.com/auth/login',
  },
};

/**
 * Certain health enrollments are managed outside of Catch:
 * - enrollments with associated dental
 * - HS data imports (not via strict linking)
 *
 * In these cases, we need to show the user where to manage their
 * coverage when they click
 *
 * managedBy: one of HEALTHCARE_GOV, HEALTHSHERPA
 * reason: one of DENTAL
 */
const CoverageManagedOutsideSheet = ({ managedBy, reason, closeSheet }) => {
  const { c } = useCopy('catch.health.managedOutside');
  const { c: basics } = useCopy('catch');

  const handleOpen = () => {
    window.open(types[managedBy]?.url);
    closeSheet();
  };

  return (
    <SheetBlueprint title={c(`${managedBy}.title`)} subtitles={[c(`${reason}.subtitle`)]}>
      <Toolbar type="stack">
        <Button testID="managed-by" icon="Share" wide onPress={handleOpen}>
          {types[managedBy]?.title}
        </Button>
        <Button testID="nvm" wide onPress={closeSheet}>
          {basics('neverMind')}
        </Button>
      </Toolbar>
    </SheetBlueprint>
  );
};

export default CoverageManagedOutsideSheet;
