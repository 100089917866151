import { isEmpty } from 'lodash';
import { useState } from 'react';
import { ConfirmationBlueprint } from '@app/blueprints';
import {
  DocumentRequests,
  DocumentRequestType,
  FileType,
  HANDLE_DOCUMENT_REQUEST,
  useFintechMutation,
  useFintechQuery,
} from '@app/data';
import { DocumentUpload, DocumentUploadField } from '@app/features/misc/DocumentUploadView';
import { FileType as FileUploadType } from '@app/hooks/useUpload';
import { navigate } from '@app/navigate';
import { Route } from '@app/types';
import {
  DOCUMENT_REQUEST_TYPE_TO_DOCUMENT_TYPE,
  DOCUMENT_REQUEST_TYPE_TO_NAME,
} from '@app/utils/const/DOCUMENT_REQUEST';
import { CatchTheme } from '@uikit';

function BankingSetupDocumentUpload() {
  const [status, setStatus] = useState<'upload' | 'uploaded'>('upload');
  const [handleDocumentRequest, { error }] = useFintechMutation(HANDLE_DOCUMENT_REQUEST, {
    onCompleted: () => {
      setStatus('uploaded');
    },
    refetchQueries: [DocumentRequests],
  });
  const { data, loading } = useFintechQuery(DocumentRequests);

  const documentRequest = data?.documentRequests[0];

  function handleSubmit(values) {
    handleDocumentRequest({
      variables: {
        input: {
          back: documentRequest?.bothSides
            ? {
                objectName: values.back,
              }
            : undefined,
          // @ts-ignore
          documentRequestID: documentRequest.id,
          front: {
            objectName: values.front,
          },
        },
      },
    });
  }

  let fields: DocumentUploadField[] = [];

  data?.documentRequests?.forEach((document) => {
    const name = DOCUMENT_REQUEST_TYPE_TO_NAME[document.type as DocumentRequestType];
    const common: Partial<DocumentUploadField> = {
      documentType: DOCUMENT_REQUEST_TYPE_TO_DOCUMENT_TYPE[document.type as DocumentRequestType],
      filePrefix: 'DocumentRequest',
      // @todo cleanup after fixing useUpload fileTypes prop
      //fileTypes: document.fileTypes,
      fileTypes: [
        ...(document.fileTypes || []),
        document.fileTypes?.includes(FileType.Heic) ? FileUploadType.HeicMime : null,
        document.fileTypes?.includes(FileType.Heif) ? FileUploadType.HeifMime : null,
        ...(document.fileTypes?.includes(FileType.Jpeg)
          ? [FileUploadType.JpegMime, FileUploadType.Jpg]
          : []),
        document.fileTypes?.includes(FileType.Pdf) ? FileUploadType.Pdf : null,
        document.fileTypes?.includes(FileType.Png) ? FileUploadType.PngMime : null,
      ].filter(Boolean),
      inputKey: 'filePath',
      required: document.status === 'REQUIRED',
      type: 'file',
    };

    if (document.bothSides) {
      fields.push(
        // @ts-ignore
        {
          ...common,
          label: `Upload the front of your ${name}`,
          name: 'front',
        },
        {
          ...common,
          label: `Upload the back of your ${name}`,
          name: 'back',
        },
      );
    } else {
      // @ts-ignore
      fields.push({
        ...common,
        label: `Upload your ${name}`,
        name: 'front',
      });
    }
  });

  return (
    <ConfirmationBlueprint
      titles={{ done: 'Your documents are in review' }}
      subtitles={{
        done: 'Catch Support is verifying your identity and will reach out with next steps.',
      }}
      called={status === 'uploaded' || (!loading && isEmpty(documentRequest))}
      // increase delay so copy is readable
      delay={CatchTheme.animation.delayLong}
      error={!!error}
      loading={false}
      onSuccess={() => navigate(Route.HOME)}
    >
      <DocumentUpload
        title="Identity documentation"
        subtitles={[
          'In order to finalize your Catch Money account, we’ll need to verify your identity.',
        ]}
        fields={fields}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </ConfirmationBlueprint>
  );
}

export const BankingSetupDocumentUploadView = {
  name: Route.BANKING_SETUP_DOCUMENT_UPLOAD,
  component: BankingSetupDocumentUpload,
  options: {},
};
