import React from 'react';
import { unstable_createElement } from 'react-native-web';
import { Pressable } from '@uikit';
import { colorElement } from './utils';

import { useTheme } from '../hooks/useTheme';

/**
 * @deprecated
 */
const createIconComponent =
  ({ content, contentDark, height, width, viewBox, fill, id, defs, defsDark, name, colorable }) =>
  // eslint-disable-next-line
  ({ label, style, onPress, color, containerStyle, disabled, ...other }) => {
    const { themeColors, colorScheme } = useTheme();

    const props = {
      style: [styles.root, onPress && styles.clickable, style],
      width,
      height,
      viewBox,
      ...other,
    };
    const [title, ...svgElements] = content;
    // Select the svg content based on color scheme and availability
    const colorSchemeElements = colorScheme === 'dark' && contentDark ? contentDark : svgElements;
    const colorSchemeDefs = colorScheme === 'dark' && defsDark ? defsDark : defs;
    const definitions = colorSchemeDefs
      ? [
          unstable_createElement(
            'defs',
            {},
            ...Object.entries(colorSchemeDefs).map(([key, { stops, ...props }]) => {
              return unstable_createElement(
                'linearGradient',
                { id: key, ...props },
                ...Object.entries(stops).map(([offset, color]) =>
                  unstable_createElement('stop', {
                    offset,
                    stopColor: color.split('|')[0] || undefined,
                    stopOpacity: color.split('|')[1],
                  }),
                ),
              );
            }),
          ),
        ]
      : [];

    const icon = unstable_createElement(
      'svg',
      {
        xmlns: 'http://www.w3.org/2000/svg',
        accessibilityRole: 'img',
        accessibilityLabel: label || (title && title.children),
        fill: fill || 'none',
        ...props,
      },
      ...colorSchemeElements
        .map(({ type, children, colorable: elColorable, ...rest }) => {
          const coloringProps = { ...rest, colorable: elColorable };

          return unstable_createElement(type, {
            ...rest,
            ...colorElement(coloringProps, color, colorable, themeColors),
          });
        })
        .concat(definitions),
    );
    if (onPress) {
      return (
        <Pressable
          accessibilityLabel={label}
          handleOnPress={onPress}
          style={containerStyle}
          disabled={disabled}
        >
          {icon}
        </Pressable>
      );
    }

    return icon;
  };

const styles = {
  root: {
    display: 'inline-block',
    maxWidth: '100%',
    position: 'relative',
    userSelect: 'none',
    textAlignVertical: 'text-bottom',
    pointerEvents: 'none',
  },
  clickable: {
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
};

export default createIconComponent;
