import { navigate } from '@navigate';
import { Route } from '@types';
import { ErrorCode } from '@app/data';

export const handleHealthResponse = ({ onError, onCompleted }) => ({
  onError: (error) => {
    console.log('There was a healthError', { error });
    if (onError) onError();
    navigate(Route.EDE_HELP);
  },
  onCompleted: (data) => {
    let error = false;

    Object.keys(data).forEach((mutation) => {
      const response = data[mutation];

      if (response?.error || response?.errors?.length > 0) {
        error = true;

        console.log('There was a healthError', {
          error: response?.error,
          errors: response?.errors,
        });

        if (onError) onError();
        navigate(Route.EDE_HELP);
      }
    });

    if (!error && onCompleted) {
      onCompleted(data);
    }
  },
});

export const handleHealthErrors = {
  onError: (error) => {
    console.log('There was a healthError', { error });
    navigate(Route.EDE_HELP);
  },
  onCatchError: (error) => {
    console.log('There was a healthError', { error });

    if (error?.[0]?.code === ErrorCode.AlreadyExists) {
      /**
       * duplicate email error for GA applicants
       * indicates email entered on id info page is already in use
       * do a quick update to email to get around this
       */
      navigate(Route.EDE_ALTERNATE_EMAIL);
    } else if (error?.[0]?.message?.includes('Found valid applications to import')) {
      navigate(Route.EDE_APPLICATION_IMPORT);
    } else {
      navigate(Route.EDE_HELP);
    }
  },
};

export const handleHealthErrorsWithProps = (props) => ({
  onError: (error) => {
    console.log('There was a healthError', { error });
    navigate(Route.EDE_HELP, props);
  },
  onCatchError: (error) => {
    console.log('There was a healthError', { error });
    navigate(Route.EDE_HELP, props);
  },
});

export const getHealthErrorHandlers = (onError) => ({
  onError: (error) => {
    console.log('There was a healthError', { error });
    if (onError) onError();
    navigate(Route.EDE_HELP);
  },
  onCatchError: (error) => {
    console.log('There was a healthError', { error });
    if (onError) onError();
    navigate(Route.EDE_HELP);
  },
});
