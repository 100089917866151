import createIconComponent from '../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Profile = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'circle',
      cx: '16',
      cy: '16',
      r: '16',
      fill: '#F1F3F500',
    },
    {
      type: 'path',
      d: 'M5.18439 27.7909C6.91344 23.7453 10.5302 21 15.9995 21C21.4694 21 25.0864 23.7453 26.8155 27.791C23.9676 30.4047 20.1701 32 16 32C11.8298 32 8.03227 30.4046 5.18439 27.7909Z',
      fill: '#70747D',
    },
    {
      type: 'path',
      d: 'M22.0001 12C22.0001 15.3137 19.314 18 16.0005 18C12.6861 18 10 15.3137 10 12C10 8.68627 12.6861 6 16.0005 6C19.314 6 22.0001 8.68627 22.0001 12Z',
      fill: '#70747D',
    },
  ],
  contentDark: [
    {
      type: 'circle',
      opacity: '0.08',
      cx: '16',
      cy: '16',
      r: '16',
      fill: 'white',
    },
    {
      type: 'path',
      d: 'M5.18457 27.7909C6.91362 23.7453 10.5304 21 15.9996 21C21.4696 21 25.0866 23.7453 26.8157 27.791C23.9678 30.4047 20.1703 32 16.0002 32C11.83 32 8.03245 30.4046 5.18457 27.7909Z',
      fill: 'white',
      opacity: '0.7',
    },
    {
      type: 'path',
      d: 'M22.0002 12C22.0002 15.3137 19.3142 18 16.0007 18C12.6863 18 10.0002 15.3137 10.0002 12C10.0002 8.68627 12.6863 6 16.0007 6C19.3142 6 22.0002 8.68627 22.0002 12Z',
      fill: 'white',
      opacity: '0.7',
    },
  ],
  defs: {},
  defsDark: {},
  name: 'Profile',
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
});
Profile.displayName = 'Profile';

/** @deprecated */
export default Profile;
