import React from 'react';
import { SkeletonColor, FontSize, TextAlign } from '@types';
import { Placeholder, PlaceholderLine } from 'rn-placeholder';
import { useTheme } from '../hooks/useTheme';
import { typeLineHeight, typeSizeSmall } from '../theme/_constants';

interface Props {
  loading?: boolean;
  children?: any;
  color?: SkeletonColor;
  fontSize?: FontSize;
  height?: any;
  width?: any;
  align?: TextAlign;
  style?: any;
}

/**
 * Wrapper for showing the skeleton when loading
 */
export const Skeleton = ({ loading, children, height, width, fontSize, align, style }: Props) => {
  const { theme, themeColors } = useTheme();
  const fillHeight = !!fontSize ? typeLineHeight[fontSize] : 0;
  const textHeight = !!fontSize ? typeSizeSmall[fontSize] : 0;
  const margin = (fillHeight - textHeight) / 2 + 2;

  return loading ? (
    <Placeholder style={theme.blend}>
      <PlaceholderLine
        color={themeColors.skeletonColor}
        style={[
          { marginBottom: 0 }, // prevents default margin on bottom
          !!textHeight && {
            height: textHeight,
            width: 200,
            marginTop: margin,
            marginBottom: margin,
          },

          !!height && { height },
          !!width && { width },
          align === 'center' && { alignSelf: 'center' },
          align === 'right' && { alignSelf: 'flex-end' },
          style,
        ]}
      />
    </Placeholder>
  ) : (
    children || null
  );
};

export default Skeleton;
