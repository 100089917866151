import gql from 'graphql-tag';

const PREFILL_HEALTH_ENROLLMENT = gql`
  mutation PrefillHealthEnrollment {
    prefillHealthInsuranceEnrollment {
      insuranceEnrollment {
        id
        applicationID
      }
    }
  }
`;

export default {
  document: PREFILL_HEALTH_ENROLLMENT,
};
