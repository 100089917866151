import { Loading } from '@app/_ui-kit';
import { useFlags } from '@app/hooks/useFlags';
import { paths } from '@app/navigate';
import { Navigate } from 'react-router';

export function FintechWrapper({ children }: { children: React.ReactNode }) {
  const { loading, hasAccessToFintech } = useFlags();

  if (loading) {
    return <Loading full page />;
  }

  if (!hasAccessToFintech) {
    return <Navigate to={paths.HOME} />;
  }

  return <>{children}</>;
}
