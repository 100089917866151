import createIconComponent from '../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Person = createIconComponent({
  content: [
    {
      type: 'title',
      children: 'Applicant',
    },
    {
      type: 'path',
      d: 'M5.68749 5.72727C7.26892 5.72727 8.5509 4.44519 8.5509 2.86364C8.5509 1.28208 7.26892 0 5.68749 0C4.10561 0 2.82363 1.28208 2.82363 2.86364C2.82363 4.44519 4.10561 5.72727 5.68749 5.72727Z',
      fill: '#70747D',
      colorable: true,
    },
    {
      type: 'path',
      d: 'M10.5965 12C11.0483 12 11.42 11.6322 11.3703 11.1831C11.0624 8.40693 9.15505 7.27273 5.68713 7.27273C2.21964 7.27273 0.312292 8.40693 0.0044849 11.1831C-0.0453104 11.6322 0.326408 12 0.778278 12H10.5965Z',
      fill: '#70747D',
      colorable: true,
    },
  ],
  id: '8859%3A46087',
  name: 'Person',
  width: '12',
  height: '12',
  viewBox: '0 0 12 12',
});
Person.displayName = 'Person';

/** @deprecated */
export default Person;
