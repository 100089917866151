import { gql } from '../generated';

export const UpsertNudge = gql(`
  mutation upsertNudge($input: NudgeInput!) {
    upsertNudge(input: $input) {
      nudgeIdentifier
      isDismissed
      lastUpdated
    }
  }
`);
