import gql from 'graphql-tag';

export interface FetchEligibilityVars {
  applicationID: string;
}

export interface FetchEligibilityResponse {
  fetchEligibility: Array<{
    id: string;
    hasEligibilityChanged: boolean;
  }>;
}

export const FetchEligibility = gql`
  mutation FetchEligibility($applicationID: ID!) {
    fetchEligibility(id: $applicationID) {
      id
      applicationID
      hasEligibilityChanged
    }
  }
`;
