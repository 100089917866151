/**
 * Helpers for naming conventions surrounding plan types and goal types
 *
 * Note: they keys used in both objects are universal so that we can
 * easily map between them! Therefore, default to using the keys
 * when possible in the rest of the codebase!
 */

// Standardized benefit names
export const BENEFITS = {
  TAX: 'TAX',
  PLANTYPE_TAX: 'TAX',
  Tax: 'TAX',
  tax: 'TAX',
  taxes: 'TAX',

  PTO: 'PTO',
  PLANTYPE_PTO: 'PTO',
  pto: 'PTO',
  Timeoff: 'PTO',
  timeoff: 'PTO',

  RETIREMENT: 'RETIREMENT',
  PLANTYPE_RETIREMENT: 'RETIREMENT',
  Retirement: 'RETIREMENT',
  retirement: 'RETIREMENT',

  FAMILY_LEAVE: 'FAMILY_LEAVE',
  PLANTYPE_FAMILY_LEAVE: 'FAMILY_LEAVE',
  PLANTYPE_PARENTAL_LEAVE: 'FAMILY_LEAVE',
  FamilyLeave: 'FAMILY_LEAVE',
  familyLeave: 'FAMILY_LEAVE',
  'family-leave': 'FAMILY_LEAVE',

  HEALTH_EXPENSES: 'HEALTH_EXPENSES',
  PLANTYPE_HEALTH_EXPENSES: 'HEALTH_EXPENSES',
  HealthExpenses: 'HEALTH_EXPENSES',
  healthExpenses: 'HEALTH_EXPENSES',
  'health-expenses': 'HEALTH_EXPENSES',

  EMERGENCY_SAVINGS: 'EMERGENCY_SAVINGS',
  PLANTYPE_EMERGENCY_SAVINGS: 'EMERGENCY_SAVINGS',
  EmergencySavings: 'EMERGENCY_SAVINGS',
  emergencySavings: 'EMERGENCY_SAVINGS',
  'emergency-savings': 'EMERGENCY_SAVINGS',

  HEALTH: 'HEALTH',
  HEALTH_LINK: 'HEALTH',
  HEALTH_SLASH: 'HEALTH',
  HEALTH_EXPRESS: 'HEALTH',
  HEALTH_APPLICATION: 'HEALTH',
  PREMIUM_SLASHER: 'HEALTH',
  PLANTYPE_HEALTH: 'HEALTH',
  HEALTH_RENEW: 'HEALTH',
  Health: 'HEALTH',
  health: 'HEALTH',
  HEALTH_INSURANCE: 'HEALTH',

  DENTAL: 'DENTAL',
  PLANTYPE_DENTAL: 'DENTAL',
  dental: 'DENTAL',
  DENTAL_INSURANCE: 'DENTAL',

  LIFE: 'LIFE',
  PLANTYPE_LIFE: 'LIFE',
  life: 'LIFE',
  LIFE_INSURANCE: 'LIFE',

  VISION: 'VISION',
  PLANTYPE_VISION: 'VISION',
  vision: 'VISION',
  VISION_INSURANCE: 'VISION',

  DISABILITY: 'DISABILITY',
  PLANTYPE_DISABILITY: 'DISABILITY',
  disability: 'DISABILITY',
  DISABILITY_INSURANCE: 'DISABILITY',

  STUDENT_LOAN: 'STUDENT_LOAN',
  PLANTYPE_STUDENT_LOAN: 'STUDENT_LOAN',
  studentLoan: 'PLANTYPE_STUDENT_LOAN',

  '529_PLAN': '529_PLAN',
  PLANTYPE_529_PLAN: '529_PLAN',
  COLLEGE_SAVINGS_529: '529_PLAN',

  CUSTOM: 'CUSTOM_SAVINGS',
  CUSTOM_SAVINGS: 'CUSTOM_SAVINGS',
};

/**
 * @deprecated
 */

export const benefit = (type) => {
  return BENEFITS[type];
};

const benefitTypes = {
  BENEFITS,

  benefit,
};

export default benefitTypes;
