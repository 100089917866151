import { useState, useCallback } from 'react';

/**
 * useToggle handles the open/closed state of anything
 */
export function useToggle(initial = false) {
  const [isOpen, setIsOpen] = useState(initial);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return {
    isOpen,
    open,
    close,
    toggle,
  };
}
