import { gql } from '../generated';

export const UploadHealthIssueDocument = gql(`
  mutation UploadHealthIssueDocument($input: [UploadHealthIssueDocumentInput!]!) {
    uploadHealthIssueDocument(input: $input) {
      dmi {
        id
        status
        lastReviewed
      }
      svi {
        id
        status
        lastReviewed
      }
    }
  }
`);
