import React from 'react';
import { PresentationMode } from '@types';
import { SheetName, ContentComponentProps, useSheet } from '@navigate';
import { Dialog, UIAccentColor } from '@uikit';

// sheet imports
import HelpTextSheet from './HelpTextSheet';
import LoaderSheet from './LoaderSheet';
import PlanSelectedSheet from './PlanSelectedSheet';
import CoverageDetailMenuSheet from './CoverageDetailMenuSheet';
import CoverageManagedOutsideSheet from './CoverageManagedOutsideSheet';
import HealthScreenedOutSheet from './HealthScreenedOutSheet';
import RefetchEligibilitySheet from './RefetchEligibilitySheet';
import CoverageMenuSheet from './CoverageMenuSheet';
import PaymentRedirectSheet from './PaymentRedirectSheet';
import UploadAvatarSheet from './UploadAvatarSheet';
import CancelPolicySheet from './CancelPolicySheet';
import AddToWalletSheet from './AddToWalletSheet';
import MissingHealthSSNSheet from './MissingHealthSSNSheet';
import MissingImmigrationFieldsSheet from './MissingImmigrationFieldsSheet';
import SilverPlanEligibleSheet from './SilverPlanEligibleSheet';
import AppliedAPTCSheet from './AppliedAPTCSheet';
import TransferOptionsSheet from './TransferOptionsSheet';
import VoterInfoSheet from './VoterInfoSheet';
import WithholdingEditSheet from './WithholdingEditSheet';
import TaxBreakdownGuideSheet from './TaxBreakdownGuideSheet';

interface SheetConfig {
  Component: React.FC<ContentComponentProps>;
  presentation?: PresentationMode; // size of sheet for web, otherwise defaults to actionSheet
  preventClose?: boolean;
  accentColor?: UIAccentColor;
}

const content: Record<SheetName, SheetConfig> = {
  COVERAGE_MENU: {
    Component: CoverageMenuSheet,
  },
  COVERAGE_DETAIL_MENU: {
    Component: CoverageDetailMenuSheet,
  },
  COVERAGE_MANAGED_OUTSIDE: {
    Component: CoverageManagedOutsideSheet,
  },
  HELP_TEXT: {
    Component: HelpTextSheet,
  },
  LOADER: {
    Component: LoaderSheet,
    preventClose: true,
  },
  NEXT_PLAN_SELECTION: {
    Component: PlanSelectedSheet,
    accentColor: 'coverage',
  },
  HEALTH_SCREENED_OUT: {
    Component: HealthScreenedOutSheet,
  },
  REFETCH_ELIGIBILITY: {
    Component: RefetchEligibilitySheet,
  },
  PAYMENT_REDIRECT: {
    Component: PaymentRedirectSheet,
    accentColor: 'coverage',
  },
  UPLOAD_AVATAR: {
    Component: UploadAvatarSheet,
  },
  CANCEL_POLICY: {
    Component: CancelPolicySheet,
    accentColor: 'coverage',
  },
  ADD_TO_WALLET: {
    Component: AddToWalletSheet,
    accentColor: 'coverage',
  },
  MISSING_HEALTH_SSN: {
    Component: MissingHealthSSNSheet,
    accentColor: 'coverage',
  },
  MISSING_IMMIGRATION_FIELDS: {
    Component: MissingImmigrationFieldsSheet,
    accentColor: 'coverage',
  },
  SILVER_PLAN_ELIGIBLE: {
    Component: SilverPlanEligibleSheet,
    accentColor: 'coverage',
    presentation: 'formSheet',
  },
  APPLIED_APTC: {
    Component: AppliedAPTCSheet,
    accentColor: 'coverage',
  },
  TRANSFER_OPTIONS: {
    Component: TransferOptionsSheet,
  },
  VOTER_INFO: {
    Component: VoterInfoSheet,
    preventClose: true,
    presentation: 'formSheet',
  },
  WITHHOLDING_EDIT: {
    Component: WithholdingEditSheet,
    presentation: 'formSheet',
  },
  TAX_BREAKDOWN_GUIDE: {
    Component: TaxBreakdownGuideSheet,
    presentation: 'formSheet',
  },
};

/**
 * Renders the current sheets
 */
const SheetsProvider: React.FC = () => {
  const { active: sheets, closeById } = useSheet();

  if (sheets.length === 0) {
    return null;
  }

  return (
    <>
      {sheets.map((sheet, index) => {
        const { Component, presentation, accentColor, preventClose } = content[sheet.name];

        if (!Component) {
          return null;
        }

        const handleClose = () => {
          closeById(sheet.id);
          setTimeout(() => {
            // wait until Dialog closes before setting focus back to Dialog trigger
            if (sheet.data.triggerRef?.current) {
              sheet.data.triggerRef.current.focus();
            }
          }, 0);
        };

        return (
          <Dialog
            key={index}
            isOpen={true}
            onRequestClose={handleClose}
            preventClose={preventClose}
            presentation={presentation || sheet?.data?.presentation || 'actionSheet'}
            accentColor={sheet?.data?.accentColor || accentColor}
          >
            <Component {...sheet.data} closeSheet={handleClose} />
          </Dialog>
        );
      })}
    </>
  );
};

export default SheetsProvider;
