import React, { useMemo } from 'react';
import { useQuery, HealthApplicationQuery, getMembers, useMutation, mutations } from '@data';
import {
  determineDeprivationNavigation,
  determineOtherCoverage,
  determineOutsideHousehold,
  hasAnyCitizens,
  isMarketplacePrelim,
} from '@navigate/EDENavigationGuard';
import { navigate } from '@navigate';
import { questions } from './dependentsCurrentCoverageFields';
import { getInitialValues, formatPayload } from './dependentsCurrentCoverageUtils';
import { MultiQuestionSplitFormBlueprint } from '@app/blueprints';
import { Route } from '@app/types';
import { Segment, SegmentEvent } from '@app/utils';

const DependentsCurrentCoverage = ({ applicationID }) => {
  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const { members, parsedQuestions } = useMemo(() => {
    return {
      members: getMembers(data),
      parsedQuestions: data?.viewerTwo?.health?.application?.uiQuestionsToDisplay,
    };
  }, [data?.viewerTwo?.health?.application]);

  const handleNext = () => {
    Segment.track(SegmentEvent.APPLICATION_CURRENT_COVERAGE_COMPLETED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    if (determineOutsideHousehold(members)) {
      return navigate(Route.EDE_OUTSIDE_HOUSEHOLD);
    } else if (determineDeprivationNavigation(parsedQuestions)) {
      return navigate(Route.EDE_MEMBER_WORK_HOURS);
    } else if (determineOtherCoverage(members)) {
      return navigate(Route.EDE_OTHER_COVERAGE);
    } else if (isMarketplacePrelim(members)) {
      return navigate(Route.EDE_SEP);
    } else if (hasAnyCitizens(members)) {
      return navigate(Route.EDE_VOTER_REGISTRATION);
    } else {
      return navigate(Route.EDE_REVIEW);
    }
  };

  return (
    <MultiQuestionSplitFormBlueprint
      loading={loading}
      submitting={upserting}
      questions={questions}
      members={members}
      data={{ uiQuestionsToDisplay: parsedQuestions }}
      getInitialValues={(val) => getInitialValues(val)}
      onNext={(values) => upsert(formatPayload({ values, applicationID }))}
      onComplete={handleNext}
    />
  );
};

export const DependentsCurrentCoverageView = {
  name: Route.EDE_DEPENDENTS_CURRENT_COVERAGE,
  component: DependentsCurrentCoverage,
  options: {
    title: 'Dependents coverage',
  },
};
