import { MetalLevel } from '@app/data';
import { uppercase } from '../format_old';

/**
 *
 * @todo move to @catch-co/health-utils
 */

const metalMapping = {
  BRONZE: MetalLevel.Bronze,
  EXPANDED_BRONZE: MetalLevel.Bronze,
  SILVER: MetalLevel.Silver,
  GOLD: MetalLevel.Gold,
  PLATINUM: MetalLevel.Platinum,
  CATASTROPHIC: MetalLevel.Catastrophic,

  // dental
  HIGH: MetalLevel.High,
  LOW: MetalLevel.Low,
};

/**
 * Given a health plan, formats the plan selection
 * This is the object that can be passed with health plan info
 */
export const formatPlanSelection = (healthPlan) => ({
  selectedProviderPlanID: healthPlan?.planID || healthPlan?.id,
  selectedPlanName: healthPlan?.name,
  selectedPlanIssuerID: healthPlan?.issuer?.id,
  selectedPlanID: healthPlan?.planID || healthPlan?.id,
  selectedMetalLevel: metalMapping[uppercase(healthPlan?.metalLevel)],
  planPremium: healthPlan?.premium || healthPlan?.originalPremium,
  planPremiumEffective: healthPlan?.premiumWithCredit || healthPlan?.unroundedPremium,
  ehbPremium: healthPlan?.ehbPremium,
  pediatricEHBPremium: healthPlan?.pediatricEHBPremium,
});
