import gql from 'graphql-tag';
import {
  Trinary,
  HealthSignatureType,
  HealthApplicationErrorCode,
  DMITypes,
  ResolutionStatus,
  EDEValidationErrorCode,
} from '@types';

interface GeneratedPhysicalDocumentData {
  id: string;
  name: string;
  dsrsIdentifier: string;
}

interface DMIData {
  id: string;
  status: DMITypes;
  resolveBy: Date;
  resolutionStatus: ResolutionStatus;
  statusCodes: number[];
  lastReviewed: Date;
  dependentID: string;
}

interface EDEValidationError {
  code: EDEValidationErrorCode;
  applicationMemberID: string;
  path: string;
}

export interface SubmitHealthApplicationVars {
  applicationID: string;
  minimalEnsure?: boolean;
  input: {
    id: string;
    lastUsedRoute: string;
    isRenewalAgreement: Trinary;
    renewEligibilityYearQuantity: number;
    isMedicaidRequirementAgreement: Trinary;
    isAbsentParentAgreement: Trinary;
    isTerminateCoverageOtherMecFoundAgreement: Trinary;
    isChangeInformationAgreement: Trinary;
    isPenaltyOfPerjuryAgreement: Trinary;
    signatureText: string;
    signatureDate: Date;
    signatureType: HealthSignatureType;
  };
}

export interface SubmitHealthApplicationResponse {
  errors: EDEValidationError[];
  currApplication: {
    id: string;
    coverageState: string;
    lastUsedRoute: string;
    isRenewalAgreement: Trinary;
    renewEligibilityYearQuantity: number;
    isMedicaidRequirementAgreement: Trinary;
    isAbsentParentAgreement: Trinary;
    isTerminateCoverageOtherMecFoundAgreement: Trinary;
    isChangeInformationAgreement: Trinary;
    isPenaltyOfPerjuryAgreement: Trinary;
    signatureText: string;
    signatureDate: Date;
    signatureType: HealthSignatureType;
    ednDocument: GeneratedPhysicalDocumentData[];
    dmis: DMIData[];
  };
  error: {
    code: HealthApplicationErrorCode;
    field: string;
    message: string;
  };
}

export const SubmitHealthApplication = gql`
  mutation SubmitHealthApplication(
    $applicationID: ID!
    $input: SubmitHealthApplicationAgreements!
    $minimalEnsure: Boolean
  ) {
    submitHealthApplicationNew(id: $applicationID, input: $input, minimalEnsure: $minimalEnsure) {
      errors {
        code
        applicationMemberID
        path
      }
      currApplication {
        id
        coverageState
        lastUsedRoute
        isRenewalAgreement
        renewEligibilityYearQuantity
        isMedicaidRequirementAgreement
        isAbsentParentAgreement
        isTerminateCoverageOtherMecFoundAgreement
        isChangeInformationAgreement
        isPenaltyOfPerjuryAgreement
        isTaxCreditReconciled
        signatureText
        signatureDate
        signatureType
        ednDocument {
          id
          name
          dsrsIdentifier
        }
        dmis {
          id
          status
          resolveBy
          resolutionStatus
          statusCodes
          lastReviewed
          dependentID
        }
      }
      error {
        code
        field
        message
      }
    }
  }
`;
