import { create, SetState, GetState } from 'zustand';

type DebugOption = 'showLayout' | 'showTestIds' | 'hideNav';

type DebugStore = {
  showLayout: boolean;
  showTestIds: boolean;
  hideNav: boolean;
  toggle: (flag: DebugOption, mode?: boolean) => void;
};

export const useDebug = create<DebugStore>(
  (set: SetState<DebugStore>, get: GetState<DebugStore>) => ({
    showLayout: false,
    showTestIds: false,
    hideNav: false,
    toggle: (flag: DebugOption, mode?: boolean) => {
      set((state) => ({
        ...state,
        [flag]: !!mode ? mode : !state[flag],
      }));
    },
  }),
);
