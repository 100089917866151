// @ts-nocheck
import React, { useMemo } from 'react';
import {
  useQuery,
  useMutation,
  UpsertExplorerVars,
  UpsertExplorerResponse,
  mutations,
  updates,
  HealthExplorerQuery,
} from '@data';
import { pop, ScreenDefinition } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { Route } from '@types';

const definedOrZero = (amount?: number) => {
  return amount || amount === 0;
};

const ExplorerHousehold = ({ handleNext }) => {
  const { c } = useCopy('catch');

  const { data } = useQuery(HealthExplorerQuery);
  const explorer = useMemo(() => {
    return data?.viewerTwo.healthExplorerData;
  }, [data?.viewerTwo.healthExplorerData]);

  const [upsert, { loading: upserting }] = useMutation<UpsertExplorerResponse, UpsertExplorerVars>(
    mutations.UPSERT_EXPLORER,
    {
      update: updates.UPDATE_EXPLORER,
      onCompleted: () => {
        handleNext({
          income: explorer?.income,
          pathwayType: explorer?.pathwayType,
          explorerID: explorer?.id,
        });
      },
    },
  );

  const onSubmit = (values) => {
    const income = definedOrZero(values.income) ? values.income : null;
    upsert({ variables: { input: { income } } });

    Segment.track(SegmentEvent.HX_INCOME_COMPLETED, {
      income,
      skipped: income === null,
      coverage_year: data?.viewerTwo?.healthExplorerData?.coverageYear,
    });
  };

  return (
    <BasicFormBlueprint
      optional
      loading={!data}
      submitting={upserting}
      title={c('healthExplorer.explorerTaxHousehold.title')}
      subtitle={c('healthExplorer.explorerTaxHousehold.subtitle')}
      onBack={pop}
      dynamicButton={(form) => {
        const value = form.methods.watch('income');
        return definedOrZero(value) ? 'Next' : 'Skip';
      }}
      formConfig={{
        initialValues: { income: definedOrZero(explorer?.income) ? explorer?.income : undefined },
        fields: [
          {
            type: 'amount',
            name: 'income',
            amountType: 'integer',
            large: true,
            subtext: c('household-income-subtext'),
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const ExplorerHouseholdView: ScreenDefinition = {
  name: Route.EXPLORER_HOUSEHOLD,
  component: ExplorerHousehold,
  options: {},
};
