import React from 'react';
import { Text, Pressable, useTheme } from '@uikit';
import { formatPlanSelection, useCopy } from '@app/utils';
import { useEnrollmentGroups, useModal } from '@hooks';
import { EnrollmentGroupMemberModal } from '@common';
import { HealthPlansBlueprint } from '@app/blueprints';
import { MembersAvatars } from '@app/partial';
import { Stack } from '@layouts';
import { Route } from '@types';

const HealthPlanSelection = ({ applicationID }) => {
  const { c } = useCopy('catch.health');
  const { theme } = useTheme();
  const { isOpen, openModal, closeModal } = useModal();

  const {
    loading,
    updatingMembers,
    selectingPlan,
    deleting,
    enrollmentGroups,
    currentGroup,
    currentMembers,
    possibleShoppingMembers,
    orphanedMembers,
    hasOtherPlans,
    changeGroup,
    deleteGroup,
    updateMembers,
    selectPlan,
    previouslySelectedID,
  } = useEnrollmentGroups({ applicationID });

  return (
    <HealthPlansBlueprint
      loading={loading}
      selecting={updatingMembers || selectingPlan}
      enrollmentType={currentGroup?.enrollmentType}
      title={c('planSelection.title')}
      subtitle={c('planSelection.subtitle')}
      initialPlanID={currentGroup?.selectedProviderPlanID || previouslySelectedID}
      previouslySelectedID={previouslySelectedID}
      enrollmentGroupID={currentGroup?.id}
      planSearch={currentGroup?.sortedPlans}
      precontent={
        <>
          <Stack horizontal style={[theme.spaceBetween, theme.bottomGutter3]}>
            <Text weight="medium">{c('planSelection.applying')}</Text>
            <Pressable
              testID="current-enrollment-group"
              handleOnPress={openModal}
              hoveredStyle={theme.cardShadowHover}
              corners="round"
              style={[
                theme.cardShadow,
                theme.xlargeCorners,
                theme.cardBg,
                theme.xSpace1,
                theme.ySpace1,
              ]}
            >
              <MembersAvatars members={currentMembers} />
            </Pressable>
          </Stack>
          <EnrollmentGroupMemberModal
            groupID={currentGroup?.id}
            isOpen={isOpen}
            closeModal={closeModal}
            deleting={deleting}
            deleteGroup={deleteGroup}
            changeGroup={changeGroup}
            hasOtherPlans={hasOtherPlans}
            updateMembers={updateMembers}
            enrollmentGroups={enrollmentGroups}
            currentMembers={currentMembers}
            possibleShoppingMembers={possibleShoppingMembers}
            orphanedMembers={orphanedMembers}
          />
        </>
      }
      onSubmit={async (plan, onCompleted) => {
        selectPlan(formatPlanSelection(plan.healthPlan), {
          onCompleted,
        });
      }}
    />
  );
};

export const HealthPlanSelectionView = {
  name: Route.EDE_PLANS,
  component: HealthPlanSelection,
  options: {
    ...HealthPlansBlueprint.options,
    title: 'Plan selection',
  },
};
