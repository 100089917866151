import React, { useMemo } from 'react';
import {
  HealthPathwayType,
  queries,
  updates,
  UPSERT_EXPLORER,
  UpsertHealthApplication,
  useMutation,
  useQuery,
} from '@data';
import { navigate } from '@navigate';
import { PrivacyAgreementsQueryData } from '@app/data/queries/PrivacyAgreementsQuery';
import { hasAgreedToPrivacy, useCopy } from '@app/utils';
import { HealthZipTriage } from '@partial';
import { Route } from '@types';
import { useAetna } from '@app/hooks';
import { AETNA_UNAVAILABLE_STATES } from '@app/constants/aetna';

const CoverageInfo = () => {
  const { data } = useQuery<PrivacyAgreementsQueryData>(queries.PRIVACY_AGREEMENTS);
  const { inAetnaWhiteLabelFlow, unsetWhiteLabel } = useAetna();
  const { c } = useCopy('catch');

  const hasAgreed = useMemo(() => {
    return hasAgreedToPrivacy(data?.viewerTwo?.agreements);
  }, [data?.viewerTwo.agreements]);

  const [upsertApplication, { loading: creating }] = useMutation(UpsertHealthApplication, {
    onCompleted: (data) => {
      navigate(hasAgreed ? Route.EDE_HOUSEHOLD : Route.EDE_PRIVACY, {
        applicationID: data?.upsertHealthApplication?.id,
      });
    },
  });

  const [upsertExplorer, { loading: upserting }] = useMutation(UPSERT_EXPLORER, {
    update: updates.UPDATE_EXPLORER,
    onCompleted: (data) => {
      const xplr = data.upsertHealthExplorerData;

      if (xplr.pathway === HealthPathwayType.Sbm) {
        navigate(Route.EXPLORER_STATE_HANDOFF);
      }
    },
  });

  const onSubmit = async ({ coverageYear, location }) => {
    // update the explorer regardless of pathway
    upsertExplorer({
      variables: {
        input: {
          coverageYear,
          zipcode: location.zip,
          countyfips: location.fips,
          state: location.state,
          pathwayType: location.pathway,
        },
      },
    });

    if (location.pathway === 'EDE' || location.state === 'CA') {
      upsertApplication({
        variables: {
          input: {
            coverageYearNumber: coverageYear,
            coverageState: location.state,
            zip: location.zip,
            countyfips: location.fips,
            isStartedApplication: true,
          },
        },
      });
    }

    //if in aetna white label flow and in unavailable state, unset white label
    if (inAetnaWhiteLabelFlow && AETNA_UNAVAILABLE_STATES[location.state]) {
      unsetWhiteLabel();
    }
  };

  return (
    <HealthZipTriage
      loading={!data}
      submitting={creating || upserting}
      title={c('healthBasics.title')}
      oeDates={data?.reference?.health?.openEnrollmentDates}
      onSubmit={onSubmit}
    />
  );
};

export const CoverageInfoView = {
  name: 'EDE_COVERAGE_INFO',
  component: CoverageInfo,
  options: {
    title: 'Location',
  },
};
