import React, { useMemo } from 'react';
import { DisplayComponent } from '@types';
import ApplicationRow, { Application } from './ApplicationRow';

interface ApplicationsListProps {
  applications?: Array<Application>;
  interactive?: boolean;
}

export const filterApplications = ({ applications }: ApplicationsListProps) => {
  return applications
    ? applications.filter((app) => {
        return [
          'IN_PROGRESS',
          'SUBMITTED',
          'DRAFT',
          'PENDING_ENROLLMENT',
          'ENROLLMENT_STUCK',
        ].includes(app.status);
      })
    : [];
};

// only display if applications exist
export const shouldDisplay = ({ applications }: ApplicationsListProps) => {
  return !!(applications && filterApplications({ applications }).length > 0);
};

export const sortApplications = ({ applications }: ApplicationsListProps) => {
  return applications
    ? [...applications]
        .sort((a, b) => (a.progress > b.progress ? -1 : 1))
        .sort((a, b) => (a.coverageYearNumber > b.coverageYearNumber ? -1 : 1))
    : [];
};

const ApplicationsList: DisplayComponent<ApplicationsListProps> = ({
  applications = [],
  interactive = true,
}) => {
  const show = useMemo(() => shouldDisplay({ applications }), [applications]);
  const sortedApplications = useMemo(() => sortApplications({ applications }), [applications]);

  if (!show) return null;

  return (
    <>
      {sortedApplications.map((app, idx) => (
        <ApplicationRow key={app.id} app={app} interactive={!!interactive} />
      ))}
    </>
  );
};

ApplicationsList.shouldDisplay = shouldDisplay;
export default ApplicationsList;
export type { Application } from './ApplicationRow';
