import { store } from '../store/store';

/**
 * Handles starting the app via navigation
 * This should handle any navigation handlers as well
 */
export const init = ({ screens, stacks }) => {
  let optionsByScreen = {};
  let optionsByStack = {};

  screens.forEach((screen) => {
    optionsByScreen[screen.name] = screen.options || {};
  });

  stacks.forEach((stack) => {
    optionsByStack[stack.config.stackName] = stack.config.options || {};
  });

  store.setState({
    options: optionsByScreen, // for now, in case we still refer to it anywhere
    optionsByScreen,
    optionsByStack,
  });
};
