import LR from 'logrocket';
import { env } from '@app/global';
import { Segment, SegmentEvent } from '@app/utils';
import { AnalyticsClient } from './types';

export const LogRocket: AnalyticsClient = {
  init: () => {
    LR.init(env.LOGROCKET_APP_ID, {
      rootHostname: 'catch.co',
      network: {
        requestSanitizer: (request) => {
          if (request?.body?.variables?.user?.ssn) {
            request.body.variables.user.ssn = '';
          }

          return request;
        },
      },
    });

    LR.getSessionURL(function (sessionURL) {
      Segment.track(SegmentEvent.LOGROCKET, { sessionURL });
    });
  },
  identify: (userId, { givenName, familyName, email, phoneNumber }) => {
    LR.identify(userId, {
      name: `${givenName} ${familyName}`,
      email: email || '',
      phoneNumber: phoneNumber || '',
      phone: phoneNumber || '',
    });
  },
  page: () => {},
};
