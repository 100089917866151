import gql from 'graphql-tag';
import {
  AgreementSet,
  IDProofFinalCode,
  IdentityProvider,
  IdentityStatus,
  ExistingApplicationsResponse,
  IDProofQuiz,
  SignupContext,
} from '@types';
import { ApolloOpenEnrollmentDates, OpenEnrollmentFragment } from '../fragments';

export interface HealthIdentityResponse {
  id: string;
  providerType: IdentityProvider;
  transactionID: string;
  sessionID: string;
  confirmationID: string;
  isTransactionIDExpired: boolean;
  finalDecisionCode: IDProofFinalCode;
  status: IdentityStatus;
  quizAttempts: number;
  identityAttempts: number;
  retryAfter?: Date; // date
  questions?: IDProofQuiz;
}

export interface HealthLinkQueryData {
  viewerTwo: {
    id: string;
    agreements: AgreementSet;
    identity: HealthIdentityResponse;
    existingApplicationInfo: ExistingApplicationsResponse;
    health: {
      healthExplorerData: {
        id: string;
        income: number;
      };
    };
  };
  viewer: {
    id: string;
    user: {
      id: string;
      dob: Date;
      ssn: string;
      signupContext: SignupContext;
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const HealthLinkQuery = gql`
  query HealthLinkQuery {
    viewer {
      id
      user {
        id
        givenName
        familyName
        dob
        ssn
        email
        phoneNumber
        legalAddress {
          street1
        }
        signupContext
      }
    }
    viewerTwo {
      id
      health {
        healthExplorerData {
          id
          income
        }
      }
      existingApplicationInfo {
        hasActivePolicy
        hasPreviousActivePolicy
        hasSubmittedApplication
        existingApplications {
          id
          familyName
          givenName
          coverageYearNumber
          state
          applicationIdentifier
          applicationVersion
          providerLastUpdatedDate
          applicationStatus
          hasExistingPersonTrackingNumbersTiedToApplication
        }
      }
      agreements {
        id
        isPrivacyDataUseAgree
        isPrivacyTruthfulAgree
        isPrivacyAuthorizeAgree
      }
      identity(providerType: CMS) {
        id
        providerType
        transactionID
        sessionID
        confirmationID
        isTransactionIDExpired
        finalDecisionCode
        status
        quizAttempts
        identityAttempts
        retryAfter
        questions {
          key
          text
          choices {
            key
            text
          }
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
`;
