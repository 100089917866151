import gql from 'graphql-tag';

const ENSURE_HEALTH_APPLICATION = gql`
  mutation _EnsureHealthApplication($applicationID: ID!, $fromSOEP: Boolean) {
    ensureHealthApplication(id: $applicationID, fromSOEP: $fromSOEP) {
      currApplication {
        id
        uiQuestionsToDisplay
        incomeExplanationRequiredIndicator
        stateDeprivationRequirementRetained
        allMembers {
          id
          dob
          age
          sex
          ssn
          isCitizen
          isPregnant
          relation
          hasAbsentParent
          isRequestingCoverage
          uiQuestionsToDisplay
          isLawfulPresenceStatus
          annualTaxIncome {
            annualIncomeExplanationRequired
            isVariableIncome
            isUnknownIncome
            attestedAnnualizedAPTCIndividualIncomeAmount
            incomeAmount
          }
          incomeSources {
            jobIncomeExplanationRequired
            type
          }
          preliminaryQHPStatus
          preliminaryAPTCStatus
          preliminaryCHIPStatus
          preliminaryMedicaidStatus
          preliminaryEmergencyMedicaidStatus
        }
      }
      responseResultType
      errors {
        code
        applicationMemberID
        path
      }
      error {
        code
        field
        message
      }
    }
  }
`;

const formatter = (data) => {
  const application = data?.ensureHealthApplication?.currApplication;
  const uiQuestionsToDisplay = application?.uiQuestionsToDisplay;

  const members = application?.allMembers;
  const isIncomeDifferent =
    application?.incomeExplanationRequiredIndicator ||
    members.some(
      (d) =>
        d?.annualTaxIncome?.annualIncomeExplanationRequired ||
        (d.incomeSources && d.incomeSources.some((s) => s?.jobIncomeExplanationRequired)),
    );
  return {
    uiQuestionsToDisplay,
    isIncomeDifferent,
    stateDeprivationRequirementRetained: application?.stateDeprivationRequirementRetained,
    members,
  };
};

/*
const updateCache = (cache, { data }, { enrollId }) => {
  const members = data?.ensureHealthApplication?.currApplication?.allMembers;
  try {
    if (!!enrollId) {
      const applicationCache = cache.readQuery({
        query: application.document,
        variables: { enrollId },
      });

      cache.writeQuery({
        query: application.document,
        data: {
          viewer: {
            ...applicationCache?.viewer,
            health: {
              ...applicationCache?.viewer?.health,
              enrollment: {
                ...applicationCache?.viewer?.health?.enrollment,
                application: {
                  ...applicationCache?.viewer?.health?.enrollment?.application,
                  uiQuestionsToDisplay:
                    data?.ensureHealthApplication?.currApplication?.uiQuestionsToDisplay,
                },
                members: {
                  ...applicationCache?.viewer?.health?.enrollment?.members,
                  members,
                },
              },
            },
          },
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
};
*/

export default {
  document: ENSURE_HEALTH_APPLICATION,
  //updateCache,
  formatter,
  getErrors: (data) => {
    if (data?.ensureHealthApplication?.responseResultType !== 'ERROR') {
      return null;
    }

    let errors = data?.ensureHealthApplication?.errors
      ? [...data?.ensureHealthApplication?.errors]
      : [];

    if (data?.ensureHealthApplication?.error) {
      errors.push(data?.ensureHealthApplication?.error);
    }

    return errors?.length > 0 ? errors : null;
  },
};
