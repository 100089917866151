import React from 'react';

import { IconSvg, Pressable } from '@uikit';

interface NavButtonProps {
  onPress: () => void;
  icon: React.ReactNode;
  iconComponent?: React.ReactNode;
  isActive: boolean;
  color?: string;
  ariaLabel?: string;
}

const NavButton: React.FC<NavButtonProps> = ({
  onPress,
  icon,
  iconComponent,
  isActive,
  color = 'text',
  ariaLabel,
}) => {
  return (
    <Pressable handleOnPress={onPress} ariaLabel={ariaLabel}>
      {({ intrx }) =>
        iconComponent || (
          <IconSvg
            svgComponent={icon}
            color={isActive ? 'page' : color}
            size="md"
            bg={
              isActive
                ? color
                : intrx.pressed
                ? 'border'
                : intrx.hovered
                ? 'skeleton'
                : 'transparent'
            }
          />
        )
      }
    </Pressable>
  );
};

export default NavButton;
