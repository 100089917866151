import React, { useContext, useMemo } from 'react';
import { ApolloScoredHealthPlan, queries, useQuery } from '@app/data';
import { View } from 'react-native';
import { SimpleRow, Text, ComplexRow, LineItem, Link } from '@uikit';
import { Layout, Card, Stack } from '@layouts';
import { getCarrierAsset, useCopy } from '@app/utils';
import { Copy } from '@app/types';
import HealthPlanCosts from '@app/partial/coverage/HealthPlanCosts';
import CoverageBadges from '@app/partial/coverage/CoverageBadges';

interface HealthPlanBrochureProps {
  plan: ApolloScoredHealthPlan;
  enrollmentType?: 'HEALTH_INSURANCE' | 'DENTAL_INSURANCE';
}

const bg = 'skeleton';

const PriceSummary = ({
  items,
}: {
  items: Array<{ id: string; name: Copy; cost: number; hidden?: boolean }>;
}) => {
  const { $ } = useCopy();

  return (
    <Card bg={bg}>
      {items.map((item) => {
        if (item.hidden) return null;

        return (
          <LineItem
            key={item.id}
            left={
              <Text weight="medium" size="fp">
                {item.name}
              </Text>
            }
            right={<Text weight="medium">{$(item.cost, { whole: true })}</Text>}
          />
        );
      })}
    </Card>
  );
};

const HealthPlanBrochureContext = React.createContext({
  enrollmentGroupID: undefined,
  explorerID: undefined,
  context: '',
});

export const HealthPlanProvider = ({ enrollmentGroupID, explorerID, children }) => (
  <HealthPlanBrochureContext.Provider
    value={{
      enrollmentGroupID,
      explorerID,
      context: !!explorerID ? 'explorer' : !!enrollmentGroupID ? 'application' : '',
    }}
  >
    {children}
  </HealthPlanBrochureContext.Provider>
);

const HealthPlanBrochure: React.FC<HealthPlanBrochureProps> = ({ enrollmentType, plan }) => {
  const { $, c } = useCopy();

  const context = useContext(HealthPlanBrochureContext);

  // @todo this works for explorer, but how should we handle getting full benefits for application flow?
  const { data } = useQuery(queries.HEALTH_BENEFITS, {
    variables: {
      input: {
        planID: plan?.planID,
        enrollmentGroupID: context.enrollmentGroupID,
        explorerID: context.explorerID,
      },
    },
    // plan ID must be defined and one of the ids from explorer or eg
    skip: !plan?.planID || !(context.enrollmentGroupID || context.explorerID),
  });

  const labels = {
    individual: c('individual'),
    family: c('family'),
    deductible: c('deductible'),
    medical: c('medical'),
    drug: c('drug'),
    combined: c('combined'),
  };

  const getDeductibleLabel = (d) => {
    const size = d?.familyCost === 'Family' ? 'family' : 'individual';
    const type = /Combined/.test(d?.type)
      ? 'combined'
      : /Medical/.test(d?.type)
      ? 'medical'
      : 'drug';
    return `${labels[size]} ${labels.deductible} (${labels[type]})`;
  };

  const deductibleItems = useMemo(() => {
    return plan?.healthPlan?.deductibles.map((d) => ({
      id: d.type,
      name: getDeductibleLabel(d),
      cost: d.amount,
    }));
  }, [plan]);

  if (!plan) return null;

  return (
    <Layout.Scroll bottomSpace style={{ maxHeight: `calc(100vh - 32px)` }}>
      <Stack spacing="1b">
        <View>
          <SimpleRow
            asset={getCarrierAsset(plan.healthPlan.issuer.title, enrollmentType || 'HEALTH', 'lg')}
            accessory={
              <CoverageBadges
                type="PLAN_DETAILS"
                metalLevel={plan.healthPlan.metal}
                planType={plan.healthPlan.type}
                hsaEligible={plan.healthPlan.hsaEligible}
                rating={plan.healthPlan.qualityRating?.globalRating}
                isStandard={plan.healthPlan.isStandard}
              />
            }
          />
          <Text size="lg" weight="medium" group>
            {plan.healthPlan.title}
          </Text>
          <Text size="fp" group>
            {plan.healthPlan.issuer.title}
          </Text>
        </View>

        {!!plan.healthPlan.benefitsURL && (
          <Link
            testID="plan-info"
            href={plan.healthPlan.benefitsURL}
            download={`${plan.healthPlan.name}.pdf`}
          >
            {c('plan-info')}
          </Link>
        )}

        <Stack spacing="3">
          <Stack spacing="0b">
            <Card bg={bg} padded={false}>
              <ComplexRow
                inset
                testID="premium"
                label={c('monthly-premium')}
                accessory={
                  <>
                    {plan.healthPlan.premium > plan.healthPlan.premiumWithCredit && (
                      <Text strike color="subtle" size="fp" align="right">
                        {$(plan.healthPlan.premium)}
                      </Text>
                    )}
                    <Text weight="medium" align="right">
                      {$(plan.healthPlan.premiumWithCredit)}
                    </Text>
                  </>
                }
              />
            </Card>

            <PriceSummary items={deductibleItems} />

            <PriceSummary
              items={[
                {
                  id: 'individual-max',
                  name: c('health.benefitLabels.outOfPocket.individual'),
                  cost: plan.healthPlan.individualMax,
                  hidden: plan.healthPlan.individualMax === null,
                },
                {
                  id: 'family-max',
                  name: c('health.benefitLabels.outOfPocket.family'),
                  cost: plan.healthPlan.familyMax || 0,
                  // go off of deductibles, since moops are sometimes defined anyway
                  hidden: plan.healthPlan.familyMax === null,
                },
              ]}
            />
          </Stack>
          <HealthPlanCosts
            benefits={data?.viewerTwo?.healthPlanDetails?.benefits || plan?.healthPlan?.benefits}
            ratings={
              data?.viewerTwo?.healthPlanDetails?.qualityRating || plan?.healthPlan?.qualityRating
            }
            isDental={enrollmentType === 'DENTAL_INSURANCE'}
          />
        </Stack>
      </Stack>
    </Layout.Scroll>
  );
};

export default HealthPlanBrochure;
