import { PlanType } from '@app/types';
import gql from 'graphql-tag';

type DocumentType = 'STATEMENT' | 'TAX_1040_ES' | 'TAX_1095_A' | 'TAX_1099_R' | 'TAX_5498';

type Type = 'IRA' | 'DDA' | 'TAX_1040_ES' | 'TAX_1095_A' | 'TAX_1099_R' | 'TAX_5498';

type Vertical = 'SAVINGS' | 'RETIREMENT' | 'HEALTH' | 'TAX';

export interface Document {
  id: string;
  date?: string;
  providerId?: string;
  documentType?: DocumentType;
  type?: Type;
  detail?: string;
  month?: string;
  vertical?: Vertical;
  planType?: PlanType;
  url?: string;
  ext?: string;
}

export interface DocumentsQueryData {
  viewerTwo: {
    id: string;
    documents: {
      statements?: Document;
      taxDocuments?: Document;
    };
  };
}

export interface DocumentQueryData {
  viewerTwo: {
    id: string;
    document: Document;
  };
}

export const DocumentsQuery = gql`
  query Documents {
    viewerTwo {
      id
      documents {
        statements {
          id
          date
          providerId
          documentType
          type
          detail
          month
          vertical
          planType
        }
        taxDocuments {
          id
          date
          providerId
          documentType
          type
          detail
          month
          vertical
          planType
        }
      }
    }
  }
`;

export const DocumentQuery = gql`
  query Document($id: ID!) {
    viewerTwo {
      id
      document(id: $id) {
        id
        ext
        url
      }
    }
  }
`;
