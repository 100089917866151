import React from 'react';

import { Accordion, EmptyState, Loading } from '@uikit';
import { Stack } from '@layouts';
import { Document } from '@data';
import DocumentRow from './DocumentRow';

import Doc from '@svg/file.svg';

interface DocumentGroupProps {
  month: string;
  documents: Array<Document>;
}

interface DocumentListProps {
  documentsGroup?: Array<DocumentGroupProps>;
  emptyHint?: any;
  loading?: boolean;
}

const DocumentList: React.FC<DocumentListProps> = ({ documentsGroup = [], emptyHint, loading }) => {
  return (
    <Stack separatorComponent spacing="1" topSpace>
      {documentsGroup?.length === 0 ? (
        loading ? (
          <Loading />
        ) : (
          <EmptyState title="Nothing yet" icon={Doc} subtitle={emptyHint} />
        )
      ) : (
        documentsGroup?.map((docGroup, idx) => (
          <Accordion key={docGroup.month} title={docGroup.month} initOpen={idx === 0}>
            <Stack separatorComponent>
              {docGroup.documents.map((d) => (
                <DocumentRow document={d} key={d.id} />
              ))}
            </Stack>
          </Accordion>
        ))
      )}
    </Stack>
  );
};

export default DocumentList;
