import React, { useMemo } from 'react';
import { Link } from '@uikit';
import { useCopy } from '@app/utils';
import { useQuery, HealthApplicationQuery, useMutation, mutations } from '@data';
import { navigate } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { optionPresets } from '@app/forms';
import { Route } from '@types';

const PREFIX = 'catch.ede';

const CICBasicsEntry = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { maritalStatus, numTaxDependents, coverageYear } = useMemo(() => {
    const app = data?.viewerTwo?.health?.application;

    return {
      maritalStatus: app?.members?.some((m) => m.relation === 'SPOUSE') ? 'MARRIED' : 'SINGLE',
      numTaxDependents: app?.members?.reduce(
        (count, m) => (m?.relation === 'CHILD' ? count + 1 : count),
        0,
      ),
      coverageYear: app?.coverageYearNumber,
    };
  }, [data]);

  const [report, { loading: reporting }] = useMutation(mutations.REPORT_HEALTH_ENROLLMENT_CHANGES, {
    onCompleted: (data) => {
      const { applicationID } = data?.reportHealthEnrollmentChanges?.insuranceEnrollment || {};
      navigate(Route.CIC_APP_REVIEW_INTRO, { applicationID });
    },
  });

  const onSubmit = (values) => {
    if (values.maritalStatus !== maritalStatus || values.numTaxDependents !== numTaxDependents) {
      report({
        variables: {
          input: {
            applicationInput: {
              id: applicationID,
              numTaxDependents: values.numTaxDependents,
              maritalStatus: values.maritalStatus,
            },
          },
        },
      });
    } else {
      navigate(Route.CIC_INCOME_ENTRY);
    }
  };

  return (
    <BasicFormBlueprint
      title={c('CIC.basicsEntry.title')}
      loading={!data}
      submitting={reporting}
      formConfig={{
        initialValues: { numTaxDependents, maritalStatus },
        fields: [
          {
            testID: 'S1',
            name: 'maritalStatus',
            required: true,
            type: 'option',
            options: optionPresets.MARITAL_STATUS,
            label: c('HealthEnrollment.S1_Q'),
            help: c('HealthEnrollment.S1_T'),
          },
          {
            testID: 'S2',
            name: 'numTaxDependents',
            required: true,
            type: 'stepper',
            stepperType: 'integer',
            headerText: c('HealthEnrollment.S2_Q', { coverageYear }),
            label: c('HealthEnrollment.S2_L'),
            help: c('HealthEnrollment.S2_T', {
              link1: <Link testID="s2-link1">{c('HealthEnrollment.S2_T_link1')}</Link>,
              link2: <Link testID="s2-link2">{c('HealthEnrollment.S2_T_link2')}</Link>,
              coverageYear,
              coverageYearNext: coverageYear ? coverageYear + 1 : '',
            }),
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const CICBasicsEntryView = {
  name: Route.CIC_BASICS,
  component: CICBasicsEntry,
  options: {},
};
