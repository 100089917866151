import React, { useMemo } from 'react';
import { useCopy } from '@app/utils';
import { getMembers, HealthApplicationQuery, useQuery } from '@data';
import { navigate } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { fields } from './memberRemovalFields';
import { Route } from '@types';

const PREFIX = 'catch.ede.CIC.memberDeath';

const CICMemberDeath = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(() => {
    return {
      members: getMembers(data)?.map((m) => ({
        id: m.id,
        name: m.legalName,
      })),
    };
  }, [data]);

  const onSubmit = ({ members }) => {
    navigate(Route.CIC_REMOVING_MEMBERS, {
      members: members?.filter((m) => m.removeMember),
    });
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={false}
      title={c('title')}
      formConfig={{
        initialValues,
        fields: fields(c('dateLabel')),
        onSubmit,
      }}
    />
  );
};

export const CICMemberDeathView = {
  name: Route.CIC_MEMBER_DEATH,
  component: CICMemberDeath,
  options: {},
};
