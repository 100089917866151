import { gql } from '../generated';

export const UpsertHealthApplication = gql(`
  mutation UpsertHealthApplication($input: UpsertHealthApplicationInput!) {
    upsertHealthApplication(input: $input) {
      id
      lastUsedRoute
      maritalStatus
      isSelfCoverage
      isSpouseCoverage
      numTaxDependents
      numDependentsRequestCoverage
      householdIncome
      aptcAgreement
      aptcSignature
      aptcHouseholdToApply
      voterRegistrationAnswerType
    }
  }
`);
