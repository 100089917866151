import React from 'react';
import { View } from 'react-native';

import { CatchTheme, Text, useTheme } from '@uikit';
import { ChartProps } from './types';
import { VictoryPie } from '../resolve';

interface DonutChartProps extends ChartProps {}

const LINE_WEIGHT = 3;
const REL_LINE_CONST = 0.1;
const lineWeight = (size) => Math.max(LINE_WEIGHT, REL_LINE_CONST * size);

/**
 *
 */
export const DonutChart = ({
  data,
  text,
  size = CatchTheme.assets.sm,
  loading,
  ...rest
}: DonutChartProps) => {
  const { themeColors } = useTheme();
  const _size = parseInt(size);
  return (
    <View
      style={{
        maxHeight: _size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
    >
      <VictoryPie
        cornerRadius={99}
        radius={_size / 2}
        innerRadius={_size / 2 - lineWeight(_size / 2)}
        labelRadius={0}
        labelComponent={<></>}
        height={_size}
        width={_size}
        data={data}
        padding={0}
        padAngle={1.5}
        animate={{
          duration: 1000,
        }}
        {...rest}
        style={{
          data: {
            fill: ({ datum }) => themeColors[datum.color + 'Color'],
          },
        }}
      />
      <View
        style={{
          position: 'absolute',
          height: _size,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
        }}
      >
        <Text
          loading={loading}
          size={_size / 3}
          align="center"
          weight="regular"
          style={{ lineHeight: _size / 3 }}
        >
          {text}
        </Text>
      </View>
    </View>
  );
};

export default DonutChart;
