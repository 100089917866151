import { gql } from '../generated';

export const UpsertConversionStatus = gql(`
  mutation UpsertConversionStatus($input: ConversionStatusInput!) {
    upsertConversionStatus(input: $input) {
      id
      status
    }
  }
`);
