import { useQuery, useMutation, GET_CURRENT_USER, UPDATE_USER, WhiteLabelOption } from '@data';

export const useAetna = () => {
  const { data } = useQuery(GET_CURRENT_USER);
  const whiteLabel = data?.me.user.whiteLabel;

  //mutation to unset white label
  const [unsetWhiteLabel] = useMutation(UPDATE_USER, {
    variables: { input: { whiteLabel: null } },
  });

  return {
    inAetnaWhiteLabelFlow: whiteLabel === WhiteLabelOption.Aetna,
    unsetWhiteLabel,
  };
};
