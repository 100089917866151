import React from 'react';
import { stacks, Stack } from '@navigate';
import { FintechWrapper } from '@app/components/wrappers';
import { MoneyView, GoalDetailView } from './routes';
import { TabStackComponent, TabConfig } from '../types';

const config: TabConfig = {
  stackName: stacks.MONEY_STACK,
  options: {
    drawBehindNav: true,
    largeTitle: true,
    accentColor: 'brand',
  },
  screens: [MoneyView, GoalDetailView],
  tabIcon: 'PLAN',
  title: 'Money',
};

const MoneyStack: TabStackComponent = () => {
  return (
    <FintechWrapper>
      <Stack stackName={config.stackName} screens={config.screens} options={config.options} />
    </FintechWrapper>
  );
};

MoneyStack.config = config;
export default MoneyStack;
