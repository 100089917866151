import React from 'react';
import { FormLabel, Input } from '@uikit';
import { InputProps } from '../types';
import { replaceAll } from '@app/utils';

interface SSNInputProps<T> extends InputProps<T> {}

// add spaces around -
export const formatSSN = (val) => {
  return replaceAll(val, '-', ' - ');
};

// stores the actual value as XXX-XX-XXXX
export const parseSSN = (val) => {
  // strip out first
  const digits = val.replace(/\D+/g, '');

  const first = digits.substring(0, 3);
  const middle = digits.substring(3, 5);
  const last = digits.substring(5, 9);

  if (digits.length < 3) {
    return first;
  }

  if (digits.length === 3) {
    const types = [first, `${first} `, `${first} -`, `${first} - `];
    return types.includes(val) ? first : `${first}-`;
  }

  if (digits.length < 5) {
    return `${first}-${middle}`;
  }

  if (digits.length === 5) {
    const types = [
      `${first} - ${middle}`,
      `${first} - ${middle} `,
      `${first} - ${middle} -`,
      `${first} - ${middle} - `,
    ];
    return types.includes(val) ? `${first}-${middle}` : `${first}-${middle}-`;
  }

  return `${first}-${middle}-${last}`;
};

const SSNInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value,
      label,
      sublabel,
      help,
      optional,
      required,
      disabled,
      onChange,
      onBlur,
      onFocus,
      onKeyPress,
      error,
    }: SSNInputProps<T>,
    ref,
  ) => {
    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
          required={required}
        />
        <Input
          ref={ref}
          testID={testID}
          disabled={disabled}
          autoFocus={autoFocus}
          keyboard="ssn"
          name={name}
          value={formatSSN(value)}
          onChange={(val) => onChange(parseSSN(val))}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder="000 - 00 - 0000"
          error={error}
          fullWidth
          sensitive
        />
      </>
    );
  },
);

SSNInput.displayName = 'SSNInput';
export default SSNInput;
