import React from 'react';

import { Text } from '@uikit';
import { FontSize, FontWeight } from '@types';
import { SectionTitle, SectionVariant } from './SectionTitle';
import Stack from './Stack';

interface SectionProps {
  loading?: boolean;
  title?: string;
  subtitle?: string;
  variant?: SectionVariant;
  children?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ loading, title, subtitle, variant, children }) => {
  return (
    <section>
      <Stack spacing="1">
        {!!title && (
          <Stack spacing="0">
            {!!title && (
              <SectionTitle loading={loading} variant={variant}>
                {title}
              </SectionTitle>
            )}
            {!!subtitle && (
              <Text
                loading={loading}
                weight={FontWeight.regular}
                size={FontSize.fp}
                color="secondary"
              >
                {subtitle}
              </Text>
            )}
          </Stack>
        )}
        <div>{children}</div>
      </Stack>
    </section>
  );
};

export default Section;
