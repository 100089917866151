import { gql } from '../generated';

gql(`
  fragment AuthUser on User {
    id
    email
    phoneNumber
    givenName
    familyName
    signupCode
    signupParams
    agreements {
      ...Agreements
    }
    createdOn
    whiteLabel
  }
`);

export const FIND_OR_CREATE_USER = gql(`
  mutation FindOrCreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...AuthUser
    }
  }
`);

export const GET_CURRENT_USER = gql(`
  query GetCurrentUser {
    me {
      id
      user {
        ...AuthUser
      }
    }
  }
`);

export const UPDATE_USER = gql(`
  mutation UpdateUserNew($input: UpdateUserInput!) {
    updateUserNew(input: $input) {
      user {
        ...AuthUser
      }
    }
  }
`);
