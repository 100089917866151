import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useNavigation } from '@navigate/utils/useNavigation';
import { Route } from '@app/navigate/paths';
import { UIAccentColor } from '@uikit/types';

export type Layout = 'page' | 'sheet';

interface LayoutContextProps {
  layout: Layout;
  needsScroll?: boolean;
  name?: Route;
  accentColor?: UIAccentColor;
  children: React.ReactNode;
}

const LayoutContext = React.createContext({
  layout: 'page',
  accentColor: 'brand',
  scrolled: false,
  needsScroll: false,
  handleScroll: undefined,
  toolbarHeight: 0,
  setToolbarHeight: (height: number) => {},
});

const downThreshold = 50;
const upThreshold = 5;

/**
 * Provides a layout provider to wrap around each screen section
 * This allows us to compute values based on the layout value
 * and know whether we're in a modal or a full page
 */
const LayoutProvider: React.FC<LayoutContextProps> = ({
  name,
  layout,
  needsScroll,
  accentColor = 'brand',
  children,
}) => {
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [toolbarHeight, setToolbarHeight] = useState<number>(0);
  const navigation = useNavigation();

  useEffect(() => {
    // we reset the scroll when a layout provider gets reinitialized
    // web always goes to top of page, so we want scrolled set to false
    if (navigation.setOptions) {
      navigation.setOptions({ [name]: { scrolled: false } });
    }
  }, []);

  const handleScroll = useMemo(() => {
    if (!needsScroll) return undefined;

    return ({ nativeEvent }) => {
      if (nativeEvent.contentOffset.y > downThreshold && !scrolled) {
        setScrolled(true);

        navigation.setOptions({ [name]: { scrolled: true } });
      }
      if (nativeEvent.contentOffset.y < upThreshold && !!scrolled) {
        setScrolled(false);

        navigation.setOptions({ [name]: { scrolled: false } });
      }
    };
  }, [scrolled]);

  return (
    <LayoutContext.Provider
      value={{
        layout,
        scrolled,
        handleScroll,
        needsScroll,
        toolbarHeight,
        setToolbarHeight,
        accentColor,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};

export default LayoutProvider;
