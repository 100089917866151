import { gql } from '../generated';

export const SignConsentRequest = gql(`
  mutation SignConsentRequest($input: SignConsentRequestInput!) {
    signConsentRequest(input: $input) {
      id
      status
    }
  }
`);
