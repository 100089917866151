import { Route } from '@app/types';

const RTS = Object.values(Route);

export const routeMatch = (search) => [...RTS]?.filter((r) => r.includes(search?.toUpperCase()));

export const updateBankLinks = (errorCode) => {
  // If the relinked bank link matches the primary account, we need to update that query
};

export const showBankLinkError = () => {
  // If the relinked bank link matches the primary account, we need to update that query
};
