import { precisionRound } from '../format_old/misc';
import { capitalize } from '../format_old/strings';
import { calculatePlanCosts } from './calculatePlanCosts';

/**
 *
 * @todo move to @catch-co/health-utils
 */

/**
 * Show messaging
 */
export const isOscar = (healthPlan) => {
  return /Oscar/.test(healthPlan?.issuer?.name);
};

/**
 * Shows eligibility for HSA based on deductible
 */
export const isHighDeductible = (healthPlan) => {
  return healthPlan?.hsaEligible;
};

/**
 * @TODO: implement this
 */
export const isExtraHighDeductible = (healthPlan) => {
  return false;
};

/**
 * @TODO: implement this
 */
export const isCSR = (healthPlan) => {
  return false;
};

/**
 * Direct to specialists means:
 * EPO or PPO
 */
export const isDirectToSpecialists = (healthPlan) => {
  return healthPlan?.type === 'PPO' || healthPlan?.type === 'EPO';
};

/**
 * @TODO: implement this
 */
export const isAdultDental = (healthPlan) => {
  return false;
};

/**
 * @TODO: implement this
 */
export const isAdultVision = (healthPlan) => {
  return false;
};

/**
 * Helper for formatting a health plan
 * @return {[type]} [description]
 */
export const formatHealthPlan = ({ healthPlan, forceIndividual }) => {
  // Calculates moops + deductibles
  const { outOfPocketFamily, outOfPocketIndividual, deductibleFamily, deductibleIndividual } =
    calculatePlanCosts({ healthPlan });

  return {
    id: healthPlan.planId,
    type: healthPlan.type,
    name: healthPlan.name,
    premium: healthPlan?.premiumWithCredit,
    unroundedPremium: precisionRound(healthPlan?.premiumWithCredit, 2),
    originalPremium: healthPlan.premium,
    savings: healthPlan.premium - healthPlan?.premiumWithCredit,
    metalLevel: capitalize(healthPlan.metalLevel),
    provider: healthPlan.issuer.name,
    issuerID: healthPlan.issuer.id,
    issuer: healthPlan.issuer,
    outOfPocketFamily: forceIndividual ? null : outOfPocketFamily,
    outOfPocketIndividual,
    deductibleFamily: forceIndividual ? null : deductibleFamily,
    deductibleIndividual,
    benefits: healthPlan.benefits,
    brochureURL: healthPlan.brochureURL,

    // computed for plan "comments"
    isOscar: isOscar(healthPlan),
    isHDHP: isHighDeductible(healthPlan),
    isExtraHDHP: isExtraHighDeductible(healthPlan),
    isCSR: isCSR(healthPlan),
    isDirectToSpecialists: isDirectToSpecialists(healthPlan),
    isAdultDental: isAdultDental(healthPlan),
    isAdultVision: isAdultVision(healthPlan),
  };
};

export const formatScoredHealthPlan = ({
  plan,
  eligibility,
  selectedID,
  originalID,
  forceIndividual,
}) => ({
  ...plan,
  healthPlan: formatHealthPlan({
    healthPlan: plan?.healthPlan,
    eligibility,
    forceIndividual,
  }),
  isOriginal: originalID === plan?.healthPlan?.planId,
  isSelected: selectedID === plan?.healthPlan?.planId,
});

/**
 * Helpers for formattting a set of plans
 * - Given the sorted plans response + eligibility
 * - Optionally, given the id of an originally selected plan
 * - Returns object with list of all plans, only filtered plans,
 *   and top plans including previously selected one
 * Note: all plans returned are formatted by as above
 */
export const formatSortedPlans = ({
  sortedPlans,
  eligibility,
  selectedID,
  originalID,
  pathwayType,
  partnerMembers,
}) => {
  // temp fix to force individual deductibles/moops
  const forceIndividual = pathwayType === 'OSCAR' && partnerMembers?.length <= 1;

  return sortedPlans?.plans?.reduce(
    (acc, plan) => {
      const p = formatScoredHealthPlan({
        plan,
        eligibility,
        originalID,
        selectedID,
        forceIndividual,
      });

      // When selected is found, stash it
      if (p.isSelected) {
        acc.selectedPlan = p;
      }

      // Appends to the list of all plans, no matter what
      const allPlans = [...acc.plans, p];

      // If it is any of the top plans, dont add anywhere else
      if (p.isCatchRecommended) {
        return {
          ...acc,
          plans: allPlans,
          topPlans: { ...acc.topPlans, catchRecommended: p },
        };
      }
      // Only set original plan if it is NOT already a top plan
      else if (p.isBudgetPick) {
        return {
          ...acc,
          plans: allPlans,
          topPlans: { ...acc.topPlans, budgetPlan: p },
        };
      }
      // Only set original plan if it is NOT already a top plan
      else if (p.isComprehensive) {
        return {
          ...acc,
          plans: allPlans,
          topPlans: { ...acc.topPlans, comprehensivePlan: p },
        };
      }
      // Only set original plan if it is NOT already another top plan
      else if (p.isOriginal) {
        return {
          ...acc,
          plans: allPlans,
          topPlans: { ...acc.topPlans, originalPlan: p },
        };
      }
      // Otherwise, its OK to add to the filtered plans
      else {
        return {
          ...acc,
          plans: allPlans,
          filteredPlans: [...acc.filteredPlans, p],
        };
      }
    },
    { plans: [], filteredPlans: [], topPlans: {}, selectedPlan: null },
  );
};

/**
 * Formats deductibles for easy display
 */
export function formatDeductibles(deductibles) {
  if (!deductibles) {
    return { individual: null, family: null };
  }

  let individual = {
    total: null,
    medical: null,
    drug: null,
    combined: null,
  };

  let family = {
    total: null,
    medical: null,
    drug: null,
    combined: null,
  };

  // sets fields for each deductible
  deductibles?.forEach((d) => {
    const obj = d.familyCost === 'Family' ? family : individual;
    const field = /Combined/.test(d.type)
      ? 'combined'
      : /Medical/.test(d.type)
      ? 'medical'
      : 'drug';
    obj[field] = d.amount;
  });

  if (family.combined === null && family.medical === null) {
    family = null;
  } else {
    family.total = family.combined !== null ? family.combined : family.medical + family.drug;
  }

  // either set object to null or compute totals
  const nullifyOrTotal = (obj) => {
    if (!obj || (obj.combined === null && obj.medical === null)) {
      return null;
    } else {
      obj.total = obj.combined !== null ? obj.combined : obj.medical + obj.drug;
      return obj;
    }
  };

  return {
    individual: nullifyOrTotal(individual),
    family: nullifyOrTotal(family),
  };
}
