import gql from 'graphql-tag';

const START_SESSION = gql`
  mutation StartSession($input: StartSessionInput!) {
    syncHealth
    startSession(input: $input) {
      loginHistory {
        loginTimeStamp
        deviceType
        version
      }
    }
  }
`;

export default {
  document: START_SESSION,
};
