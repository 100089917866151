export const TEST_DATA = [
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Bank',
    outcome: 'oauth',
    value: 'Platypus OAuth',
  },
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Bank',
    outcome: 'oauth (app)',
    value: 'Platypus OAuth App2App',
  },
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Bank',
    outcome: 'down',
    value: 'Unhealthy Platypus Bank - Down',
  },
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Bank',
    outcome: 'regular',
    value: 'Tartan',
  },
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Username',
    outcome: 'good',
    value: 'user_good',
  },
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Password',
    outcome: 'good',
    value: 'pass_good',
  },
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Password',
    outcome: 'good',
    value: 'microdeposits_good',
  },
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Routing',
    outcome: '',
    value: '021000021',
  },
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Routing',
    outcome: '',
    value: '011401533',
  },
  {
    provider: 'Plaid',
    workflow: 'Link',
    type: 'Account',
    outcome: '',
    value: 1111222233331111,
  },
  {
    provider: 'Unit',
    workflow: 'KYC',
    type: 'SSN',
    outcome: 'pending_review',
    value: '000000004',
  },
  {
    provider: 'Unit',
    workflow: 'KYC',
    type: 'SSN',
    outcome: 'awaiting_docs (address)',
    value: '000000002',
  },
  {
    provider: 'Unit',
    workflow: 'KYC',
    type: 'SSN',
    outcome: 'awaiting_docs (id)',
    value: '000000003',
  },
  {
    provider: 'Unit',
    workflow: 'KYC',
    type: 'SSN',
    outcome: 'awaiting_docs (ssn)',
    value: '000000006',
  },
  {
    provider: 'Unit',
    workflow: 'KYC',
    type: 'SSN',
    outcome: 'denied',
    value: '000000001',
  },
  {
    provider: 'Unit',
    workflow: 'Reference',
    type: 'Routing',
    outcome: 'sandbox',
    value: 812345678,
  },
  {
    provider: 'EDE',
    workflow: 'Link',
    type: 'First',
    outcome: 'has policy',
    value: 'Tammy',
  },
  {
    provider: 'EDE',
    workflow: 'Link',
    type: 'Last',
    outcome: 'has policy',
    value: 'James',
  },
  {
    provider: 'EDE',
    workflow: 'Link',
    type: 'State',
    outcome: 'has policy',
    value: 'AL',
  },
  {
    provider: 'EDE',
    workflow: 'Link',
    type: 'DOB',
    outcome: 'has policy',
    value: '1971-08-08',
  },
  {
    provider: 'EDE',
    workflow: 'Link',
    type: 'SSN',
    outcome: 'has policy',
    value: '317-20-1436',
  },
  {
    provider: 'Abound',
    workflow: 'Payment',
    type: 'Amount',
    outcome: 'pending',
    value: 111,
  },
  {
    provider: 'Abound',
    workflow: 'Payment',
    type: 'Amount',
    outcome: 'done',
    value: 222,
  },
  {
    provider: 'Abound',
    workflow: 'Payment',
    type: 'Amount',
    outcome: 'userError',
    value: 333,
  },
  {
    provider: 'Abound',
    workflow: 'Payment',
    type: 'Amount',
    outcome: 'paymentMethodError',
    value: 444,
  },
  {
    provider: 'Abound',
    workflow: 'Payment',
    type: 'Amount',
    outcome: 'timingError',
    value: 555,
  },

  {
    provider: 'Catch',
    workflow: 'Transfer',
    type: 'Amount',
    outcome: 'Fail Transfer',
    value: 154,
  },
  {
    provider: 'EDE',
    workflow: 'IDP',
    type: 'Middle',
    outcome: 'Skip ID Proof',
    value: 'passid',
  },
];

export const HEALTH_SSNS = {
  '1 Person Household': ['317-20-1400', '299-16-2693'],
  '2 Person Household': ['298-18-8493', '298-20-2792'],
  '3 Person Household': ['317-20-1462', '317-20-1463', '317-20-1464'],
  '4 Person Household': ['276-82-6192', '276-82-6694', '276-82-9792', '276-82-9894'],
  '5 Person Household': ['292-66-7590', '292-66-9354', '292-68-0052', '292-68-0492', '292-68-1494'],
};
