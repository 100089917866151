import React, { useEffect } from 'react';
import { Segment, SegmentEvent, openIntercom, useCopy } from '@app/utils';
import { exit } from '@navigate';
import { HiccupBlueprint } from '@app/blueprints';
import Clock from '@svg/clock.svg';
import Chat from '@svg/message-2.svg';
import { Route } from '@types';
import { HealthApplicationQuery, useQuery } from '@app/data';

/**
 * This page gets shown when we have a CMS error
 * When this happens, typically the issue needs to be addressed by an engineer
 */
//props = skipHandoff = true
const HealthError = ({ applicationID }) => {
  const { c } = useCopy('catch');

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  useEffect(() => {
    Segment.track(SegmentEvent.APPLICATION_ERROR_OCCURRED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });
  }, []);

  return (
    <HiccupBlueprint
      asset={{ svg: Clock, gradient: 'coverage' }}
      title={c('health.errors.title')}
      subtitles={[c('health.errors.subtitle')]}
      onPress={() => exit(Route.HOME)}
      actions={[
        {
          testID: 'support',
          label: c('basics.contactSupport'),
          inherit: true,
          alt: true,
          onAction: openIntercom,
          svg: Chat,
        },
      ]}
    />
  );
};

export const HealthErrorView = {
  name: Route.EDE_HELP,
  component: HealthError,
  options: {},
};
