import createIconComponent from '../../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const NewMexico = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      d: 'M38.5894 20.3828H216.732L215.794 208.985L109.871 208.746C109.871 208.746 109.871 211.615 109.423 213.049C108.974 214.484 109.423 215.201 110.766 215.918C112.109 216.635 112.557 217.831 112.557 217.831L63.7382 217.114L63.9625 234.798L38.5865 234.485V20.3828H38.5894Z',
      colorable: true,
      fill: '#1F2533',
    },
  ],
  name: 'NewMexico',
  width: '255',
  height: '255',
  viewBox: '0 0 255 255',
});
NewMexico.displayName = 'NewMexico';

/** @deprecated */
export default NewMexico;
