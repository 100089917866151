import React from 'react';
import { CatchTheme, ComplexRow } from '@uikit';
import { goToHealthApp, STATES } from '@app/utils';
import { date } from '@app/utils/format/date';
import { HealthApplicationStatus, HealthPathwayType } from '@app/types';
import { Donut } from '@app/charts';

export interface Application {
  id: string;
  status: HealthApplicationStatus;
  coverageState: string;
  coverageYearNumber: number;
  lastUserUpdate: Date;
  lastUsedRoute: string;
  isGeorgiaAccess: boolean;
  pathwayType: HealthPathwayType;
  progress: number;
}

interface ApplicationRowProps {
  app: Application;
  interactive?: boolean;
}

const ApplicationRow: React.FC<ApplicationRowProps> = ({ app, interactive = true }) => {
  return (
    <ComplexRow
      testID={app?.id}
      label={`${app?.coverageYearNumber} ${STATES[app?.coverageState]}`}
      sublabel={`Last updated ${date(app?.lastUserUpdate, 'RELATIVE')}`}
      asset={{
        size: 'md',
        element: <Donut percent={app.progress} color="coverage" size={CatchTheme.assets.md} />,
      }}
      onPress={interactive ? () => goToHealthApp(app) : undefined}
    />
  );
};

export default ApplicationRow;
