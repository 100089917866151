import React from 'react';
import { Proofing } from '@app/partial';
import { Route } from '@types';

/**
 * Health Link IDP
 * @see https://www.figma.com/file/xkXhK2lvfZyzMp6GUMFPuc/Onboarding?node-id=4083%3A50646
 */
const HealthLinkIdentity = ({ handleNext, canSearch }) => {
  return <Proofing canSearch={canSearch} handleSuccess={handleNext} />;
};

export const HealthLinkIdentityView = {
  name: Route.HEALTH_LINK_IDENTITY,
  component: HealthLinkIdentity,
  options: {},
};
