import React, { useMemo } from 'react';
import { FontSize, Prompt, PromptContext, PromptName } from '@app/types';
import { Link, useResponsive } from '@uikit';
import { Carousel, Layout, Section, Stack } from '@layouts';
import { navigate } from '@app/navigate';
import { CatchTheme } from '@app/_ui-kit/themes/catch';
import { PromptCard, HealthWidget } from '@common';
import { HomeQueryData } from '@data';
import { Route } from '@types';
import { Color } from '@app/styles';
import { IncomePromptCard } from '@app/components/income';

interface PromptProps {
  prompts?: Array<Prompt>;
  data?: HomeQueryData;
}

const Outer = ({ stacked, children, isMobile }) =>
  stacked ? (
    <Layout mobileSnap={isMobile}>
      <Stack spacing="+gap">{children}</Stack>
    </Layout>
  ) : (
    <Carousel itemWidth={CatchTheme.constants.promptWidthSm} spacing="1" fullWidth>
      {children}
    </Carousel>
  );

const PromptSection = ({
  title,
  prompts,
  stacked,
  style,
}: {
  title?: string;
  prompts: Array<Prompt>;
  stacked?: boolean;
  style?: any;
}) => {
  const { isMobile } = useResponsive();

  return (
    <Section color="incomeLight" title={title || ''} style={{ width: '100%', flex: 1, ...style }}>
      <Outer stacked={stacked} isMobile={isMobile}>
        {prompts?.map((cardType, idx) => {
          if (cardType.name === PromptName.INCOME) {
            const income = cardType?.data?.income;
            return <IncomePromptCard key={income?.id} index={idx} income={income} />;
          }

          return (
            <PromptCard
              size={stacked ? 'ALERT' : 'CARD'}
              key={cardType.name + cardType?.forEach ? idx : undefined}
              wideMobile={prompts?.length === 1 && !stacked}
              {...cardType}
            />
          );
        })}
      </Outer>
    </Section>
  );
};

const HomePrompts = ({ prompts, data }: PromptProps) => {
  const grouped: { [context: PromptContext]: Array<Prompt> } = useMemo(() => {
    if (!prompts) return {};

    return prompts?.reduce((groups, prompt) => {
      const existing = groups[prompt.context] || [];

      return {
        ...groups,
        [prompt.context]: [...existing, prompt],
      };
    }, {});
  }, [prompts]);

  return (
    <Layout bottomSpace>
      <Stack spacing="1">
        {grouped.BLOCKING_CLOCK && (
          <PromptSection style={{ paddingTop: 0 }} prompts={[...(grouped.BLOCKING_CLOCK || [])]} />
        )}
        {(grouped.ACCOUNT || grouped.BLOCKING) && (
          <PromptSection
            style={{ paddingTop: 0 }}
            stacked
            prompts={[...(grouped.ACCOUNT || []), ...(grouped.BLOCKING || [])]}
          />
        )}

        {grouped.INCOME && <PromptSection title="Review your income" prompts={grouped.INCOME} />}
        {grouped.HEALTH && <PromptSection title="Health coverage" prompts={grouped.HEALTH} />}
        {grouped.STATUS && <PromptSection stacked prompts={grouped.STATUS} />}

        <Section>
          <Stack spacing="1">
            {HealthWidget.shouldDisplay(data) && <HealthWidget data={data} />}
          </Stack>
        </Section>

        {grouped.TODO && <PromptSection title="Finish Setup" prompts={grouped.TODO} />}
        <Section>
          <Stack horizontal spacing="2">
            <Link
              testID="statements"
              size={FontSize.sm}
              color={Color.subtle}
              onPress={() => navigate(Route.STATEMENTS)}
            >
              Statements
            </Link>
          </Stack>
        </Section>
      </Stack>
    </Layout>
  );
};

export default HomePrompts;
