import React, { useState } from 'react';
import { useCopy, isWithinLastTwoWeeksOfYear } from '@app/utils';
import { Text } from '@uikit';
import { BasicFormBlueprint } from '@app/blueprints';
import { ApolloOpenEnrollmentDates } from '@app/data/fragments';
import { Copy, HealthPathwayType } from '@app/types';
import ShieldWarning from '@svg/warning-triangle.svg';
import Check from '@svg/check.svg';
import { pop } from '@app/navigate';

interface HealthZipTriageProps {
  loading: boolean;
  submitting: boolean;
  title: Copy;
  subtitle?: Copy;
  oeDates?: ApolloOpenEnrollmentDates;
  onSubmit: (values: {
    coverageYear: number;
    location: {
      zip: string;
      county: string;
      fips: string;
      state: string;
      pathway: HealthPathwayType;
    };
  }) => void;
}

const HealthZipTriage: React.FC<HealthZipTriageProps> = ({
  loading,
  submitting,
  title,
  subtitle,
  initialLocation,
  oeDates,
  onSubmit,
}) => {
  const { c } = useCopy('catch');

  const possibleYears = oeDates?.coverageYears || [];
  const defaultCoverageYear = possibleYears[possibleYears.length - 1];
  const { oeYear, sepYear } = oeDates || {};

  const [selectedCoverageYear, setSelectedCoverageYear] = useState(defaultCoverageYear);
  const shouldDisableSubmitDueToEOY =
    selectedCoverageYear === sepYear && isWithinLastTwoWeeksOfYear(); // disable Next button for last two weeks of current year

  const requiredFieldNote = (
    <Text color="subtle" size="fp" gutter="bottom">
      {c('forms.requiredFieldsNote')}
    </Text>
  );

  return (
    <BasicFormBlueprint
      loading={loading || !oeDates}
      submitting={submitting}
      title={title}
      subtitle={subtitle}
      precontent={requiredFieldNote}
      onBack={pop}
      isInvalid={shouldDisableSubmitDueToEOY}
      formConfig={{
        initialValues: {
          sepYear,
          oeYear,
          location: initialLocation || {},
          coverageYear: defaultCoverageYear,
        },
        fields: [
          {
            name: 'location',
            type: 'zip',
            label: c('basics.zipcode'),
            large: true,
            required: true,
          },
          {
            name: 'coverageYear',
            type: 'option',
            label: c('basics.coverageYear'),
            dependencies: ['coverageYear', 'sepYear', 'oeYear'],
            options: possibleYears.map((year) => ({
              label: year,
              value: year,
            })),
            required: true,
            horizontal: true,
            fieldAddition: ({ coverageYear, sepYear, oeYear }) => {
              setSelectedCoverageYear(coverageYear);
              return {
                component: 'BANNER',
                props:
                  coverageYear === sepYear
                    ? isWithinLastTwoWeeksOfYear()
                      ? {
                          title: c('healthBasics.QLEflag.EoY.title', { coverageYear }),
                          subtitle: c('healthBasics.QLEflag.EoY.subtitle', { coverageYear }),
                          bg: 'negativeLight',
                          asset: { svg: ShieldWarning, color: 'negative' },
                        }
                      : {
                          title: c('healthBasics.QLEflag.title', { coverageYear }),
                          subtitle: c('healthBasics.QLEflag.subtitle', { coverageYear }),
                          bg: 'negativeLight',
                          asset: { svg: ShieldWarning, color: 'negative' },
                        }
                    : {
                        title: c('healthBasics.nextYear.title', { coverageYear }),
                        subtitle: c('healthBasics.nextYear.subtitle'),
                        bg: 'successLight',
                        asset: { svg: Check, color: 'success' },
                      },
              };
            },
          },
        ],
        onSubmit,
      }}
    />
  );
};

export default HealthZipTriage;
