import React from 'react';
import styled from 'styled-components';
import { Text } from './Text';

interface TextProps {
  mono?: boolean;
  weight?: FontWeight;
  color?: TextColor;
  size?: FontSize;
  loading?: boolean;
  tabular?: boolean;
  align?: TextAlign;
}
interface DefinitionListProps {
  children: React.ReactNode;
  testID?: string;
}
interface DefinitionListItemProps {
  term: React.ReactNode;
  description: React.ReactNode;
  testID?: string;
  textProps?: TextProps;
}

const DefinitionListElement = styled.dl`
  margin: 0;
  padding-top: 12px;
`;

const DefinitionTermElement = styled.dt`
  margin: 0;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  justify-content: space-between;

  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const DefinitionDescriptionElement = styled.dd`
  margin: 0 0 16px 0;
  text-align: right;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const DefinitionList: React.FC<DefinitionListProps> = ({ children, testID }) => (
  <DefinitionListElement testID={testID}>{children}</DefinitionListElement>
);

export const DefinitionListItem: React.FC<DefinitionListItemProps> = ({
  term,
  description,
  testID,
  textProps = {},
}) => (
  <DefinitionTermElement testID={testID}>
    <Text {...textProps}>{term}</Text>
    <DefinitionDescriptionElement>
      <Text {...textProps} align="right" tabular>
        {description}
      </Text>
    </DefinitionDescriptionElement>
  </DefinitionTermElement>
);
