import { gql } from '../generated';

export const DocumentRequests = gql(`
  query DocumentRequests {
    documentRequests(input: { actionable: true }) {
      id
      actionable
      backUploadId
      bothSides
      fileTypes
      frontUploadId
      status
      type
    }
  }
`);

export const HANDLE_DOCUMENT_REQUEST = gql(`
  mutation HandleDocumentRequest($input: HandleDocumentRequestInput!) {
    handleDocumentRequest(input: $input) {
      id
      actionable
      backUploadId
      bothSides
      fileTypes
      frontUploadId
      status
      type
    }
  }
`);
