import React from 'react';
import styled, { keyframes } from 'styled-components';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { FontSize, FontWeight } from '@app/types';
import { Text } from './Text';
import { IconSvg } from './Asset';
import ArrowIcon from '@svg/chevron-down.svg';

const slideDown = keyframes`
  from { height: 0 }
  to { height: var(--radix-accordion-content-height) }
`;

const slideUp = keyframes`
  from { height: var(--radix-accordion-content-height) }
  to { height: 0 }
`;

const StyledAccordionItem = styled(AccordionPrimitive.Item)`
  overflow: hidden;
  margin-top: 1px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledAccordionTrigger = styled(AccordionPrimitive.Trigger)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: 2px solid transparent;
  padding: 0;
  cursor: pointer;

  &:focus-visible {
    border: 2px solid ${(p) => p.theme.colors.system};
  }
`;

const StyledAccordionContent = styled(AccordionPrimitive.Content)`
  overflow: hidden;
  &[data-state='open'] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

const IconWrapper = styled.div`
  width: 25px;
  transform: rotate(-90deg);
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

  [data-state='open'] & {
    transform: rotate(0deg);
  }
`;
export interface AccordionProps {
  title: string;
  initOpen?: boolean;
  children: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({ title, initOpen, children }) => {
  return (
    <AccordionPrimitive.Root collapsible type="single" defaultValue={initOpen ? 'item' : undefined}>
      <StyledAccordionItem value="item">
        <AccordionPrimitive.Header>
          <StyledAccordionTrigger aria-label={title}>
            <Text size={FontSize.lg} weight={FontWeight.medium}>
              {title}
            </Text>
            <IconWrapper>
              <IconSvg svgComponent={ArrowIcon} size={24} color="subtle" aria-hidden="true" />
            </IconWrapper>
          </StyledAccordionTrigger>
        </AccordionPrimitive.Header>
        <StyledAccordionContent>{children}</StyledAccordionContent>
      </StyledAccordionItem>
    </AccordionPrimitive.Root>
  );
};

export default Accordion;
