import React, { useMemo, useState } from 'react';

import { SegmentedControl } from '@uikit';
import { Section } from '@layouts';
import { DocumentsQueryData, queries, useQuery } from '@data';
import { useCopy } from '@app/utils';
import { SettingsLayout } from '@common';
import { date } from '@app/utils/format/date';
import { DocumentList } from '@app/partial';
import { Route } from '@types';

export const Statements = () => {
  const { c } = useCopy('catch.module.me.StatementsView');
  const [index, setIndex] = useState(0);
  const { data, loading } = useQuery<DocumentsQueryData>(queries.DOCUMENTS, {
    fetchPolicy: 'cache-and-network',
  });

  const documents = useMemo(() => {
    const statements = data?.viewerTwo?.documents?.statements?.reduce((accu, s) => {
      const last = accu[accu.length - 1];
      if (last && last.month === date(s.month, 'MONTH_YEAR')) {
        last.documents.push(s);
      } else {
        accu.push({
          month: date(s.month, 'MONTH_YEAR'),
          documents: [s],
        });
      }

      return accu;
    }, []);

    const forms = data?.viewerTwo?.documents?.taxDocuments?.reduce((accu, t) => {
      const last = accu[accu.length - 1];
      if (last && last.month === t.month.slice(0, 4)) {
        last.documents.push(t);
      } else {
        accu.push({
          month: t.month.slice(0, 4),
          documents: [t],
        });
      }

      return accu;
    }, []);

    return {
      statements,
      forms,
    };
  }, [data?.viewerTwo?.documents]);

  return (
    <SettingsLayout title={c('title')}>
      <Section>
        <SegmentedControl
          index={index}
          setIndex={setIndex}
          controls={[{ title: 'Forms' }, { title: 'Statements' }]}
        />
        {index === 0 && (
          <DocumentList
            loading={loading}
            documentsGroup={documents.forms}
            emptyHint={c('emptyHintForms')}
          />
        )}
        {index === 1 && (
          <DocumentList
            loading={loading}
            documentsGroup={documents.statements}
            emptyHint={c('emptyHintStatements')}
          />
        )}
      </Section>
    </SettingsLayout>
  );
};

export const StatementsView = {
  name: Route.STATEMENTS,
  component: Statements,
  options: {
    title: 'Documents',
    largeTitle: true,
  },
};
