export enum DirectSignup {
  BenefitsAdvisor = 'benefits_advisor',
  Fastlane = 'fastlane',
}

export enum RegistrationType {
  Concierge = 'Concierge',
  Standard = 'Standard',
}

export interface UrlParams {
  direct?: DirectSignup;
  r?: string;
  hid?: string;
  skipIntake?: boolean;
}
