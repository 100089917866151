// @ts-nocheck
import React, { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation, mutations, updates, HealthExplorerQuery } from '@data';
import { useMarketplace } from '@hooks';
import { Button, ComplexRow } from '@uikit';
import { Segment, SegmentEvent, capitalize, useCopy } from '@app/utils';
import { ScreenDefinition, pop } from '@navigate';
import { BasicLayout, Stack, Toolbar } from '@layouts';
import { Route } from '@types';
import { formatMedicalName } from '@catch-co/health-utils';
import { SearchSelection, SearchResults } from '../components';
import Search from '@svg/search.svg';
import Stethoscope from '@svg/stethoscope.svg';
import { TextInput } from '@app/forms';

type Provider = {
  npi: string;
  name: string;
};

const defaultNPI = '000000';

const ExplorerDoctors = ({ handleNext }) => {
  const { c } = useCopy('catch');

  const [providers, setProviders] = useState<Array<Provider>>();

  const { loading, data } = useQuery(HealthExplorerQuery, {
    fetchPolicy: 'cache-first',
  });

  const [upsert] = useMutation(mutations.UPSERT_HEALTH_PREFERENCE, {
    update: updates.UPSERT_HEALTH_PREFERENCE,
  });

  const explorer = useMemo(() => {
    return data?.viewerTwo?.healthExplorerData;
  }, [data?.viewerTwo?.healthExplorerData]);

  useEffect(() => {
    const providersWanted = data?.viewerTwo?.health?.healthPreference?.providersWanted || [];
    const formatted = providersWanted.map((d) => ({ name: d.name, npi: d.npi }));
    setProviders(formatted);
  }, [data?.viewerTwo?.health?.healthPreference]);

  const { searchTerm, handleSearch, clear, results, searching } = useMarketplace({
    type: 'providers',
    zip: explorer?.zipcode,
  });

  const updateProviders = (providers: Array<Provider>) => {
    const providersWanted = providers.length === 0 ? null : providers;

    upsert({
      variables: {
        input: { providersWanted },
      },
      optimisticResponse: {
        upsertHealthPreference: {
          ...data?.viewerTwo.health?.healthPreference,
          providersWanted,
        },
      },
    });
  };

  const addProvider = (provider: Provider) => {
    updateProviders([...providers, provider]);
  };

  const removeProvider = (provider: Provider) => {
    updateProviders(
      [...providers].filter((p) => {
        return provider.npi === defaultNPI ? p.name !== provider.name : p.npi !== provider.npi;
      }),
    );
  };

  const handleSubmit = () => {
    const missing = providers?.filter((p) => p.npi === defaultNPI);

    Segment.track(SegmentEvent.HX_PROVIDERS_COMPLETED, {
      skipped: providers?.length === 0,
      total_providers: providers?.length || 0,
      providers__not_found: missing?.length || 0,
      coverage_year: data?.viewerTwo?.healthExplorerData?.coverageYear,
    });

    handleNext();
  };

  return (
    <BasicLayout
      loading={loading}
      pretitle={c('basics.optional')}
      title={c('healthExplorer.explorerDoctors.title')}
      toolbar={
        <Toolbar type="flow" onBack={pop}>
          <Button testID="next" inherit onPress={handleSubmit}>
            {providers?.length === 0 ? 'Skip' : 'Next'}
          </Button>
        </Toolbar>
      }
    >
      <Stack spacing="3" separatorComponent>
        <SearchSelection<Provider>
          title={c('healthExplorer.explorerDoctors.searchTitle')}
          empty={c('healthExplorer.explorerDoctors.searchEmpty')}
          icon={Stethoscope}
          data={providers}
          getKey={(provider) => `${provider.npi}-${provider.name}`}
          getLabel={(provider) => provider.name}
          handleRemove={removeProvider}
        />
        <Stack spacing="2">
          <TextInput
            sensitive
            fullWidth
            label={c('healthExplorer.explorerDoctors.label')}
            placeholder={c('healthExplorer.explorerDoctors.placeholder')}
            icon={Search}
            value={searchTerm}
            onChange={handleSearch}
          />

          <SearchResults searching={searching}>
            {searchTerm && (
              <ComplexRow
                bg="mediumLight"
                selectedBg="mediumLight"
                label="Don't see your provider?"
                sublabel={`Add "${searchTerm}"`}
                accessory="add"
                onPress={() => {
                  addProvider({
                    npi: defaultNPI,
                    name: searchTerm,
                  });

                  clear();
                }}
              />
            )}
            {results?.map((doctor) => (
              <ComplexRow
                key={doctor?.npi}
                sensitive
                disabled={searching}
                label={formatMedicalName(doctor?.name)}
                sublabel={`${doctor?.taxonomy} - ${capitalize(doctor?.address?.city)}, ${doctor
                  ?.address?.state}`}
                onPress={() => {
                  addProvider({
                    npi: doctor.npi,
                    name: formatMedicalName(doctor.name),
                  });

                  clear();
                }}
              />
            ))}
          </SearchResults>
        </Stack>
      </Stack>
    </BasicLayout>
  );
};

export const ExplorerDoctorsView: ScreenDefinition = {
  name: Route.EXPLORER_DOCTORS,
  component: ExplorerDoctors,
  options: {},
};
