// keep these in alphabetical order
import { DocumentsQuery, DocumentQuery } from './queries/DocumentsQuery';
import { EnrollmentGroupsQuery } from './queries/EnrollmentGroupsQuery';
import { HealthBenefitsQuery } from './queries/HealthBenefitsQuery';
import { HealthLinkQuery } from './queries/HealthLinkQuery';
import { HealthProgressLookupQuery } from './queries/HealthProgressLookupQuery';
import { OpenEnrollmentQuery } from './queries/OpenEnrollmentQuery';
import { PrivacyAgreementsQuery } from './queries/PrivacyAgreementsQuery';
import { StateReferenceQuery } from './queries/StateReferenceQuery';

/**
 * @deprecated
 */
type QueryName =
  | 'DOCUMENTS'
  | 'DOCUMENT'
  | 'ENROLLMENT_GROUPS'
  | 'HEALTH_BENEFITS'
  | 'HEALTH_LINK'
  | 'HEALTH_PROGRESS_LOOKUP'
  | 'OPEN_ENROLLMENT'
  | 'PRIVACY_AGREEMENTS'
  | 'STATE_REFERENCE';

/**
 * @deprecated
 */
export const queries: Record<QueryName, any> = {
  DOCUMENTS: DocumentsQuery,
  DOCUMENT: DocumentQuery,
  ENROLLMENT_GROUPS: EnrollmentGroupsQuery,
  HEALTH_BENEFITS: HealthBenefitsQuery,
  HEALTH_LINK: HealthLinkQuery,
  HEALTH_PROGRESS_LOOKUP: HealthProgressLookupQuery,
  OPEN_ENROLLMENT: OpenEnrollmentQuery,
  PRIVACY_AGREEMENTS: PrivacyAgreementsQuery,
  STATE_REFERENCE: StateReferenceQuery,
};

/**
 * @deprecated
 */
export * from './queries/AliasQuery';
export * from './queries/AnalyticsContextQuery';
export * from './queries/ApplicationIssuesQuery';
export * from './queries/CountiesQuery';
export * from './queries/EligibilityResultsQuery';
export * from './queries/ExplorerEligibilityQuery';
export * from './queries/ExplorerPlansQuery';
export * from './queries/HealthApplicationQuery';
export * from './queries/HealthExplorerQuery';
export * from './queries/HealthProgressLookupQuery';
export * from './queries/NotificationSettingsQuery';
export * from './queries/PreviousApplicationsQuery';
export * from './queries/NudgesQuery';
export * from './queries/UserQuery';

// public queries
export * from './queries/ConsentRequestQuery';

// content queries
export * from './queries/PartnerQuery';
