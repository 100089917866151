import React from 'react';

import { Button, Link, Banner } from '@uikit';
import { Toolbar, BasicLayout } from '@layouts';
import { useCopy } from '@app/utils';
import { navigate, close } from '@navigate';
import { Route } from '@types';

const PREFIX = 'catch.ede.CIC.policyInvalid';

const CICPolicyInvalid = () => {
  const { c } = useCopy(PREFIX);
  const state = 'Massachusetts';

  return (
    <BasicLayout
      title={c('title', { state })}
      subtitles={[
        c('subtitle1'),
        c('subtitle2', {
          link: (
            <Link key="carrierLink" testID="carrier-link">
              {c('carrierLink')}
            </Link>
          ),
        }),
      ]}
      toolbar={
        <Toolbar type="stack">
          <Button wide alt gutter="bottom" onPress={() => navigate(Route.CIC_CANCEL_POLICY)}>
            {c('cancelPolicy')}
          </Button>
          <Button wide onPress={() => close()}>
            {c('done')}
          </Button>
        </Toolbar>
      }
    >
      <Banner bg="grayBg" title={c('caption')} />
    </BasicLayout>
  );
};

export const CICPolicyInvalidView = {
  name: 'CIC_POLICY_INVALID',
  component: CICPolicyInvalid,
  options: {
    bg: 'page',
  },
};
