import { calculateTaxes } from '@catch-co/tax-calculator';
import { useMemo } from 'react';
import { User } from '@app/data';

// @todo export from @catch-co/tax-calculator
export type TaxRecommendations = {
  estimatedPaycheckPercentage: number;
  totalLiability: number;
  userResponsibilities: {
    federalLiability: number;
    medicareLiability: number;
    socialSecurityLiability: number;
    stateLiability: number;
  };
};

export default function useTaxRecommendations(user: User): TaxRecommendations | undefined {
  return useMemo(() => {
    if (user?.filingStatus && user?.workState) {
      return calculateTaxes({
        filingStatus: user?.filingStatus,
        grossIncome: user?.income?.estimatedGrossIncome,
        numDependents: user?.numTaxDependents,
        spouseIncome: user?.income?.spouseIncome,
        // @ts-ignore
        state: user?.workState,
      });
    }
  }, [user]);
}
