import {
  ApolloClient,
  HttpLink,
  from,
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { isBefore, isWithinInterval, parseISO, subDays } from 'date-fns';
import { getCarrierName, formatHealthPlanName } from '@catch-co/health-utils';

import { createLogger } from '@app/utils/logger';
import Env from '@app/utils/env';
import { convertUnixTimeToAge } from '@app/utils/time';
import { capitalizeAll } from '@app/utils/format_old/strings';
import generatedIntrospection from './fragmentTypes';
import { formatGoalName } from '@format/formatGoals';
import { clientFields } from './clientFields';
import { healthRouteMap } from '@app/navigate/healthRouteMap';
import { env, getAuthToken } from '@global';

const fetcher = (...args) => {
  return window.fetch(...args);
};
const Log = createLogger('apollo-client');
const abortController = new AbortController();
const httpLink = new HttpLink({
  uri: Env.graphql.uri,
  fetch: fetcher,
  fetchOptions: { mode: 'cors', signal: abortController.signal },
});

const withToken = setContext(async () => {
  const token = getAuthToken();
  let headers = {};

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return {
    headers: headers,
  };
});

const cache = new InMemoryCache({
  possibleTypes: generatedIntrospection,
  dataIdFromObject(responseObject) {
    const d = new Date();
    const dt = d?.toISOString();
    const dt2 = dt?.slice(0, dt?.indexOf('T'));

    switch (responseObject.__typename) {
      case 'Viewer':
        // since theres only one viewer per cache, make it easily identifiable
        return 'Viewer:current';
      case 'ViewerTwo':
        // since theres only one viewer per cache, make it easily identifiable
        return 'ViewerTwo:current';
      case 'Goal':
      case 'TaxGoal':
      case 'PTOGoal':
      case 'RetirementGoal':
      case 'EmergencySavingsGoal':
      case 'FamilyLeaveGoal':
      case 'HealthExpensesGoal':
      case 'CustomGoal':
        return `Goal:${responseObject.slug}`;
      case 'Nudge':
        return `${responseObject.__typename}:${responseObject.nudgeIdentifier}`;
      case 'PlatformReference':
        return `PlatformReference:${dt2}`;
      case 'PlatformConfiguration':
        return `PlatformReference:${dt2}`;
      case 'HealthInsuranceApplication':
      case 'HealthApplication':
      case 'PartnerHealthApplication':
        return `HealthApplication:${responseObject?.id}`;
      case 'ApplicationMember':
        return `ApplicationMember:${responseObject?.id}`;
      case 'KycInfo':
        return `KYCInfo:${dt2}`;
      case 'Address':
        return responseObject?.street1;
      case 'Identity':
        return `Identity:${responseObject.providerType}`;
      case 'Agreements':
        return `Agreements:current`;
      case 'HealthPreference':
        return `HealthPreference:${responseObject.ownerID}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  /**
   * plural to singular mapping
   * @see https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
   *
   * @todo mouseover preload?
   * */
  typePolicies: {
    ViewerTwo: {
      merge: (og, incoming, opts) => opts.mergeObjects(og, incoming),
      fields: {
        health: {
          merge: (existing, incoming) => {
            return incoming ? { ...existing, ...incoming } : null;
          },
        },
        taxPayment: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'TaxPayment',
              id: args.id || args.paymentId,
            });
          },
        },
        account: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'UnitAccount',
              id: args.id,
            });
          },
        },
        identity: {
          read(_, { args, toReference, canRead }) {
            if (args.providerType) {
              const ref = toReference({
                __typename: 'Identity',
                providerType: args.providerType,
              });

              if (canRead(ref)) {
                return ref;
              }
            }

            return null;
          },
        },
        agreements: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Agreements',
              id: 'current',
            });
          },
        },
        existingApplicationInfo: {
          merge: (existing, incoming) => ({ ...existing, ...incoming }),
        },
      },
    },

    Issuer: {
      fields: {
        title(data, { readField }) {
          const name = readField('name') || '';
          return getCarrierName(name) || name;
        },
      },
    },

    DocumentMetadata: {
      fields: {
        name(data, { readField }) {
          const type = readField('documentType');

          const map = {
            PHOTO_ID_FRONT: "Driver's License",
            PASSPORT: 'Passport',
            SOCIAL_SECURITY_CARD: 'Social Security Card',
          };

          return map[type] || '';
        },
      },
    },

    HealthPlan: {
      fields: {
        benefits: {
          merge: (existing, incoming) => incoming,
        },
        title(data, { readField }) {
          return formatHealthPlanName(readField('name') || '');
        },
        metal(data, { readField }) {
          const level = readField('metalLevel')?.replace('_', ' ');
          return capitalizeAll(level);
        },
        individualDeductible(_, { readField }) {
          const d = readField('deductibles')?.find(
            (d) => /^Family Per Person$|Individual/.test(d.familyCost) && /Medical/.test(d.type),
          );

          return d ? d.amount : null;
        },
        familyDeductible(_, { readField }) {
          const d = readField('deductibles')?.find(
            (d) => d.familyCost === 'Family' && /Medical/.test(d.type),
          );

          return d ? d.amount : null;
        },
        individualMax(_, { readField }) {
          const d = readField('moops')?.find(
            (d) => /^Family Per Person$|Individual/.test(d.familyCost) && /Medical/.test(d.type),
          );

          return d ? d.amount : null;
        },
        familyMax(_, { readField }) {
          const d = readField('moops')?.find(
            (d) => d.familyCost === 'Family' && /Medical/.test(d.type),
          );

          return d ? d.amount : null;
        },
      },
    },

    OpenEnrollmentDates: {
      fields: {
        isWindowShopping(_, { readField }) {
          const windowShoppingOpen = readField('windowShoppingOpenTime');
          const oeStart = readField('startTime');

          return !!isWithinInterval(new Date(), {
            start: new Date(windowShoppingOpen),
            end: new Date(oeStart),
          });
        },
        isOpenEnrollment(_, { readField }) {
          const oeStart = readField('startTime');
          const oeEnd = readField('endTime');

          return !!isWithinInterval(new Date(), {
            start: new Date(oeStart),
            end: new Date(oeEnd),
          });
        },
        sepCoverageYear() {
          // sep is always the current year
          return new Date().getFullYear();
        },

        // returns OE year during open enrollment and null otherwise
        oeYear(_, { readField }) {
          const currentOEYear = readField('oeCoverageYear');
          const isOpenEnrollment = readField('isOpenEnrollment');
          const isWindowShopping = readField('isWindowShopping');
          const showOE = isOpenEnrollment || isWindowShopping;

          // only return the current year during OE
          return showOE ? currentOEYear : null;
        },

        // returns SEP year only when different from current year
        sepYear(_, { readField }) {
          const currentOEYear = readField('oeYear');
          const sepYear = readField('sepCoverageYear');
          return sepYear !== currentOEYear ? sepYear : null;
        },
        lastOEYear(_, { readField }) {
          const oeEnd = readField('endTime');
          const currentOEYear = readField('oeCoverageYear');
          return isBefore(new Date(), new Date(oeEnd)) ? currentOEYear - 1 : currentOEYear;
        },
        nextOEYear(_, { readField }) {
          const oeEnd = readField('endTime');
          const currentOEYear = readField('oeCoverageYear');
          return isBefore(new Date(), new Date(oeEnd)) ? currentOEYear : currentOEYear + 1;
        },
        coverageYears(_, { readField }) {
          const sepYear = readField('sepCoverageYear');
          const oeYear = readField('oeCoverageYear');

          const isOpenEnrollment = readField('isOpenEnrollment');
          const isWindowShopping = readField('isWindowShopping');
          const showOE = isOpenEnrollment || isWindowShopping;

          // during OE, we want to append the oe coverage year as well
          if (!!oeYear && oeYear !== sepYear && showOE) {
            return [sepYear, oeYear];
          }

          return showOE ? [oeYear] : [sepYear];
        },
      },
    },

    Goal: {
      fields: {
        title(_, { readField }) {
          const goalType = readField('goalType');
          const name = readField('name');
          return capitalizeAll(formatGoalName({ goalType, name }));
        },
      },
    },

    User: {
      fields: {
        kycSavings: {
          merge: (existing, incoming) => {
            return incoming ? { ...existing, ...incoming } : null;
          },
        },
        legalAddress: {
          merge: (existing, incoming) => ({ ...existing, ...incoming }),
        },
        legalName(data, { readField }) {
          const first = readField('givenName');
          const last = readField('familyName');

          // only return if both first and last are defined
          return !!first && !!last ? `${first} ${last}` : '';
        },
        age(data, { readField }) {
          const dob = readField('dob');
          return !!dob ? convertUnixTimeToAge(dob) : 0;
        },
      },
    },
    HealthReference: {
      merge: (existing, incoming) => {
        return incoming ? { ...existing, ...incoming } : null;
      },
    },

    Health: {
      merge: (existing, incoming) => {
        return incoming ? { ...existing, ...incoming } : null;
      },
    },

    HealthTwo: {
      merge: (existing, incoming) => {
        return incoming ? { ...existing, ...incoming } : null;
      },
    },

    EnrollmentGroup: {
      fields: {
        members: {
          merge: (existing, incoming) => {
            return incoming ? incoming : existing;
          },
        },
      },
    },

    HealthApplication: {
      fields: {
        isGeorgiaAccess: {
          read(_, { readField }) {
            return Env.isGeorgia && readField('coverageState') === 'GA';
          },
        },
        progress: {
          read(_, { readField }) {
            const _hRoutes = Object.keys(healthRouteMap);
            const lastUsedRoute = readField('lastUsedRoute');
            return lastUsedRoute ? _hRoutes.indexOf(lastUsedRoute) / _hRoutes.length : 0.05;
          },
        },
        uiQuestionsToDisplay: {
          read(questions) {
            if (!questions || typeof questions === 'object') {
              return questions;
            }

            return JSON.parse(questions);
          },
        },
        stateReferenceData: {
          merge: (existing, incoming) => {
            return incoming ? { ...existing, ...incoming } : null;
          },
        },
      },
    },

    ApplicationMember: {
      fields: {
        legalName: {
          read(_, { readField }) {
            const first = readField('givenName');
            const last = readField('familyName');

            // only return if both first and last are defined
            return !!first && !!last ? `${first} ${last}` : '';
          },
        },
        phone: {
          merge: (existing, incoming) => {
            return incoming ? { ...existing, ...incoming } : null;
          },
        },
        annualTaxIncome: {
          merge: (existing, incoming) => {
            return incoming ? { ...existing, ...incoming } : null;
          },
        },
        uiQuestionsToDisplay: {
          read(questions) {
            if (!questions || typeof questions === 'object') {
              return questions;
            }

            return JSON.parse(questions);
          },
        },

        isUnwindingSEP: {
          read(_, { readField }) {
            const isMedicaidEnd = readField('isMedicaidEnd');
            const medicaidEndDate = readField('medicaidEndDate');
            return isMedicaidEnd && medicaidEndDate && medicaidEndDate >= '2023-03-31';
          },
        },
      },
    },

    HealthPolicy: {
      fields: {
        shorthand(data, { readField }) {
          const carrier = readField('carrier') || '';
          return carrier?.length > 20 ? carrier.split(' ').slice(0, 2).join(' ') : carrier;
        },
        coverageYear: {
          read(_, { readField }) {
            const date = readField('endDate');
            return date ? Number(date.slice(0, 4)) : undefined;
          },
        },
        dueDate: {
          read(_, { readField }) {
            const start = readField('startDate');
            return start ? subDays(parseISO(start), 1) : undefined;
          },
        },
      },
    },

    Viewer: {
      merge: (og, incoming, opts) => opts.mergeObjects(og, incoming),
      fields: {
        health: {
          merge: (existing, incoming) => {
            return incoming ? { ...existing, ...incoming } : null;
          },
        },
        savingsAccountMetadata: {
          merge: (existing, incoming) => {
            return incoming ? { ...existing, ...incoming } : null;
          },
        },
        activityItem: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Event',
              id: args.id,
              eventType: args.eventType,
            });
          },
        },
        bankLink: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'BankLink',
              id: args.id,
            });
          },
        },
        goal: {
          read(_, { args, toReference, canRead }) {
            if (args.slug) {
              const ref = toReference({
                __typename: 'Goal',
                slug: args.slug,
              });

              if (canRead(ref)) {
                return ref;
              }
            }

            return null;
          },
        },
        taxGoal: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'TaxGoal',
              slug: 'taxes',
            });
          },
        },
        ptoGoal: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'PTOGoal',
              slug: 'timeoff',
            });
          },
        },
        retirementGoal: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'RetirementGoal',
              slug: 'retirement',
            });
          },
        },
        emergencySavingsGoal: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'EmergencySavingsGoal',
              slug: 'emergency-savings',
            });
          },
        },
        healthExpensesGoal: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'HealthExpensesGoal',
              slug: 'health-expenses',
            });
          },
        },
        familyLeaveGoal: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'FamilyLeaveGoal',
              slug: 'family-leave',
            });
          },
        },
        incomeTransaction: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'IncomeTransaction',
              id: args.id,
            });
          },
        },
        transfer: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Transfer',
              id: args.id,
            });
          },
        },
        walletItem: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'WalletItem',
              id: args.id,
            });
          },
        },

        enrollment: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'HealthInsuranceEnrollment',
              id: args.id,
            });
          },
        },
        bankLinks: {
          merge: (existing, incoming) => incoming,
        },
      },
    },
  },
});

export const appClient = new ApolloClient({
  name: 'web',
  version: env.VERSION_NUMBER,
  link: from([withToken, httpLink]),
  connectToDevTools: Env.isLocal,
  cache,
  clientFields,
  devtools: {
    name: 'app',
  },
});

export async function signApolloOut() {
  appClient.stop();
  await appClient.clearStore();
  Log.debug('cleared store');
}

export async function signApolloIn() {
  await appClient.resetStore();
  Log.debug('reset store');
}

export default appClient;
