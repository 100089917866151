import gql from 'graphql-tag';
import { HealthPlanFragment, ApolloHealthPlanDetails } from '../fragments';

export interface HealthBenefitsQueryData {
  viewerTwo: {
    id: string;
    healthPlanDetails: ApolloHealthPlanDetails;
  };
}

export const HealthBenefitsQuery = gql`
  query HealthBenefitsQuery($input: GetHealthPlanDetailsInput!) {
    viewerTwo {
      id
      healthPlanDetails(input: $input) {
        planID: id
        ...HealthPlanDetails
      }
    }
  }

  ${HealthPlanFragment}
`;
