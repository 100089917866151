import React from 'react';

import { openIntercom } from '@app/utils';
import { HiccupBlueprint } from '@app/blueprints';
import { navigate } from '@app/navigate';
import { Route } from '@app/types';
import Warning from '@svg/warning-triangle.svg';
import Chat from '@svg/message-2.svg';

class ErrorBoundary extends React.PureComponent<{ route: Route }> {
  state = {
    hasError: false,
    error: null,
  };

  componentDidCatch(error) {
    console.error('Caught by error boundary:', error);
    this.setState({ hasError: true, error });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.route !== this.props.route) {
      this.resetError();
    }
  }

  resetError = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <HiccupBlueprint
          asset={{ svg: Warning }}
          title="Something went wrong"
          subtitles={['Try again later']}
          buttonText="Go home"
          onPress={() => navigate(Route.HOME)}
          actions={[
            {
              testID: 'support',
              label: 'Contact support',
              onAction: openIntercom,
              inherit: true,
              alt: true,
              svg: Chat,
            },
          ]}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
