import { DocumentRequestType, DocumentType } from '@app/data';

export const DOCUMENT_REQUEST_TYPE_TO_NAME: Record<DocumentRequestType, string> = {
  [DocumentRequestType.AddressVerification]:
    'utility bill, bank statement, lease agreement, or current pay stub',
  [DocumentRequestType.Passport]: 'passport',
  [DocumentRequestType.PhotoId]: 'driver’s license',
  [DocumentRequestType.SocialSecurityCard]: 'Social Security card',
};

// https://catchco.slack.com/archives/CBLQ8BM5M/p1729735394768539?thread_ts=1729709502.564069&cid=CBLQ8BM5M
export const DOCUMENT_REQUEST_TYPE_TO_DOCUMENT_TYPE: Record<DocumentRequestType, DocumentType> = {
  [DocumentRequestType.AddressVerification]: DocumentType.ProofResidence,
  [DocumentRequestType.Passport]: DocumentType.Passport,
  [DocumentRequestType.PhotoId]: DocumentType.PhotoIdFront,
  [DocumentRequestType.SocialSecurityCard]: DocumentType.SocialSecurityCard,
};
