import gql from 'graphql-tag';

export const SUBMIT_DOCUMENTS = gql`
  mutation SubmitDocuments($input: SubmitDocumentListInput!) {
    submitDocuments(input: $input) {
      error
      documentList {
        id
      }
    }
  }
`;

const mutation = {
  document: SUBMIT_DOCUMENTS,
};

export default mutation;
