import gql from 'graphql-tag';
import { ApolloOpenEnrollmentDates, OpenEnrollmentFragment } from '../fragments';

export interface PrivacyAgreementsQueryData {
  viewerTwo: {
    agreements: {
      id: string;
      isPrivacyTruthfulAgree: boolean;
      isPrivacyAuthorizeAgree: boolean;
      isPrivacyDataUseAgree: boolean;
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const PrivacyAgreementsQuery = gql`
  query PrivacyAgreementsQuery {
    viewerTwo {
      id
      agreements {
        id
        isPrivacyTruthfulAgree
        isPrivacyAuthorizeAgree
        isPrivacyDataUseAgree
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
`;
