import createIconComponent from '../../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Utah = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      d: 'M46.0122 17.4004L143.591 17.5856L143.806 61.5887H208.073L208.899 236.396H46.0122V17.4004Z',
      colorable: true,
      fill: '#1F2533',
    },
  ],
  name: 'Utah',
  width: '255',
  height: '254',
  viewBox: '0 0 255 254',
});
Utah.displayName = 'Utah';

/** @deprecated */
export default Utah;
