import React, { useCallback, useMemo } from 'react';
import {
  useCopy,
  constants,
  ninetyDaysAgo,
  fiveYearsAgo,
  handleHealthResponse,
  Segment,
  SegmentEvent,
} from '@app/utils';
import { navigate, ScreenDefinition } from '@navigate';
import { getMembers, HealthApplicationQuery, mutations, useMutation, useQuery } from '@data';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { fields } from './medicaidDenialFields';
import { getInitialValues, formatPayload } from './medicaidDenialUtils';
import { Route } from '@app/types';

/**
 * MedicaidDenialView
 * Page that shows questions for medicaid denial
 * Each member is prompted
 */
const MedicaidDenial = ({ applicationID }) => {
  const { c } = useCopy('catch.ede.MedicaidDenial');

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { requestingCoverageMembers, coverageState, lastOEYear } = useMemo(() => {
    return {
      requestingCoverageMembers: getMembers(data).filter((m) => !!m.isRequestingCoverage),
      coverageState: data?.viewerTwo?.health?.application?.coverageState,
      lastOEYear: data?.reference?.health?.openEnrollmentDates?.lastOEYear,
    };
  }, [data]);

  // @ts-ignore
  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS, handleHealthResponse({}));

  const handleUpsert = useCallback(
    (values) => upsert(formatPayload({ applicationID, values })),
    [applicationID],
  );

  const handleNext = () => {
    Segment.track(SegmentEvent.APPLICATION_MEDICAID_COMPLETED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    navigate(Route.EDE_MEMBER_INCOME_LIST);
  };

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      title={(m) => c('title', { loading, givenName: m?.givenName })}
      subtitle={(m) => c('subtitle', { relation: m?.relation })}
      members={requestingCoverageMembers}
      fields={fields}
      getInitialValues={getInitialValues}
      data={{
        medicaid: constants.medicaidCHIPNames[coverageState]?.medicaid,
        chip: constants.medicaidCHIPNames[coverageState]?.chip,
        state: constants.medicaidCHIPNames[coverageState]?.state,
        ninetyDaysAgo: ninetyDaysAgo(),
        fiveYearsAgo: fiveYearsAgo(),
        oeStartDate: 'November 1',
        oeEndDate: 'January 15',
        oeYear: lastOEYear,
      }}
      onNext={handleUpsert}
      onComplete={handleNext}
    />
  );
};

export const MedicaidDenialView: ScreenDefinition = {
  name: Route.EDE_MEDICAID_DENIAL,
  component: MedicaidDenial,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Medicaid info',
  },
};
