import { gql } from '../generated';

export const HealthApplicationQuery = gql(`
  query HealthApplication($applicationID: ID!) {
    viewer {
      id
      user {
        id
        givenName
        middleName
        familyName
        email
        phoneNumber
        dob
        filingStatus
        numTaxDependents
        legalAddress {
          street1
          street2
          city
          state
          zip
        }
      }
    }
    viewerTwo {
      id
      agreements {
        id
        isPrivacyTruthfulAgree
        isPrivacyAuthorizeAgree
        isPrivacyDataUseAgree
      }
      identity(providerType: CMS) {
        id
        providerType
        status
      }
      health {
        application(id: $applicationID) {
          id
          status
          applicationContext
          lastUsedRoute
          pathwayType
          pathway
          isGeorgiaAccess @client
          shouldRefetchEligibility
          uiQuestionsToDisplay
          insuranceApplicationIdentifier
          handoffUrl

          # assistors
          applicationAssistors {
            applicationAssistorType
            assistorFFMUserName
            assistorNPN
            firstName
            lastName
            middleName
            organizationID
            organizationName
            suffix
          }

          # household information
          coverageState
          coverageYearNumber
          maritalStatus
          numTaxDependents
          isSelfCoverage
          isSpouseCoverage
          numDependentsRequestCoverage
          householdIncome

          # misc
          voterRegistrationAnswerType

          #agreements
          isRenewalAgreement
          renewEligibilityYearQuantity
          isMedicaidRequirementAgreement
          isAbsentParentAgreement
          isTerminateCoverageOtherMecFoundAgreement
          isChangeInformationAgreement
          isTaxCreditReconciled

          eligibility {
            totalAptc
            csrLevel
            isMedicaid
            isChip
          }

          parent {
            id
            aptcHouseholdAmount
            eligibility {
              totalAptc
              csrLevel
              isMedicaid
              isChip
            }
          }

          screening {
            failedScreeners

            # phase 3
            areAllSameAddress
            areDependentsLivingWithParentNotOnTaxReturn
            isClaimingAllDependentsOnTax
            isCoveredThroughJobOrCobra
            isDependentSingleChild25OrYounger
            isIndianOrAlaskan
            isOfferedIchra
            isPlanningToFileTax
            isRequestingFinancialAssistance
            isResponsibleForChildNotOnTaxReturn
            areDependentsChildOrStepchildSingleUnder26

            # phase 2
            areApplicationsCitizens
            isAnyoneFullTimeStudent1822
            isApplicationNameSameAsSsn
            isCanProvideSsn
            isFormerFosterCareAndUnder25
            isIncarcerated
            isNaturalizedOrDerivedCitizenship
            isPregnantOrHadChildLast60Days
            isStepchildOrGrandchild
          }

          # income
          incomeExplanationRequiredIndicator
          incomeExplanationRequiredReason

          # misc.
          stateReferenceData {
            stateCHIPWaitingPeriod
            deprivationRequirementRetained
          }

          applicant {
            id
            ...ApplicationMember
          }

          members {
            id
            ...ApplicationMember
          }

          plan {
            planId: id
            ...HealthPlanDetails
          }

          policies {
            id
            startDate
            endDate
            coverageYear @client
            planName
            carrier
            premium
            premiumEffective
            policyStatus
            enrollmentID
            productType
          }

          enrollment {
            id
            enrollmentType
            isOptOutRenewal
            hasAssociatedDentalPlan
          }
        }
      }
      healthExplorerData {
        id
        healthPlanDetails {
          planID: id
          name
          title @client
          premium
          premiumWithCredit
          issuer {
            id
            name
            title @client
          }
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
        ffmStates {
          code
        }
      }
    }
  }
`);
