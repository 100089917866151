import gql from 'graphql-tag';
import access from 'safe-access';

export const MEMBERS_ELIGIBILITY = gql`
  query MembersEligibility($applicationID: ID!) {
    viewerTwo {
      id
      health {
        application(id: $applicationID) {
          id
          exchangeEligibleMembers {
            id
            givenName
            familyName
            enrollmentGroupID
          }
          chipEligibleMembers {
            id
            givenName
            familyName
          }
          medicaidEligibleMembers {
            id
            givenName
            familyName
          }
        }
      }
    }
  }
`;

const formatter = (data) => {
  const get = access(data);

  const members = get('viewerTwo.health.application.exchangeEligibleMembers') || [];

  const chip = get('viewerTwo.health.application.chipEligibleMembers') || [];

  const medicaid = get('viewerTwo.health.application.medicaidEligibleMembers') || [];

  const { enrolling, notEnrolling } = members?.reduce(
    (acc, m) => {
      if (!!m?.enrollmentGroupID) {
        return { ...acc, enrolling: [...acc.enrolling, m] };
      } else {
        return { ...acc, notEnrolling: [...acc.notEnrolling, m] };
      }
    },
    { enrolling: [], notEnrolling: [] },
  );

  return {
    enrollingMembers: enrolling,
    notEnrollingMembers: notEnrolling,
    splitMembers: [...chip, ...medicaid],
  };
};

export default {
  document: MEMBERS_ELIGIBILITY,
  formatter,
};
