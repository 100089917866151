import { gql } from '../generated';

export const ApplicationIssuesQuery = gql(`
  query ApplicationIssues($applicationID: ID!) {
    viewerTwo {
      id
      health {
        application(id: $applicationID) {
          ...ApplicationIssues
        }
      }
    }
  }
`);
