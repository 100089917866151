import createIconComponent from '../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const More = createIconComponent({
  content: [
    {
      type: 'title',
      children: 'Options',
    },
    {
      type: 'circle',
      cx: '12.5',
      cy: '4',
      r: '2',
      fill: '#1F2533',
      colorable: true,
    },
    {
      type: 'circle',
      cx: '12.5',
      cy: '12',
      r: '2',
      fill: '#1F2533',
      colorable: true,
    },
    {
      type: 'circle',
      cx: '12.5',
      cy: '20',
      r: '2',
      fill: '#1F2533',
      colorable: true,
    },
  ],
  id: '4845%3A28',
  name: 'More',
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
});
More.displayName = 'More';

/** @deprecated */
export default More;
