import { gql } from '../generated';

export const ExplorerPlansQuery = gql(`
  query ExplorerPlans {
    viewerTwo {
      id
      healthExplorerData {
        id
        providerPlanID
        coverageYear
        state
        healthPlans {
          ...PlanSearch
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
`);
