import React from 'react';

import { Link, ToastType, popToast } from '@uikit';
import { BasicLayout, Layout } from '@layouts';
import { safeFormatDate, useCopy } from '@app/utils';
import {
  useDeprecatedMutation,
  useQuery,
  OpenEnrollmentQueryData,
  queries,
  HealthApplicationQuery,
} from '@data';
import { navigate, open, close } from '@navigate';
import { BottomActions } from '@common';
import { Route } from '@types';

import CancelAutoRenew from '@svg/calendar-minus.svg';
import Unlink from '@svg/link-break.svg';
import Plus from '@svg/user-plus.svg';
import Minus from '@svg/user-minus.svg';
import End from '@svg/square-minus.svg';

const icons = {
  CHANGE_PLAN: CancelAutoRenew,
  ADD_MEMBER: Plus,
  REMOVE_MEMBER: Minus,
  CANCEL_RENEWAL: CancelAutoRenew,
  CANCEL_POLICY: End,
};

const CICCoverageOptions = ({ applicationID }) => {
  const { c } = useCopy(`catch.ede.CIC.manageCoverageIntro`);
  const { data } = useQuery<OpenEnrollmentQueryData>(queries.OPEN_ENROLLMENT);

  const { data: app } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const enrollment = app?.viewerTwo?.health?.application?.enrollment;
  const policy = app?.viewerTwo?.health?.application?.policies?.[0];

  const [revoke, { loading: revoking }] = useDeprecatedMutation('revokePermissions', {
    variables: { applicationID },
    onCompleted: () => close(),
    onError: () => close(),
  });

  const [cancelRenewal, { loading: optingOut }] = useDeprecatedMutation('optOutAutoRenewal', {
    variables: { id: enrollment?.id },
    onCompleted: () => {
      popToast({
        type: ToastType.coverage,
        title: c('optOutToastTitle'),
        msg: c('optOutToastMessage', {
          date: safeFormatDate(policy?.endDate),
        }),
      });

      close();
    },
  });

  const disabled = revoking || optingOut;

  return (
    <BasicLayout
      loading={!data}
      title={c('title')}
      subtitles={[
        c('subtitle1'),
        c('subtitle2', {
          link: (
            <Link testID="qle-link" key="sepLink">
              {c('qleLink')}
            </Link>
          ),
        }),
      ]}
    >
      <Layout mobileSnap>
        <BottomActions
          actions={[
            {
              label: c('CHANGE_PLAN'),
              type: 'CUSTOM',
              glyph: icons.CHANGE_PLAN,
              onAction: () => open(Route.EDE_PLANS),
              disabled,
              hidden: !data?.reference?.health?.openEnrollmentDates?.isOpenEnrollment,
            },
            {
              label: c('ADD_MEMBER'),
              type: 'CUSTOM',
              glyph: icons.ADD_MEMBER,
              onAction: () => navigate(Route.CIC_CHANGE_SELECTION, { step: 'ADD_MEMBER' }),
              disabled,
            },
            {
              label: c('REMOVE_MEMBER'),
              type: 'CUSTOM',
              glyph: icons.REMOVE_MEMBER,
              onAction: () => navigate(Route.CIC_CHANGE_SELECTION, { step: 'REMOVE_MEMBER' }),
              disabled,
            },
            {
              label: c('CANCEL_RENEWAL'),
              type: 'CUSTOM',
              glyph: icons.CANCEL_RENEWAL,
              destructive: true,
              onAction: () => cancelRenewal(),
              disabled,
              hidden: enrollment?.isOptOutRenewal, // don't show if already opted out
            },
            {
              label: c('CANCEL_POLICY'),
              type: 'CUSTOM',
              glyph: icons.CANCEL_POLICY,
              destructive: true,
              onAction: () => navigate(Route.CIC_CANCEL_POLICY, { step: 'CANCEL_POLICY' }),
              disabled,
            },
            {
              label: c('REVOKE_PERMISSIONS'),
              type: 'CUSTOM',
              destructive: true,
              glyph: Unlink,
              onAction: () => revoke(),
              disabled,
            },
          ]}
        />
      </Layout>
    </BasicLayout>
  );
};

export const CICCoverageOptionsIntroView = {
  name: Route.CIC_MANAGE_COVERAGE_INTRO,
  component: CICCoverageOptions,
  options: {},
};
