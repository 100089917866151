import { gql } from '../generated';

export const NotificationSettingsQuery = gql(`
  query NotificationSettings {
    viewer {
      id
      notificationSettings {
        text {
          healthInsurance
        }
        email {
          myBenefits
          recommendedBenefits
          featureAnnouncements
        }
      }
    }
  }
`);
