import React, { useState, ComponentType } from 'react';

import { ActionRow, Button, Text, useTheme } from '@uikit';
import { Stack, Layout } from '@layouts';
import { handleAction } from '@navigate';
import { Action } from '@types';
import { View } from 'react-native';

interface BottomActionsProps {
  actions?: Array<Action>;
  accentColor: string;
  routeProps: any;
  disabled?: boolean;
  container?: ComponentType<{ children?: any }>;
}

const BottomAction = ({ testID, action, accentColor, routeProps, disabled }) => {
  const { theme } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);

  return !!action?.hidden || !action ? null : !!action?.button ? (
    <Layout margins center>
      {!!action.errorLabel && (
        <Layout bottomSpace>
          <Text color="credit">{action.errorLabel}</Text>
        </Layout>
      )}
      <View style={[theme.ySpace1, { alignSelf: 'stretch', margin: 'auto' }]}>
        <Button
          testID={testID}
          inherit={action.inherit}
          wide
          onPress={() => handleAction(action, routeProps, setLoading)}
          loading={action.loading || loading}
          disabled={disabled || action.disabled || loading}
          accentColor={action.color}
        >
          {action.label}
        </Button>
      </View>
    </Layout>
  ) : (
    <ActionRow
      {...action}
      textColor={action.destructive ? 'credit' : undefined}
      testID={testID}
      interaction={action.interaction}
      inset={!!action.interaction}
      onAction={(props) => {
        const payload = props !== undefined ? props : { setLoading };
        handleAction(action, routeProps, payload);
      }}
      accentColor={action.color}
      hexColor={accentColor}
      loading={action.loading || loading}
      disabled={disabled || action.disabled || loading}
      loadStyle="spinner"
    />
  );
};

/**
 *
 */
export const BottomActions: React.FC<BottomActionsProps> = ({
  actions = [],
  accentColor,
  routeProps,
  container,
  disabled,
}) => {
  const Wrapper = container && actions.length ? container : React.Fragment;
  return (
    <Wrapper>
      <Stack>
        {actions?.map((action, idx) => (
          <BottomAction
            testID={action.testID || action.label || `ACTION_${idx}`}
            key={action.testID || action.label || idx}
            action={action}
            accentColor={accentColor}
            routeProps={routeProps}
            disabled={disabled}
          />
        ))}
      </Stack>
    </Wrapper>
  );
};

export default BottomActions;
