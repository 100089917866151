import React from 'react';

import { stacks, Stack, StackComponent, StackDefinition } from '@navigate';

import { MeView } from './MeView';
import { UserInfoView } from './UserInfoView';
import { AccountSettingsView } from './AccountSettingsView';
import { StatementsView } from './StatementsView';

const config: StackDefinition = {
  stackName: stacks.ME_STACK,
  options: { accentColor: 'brand', presentation: 'pageSheet' },
  screens: [MeView, UserInfoView, AccountSettingsView, StatementsView], // @todo E-3445 typescript
};

const MeStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

MeStack.config = config;

export default MeStack;
