import React from 'react';
import styled from 'styled-components';
import { useLayoutContext } from '@layouts/LayoutContext';
import { FontWeight, UniversalTextProps } from '@app/types';
import { LinkColor, applyFontStyles } from '@app/styles';

export interface LinkProps extends UniversalTextProps {
  testID: string;
  color?: LinkColor;
  href?: string;
  download?: string;
  disabled?: boolean;
  onPress?: () => void;
  inline?: boolean;
  children: React.ReactNode;
  ariaLabel?: string;
}

interface StyledLinkProps extends UniversalTextProps {
  inline?: boolean;
  disabled: boolean;
}

const StyledLink = styled.a<StyledLinkProps>`
  ${(props) => applyFontStyles(props)}
  display: ${(p) => (p.inline ? 'inline' : 'inline-flex')};
  align-items: center;

  &[disabled] {
    opacity: 0.5;
  }

  &:not([disabled]):hover {
    cursor: pointer;
    filter: brightness(85%);
  }

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

export function Link({
  testID,
  weight = FontWeight.medium,
  color,
  size,
  align,
  href,
  download,
  disabled,
  onPress,
  inline,
  className,
  children,
  ariaLabel,
}: LinkProps) {
  const context = useLayoutContext();

  const handlePress = () => {
    if (onPress && !disabled) onPress();
  };

  return (
    <StyledLink
      id={testID}
      data-testid={testID}
      $weight={weight}
      size={size}
      align={align}
      color={color || (context?.accentColor as LinkColor)}
      href={href}
      target={href ? '_blank' : undefined}
      download={download}
      disabled={!!disabled}
      onClick={handlePress}
      tabIndex={0}
      inline={inline}
      className={className}
      aria-label={ariaLabel}
      onKeyPress={({ key }) => {
        if (key === 'Enter') {
          handlePress();
        }
      }}
    >
      {children}
    </StyledLink>
  );
}
