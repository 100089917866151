import { useQuery as useApolloQuery } from '@apollo/client';
import Log from '@app/utils/logger';
import { cloneDeep } from 'lodash';

import * as QUERIES from './deprecated/queries';

/** @deprecated */
interface DeprecatedQueryReturn {
  /** @deprecated */
  data?: any;
  /** @deprecated */
  loading?: boolean;
  /** @deprecated */
  error?: boolean;
}

/**
 *
 * @deprecated consider moving to useQuery with directly imported typed queries
 *
 */
function useDeprecatedQuery(query, options = {}): DeprecatedQueryReturn {
  const {
    data: _data,
    loading,
    error,
    networkStatus,
    refetch,
    ...rest
  } = useApolloQuery(
    QUERIES[query]?.document || QUERIES['ref'].document, // fairly generic fallback if query is undefined
    QUERIES[query]?.options || options,
  );

  if (error) {
    Log.error(error);
  }

  // required for Apollo 3+
  const data = cloneDeep(_data);

  const hasData = !!data;

  /**
   * When a formatter is defined on the query,
   * we apply that formatter to the data
   * Otherwise, we return the data raw
   *
   * Note: the addition of useMemo is to make sure that we don't
   * call the formatter over and over again unless the query data changes
   */
  const values = QUERIES[query]?.formatter ? QUERIES[query]?.formatter(data, options) : data;

  return {
    ...values,
    hasData: !!data || !!_data,
    error,
    refetch,
    networkStatus,

    /** waiting means we don't have data yet and it's fetching */
    waiting: !!loading && !hasData,

    /** "loading" means it's fetching (maybe the first time, maybe a refetch) */
    loading,

    ...rest,
  };
}

export default useDeprecatedQuery;
