import React from 'react';
import styled from 'styled-components';
import { View } from 'react-native';

import { default as Stack } from '../../layouts/Stack';
import { Text } from './Text';
import { useTheme } from '../hooks/useTheme';
import { IconSvg } from './Asset';

const BulletContainer = styled.div`
  padding-right: 16px;
`;

export const Bullets = ({ children }) => <Stack spacing="0b">{children}</Stack>;

export const Bullet = ({
  style = [],
  text,
  bulletColor,
  bulletSize,
  textColor,
  textSize,
  children,
}) => {
  const { theme } = useTheme();

  return (
    <View style={[theme.row, theme.baselineAlign, theme.yGutter0, ...style]}>
      <BulletContainer>
        <Text color={bulletColor} size={bulletSize}>
          •
        </Text>
      </BulletContainer>
      {children || (
        <Text fluid color={textColor} size={textSize}>
          {text}
        </Text>
      )}
    </View>
  );
};

export const NumberedBullets = ({ number, children, text }) => {
  const { theme } = useTheme();
  return (
    <View style={[theme.bottomGutter2, theme.row, theme.alignStart]}>
      <BulletContainer>
        <Text fluid weight="bold" style={[theme.flex1, theme.rightGutter2]}>
          {number}.
        </Text>
      </BulletContainer>
      <View style={theme.flex1}>{children || <Text fluid>{text}</Text>}</View>
    </View>
  );
};

export const IconBullet = ({ icon, children, text }) => {
  const { theme } = useTheme();
  return (
    <View style={[theme.bottomGutter2, theme.row, theme.alignStart]}>
      <View>
        <IconSvg svgComponent={icon} size="xs" />
      </View>
      <View style={theme.flex1}>{children || <Text fluid>{text}</Text>}</View>
    </View>
  );
};

export default Bullets;
