import gql from 'graphql-tag';

export const UPSERT_DENTAL_WALLET_ITEM = gql`
  mutation upsertDentalWalletItem($input: DentalWalletItemInput!) {
    upsertDentalWalletItem(input: $input) {
      id
      vertical
      carrier
      planName
      source
      groupNumber
      policyNumber
      notes
    }
  }
`;

export const UPSERT_HEALTH_WALLET_ITEM = gql`
  mutation upsertHealthWalletItem($input: HealthWalletItemInput!) {
    upsertHealthWalletItem(input: $input) {
      id
      vertical
      carrier
      planName
      source
      groupNumber
      policyNumber
      notes
    }
  }
`;

export const UPSERT_VISION_WALLET_ITEM = gql`
  mutation upsertVisionWalletItem($input: VisionWalletItemInput!) {
    upsertVisionWalletItem(input: $input) {
      id
      vertical
      carrier
      planName
      source
      groupNumber
      policyNumber
      notes
    }
  }
`;

export const UPSERT_LIFE_WALLET_ITEM = gql`
  mutation upsertLifeWalletItem($input: LifeWalletItemInput!) {
    upsertLifeWalletItem(input: $input) {
      id
      vertical
      carrier
      planName
      source
      groupNumber
      policyNumber
      notes
    }
  }
`;

export const upsertWallet_DENTAL = {
  document: UPSERT_DENTAL_WALLET_ITEM,
  formatter: (data) => data?.upsertDentalWalletItem,
};

export const upsertWallet_HEALTH = {
  document: UPSERT_HEALTH_WALLET_ITEM,
  formatter: (data) => data?.upsertHealthWalletItem,
};

export const upsertWallet_VISION = {
  document: UPSERT_VISION_WALLET_ITEM,
  formatter: (data) => data?.upsertVisionWalletItem,
};

export const upsertWallet_LIFE = {
  document: UPSERT_LIFE_WALLET_ITEM,
  formatter: (data) => data?.upsertLifeWalletItem,
};
