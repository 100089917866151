import { gql } from '../generated';

gql(`
  fragment GoalSetupUser on User {
    id
    workType
    filingStatus
    numTaxDependents
    workState
    income {
      estimated1099Income
      estimatedW2Income
      estimatedGrossIncome
      spouseIncome
    }
    agreements {
      ...Agreements 
    }
  }
`);

export const GET_GOAL_SETUP_DETAILS = gql(`
  query GetGoalSetupDetails {
    me {
      user {
        ...GoalSetupUser 
      }
    }
  }
`);

export const UPDATE_GOAL_SETUP_USER = gql(`
  mutation UpdateGoalSetupUser($input: UpdateUserInput!) {
    updateUserNew(input: $input) {
      user {
        ...GoalSetupUser
      }
    }
  }
`);
