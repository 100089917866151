import React from 'react';
import styled from 'styled-components';
import { Copy, FontSize } from '@types';
import { Stack } from '@layouts/Stack';
import { Text } from './Text';
import { Color } from '@app/styles';

export type AnnotationScheme = 'positive' | 'negative' | 'info';

export interface AnnotationItem {
  id?: string;
  title: Copy;
  scheme: AnnotationScheme;
}

const bgs: Record<AnnotationScheme, Color> = {
  positive: Color.positiveLight,
  negative: Color.negativeLight,
  info: Color.brandLight,
};

const StyledContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

const StyledAnnotation = styled.div<{ $scheme: AnnotationScheme }>`
  background-color: ${({ theme, $scheme }) => theme.colors[bgs[$scheme]]};
  padding: 6px;
`;

export const Annotation: React.FC<{ note: AnnotationItem }> = ({ note }) => {
  return (
    <StyledAnnotation $scheme={note.scheme}>
      <Text size={FontSize.sm} color={note.scheme}>
        {note.title}
      </Text>
    </StyledAnnotation>
  );
};

export const AnnotationGroup: React.FC<{ notes: Array<AnnotationItem> }> = ({ notes }) => {
  return (
    <StyledContainer>
      <Stack spacing="+gap">
        {notes.map((note, index) => (
          <Annotation key={note.id || index} note={note} />
        ))}
      </Stack>
    </StyledContainer>
  );
};

export default Annotation;
