import { gql } from '../generated';

export const AnalyticsContextQuery = gql(`
  query AnalyticsContext($platform: AppPlatform!) {
    viewer {
      id
      user {
        id
        givenName
        familyName
        email
        phoneNumber
      }
      intercomIdentityHash(appPlatform: $platform)
    }
  }
`);
