export const fields = [
  {
    name: 'members',
    type: 'array',
    Component: ({ children }) => children,
    Footer: () => null,
    subfields: [
      {
        name: 'hasAbsentParent',
        bare: true,
        type: 'person',
        dependencies: ['fullName'],
        label: ({ fullName }) => fullName,
        secondary: {
          unchecked: 'No',
          checked: 'Yes',
        },
      },
    ],
  },
];
