import React from 'react';

import { useCopy } from '@app/utils';
import { GoalSlug, Route } from '@types';
import { Button } from '@uikit';
import { Toolbar } from '@layouts';
import { IntroBlueprint } from '@blueprints';
import { getGoalTypeFromSlug, GOAL_TYPE_TO_COLOR, GOAL_TYPE_TO_NAME } from '@const/goals';

import Bank from '@svg/bank.svg';
import Bolt from '@svg/bolt.svg';
import Chart from '@svg/chart.svg';
import { useFintechMutation, useFintechQuery } from '@app/data';
import {
  CREATE_GOAL,
  CREATE_GOAL_CACHE_UPDATE,
  GET_GOAL_BY_SLUG,
} from '@app/data/definitions/goals';

interface GoalIntroProps {
  slug: GoalSlug;
  handleNext: (updates: { slug: string }) => void;
}

/**
 * Renders an intro screen to setting up a goal
 */
const GoalIntro = ({ slug, handleNext }: GoalIntroProps) => {
  const [createGoal, { loading: creating }] = useFintechMutation(CREATE_GOAL, {
    onCompleted: (data) => handleNext({ slug: data?.createGoal?.slug || slug }),
    update: CREATE_GOAL_CACHE_UPDATE,
  });

  const { loading, data } = useFintechQuery(GET_GOAL_BY_SLUG, {
    variables: { slug },
    skip: !slug,
  });

  // otherwise, use the config to assume type
  const goalType = data?.goal?.type || getGoalTypeFromSlug(slug);
  const goalName = data?.goal?.name || GOAL_TYPE_TO_NAME[goalType];
  const goalColor = GOAL_TYPE_TO_COLOR[goalType];

  const { c } = useCopy(`catch.goal.intro.${goalType}`);
  const { c: c2 } = useCopy('catch.basics');

  const handlePress = async () => {
    if (data?.goal) {
      console.warn('Goal already exists, continuing..');
      handleNext({ slug: data?.goal?.slug || slug });
    }

    if (!data?.goal) {
      console.warn('No goal found, creating new goal');
      createGoal({ variables: { input: { type: goalType } } });
    }
  };

  return (
    <IntroBlueprint
      loading={loading}
      type={goalType}
      title={goalName}
      titleSize="page"
      align="center"
      gradient={goalColor + 'Light'}
      bullets={[
        {
          id: 'ONE',
          label: c('step1'),
          sublabel: c('subtitle1'),
          svg: Bank,
        },
        {
          id: 'TWO',
          label: c('step2'),
          sublabel: c('subtitle2'),
          svg: Chart,
        },
        {
          id: 'THREE',
          label: c('step3'),
          sublabel: c('subtitle3'),
          svg: Bolt,
        },
      ]}
      toolbar={
        <Toolbar type="stack">
          <Button inherit onPress={handlePress} testID="next-btn" disabled={creating}>
            {c2('next')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const GoalIntroView = {
  name: Route.GOAL_INTRO,
  component: GoalIntro,
  options: {
    drawBehindNav: true,
  },
};
