import { GoalFragment, GoalStatus, GoalType } from '@data';
import { Color } from '@app/styles/types';

export interface GoalConfig {
  goalType: GoalType;
  slug: string;
  color: Color;
}

/**
 * Note: the enum values are used in display
 * Make sure they are readable
 */
export enum GoalSection {
  Taxes = 'Taxes',
  Goals = 'Goals',
}

/**
 * Mapping of goal type to slugs
 */
export const GOAL_TYPE_TO_SLUG: Record<GoalType, string> = {
  [GoalType.TaxSavings]: 'taxes',
  [GoalType.PaidTimeOff]: 'timeoff',
  [GoalType.EmergencySavings]: 'emergency-savings',
  [GoalType.FamilyLeave]: 'family-leave',
  [GoalType.HealthExpenses]: 'health-expenses',
  [GoalType.RetirementSavings]: 'retirement-savings',
  [GoalType.Custom]: 'custom-goal',
};

/**
 * Mapping of goal type to readable name
 */
export const GOAL_TYPE_TO_NAME: Record<GoalType, string> = {
  [GoalType.TaxSavings]: 'Taxes',
  [GoalType.PaidTimeOff]: 'Time-Off',
  [GoalType.EmergencySavings]: 'Emergency Savings',
  [GoalType.FamilyLeave]: 'Family Leave',
  [GoalType.HealthExpenses]: 'Health Expenses',
  [GoalType.RetirementSavings]: 'Retirement Savings',
  [GoalType.Custom]: 'Custom Goal',
};

/**
 * Mapping of goal type to color
 */
export const GOAL_TYPE_TO_COLOR: Record<GoalType, Color> = {
  [GoalType.TaxSavings]: Color.taxes,
  [GoalType.PaidTimeOff]: Color.saved,
  [GoalType.EmergencySavings]: Color.saved,
  [GoalType.FamilyLeave]: Color.saved,
  [GoalType.HealthExpenses]: Color.coverage,
  [GoalType.RetirementSavings]: Color.retirement,
  [GoalType.Custom]: Color.saved,
};

/**
 * Mapping of goal type to default withholding percentage
 * Note: these are sometimes calculated based on other properties (e.g. taxes)
 * but these should act as sensible defaults
 */
export const GOAL_TYPE_DEFAULT_PERCENTAGE: Record<GoalType, number> = {
  [GoalType.TaxSavings]: 25,
  [GoalType.EmergencySavings]: 10,
  [GoalType.PaidTimeOff]: 5,
  [GoalType.FamilyLeave]: 5,
  [GoalType.HealthExpenses]: 5,
  [GoalType.RetirementSavings]: 5,
  [GoalType.Custom]: 5,
};

/**
 * The set of goals that we want to prompt users to setup
 * Note: this also acts as an ordering of priority
 */
export const GOAL_TYPES_TO_ADD: Array<GoalType> = [
  GoalType.TaxSavings,
  GoalType.EmergencySavings,
  GoalType.PaidTimeOff,
  GoalType.FamilyLeave,
  GoalType.Custom,
];

/**
 * Splits the goal types into sections for display in the UI
 * We want to show taxes slightly different than the other goals
 */
export const GOAL_TYPE_TO_SECTION: Record<GoalType, GoalSection> = {
  [GoalType.TaxSavings]: GoalSection.Taxes,
  [GoalType.PaidTimeOff]: GoalSection.Goals,
  [GoalType.EmergencySavings]: GoalSection.Goals,
  [GoalType.FamilyLeave]: GoalSection.Goals,
  [GoalType.HealthExpenses]: GoalSection.Goals,
  [GoalType.RetirementSavings]: GoalSection.Goals,
  [GoalType.Custom]: GoalSection.Goals,
};

/**
 * Goal sections (in order) to display on the goals page
 */
export const GOAL_SECTIONS: Array<GoalSection> = [GoalSection.Taxes, GoalSection.Goals];

/**
 * We treat these statuses as "ready" for purposes of the UI
 * Users can view the goal detail view for these
 */
export const READY_GOAL_STATUSES: Array<GoalStatus> = [GoalStatus.Active, GoalStatus.Pending];

/**
 * Reverse mapping from slug to goal type
 */
export const SLUG_TO_GOAL_TYPE: Record<string, GoalType> = Object.values(GoalType).reduce(
  (acc, goalType: GoalType) => {
    const slug = GOAL_TYPE_TO_SLUG[goalType];
    return { ...acc, [slug]: goalType };
  },
  {} as Record<string, GoalType>,
);

/**
 * Given a generic slug, returns the corresponding goal type
 * If defined in the mappings above, use that. Otherwise, its a custom goal
 */
export const getGoalTypeFromSlug = (slug: string): GoalType => {
  return SLUG_TO_GOAL_TYPE[slug] || GoalType.Custom;
};

/**
 * Helper function to get the goal config from a slug
 * This is useful for setting defaults
 */
export const getConfigFromSlug = (slug: string): GoalConfig => {
  const goalType = getGoalTypeFromSlug(slug);
  const color = GOAL_TYPE_TO_COLOR[goalType];

  return {
    slug,
    goalType,
    color,
  };
};

/**
 * Given a set of existing goals, returns a list of types that should be added
 * This relies on the GOAL_TYPES_TO_ADD constant defined above.
 */
export const getGoalTypesToAdd = (existingGoals: Array<GoalFragment>) => {
  const goalTypes = GOAL_TYPES_TO_ADD.reduce(
    (acc, goalType) => {
      const alreadyAdded = existingGoals.some((goal) => goal?.type === goalType);
      return alreadyAdded ? acc : [...acc, goalType];
    },

    // always allow users to setup custom goals
    [GoalType.Custom] as Array<GoalType>,
  );

  // make sure to return unique values
  const uniqueGoalTypes = [...new Set(goalTypes)];

  // uses the GOAL_TYPES_TO_ADD array for priority ordering
  return uniqueGoalTypes.sort((a, b) => {
    return GOAL_TYPES_TO_ADD.indexOf(a) - GOAL_TYPES_TO_ADD.indexOf(b);
  });
};

interface GoalSectionConfig {
  name: GoalSection;
  goals: Array<GoalFragment>;
  goalsToAdd: Array<GoalType>;
}

/**
 * Handles filtering the goals/goals to add by section type
 */
export const filterGoalsBySection = ({
  section,
  existingGoals,
  goalsToAdd,
}: {
  section: GoalSection;
  existingGoals: Array<GoalFragment>;
  goalsToAdd: Array<GoalType>;
}): GoalSectionConfig => {
  return {
    name: section,
    goals: existingGoals.filter((g) => g?.type && GOAL_TYPE_TO_SECTION[g?.type] === section),
    goalsToAdd: goalsToAdd.filter(
      (goalType) => GOAL_TYPE_TO_SECTION[goalType] === section && goalType !== GoalType.Custom,
    ),
  };
};

/**
 * Gets a break down of existing goals and goals to add by section
 */
export const getGoalsBySection = (existingGoals: Array<GoalFragment>): Array<GoalSectionConfig> => {
  const goalsToAdd = getGoalTypesToAdd(existingGoals);

  return GOAL_SECTIONS.map((section) =>
    filterGoalsBySection({
      section,
      existingGoals,
      goalsToAdd,
    }),
  );
};

export const GOALS_STORAGE_ADDITIONAL_DATA_KEY = 'goals-additional-data';
export const GOALS_STORAGE_ADDITIONAL_SEEN_KEY = 'goals-additional-seen';
export const GOALS_STORAGE_BANKING_SEEN_KEY = 'goals-banking-seen';
export const GOALS_STORAGE_FLOW_KEY = 'goals-flow';

export const GOALS_STORAGE_KEYS = [
  GOALS_STORAGE_ADDITIONAL_DATA_KEY,
  GOALS_STORAGE_ADDITIONAL_SEEN_KEY,
  GOALS_STORAGE_BANKING_SEEN_KEY,
  GOALS_STORAGE_FLOW_KEY,
];

export const GOALS_STORAGE_TRUE_VALUE = 'true';
