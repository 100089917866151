import React, { useMemo } from 'react';
import {
  useQuery,
  HealthApplicationQuery,
  useMutation,
  mutations,
  updates,
  UpsertHealthApplication,
} from '@data';
import { navigate } from '@navigate';
import { SplitFormBlueprint } from '@app/blueprints';
import { fields } from './confirmApplicantsFields';
import {
  formatMembersPayload,
  formatApplicationPayload,
  getInitialValues,
  checkForRescreening,
} from './confirmApplicantsUtils';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { ToastType, popToast } from '@app/_ui-kit';
import { Route } from '@app/types';

const ConfirmApplicants = ({ applicationID }) => {
  const { c } = useCopy('catch.ede.ConfirmApplicants');

  const [upsertApp, { loading: upsertingApplication }] = useMutation(UpsertHealthApplication);

  const [upsertMembers, { loading: upsertingMembers }] = useMutation(
    mutations.UPSERT_APPLICATION_MEMBERS,
    {
      update: updates.UPDATE_APPLICATION_MEMBERS,
    },
  );

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(() => getInitialValues(data), [data]);

  const handleSubmit = async (values) => {
    // determines if there are any diffs
    const rescreen = checkForRescreening({ values });

    const { isWindowShopping, oeYear } = data?.reference?.health?.openEnrollmentDates || {};
    const appYear = data?.viewerTwo?.health?.application?.coverageYearNumber;

    // handles updating app + members
    await Promise.all([
      upsertApp({ variables: { input: formatApplicationPayload({ values, applicationID }) } }),
      upsertMembers({ variables: formatMembersPayload({ values, applicationID }) }),
    ]);

    Segment.track(SegmentEvent.APPLICATION_APPLICANTS_CONFIRMED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    // handles proper navigation
    if (rescreen) {
      navigate(Route.EDE_SCREENING, { householdRevisit: true });

      popToast({
        type: ToastType.coverage,
        title: 'Your household has changed',
        msg: "You'll have to answer some questions again",
        autoCloseIn: 5000,
      });
    } else {
      if (appYear === oeYear && isWindowShopping) {
        navigate(Route.EDE_WINDOW_SHOPPING);
      } else {
        navigate(Route.EDE_MEMBER_INFO);
      }
    }
  };

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={upsertingApplication || upsertingMembers}
      dependencies={['members']}
      title={c('title')}
      subtitle={({ members }) => {
        const household = members?.length;
        const numApplicants = members?.filter((m) => m.isRequestingCoverage)?.length;
        return c('subtitle', { numApplicants, household });
      }}
      formConfig={{
        initialValues,
        fields,
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const ConfirmApplicantsView = {
  name: Route.EDE_CONFIRM_APPLICANTS,
  component: ConfirmApplicants,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Applicants',
  },
};
