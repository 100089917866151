import React from 'react';
import styled from 'styled-components';
import { Copy } from '@app/types';
import { Color } from '@app/styles';
import Tag from '@svg/tag.svg';
import { Text } from './Text';
import { IconSvg } from './Asset';

const StyledBadge = styled.div<{ color: BadgeColor; onClick?: () => void }>`
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ theme, color }) => theme.colors[borderColors[color]]};
  background-color: ${({ theme, color }) => theme.colors[backgroundColors[color]]};
  padding: 4px 8px;
  align-self: flex-start;

  > *:not(:first-child) {
    padding-left: 2px;
  }

  > *:not(:last-child) {
    padding-right: 2px;
  }

  ${({ onClick, theme, color }) =>
    onClick &&
    `
    &:hover {
      cursor: pointer;
      background-color: ${theme.colors[hoverColors[color]]};
    }
  `}
`;

export enum BadgeColor {
  primary = 'primary',
  success = 'success',
}

export enum BadgeIcon {
  Tag = 'Tag',
}

export interface BadgeProps {
  label: Copy;
  icon?: BadgeIcon;
  color?: BadgeColor;
  onPress?: () => void;
}

const icons: Record<BadgeIcon, any> = {
  [BadgeIcon.Tag]: Tag,
};

const textColors: Record<BadgeColor, string> = {
  [BadgeColor.primary]: 'text',
  [BadgeColor.success]: 'positive',
};

const borderColors: Record<BadgeColor, Color> = {
  [BadgeColor.primary]: Color.border,
  [BadgeColor.success]: Color.bgTransparent,
};

const hoverColors: Record<BadgeColor, Color> = {
  [BadgeColor.primary]: Color.bg2,
  [BadgeColor.success]: Color.positiveHover,
};

const backgroundColors: Record<BadgeColor, Color> = {
  [BadgeColor.primary]: Color.bgTransparent,
  [BadgeColor.success]: Color.positiveLight,
};

export const Badge: React.FC<BadgeProps> = ({
  label,
  color = BadgeColor.primary,
  icon,
  onPress,
}) => {
  const ComponentType = onPress ? 'button' : 'div';

  return (
    <StyledBadge as={ComponentType} color={color} onClick={onPress}>
      {icon && <IconSvg size="glyph" svgComponent={icons[icon]} />}
      <Text size="sm" color={textColors[color]} weight="medium">
        {label}
      </Text>
    </StyledBadge>
  );
};

export default Badge;
