import React from 'react';

import { Button } from '@uikit';
import { Toolbar, CalloutLayout } from '@layouts';
import { useCopy } from '@app/utils';
import { close } from '@navigate';

const PREFIX = 'catch.ede.CIC.govPickup';

const CICGovPickup = () => {
  const { c } = useCopy(PREFIX);

  return (
    <CalloutLayout
      render="coverage"
      title={c('title')}
      subtitles={[c('subtitle')]}
      toolbar={
        <Toolbar>
          <Button onPress={() => close()}>{c('done')}</Button>
        </Toolbar>
      }
    />
  );
};

export const CICGovPickupView = {
  name: 'CIC_GOV_PICKUP',
  component: CICGovPickup,
  options: {},
};
