import React, { useEffect } from 'react';
import { navigate, SheetComponentProps } from '@navigate';
import {
  useMutation,
  queries,
  mutations,
  updates,
  FetchEligibilityVars,
  FetchEligibilityResponse,
} from '@app/data';
import { Route } from '@types';
import LoaderSheet from './LoaderSheet';

const RefetchEligibilitySheet: React.FC<SheetComponentProps['REFETCH_ELIGIBILITY']> = ({
  applicationID,
  closeSheet,
}) => {
  const [refetch] = useMutation<FetchEligibilityResponse, FetchEligibilityVars>(
    mutations.FETCH_ELIGIBILITY,
    {
      variables: { applicationID },
      refetchQueries: [queries.ELIGIBILITY_RESULTS],
      awaitRefetchQueries: true,
      update: (cache) =>
        updates.ELIG_REFETCHED(cache, {
          didRefetch: true,
          applicationID,
        }),
      onCompleted: (data) => {
        // if any member has different eligibility,
        if (data.fetchEligibility?.some((m) => m.hasEligibilityChanged)) {
          navigate(Route.EDE_RESULTS);
        }

        closeSheet();
      },
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <LoaderSheet
      accentColor="coverage"
      title="Refreshing your application"
      subtitle="This may take a few seconds"
    />
  );
};

export default RefetchEligibilitySheet;
