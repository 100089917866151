import createIconComponent from '../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const MedicaidMedicareChip = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      d: 'M27.3299 3.5357C30.5273 -1.17846 37.4729 -1.17846 40.6703 3.5357L41.3857 4.59045C43.1927 7.25466 46.4118 8.58804 49.5734 7.98192L50.8251 7.74196C56.4194 6.66946 61.3307 11.5807 60.2582 17.1751L60.0182 18.4267C59.4121 21.5884 60.7455 24.8075 63.4097 26.6145L64.4645 27.3299C69.1786 30.5273 69.1786 37.4729 64.4645 40.6703L63.4097 41.3857C60.7455 43.1927 59.4121 46.4118 60.0182 49.5734L60.2582 50.8251C61.3307 56.4194 56.4194 61.3307 50.8251 60.2582L49.5734 60.0182C46.4118 59.4121 43.1927 60.7455 41.3857 63.4097L40.6703 64.4645C37.4729 69.1786 30.5273 69.1786 27.3299 64.4645L26.6145 63.4097C24.8075 60.7455 21.5884 59.4121 18.4267 60.0182L17.1751 60.2582C11.5807 61.3307 6.66946 56.4194 7.74196 50.8251L7.98192 49.5734C8.58804 46.4118 7.25466 43.1927 4.59045 41.3857L3.5357 40.6703C-1.17846 37.4729 -1.17846 30.5273 3.5357 27.3299L4.59045 26.6145C7.25466 24.8075 8.58804 21.5884 7.98192 18.4267L7.74196 17.1751C6.66946 11.5807 11.5807 6.66946 17.1751 7.74196L18.4267 7.98192C21.5884 8.58804 24.8075 7.25466 26.6145 4.59045L27.3299 3.5357Z',
      fill: '#03948C',
    },
    {
      type: 'path',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M37.529 22.9179H30.4767V30.4739L22.9179 30.4739V37.5261H30.4767V45.0822H37.529V37.5261H45.0822V30.4739L37.529 30.4739V22.9179Z',
      fill: 'white',
    },
  ],
  defs: {},
  name: 'MedicaidMedicareChip',
  width: '68',
  height: '68',
  viewBox: '0 0 68 68',
});
MedicaidMedicareChip.displayName = 'MedicaidMedicareChip';

/** @deprecated */
export default MedicaidMedicareChip;
