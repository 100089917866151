import gql from 'graphql-tag';
import { IDProofApplicationStatus, IDProofErrorCode, IDProofQuiz, IdentityStatus } from '@types';

export interface ConfirmIdentityResponse {
  ConfirmIdentity: {
    confirmIdentity: {
      status: IDProofApplicationStatus;
      idStatus: IdentityStatus;
      error: IDProofErrorCode;
      retryAfter: any;
      dshReferenceNumber: string;
      questions: IDProofQuiz;
    };
  };
}

export const ConfirmIdentity = gql`
  mutation ConfirmIdentity {
    confirmIdentity {
      status
      idStatus
      error
      retryAfter
      dshReferenceNumber
      questions {
        key
        text
        answers {
          key
          text
        }
      }
    }
  }
`;
