import { colors as constColors } from '../theme/colors';

export function colorElement(props, color, all, colors) {
  const colored = {};
  // Theme colors set inline with the svg data
  if (props.fill && (colors[`${props.fill}Color`] || colors[props.fill])) {
    colored.fill = colors[`${props.fill}Color`] || colors[props.fill];
  }
  if (props.stroke && (colors[`${props.stroke}Color`] || colors[props.stroke])) {
    colored.stroke = colors[`${props.stroke}Color`] || colors[props.stroke];
  }
  if (
    (props.colorable && color && 'fill' in props && props.fill !== 'none') ||
    ('fill' in props && all)
  ) {
    colored.fill = colors[`${color}Color`] || colors[color] || constColors[color] || color;
  }
  if (
    (props.colorable && color && 'stroke' in props && props.fill === 'none') ||
    ('stroke' in props && all)
  ) {
    colored.stroke = colors[`${color}Color`] || colors[color] || constColors[color] || color;
  }
  return colored;
}
