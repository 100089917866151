import { useMemo, useState, useEffect } from 'react';
import { LayoutEvent } from 'react-native';
import { useForm as useReactHookForm } from 'react-hook-form';
import { shouldShowSubmitButton, shouldAutoSubmitForm } from '../utils/formDisplayLogic';
import { FormHookProps, FormHookResponse } from '../types';
import { FieldsConfig, FieldConfig, SectionConfig } from '@app/types';
import { parsePhone } from '../inputs/PhoneInput';

const formatInitialValues = (values: any, fields: FieldsConfig) => {
  let initial = { ...values };

  fields.forEach((field) => {
    if (field.type === 'legalName') {
      const nameValues = field.prefix ? values?.[field.name] : values;

      initial[field.name] = {
        givenName: nameValues?.givenName,
        middleName: nameValues?.middleName,
        familyName: nameValues?.familyName,
        nameSuffix: nameValues?.nameSuffix,
      };
    }

    if (field.type === 'phone' && !!field.subfields) {
      const phoneValues = field.prefix ? values?.[field.name] : values;

      // always default type to mobile if field is required
      const defaultType = field.required ? phoneValues?.type || 'MOBILE' : phoneValues?.type;

      initial[field.name] = {
        number: phoneValues?.number ? parsePhone(phoneValues?.number) : '',
        ext: phoneValues?.ext,
        type: defaultType,
      };
    }

    if (field.type === 'array') {
      const arr = values?.[field.name] || [];
      initial[field.name] = arr.map((val) => {
        return typeof val === 'object' ? { ...val } : val;
      });
    }
  });

  return initial;
};

const formatPayload = (data: any, fields: FieldsConfig) => {
  let payload = { ...data };

  fields.forEach((field) => {
    if (field.type === 'legalName') {
      const prefix = field.prefix ? `${field.name}.` : '';

      payload[`${prefix}givenName`] = data[field.name]?.givenName || undefined;
      payload[`${prefix}middleName`] = data[field.name]?.middleName || undefined;
      payload[`${prefix}familyName`] = data[field.name]?.familyName || undefined;
      payload[`${prefix}nameSuffix`] = data[field.name]?.nameSuffix || undefined;
    }
  });

  return payload;
};

export function useForm<T>(props: FormHookProps<T>): FormHookResponse<T> {
  const [currentField, setCurrentField] = useState<string | undefined>();
  const [currentHelpText, setCurrentHelpText] = useState<string | undefined>();
  const [, setLocations] = useState<{ [fieldID as string]: LayoutEvent['layout'] }>({});
  const [formLocation, setFormLocation] = useState<LayoutEvent['layout']>();

  const setLocation = (fieldID: string, value: LayoutEvent['layout']) => {
    setLocations((prevState) => ({ ...prevState, [fieldID]: value }));
  };

  const layout = formLocation; // for now, all tooltips show at start of form

  // const layout = useMemo(() => {
  //   return currentField ? locations[currentField] : {};
  // }, [currentField, locations]);

  const fields = useMemo(() => {
    return props.fields.reduce((f, el) => {
      if (el.type === 'section') {
        return [...f, ...el.fields];
      } else {
        return [...f, el];
      }
    }, []);
  }, [props.fields]);

  // we need to lookup the config for the current field
  const currentConfig = useMemo(() => {
    let found: FieldConfig | SectionConfig | undefined = undefined;

    fields.forEach((field) => {
      if (field.name === currentField) {
        found = field;
      } else if (
        (field.type === 'array' || field.type === 'person') &&
        currentField?.includes(field.name)
      ) {
        // we'll have to loop over subfields to check
        field.subfields.forEach((subfield) => {
          const regex = new RegExp(`${field.name}.\\d+.${subfield.name}`);

          if (regex.test(currentField)) {
            found = subfield;
          }
        });
      }
    });

    return found;
  }, [currentField, fields]);

  // this prevents rerenders
  const [defaultValues, setDefaultValues] = useState(
    formatInitialValues(props.initialValues, fields),
  );

  const methods = useReactHookForm<T>({
    defaultValues,
    mode: 'onSubmit', // Validation on all blur and change events
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const updateDefaultValues = (values: any) => {
    methods.reset(formatInitialValues(values, fields));
    setDefaultValues(formatInitialValues(values, fields));
  };

  useEffect(() => {
    if (!props.loading) {
      updateDefaultValues(props.initialValues);
    }
  }, [props.loading]);

  useEffect(() => {
    if (
      JSON.stringify(formatInitialValues(props.initialValues, fields)) !==
      JSON.stringify(defaultValues)
    ) {
      updateDefaultValues(props.initialValues);
    }
  }, [props.initialValues, fields]);

  const submitForm = methods.handleSubmit((values) => {
    props.onSubmit(formatPayload(values, fields), { setError: methods.setError });
  });

  // whether to display or hide the submit button
  const isVisibleSubmitButton = useMemo(() => {
    return shouldShowSubmitButton(fields);
  }, [fields]);

  // whether to automatically submit the form on input change
  const autoSubmit = useMemo(() => {
    //check if we're turning off autosubmit for the whole form first
    if (props.autoSubmit !== undefined) return props.autoSubmit;

    return shouldAutoSubmitForm(props.fields);
  }, [props.autoSubmit, props.fields]);

  // don't allow form submission when loading/submitting
  const disableFields = props.disabled;
  const hasErrors = Object.keys(methods.formState.errors).length > 0;
  const disableSubmit = props.disabled || props.loading;

  // triggers onError when we have any error
  useEffect(() => {
    if (hasErrors && props.onError) {
      props.onError(
        Object.keys(methods.formState.errors),
        methods.formState.errors,
        methods.getValues(),
      );
    }
  }, [hasErrors]);

  return {
    methods,
    autoSubmit,
    isVisibleSubmitButton,
    disableSubmit,
    disableFields,
    hideLabels: !!props.hideLabels,

    submitForm,
    hasErrors,

    // for handling locations etc
    setLocation,
    setCurrentField,
    setCurrentHelpText,
    setFormLocation,
    currentConfig,
    currentHelpText,
    layout,
  };
}
