import { gql } from '../generated';

export const AliasQuery = gql(`
  query Alias {
    viewer {
      id
      user {
        id
        phoneNumber
        email
      }
    }
  }
`);
