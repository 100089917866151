import React from 'react';
import { Dialog, FormLabel } from '@uikit';
import { Option } from '@types';
import { InputProps } from '../types';
import { useToggle } from '@app/hooks';
import { SheetBlueprint } from '@app/blueprints';
import { Stack } from '@app/layouts';

interface RowInputProps<T> extends InputProps<T> {
  options: Array<Option>;
  renderRow: (props: { onPress: () => void; value?: T }) => React.ReactNode;
}

const RowInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      name,
      placeholder,
      value,
      renderRow,
      label,
      sublabel,
      help,
      optional,
      required,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      error,
      options,
    }: RowInputProps<T>,
    ref,
  ) => {
    const toggle = useToggle();

    const handleSelect = (value) => {
      onChange(value);
      onBlur();
      toggle.close();
    };

    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
          required={required}
        />

        {renderRow({
          value,
          onPress: toggle.open,
        })}

        <Dialog isOpen={toggle.isOpen} onRequestClose={toggle.close}>
          <SheetBlueprint title={placeholder}>
            <Stack spacing="1">
              {options.map((option) =>
                renderRow({ value: option.value, onPress: () => handleSelect(option.value) }),
              )}
            </Stack>
          </SheetBlueprint>
        </Dialog>
      </>
    );
  },
);

RowInput.displayName = 'RowInput';
export default RowInput;
