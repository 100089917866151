import React from 'react';
import styled from 'styled-components';
import { CatchTheme, UIGradientName } from '@uikit';
import { Color, applyGradient } from '@app/styles';

export interface CardProps {
  testID?: string;
  onPress?: () => void;
  checked?: boolean;
  padded?: boolean;
  border?: boolean;
  shadow?: boolean;
  gradient?: UIGradientName;
  bg?: Color;
  disabled?: boolean;
  children: React.ReactNode;
}

const StyledCard = styled.div<{
  $padded: boolean;
  $pressable: boolean;
  $disabled: boolean;
  $selected: boolean;
  $shadow: boolean;
  $gradient?: UIGradientName;
  $border: boolean;
  $background?: Color;
}>`
  border-radius: ${CatchTheme.corners.lg}px;
  padding: ${({ $padded }) => ($padded ? 20 : 0)}px;
  ${({ $shadow }) => $shadow && `box-shadow: rgba(16, 15, 19, 0.1) 0px 3px 21px;`}
  overflow: hidden;

  border: ${({ $border }) => ($border ? '1.5px solid transparent' : 'none')};

  ${({ theme, $selected }) =>
    $selected &&
    `
    border-color: ${theme.colors.fg};
  `}

  transition: all 150ms;

  ${({ theme, $background }) => $background && `background-color: ${theme.colors[$background]};`}

  ${({ theme, $gradient }) =>
    $gradient && `background-image: ${applyGradient({ $colors: theme.gradients[$gradient] })};`}

  &:hover {
    ${({ $pressable, $disabled }) =>
      $pressable &&
      !$disabled &&
      `
      transition: all 150ms;
      cursor: pointer;
      box-shadow: rgba(16, 15, 19, 0.1) 0px 3px 25px;
    `}
  }
`;

const Card = ({
  testID,
  children,
  gradient,
  checked,
  padded = true,
  shadow = true,
  border = true,
  disabled,
  onPress,
  bg,
}: CardProps) => {
  return (
    <StyledCard
      data-testid={testID}
      $pressable={!!onPress}
      $selected={!!checked}
      $shadow={!!shadow}
      $disabled={!!disabled}
      onClick={disabled ? undefined : onPress}
      disabled={disabled}
      role={onPress ? 'button' : undefined}
      $padded={padded}
      $gradient={gradient}
      $border={!!border}
      $background={bg}
    >
      {children}
    </StyledCard>
  );
};

const Content = styled.div<{ $bg?: Color }>`
  padding: 16px;
  ${({ theme, $bg }) => $bg && `background-color: ${theme.colors[$bg]};`}
`;

Card.Content = Content;
export default Card;
