import styled from 'styled-components';

/**
 * This is a helper function to help replace View
 * It sets all of the default styles that the react native library was setting
 */
export const Block = styled.div`
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0px;
  min-height: 0px;
  min-width: 0px;
  padding: 0px;
  position: relative;
  z-index: 0;
`;
