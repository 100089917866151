import gql from 'graphql-tag';

const CONFIRM_VERIFIED_OFFLINE = gql`
  mutation ConfirmVerifiedOffline($applicantID: ID) {
    confirmVerifiedOffline(id: $applicantID) {
      status
      idStatus
    }
  }
`;

const formatter = (data) => ({
  status: data?.confirmVerifiedOffline?.status,
});

export default {
  document: CONFIRM_VERIFIED_OFFLINE,
  formatter,
};
