import React from 'react';

import { Copy, AssetProps, Action } from '@types';
import { Layout, Stack } from '@layouts';
import { ActionRowProps } from '@uikit/components/Row';
import { Loading, UIAccentColor, s } from '@uikit';
import { BottomActions } from '@common/layouts/BottomActions';

interface Props {
  loading?: boolean;
  precontent?: React.ReactNode;
  title: Copy;
  subtitle?: Copy;
  subtitles?: [Copy | any];
  asset?: AssetProps;
  actions?: Array<Action | ActionRowProps>;
  altRows?: boolean;
  style?: any;
  accentColor?: UIAccentColor;
  actionsContainer?: React.ComponentType<{ children?: any }>;
  headerProps?: { [prop: string]: any };
  layoutProps?: { [prop: string]: any };
  actionsProps?: { [prop: string]: any };
  hasDivider?: boolean;
  actionsPosition?: 'top' | 'bottom';
  children?: any;
  hasBottomActions?: boolean;
}

export const SheetSection = ({ children, altRows }) => {
  // @ts-ignore
  return (
    // @ts-ignore
    <Layout style={[altRows && { paddingHorizontal: 0, paddingVertical: 0 }]}>{children}</Layout>
  );
};

export const SheetBlueprint: React.FC<Props> = ({
  loading,
  precontent,
  title,
  subtitle,
  subtitles,
  asset,
  actions,
  altRows,
  style,
  accentColor,
  // we gather these props so we can compose the layout from a high level
  headerProps = {},
  layoutProps = {},
  actionsProps = {},
  hasDivider,
  actionsPosition,
  hasBottomActions, // for fixing the spacing
  children,
}) => {
  const controls = loading ? (
    <Loading accentColor={accentColor} />
  ) : (
    <BottomActions
      /* @ts-ignore */
      actions={actions}
      {...actionsProps}
    />
  );
  const topActions = actionsPosition === 'top';
  const noBottomSpace = hasBottomActions || !!actions;

  return (
    // @ts-ignore
    <Layout style={[style, noBottomSpace ? {} : s.allSpace3]} color="sheet" {...layoutProps}>
      {/* @ts-ignore */}
      <Stack separatorComponent={hasDivider}>
        {/* @ts-ignore */}
        <Layout margins>
          {precontent}

          {/* @ts-ignore */}
          {(!!title || !!subtitle || !!asset) && (
            <Layout.Header
              // @ts-ignore
              asset={
                !!asset && {
                  ...asset,
                  size: 'lg',
                  // if the asset has a separate container size, we should make sure it fits
                  containerSize:
                    !!asset.containerSize && asset.containerSize !== asset.size ? 'xl' : 'lg',
                  shape: 'circle',
                }
              }
              // @ts-ignore
              title={title}
              titleSize="smol"
              subtitle={subtitle}
              align="center"
              subtitles={subtitles}
              style={[s.topSpace3, s.bottomSpace2]}
              {...headerProps}
            />
          )}
          {topActions && controls}
        </Layout>
        <Stack>
          {!!children && (
            // @ts-ignore
            <>
              {(React.isValidElement(children) || children?.length > 0) && (
                <SheetSection altRows={altRows}>{children}</SheetSection>
              )}
            </>
          )}
          {!topActions && controls}
        </Stack>
      </Stack>
    </Layout>
  );
};

export default SheetBlueprint;
