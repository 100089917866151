import { PlanDetailLayout } from '@common';
import { ScreenDefinition, sheets, useSheet } from '@navigate';

import { NotFoundPage } from '@app/components/404';
import { GoalActions, GoalDetailsTab } from '@app/partial/goals';
import { Route } from '@app/types';
import { getConfigFromSlug, getGoalTypeFromSlug, GOAL_TYPE_TO_COLOR } from '@app/constants/goals';
import { GET_GOAL_BY_SLUG, useFintechQuery } from '@app/data';

const GoalDetail = ({ slug }) => {
  const { close: closeSheet, open: openSheet } = useSheet();

  const { loading, data } = useFintechQuery(GET_GOAL_BY_SLUG, {
    variables: { slug },
    skip: !slug,
  });

  const editPercentage = () => {
    openSheet(sheets.WITHHOLDING_EDIT, { closeSheet, goal: data?.goal });
  };

  // opens sheet with transfer options
  const openTransfer = () => {
    openSheet(sheets.TRANSFER_OPTIONS, { goal: data?.goal });
  };

  const goalType = getGoalTypeFromSlug(slug);
  const color = GOAL_TYPE_TO_COLOR[goalType];

  if (!loading && !data?.goal) {
    return <NotFoundPage />;
  }

  if (!data?.goal) {
    return null;
  }

  return (
    <PlanDetailLayout
      color={color + 'Light'}
      loading={loading}
      title={data?.goal.name || ''}
      goal={data?.goal}
      details={<GoalDetailsTab goal={data?.goal} />}
      controls={
        <GoalActions
          loading={loading}
          percentage={data?.goal.withholdingPercentage}
          editPercentage={editPercentage}
          openTransfer={openTransfer}
        />
      }
    />
  );
};

export const GoalDetailView: ScreenDefinition = {
  name: Route.GOAL_DETAIL,
  component: GoalDetail,
  options: {
    accentColor: ({ slug }) => getConfigFromSlug(slug).color + 'Light',
    drawBehindNav: true,
  },
};
