import gql from 'graphql-tag';
import { Trinary } from '@app/types';

export interface UpsertHealthPrivacyVars {
  input: {
    isPrivacyDataUseAgree: Trinary;
    isPrivacyTruthfulAgree: Trinary;
    isPrivacyAuthorizeAgree: Trinary;
  };
}

export interface UpsertHealthPrivacyResponse {
  upsertHealthPrivacy: {
    id: string;
    isPrivacyDataUseAgree: boolean;
    isPrivacyTruthfulAgree: boolean;
    isPrivacyAuthorizeAgree: boolean;
  };
}

export const UpsertHealthPrivacy = gql`
  mutation UpsertHealthPrivacy($input: HealthPrivacyInput!) {
    upsertHealthPrivacy(input: $input) {
      id
      isPrivacyDataUseAgree
      isPrivacyTruthfulAgree
      isPrivacyAuthorizeAgree
    }
  }
`;
