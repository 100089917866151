import React from 'react';

import { useCopy, goToHealthApp } from '@app/utils';
import { useQuery, HealthApplicationQuery } from '@data';
import { open } from '@navigate';
import { Button, Banner } from '@uikit';
import { Toolbar, BasicLayout } from '@layouts';

const PREFIX = 'catch.ede.CIC.appReviewIntro';

const CICAppReviewIntro = ({ applicationID, change }) => {
  const { c } = useCopy(PREFIX);
  const message = ['BIRTH', 'ADOPTION', 'COVERAGE_GAIN', 'COVERAGE_LOSS'].includes(change);

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  /**
   * when a user is reporting a change, we direct them
   * into the applicants page so that they can make changes there
   */
  const handleChange = () => {
    goToHealthApp(
      {
        ...data?.viewerTwo?.health?.application,
        lastUsedRoute: 'confirm-applicants',
      },
      {
        goTo: open,
      },
    );
  };

  return (
    <BasicLayout
      loading={!data}
      title={c('title')}
      subtitles={[c('subtitle1'), c('subtitle2')]}
      toolbar={
        <Toolbar>
          <Button onPress={handleChange}>{c('next')}</Button>
        </Toolbar>
      }
    >
      {message && <Banner title={c('message')} bg="skeleton" />}
    </BasicLayout>
  );
};

export const CICAppReviewIntroView = {
  name: 'CIC_APP_REVIEW_INTRO',
  component: CICAppReviewIntro,
  options: {},
};
