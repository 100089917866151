import { gql } from '../generated';

export const UpdateNotificationSettings = gql(`
  mutation UpdateNotificationSettings($input: UpdateNotificationSettingsInput!) {
    updateNotificationSettings(input: $input) {
      text {
        healthInsurance
      }
      email {
        myBenefits
        recommendedBenefits
        featureAnnouncements
      }
    }
  }
`);
