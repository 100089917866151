export const formatPayload = ({ applicationID, values }) => {
  const type = values?.taxHouseholdIncomeDifferenceReasonType;

  let payloadMembers;

  if (values.incomeSources) {
    // @todo handle updating each income source
  }

  if (values?.hasDiscrepancy) {
    const taxHouseholdIncomeDifferenceReasonType = type?.includes('HOUSEHOLD_MEMBER_CHANGE')
      ? 'HOUSEHOLD_MEMBER_CHANGE'
      : type?.includes('JOB_CHANGE')
      ? 'JOB_CHANGE'
      : type?.includes('SELF_EMPLOYMENT_INCOME_FLUCTUATION')
      ? 'SELF_EMPLOYMENT_INCOME_FLUCTUATION'
      : type?.includes('OTHER')
      ? 'OTHER'
      : undefined;

    const isIncomeLessExplained =
      taxHouseholdIncomeDifferenceReasonType === 'OTHER'
        ? 'NO'
        : !!taxHouseholdIncomeDifferenceReasonType
        ? 'YES'
        : undefined;

    const taxHouseholdIncomeDiscrepancyDescriptionText =
      values?.taxHouseholdIncomeDiscrepancyDescriptionText;

    payloadMembers = values?.members?.map((member) => {
      const setVariableIncomeDescriptionText =
        member?.annualTaxIncome?.annualIncomeExplanationRequired ||
        values?.householdNeedsAnnualIncomeExplanation;

      return {
        id: member?.id,
        annualTaxIncome: {
          taxHouseholdIncomeDifferenceReasonType,
          taxHouseholdIncomeDiscrepancyDescriptionText,
          isIncomeLessExplained,
          variableIncomeDescriptionText: setVariableIncomeDescriptionText
            ? taxHouseholdIncomeDifferenceReasonType
            : undefined,
        },
      };
    });
  }

  return {
    applicationID,
    applicationMembersInput: payloadMembers,
  };
};
