import React, { useCallback } from 'react';

import { BasicFormBlueprint, SplitFormBlueprint } from '@blueprints';
import {
  mutations,
  useQuery,
  useMutation,
  HealthApplicationQuery,
  SubmitHealthApplicationResponse,
  SubmitHealthApplicationVars,
  updates,
  VoterRegistrationAnswerType,
} from '@data';
import { Segment, SegmentEvent, handleHealthResponse } from '@util';
import { sheets, useSheet } from '@navigate';
import { getInitialValues, formatPayload } from './agreementsUtils';
import { fields, fieldsByState } from './agreementsFields';
import { shallow } from 'zustand/shallow';
import { loaders } from '@app/config';
import { Route } from '@types';

const Agreements = ({ layout, isResubmit, applicationID, coverageState, isGA, handleNext }) => {
  const { openLoader, openVoterInfo } = useSheet(
    (state) => ({
      openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT),
      openVoterInfo: (handleNext) => state.replace(sheets.VOTER_INFO, { handleNext }),
    }),
    shallow,
  );

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  /**
   * Once the application is submitted,
   * either show the user the voter information or continue to elig. results
   */
  const onCompleted = () => {
    const application = data?.viewerTwo?.health?.application;

    if (application?.voterRegistrationAnswerType === VoterRegistrationAnswerType.Yes) {
      openVoterInfo(handleNext);
    } else {
      handleNext();
    }
  };

  const [upsert, { loading: submitting }] = useMutation<
    SubmitHealthApplicationResponse,
    SubmitHealthApplicationVars
  >(mutations.SUBMIT_HEALTH_APPLICATION, {
    ...handleHealthResponse({ onCompleted }),
    update: (cache, result) =>
      updates.ELIG_REFETCHED(cache, {
        didRefetch: true,
        applicationID,
      }),
  });

  const onSubmit = useCallback(
    (values) => {
      openLoader();
      Segment.track(SegmentEvent.APPLICATION_AGREEMENTS_CONFIRMED, {
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
      });
      const preliminaryAPTCStatus =
        data?.viewerTwo?.health?.application?.applicant?.preliminaryAPTCStatus;

      upsert(formatPayload({ applicationID, values, isResubmit, preliminaryAPTCStatus }));
    },
    [applicationID, isResubmit],
  );

  // handles two different layouts
  const Blueprint = layout === 'split' ? SplitFormBlueprint : BasicFormBlueprint;

  return (
    <Blueprint
      title="Agreements"
      loading={!data}
      submitting={submitting}
      formConfig={{
        initialValues: getInitialValues(data?.viewerTwo?.health?.application, isResubmit, isGA),
        fields: coverageState in fieldsByState ? fieldsByState[coverageState] : fields,
        onSubmit,
      }}
    />
  );
};

export const AgreementsView = {
  name: Route.EDE_AGREEMENTS,
  component: (props) => <Agreements {...props} layout="split" />,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Agreements',
  },
};
