import React from 'react';
import { useLayoutContext } from '@app/layouts/LayoutContext';
import { useDebug } from '@app/hooks/useDebug';
import { useTheme } from '../hooks/useTheme';
import Text from './Text';

const TestID = ({ children }) => {
  const { accentColor } = useLayoutContext();
  const { themeColors } = useTheme();
  const { showTestIds } = useDebug();

  if (!showTestIds) return null;

  return (
    <Text size="fp" style={[styles.tag, { backgroundColor: themeColors[`${accentColor}Color`] }]}>
      {children}
    </Text>
  );
};

const styles = {
  tag: {
    borderRadius: 8,
    marginHorizontal: 8,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
};

export default TestID;
