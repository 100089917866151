import React, { useMemo } from 'react';
import { SheetBlueprint } from '@blueprints';
import { UpsertHealthApplication, useMutation } from '@data';
import { useCopy } from '@app/utils';
import { Fields, useForm } from '@app/forms';
import { Layout } from '@app/layouts';

const AppliedAPTCSheet = ({ appliedAmount, maxAmount, applicationID, closeSheet }) => {
  const { c } = useCopy('catch');

  const [upsert, { loading }] = useMutation(UpsertHealthApplication, {
    onCompleted: () => closeSheet(),
  });

  const fields = useMemo(
    () => [
      {
        name: 'amount',
        required: true,
        type: 'amount',
        label: c('basics.amount'),
        amountType: 'decimal',
        max: maxAmount,
      },
    ],
    [maxAmount],
  );

  const form = useForm<{ amount: number }>({
    loading: false,
    disabled: loading,
    initialValues: { amount: appliedAmount },
    fields,
    onSubmit: (values) => {
      upsert({
        variables: {
          input: {
            id: applicationID,
            aptcHouseholdToApply: values?.amount,
          },
        },
      });
    },
  });

  return (
    <SheetBlueprint
      title={c('ede.EligibilityResults.monthlySavingsTitle')}
      subtitle={c('ede.EligibilityResults.monthlySavingsCTA')}
      actions={[
        {
          button: true,
          type: 'CUSTOM',
          label: c('basics.save'),
          onAction: form.submitForm,
          disabled: loading,
        },
        {
          type: 'CUSTOM',
          label: c('basics.cancel'),
          onAction: closeSheet,
          disabled: loading,
        },
      ]}
    >
      <Layout margins>
        <Fields form={form} fields={fields} />
      </Layout>
    </SheetBlueprint>
  );
};

export default AppliedAPTCSheet;
