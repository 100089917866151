import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import {
  CREATE_GOAL,
  CREATE_GOAL_CACHE_UPDATE,
  FilingStatus,
  GET_GOAL_SETUP_DETAILS,
  GetGoalSetupDetailsQuery,
  GoalType,
  UPDATE_GOAL_SETUP_USER,
  UpdateUserInput,
  useFintechMutation,
  useMutation,
  useQuery,
  WorkType,
} from '@data';
import { BasicFormBlueprint } from '@app/blueprints';
import { fields } from '@app/config';
import {
  GOALS_STORAGE_ADDITIONAL_DATA_KEY,
  GOALS_STORAGE_ADDITIONAL_SEEN_KEY,
  GOALS_STORAGE_FLOW_KEY,
  GOALS_STORAGE_TRUE_VALUE,
} from '@app/constants/goals';
import { Storage } from '@app/utils';
import { FieldConfig, Route } from '@types';
import { HandleNextUpdates } from '../GoalSetupStack';

type FormValues = { [key: string]: any };

export interface GoalsSetupInfoProps<T extends FormValues> {
  title: string;
  subtitle?: string;
  fields: FieldConfig[];
  getInitialValues: (data?: GetGoalSetupDetailsQuery) => T;
  formatPayload: (data: T) => UpdateUserInput;
  formatNext?: (data: T) => HandleNextUpdates;
  formatOptimistic?: (
    data: T,
    existing: GetGoalSetupDetailsQuery['me']['user'],
  ) => GetGoalSetupDetailsQuery['me']['user'];
  handleNext: (pass?: HandleNextUpdates) => void;
}

const GoalsSetupInfo = <T extends FormValues>({
  title,
  subtitle,
  fields,
  getInitialValues,
  formatPayload,
  formatOptimistic,
  formatNext,
  handleNext,
}: GoalsSetupInfoProps<T>) => {
  const { loading, data } = useQuery(GET_GOAL_SETUP_DETAILS);
  const [updateUser, { loading: submitting }] = useMutation(UPDATE_GOAL_SETUP_USER);

  const initialValues = useMemo(() => {
    return getInitialValues(data);
  }, [data]);

  const formConfig = {
    initialValues,
    fields,
    onSubmit: (values) => {
      const payload = formatPayload(values);
      // @ts-ignore
      const optimistic = formatOptimistic ? formatOptimistic(values, data?.me?.user) : undefined;
      const nextData = formatNext ? formatNext(values) : undefined;

      updateUser({
        variables: { input: payload },
        optimisticResponse: {
          updateUserNew: {
            // @ts-ignore - not sure how to get this to work properly
            user: {
              ...data?.me?.user,
              ...(optimistic || payload),
              __typename: 'User',
            },
          },
        },
      });

      handleNext(nextData);
    },
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={submitting}
      title={title}
      subtitles={[subtitle]}
      formConfig={formConfig}
    />
  );
};

export const GoalsEmploymentTypeView = {
  name: Route.GOALS,
  component: ({ handleNext }) => {
    Storage.setItem(GOALS_STORAGE_FLOW_KEY, GOALS_STORAGE_TRUE_VALUE);

    // @todo mv view to separate component
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [createGoal] = useFintechMutation(CREATE_GOAL, {
      update: CREATE_GOAL_CACHE_UPDATE,
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      createGoal({ variables: { input: { type: GoalType.TaxSavings } } });
    }, []);

    return (
      <GoalsSetupInfo
        title="Employment Type"
        fields={[fields.EMPLOYMENT_TYPE]}
        getInitialValues={(data) => ({
          workType: data?.me?.user?.workType,
        })}
        formatPayload={(values) => ({
          workType: values?.workType,
        })}
        handleNext={handleNext}
      />
    );
  },
  options: {},
};

export const GoalsEstimatedIncomeView = {
  name: Route.GOALS_ESTIMATED_INCOME,
  component: ({ handleNext }) => (
    <GoalsSetupInfo<{
      workType?: WorkType;
      estimated1099Income?: number;
      estimatedW2Income?: number;
    }>
      title="How much do you earn?"
      fields={[fields.INCOME_1099, fields.INCOME_W2]}
      getInitialValues={(data) => ({
        // @ts-ignore
        workType: data?.me?.user?.workType,
        estimated1099Income: data?.me?.user?.income?.estimated1099Income,
        estimatedW2Income: data?.me?.user?.income?.estimatedW2Income,
      })}
      formatPayload={(values) => ({
        estimated1099Income: values?.estimated1099Income,
        estimatedW2Income: values?.estimatedW2Income,
      })}
      // @ts-ignore
      formatOptimistic={(values, existing) => ({
        income: {
          ...existing?.income,
          estimated1099Income: values?.estimated1099Income,
          estimatedW2Income: values?.estimatedW2Income,
        },
      })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalsWorkStateView = {
  name: Route.GOALS_WORK_STATE,
  component: ({ handleNext }) => (
    <GoalsSetupInfo<{
      workState: string;
    }>
      title="Where do you work?"
      fields={[
        {
          ...fields.WORK_STATE,
          label: 'State',
        },
      ]}
      getInitialValues={(data) => ({
        workState: data?.me?.user?.workState,
      })}
      formatPayload={(values) => ({
        workState: values.workState,
      })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalsTaxFilingStatusView = {
  name: Route.GOALS_TAX_FILING_STATUS,
  component: ({ handleNext }) => (
    <GoalsSetupInfo<{ filingStatus: FilingStatus }>
      title="What’s your tax filing status?"
      fields={[fields.FILING_STATUS]}
      getInitialValues={(data) => ({
        filingStatus: data?.me?.user?.filingStatus,
      })}
      formatPayload={(values) => ({ filingStatus: values.filingStatus })}
      formatNext={(values) => ({ filingStatus: values.filingStatus })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalsSpouseIncomeView = {
  name: Route.GOALS_SPOUSE_INCOME,
  component: ({ handleNext }) => (
    <GoalsSetupInfo<{ spouseIncome: number }>
      title="Spouse Income"
      fields={[fields.SPOUSE_INCOME]}
      getInitialValues={(data) => ({
        spouseIncome: data?.me?.user?.spouseIncome,
      })}
      formatPayload={(values) => ({
        spouseIncome: values.spouseIncome,
      })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalsDependentsView = {
  name: Route.GOALS_DEPENDENTS,
  component: ({ handleNext }) => (
    <GoalsSetupInfo<{ numTaxDependents: number }>
      title="How many tax dependents do you have?"
      fields={[fields.DEPENDENTS]}
      getInitialValues={(data) => ({
        numTaxDependents: data?.me?.user?.numTaxDependents,
      })}
      formatPayload={(values) => ({
        numTaxDependents: values.numTaxDependents,
      })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalsAdditionalView = {
  name: Route.GOALS_ADDITIONAL,
  component: ({ handleNext }) => {
    // @todo mv view to separate component
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [createGoal] = useFintechMutation(CREATE_GOAL, {
      update: CREATE_GOAL_CACHE_UPDATE,
    });

    Storage.setItem(GOALS_STORAGE_ADDITIONAL_SEEN_KEY, GOALS_STORAGE_TRUE_VALUE);

    async function createGoals(goals) {
      for (const goal of goals) {
        await createGoal({ variables: { input: { type: goal } } });
      }
    }

    const formConfig = {
      fields: [fields.GOALS_ADDITIONAL],
      onSubmit: async ({ goals }) => {
        if (isEmpty(goals)) {
          handleNext();
          return;
        }

        await createGoals(goals);

        Storage.setItem(GOALS_STORAGE_ADDITIONAL_DATA_KEY, goals);

        handleNext();
      },
    };

    return (
      <BasicFormBlueprint title="What else do you want to set aside for?" formConfig={formConfig} />
    );
  },
  options: {},
};
