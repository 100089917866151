import React from 'react';
import { SheetBlueprint } from '@blueprints';
import { useCopy } from '@app/utils';

const MissingImmigrationFieldsSheet = ({ onContinue, closeSheet }) => {
  const { c } = useCopy('catch.ede.HealthEnrollment');

  return (
    <SheetBlueprint
      title={c('74_L')}
      subtitle={c('74_Q')}
      actions={[
        {
          type: 'CUSTOM',
          testID: 'continue',
          label: c('74_continue'),
          onAction: () => {
            onContinue();
            closeSheet();
          },
        },
        {
          type: 'CUSTOM',
          testID: 'back',
          label: c('74_back'),
          onAction: closeSheet,
        },
      ]}
    />
  );
};

export default MissingImmigrationFieldsSheet;
