export const ID_PROOF_USER_INFO = [
  {
    description: 'Has importable plans',
    dob: '1979-06-29',
    givenName: 'traci',
    familyName: 'hodkiewicz',
    ssn: 317201436,
    state: 'AL',
    zip: '36106',
    street1: '152 ALEXANDRINE PLAZA',
    city: 'MONTGOMERY',
    phoneNumber: 5555550017,
  },
  {
    description:
      'Generates questions for the consumer in English; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an ACC code.',
    dob: '1971-01-01',
    givenName: 'Harlow',
    middleName: 'A',
    familyName: 'Tateeh',
    suffix: '',
    ssn: 355142952,
    language: 'eng',
    street1: '8 Testing Place',
    city: 'JERICHO',
    state: 'NY',
    zip: 11753,
    zip4: 6311,
    phoneNumber: 5552221001,
  },
  {
    description:
      'Generates questions for the consumer in English; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an RF1 code. Should also test with FARS service.',
    dob: '1962-01-01',
    givenName: 'Briana',
    middleName: '',
    familyName: 'Tayloreh',
    suffix: '',
    ssn: 355185590,
    language: 'eng',
    street1: '78 Testing Ct',
    city: 'APO',
    state: 'AE',
    zip: '09470',
    zip4: '',
    phoneNumber: 5552221007,
  },
  {
    description:
      'Generates questions for the consumer in English; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an RF2 code. Should also test with FARS service.',
    dob: '1986-01-19',
    givenName: 'Camdyn',
    middleName: '',
    familyName: 'Terryeh',
    suffix: 'II',
    ssn: '',
    language: 'eng',
    street1: '114 Testing Circle',
    city: 'CARLISLE',
    state: 'PA',
    zip: 17013,
    zip4: 4774,
    phoneNumber: 5552221008,
  },
  {
    description:
      'Generates questions for the consumer in English; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an RF3 code.',
    dob: '1976-02-16',
    givenName: 'Ariel',
    middleName: '',
    familyName: 'Thomaseh',
    suffix: '',
    ssn: 355201494,
    language: 'eng',
    street1: '129 Testing St',
    city: 'BUTLER',
    state: 'PA',
    zip: 16002,
    zip4: '',
    phoneNumber: 5552221009,
  },
  {
    description:
      'Generates questions for the consumer in English; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an RF4 code.',
    dob: '1980-02-10',
    givenName: 'Peyton',
    middleName: '',
    familyName: 'Thompsoneh',
    suffix: '',
    ssn: 355220694,
    language: 'eng',
    street1: '165 Testing Circle',
    city: 'KILLEEN',
    state: 'TX',
    zip: 76541,
    zip4: 8947,
    phoneNumber: 5552221010,
  },
  {
    description:
      'Generates questions for the consumer in Spanish; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an ACC code.',
    dob: '1957-02-02',
    givenName: 'Virginia',
    middleName: 'E',
    familyName: 'Tannereh',
    suffix: '',
    ssn: 355166491,
    language: 'spa',
    street1: '20 Testing St',
    city: 'BARRE',
    state: 'VT',
    zip: '05641',
    zip4: '',
    phoneNumber: 5552221004,
  },
  {
    description:
      'Generates questions for the consumer in Spanish; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an RF1 code.',
    dob: '1949-05-10',
    givenName: 'Kailee',
    middleName: '',
    familyName: 'Thorntoneh',
    suffix: '',
    ssn: 355266693,
    language: 'spa',
    street1: '455 Testing Avenue',
    city: 'HINGHAM',
    state: 'MA',
    zip: '02043',
    zip4: 7628,
    phoneNumber: 5552221019,
  },
  {
    description:
      'Generates questions for the consumer in Spanish; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an RF2 code. Should also test with FARS service.',
    dob: '1972-02-04',
    givenName: 'Vanessa',
    middleName: '',
    familyName: 'Toddeh',
    suffix: '',
    ssn: '',
    language: 'spa',
    street1: '470 Testing Ct',
    city: 'FLINT',
    state: 'MI',
    zip: 48505,
    zip4: '',
    phoneNumber: 5552221020,
  },
  {
    description:
      'Generates questions for the consumer in Spanish; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an RF3 code.',
    dob: '1983-01-18',
    givenName: 'Isla',
    middleName: '',
    familyName: 'Torreseh',
    suffix: 'IV',
    ssn: '',
    language: 'spa',
    street1: '525 Testing St',
    city: 'STAFFORD',
    state: 'VA',
    zip: 22554,
    zip4: '',
    phoneNumber: 5552221021,
  },
  {
    description:
      'Generates questions for the consumer in Spanish; Primary and Secondary requests generate response code HS000000 - Successful; it will result in an RF4 code.',
    dob: '1964-01-23',
    givenName: 'Lara',
    middleName: 'D',
    familyName: 'Townsenh',
    suffix: '',
    ssn: '',
    language: 'spa',
    street1: '539 Testing Ct',
    city: 'MINOT',
    state: 'ND',
    zip: 58707,
    zip4: '',
    phoneNumber: 5552221022,
  },
  {
    description:
      'HE000050 - Cannot formulate questions for this consumer. Please reference Final Decision Code. (Result in RF1 code). Should also test with FARS service.',
    dob: '1965-02-03',
    givenName: 'Curtis',
    middleName: 'Jason',
    familyName: 'Traneh',
    suffix: 'IV',
    ssn: 355307153,
    language: 'eng',
    street1: '834 Testing Square',
    city: 'IRWIN',
    state: 'PA',
    zip: 15642,
    zip4: 3145,
    phoneNumber: 5552221031,
  },
  {
    description:
      'HE000050 - Cannot formulate questions for this consumer. Please reference Final Decision Code. (Result in RF1 code). Should also test with FARS service.',
    dob: '1997-01-01',
    givenName: 'Harley',
    middleName: 'A',
    familyName: 'Turnereh',
    suffix: '',
    ssn: 355362090,
    language: 'spa',
    street1: '1099 Testing Ave',
    city: 'EMPORIA',
    state: 'KS',
    zip: 66801,
    zip4: '',
    phoneNumber: 5552221043,
  },
  {
    description:
      'HE000050 - Cannot formulate questions for this consumer. Please reference Final Decision Code. (Result in RF1 code). Should also test with FARS service.',
    dob: '1983-10-15',
    givenName: 'Janet',
    middleName: '',
    familyName: 'Hoover',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1206 Carter Hill Rd',
    city: 'Montgomery',
    state: 'AL',
    zip: 36106,
    zip4: 9998,
    phoneNumber: 5555550539,
  },
  {
    description:
      'HE000050 - Cannot formulate questions for this consumer. Please reference Final Decision Code. (Result in RF2 code). Should also test with FARS service.',
    dob: '1996-01-06',
    givenName: 'Jeffrey',
    middleName: 'V',
    familyName: 'Trevinoeh',
    suffix: '',
    ssn: 355307591,
    language: 'eng',
    street1: '844 Testing Avenue',
    city: 'POUGHKEEPSIE',
    state: 'NY',
    zip: 12601,
    zip4: 5068,
    phoneNumber: 5552221032,
  },
  {
    description:
      'HE000050 - Cannot formulate questions for this consumer. Please reference Final Decision Code. (Result in RF2 code). Should also test with FARS service.',
    dob: '1952-05-04',
    givenName: 'Maxim',
    middleName: 'Skye',
    familyName: 'Tylereh',
    suffix: 'II',
    ssn: 355364252,
    language: 'spa',
    street1: '1118 Testing Cir',
    city: 'REDDING',
    state: 'CA',
    zip: 96003,
    zip4: 6931,
    phoneNumber: 5552221044,
  },
  {
    description:
      'HE000050 - Cannot formulate questions for this consumer. Please reference Final Decision Code. (Result in RF3 code).',
    dob: '1989-01-01',
    givenName: 'Bailey',
    middleName: 'Elizabeth',
    familyName: 'Trujilloeh',
    suffix: 'JR',
    ssn: '',
    language: 'eng',
    street1: '857 Testing Place',
    city: 'OAK PARK',
    state: 'IL',
    zip: 60302,
    zip4: '',
    phoneNumber: 5552221033,
  },
  {
    description:
      'HE000050 - Cannot formulate questions for this consumer. Please reference Final Decision Code. (Result in RF3 code).',
    dob: '1959-01-07',
    givenName: 'Catherine',
    middleName: '',
    familyName: 'Talleyax',
    suffix: '',
    ssn: '',
    language: 'spa',
    street1: '1128 Testing Drive',
    city: 'BATAVIA',
    state: 'OH',
    zip: 45103,
    zip4: 3574,
    phoneNumber: 5552221045,
  },
  {
    description:
      'HE000050 - Cannot formulate questions for this consumer. Please reference Final Decision Code. (Result in RF4 code).',
    dob: '1988-01-02',
    givenName: 'Dane',
    middleName: 'H',
    familyName: 'Tuckereh',
    suffix: 'SR',
    ssn: '',
    language: 'eng',
    street1: '867 Testing Court',
    city: 'SAINT LOUIS',
    state: 'MI',
    zip: 48880,
    zip4: 5058,
    phoneNumber: 5552221034,
  },
  {
    description:
      'HE000050 - Cannot formulate questions for this consumer. Please reference Final Decision Code. (Result in RF4 code).',
    dob: '1977-01-13',
    givenName: 'Mae',
    middleName: '',
    familyName: 'Travisax',
    suffix: 'IV',
    ssn: '',
    language: 'spa',
    street1: '1182 Testing St',
    city: 'CLEVELAND',
    state: 'OH',
    zip: 44112,
    zip4: '',
    phoneNumber: 5552221046,
  },
  {
    description: 'HE009999 - Unexpected Response Code. (002)',
    dob: '1989-02-06',
    givenName: 'Janessa',
    middleName: 'A',
    familyName: 'Tysonax',
    suffix: 'SR',
    ssn: '',
    language: 'eng',
    street1: '1267 Testing Street',
    city: 'BALTIMORE',
    state: 'MD',
    zip: 21223,
    zip4: '',
    phoneNumber: '555-222-1055',
  },
  {
    description: 'HE200024 - State legislation requires match on more identification information',
    dob: '1963-03-02',
    givenName: 'Antoine',
    middleName: '',
    familyName: 'Thomasam',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1647 Testing Court',
    city: 'FRESNO',
    state: 'CA',
    zip: 93711,
    zip4: '',
    phoneNumber: '555-222-1070',
  },
  {
    description:
      'HE200025 - Suffix is required to access consumer file, have consumer resubmit with name suffix',
    dob: '1964-02-18',
    givenName: 'Christina',
    middleName: '',
    familyName: 'Trimacbe',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1659 Testing Circle',
    city: 'GARDENA',
    state: 'CA',
    zip: 90249,
    zip4: 6695,
    phoneNumber: '555-222-1071',
  },
  {
    description:
      'HE200026 - Date of Birth required to access consumer, have consumer resubmit with DOB',
    dob: '',
    givenName: 'Benton',
    middleName: '',
    familyName: 'Tethcoteacbf',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1691 Testing Drive',
    city: 'SUGAR LAND',
    state: 'TX',
    zip: 77478,
    zip4: 4736,
    phoneNumber: '555-222-1072',
  },
  {
    description:
      'HE200027 - Middle name required to access file, have consumer resubmit including Middle Name',
    dob: '1966-02-19',
    givenName: 'Daniel',
    middleName: '',
    familyName: 'Thomeacbf',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1696 Testing Circle',
    city: 'CHARLESTON',
    state: 'WV',
    zip: 25311,
    zip4: 5041,
    phoneNumber: '555-222-1073',
  },
  {
    description: 'HE200028 - Invalid street address',
    dob: '1967-02-15',
    givenName: 'Brayden',
    middleName: '',
    familyName: 'Toogoodacbf',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1699 Testing Square',
    city: 'CAMDEN',
    state: 'ME',
    zip: '04843',
    zip4: 8497,
    phoneNumber: '555-222-1074',
  },
  {
    description: 'HE200029 - Consumer is a minor, manual verification required',
    dob: '2002-02-26',
    givenName: 'Gracelynn',
    middleName: 'A',
    familyName: 'Torresju',
    suffix: '',
    ssn: 355168092,
    language: 'eng',
    street1: '1322 Testing Avenue',
    city: 'HOUSTON',
    state: 'TX',
    zip: 77065,
    zip4: '',
    phoneNumber: '555-222-1056',
  },
  {
    description:
      'HE200030 - Information on the inquiry was reported as fraud by the  consumer, manual verification is required',
    dob: '1958-01-19',
    givenName: 'Lilia',
    middleName: '',
    familyName: 'Taylorau',
    suffix: '',
    ssn: 355168950,
    language: 'eng',
    street1: '1329 Testing Ct',
    city: 'ASPEN',
    state: 'CO',
    zip: 81611,
    zip4: '',
    phoneNumber: '555-222-1057',
  },
  {
    description: 'HE200031 - Invalid surname or less than two characters in length',
    dob: '1971-02-26',
    givenName: 'Nahla',
    middleName: 'Shelby',
    familyName: 'X',
    suffix: '',
    ssn: 355185590,
    language: 'eng',
    street1: '1363 Testing Place',
    city: 'TAMPA',
    state: 'FL',
    zip: 33625,
    zip4: 4771,
    phoneNumber: '555-222-1058',
  },
  {
    description: 'HE200037 - SSN required to access consumer’s file, have consumer input SSN',
    dob: '1957-03-23',
    givenName: 'Jace',
    middleName: '',
    familyName: 'Turnernov',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1493 Testing Circle',
    city: 'COTTONWOOD',
    state: 'AZ',
    zip: 86326,
    zip4: 6369,
    phoneNumber: '555-222-1064',
  },
  {
    description:
      'HE200038 - Unable to standardize current address, have consumer verify address with official documentation',
    dob: '1958-02-05',
    givenName: 'Clara',
    middleName: 'E',
    familyName: 'Torresec',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1551 Testing Dr',
    city: 'LANSING',
    state: 'MI',
    zip: 48912,
    zip4: '',
    phoneNumber: '555-222-1065',
  },
  {
    description:
      'HE200039 - Current Address exceeds maximum length, have consumer verify address with official documentation',
    dob: '1959-03-03',
    givenName: 'Emerson',
    middleName: '',
    familyName: 'Turnerec',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1559 Testing Dr',
    city: 'KULPMONT',
    state: 'PA',
    zip: 17834,
    zip4: '',
    phoneNumber: '555-222-1066',
  },
  {
    description: 'HE200040 - Input validation error, verify data validation rules',
    dob: '1960-03-12',
    givenName: 'Kaelyn',
    middleName: '',
    familyName: 'Thompsonafe',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1575 Testing Court',
    city: 'SANTA MARIA',
    state: 'CA',
    zip: 93455,
    zip4: 3056,
    phoneNumber: '555-222-1067',
  },
  {
    description: 'HE200041 - Session timeout, please retry',
    dob: '1961-03-16',
    givenName: 'Jayde',
    middleName: '',
    familyName: 'Turnerafe',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1604 Testing Drive',
    city: 'TYLER',
    state: 'TX',
    zip: 75707,
    zip4: '',
    phoneNumber: '555-222-1068',
  },
  {
    description:
      'HE200042 - Other Precise ID system error, have consumer retry using prior address',
    dob: '1962-01-27',
    givenName: 'Nova',
    middleName: '',
    familyName: 'Tayloram',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1639 Testing Square',
    city: 'FORT SCOTT',
    state: 'KS',
    zip: 66701,
    zip4: '',
    phoneNumber: '555-222-1069',
  },
  {
    description: 'HX005001 - Unexpected Exception Occurred at Trusted Data Source',
    dob: '1968-01-26',
    givenName: 'Reuben',
    middleName: 'Gideon',
    familyName: 'Topleyacbf',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1732 Testing Dr',
    city: 'MILTON',
    state: 'WI',
    zip: 53563,
    zip4: '',
    phoneNumber: '555-222-1075',
  },
  {
    description: 'HX005001 - Unexpected Exception Occurred at Trusted Data Source',
    dob: '1969-02-14',
    givenName: 'Dangelo',
    middleName: '',
    familyName: 'Tunnickacbf',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1755 Testing Dr',
    city: 'HANOVER PARK',
    state: 'IL',
    zip: 60103,
    zip4: 7020,
    phoneNumber: '555-222-1076',
  },
  {
    description: 'HX005001 - Unexpected Exception Occurred at Trusted Data Source',
    dob: '1970-01-25',
    givenName: 'Daniel',
    middleName: '',
    familyName: 'Thatcher',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1778 Testing Cir',
    city: 'HOLLIDAYSBURG',
    state: 'PA',
    zip: 16648,
    zip4: '',
    phoneNumber: '555-222-1077',
  },
  {
    description:
      'HX005001 - Unexpected Exception Occurred at Trusted Data Source. Should also test with FARS service.',
    dob: '1983-10-15',
    givenName: 'Donald',
    middleName: '',
    familyName: 'Cooke',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1206 Carter Hill Rd',
    city: 'Montgomery',
    state: 'AL',
    zip: 36106,
    zip4: 9998,
    phoneNumber: 5555550566,
  },
  {
    description:
      'HX200004 - One or more requested reports unavailable at this time. Please resubmit later',
    dob: '1969-01-20',
    givenName: 'Jemma',
    middleName: '',
    familyName: 'Torresau',
    suffix: 'SR',
    ssn: '',
    language: 'eng',
    street1: '1393 Testing Cir',
    city: 'WOODBRIDGE',
    state: 'VA',
    zip: 22192,
    zip4: '',
    phoneNumber: '555-222-1059',
  },
  {
    description:
      'HX200005 - Components of checkpoint system temporarily unavailable. Please resubmit',
    dob: '1985-02-06',
    givenName: 'Misael',
    middleName: '',
    familyName: 'Thompsonno',
    suffix: '',
    ssn: 355201494,
    language: 'eng',
    street1: '1395 Testing Dr',
    city: 'WASHINGTON',
    state: 'DC',
    zip: 20003,
    zip4: '',
    phoneNumber: '555-222-1060',
  },
  {
    description: 'HX200008 - Not all data available for Experian Detect evaluation',
    dob: '1954-07-05',
    givenName: 'Brenton',
    middleName: '',
    familyName: 'Thomasnov',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1420 Testing Place',
    city: 'PHOENIXVILLE',
    state: 'PA',
    zip: 19460,
    zip4: 8526,
    phoneNumber: '555-222-1061',
  },
  {
    description: 'HX200009 - Experian Detect temporarily unavailable, please resubmit later',
    dob: '1955-03-09',
    givenName: 'Angelo',
    middleName: 'A',
    familyName: 'Torresnov',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1433 Testing Street',
    city: 'SOUTH CHARLESTON',
    state: 'WV',
    zip: 25309,
    zip4: '',
    phoneNumber: '555-222-1062',
  },
  {
    description: 'HX200010 - Precise ID system temporarily unavailable, please resubmit later',
    dob: '1956-04-14',
    givenName: 'Joanna',
    middleName: 'E',
    familyName: 'Tuckernov',
    suffix: '',
    ssn: '',
    language: 'eng',
    street1: '1482 Testing Avenue',
    city: 'INDIANAPOLIS',
    state: 'IN',
    zip: 46219,
    zip4: '',
    phoneNumber: '555-222-1063',
  },
];
