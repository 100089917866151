import { ApolloApplicationMember } from '@app/data';
import { Trinary } from '@app/types';
import { boolToTri, getFullName } from '@util';

interface OutsideHouseholdFormData {
  members: Array<{
    id: string;
    hasAbsentParent: boolean;
    fullName: string;
  }>;
}

type MemberPayload = Array<{ id: string; hasAbsentParent: Trinary }>;

export const filterMembers = (members) => {
  // only show CHILD applicants who have a positive prelim medicaid status as options for this form
  return members?.filter(
    (mem) =>
      (mem.preliminaryMedicaidStatus === 'YES' ||
        mem.preliminaryEmergencyMedicaidStatus === 'YES') &&
      mem.relation === 'CHILD',
  );
};

export const getInitialValues = (
  members: Array<ApolloApplicationMember>,
): OutsideHouseholdFormData => {
  const childrenOnly = filterMembers(members);

  return {
    members: childrenOnly.map((member) => ({
      id: member.id,
      hasAbsentParent: member.hasAbsentParent,
      fullName: getFullName(member),
    })),
  };
};

export const formatPayload = (values: OutsideHouseholdFormData): MemberPayload => {
  return values.members.map((m) => ({
    id: m.id,
    hasAbsentParent: boolToTri(m.hasAbsentParent),
  }));
};
