import { Action } from '../types/prompts';
import { open, links } from '@navigate';

import Edit from '@svg/edit.svg';
import Swap from '@svg/swap.svg';
import Pause from '@svg/pause.svg';
import Percent from '@svg/percentage.svg';
import Payment from '@svg/arrow-up-right.svg';
import Chart from '@svg/pie-chart.svg';
import Minus from '@svg/minus-circle.svg';
import Minus2 from '@svg/minus.svg';
import Bolt from '@svg/bolt.svg';
import Plus from '@svg/plus.svg';
import Book from '@svg/book.svg';
import Help from '@svg/help-circle.svg';
import File from '@svg/file-text.svg';
import { Route } from '@app/types';

export const Actions: Record<string, Action> = {
  DISMISS: {
    id: 'dismiss',
    label: 'Dismiss',
    type: 'CUSTOM',
    glyph: Minus2,
  },
  ARTICLE: {
    id: 'article',
    label: 'How to',
    type: 'CUSTOM',
    glyph: Book,
  },
  GUIDE: {
    id: 'guide',
    label: 'Learn more',
    type: 'CUSTOM',
    glyph: Book,
  },
  TAX_RECOMMENDATION: {
    id: 'article',
    label: 'Tax recommendation',
    type: 'CUSTOM',
    glyph: Chart,
  },
  DEV_PAGE: {
    type: 'CUSTOM',
    id: 'dev',
    label: 'Dev Page',
  },
  DEBUG_LAYOUT: {
    type: 'CUSTOM',
    id: 'debug-layout',
    label: 'Debug Layout',
  },
  DEBUG_TESTIDS: {
    type: 'CUSTOM',
    id: 'debug-testids',
    label: 'Test IDs',
  },
  DEBUG_MAKE_PAYCHECKS: {
    type: 'CUSTOM',
    id: 'debug-make-paychecks',
    label: 'Make Paychecks',
  },
  EDIT_WITHHOLDING: {
    type: 'CUSTOM',
    id: 'edit-withholding',
    label: 'Edit withholding',
    onAction: () => console.log(),
    glyph: Percent,
  },
  CUSTOMIZE_GOAL: {
    type: 'CUSTOM',
    id: 'customize-goal',
    label: 'Customize',
    onAction: () => console.log(),
    glyph: Edit,
  },
  TRANSFER: {
    type: 'CUSTOM',
    id: 'transfer',
    label: 'Transfer money',
    glyph: Swap,
    onAction: () => console.log(),
  },
  TAX_PAYMENT: {
    type: 'CUSTOM',
    id: 'tax-payment',
    label: 'Manually pay taxes',
    glyph: Payment,
    onAction: () => console.log(),
  },
  AUTO_TAX_PAYMENT: {
    type: 'CUSTOM',
    id: 'auto-tax-payment',
    label: 'Auto pay taxes',
    interaction: 'switch',
    glyph: Bolt,
  },
  PAUSE_RESUME_GOAL: {
    type: 'CUSTOM',
    id: 'pause-resume-goal',
    label: true ? 'Pause' : 'Resume',
    glyph: Pause,
    onAction: () => console.log(),
  },
  REMOVE_GOAL: {
    type: 'CUSTOM',
    id: 'remove-goal',
    label: 'Remove',
    glyph: Minus,
    destructive: true,
    onAction: () => console.log(),
  },
  HELP: {
    type: 'HELP',
    id: 'help',
    label: 'Support',
    glyph: Help,
  },
  HELP_DESK: {
    type: 'LINK',
    id: 'help-desk',
    label: 'Help Center',
    glyph: Help,
    link: links.HELP_DESK,
  },
  GUIDES: {
    type: 'LINK',
    id: 'guides',
    label: 'Guides',
    link: links.GUIDES,
  },
  ABOUT: {
    type: 'LINK',
    id: 'about-catch',
    label: 'About',
    link: links.ABOUT_CATCH,
  },
  TOGGLE_LANGUAGE: {
    type: 'TOGGLE_LANGUAGE',
    id: 'toggle-language',
    label: 'Toggle language',
  },
  REFRESH: {
    type: 'ROUTE',
    id: 'refresh',
    label: 'Refresh',
    onAction: () => open('PLAN'),
  },
  ADD_TO_PLAN: {
    type: 'CUSTOM',
    id: 'add-to-plan',
    label: 'Add',
    glyph: Plus,
    onAction: () => {},
  },
  REPORT_CHANGE: {
    type: 'ROUTE',
    id: 'cic',
    label: 'Report change',
    glyph: Edit,
    route: Route.CIC_CHANGE_SELECTION,
  },
  GET_EDN: {
    id: 'view-notice',
    label: 'View eligibility notice',
    type: 'CUSTOM',
    glyph: File,
  },
  MANAGE_COVERAGE: {
    id: 'manage-coverage',
    label: 'Manage coverage',
    type: 'CUSTOM',
    glyph: Edit,
  },
  CHANGE_PORTFOLIO: {
    id: 'change-portfolio',
    label: 'Change portfolio',
    type: 'CUSTOM',
    glyph: Chart,
  },
};
