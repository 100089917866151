import { shadows } from './_constants';
import { colors } from './colors';
import { theme } from '@app/_ui-kit/themes/catch';
import { colorgen } from './_colorgen';

const t = theme;

export const elements = (themeColors) => ({
  ...colorgen(themeColors, colors),
  /** ALIGN */
  alignCenter: { width: '100%', alignItems: 'center' }, // Single Column Fixed Layout
  alignBaseline: { width: '100%', alignItems: 'baseline' },
  alignStretch: { width: '100%', alignItems: 'stretch' },
  centerSelf: { alignSelf: 'center' },
  auto: { width: 'auto' },

  /** WIDTH */
  singleColumn: { width: t.containers.dialog },
  page: { maxWidth: t.containers.page, width: '100%' },
  miniPage: { maxWidth: t.containers.miniPage, width: '100%' },
  pMax: { maxWidth: t.containers.formSheet, width: '100%' },
  cardMax: { maxWidth: t.containers.dialog, width: '100%' },
  pMin: { minWidth: t.containers.dialog },

  _hover: { filter: themeColors.pageColor === '#FFFFFF' ? 'brightness(1.02)' : 'brightness(1.15)' },

  // MODALS
  formSheet: {
    width: t.containers.formSheet,
    height: t.containers.pageSheet,
    maxHeight: '90%',
  },
  basicSheet: {
    width: t.containers.largeSheet,
    height: t.containers.basic,
    maxHeight: '98%',
  },
  pageSheet: {
    width: t.containers.pageSheet,
    height: t.containers.page,
    maxHeight: '90%',
  },
  actionSheet: {
    width: t.containers.dialog,
    maxHeight: 520,
  },
  largeSheet: {
    width: t.containers.largeSheet,
    height: t.containers.basic,
    maxHeight: '90%',
  },
  dialogSheet: {
    width: t.containers.dialog,
    height: 312,
    maxHeight: '100%',
  },
  tabular: {
    fontVariant: ['tabular-nums'],
  },
  technical: {
    fontFeatureSettings: `'ss01' 1, 'ss02' 1, 'tnum' 1`,
  },
  strikethrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  border: {
    borderWidth: t.constants.borderWidth,
    borderStyle: 'solid',
  },
  borderColor: {
    borderWidth: t.constants.borderWidth,
    borderStyle: 'solid',
    borderColor: themeColors.borderColor,
  },

  /**
   * Base layouts
   */
  toolbar: {
    width: '100%',
    borderTopWidth: t.constants.borderWidth,
    borderTopColor: themeColors.borderColor,
    bottom: 0,
  },
  sheetToolbar: {
    paddingTop: 32,
    borderTopRightRadius: t.corners.lg,
    borderTopLeftRadius: t.corners.lg,
    ...shadows.card,
  },
  sheetHeader: {
    paddingTop: 24,
    paddingHorizontal: 12,
  },
  sheetLayout: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },

  /** ADDING BORDERS */
  dividerHorizontal: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: themeColors.borderColor,
  },
  topDivider: {
    width: '100%',
    borderTopWidth: t.constants.borderWidth,
    borderTopColor: themeColors.borderColor,
  },
  bottomDivider: {
    width: '100%',
    borderBottomWidth: t.constants.borderWidth,
    borderBottomColor: themeColors.borderColor,
  },
  bottomDividerLight: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: themeColors.borderLightColor,
  },

  disabledIncome: {
    opacity: 0.1,
    filter: `blur(2px)`,
  },

  /**
   * Backgrounds
   */
  acrylicBg: {
    backgroundColor: themeColors.acrylicColor,
    backdropFilter: `blur(${t.constants.modalBlur}px)`,
  },
  acrylic: {
    backgroundColor: themeColors.acrylicColor,
    ...shadows.deep,
    backdropFilter: `blur(${t.constants.modalBlur * 2}px)`,
  },
  webpop: {
    position: 'absolute',
    marginTop: 63,
    marginRight: 24,
    right: 0,
    minWidth: 200,
    maxHeight: '85%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1.5,
    borderColor: themeColors.borderColor,
    transformOrigin: 'top right',
    userSelect: 'none',
  },

  sticky: {
    position: 'sticky',
    top: 24,
  },

  mono: {
    fontVariant: ['tabular-nums'],
  },

  /** BUTTONS */
  buttonContainer: {
    alignItems: 'flex-start',
    alignSelf: 'auto',
  },
  button: {
    paddingHorizontal: 24,
    paddingVertical: 0,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 44,
    lineHeight: 44,
    transitionProperty: 'transform',
    transitionDuration: `50ms`,
    transitionTimingFunction: 'ease-out',
  },
  buttonIconOnly: {
    height: 44,
    width: 44,
    paddingHorizontal: 0,
  },
  buttonHover: {
    transform: [
      {
        scale: 0.98,
      },
    ],
  },
  buttonActive: {
    transform: [
      {
        scale: 0.96,
      },
    ],
  },
  buttonMini: {
    paddingHorizontal: 12,
    height: 28,
  },
  buttonMiniIconOnly: {
    height: 36,
    width: 36,
  },
  buttonSmall: {
    paddingHorizontal: 20,
    height: 42,
  },
  buttonSmallIconOnly: {
    height: 42,
    width: 42,
  },
  buttonLarge: {
    paddingHorizontal: 24,
  },
  buttonLargeIconOnly: {
    height: 44,
    width: 44,
  },
  buttonOutline: {
    borderWidth: 2,
    borderStyle: 'solid',
  },

  // pressable
  pressedText: { opacity: 0.7 },
  margins: {
    paddingHorizontal: t.spacing.mobile.margins,
  },
  detailMargins: {
    paddingHorizontal: t.spacing.mobile.margins * 2.5,
  },
  optionButton: {
    padding: 16,
    backgroundColor: themeColors.skeletonColor,
    borderWidth: t.constants.borderWidth,
    borderColor: 'transparent',
  },
  optionButtonSmall: {
    paddingVertical: 8,
  },
  optionHover: {
    backgroundColor: themeColors.bgGrayColor,
  },
  optionSelected: {
    borderColor: themeColors.textColor,
    borderWidth: t.constants.borderWidth,
    backgroundColor: 'transparent',
  },

  /** RADIO */
  radio: {
    width: t.constants.checkRadioSize,
    height: t.constants.checkRadioSize,
    borderWidth: t.constants.borderWidth,
    borderRadius: t.constants.checkRadioSize,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: themeColors.textSubtleColor,
    backgroundColor: themeColors.pageColor,
  },
  radioControl: {
    width: t.constants.checkRadioSize - t.constants.borderWidth * 4,
    height: t.constants.checkRadioSize - t.constants.borderWidth * 4,
    lineHeight: 10,
    opacity: 0,
    borderRadius: 1994,
    backgroundColor: themeColors.textColor,
  },
  smallRadio: { width: 16, height: 16 },
  smallRadioControl: { width: 8, height: 8 },
  radioControlChecked: { opacity: 1 },

  radioOutline: {
    borderRadius: 1994,
    padding: 2,
    borderWidth: 2,
    borderColor: 'transparent',
  },
  radioContainer: { flexDirection: 'row', alignItems: 'center' },
  radioLabel: { marginLeft: 4 },

  /** CHECKBOX */
  checkboxContainer: {
    borderRadius: 8,
    alignItems: 'center',
    padding: 2,
    borderWidth: 2,
    borderColor: 'transparent',
  },
  checkbox: {
    height: t.constants.checkRadioSize,
    width: t.constants.checkRadioSize,
    borderWidth: t.constants.borderWidth,
    borderColor: themeColors.textColor,
    borderRadius: 6,
    backgroundColor: themeColors.skeletonColor,
  },

  checkboxControl: {
    position: 'absolute',
    top: 6,
    left: 6,
    height: 6,
    width: 10,
    borderLeftWidth: 2,
    borderLeftColor: themeColors.pageColor,
    borderBottomWidth: 2,
    borderBottomColor: themeColors.pageColor,
    transform: [{ translateY: 1 }, { rotate: '-45deg' }],
    opacity: 0,
  },
  checkboxChecked: { backgroundColor: themeColors.textColor },
  checkboxControlChecked: { opacity: 1 },
  checkboxSmall: { height: 16, width: 16 },
  checkboxControlSmall: { top: 5, left: 5 },
  checkboxLabel: { marginLeft: 8 },

  /** STEPPER */
  stepperGauge: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 12,
    marginRight: 12,
    textAlign: 'center',
    userSelect: 'none',
  },
  stepperGaugeLg: {
    marginLeft: 32,
    marginRight: 32,
  },

  /** FIELDS */
  searchField: {
    backgroundColor: themeColors.bg2Color,
    borderColor: 'transparent',
  },
  groupedField: {
    marginBottom: 4,
  },
  textField: {
    backgroundColor: themeColors.bg2Color,
    borderColor: 'transparent',
    color: themeColors.textColor,
    fontFamily: t.font.face.Body,
  },
  textFieldBase: {
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: t.constants.borderWidth,
    borderRadius: t.corners.sm,
    borderStyle: 'solid',
    fontSize: t.font.size.Button,
    fontFamily: t.font.face.Body,
    fontWeight: '400',
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: themeColors.bg2Color,
    borderColor: 'transparent',
    color: themeColors.textColor,
    display: 'flex',
    alignItems: 'center',
    transition: '100ms',
  },
  textFieldFocused: { borderColor: themeColors.subtleColor, backgroundColor: 'transparent' },
  textFieldError: { borderColor: themeColors.creditColor },
  textFieldContainer: { alignItems: 'flex-start', alignSelf: 'baseline' },
  textFieldLarge: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    fontSize: t.font.size.FormTitle,
    textAlign: 'center',
    fontWeight: '500',
    borderRadius: t.corners.md,
  },
  textFieldXl: {
    fontSize: t.font.size.XL,
    textAlign: 'center',
    fontWeight: '500',
    borderRadius: t.corners.md,
    borderColor: 'transparent',
    borderWidth: 0,
    backgroundColor: themeColors.skeletonColor,
  },
  textFieldSpaced: {
    letterSpacing: 4,
    textAlign: 'center',
  },
  textFieldSpecial: {
    borderColor: 'transparent',
  },
  hiddenTextField: {
    paddingRight: 54,
  },
  textFieldIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    lineHeight: 1,
    justifyContent: 'center',
    paddingRight: 12,
  },

  /** CARD */
  card: {
    backgroundColor: themeColors.cardColor,
    borderColor: themeColors.borderColor,
    borderWidth: 2,
    overflow: 'hidden', //makes sure content inside border radius
  },
  track: {
    height: 3,
    overflow: 'hidden',
    borderRadius: t.corners.lg,
    backgroundColor: themeColors.borderColor,
  },
  buttonShadow: {
    shadowColor: themeColors.textColor,
    ...shadows.button,
  },
  cardShadow: {
    shadowColor: 'black',
    ...shadows.card,
  },
  deepShadow: {
    shadowColor: themeColors.textColor,
    ...shadows.deep,
  },
  screenShadow: {
    shadowColor: themeColors.textColor,
    ...shadows.screen,
  },
  cardPadding: {
    padding: t.spacing.desktop.paddingY,
  },

  /** CORNERS */
  roundCorners: {
    borderRadius: t.corners.sm,
  },
  circleCorners: {
    borderRadius: t.corners.lg,
  },
  circle: {
    borderRadius: t.corners.lg,
  },
  smallCorners: {
    borderRadius: t.corners.sm,
  },
  midCorners: {
    borderRadius: t.corners.md,
  },
  mdCorners: {
    borderRadius: t.corners.md,
  },
  mediumCorners: {
    borderRadius: t.corners.md,
  },
  largeCorners: {
    borderRadius: t.corners.lg,
  },
  xlargeCorners: {
    borderRadius: t.corners.round,
  },
  plusCorners: {
    borderRadius: t.corners.round,
  },

  bannerCorners: {
    borderRadius: t.corners.round,
  },

  noRadiusLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  noRadius: {
    borderRadius: 0,
  },
  noRadiusRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  flatBottom: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  /** BORDERS */
  noBorderLeft: {
    borderLeftWidth: 0,
  },
  noBorderRight: {
    borderRightWidth: 0,
  },
  noBorder: {
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
  },

  shortField: {
    width: 83,
  },
  majorShadow: {
    shadowColor: themeColors.textColor,
    ...shadows.major,
  },
  mediumShadow: {
    shadowColor: themeColors.textColor,
    ...shadows.medium,
  },
  navShadow: {
    shadowColor: themeColors.textColor,
    ...shadows.nav,
  },
  maxWidthSheet: {
    maxWidth: t.containers.formSheet,
  },
  touchableField: {
    paddingTop: 16,
    paddingBottom: 16,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    flex: 1,
  },
  defaultCursor: {
    cursor: 'default',
  },
  pickerField: {
    appearance: 'none',
    cursor: 'pointer',
    paddingRight: 56,
    minWidth: 78,
  },
  pickerFieldIcon: {
    position: 'absolute',
    right: 8,
    pointerEvents: 'none',
  },
  pickerFieldContainer: {},

  fieldWrapper: {
    alignItems: 'flex-start',
  },

  modalOverlay: {
    zIndex: 1001,
    backgroundColor: themeColors.veilColor,
    backdropFilter: 'blur(25px)',
  },
  fixedOverlay: {
    position: 'fixed',
    top: 64,
    right: 0,
    left: 0,
    bottom: 0,
  },
  promptOverlay: {
    zIndex: 1002,
    backgroundColor: themeColors.veilColor,
  },
  modalContent: {
    backgroundColor: themeColors.cardColor,
  },
  flag: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: t.corners.sm,
  },
  popMenuWeb: {
    ...shadows.deep,
    borderRadius: t.corners.lg,
    backdropFilter: 'blur(50px)',
    overflow: 'hidden',
    transformOrigin: 'top',
    animationDuration: `${t.animation.duration}ms`,
    animationTimingFunction: t.animation.bounce,
    animationKeyframes: [
      {
        '0%': {
          transform: [{ scale: 0.97 }, { translateY: 24 }],
        },

        '100%': { transform: [{ scale: 1 }, { translateY: 0 }] },
      },
    ],
  },
  absolute: {
    position: 'absolute',
    zIndex: 1,
  },

  noDisplay: {
    display: 'none',
  },
  popoverBase: {
    top: 0,
    position: 'absolute',
    margin: 0,
    opacity: 1,
    borderRadius: t.corners.md,
    backgroundColor: themeColors.sheetColor,
  },
  popover: {
    width: t.containers.dialog,
    backgroundColor: themeColors.sheetColor,
    padding: t.spacing.mobile.margins,
    top: 0,
    margin: 0,
    zIndex: 100000,
  },
  hoverElement: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: themeColors.sheetColor,
    borderRadius: t.corners.sm,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    zIndex: 100000,
  },

  popoverOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  /** TOAST */
  toastBase: {
    padding: 16,
    borderRadius: t.corners.md,
  },

  /**
   * States
   */
  // Hoping to reuse these rules for any focused elements
  elementFocused: {
    padding: 2,
    borderWidth: 2,
    borderColor: themeColors.linkColor,
  },
  buttonFocused: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },

  corner: {
    borderRadius: t.corners.sm,
  },
  positionTopRight: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  strikeThroughText: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  underlineText: {
    borderStyle: 'dashed',
    borderColor: themeColors.subtleColor,
    borderBottomWidth: 1,
  },
  underlineTextHover: {
    borderStyle: 'dashed',
    borderColor: themeColors.textColor,
    borderBottomWidth: 1,
  },

  /** TEXT ALIGN */
  centerText: {
    textAlign: 'center',
  },
  rightText: {
    textAlign: 'right',
  },
  leftText: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
    alignItems: 'flex-end',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyEvenly: {
    justifyContent: 'space-evenly',
  },
  alignLeft: {
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignCentered: {
    alignItems: 'center',
  },
  justifyRight: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  justifyLeft: {
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerAll: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flex: 1,
  },

  justifyCenter: {
    justifyContent: 'center',
  },
  noTextMargin: {
    marginBottom: 0,
  },
  reverse: {
    flexDirection: 'row-reverse',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: themeColors.borderColor,
  },
  reverseRow: {
    flexDirection: 'row-reverse',
  },
  reverseCol: {
    flexDirection: 'column-reverse',
  },
  fillColumn: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  startRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  fullSize: {
    width: '100%',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  baselineAlign: {
    alignItems: 'baseline',
  },
  startSelfAlign: {
    alignSelf: 'flex-start',
  },
  endSelfAlign: {
    alignSelf: 'flex-end',
  },
  rightRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  spaceBetween: { justifyContent: 'space-between' },
  spaceAround: { justifyContent: 'space-around' },

  extralabel: { marginBottom: 12, marginLeft: 8 },

  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  grow: {
    flexGrow: 1,
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  /**
   *
   *
   *
   * ANIMATIONS
   */
  transition: {
    transitionDuration: `${t.animation.duration}ms`,
    transitionTimingFunction: t.animation.curve,
  },
  fadeInUp: {
    animationDuration: `${t.animation.duration}ms`,
    animationTimingFunction: t.animation.bounce,
    animationKeyframes: [
      {
        '0%': {
          transform: [{ translateZ: 0 }, { scale: 0.8 }, { translateY: 0 }],
          opacity: 0,
        },

        '100%': { transform: [{ translate: 0, scale: 1 }], opacity: 1 },
      },
    ],
  },
  fadeInUpSm: {
    animationDuration: `${t.animation.duration * 2}ms`,
    animationTimingFunction: t.animation.curve,
    animationKeyframes: [
      {
        '0%': {
          transform: [{ translateZ: 0 }, { scale: 0.95 }, { translateX: 12 }],
          opacity: 0,
        },

        '100%': { transform: [{ translate: 0, scale: 1 }], opacity: 1 },
      },
    ],
  },
  fadeInLeft: {
    animationDuration: `${t.animation.duration * 1.5}ms`,
    animationFillMode: 'both',
    animationTimingFunction: t.animation.curve,
    transformOrigin: 'right',

    animationKeyframes: [
      {
        '0%': {
          transform: [{ translateX: 72 }, { scale: 0.8 }, { translateY: 0 }],
          opacity: 0,
        },

        '100%': { transform: [{ translate: 0, scale: 1 }], opacity: 1 },
      },
    ],
  },
  fadeIn: {
    animationDuration: `${t.animation.durationLong}ms`,
    animationTimingFunction: t.animation.curve,
    animationKeyframes: [
      {
        '0%': {
          opacity: 0,
        },
        '100%': { opacity: 1 },
      },
    ],
  },
  growUp: {
    transformOrigin: 'bottom',
    animationFillMode: 'both',
    animationDuration: `${t.animation.durationLong}ms`,
    animationTimingFunction: t.animation.curve,
    animationKeyframes: [
      {
        '0%': {
          transform: 'scaleY(0)',
          opacity: 0,
        },
        '100%': { transform: 'scaleY(1)', opacity: 1 },
      },
    ],
  },

  navbar: {
    zIndex: 9999999,
    top: 0,
    left: 0,
    right: 0,
  },
  badge: {
    position: 'absolute',
    borderWidth: 2,
    borderColor: themeColors.pageColor,
    right: -2,
    bottom: -2,
  },
  selectableElementStyles: {
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: themeColors.borderColor,
    position: 'relative',
    height: 72,
    width: 72,
  },
  rowPadding: {
    paddingVertical: 16,
    paddingHorizontal: t?.spacing?.mobile?.margins,
  },

  ...Object.keys(t.assets).reduce((acc, name) => {
    return {
      ...acc,
      [`${name}Asset`]: {
        height: t.assets[name],
        width: t.assets[name],
      },
    };
  }, {}),

  blogImage: {
    height: 135,
  },
  selectableCardInner: {
    borderWidth: 2,
    borderColor: 'transparent',
    overflow: 'hidden',
  },

  lightFade: { opacity: 0.85 },
  fullOpacity: { opacity: 1 },
  disabled: { opacity: t.constants.disabledOpacity },
  hideOverflow: { overflow: 'hidden' },
  hoverBg: { backgroundColor: themeColors.skeletonColor },

  _overlay: {
    backgroundColor: colors['coral@28'],
    border: '1px solid ' + colors['coral@28'],
  },

  thinBorder: {
    borderWidth: 1,
    borderColor: themeColors.borderLightColor,
    borderStyle: 'solid',
  },
  lightBorder: {
    borderWidth: 1,
    borderColor: themeColors.borderColor,
    borderStyle: 'solid',
  },
});
