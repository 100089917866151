import { ApplicationMemberFragment, UpsertApplicationMembersInput } from '@app/data/types';

export const getInitialValues = (member: ApplicationMemberFragment) => {
  return {
    id: member.id,
    parent1WeeklyWorkHourQuantity: member.parent1WeeklyWorkHourQuantity,
    parent2WeeklyWorkHourQuantity: member.parent2WeeklyWorkHourQuantity,
  };
};

export const formatPayload = ({ applicationID, values }): UpsertApplicationMembersInput => {
  return {
    applicationID,
    applicationMembersInput: [
      {
        id: values.id,
        parent1WeeklyWorkHourQuantity: values.parent1WeeklyWorkHourQuantity,
        parent2WeeklyWorkHourQuantity: values.parent2WeeklyWorkHourQuantity,
      },
    ],
  };
};
