import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export const useDependentValues = ({
  dependencies = [],
  control,
  prefix,
}: {
  dependencies: Array<string>;
  control: any;
  prefix?: string;
}) => {
  const name = useMemo(() => {
    return dependencies.map((d) => {
      if (d.includes('PARENT:')) {
        const [, name] = d.split('PARENT:');
        return name;
      }

      return d.replace('SUBFIELD:', `${prefix}.`);
    });
  }, [dependencies]);

  // if a field is dependent on any other form values, we will watch for changes of those values
  const watch = useWatch({ name, control });

  // creates an object of any dependent fields with their current values
  const vals = useMemo(() => {
    return dependencies.reduce((values, fieldName, index) => {
      const lookup = fieldName.includes('PARENT:')
        ? fieldName.split('PARENT:')?.[1]
        : fieldName.replace('SUBFIELD:', '');
      return { ...values, [lookup]: watch[index] };
    }, {});
  }, [watch]);

  const dependentValues = useMemo(() => {
    if (!prefix) return vals;

    return Object.keys(vals).reduce(
      (acc, fieldName) => ({
        ...acc,
        [fieldName.replace(`${prefix}.`, '')]: vals[fieldName],
      }),
      {},
    );
  }, [vals, prefix]);

  return dependentValues;
};
