import React from 'react';
import {
  useMutation,
  mutations,
  UpsertExplorerResponse,
  UpsertExplorerVars,
  useQuery,
  updates,
  HealthExplorerQuery,
} from '@data';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { ScreenDefinition } from '@navigate';
import { HealthZipTriage } from '@partial';
import { useHealthStore } from '@hooks/useHealthStore';
import { Route } from '@types';
import { useAetna } from '@app/hooks';
import { AETNA_UNAVAILABLE_STATES } from '@app/constants/aetna';

const ExplorerZipCode = ({ handleNext }) => {
  const { c } = useCopy('catch');
  const { setHealthContext } = useHealthStore();
  const { data } = useQuery(HealthExplorerQuery);
  const { inAetnaWhiteLabelFlow, unsetWhiteLabel } = useAetna();

  const [upsert, { loading: upserting }] = useMutation<UpsertExplorerResponse, UpsertExplorerVars>(
    mutations.UPSERT_EXPLORER,
    { update: updates.UPDATE_EXPLORER },
  );

  const onSubmit = ({ coverageYear, location }) => {
    upsert({
      variables: {
        input: {
          coverageYear,
          countyfips: location.fips,
          state: location.state,
          zipcode: location.zip,
          pathwayType: location.pathway,
        },
      },
    });

    if (location.state === 'GA') {
      setHealthContext('GA');
    }

    //if in aetna white label flow and in unavailable state, unset white label
    if (inAetnaWhiteLabelFlow && AETNA_UNAVAILABLE_STATES[location.state]) {
      unsetWhiteLabel();
    }

    Segment.track(SegmentEvent.HX_ZIP_COMPLETED, {
      coverage_year: coverageYear,
      fips: location.fips,
      state: location.state,
      zip: location.zip,
      pathway: location.pathway,
    });

    handleNext({ coverageYear });
  };

  const initialLocation = {
    zip: data?.viewerTwo?.healthExplorerData?.zipcode,
    county: '',
    fips: data?.viewerTwo?.healthExplorerData?.countyfips,
    state: data?.viewerTwo?.healthExplorerData?.state,
    pathway: data?.viewerTwo?.healthExplorerData?.pathwayType,
  };

  return (
    <HealthZipTriage
      loading={!data}
      submitting={upserting}
      initialLocation={initialLocation}
      title={c('healthExplorer.explorerZip.OEPtitle')}
      subtitle={c('healthExplorer.explorerZip.subtitle')}
      oeDates={data?.reference?.health?.openEnrollmentDates}
      onSubmit={onSubmit}
    />
  );
};

export const ExplorerZipCodeView: ScreenDefinition = {
  name: Route.EXPLORER_ZIPCODE,
  component: ExplorerZipCode,
  options: {},
};
