import React, { useEffect } from 'react';
import { FinePrint, Button } from '@uikit';
import { Route } from '@types';
import { ExplainerIntroBlueprint } from '@app/blueprints';
import { Toolbar } from '@app/layouts';
import PriceTag from '@svg/tag.svg';
import Renew from '@svg/catch-roll.svg';

/**
 * Health Link Intro
 */
const HealthLinkIntro = ({
  handleNext,
  handleSkip,
  loading,
  searching,
  confirmIdentity,
  canSearch,
  isInExchange,
  lookup,
  isSlasherFlow,
}) => {
  const _loading = loading || searching;

  useEffect(() => {
    lookup(true);
  }, []);

  useEffect(() => {
    if (!loading && !!canSearch) confirmIdentity();
  }, [loading, canSearch]);

  return (
    <ExplainerIntroBlueprint
      loading={!isInExchange && _loading}
      context="page"
      render="coverageAlt"
      color="coverage"
      gradient="coverageLight"
      title="Connect your coverage"
      subtitle="Easily manage your coverage all in one place"
      bullets={[
        {
          id: 'savings',
          svg: PriceTag,
          label: 'Maximize savings',
          sublabel: 'Make sure you’re getting the tax credits you qualify for',
        },
        {
          id: 'renewals',
          svg: Renew,
          label: 'Automate renewals',
          sublabel: 'Never worry about renewing your health coverage',
        },
      ]}
      toolbar={
        <Toolbar type="stack">
          <FinePrint
            align="center"
            items={['Catch is securely integrated with health exchanges.']}
          />
          <Button
            testID="set-up-later"
            wide
            disabled={loading}
            onPress={isSlasherFlow ? undefined : handleSkip}
            accentColor="coverageLight"
          >
            Set up later
          </Button>
          <Button testID="next" wide accentColor="coverage" disabled={loading} onPress={handleNext}>
            Next
          </Button>
        </Toolbar>
      }
    />
  );
};

export const HealthLinkIntroView = {
  name: Route.HEALTH_LINK_INTRO,
  component: HealthLinkIntro,
  options: {
    drawBehindNav: true,
  },
};
