import React from 'react';

import ContentTooltip from './ContentTooltip';

const Tooltip = ({
  type = 'popover', // can also be 'sheet'
  ...rest
}) => {
  return <ContentTooltip type={type} {...rest} />;
};

export default Tooltip;
