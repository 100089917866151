import { optionPresets } from '@app/forms';
import {
  ninetyDaysAgoISO,
  safeFormatDate,
  sixtyDaysFromNow,
  sixtyDaysFromNowISO,
  todayISO,
} from '@app/utils';
import { subDays } from 'date-fns';

export const fields = [
  {
    testID: '138',
    name: 'isMedicaidEnd',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['relation', 'fullName', 'medicaid', 'chip'],
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, medicaid, chip }) => ({
      id: '138_Q',
      data: { relation, fullName, medicaid, chip, endDate: sixtyDaysFromNow() },
    }),
  },
  {
    testID: '140',
    name: 'medicaidEndDate',
    type: 'date',
    required: true,
    format: 'YYYY-MM-DD',
    dependencies: ['isMedicaidEnd', 'relation', 'fullName', 'medicaid', 'chip'],
    display: (values) => values.isMedicaidEnd === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    before: { date: sixtyDaysFromNowISO(), inclusive: true, message: 'Must be within sixty days' },
    after: { date: '2023-03-31', inclusive: true, message: 'Must be on or after March 31, 2023' },
    label: ({ relation, fullName, medicaid, chip }) => ({
      id: '140_Q',
      data: { relation, fullName, medicaid, chip },
    }),
  },
  {
    testID: '139',
    name: 'isHouseholdChanged',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isMedicaidEnd', 'medicaidEndDate', 'relation', 'fullName'],
    display: (values) => values.isMedicaidEnd === 'YES' && !!values?.medicaidEndDate,
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName }) => ({ id: '139_Q', data: { relation, fullName: fullName } }),
  },

  {
    testID: '133',
    name: 'isMedicaidDenied',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isMedicaidEnd',
      'medicaidEndDate',
      'isHouseholdChanged',
      'relation',
      'fullName',
      'medicaid',
      'chip',
      'state',
      'ninetyDaysAgo',
    ],
    display: (values) => {
      // helpers for checking if date entered is more than 90 days ago
      const ninetyDays = safeFormatDate(subDays(new Date(), 90), 'yyyy-MM-dd');
      const canCheckDate =
        !!values.medicaidEndDate && /^\d{4}-\d{2}-\d{2}$/.test(values.medicaidEndDate);

      return (
        values.isMedicaidEnd === 'NO' || // name not selected
        // ended more than 90 days ago
        (values.isMedicaidEnd === 'YES' && canCheckDate && values.medicaidEndDate < ninetyDays) ||
        // ended due to household change
        (values.isMedicaidEnd === 'YES' &&
          !!values.medicaidEndDate &&
          values.isHouseholdChanged === 'YES')
      );
    },
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, medicaid, chip, state, ninetyDaysAgo }) => ({
      id: '133_Q',
      data: { relation, fullName, medicaid, chip, state, date: ninetyDaysAgo },
    }),
    help: { id: '133_T' },
  },
  {
    testID: '134',
    name: 'medicaidDeniedDate',
    type: 'date',
    required: true,
    format: 'YYYY-MM-DD',
    dependencies: ['isMedicaidDenied', 'relation', 'fullName', 'chip', 'medicaid'],
    display: (values) => values.isMedicaidDenied === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, chip }) => ({
      id: '134_Q',
      data: { relation, fullName, chip },
    }),
    help: ({ medicaid, chip }) => ({ id: '134_T', data: { medicaid, chip } }),
    before: { date: todayISO(), message: 'Must be before today' },
    after: { date: ninetyDaysAgoISO(), message: 'Must be in the last 90 days' },
  },
  {
    testID: '136',
    name: 'isAppliedDuringOe',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isMedicaidDenied',
      'relation',
      'fullName',
      'oeStartDate',
      'oeEndDate',
      'oeYear',
    ],
    display: (values) => values.isMedicaidDenied === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, oeStartDate, oeEndDate, oeYear }) => ({
      id: '136_Q',
      data: { relation, fullName, oeStartDate, oeEndDate, oeYear },
    }),
  },
  {
    testID: '137',
    name: 'isAppliedDuringLifeChange',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isAppliedDuringOe', 'relation', 'fullName'],
    display: (values) => values.isAppliedDuringOe === 'NO',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName }) => ({ id: '137_Q', data: { relation, fullName: fullName } }),
    help: { id: '137_T' },
  },
  {
    testID: '141',
    name: 'isMedicaidDeniedDueToImmigration',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isLawfulPresenceStatus',
      'relation',
      'fullName',
      'medicaid',
      'chip',
      'fiveYearsAgo',
    ],
    display: (values) => values.isLawfulPresenceStatus,
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, medicaid, chip, fiveYearsAgo }) => ({
      id: '141_Q',
      data: { relation, fullName, medicaid, chip, date: fiveYearsAgo },
    }),
    help: ({ medicaid, chip }) => ({ id: '141_T', data: { medicaid, chip } }),
  },
  {
    testID: '142',
    name: 'hasImmigrationStatusFiveYear',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isLawfulPresenceStatus',
      'isMedicaidDeniedDueToImmigration',
      'relation',
      'fullName',
      'fiveYearsAgo',
    ],
    display: (values) =>
      values.isLawfulPresenceStatus && values.isMedicaidDeniedDueToImmigration === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, fiveYearsAgo }) => ({
      id: '142_Q',
      data: { relation, fullName, date: fiveYearsAgo },
    }),
  },
  {
    testID: '143',
    name: 'isImmigrationStatusChangeSinceDenied',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isMedicaidDeniedDueToImmigration',
      'isLawfulPresenceStatus',
      'hasImmigrationStatusFiveYear',
      'relation',
      'fullName',
      'medicaid',
      'chip',
    ],
    display: (values) =>
      values.isLawfulPresenceStatus &&
      values.isMedicaidDeniedDueToImmigration === 'YES' &&
      !!values.hasImmigrationStatusFiveYear,
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, medicaid, chip }) => ({
      id: '143_Q',
      data: { relation, fullName, medicaid, chip },
    }),
  },
];
