import {
  AssistorType,
  ProfessionalAssistorInput,
  Trinary,
  UpsertHealthApplicationInput,
} from '@app/data';
import { HealthApplicationQuery } from '@app/data/types';

type AssistorValues = {
  organizationName: string;
  organizationID: string;
  assistorNPN?: string;
  name: {
    givenName: string;
    familyName: string;
    middleName: string;
    nameSuffix: string;
  };
};

type AssistorDataMap = {
  [key in AssistorType]?: AssistorValues;
};

export interface AssistorFormValues {
  isProfessionalAssistance?: Trinary;
  assistorTypes: Array<AssistorType>;
  applicationAssistors: AssistorDataMap;
}

export const getInitialValues = (
  data?: HealthApplicationQuery['viewerTwo']['health']['application'],
): AssistorFormValues => {
  const applicationAssistors = data?.applicationAssistors || [];

  const initialFormattedAssistors: AssistorDataMap = applicationAssistors.reduce(
    (acc: AssistorDataMap, a) => {
      if (!a.applicationAssistorType) return acc;

      return {
        ...acc,
        [a.applicationAssistorType]: {
          organizationID: a.organizationID || '',
          organizationName: a.organizationName || '',
          assistorNPN: a.assistorNPN ? `${a.assistorNPN}` : undefined,
          name: {
            givenName: a.firstName || '',
            familyName: a.lastName || '',
            middleName: a.middleName || '',
            nameSuffix: a.suffix || '',
          },
        },
      };
    },
    {},
  );

  const assistorTypes = applicationAssistors
    .map((a) => a.applicationAssistorType)
    .filter((type) => !!type)
    .concat(AssistorType.AssistorTypeAgentBroker);

  return {
    isProfessionalAssistance: applicationAssistors.length > 0 ? Trinary.Yes : undefined,
    assistorTypes,
    applicationAssistors: initialFormattedAssistors,
  };
};

export const formatPayload = ({
  applicationID,
  values,
}: {
  applicationID: string;
  values: AssistorFormValues;
}): UpsertHealthApplicationInput => {
  const types = values.isProfessionalAssistance === Trinary.Yes ? values.assistorTypes : [];

  const formattedAssistors = types.reduce(
    (acc: Array<ProfessionalAssistorInput>, type: AssistorType) => {
      const data = values.applicationAssistors[type];

      // skips over any other agent brokers or missing data
      if (type === AssistorType.AssistorTypeAgentBroker || !data) return acc;

      return [
        ...acc,
        {
          applicationAssistorType: type,
          organizationName: data.organizationName,
          organizationID: data.organizationID,
          firstName: data.name.givenName,
          lastName: data.name.familyName,
          middleName: data.name.middleName,
          suffix: data.name.nameSuffix,
          assistorNPN: data.assistorNPN ? Number(data.assistorNPN) : undefined,
        },
      ];
    },
    [],
  );

  return {
    id: applicationID,
    lastUsedRoute: 'pro-assistance',
    applicationAssistors: formattedAssistors,
  };
};
