import React from 'react';
import { Asset } from '@uikit';
import { personInitials } from '@app/utils';
import { Stack } from '@app/layouts';
import User from '@svg/user.svg';

interface MembersAvatarsProps {
  currentIndex: number;
  members: Array<{
    id: string;
    givenName: string;
    familyName: string;
  }>;
}

const MembersAvatars: React.FC<MembersAvatarsProps> = ({ setIndex, currentIndex, members }) => {
  if (!members || members.length === 0) return null;

  return (
    <Stack horizontal spacing="0b">
      {members.map((m, idx) => (
        <Asset
          key={m.id}
          svg={User}
          text={personInitials(m)}
          containerSize="md"
          size="xs"
          bg={idx <= currentIndex ? 'text' : 'skeleton'}
          color={idx <= currentIndex ? 'page' : 'text'}
          shape="circle"
          style={idx < currentIndex ? { opacity: 0.5 } : undefined}
          onPress={idx < currentIndex ? () => setIndex(idx) : undefined}
        />
      ))}
    </Stack>
  );
};

export default MembersAvatars;
