type HelpArticleKey =
  | 'tax-withholding'
  | 'retirement'
  | 'saving'
  | 'tax-payments'
  | 'bank-link'
  | 'kyc'
  | 'health';

export const helpArticles: Record<HelpArticleKey, string> = {
  'tax-withholding':
    'https://help.catch.co/en/articles/2129934-how-does-catch-help-me-with-my-tax-withholding',
  retirement: 'https://help.catch.co/en/collections/734399-building-for-retirement',
  saving: 'https://help.catch.co/en/articles/2129988-what-does-it-mean-to-save-for-time-off',
  'tax-payments': 'https://help.catch.co/en/articles/4101399-federal-tax-payments',
  'bank-link': 'https://help.catch.co/en/articles/2034414-why-do-i-need-to-link-my-bank-account',
  kyc: 'https://help.catch.co/en/articles/2130140-what-is-kyc',
  health: 'https://help.catch.co/en/collections/1672481-understanding-health-insurance',
};

export const links = {
  trustpilot:
    'https://www.trustpilot.com/review/catch.co?utm_medium=trustbox&utm_source=in_app_review',
};
