import React from 'react';
import { Section } from '@layouts';
import ApplicationsList, { Application } from '../applications/ApplicationsList';
import PoliciesList, { Policy } from '../policies/PoliciesList';
import { DisplayComponent } from '@app/types';
import { open } from '@app/navigate';
import { Route } from '@types';

interface CoverageListProps {
  applications?: Array<Application>;
  policies?: Array<Policy>;
}

const shouldDisplay = ({ applications, policies }: CoverageListProps) => {
  return (
    ApplicationsList.shouldDisplay({ applications }) || PoliciesList.shouldDisplay({ policies })
  );
};

const CoverageList: DisplayComponent<CoverageListProps> = ({ applications, policies }) => {
  if (!shouldDisplay({ applications, policies })) return null;

  return (
    <>
      {ApplicationsList.shouldDisplay({ applications }) && (
        <Section title="Applications">
          <ApplicationsList applications={applications} />
        </Section>
      )}

      {PoliciesList.shouldDisplay({ policies }) && (
        <PoliciesList
          policies={policies}
          onPress={(policy) =>
            open(Route.COVERAGE_DETAIL, {
              type: /DENTAL/.test(policy.productType) ? 'dental' : 'health',
              policyID: policy.id,
              enrollId: policy.enrollmentID,
            })
          }
        />
      )}
    </>
  );
};

CoverageList.shouldDisplay = shouldDisplay;
export default CoverageList;
