import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Env, Storage } from '@app/utils';

type HealthContext = 'FFM' | 'GA' | 'CA' | 'NJ';

interface HealthStore {
  applicationID: string;
  context: HealthContext;
  setID: (applicationID: string) => void;
  setHealthContext: (context: HealthContext) => void;
  reset: () => void;
}

export const useHealthStore = create<HealthStore>(
  persist(
    (set, get) => ({
      // application id
      applicationID: '',
      setID: (applicationID: string) => {
        set({ applicationID });
      },

      // health context
      context: 'FFM',
      setHealthContext: (context: HealthContext) => {
        // context can only be set to GA when the flag is enabled
        if (context === 'GA' && !Env.isGeorgia) {
          return;
        }

        set({ context });
      },

      reset: () => {
        set({ applicationID: '', context: 'FFM' });
      },
    }),
    {
      name: 'health',
      getStorage: () => Storage,
    },
  ),
);
