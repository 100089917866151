import React, { useMemo } from 'react';

import { ComplexRow } from '@uikit';
import { Document } from '@data';
import { useDocument } from '@app/hooks/useDocument';

export const get_labels = ({ type, detail }) => {
  let label, sublabel;
  switch (type) {
    case 'TAX_1040_ES':
      label = `Form 1040-ES (${detail})`;
      sublabel = 'Estimated Tax Payment';
      break;
    case 'TAX_1095_A':
      label = `Form 1095-A (${detail})`;
      sublabel = 'Health Marketplace Statement';
      break;
    case 'TAX_1099_R':
      label = 'Form 1099-R';
      sublabel = 'IRA Contributions';
      break;
    case 'TAX_5498':
      label = 'Form 5498';
      sublabel = 'IRA Contributions';
      break;
    case 'DDA':
      label = detail;
      sublabel = 'Bank Statement';
      break;
    case 'IRA':
      label = `Retirement`;
      sublabel = 'IRA Statement';
      break;
    default:
      break;
  }

  return {
    label,
    sublabel,
  };
};

interface DocumentRowProps {
  document: Document;
}

const DocumentRow: React.FC<DocumentRowProps> = ({ document }) => {
  const { label, sublabel } = useMemo(
    () =>
      get_labels({
        type: document?.type,
        detail: document?.detail,
      }),
    [document],
  );

  const { downloadDocument } = useDocument(document);

  return <ComplexRow onPress={downloadDocument} label={label} sublabel={sublabel} />;
};

export default DocumentRow;
