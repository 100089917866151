import React from 'react';
import { IconBullet, Text } from '@app/_ui-kit';

const EligibilityItem = ({ icon, title, description }) => (
  <IconBullet icon={icon}>
    <Text gutter="left">
      <Text size="fp" gutter="right">
        {title}
      </Text>
      <Text size="fp" color="subtle">
        {description}
      </Text>
    </Text>
  </IconBullet>
);

export default EligibilityItem;
