import moment from 'moment';
import {
  parseJSON,
  parseISO,
  isToday,
  isTomorrow,
  getMinutes,
  isSameYear,
  differenceInMinutes,
  differenceInDays,
} from 'date-fns';

import { safeFormatDate } from '../time';

/**
 * Parsing date strings as such `new Date(string)` is bad
 * we need to use the proper contstructor usage as:
 * new Date(year, monthIndex [, day [, hours [, minutes [, seconds [, milliseconds]]]]]);
 * @param { String }
 */
export const toDate = (string) => {
  if (string == null) return '';
  if (!moment(string).isValid()) return string;
  return moment(string).toDate();
};

//Formats string YYYY-MM-DD to MM/DD/YYY
export const formatDOB = (string) => {
  if (typeof string !== 'string') return string;

  const [year, month, day] = string.substr(0, 10).split('-');
  return `${month}/${day}/${year}`;
};

// Coverts string MM/DD/YYYY to YYYY-MM-DD
export const parseDOB = (string) => {
  if (typeof string !== 'string') return string;

  const [month, day, year] = string.substr(0, 10).split('/');
  return `${year}-${month}-${day}`;
};

/**
 * when given timestamps, we can be real about exactly when, down to the minute
 */
export const fromTimestamp = (datetime) => {
  if (!datetime) return ''; // always return string so text works properly
  const date = parseJSON(datetime);
  const diff = differenceInMinutes(date, new Date());
  const showMinutes = getMinutes(date) !== 0;
  const time = showMinutes ? 'h:mma' : 'ha';

  if (diff < 60) return `In ${diff} mins`; // In 48 mins
  if (isToday(date)) return `${safeFormatDate(date, time)} today`; // 9PM today
  if (isTomorrow(date)) return `${safeFormatDate(date, time)} tomorrow`; // 11:30AM tomorrow
  if (isSameYear(date, new Date())) return safeFormatDate(date, `MMM d, ${time}`); // Jan 4, 6PM
  return safeFormatDate(date, `MMM d, yyyy, ${time}`); // Jun 4, 2017, 5:30AM
};

/**
 * when given an iso date, we could only be specific to the day
 */
export const formatISO = (isoString, formatString = 'MMM d') => {
  if (!isoString) return ''; // always return string so text works properly
  const date = parseISO(isoString);

  return safeFormatDate(date, formatString); // Dec 30, 2019
};

export const fromDays = (isoString) => {
  if (!isoString) return ''; // always return string so text works properly
  const date = parseISO(isoString);

  return differenceInDays(date, new Date());
};
