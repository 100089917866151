import React, { useEffect, useMemo } from 'react';
import Intercom from 'react-intercom';

import { Env, Segment, setSentryUser } from '@app/utils';
import { useResponsive } from '@uikit';
import { AnalyticsContextQuery, AppPlatform, useDeprecatedMutation, useQuery } from '@data';
import { env } from '@app/global';

// filters out local environments + frequent users
export const enableTracking = (email) => {
  if (!email) return false;
  if (Env.isLocal) return false;

  const users = [
    'mayhew',
    'fenster',
    'cypress+2fa',
    'jeff+2fa',
    'alvin+2fa',
    'matt+2fa',
    'lexi+2fa',
  ];

  const match = users.reduce((acc, user) => acc || email?.includes(user), false);
  return !match;
};

/**
 * Centralizing all web specific support/analytics tools
 *
 * Segment sends to Heap, Mixpanel, Customer.io, and others
 * https://github.com/nhagen/react-intercom/blob/master/src/index.js
 * https://app.logrocket.com/6an89b/catch-production/settings/setup
 * https://app.datadoghq.com/rum/application
 */
const AuthenticatedSupport = () => {
  const { longRange } = useResponsive();

  const { data } = useQuery(AnalyticsContextQuery, {
    variables: { platform: AppPlatform.Web },
  });

  const { userId, givenName, familyName, email, phoneNumber, intercomHash } = useMemo(() => {
    return {
      userId: data?.viewer?.user?.id,
      givenName: data?.viewer?.user?.givenName,
      familyName: data?.viewer?.user?.familyName,
      email: data?.viewer?.user?.email,
      phoneNumber: data?.viewer?.user?.phoneNumber,
      intercomHash: data?.viewer?.intercomIdentityHash,
    };
  }, [data]);

  const [startSession] = useDeprecatedMutation('startSession', {
    variables: {
      input: {
        version: env.VERSION_NUMBER,
        deviceType: 'web',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
  });

  const enable = Env.isProd || enableTracking(email);

  /**
   * @todo make sure EBP is properly updating
   * @todo only keep this live until end of OE 2023-01-16
   * */
  useEffect(() => {
    startSession();
  }, []);

  useEffect(() => {
    if (enable && userId) {
      Segment.identifyUser(userId, {
        name: `${givenName} ${familyName}`,
        email,
        givenName,
        familyName,
        phoneNumber,
        phone: phoneNumber,
      });

      setSentryUser({ email, id: userId });
    }
  }, [userId, givenName, familyName, email, phoneNumber, enable]);

  return (
    <>
      {userId && (
        <Intercom
          appID={Env.isProd ? 'pniw40fg' : 'qn5cenup'}
          vertical_padding={longRange === 'mobile' ? 84 : 20}
          user_id={userId}
          name={`${givenName} ${familyName}`}
          email={email}
          user_hash={intercomHash}
          hide_default_launcher={true}
        />
      )}
    </>
  );
};

export default AuthenticatedSupport;
