import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Badge, Button, ComplexRow, SimpleRow, Text } from '@uikit';
import { constants, isWithin30Days, safeFormatDate, useCopy } from '@app/utils';
import { Card, Stack } from '@layouts';
import { ConsolidatedIssueStatus, DMI, HealthDocType, SVI, SviStatusCode } from '@app/data';
import { FontSize, Route } from '@app/types';
import { Color } from '@app/styles';
import { open } from '@app/navigate';

const selectThemeType = (status, resolveBy) => {
  switch (status) {
    case ConsolidatedIssueStatus.ActionNeeded:
      return isWithin30Days(resolveBy) ? Color.negativeLight : Color.mediumLight;
    case ConsolidatedIssueStatus.Processing:
      return Color.border;
    case ConsolidatedIssueStatus.Expired:
      return Color.border;
    case ConsolidatedIssueStatus.Complete:
      return Color.positiveLight;
    default:
      return;
  }
};

const ApplicationIssueCard = ({
  issue,
  applicationID,
}: {
  issue: DMI | SVI;
  applicationID: string;
}) => {
  const { c } = useCopy('catch.ede');
  const themeType = selectThemeType(issue.status, issue.resolveBy);

  // const [getDocument] = useDeprecatedMutation('getGeneratedDocumentURL', {
  //   onCompleted: (data) => window.open(data.getGeneratedDocumentURL),
  // });
  const getDocument = () => {};

  const handleUpload = () => {
    open(Route.HEALTH_DOCS_UPLOAD, { issue, applicationID });
  };

  const viewDocs = () => {
    open(Route.HEALTH_DOCS_UPLOADED, { issue });
  };

  const renderNotices = (notice, idx) => {
    if (
      issue.status !== ConsolidatedIssueStatus.Expired &&
      notice?.name === constants.dmiNoticeTypes.CLOCK_EXPIRATION_NOTICE
    ) {
      return;
    }

    return (
      <Button
        key={notice.id}
        small
        alt
        testID={`getNotices_${idx}`}
        onPress={() =>
          getDocument({
            variables: { input: { dsrsIdentifier: notice.dsrsIdentifier } },
          })
        }
      >
        {notice.name
          ? notice.name
              .replace(/_/g, ' ')
              .split(' ')
              .map((word) => word[0] + word.substring(1).toLowerCase())
              .join(' ')
          : `${issue.docType} notice`}
      </Button>
    );
  };

  const member = useMemo(() => {
    // for Adoption, UI needs to show either the applicant's name
    // or dependent's name unlike showing household in the Flag
    // for ORR compliance
    if (
      issue.members.length > 1 &&
      issue.docType === HealthDocType.SVI &&
      issue.statusCode === SviStatusCode.Adoption
    ) {
      return c('DMI.household');
    }

    return issue.members[0]?.givenName;
  }, [issue]);

  const issueLabel = useMemo(() => {
    if (issue.docType === HealthDocType.SVI) {
      return c(`DMI.${issue.statusCode}.title`);
    } else {
      return c(`enums.${issue.subType}`) || c(`enums.${issue.verificationType}`);
    }
  }, [issue]);

  const lastReview = useMemo(() => {
    return issue.lastReviewed
      ? c('DMI.reviewDate', { date: safeFormatDate(new Date(issue.lastReviewed)) })
      : '';
  }, [issue]);

  const canAddDocs = [ConsolidatedIssueStatus.ActionNeeded].includes(issue.status);
  const canViewDocs = [
    ConsolidatedIssueStatus.Processing,
    ConsolidatedIssueStatus.Complete,
  ].includes(issue.status);

  return (
    <Card padded={false} border={false}>
      <Card.Content>
        <Stack spacing="1">
          <SimpleRow
            noPadding
            label={member}
            accessory={<Badge label={c(`DMI.${issue.status}.title`)} />}
          />
          <ComplexRow noPadding label={issueLabel} sublabel={lastReview} />
          {issue.notices?.length > 0 && (
            <View>{issue.notices?.map((n, idx) => renderNotices(n, idx))}</View>
          )}
        </Stack>
      </Card.Content>
      <Card.Content $bg={themeType}>
        <ComplexRow
          noPadding
          label={<Text size={FontSize.h6}>{c(`DMI.${issue.status}.docCaption`)}</Text>}
          sublabel={
            <Text size={FontSize.sm}>
              {c(`DMI.${issue.status}.docStatus`, { date: safeFormatDate(issue.resolveBy) })}
            </Text>
          }
          accessory={
            canAddDocs ? (
              <Button testID="add-doc-btn" small onPress={handleUpload}>
                {c('DMI.addButton')}
              </Button>
            ) : canViewDocs ? (
              <Button testID="view-doc-btn" alt small onPress={viewDocs}>
                {c('DMI.viewLink')}
              </Button>
            ) : null
          }
        />
      </Card.Content>
    </Card>
  );
};

export default ApplicationIssueCard;
