import React from 'react';
import styled from 'styled-components';
import Stack from './Stack';
import { useTheme } from '@uikit/hooks/useTheme';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { CatchTheme } from '@app/_ui-kit/themes/catch';

export const Split = ({ children, sticky }) => {
  const { theme } = useTheme();
  const { longRange, isMobile } = useResponsive();
  const small = longRange === 'mobile';
  const kids = !!children && children?.length > 0 ? React.Children.toArray(children) : [];
  return (
    <Stack
      horizontal={!small}
      spacing="8"
      style={[
        theme.alignStart,
        { justifyContent: 'space-between', overflow: 'visible', margin: 'auto' },
      ]}
      itemStyle={[!isMobile && theme.flex1, theme.fullWidth, sticky && !isMobile && theme.sticky]}
    >
      {kids.map((item, i) => (
        <div key={item.key || item.testID || i}>{item}</div>
      ))}
      {kids?.length === 1 && !small ? <div key="XTRA"></div> : null}
    </Stack>
  );
};

export const Narrow = styled.div`
  max-width: ${CatchTheme.containers.miniPage}px;
  align-self: center;
  margin: auto;

  padding: 0 ${CatchTheme.spacing.mobile.margins}px;
  width: calc(100% - ${CatchTheme.spacing.mobile.margins * 2}px);

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    padding: 0 ${CatchTheme.spacing.desktop.margins}px;
    width: calc(100% - ${CatchTheme.spacing.desktop.margins * 2}px);
  }
`;

export const Container = styled.div`
  max-width: ${CatchTheme.containers.page}px;
  margin: auto;

  padding: 0 ${CatchTheme.spacing.mobile.margins}px;
  width: calc(100% - ${CatchTheme.spacing.mobile.margins * 2}px);

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    padding: 0 ${CatchTheme.spacing.desktop.margins}px;
    width: calc(100% - ${CatchTheme.spacing.desktop.margins * 2}px);
  }
`;
