export const fields = (dateLabel) => [
  {
    name: 'members',
    type: 'array',
    Component: ({ children }) => children,
    Footer: () => null,
    subfields: [
      {
        name: 'removeMember',
        type: 'person',
        bare: true,
        dependencies: ['name'],
        label: ({ name }) => name,
        secondary: {
          unchecked: '',
          checked: '',
        },
      },
      {
        name: 'removeDate',
        type: 'date',
        format: 'YYYY-MM-DD',
        dependencies: ['removeMember'],
        display: ({ removeMember }) => !!removeMember,
        label: dateLabel || 'Date',
        required: true,
      },
    ],
  },
];
