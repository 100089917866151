import chroma from 'chroma-js';
import { CatchTheme } from '../themes/catch';

const darkModePresets = (name, hex) => ({
  [`${name}@100`]: hex,
  [`${name}@0`]: chroma(hex).alpha(0).hex(),
  [`${name}@5`]: chroma(hex).alpha(0.05).hex(),
  [`${name}@8`]: chroma(hex).alpha(0.08).hex(),
  [`${name}@10`]: chroma(hex)
    .alpha(1 - 0.9)
    .hex(),
  [`${name}@11`]: chroma(hex)
    .alpha(1 - 0.89)
    .hex(),
  [`${name}@14`]: chroma(hex)
    .alpha(1 - 0.86)
    .hex(),
  [`${name}@17`]: chroma(hex)
    .alpha(1 - 0.83)
    .hex(),
  [`${name}@20`]: chroma(hex)
    .alpha(1 - 0.8)
    .hex(),
  [`${name}@22`]: chroma(hex)
    .alpha(1 - 0.78)
    .hex(),
  [`${name}@28`]: chroma(hex)
    .alpha(1 - 0.72)
    .hex(),
  [`${name}@30`]: chroma(hex)
    .alpha(1 - 0.7)
    .hex(),
  [`${name}@50`]: chroma(hex)
    .alpha(1 - 0.5)
    .hex(),
  [`${name}@95`]: chroma(hex)
    .alpha(1 - 0.05)
    .hex(),
});

export const colors = {
  snow: '#FFFFFF',
  paper: '#FFFFFF',
  ...darkModePresets('snow', '#FFFFFF'),
  ...darkModePresets('glow', '#FFFFFF'),

  ink: '#100F13',
  'ink-1': '#000000',
  'ink+1': '#7C7B7E',
  'ink+2': '#B8B7B8',
  'ink+3': '#CDCED1',
  'ink+4': '#E6E8EB',
  'ink+5': '#F1F3F5',
  'ink+6': '#F8F9FA',
  ...darkModePresets('ink', '#100F13'),

  // helper function to convert hex -> rgb
  rgb: (hex) => ({
    r: parseInt(hex.substring(1, 3), 16),
    g: parseInt(hex.substring(3, 5), 16),
    b: parseInt(hex.substring(5, 7), 16),
  }),
};

const nc = CatchTheme.colors;

// the base page color
const PAGE = {
  name: 'page',
  light: colors['paper'],
  dark: CatchTheme.colors.dark.bg,
};

// the card color for separating block of content from page underneath
const CARD = {
  name: 'card',
  light: colors['paper'],
  dark: colors['glow@5'],
};

// the color used for the base of modals, action sheets
// derived from the color of a card on a page
const SHEET = {
  name: 'sheet',
  light: colors['paper'],
  dark: CatchTheme.colors.dark.bgSheet,
};

const SETUP_BASE = {
  name: 'setupBase',
  light: nc.light.bgGray,
  dark: nc.dark.bgGray,
};

const NEW_COLORS = Object.entries(nc.light).map(([k, v]) => {
  return {
    name: k,
    light: v,
    dark: CatchTheme.colors.dark[k],
  };
});

// semantic colors object should not use any brighten, darken or transparentize functions
// unless they are used for hover states, or instances in which
// it's specially administered otherwise, such as login page and modal
export const SEMANTIC_COLORS = [
  // base colors
  PAGE,
  SHEET,
  SETUP_BASE,
  CARD,
  ...NEW_COLORS,

  // Basic Text Color
  { name: 'navbarLinkActive', light: nc.light.fg, dark: nc.dark.fg },
  { name: 'text', light: nc.light.fg, dark: nc.dark.fg },
  { name: 'textBg', light: nc.light.fg, dark: nc.dark.fg },
  { name: 'navbarLink', light: nc.light.fg2, dark: nc.dark.fg2 },
  { name: 'taxesLight', light: nc.light.taxesLight, dark: nc.dark.taxesLight },

  // buttons
  { name: 'primaryButton', light: nc.light.fg, dark: nc.dark.fg },
  { name: 'primaryButtonText', light: nc.light.bg, dark: nc.dark.bg },
  { name: 'secondaryButton', light: nc.light.bg3, dark: nc.dark.bg3 },
  { name: 'secondaryButtonText', light: nc.light.fg, dark: nc.dark.fg },

  // Inverted black/white
  { name: 'navbar', light: nc.light.bg, dark: nc.dark.bg },
  { name: 'switchOn', light: nc.light.bg, dark: nc.dark.bg },

  // PAGE LIGHT
  { name: 'pageLight', light: nc.light.bg2, dark: nc.dark.bg2 },
  { name: 'checkboxDeselected', light: nc.light.bg2, dark: nc.dark.bg2 },
  { name: 'disabled', light: nc.light.bg2, dark: nc.dark.bg2 },

  // BORDER
  { name: 'skeleton', light: nc.light.bg3, dark: nc.dark.bg3 },
  { name: 'skeletonColor', light: nc.light.bg3, dark: nc.dark.bg3 },

  // TRANSPARENT
  { name: 'pageTransparent', light: `${PAGE.light}00`, dark: `${PAGE.dark}00` },
  { name: 'sheetTransparent', light: `${SHEET.light}00`, dark: `${SHEET.dark}00` },
  { name: 'flowNav', light: nc.light.bgGray, dark: nc.dark.bgGray },

  {
    name: 'veil',
    light: nc.light.veil,
    dark: nc.dark.veil,
  },
  {
    name: 'acrylic',
    light: '#FFFFFFAA',
    dark: '#3a3b3fAA',
  },

  { name: 'snow', light: colors.snow, dark: colors.snow },
  { name: 'ink', light: colors.ink, dark: colors.ink },

  { name: 'reverseText', light: nc.dark.fg, dark: nc.light.fg },
  {
    name: 'textSubtle',
    light: chroma(colors.ink).alpha(0.65).hex(),
    dark: chroma(colors.snow).alpha(0.6).hex(),
  },
  {
    name: 'secondary',
    light: chroma(colors.ink).alpha(0.75).hex(),
    dark: chroma(colors.snow).alpha(0.7).hex(),
  },
  {
    name: 'subtle',
    light: chroma(colors.ink).alpha(0.65).hex(),
    dark: chroma(colors.snow).alpha(0.6).hex(),
  },
  {
    name: 'subtler',
    light: chroma(colors.ink).alpha(0.55).hex(),
    dark: chroma(colors.snow).alpha(0.5).hex(),
  },
  {
    name: 'border',
    light: nc.light.border,
    dark: nc.dark.border,
  },
  {
    name: 'borderLight',
    light: colors['ink@5'],
    dark: colors['snow@5'],
  },
  {
    name: 'borderHover',
    light: colors['ink@17'],
    dark: colors['snow@17'],
  },
  {
    name: 'link',
    light: nc.light.brand,
    dark: nc.dark.brand,
  },
  {
    name: 'dev',
    light: nc.light.negative,
    dark: nc.dark.negative,
  },
  { name: 'positive', light: nc.light.positive, dark: nc.dark.positive },
  { name: 'positiveLight', light: nc.light.positiveLight, dark: nc.dark.positiveLight },
  { name: 'negative', light: nc.light.negative, dark: nc.dark.negative },
  { name: 'negativeLight', light: nc.light.negativeLight, dark: nc.dark.negativeLight },
  { name: 'focusLight', light: nc.light.brandLight, dark: nc.dark.brandLight },
  { name: 'error', light: nc.light.negative, dark: nc.dark.negative },
  { name: 'destruct', light: nc.light.negative, dark: nc.dark.negative },
  { name: 'debit', light: nc.light.positive, dark: nc.dark.positive },
  { name: 'debitLight', light: nc.light.positiveLight, dark: nc.dark.positiveLight },
  { name: 'credit', light: nc.light.negative, dark: nc.dark.negative },
  {
    name: 'creditLight',
    light: nc.light.negativeLight,
    dark: nc.dark.negativeLight,
  },
  { name: 'grayBg', light: colors['ink+5'], dark: colors['midnight+2'] },
  { name: 'gray', light: colors['ink+1'], dark: colors['ink+1'] },
  { name: 'switchOff', light: nc.light.fg3, dark: nc.dark.fg3 },
  { name: 'progressBg', light: nc.light.fg, dark: nc.dark.fg },
  { name: 'progressTrackBg', light: nc.light.border, dark: nc.dark.border },
  { name: 'coveredPlanCard', light: nc.light.positive, dark: nc.dark.positive },
  { name: 'coveredText', light: nc.light.positive, dark: nc.dark.positive },
];

export const { themeColorsLight, themeColorsDark } = SEMANTIC_COLORS.reduce(
  (acc, item) => {
    return {
      themeColorsLight: {
        ...acc.themeColorsLight,
        [`${item.name}Color`]: item?.light,
      },
      themeColorsDark: {
        ...acc.themeColorsDark,
        [`${item.name}Color`]: item?.dark,
      },
    };
  },
  {
    themeColorsLight: {
      skeletonFunction: (amt, color) => chroma(color).alpha(amt).hex(),
      hoverFunction: (amt, color) => chroma(color).alpha(amt).hex(),
    },
    themeColorsDark: {
      skeletonFunction: (amt, color) =>
        chroma(color)
          .alpha(1 - amt)
          .hex(),
      hoverFunction: (amt, color) =>
        chroma(color)
          .alpha(1 - amt)
          .hex(),
    },
  },
);

/**
 * Looks up a color by name
 * Checks the store to see whether we're in light mode or dark mode,
 * then returns the associated color
 * @param {Color} color
 */
export const colorLookup = (color) => {
  const name = `${color}Color`;

  return {
    light: themeColorsLight[name] || themeColorsLight[name?.toUpperCase] || themeColorsLight[color],
    dark: themeColorsDark[name] || themeColorsDark[name?.toUpperCase] || themeColorsDark[color],
  };
};
