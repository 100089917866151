import gql from 'graphql-tag';

const REMOVE_AVATAR = gql`
  mutation RemoveAvatarRequest($input: RemoveAvatarRequest!) {
    removeAvatar(input: $input)
  }
`;

export default {
  document: REMOVE_AVATAR,
};
