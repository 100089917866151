import { Text } from '@uikit';
import { capitalize } from '@app/utils';
import { Stack } from '@app/layouts';
import { FontSize } from '@app/types';
import { env } from '@app/global';

export function VersionLabel() {
  return (
    <Stack>
      <Text size={FontSize.sm} color="subtler">
        {capitalize(env.CATCH_ENV)} {env.VERSION_NUMBER} ({env.BUILD_NUMBER})
      </Text>
    </Stack>
  );
}
