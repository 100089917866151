import { ootIntl } from '../language';
import { capitalizeAll, deslug } from './strings';
import { Goal } from '@types';

/**
 * Formats the name of a benefit
 * - If a name is specified, we use the capitalized version of it
 * - Otherwise, we should default to the catch benefit name
 * - If benefit is not defined in copy sheet, make the type readable
 */
export const benefitName = (
  benefit: Pick<Goal, 'type' | 'goalType' | 'name' | 'slug'> | Goal,
  fallback = '',
) => {
  const { type, goalType, name, slug } = benefit || {};
  const specifiedType = type || goalType;

  if (name && specifiedType === 'CUSTOM_SAVINGS') return capitalizeAll(name)?.replace('Hsa', 'HSA');

  if (specifiedType) {
    return ootIntl.formatMessage({
      id: `catch.benefits.${specifiedType}`,
      defaultMessage: capitalizeAll(specifiedType.replace('_', ' ')),
    });
  }

  // default to the fallblack or "Benefit"
  return (
    deslug(slug) ||
    fallback ||
    ootIntl.formatMessage({ id: 'catch.benefits.fallback' })
  )?.replace('Hsa', 'HSA');
};

export const canDeleteBenefit = (goalType) => {
  return goalType === 'RETIREMENT' ? false : true;
};

export const formatBenefit = {
  name: benefitName,
};
