import React from 'react';
import { HiccupBlueprint } from '@app/blueprints';
import emails from '@app/constants/emails';
import { BankApplicationStatus, useFintechQuery } from '@app/data';
import { GET_MONEY_PAGE } from '@app/data/definitions/money';
import {
  BANK_APPLICATION_STATUSES_IN_PROGRESS,
  BANK_APPLICATION_STATUSES_NEGATIVE,
} from '@app/features/banking/utils/bankApplication';
import { Route } from '@app/types';
import { openIntercom, useCopy } from '@app/utils';
import { navigate } from '@navigate';
import Chat from '@svg/message-2.svg';
import Timer from '@svg/timer.svg';
import Warning from '@svg/warning-triangle.svg';

export function MoneyBankApplicationStatus() {
  const { c } = useCopy('catch');
  const { loading, data } = useFintechQuery(GET_MONEY_PAGE);

  const commonProps = {
    loading,
    onPress: () => navigate(Route.HOME),
  };

  if (
    BANK_APPLICATION_STATUSES_IN_PROGRESS.includes(
      data?.bankApplication?.status as BankApplicationStatus,
    )
  ) {
    return (
      <HiccupBlueprint
        {...commonProps}
        asset={{ svg: Timer }}
        title="We’re working on opening your Catch Money account."
        subtitles={['You’ll get an update from us soon!']}
        buttonText="Go home"
      />
    );
  }

  if (data?.bankApplication?.status === BankApplicationStatus.Denied) {
    return (
      <HiccupBlueprint
        {...commonProps}
        asset={{ svg: Warning }}
        title="Unfortunately, our systems were not able to confirm your identity"
        subtitles={[
          <>
            If you believe there was an error, please contact us at{' '}
            <a href={`mailto:${emails.HELP}`}>{emails.HELP}</a>.
          </>,
        ]}
        buttonText="Go home"
      />
    );
  }

  if (
    BANK_APPLICATION_STATUSES_NEGATIVE.includes(
      data?.bankApplication?.status as BankApplicationStatus,
    )
  ) {
    return (
      <HiccupBlueprint
        {...commonProps}
        asset={{ svg: Warning }}
        title="Something’s not quite right"
        subtitles={[
          'Don’t worry, your info and progress have been saved. We’re looking into what went wrong and will be in touch.',
        ]}
        actions={[
          {
            alt: true,
            inherit: true,
            label: c('basics.contactSupport'),
            svg: Chat,
            testID: 'support',
            onAction: openIntercom,
          },
        ]}
      />
    );
  }

  return null;
}
