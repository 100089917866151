interface Agreements {
  isPrivacyAuthorizeAgree?: boolean;
  isPrivacyDataUseAgree?: boolean;
  isPrivacyTruthfulAgree?: boolean;
}

export const hasAgreedToPrivacy = (agreements?: Agreements) => {
  return (
    !!agreements?.isPrivacyAuthorizeAgree &&
    !!agreements?.isPrivacyDataUseAgree &&
    !!agreements?.isPrivacyTruthfulAgree
  );
};
