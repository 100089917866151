import React from 'react';
import { Text, Input } from '@uikit';
import { safeFormatDate } from '@app/utils';
import { InputProps } from '../types';

interface SignatureInputProps<T> extends InputProps<T> {
  legalName: string;
}

const SignatureInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      name,
      value,
      label,
      placeholder,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      autoFocus,
      legalName,
      error,
    }: SignatureInputProps<T>,
    ref,
  ) => {
    return (
      <>
        <Input
          ref={ref}
          testID={testID}
          autoFocus={autoFocus}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          label="Enter your full legal name to sign"
          placeholder="Signature"
          error={error}
          disabled={disabled}
          fullWidth
          signature
          sub={
            <div>
              <div>
                <Text weight="medium" size="fp">
                  {legalName}
                </Text>
              </div>
              <div>
                <Text color="subtle" size="sm">
                  {safeFormatDate(new Date())}
                </Text>
              </div>
            </div>
          }
        />
      </>
    );
  },
);

SignatureInput.displayName = 'SignatureInput';
export default SignatureInput;
