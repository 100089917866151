import { InsuranceMarketType, Trinary, CoverageEndedReason } from '@app/types';
import { boolToTri } from '@app/utils';
import { ApolloApplicationMember, ApolloApplicationMemberInput } from '@data';

interface OtherCoverageFormValues {
  id: string;
  givenName: string;
  relation?: 'SELF' | 'SPOUSE' | 'CHILD';
  preliminaryCHIPStatus: PreliminaryStatus;
  isCoverageEnded?: Trinary;
  coverageEndedReason?: CoverageEndedReason;
  coverageEndedReasonText?: string;
  isEnrolledInHealthCoverage?: Trinary;
  otherCoverages?: Array<{
    insuranceMarketType: InsuranceMarketType;
    insurancePlanName?: string;
    insurancePolicyMemberID?: string;
    insurancePolicyNumber?: string;
  }>;
}

export const getInitialValues = (member: ApolloApplicationMember): OtherCoverageFormValues => {
  return {
    id: member.id,
    relation: member.relation,
    givenName: member.givenName || '',
    preliminaryCHIPStatus: member.preliminaryCHIPStatus,
    isCoverageEnded: boolToTri(member.isCoverageEnded),
    coverageEndedReason: member.coverageEndedReason,
    coverageEndedReasonText: member.coverageEndedReasonText,
    isEnrolledInHealthCoverage: boolToTri(member.isEnrolledInHealthCoverage),
    otherCoverages: member.otherCoverages || [],
  };
};

export const formatPayload = (values: OtherCoverageFormValues): ApolloApplicationMemberInput => {
  return {
    id: values.id,
    isCoverageEnded: values.isCoverageEnded,
    coverageEndedReason: values.coverageEndedReason,
    coverageEndedReasonText: values.coverageEndedReasonText,
    isEnrolledInHealthCoverage: values.isEnrolledInHealthCoverage,
    otherCoverages: values.isEnrolledInHealthCoverage === 'YES' ? values.otherCoverages : [],
  };
};
