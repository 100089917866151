import Env from '../env';
import Sentry from './Sentry';

const SENTRY_DSN = 'https://ae972e4ff7c44782a153a495aed7ad88@sentry.io/1363083';

export type FunctionalityProductTag = 'authentication' | 'goals' | 'health' | 'tax' | 'payroll';
export type FunctionalityFeatureTag =
  | 'login-link'
  | 'application'
  | 'eligibility'
  | 'enrollment'
  | 'explorer'
  | 'plan_search'
  | 'policy'
  | 'state_plans'
  | 'accounts'
  | 'customer';

/**
 * @todo actually use this
 * (although, prob mostly for backend anyway)
 */
export interface SentryFunctionalityTags {
  product?: FunctionalityProductTag;
  feature?: FunctionalityFeatureTag;
}

export const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: Env.isProd
      ? 'production'
      : Env.isStage
      ? 'stage'
      : Env?.isDev
      ? 'development'
      : 'local',
  });
};

/**
 * @name logSentryError
 */
export const logSentryError = (exception, extra, { tags = {} } = {}) => {
  try {
    const { product, feature }: SentryFunctionalityTags = tags || {};
    Sentry.configureScope((scope) => {
      if (extra && typeof extra === 'object') {
        Object.keys(extra).forEach((key) => {
          scope.setExtra(key, extra[key]);
        });
      }

      /**
       * Add enriched product/feature tagging
       * @see https://github.com/catch-co/platform/pull/6211/files
       */
      if (product) scope.setTag('product', product);
      if (feature) scope.setTag('feature', feature);

      Sentry.captureException(exception);
    });
  } catch (e) {}
};

/**
 * @name logGQLError
 */
export const logGQLError = (err, operation, { tags = {}, requestId = '' } = {}) => {
  try {
    const { product, feature }: SentryFunctionalityTags = tags || {};
    // Add scoped report details and send to Sentry
    Sentry.configureScope((scope) => {
      // // Annotate whether failing operation was query/mutation/subscription
      scope.setTag('kind', operation.operationName);
      scope.setTag('client', 'app');
      scope.setTransactionName(operation.operationName);

      // Log query and variables as extras
      scope.setExtra('variables', operation.variables);

      /**
       * Add enriched product/feature tagging
       * @see https://github.com/catch-co/platform/pull/6211/files
       */
      if (product) scope.setTag('product', product);
      if (feature) scope.setTag('feature', feature);

      const _msg = err?.message?.replace('ApolloError: ', '');

      const apiErrorCategory = _msg?.match(/(rpc|gQL)(?=\s+error:)/g)?.[0];
      const apiErrorCode = _msg?.match(/(?:code = )(.+)(?= desc =)/g)?.[0]?.replace('code = ', '');
      const apiErrorMessage = _msg
        ?.match(/(?:(desc = )|(Message: ))((\w| |'|:|\/)+)(?=(\n|\r|))/g)?.[0]
        ?.replace('desc = ', '')
        ?.replace('Message: ', '');

      if (err.path) {
        // We can also add the path as breadcrumb
        scope.addBreadcrumb({
          category: 'query-path',
          message: err.path.join('.'),
        });
      }

      if (apiErrorCategory) {
        scope.setTag('api.category', apiErrorCategory);
        scope.setTag('api.code', apiErrorCode);
        scope.setTag('api.message', apiErrorMessage);
      }
      if (requestId) {
        scope.setTag('api.reqId', requestId);
        scope.setExtra('requestId', requestId);
      }
      scope.setExtra('fullMessage', err.message);
      scope.setExtra('fullException', err);
      Sentry.captureMessage(apiErrorMessage || _msg || err.message);
    });
  } catch (e) {}
};

/**
 * @name setSentryUser
 */
export const setSentryUser = (user) => {
  Sentry.setUser(user);
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
};
