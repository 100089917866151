/**
 * Helper function to trim form values
 * This makes sure we don't have any trailing whitespace
 * This recursively iterates over arrays and objects to make
 * sure any possible nested values are properly trimmed as well
 */
export function trimValue(value) {
  if (typeof value === 'string') {
    return value.trim();
  } else if (Array.isArray(value)) {
    return value.map(trimValue);
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((acc, key) => {
      acc[key] = trimValue(value[key]);
      return acc;
    }, {});
  }

  return value;
}
