import { env } from '@global';
import { AgreementType } from '@data';
import { Link } from '@uikit';
import { useAgreement } from '@app/hooks';
import { LinkColor } from '@app/styles';

export interface LegalAgreementProps {
  type: AgreementType;
  color?: LinkColor;
}

/**
 * Displays a link to a legal agreement
 */
export const LegalAgreement = ({ type, color }: LegalAgreementProps) => {
  const { agreement } = useAgreement(type);

  if (!agreement) {
    console.warn(`Unable to display agreement ${type}`);
    return null;
  }

  return (
    <Link
      testID={`${type}-agreement`}
      color={color}
      inline
      href={`${env.WEBSITE_URL}/legal/${agreement.slug}`}
    >
      {agreement.title}
    </Link>
  );
};
