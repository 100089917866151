import { useCopy } from '@app/utils';
import { TransferAccount } from '../TransferStack';
import { ComplexRow } from '@app/_ui-kit';

export interface TransferAccountProps {
  account?: TransferAccount;
  onPress: () => void;
}

/**
 * Renders EITHER a goal or bank account for transfer purposes
 *
 */
export function TransferAccountRow({ account, onPress }: TransferAccountProps) {
  const { $ } = useCopy('catch');

  const sharedProps = {
    testID: `select-${account?.id || 'account'}`,
    inset: true,
    bg: 'skeleton',
    accessory: 'arrow',
    onPress,
  };

  if (account?.__typename === 'Goal') {
    return (
      <ComplexRow
        key={account.slug}
        label={account.name}
        sublabel={`${$(account?.availableBalance)} available`}
        asset={{
          render: account.type,
        }}
        {...sharedProps}
      />
    );
  }

  if (account?.__typename === 'LinkedAccount') {
    return (
      <ComplexRow
        label={account.name}
        sublabel={`ending in ${account.accountMask}`}
        // @todo: add institution logo here
        {...sharedProps}
      />
    );
  }

  return <ComplexRow {...sharedProps} label="Select account" />;
}
