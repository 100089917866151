import React, { useMemo } from 'react';
import { useCopy } from '@app/utils';
import { useQuery, HealthApplicationQuery, useMutation, mutations } from '@data';
import { navigate } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { Route } from '@types';

const PREFIX = 'catch.ede.CIC.addressEntry';

const CICAddressEntry = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { applicantID, initialValues } = useMemo(() => {
    const applicant = data?.viewerTwo?.health?.application?.applicant;

    return {
      applicantID: applicant?.id,
      initialValues: {
        homeAddress: applicant?.homeAddress,
        mailingAddress: applicant?.mailingAddress,
        mailingAddressOption:
          JSON.stringify(applicant?.homeAddress) === JSON.stringify(applicant?.mailingAddress),
      },
    };
  }, [data]);

  const [report, { loading }] = useMutation(mutations.REPORT_HEALTH_ENROLLMENT_CHANGES, {
    onCompleted: (data) => {
      const { applicationID } = data?.reportHealthEnrollmentChanges?.insuranceEnrollment || {};
      navigate(Route.CIC_MOVE_DATE_ENTRY, { applicationID });
    },
  });

  const onSubmit = async (values) => {
    if (JSON.stringify(values) === JSON.stringify(initialValues)) {
      navigate(Route.CIC_NO_CHANGE, { screenStep: 'movedParamsUnchanged' });
    } else {
      try {
        // Seems more performant to store these than to ask the platform for it
        const isSupported = !!data?.reference?.health?.ffmStates?.find((s) => {
          return s.code === values.homeAddress.state;
        });

        if (!isSupported) {
          navigate(Route.CIC_STATE_HANDOFF, { exchangeState: values.homeAddress.state });
          return;
        }

        report({
          variables: {
            input: {
              applicationInput: {
                coverageState: values.homeAddress.state,
              },
              memberInputs: [
                {
                  applicationMember: {
                    id: applicantID,
                    homeAddress: values.homeAddress,
                    mailingAddress: values.mailingAddressOption
                      ? values.homeAddress
                      : values.mailingAddress,
                    isMoved: 'YES',
                    previousZipcode: initialValues?.homeAddress?.zip,
                  },
                },
              ],
            },
          },
        });
      } catch (e) {}
    }
  };

  return (
    <BasicFormBlueprint
      loading={!data}
      submitting={loading}
      title={c('title')}
      formConfig={{
        onSubmit,
        initialValues,
        fields: [
          {
            testID: '5',
            copy: 'catch.ede.HealthEnrollment',
            name: 'homeAddress',
            type: 'address',
            required: true,
            label: { id: '5_Q' },
            help: { id: '5_T' },
            dependencies: ['coverageState', 'homeAddress'],
          },
          {
            testID: '7',
            copy: 'catch.ede',
            name: 'mailingAddressOption',
            type: 'option',
            required: true,
            allowFalseValues: true,
            options: [
              { label: 'Same as home address', value: true },
              { label: 'Different address', value: false },
            ],
            label: { id: 'HealthEnrollment.7_Q' },
            help: { id: 'HealthEnrollment.7_T' },
          },
          {
            testID: '8',
            copy: 'catch.ede.HealthEnrollment',
            name: 'mailingAddress',
            type: 'address',
            required: true, // required when showing
            dependencies: ['mailingAddressOption'],
            display: (values) => values?.mailingAddressOption === false,
            allowPOBox: true,
            label: { id: '8_Q' },
            help: { id: '8_T' },
          },
        ],
      }}
    />
  );
};

export const CICAddressEntryView = {
  name: Route.CIC_ADDRESS_ENTRY,
  component: CICAddressEntry,
  options: {},
};
