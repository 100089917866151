import { gql } from '../generated';

gql(`
  fragment BankAccount on BankAccount {
    id
    availableBalance
    pendingBalance
    totalBalance
  }
`);

export const GET_BANK_ACCOUNT = gql(`
  query GetBankAccount {
    bankAccount {
      ...BankAccount
    }
  }
`);
