import React from 'react';
import { View } from 'react-native';

import { useTheme } from '../hooks/useTheme';
import { Layout } from '../../layouts';
import { Pressable } from './Pressable';

import DefaultIcon from '@svg/sunrise.svg';

interface EmptyStateProps {
  title: string;
  subtitle?: string;
  icon?: any;
  onPress?: () => void;
  loading?: boolean;
  color?: any;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  icon = DefaultIcon,
  title,
  subtitle,
  onPress,
  loading,
  color = 'skeleton',
  ...rest
}) => {
  const { theme, themeColors } = useTheme();
  const handlePress = !loading ? onPress : undefined;

  return (
    <Pressable handleOnPress={handlePress}>
      {({ intrx }) => (
        <View
          style={[
            intrx.hovered ? theme.borderBg : { backgroundColor: themeColors[color + 'Color'] },
            theme.largeCorners,
            theme.xSpace4,
            theme.ySpace3,
            { opacity: 0.5 },
          ]}
        >
          <Layout.Header
            light
            sheet
            title={title}
            subtitle={subtitle}
            svg={icon}
            align="center"
            loading={loading}
            {...rest}
          />
        </View>
      )}
    </Pressable>
  );
};

export default EmptyState;
