enum PhoneValidationError {
  INVALID_LENGTH = 'INVALID_LENGTH',
  INVALID_AREA_CODE_START = 'INVALID_AREA_CODE_START',
  INVALID_AREA_CODE = 'INVALID_AREA_CODE',
  INVALID_EXCHANGE_CODE = 'INVALID_EXCHANGE_CODE',
  INVALID_PATTERN = 'INVALID_PATTERN',
}

const ERROR_MESSAGE: Record<PhoneValidationError, string> = {
  [PhoneValidationError.INVALID_LENGTH]: 'Phone number must be exactly 10 digits',
  [PhoneValidationError.INVALID_AREA_CODE_START]: 'Area code cannot start with 0 or 1',
  [PhoneValidationError.INVALID_AREA_CODE]: 'Invalid area code',
  [PhoneValidationError.INVALID_EXCHANGE_CODE]: 'Exchange code cannot start with 0 or 1',
  [PhoneValidationError.INVALID_PATTERN]: 'Invalid phone number pattern',
};

const INVALID_PATTERNS = new Set([
  '0000000000',
  '1111111111',
  '2222222222',
  '3333333333',
  '4444444444',
  '5555555555',
  '6666666666',
  '7777777777',
  '8888888888',
  '9999999999',
]);

const INVALID_AREA_CODES = new Set([
  '204',
  '226',
  '236',
  '242',
  '246',
  '249',
  '250',
  '263',
  '264',
  '268',
  '284',
  '289',
  '306',
  '343',
  '345',
  '354',
  '365',
  '367',
  '368',
  '382',
  '403',
  '416',
  '418',
  '428',
  '431',
  '437',
  '438',
  '441',
  '450',
  '468',
  '473',
  '474',
  '506',
  '514',
  '519',
  '548',
  '579',
  '581',
  '584',
  '587',
  '604',
  '613',
  '639',
  '647',
  '649',
  '664',
  '672',
  '683',
  '705',
  '709',
  '721',
  '742',
  '743',
  '753',
  '758',
  '767',
  '778',
  '780',
  '782',
  '784',
  '807',
  '809',
  '819',
  '825',
  '829',
  '849',
  '867',
  '868',
  '869',
  '873',
  '876',
  '879',
]);

export const validatePhone = (val: string = '') => {
  const rawString = val.replace(/-/g, '');
  if (rawString[0] === '0' || rawString[0] === '1') {
    return ERROR_MESSAGE.INVALID_AREA_CODE_START;
  }

  if (rawString.length >= 3 && INVALID_AREA_CODES.has(rawString.substring(0, 3))) {
    return ERROR_MESSAGE.INVALID_AREA_CODE;
  }
  if (rawString[3] === '0' || rawString[3] === '1') {
    return ERROR_MESSAGE.INVALID_EXCHANGE_CODE;
  }

  if (INVALID_PATTERNS.has(rawString)) {
    return ERROR_MESSAGE.INVALID_PATTERN;
  }

  if (rawString.length !== 10) {
    return ERROR_MESSAGE.INVALID_LENGTH;
  }
};
