import { gql } from '../generated';

gql(`
  fragment BaseExplorer on HealthExplorerData {
    id
    pathway
    state
    providerPlanID
    canCreateEnrollment
  }
`);

export const IMPORT_PUBLIC_EXPLORER = gql(`
  mutation ImportPublicExplorer($hid: ID!) {
    importExplorerFromPublic(id: $hid) {
      ...BaseExplorer
    }
  }
`);

export const UPSERT_EXPLORER = gql(`
  mutation UpsertExplorer($input: HealthExplorerDataInput!) {
    upsertHealthExplorerData(input: $input) {
      id
      pathwayType
      pathway
      zipcode
      countyfips
      state
      coverageYear
      providerPlanID
      income
      healthPlans {
        plans {
          id
        }
      }
    }
  }
`);
