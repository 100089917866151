import React from 'react';
import { View } from 'react-native';

import type { AssetProps } from '@types';
import { Icon, IconSvg, Image, Render } from './assets';
import { Skeleton } from '../Skeleton';
import { Pressable } from '../Pressable';
import { Text } from '../Text';
import { assets } from '@app/_ui-kit/theme/_constants';
import { useTheme } from '@uikit/hooks/useTheme';
import { benefitColorLookup, benefitIconLookup } from '@app/utils/color';
import Gradient from '../Gradient';
import { useLayoutContext } from '@app/layouts/LayoutContext';

import Skip from '@svg/catch-skip.svg';
import Roll from '@svg/catch-roll.svg';
import Fail from '@svg/close.svg';
import Clock from '@svg/clock.svg';
import Minus from '@svg/minus.svg';
import Check from '@svg/check.svg';
import Pending from '@svg/spinner.svg';

const badgeIcons = {
  scheduled: { icon: Clock },
  skipped: { color: '', icon: Skip },
  never: { color: '', icon: Minus },
  failed: { color: 'negative', icon: Fail },
  completed: { icon: Check },
  auto: { icon: Roll },
  deposit: { icon: Check },
  pending: { color: '', icon: Pending },
};

/**
 * Assets are images, logos, or icons
 * - the asset component handles universal sizing + styling across all of them
 *
 * Types of assets:
 * - base64: for example, bank logos returned from plaid
 * - url: an img url, for example the logos for paycheck sources
 * - icons: a react component for an icon
 * - text: for showing initials, etc. in center
 * - element: custom element inside
 */
const Asset = ({
  loading,
  testID,
  alt,
  ariaLabel,

  // asset source
  base64,
  icon,
  img,
  uri,
  text,
  render,
  element,
  svg,

  // sizing
  size = 'sm',
  containerSize,
  textSize,
  width = true, // if set to false, dont set width
  height = true, // if set to false, dont set height
  disabled = false,
  onPress,

  // additional styles
  shape, // one of ['circle', 'round', 'squircle']
  shadow,

  align,
  bg,
  bgHover,
  bgActive,
  color,
  fontWeight,
  style,
  containerStyle,
  borderColor,
  pressableProps = {},
  gradient,
  badge,
  absolute,

  ...rest // for IconSvg or Render props
}: AssetProps) => {
  const { theme, themeColors } = useTheme();

  // Needed a way to properly map the asset shape to the pressable corners
  // @todo: the shape prop should be deprecated in favor of corners I believe
  const corners = shape === 'circle' ? 'round' : shape === 'round' ? 'md' : 'none';
  const { accentColor } = useLayoutContext();

  /**
   * Defaults the asset to null
   * then, check for defined base64, icon, or url to display
   */
  let asset: any = null;

  if (uri) {
    asset = <Image alt={alt} uri={uri} size={size} />;
  }

  if (base64) {
    asset = <Image alt={alt} uri={`data:image/png;base64,${base64}`} size={size} />;
  }

  if (img) {
    asset = <Image alt={alt} img={img} size={size} />;
  }

  if (svg) {
    asset = <IconSvg svgComponent={svg} size={size} color={color} {...rest} />;
  }

  if (render) {
    asset = <Render name={render} size={size} {...rest} />;
  }

  if (icon) {
    asset = (
      <Icon
        icon={icon}
        size={typeof size === 'number' ? size : assets?.[size]}
        width={width}
        height={height}
        color={color}
      />
    );
  }

  if (text) {
    const fallbackTextSize = containerSize === 'lg' ? 'p' : 'fp';
    asset = (
      <Text size={textSize || fallbackTextSize} weight={fontWeight} align="center" color={color}>
        {text}
      </Text>
    );
  }

  if (element) {
    asset = element;
  }

  return (
    <Pressable
      testID={testID}
      handleOnPress={onPress}
      corners={corners}
      style={[
        { position: 'relative' },
        theme[`${containerSize || size}Asset`],
        !width && { width: 'auto' },
        !height && { height: 'auto' },
        align === 'center' && theme.centerSelf,
        absolute && theme.absolute,
        absolute && { top: 16, right: 16 }, // pins top right
        containerStyle,
      ]}
      hoveredStyle={onPress && [theme.buttonActive]}
      pressedStyle={onPress && [theme.pressedText, theme.buttonActive]}
      ariaLabel={ariaLabel}
      {...pressableProps}
    >
      {({ intrx }) => (
        <>
          {!!gradient && (
            <Gradient
              type="icon"
              gradient={gradient}
              style={[
                { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 },
                shape === 'circle' && { borderRadius: 9999 },
              ]}
            />
          )}
          <View
            style={[
              theme[`${containerSize || size}Asset`], // applies size
              shadow && theme[`${shadow}Shadow`], // applies shadow, if set
              shape === 'squircle' && theme.smallCorners,
              shape === 'round' && theme.mediumCorners,
              shape === 'circle' && { borderRadius: 9999 },
              (bg && !loading && theme[`${bg}Bg`]) || {
                backgroundColor: themeColors[bg] || themeColors[`${bg}Color`],
              },
              intrx.hovered && theme[`${bgHover || bg}Bg`],
              intrx.pressed && theme[`${bgActive || bg}Bg`],
              disabled && theme.disabled,
              {
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
              },
              !width && { width: 'auto' },
              !height && { height: 'auto' },
              style,

              borderColor && { borderWidth: 1, borderColor: themeColors[`${borderColor}`] },
            ]}
          >
            <Skeleton
              loading={loading}
              height={assets[containerSize || size]}
              width={assets[containerSize || size]}
              style={shape && theme[`${shape}Corners`]}
            >
              {asset}
            </Skeleton>
          </View>
          {badge && (
            <Gradient
              angle={180}
              color={badgeIcons[badge]?.color === '' ? 'grayBg' : undefined}
              gradient={
                badgeIcons[badge]?.color === ''
                  ? undefined
                  : badgeIcons[badge]?.color || accentColor
              }
              style={[
                {
                  position: 'absolute',
                  bottom: -2,
                  right: -2,
                  borderRadius: 9999,
                  padding: 2,
                },
              ]}
            >
              <IconSvg
                svgComponent={badgeIcons[badge]?.icon}
                size="mini"
                color={badgeIcons[badge]?.color === '' ? 'text' : 'white'}
              />
            </Gradient>
          )}
        </>
      )}
    </Pressable>
  );
};

Asset.configureBenefit = (benefitType, customIcon, size = 'sm'): AssetProps => ({
  element: <Render name={benefitIconLookup({ goalType: benefitType, customIcon })} size={size} />,
  bg: benefitColorLookup({ goalType: benefitType }) + 'LightColor',
  size: size,
  containerSize: 'lg',
  shape: 'circle',
});

export default Asset;
