import React from 'react';
import { Button, Banner } from '@uikit';
import { Toolbar, BasicLayout } from '@layouts';
import { useCopy } from '@app/utils';
import { close, navigate } from '@navigate';
import { Route } from '@types';

const PREFIX = 'catch.ede.CIC.statePickup';

const CICStatePickup = () => {
  const { c } = useCopy(PREFIX);

  return (
    <BasicLayout
      title={c('title')}
      subtitles={[c('subtitle')]}
      toolbar={
        <Toolbar type="stack">
          <Button wide alt gutter="bottom" onPress={() => navigate(Route.CIC_CANCEL_POLICY)}>
            {c('cancelPolicy')}
          </Button>
          <Button wide onPress={() => close()}>
            {c('done')}
          </Button>
        </Toolbar>
      }
    >
      <Banner title={c('caption')} />
    </BasicLayout>
  );
};

export const CICStatePickupView = {
  name: Route.CIC_STATE_PICKUP,
  component: CICStatePickup,
  options: {
    bg: 'page',
  },
};
