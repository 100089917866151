import gql from 'graphql-tag';

const REPORT_ENROLLMENT_CHANGES = gql`
  mutation _ReportHealthEnrollmentChanges($input: ReportHealthEnrollmentChangesInput!) {
    reportHealthEnrollmentChanges(input: $input) {
      insuranceEnrollment {
        id
        status
        applicationID
      }
      error {
        field
        message
        code
      }
    }
  }
`;

const formatter = (data) => {
  const applicationID = data?.reportHealthEnrollmentChanges?.insuranceEnrollment?.applicationID;
  const enrollId = data?.reportHealthEnrollmentChanges?.insuranceEnrollment?.id;
  return { applicationID, enrollId };
};

export default {
  document: REPORT_ENROLLMENT_CHANGES,
  formatter,
  getErrors: (data) => {
    if (!data?._reportHealthEnrollmentChanges?.error) return null;
    return [data._reportHealthEnrollmentChanges.error];
  },
};
