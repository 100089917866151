import React from 'react';
import { HiccupBlueprint } from '@blueprints';
import {
  ConversionStatusEnum,
  ConversionStatusType,
  HealthExplorerQuery,
  UpsertConversionStatus,
  useMutation,
  useQuery,
} from '@data';
import { ScreenDefinition } from '@navigate';
import { Segment, SegmentEvent } from '@util';
import { Route } from '@types';
import Concierge from '@svg/new-hire.svg';
import { Link, Loading } from '@app/_ui-kit';

const DEFAULT_HANDOFF_URL = 'https://www.healthsherpa.com/marketplace/health?_agent_id=catch';

const ExplorerPartnerHandoff = () => {
  const { loading, data } = useQuery(HealthExplorerQuery);
  const [upsertConversion] = useMutation(UpsertConversionStatus);

  const handoff = () => {
    window.open(data?.viewerTwo?.healthExplorerData?.handoffUrl || DEFAULT_HANDOFF_URL);

    Segment.track(SegmentEvent.SCREENING_HANDOFF_CLICKED, {
      coverage_year: data?.viewerTwo?.healthExplorerData?.coverageYear,
    });

    upsertConversion({
      variables: {
        input: {
          year: data?.viewerTwo?.healthExplorerData?.coverageYear,
          type: ConversionStatusType.Health,
          status: ConversionStatusEnum.Handoff,
        },
      },
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <HiccupBlueprint
      loading={loading}
      asset={{ svg: Concierge, color: 'text' }}
      title="Continue to apply"
      subtitles={[
        'You’ll be redirected to complete your application. First, view your selected plan in your cart, then click to start your application.',
        <>
          Need help? Contact us at{' '}
          <Link testID="help-phone" href="tel:+18333090500">
            833-309-0500
          </Link>
        </>,
      ]}
      actions={[
        {
          testID: 'handoff',
          type: 'CUSTOM',
          label: 'Continue',
          onAction: handoff,
        },
      ]}
    />
  );
};

export const ExplorerPartnerHandoffView: ScreenDefinition = {
  name: Route.EXPLORER_PARTNER_HANDOFF,
  component: ExplorerPartnerHandoff,
  options: {
    title: 'Continue to apply',
  },
};
