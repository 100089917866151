import React from 'react';
import { View } from 'react-native';
import { useTheme } from '../hooks/useTheme';
import { useHover } from '../hooks/useHover';
import { Text } from './Text';
import { Skeleton } from './Skeleton';
import { Pressable } from './Pressable';
import { useLayoutContext } from '@layouts';
import { openIntercom } from '@app/utils';

export const TouchableField = ({
  testID,
  onPress,
  label,
  sublabel,
  value,
  children,
  disabled,
  link,
  loading,
  editing,
  color,
  inherit,
}) => {
  const { theme } = useTheme();
  const [isHovered, handlers] = useHover(disabled);
  const context = useLayoutContext();
  const accentColor = inherit ? context?.accentColor : color;

  const isCopy = value?.type?.displayName === 'FormattedMessage';

  return (
    <Pressable
      testID={testID}
      {...handlers}
      handleOnPress={disabled ? () => openIntercom() : onPress}
      style={[
        disabled && theme.disabled,
        theme.touchableField,
        isHovered && theme.hoverBg,
        disabled && { cursor: 'not-allowed' },
        theme.fullWidth,
      ]}
    >
      {({ intrx }) => (
        <>
          <View style={theme.flex1}>
            <Text
              color={!link && !!intrx.hovered ? 'text' : 'subtle'}
              weight="medium"
              size="sm"
              gutter={!sublabel && 'bottom'}
            >
              {label}
            </Text>
            {sublabel && (
              <Text color="subtler" gutter="bottom" size="sm">
                {sublabel}
              </Text>
            )}
            <Skeleton loading={loading} fontSize="btn">
              {typeof value === 'string' || typeof value === 'number' || isCopy ? (
                <Text weight="medium" color={link && !intrx.hovered ? 'subtle' : 'text'}>
                  {value}
                </Text>
              ) : typeof value === 'object' && /Address/.test(value.type?.name) ? (
                value
              ) : typeof value === 'object' && /FormattedMessage/.test(value.type?.name) ? (
                value
              ) : Array.isArray(value) ? (
                <Text size="btn" weight="medium">
                  {value.map((value) => value)}
                </Text>
              ) : (
                value
              )}
              {children}
            </Skeleton>
          </View>
          <View>
            {!disabled && !editing && onPress && (
              <Text
                size="sm"
                weight="medium"
                color={accentColor ? accentColor : intrx.hovered ? 'text' : 'link'}
              >
                {link ? 'Add' : 'Edit'}
              </Text>
            )}
          </View>
        </>
      )}
    </Pressable>
  );
};

export default TouchableField;
