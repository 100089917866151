export type LinkName =
  | 'LEGAL'
  | 'HELP_DESK'
  | 'ABOUT_CATCH'
  | 'GUIDES'
  | 'CONTACT'
  | 'HEALTHSHERPA'
  | 'COVEREDCA';

export type ExternalLink = string;

export const links: Record<LinkName, ExternalLink> = {
  LEGAL: 'https://catch.co/legal',
  HELP_DESK: 'https://help.catch.co',
  ABOUT_CATCH: 'https://catch.co/about',
  GUIDES: 'https://catch.co/guides/health',
  CONTACT: 'https://catch.co/contact',
  HEALTHSHERPA: 'https://www.healthsherpa.com/?_agent_id=catch',
  COVEREDCA: 'https://www.coveredca.com/',
};
