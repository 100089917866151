import React from 'react';
import { SheetBlueprint } from '@blueprints';
import { Stack } from '@app/layouts';
import { ComplexRow } from '@app/_ui-kit';
import { useSortAndFilter } from '@app/hooks/useHealthPlansSortAndFilter';

const SilverPlanEligibleSheet = ({ handleNext, closeSheet }) => {
  const { setMetalLevel, resetFilters } = useSortAndFilter();

  const showSilverPlans = () => {
    resetFilters();
    setMetalLevel(['silver']);
  };

  return (
    <SheetBlueprint
      title="Want to save more?"
      subtitle="You can save money on out-of-pocket costs, like deductibles and copays - but only with a Silver plan."
    >
      <Stack>
        <ComplexRow
          inset
          badge="Recommended"
          label="Explore Silver plans for extra savings"
          sublabel="Save money on deductibles and copays when you get care."
          onPress={() => {
            closeSheet();
            showSilverPlans();
          }}
        />
        <ComplexRow
          inset
          label="Continue with this plan"
          sublabel="You won't save money on deductibles and copays."
          onPress={() => {
            closeSheet();
            handleNext();
          }}
        />
      </Stack>
    </SheetBlueprint>
  );
};

export default SilverPlanEligibleSheet;
