import { ApolloApplicationMember } from '@app/data';
import { getFullName } from '@app/utils';

type MemberFormData = {
  members: Array<{
    id: string;
    hasDeductions: boolean;
    fullName: string;
  }>;
};

type MemberPayload = Array<{ id: string; hasDeductions: boolean }>;

export const getInitialValues = (members: Array<ApolloApplicationMember>) => {
  return members.map((member) => ({
    id: member.id,
    hasDeductions: member.hasDeductions,
    fullName: getFullName(member),
  }));
};

export const formatPayload = (values: MemberFormData): MemberPayload => {
  return values.members.map((m) => ({
    id: m.id,
    hasDeductions: m.hasDeductions || false,
  }));
};
