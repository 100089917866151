import React from 'react';
import { Copy } from '@types';
import { Gradient, Loading, UIGradientName, useResponsive } from '@uikit';
import { Layout } from './Layout';
import { ToolbarProps } from './Toolbar';
import Page from './Page';
import LayoutContext, { useLayoutContext } from './LayoutContext';
import { theme } from '@app/_ui-kit/themes/catch';

type BasicLayoutVariant = 'confirm' | 'basic';

export interface BasicLayoutProps {
  showLoaderOnly?: boolean; // hides the header content while loading
  precomponent?: React.ReactNode;
  pretitle?: string;
  title?: Copy;
  titleSize?: string;
  subtitleSize?: string;
  subtitles?: Array<Copy>;
  loading?: boolean;
  centered?: boolean;
  toolbar: React.ReactElement<ToolbarProps>;
  children: any;
  variant?: BasicLayoutVariant;
  icon?: {
    svg: React.ReactElement;
    gradient?: UIGradientName;
  };
  style?: any;
}

const variants: Record<BasicLayoutVariant, { titleSize: string; spacing: boolean }> = {
  confirm: {
    titleSize: 'page',
    spacing: false,
  },
  basic: {
    titleSize: 'form',
    spacing: true,
  },
};

/**
 *
 * @todo abstract the "readable color" logic from Button.tsx
 * and use it here for the icon stroke color
 */
export const BasicLayout: React.FC<BasicLayoutProps> = ({
  showLoaderOnly,
  loading,
  precomponent,
  pretitle,
  title,
  subtitles,
  centered,
  toolbar,
  children,
  icon,
  style,
  variant = 'basic',
}) => {
  const { res, isDesktop, shortRange } = useResponsive(styles);
  const { accentColor, layout } = useLayoutContext();

  const hideHeader = showLoaderOnly && loading;
  const showToolbarInContent = isDesktop && layout === 'page';

  const config = variants[variant];

  //@todo clean up this logic, temporarily workaround to make Aetna theme have a white bg
  let gradientColor = `${accentColor}Light`;
  if (accentColor === 'aetna') {
    gradientColor = 'snow';
  }

  return (
    <Gradient gradient={layout !== 'page' ? undefined : `${gradientColor}`} style={{ flex: 1 }}>
      <Gradient
        color={layout === 'page' ? 'page' : 'sheet'}
        color2={layout === 'page' ? 'pageTransparent' : 'sheetTransparent'}
        style={{ flex: 1, paddingBottom: 32 }}
      >
        <Page>
          <Layout.Scroll center fullWidth bottomSpace topSpace>
            <Layout
              margins={layout === 'sheet'}
              color={
                layout === 'sheet' ? 'sheet' : shortRange === 'l' && layout === 'page' && 'card'
              }
              topSpace
              bottomSpace
              style={[
                layout !== 'sheet' && res.webContainer,
                layout !== 'sheet' && res.webContainerShadow,
                style,
              ]}
            >
              {precomponent}
              <Layout style={[isDesktop && layout !== 'sheet' && res.webContainerInner]}>
                {!!title && !hideHeader && (
                  <Layout.Header
                    light
                    pretitle={pretitle}
                    title={title}
                    subtitles={subtitles}
                    rightSpace={false}
                    titleSize={config.titleSize}
                    bottomSpace
                    align={centered ? 'center' : undefined}
                    asset={
                      !!icon && {
                        ...icon,
                        shape: 'circle',
                        size: 'md',
                        color: 'white',
                        containerSize: 'xl',
                      }
                    }
                  />
                )}
                {loading ? <Loading /> : <>{children}</>}
              </Layout>
              {showToolbarInContent && toolbar && (
                <LayoutContext context="sheet" accentColor={accentColor}>
                  <Layout style={[res.toolbarOffset]}>{React.cloneElement(toolbar)}</Layout>
                </LayoutContext>
              )}
            </Layout>
          </Layout.Scroll>
          {!showToolbarInContent && toolbar && (
            <LayoutContext context="sheet" accentColor={accentColor}>
              {React.cloneElement(toolbar, { fixed: true })}
            </LayoutContext>
          )}
        </Page>
      </Gradient>
    </Gradient>
  );
};

/**
 * this is styled to match the pxLayout of website
 * Certainly not the best implementation
 */
const styles = {
  webContainer: {
    desktop: {
      padding: theme.spacing.desktop.margins, // 64
      maxWidth: theme.containers.pageSheet, // 800
      marginHorizontal: 'auto',
      borderRadius: theme.spacing.desktop.margins, // 24
      overflow: 'hidden',
    },
    default: {
      padding: theme.spacing.desktop.margins, // 64
      marginHorizontal: 'auto',
    },
  },
  webContainerShadow: {
    desktop: {
      boxShadow: '0 25px 100px -10px rgb(0 0 0 / 5%)',
    },
    default: {},
  },
  webContainerInner: {
    desktop: {
      padding: theme.spacing.desktop.margins, // 64
    },
  },
  toolbarOffset: {
    desktop: { marginTop: 100 },
  },
};

export default BasicLayout;
