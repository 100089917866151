import React from 'react';
import { Layout, Stack } from '@layouts';
import { Copy } from '@types';

export interface PlanSummaryProps {
  title: Copy;
  subtitle: Copy;
  banners: Array<React.ReactNode>;
}

interface Props extends PlanSummaryProps {
  loading: boolean;
}

const PlanSummary: React.FC<Props> = ({ loading, title, subtitle, banners }) => {
  return (
    <Stack spacing="1">
      <Layout.Header
        align="center"
        titleSize="form"
        light
        loading={loading}
        title={title}
        subtitle={subtitle}
      />
      <Stack spacing="1">{banners}</Stack>
    </Stack>
  );
};

export default PlanSummary;
