import React from 'react';
import { KeyboardSet } from '@types';
import { FormLabel, Input } from '@uikit';
import { InputProps } from '../types';

interface TextInputProps<T> extends InputProps<T> {
  placeholder: string;
  keyboard?: KeyboardSet;
  format: (v: string) => string | null;
  characters?: RegExp; // when we only want to allow certain characters
  length?: number;
  icon?: React.Component;
}

export const parse = (val, { characters, length } = {}) => {
  const text = characters ? val?.replace(characters, '') : val; // strip down to
  return length ? text?.substring(0, length) : text;
};

const TextInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      keyboard,
      value,
      label,
      sublabel,
      optional,
      required,
      placeholder,
      disabled,
      onChange,
      onFocus,
      onBlur,
      error,
      help,
      characters,
      length,
      icon,
    }: TextInputProps<T>,
    ref,
  ) => {
    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
          required={required}
        />
        <Input
          ref={ref}
          testID={testID}
          autoFocus={autoFocus}
          keyboard={keyboard}
          name={name}
          value={value}
          disabled={disabled}
          onChange={(val) => onChange(parse(val, { characters, length }))}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          error={error}
          fullWidth
          icon={icon}
        />
      </>
    );
  },
);

TextInput.displayName = 'TextInput';
export default TextInput;
