import React from 'react';
import { useMutation, mutations, updates, useQuery, HealthExplorerQuery } from '@data';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { ScreenDefinition, pop } from '@navigate';
import { Route } from '@types';
import { BasicFormBlueprint } from '@app/blueprints';

const ExplorerUsage = ({ handleNext }) => {
  const { c } = useCopy('catch.healthExplorer.explorerUsage');
  const { data } = useQuery(HealthExplorerQuery);
  const [upsert, { loading: submitting }] = useMutation(mutations.UPSERT_HEALTH_PREFERENCE, {
    update: updates.UPSERT_HEALTH_PREFERENCE,
  });

  const options = [
    {
      id: 'REC_LOW',
      label: c('rarelyPrimary'),
      description: c('rarelySecondary'),
      value: 'REC_LOW',
    },
    {
      id: 'REC_MEDIUM',
      label: c('sometimesPrimary'),
      description: c('sometimesSecondary'),
      value: 'REC_MEDIUM',
    },
    {
      id: 'REC_HIGH',
      label: c('veryOftenPrimary'),
      description: c('veryOftenSecondary'),
      value: 'REC_HIGH',
    },
  ];

  const handleSubmit = ({ plannedUsage }) => {
    upsert({ variables: { input: { plannedUsage } } });

    Segment.track(SegmentEvent.HX_USAGE_COMPLETED, {
      planned_usage: plannedUsage,
      skipped: !plannedUsage,
      coverage_year: data?.viewerTwo?.healthExplorerData?.coverageYear,
    });

    handleNext({ plannedUsage, skipped: !plannedUsage });
  };

  return (
    <BasicFormBlueprint
      optional
      loading={false}
      submitting={submitting}
      title={c('title')}
      subtitle={c('subtitle')}
      onBack={pop}
      button="Skip"
      formConfig={{
        initialValues: {
          plannedUsage: data?.viewerTwo?.health?.healthPreference?.plannedUsage,
        },
        fields: [
          {
            type: 'option',
            name: 'plannedUsage',
            options,
          },
        ],
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const ExplorerUsageView: ScreenDefinition = {
  name: Route.EXPLORER_USAGE,
  component: ExplorerUsage,
  options: {},
};
