import React, { useState } from 'react';

import { useMutation, UploadAvatar, UploadAvatarRequest } from '@data';
import { SheetBlueprint } from '@app/blueprints';
import { Layout } from '@app/layouts';
import { FileDropper } from '@app/_common';

const UploadAvatarSheet = ({ id, closeSheet }) => {
  const [input, setInput] = useState<UploadAvatarRequest | null>();

  const [uploadAvatar, { loading: uploading }] = useMutation(UploadAvatar, {
    refetchQueries: ['User'],
    onCompleted: closeSheet,
  });

  return (
    <SheetBlueprint
      actions={[
        {
          label: 'Save Image',
          type: 'CUSTOM',
          disabled: !input || uploading,
          onAction: () => {
            if (input) {
              uploadAvatar({ variables: { input } });
            }
          },
        },
      ]}
    >
      <Layout topSpace centerAll margins>
        <FileDropper
          name="AVATAR"
          display="avatar"
          disabled={uploading}
          handleDelete={() => setInput(null)}
          filePrefix="ProfilePhoto"
          copyPrefix="catch.module.me.MeView"
          handleDrop={({ fileKey, fileType }) => {
            setInput({
              personID: id,
              imageFiletype: fileType,
              imageKey: fileKey,
            });
          }}
        />
      </Layout>
    </SheetBlueprint>
  );
};

export default UploadAvatarSheet;
