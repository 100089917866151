import gql from 'graphql-tag';
import { ApolloOpenEnrollmentDates, OpenEnrollmentFragment } from '../fragments';

export interface OpenEnrollmentQueryData {
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const OpenEnrollmentQuery = gql`
  query OpenEnrollmentQuery {
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
`;
