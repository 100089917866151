import React from 'react';
import { Layout, Narrow, Stack } from '@app/layouts';
import { Gradient, Text, LineItem, CatchTheme } from '@uikit';
import { useCopy } from '@app/utils';

const EXPERIAN_HOURS = {
  Weekdays: '8:30am - Midnight EST',
  Saturday: '10am - 8pm EST',
  Sunday: '11am - 7pm EST',
};

const OfflineVerification: React.FC<{ loading: boolean; referenceNumber: string }> = ({
  loading,
  referenceNumber,
}) => {
  const { c } = useCopy('catch.plans.PlanIdentityFailureView');

  return (
    <>
      <Layout margins topSpace bottomSpace>
        <Gradient gradient="brandLight" style={styles.gradientStyle}>
          <Text align="center" loading={loading} size="lg">
            {referenceNumber}
          </Text>
        </Gradient>
      </Layout>
      <Layout topSpace bottomSpace>
        <Narrow>
          <Stack topSpace separatorComponent spacing="2">
            <Text align="center" size="fp">
              {c('experianHours')}
            </Text>
            <Stack spacing="1">
              {Object.entries(EXPERIAN_HOURS).map(([day, hour], idx) => (
                <LineItem key={idx} left={day} right={hour} />
              ))}
            </Stack>
          </Stack>
        </Narrow>
      </Layout>
    </>
  );
};

const styles = {
  gradientStyle: {
    padding: 12,
    borderRadius: CatchTheme.corners.sm,
  },
};

export default OfflineVerification;
