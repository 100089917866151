import React, { useEffect, useMemo } from 'react';
import { FormLabel, Input, Text } from '@uikit';
import { CountiesQuery, useQuery } from '@data';
import { InputProps } from '../types';
import OptionInput from './OptionInput';
import { useCopy } from '@app/utils';
import { Stack } from '@app/layouts';
import { useAetna } from '@app/hooks';
import { AETNA_UNAVAILABLE_STATES } from '@app/constants/aetna';

interface ZipInputProps<T> extends InputProps<T> {
  large?: boolean;
}

const parseZip = (val: string): string => {
  return val?.replace(/\D+/g, '').slice(0, 5);
};

// value gets1 sec grab stored as...
// { zip: ___, county: ___, fips: ___, state: ___ }
const ZipInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      keyboard,
      value,
      label,
      sublabel,
      optional,
      required,
      placeholder,
      disabled,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
      onSubmitEditing,
      error,
      help,
      format,
      large,
    }: ZipInputProps<T>,
    ref,
  ) => {
    const { c } = useCopy('catch.basics');
    const { c: aetnaCopy } = useCopy('catch.aetna');
    const { inAetnaWhiteLabelFlow } = useAetna();

    const validZip = useMemo(() => {
      return value && value?.zip?.length === 5;
    }, [value]);

    const { data } = useQuery(CountiesQuery, {
      variables: { zipcode: value?.zip },
      skip: !validZip,
    });

    const { counties, countiesByFips, countiesByName, matchMultiple, county, aetnaUnavailable } =
      useMemo(() => {
        const ffmStates = data?.reference?.health?.ffmStates || [];

        const counties = (data?.reference.counties || [])?.map((c, i) => ({
          ...c,
          pathway: !!ffmStates?.find((s) => s.code === c.state) ? 'EDE' : 'STATE_EXCHANGE',
        }));

        const countiesByFips = counties.reduce((acc, county, i) => {
          return { ...acc, [county.fips]: county };
        }, {});

        const countiesByName = counties.reduce((acc, county, i) => {
          return { ...acc, [county.name]: county };
        }, {});

        const aetnaUnavailable =
          inAetnaWhiteLabelFlow && value?.state && AETNA_UNAVAILABLE_STATES[value.state];

        return {
          counties,
          countiesByFips,
          countiesByName,
          matchMultiple: counties?.length > 1,
          hasMatches: counties?.length > 0,
          county: counties?.length === 1 && validZip ? counties[0] : undefined,
          aetnaUnavailable,
        };
      }, [data]);

    // handles setting the county when there is only one
    useEffect(() => {
      onChange({
        zip: value?.zip,
        county: county?.name,
        fips: county?.fips,
        state: county?.state,
        pathway: county?.pathway,
      });
    }, [county]);

    //
    useEffect(() => {
      let county;

      // if fips is set, but no county name -> set it!
      if (value.fips && !value.county && countiesByFips) {
        county = countiesByFips[value?.fips];
      }

      // if county name is set, but no fips -> set it!
      if (value.county && !value.fips && countiesByName) {
        county = countiesByName[value.county];
      }

      if (county) {
        onChange({
          zip: value?.zip,
          county: county?.name,
          fips: county?.fips,
          state: county?.state,
          pathway: county?.pathway,
        });
      }
    }, [value, countiesByFips, countiesByName]);

    return (
      <Stack spacing="2">
        <div>
          <FormLabel
            testID={testID}
            label={label}
            sublabel={sublabel}
            help={help}
            optional={optional}
            required={required}
          />
          <Input
            ref={ref}
            testID={testID}
            name={name}
            autoFocus={autoFocus}
            keyboard="zip"
            large={large}
            value={value?.zip || ''}
            onChange={(val) =>
              onChange({
                zip: parseZip(val),
                county: undefined,
                fips: undefined,
                state: undefined,
              })
            }
            onFocus={onFocus}
            disabled={disabled}
            onBlur={onBlur}
            placeholder="00000"
            format={format}
            fullWidth
            error={error}
            sub={
              <>
                {!!validZip && !data && <Text size="fp">Checking...</Text>}

                {!!data && !matchMultiple && (
                  <Text size="fp" gutter="bottom">
                    {county ? `${county?.name}, ${county?.state}` : 'Not a valid zip code'}
                  </Text>
                )}
              </>
            }
          />
        </div>
        {matchMultiple && (
          <div>
            <OptionInput
              testID="county-lookup"
              label={c('multipleCounties')}
              name="county-lookup"
              value={value?.fips}
              onChange={(fips) => {
                const county = countiesByFips[fips];
                onChange({
                  ...value,
                  county: county?.name,
                  fips: county?.fips,
                  state: county?.state,
                  pathway: county?.pathway,
                });
              }}
              options={counties.map((county) => ({
                label: `${county.name}, ${county.state}`,
                value: county.fips,
              }))}
            />
          </div>
        )}
        {aetnaUnavailable && (
          <Stack>
            <Text weight="semibold">{aetnaCopy('unavailableState.title')}</Text>
            <Text>{aetnaCopy('unavailableState.description')}</Text>
          </Stack>
        )}
      </Stack>
    );
  },
);

ZipInput.displayName = 'ZipInput';
export default ZipInput;
