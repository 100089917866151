import { store } from '../store/store';

export const useNavigation = () => {
  return {
    setOptions: (props) => {
      const screen = Object.keys(props)?.[0];
      if (!screen) return;

      const { optionsByScreen } = store.getState();

      store.setState({
        lastUpdated: new Date(),
        optionsByScreen: {
          ...optionsByScreen,
          [screen]: {
            ...optionsByScreen[screen],
            ...props[screen],
          },
        },
      });
    },
  };
};
