import { GoalType } from '@types';

const names: Record<GoalType, string | undefined> = {
  TAX: 'Taxes',
  PTO: 'Time Off',
  RETIREMENT: 'Retirement',
  FAMILY_LEAVE: 'Family Leave',
  HEALTH_EXPENSES: 'Health Expenses',
  EMERGENCY_SAVINGS: 'Emergency Savings',
  CUSTOM_SAVINGS: undefined,
};

export const formatGoalName = (goal: { goalType: GoalType; name?: string }): string => {
  return names[goal.goalType] || goal.name || '';
};
