import React from 'react';
import { useMutation, mutations, updates, useQuery, HealthExplorerQuery } from '@data';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { ScreenDefinition, pop } from '@navigate';
import { Route } from '@types';
import { BasicFormBlueprint } from '@app/blueprints';

const ExplorerRisk = ({ handleNext }) => {
  const { c } = useCopy('catch.healthExplorer.explorerRisk');
  const { data } = useQuery(HealthExplorerQuery);
  const [upsert, { loading: submitting }] = useMutation(mutations.UPSERT_HEALTH_PREFERENCE, {
    update: updates.UPSERT_HEALTH_PREFERENCE,
  });

  const options = [
    {
      id: 'LOW_PREMIUM',
      label: c('premiumPrimary') || '',
      description: c('premiumSecondary') || '',
      value: 'LOW_PREMIUM',
    },
    {
      id: 'BALANCED',
      label: c('balancedPrimary') || '',
      description: c('balancedSecondary') || '',
      value: 'BALANCED',
    },
    {
      id: 'LOW_DEDUCTIBLE',
      label: c('deductiblePrimary') || '',
      description: c('deductibleSecondary') || '',
      value: 'LOW_DEDUCTIBLE',
    },
  ];

  const handleSubmit = ({ financialPreference }) => {
    if (financialPreference) {
      upsert({ variables: { input: { financialPreference } } });
    }

    Segment.track(SegmentEvent.HX_PRIORITY_COMPLETED, {
      financial_preference: financialPreference,
      skipped: !financialPreference,
      coverage_year: data?.viewerTwo?.healthExplorerData?.coverageYear,
    });

    handleNext({ financialPreference, skipped: !financialPreference });
  };

  return (
    <BasicFormBlueprint
      loading={false}
      submitting={submitting}
      optional
      title={c('title')}
      subtitle={c('subtitle')}
      onBack={pop}
      button="Skip"
      formConfig={{
        initialValues: {
          financialPreference: data?.viewerTwo?.health?.healthPreference?.financialPreference,
        },
        fields: [
          {
            type: 'option',
            name: 'financialPreference',
            options,
          },
        ],
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const ExplorerRiskView: ScreenDefinition = {
  name: Route.EXPLORER_RISK_TOLERANCE,
  component: ExplorerRisk,
  options: {},
};
