import React from 'react';

import { useCopy } from '@app/utils';
import { default as ModalNav } from '../ModalNav';
import { useLayoutContext } from '@app/layouts/LayoutContext';

/**
 * @deprecated
 */
const EditNav = ({ onClose, handleCancel, handleSave, disabled, isEditing, toggle }) => {
  const { accentColor } = useLayoutContext();
  const { c } = useCopy('catch.basics');
  const onEdit = () => toggle(true);

  const onCancel = () => {
    toggle(false);
    handleCancel();
  };

  const onSave = () => {
    toggle(false);
    handleSave();
  };

  const save = { text: c('save'), color: accentColor || 'link' };
  const edit = { text: c('edit'), color: accentColor || 'link' };
  const cancel = { text: c('cancel') };
  const close = { icon: 'close' };

  return (
    <ModalNav
      onLeft={isEditing ? onCancel : onClose}
      left={isEditing ? cancel : close}
      right={isEditing ? save : edit}
      onRight={isEditing ? onSave : onEdit}
      disabledRight={disabled}
    />
  );
};

export default EditNav;
