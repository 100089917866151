import { Trinary } from '@app/types';
import { boolToTri } from '@app/utils';

interface FormValues {
  id: string;
  fullName: string;
  isCoveredDependentChild: boolean;
}

interface PayloadValues {
  variables: {
    input: {
      applicationID: string;
      applicationMembersInput: Array<{
        id: string;
        isCoveredDependentChild: Trinary;
      }>;
    };
  };
}

export const getInitialValues = (member): FormValues => {
  return {
    id: member?.id,
    fullName: member?.legalName,
    isCoveredDependentChild: member?.isCoveredDependentChild,
  };
};

// updated to handle new indicators
export const formatPayload = ({ applicationID, values }): PayloadValues => {
  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: values.members.map((m) => ({
          id: m?.id,
          isCoveredDependentChild: boolToTri(m?.isCoveredDependentChild),
        })),
      },
    },
  };
};
