import React, { useCallback } from 'react';
import { Route } from '@types';
import { FintechWrapper } from '@app/components/wrappers';
import {
  navigate,
  Stack,
  StackComponent,
  StackDefinition,
  stacks,
  useCurrentRoute,
} from '@navigate';

import { GoalIntroView } from './routes/GoalIntroView';
import { GoalEstimatorView } from './routes/GoalEstimatorView';
import {
  GoalEmploymentTypeView,
  GoalEstimatedIncomeView,
  GoalTaxFilingStatusView,
  GoalDependentsView,
  GoalWorkStateView,
  GoalSpouseIncomeView,
} from './routes/GoalSetupInfoView';

import { GoalConfirmView } from './routes/GoalConfirmView';
import {
  GET_GOAL_SETUP_DETAILS,
  useQuery,
  FilingStatus,
  WorkType,
  useFintechQuery,
  GET_GOAL_BY_SLUG,
  GET_BANK_ACCOUNT,
} from '@app/data';
import { getGoalTypeFromSlug, GOAL_TYPE_TO_COLOR } from '@app/constants/goals';
import { useLocation } from 'react-router';

const config: StackDefinition = {
  stackName: stacks.GOAL_SETUP_STACK,
  options: {
    layout: 'page',
    navMode: 'flow',
    buttons: { help: true },
    accentColor: ({ slug }) => {
      const goalType = getGoalTypeFromSlug(slug);
      return GOAL_TYPE_TO_COLOR[goalType];
    },
  },
  screens: [
    GoalIntroView,
    GoalEmploymentTypeView,
    GoalEstimatedIncomeView,
    GoalWorkStateView,
    GoalTaxFilingStatusView,
    GoalSpouseIncomeView,
    GoalDependentsView,
    GoalEstimatorView,
    GoalConfirmView,
  ],
};

export interface HandleNextUpdates {
  slug?: string;
  filingStatus?: FilingStatus;
  workType?: WorkType;
}

const GoalSetupStack: StackComponent = (props) => {
  const route = useCurrentRoute();
  const location = useLocation();
  const slug = location?.state?.slug;

  // prefetches data that is going to be used throughout the stack
  const setup = useQuery(GET_GOAL_SETUP_DETAILS);
  // @todo had to adjust `fetchPolicy` because data was wrong until refresh
  const { data: bankAccountData } = useFintechQuery(GET_BANK_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
  });

  useFintechQuery(GET_GOAL_BY_SLUG, {
    variables: { slug },
    skip: !slug,
  });

  const isGoalOnboarded =
    setup.data?.me?.user?.filingStatus &&
    setup.data?.me?.user?.workState &&
    setup.data?.me?.user?.workType &&
    setup.data?.me?.user?.income?.estimatedGrossIncome;

  const isBankingSetup = !!bankAccountData?.bankAccount;

  const handleNext = useCallback(
    (updates?: HandleNextUpdates) => {
      switch (route) {
        case Route.GOAL_INTRO:
          navigate(isGoalOnboarded ? Route.GOAL_ESTIMATOR : Route.GOAL_EMPLOYMENT_TYPE, {
            slug: updates?.slug,
          });
          break;
        case Route.GOAL_EMPLOYMENT_TYPE:
          navigate(Route.GOAL_ESTIMATED_INCOME);
          break;
        case Route.GOAL_ESTIMATED_INCOME:
          navigate(Route.GOAL_WORK_STATE);
          break;
        case Route.GOAL_WORK_STATE:
          navigate(Route.GOAL_TAX_FILING_STATUS);
          break;
        case Route.GOAL_TAX_FILING_STATUS:
          if (!updates?.filingStatus) {
            console.error('Filing status not passed to handleNext');
            break;
          }

          const isMarried = [FilingStatus.Married, FilingStatus.MarriedSeparately].includes(
            updates?.filingStatus,
          );

          navigate(isMarried ? Route.GOAL_SPOUSE_INCOME : Route.GOAL_DEPENDENTS);

          break;
        case Route.GOAL_SPOUSE_INCOME:
          navigate(Route.GOAL_DEPENDENTS);
          break;
        case Route.GOAL_DEPENDENTS:
          navigate(Route.GOAL_ESTIMATOR);
          break;
        case Route.GOAL_ESTIMATOR:
          if (!isBankingSetup) {
            navigate(Route.BANKING_SETUP_INTRO);
          } else {
            navigate(Route.GOAL_CONFIRM);
          }
          break;
        case Route.GOAL_CONFIRM:
          navigate(Route.MONEY);
          break;

        default:
          console.log({ route });
          break;
      }
    },
    [route, isGoalOnboarded],
  );

  return (
    <FintechWrapper>
      <Stack
        stackName={config.stackName}
        screens={config.screens}
        options={config.options}
        data={{ handleNext }}
      />
    </FintechWrapper>
  );
};

GoalSetupStack.config = config;
export default GoalSetupStack;
