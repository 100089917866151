import { gql } from '../generated';

export const GetPartner = gql(`
  query Partner($slug: String) {
    partner(where: { slug: $slug }) {
      id
      slug
      name
      website
      color {
        hex
      }
      color2 {
        hex
      }
      colorLight {
        hex
      }
      logo {
        url
      }
    }
  }
`);
