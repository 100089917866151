import { Route } from '@types';
import { ScreenDefinition } from '@navigate';
import { AddBankLinkPage } from '@app/components/bankLink';
import { FintechWrapper } from '@app/components/wrappers';

export const AddBankLinkView: ScreenDefinition = {
  name: Route.ADD_BANK_LINK,
  component: (props) => (
    <FintechWrapper>
      <AddBankLinkPage {...props} />
    </FintechWrapper>
  ),
  options: {},
};
