import React from 'react';
import { SafeAreaView, View } from 'react-native';
import { BottomActions } from '@app/_common/layouts/BottomActions';
import { Layout, Stack } from '@layouts';
import { SheetName } from '@navigate';
import { Action, AssetProps, Copy } from '@types';

interface Props {
  actions?: Action[];
  asset?: AssetProps;
  children: any;
  color?: string;
  layout?: 'modal' | 'sheet';
  name: SheetName;
  subtitle?: Copy;
  title: Copy;
}

/**
 * @todo add sticky header, disclaimers, etc
 */
export const GuideBlueprint: React.FC<Props> = ({
  actions,
  asset,
  children,
  color,
  name,
  subtitle,
  title,
}) => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Layout.Scroll>
        <Stack spacing="2">
          <Layout color={color} margins topSpace>
            <Layout.Header
              asset={asset}
              light
              spaced
              subtitle={subtitle}
              title={title}
              titleSize="form"
            />
          </Layout>
          <Layout bottomSpace margins>
            <Stack>{children}</Stack>
          </Layout>
        </Stack>
      </Layout.Scroll>
      {!!actions && actions?.length > 0 && (
        <View>
          <BottomActions actions={actions} />
        </View>
      )}
    </SafeAreaView>
  );
};

export default GuideBlueprint;
