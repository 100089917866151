import gql from 'graphql-tag';

export interface RemoveExplorerApplicantVars {
  applicantID: string;
}

export interface RemoveExplorerApplicantResponse {
  deleteHealthExplorerDependents: Array<{
    id: string;
  }>;
}

export const RemoveExplorerApplicant = gql`
  mutation RemoveExplorerApplicant($applicantID: ID!) {
    deleteHealthExplorerDependents(ids: [$applicantID]) {
      id
    }
  }
`;
