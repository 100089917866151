import { FieldsConfig } from '@types';

/**
 * Whether to show/hide the submit button
 * The submit button is generally hidden for forms with only a single select option
 * cc @design
 */
export const shouldShowSubmitButton = (fields: FieldsConfig): boolean => {
  if (fields.length === 1) {
    const isRequiredOption = fields[0].type === 'option' && fields[0].required;
    return isRequiredOption ? false : true;
  }

  return true;
};

/**
 * Whether changing the field value automatically submits the form
 * Single select options should always auto submit when pressed
 * cc @design
 */
export const shouldAutoSubmitForm = (fields: FieldsConfig): boolean => {
  if (fields.length === 1) {
    return fields[0].type === 'option';
  }

  return false;
};
