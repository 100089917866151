import React from 'react';

import { useCopy } from '@app/utils';
import { ExplainerIntroBlueprint } from '@blueprints';
import { Toolbar } from '@layouts';
import { Button } from '@uikit';
import { Route } from '@types';

import Money from '@svg/dollar-2.svg';
import Plan from '@svg/gallery-view.svg';
import Shield from '@svg/shield-tick.svg';

/**
 *
 */
export const HealthExplorerIntro = ({ handleNext }) => {
  const { c } = useCopy('catch');

  return (
    <ExplainerIntroBlueprint
      context="page"
      title={c('healthExplorer.explorerIntro.title')}
      color="coverage"
      render="coverage"
      gradient="coverageLight"
      bullets={[
        {
          id: 'bullet1',
          label: c('healthExplorer.explorerIntro.estimate'),
          svg: Money,
        },
        {
          id: 'bullet2',
          label: c('healthExplorer.explorerIntro.recommendation'),
          svg: Plan,
        },
        {
          id: 'bullet3',
          label: c('healthExplorer.explorerIntro.enroll'),
          svg: Shield,
        },
      ]}
      toolbar={
        <Toolbar narrow type="stack">
          <Button testID="continue" wide onPress={handleNext}>
            {c('continue')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const ExplorerIntroView: ScreenDefinition = {
  name: Route.EXPLORER_INTRO,
  component: HealthExplorerIntro,
  options: {
    title: 'Explore Health Plans',
    drawBehindNav: true,
  },
};
