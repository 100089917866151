import React, { useMemo } from 'react';
import { ComplexRow } from '@uikit';
import { open } from '@navigate';
import { formatStatus } from '@app/utils/format/health';
import { PolicyStatusType } from '@types';
import { getCarrierAsset } from '@app/utils';
import { Route } from '@types';

export interface Policy {
  id: string;
  coverageYear: number;
  planName: string;
  carrier: string;
  premium: number;
  premiumEffective: number;
  policyStatus: PolicyStatusType;
  enrollmentID: string;
  productType: 'PLANTYPE_HEALTH' | 'PLANTYPE_DENTAL' | 'HEALTH_INSURANCE' | 'DENTAL_INSRUANCE';
}

interface PolicyRowProps {
  testID?: string;
  policy: Policy;
  interactive?: boolean;
}

/**
 * PolicyRow
 */
const PolicyRow: React.FC<PolicyRowProps> = ({ testID, policy, interactive = true }) => {
  const type: 'dental' | 'health' = useMemo(() => {
    return /DENTAL/.test(policy.productType) ? 'dental' : 'health';
  }, [policy]);

  const onPress = useMemo(() => {
    if (!interactive) return undefined;

    return () =>
      open(Route.COVERAGE_DETAIL, { type, policyID: policy.id, enrollId: policy.enrollmentID });
  }, [interactive, policy]);

  return (
    <ComplexRow
      disabled={/TERMINATED|CANCELLED/.test(policy?.policyStatus)}
      testID={testID || policy.id}
      key={policy.id}
      label={policy.planName}
      sublabel={formatStatus(policy.policyStatus)}
      onPress={onPress}
      asset={getCarrierAsset(policy.carrier, policy.productType, 'lg')}
    />
  );
};

export default PolicyRow;
