import gql from 'graphql-tag';

const OPT_OUT_AUTO_RENEWAL = gql`
  mutation OptOutAutoRenewal($id: ID!) {
    optOutAutoRenewal(id: $id) {
      insuranceEnrollment {
        id
        status
        isOptOutRenewal
      }
      error {
        field
        message
        code
      }
    }
  }
`;

export default {
  document: OPT_OUT_AUTO_RENEWAL,
  getErrors: (data) => (data?.optOutAutoRenewal?.error ? [data?.optOutAutoRenewal?.error] : null),
};
