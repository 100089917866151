import { useState } from 'react';
import { HiccupBlueprint } from '@blueprints';
import { ScheduleButton } from '@app/components/schedule';
import { ConciergeType, useMutation, RequestConcierge } from '@app/data';
import { Button, Loading } from '@uikit';
import { Stack } from '@layouts';
import { Route } from '@types';
import Concierge from '@svg/new-hire.svg';

const ExplorerConcierge = () => {
  const [completed, setCompleted] = useState(false);

  const [requestEmailConcierge] = useMutation(RequestConcierge, {
    variables: { input: { conciergeType: ConciergeType.Email } },
  });

  if (completed) {
    return (
      <Loading
        full
        page
        status="done"
        titles={{ done: 'Talk to you soon' }}
        subtitles={{ done: 'Your Benefits Advisor is working on your recommendations' }}
      />
    );
  }

  return (
    <HiccupBlueprint
      asset={{ svg: Concierge, gradient: 'coverageLight' }}
      title="How should your Benefits Advisor get in touch?"
      subtitles={['Select your communication preference below']}
      actions={[]}
    >
      <Stack spacing="3" separatorComponent>
        <ScheduleButton onScheduled={() => setCompleted(true)}>
          I&apos;d like to schedule a call
        </ScheduleButton>
        <Button testID="start-recs-workflow" onPress={requestEmailConcierge}>
          I prefer email
        </Button>
      </Stack>
    </HiccupBlueprint>
  );
};

export const ExplorerConciergeView = {
  name: Route.EXPLORER_CONCIERGE,
  component: ExplorerConcierge,
  options: {},
};
