import { gql } from '../generated';

export const UserQuery = gql(`
  query User {
    viewer {
      id
      user {
        id
        nickname
        legalName @client
        givenName
        familyName
        email
        phoneNumber
        avatarImage
        dob
        occupation
        workType
        filingStatus
        income {
          estimated1099Income
          estimatedW2Income
          estimatedGrossIncome
          spouseIncome
        }
        workState
        numTaxDependents
        signupContext
        referralCode
        dba
        ein
        ssn
        legalAddress {
          street1
          street2
          city
          state
          zip
        }
        createdOn
      }
    }
  }
`);
