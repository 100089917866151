import React from 'react';
import { View } from 'react-native';
import { Layout, Page, Container } from '@layouts';
import { useResponsive, s, useTheme } from '@uikit';

import ProfileMenu from '../sections/ProfileMenu';

const SettingsLayout = ({ title, children }) => {
  const { res, isDesktop } = useResponsive(responsiveMap);
  const { theme, showLayout } = useTheme();
  return (
    <Page>
      <Container>
        <View style={[res.container, s?.transitionBg, showLayout && theme._overlay]}>
          {isDesktop && (
            <View>
              <ProfileMenu />
            </View>
          )}
          <Layout.Scroll>
            <Layout topSpace bottomSpace margins>
              {!!title && <Layout.Header topSpace titleSize="form" title={title} />}
              {children}
            </Layout>
          </Layout.Scroll>
        </View>
      </Container>
    </Page>
  );
};

const responsiveMap = {
  container: {
    default: {
      width: '100%',
      flex: 1,
      height: '100%',
    },
    desktop: {
      flexDirection: 'row',
    },
  },
};

export default SettingsLayout;
