import createIconComponent from '../../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Kansas = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      colorable: true,
      d: 'M10.2324 63.3789L222.644 63.5707C222.644 63.5707 223.695 65.2412 224.394 65.439C225.094 65.6367 226.144 66.3244 225.882 67.0121C225.619 67.6997 226.755 68.5852 227.281 68.0952C227.806 67.6023 227.369 67.9978 228.154 68.5881C228.942 69.1784 231.566 69.7687 231.566 68.5881C231.566 67.4076 234.541 66.9176 234.715 68.6855C234.889 70.4563 236.114 70.356 236.028 71.4391C235.943 72.5223 236.902 73.3074 235.677 74.4879C234.452 75.6685 234.627 74.5853 233.579 76.9464C232.528 79.3075 230.866 77.4393 231.041 79.5023C231.215 81.5683 229.99 81.9608 229.379 81.8635C228.768 81.7661 228.942 83.9294 229.73 84.5197C230.518 85.11 230.604 85.7977 231.392 86.6831C232.18 87.5685 233.316 87.3707 233.841 88.5513C234.367 89.7319 236.639 89.8292 235.94 91.0098C235.24 92.1904 234.715 92.7806 236.377 93.8608C238.038 94.9411 238.038 94.7463 237.95 96.222C237.861 97.6977 238.387 97.3051 239.349 97.7951C240.311 98.285 240.662 99.861 241.447 99.2707C242.232 98.6805 242.409 98.9756 243.546 99.7636C244.682 100.552 245.47 99.9614 245.033 101.534C244.596 103.108 244.596 191.13 244.596 191.13L10.6899 191.623L10.2324 63.3789Z',
      fill: '#1F2533',
    },
  ],
  name: 'Kansas',
  width: '255',
  height: '255',
  viewBox: '0 0 255 255',
});
Kansas.displayName = 'Kansas';

/** @deprecated */
export default Kansas;
