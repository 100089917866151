import React, { useCallback, useMemo } from 'react';
import { safeFormatDate, toDate, useCopy, constants } from '@app/utils';
import {
  useQuery,
  HealthApplicationStatus,
  ApplicationIssuesQuery,
  getApplicationIssues,
} from '@data';
import { navigate, exit } from '@navigate';
import { Loading, Button } from '@uikit';
import { Layout, Split, Stack } from '@layouts';
import FlowLayout from '@layouts/FlowLayout';
import ApplicationIssueCard from '@app/_common/cards/ApplicationIssueCard';
import { Route } from '@types';

const PREFIX = 'catch.ede.DMI';

const HealthDocs = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const { loading, data } = useQuery(ApplicationIssuesQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const handleNext = useCallback(() => {
    if (data?.viewerTwo?.health?.application?.status === HealthApplicationStatus.Submitted) {
      return navigate(Route.EDE_PLANS);
    }

    return exit(Route.COVERAGE);
  }, [data]);

  const { svis, dmis, uploaded, expired } = useMemo(() => {
    const issues = getApplicationIssues(data?.viewerTwo?.health?.application || undefined);

    return {
      svis: issues.svis,
      dmis: issues.dmis,
      uploaded: issues.issuesByStatus.PROCESSING,
      expired: issues.issuesByStatus.EXPIRED,
    };
  }, [data]);

  //this is at the application and not applicant level so we render it above the Cards
  const renderClockExpirationNotice = (notice) => {
    if (!expired && notice?.name !== constants.dmiNoticeTypes.CLOCK_EXPIRATION_NOTICE) {
      return;
    }
    return (
      <Button
        key={notice.id}
        small
        alt
        testID={`getNotices_0`}
        onPress={() =>
          getDocument({
            variables: { input: { dsrsIdentifier: notice.dsrsIdentifier } },
          })
        }
      >
        {constants.dmiNoticeTypes.CLOCK_EXPIRATION_NOTICE.replace(/_/g, ' ')
          .split(' ')
          .map((word) => word[0] + word.substring(1).toLowerCase())
          .join(' ')}
      </Button>
    );
  };

  return (
    <FlowLayout onNext={handleNext} title={c('title')} subtitle={c('subtitle')}>
      {loading || !applicationID ? (
        <Loading accentColor="coverage" />
      ) : (
        <Stack separatorComponent spacing="5">
          {!!svis?.length && (
            <Split>
              <Layout.Header
                title={c('todoTitle', {
                  date: safeFormatDate(toDate(svis[0].resolveBy), 'MMMM d, yyyy'),
                })}
                subtitle={c('sviSubtitle', {
                  date: safeFormatDate(toDate(svis[0].resolveBy), 'MMMM d, yyyy'),
                  info: c('sviLink'),
                })}
              />
              <Stack spacing="1">
                {svis.map((issue) => (
                  <ApplicationIssueCard
                    key={issue.id}
                    issue={issue}
                    applicationID={applicationID}
                  />
                ))}
              </Stack>
            </Split>
          )}

          {!!dmis?.length && (
            <Split sticky>
              <Layout.Header
                title={c('todoTitle', {
                  date: safeFormatDate(toDate(dmis[0].resolveBy), 'MMMM d, yyyy'),
                })}
                subtitle={c('dmiSubtitle', {
                  date: safeFormatDate(toDate(dmis[0].resolveBy), 'MMMM d, yyyy'),
                  info: c('dmiLink'),
                })}
              />
              <Stack spacing="1">
                {!!dmis[0]?.notices[0] && renderClockExpirationNotice(dmis[0]?.notices[0])}
                {dmis?.map((issue) => (
                  <ApplicationIssueCard
                    key={issue.id}
                    issue={issue}
                    applicationID={applicationID}
                  />
                ))}
              </Stack>
            </Split>
          )}

          {!!uploaded?.length && (
            <Split sticky>
              <Layout.Header title={c('uploadedTitle')} subtitle={c('uploadedSubtitle')} />
              <Stack spacing="1">
                {uploaded?.map((issue) => (
                  <ApplicationIssueCard
                    key={issue.id}
                    issue={issue}
                    applicationID={applicationID}
                  />
                ))}
              </Stack>
            </Split>
          )}

          {!!expired.length && (
            <Split sticky>
              <Layout.Header title={c('expiredTitle')} subtitle={c('expiredSubtitle')} />
              <Stack spacing="1">
                {expired?.map((issue) => (
                  <ApplicationIssueCard
                    key={issue.id}
                    issue={issue}
                    applicationID={applicationID}
                  />
                ))}
              </Stack>
            </Split>
          )}
        </Stack>
      )}
    </FlowLayout>
  );
};

export const HealthDocsView = {
  name: Route.HEALTH_DOCS,
  component: HealthDocs,
  options: {
    title: 'Documents',
  },
};
