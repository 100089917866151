import React, { useMemo } from 'react';
import { Copy, PolicyStatusType } from '@app/types';
import { Stack } from '@app/layouts';
import { Badge, BadgeIcon } from '@uikit';
import { shallow } from 'zustand/shallow';
import { sheets, useSheet } from '@app/navigate';
import { useCopy } from '@app/utils';

// the set of statuses to show for
const statuses = {
  ACTIVE_PENDING_PAYMENT: 'Pending Payment',
  PENDING_START_DATE: 'Upcoming',
  PENDING: 'Docs Required',
  CANCELLED: 'Cancelled',
  TERMINATED: 'Terminated',
};

interface CoverageBadgesProps {
  type: 'PLAN_DETAILS' | 'POLICY_STATUS' | 'NONE';
  policyStatus?: PolicyStatusType;
  metalLevel?: string;
  planType?: string; // HMO, PPO etc.
  hsaEligible?: boolean;
  isStandard?: boolean;
  rating?: number;
}

const CoverageBadges: React.FC<CoverageBadgesProps> = ({
  type,
  policyStatus,
  metalLevel,
  planType,
  hsaEligible,
  isStandard,
  rating,
}) => {
  const { c, locale } = useCopy('catch');

  const { showInfo } = useSheet(
    (state) => ({
      showInfo: ({ title, subtitle }) => state.open(sheets.HELP_TEXT, { title, subtitle }),
    }),
    shallow,
  );

  const badges = useMemo(() => {
    const list: Array<{ id: string; label: Copy; onPress?: () => void; icon?: BadgeIcon }> = [];

    if (type === 'POLICY_STATUS' && !!policyStatus && policyStatus in statuses) {
      list.push({ id: 'status', label: statuses[policyStatus] });
    }

    if (type === 'PLAN_DETAILS') {
      if (!!isStandard) {
        list.push({
          id: 'easy-pricing',
          icon: BadgeIcon.Tag,
          label: c('easy-pricing'),
          onPress: () =>
            showInfo({
              title: c('easy-pricing.help-title'),
              subtitle: c('easy-pricing.help-content'),
            }),
        });
      }
      if (!!planType) list.push({ id: 'plan-type', label: planType });
      if (!!metalLevel) list.push({ id: 'metal-level', label: metalLevel });
      if (!!hsaEligible) list.push({ id: 'hsa-eligible', label: 'HSA eligible' });
    }

    if (rating && rating > 0) {
      list.push({ id: 'rating', label: `${rating}/5 ☆` });
    }

    return list;
  }, [type, policyStatus, metalLevel, planType, hsaEligible, isStandard, rating, locale]);

  if (badges.length === 0) return null;

  return (
    <Stack horizontal spacing="0b">
      {badges.map((badge) => (
        <Badge key={badge.id} icon={badge.icon} label={badge.label} onPress={badge.onPress} />
      ))}
    </Stack>
  );
};

export default CoverageBadges;
