import React, { useState } from 'react';

import { Stack } from '@app/layouts';
import { SegmentedControl } from '@app/_ui-kit';

/**
 * renders detailed information about a Benefit, including
 * - Details (such as percentage allocated or insurance provider)
 * - Costs (for insurance cost breakdown)
 *
 * currently used by health insurance
 */
const PlanDetailTabs = ({ details, additionalDetailTab, tabKey = 'default' }) => {
  const [index, setIndex] = useState(0);
  const TABS = {
    health: ['Cost', 'Details'],
    default: ['Details', 'Activity'],
  };

  return (
    <Stack spacing="2">
      <SegmentedControl
        index={index}
        setIndex={setIndex}
        controls={TABS[tabKey]?.map((item) => ({
          title: item,
        }))}
      />
      <>
        {TABS[tabKey][index] === 'Details' && details}
        {TABS[tabKey][index] === 'Cost' && additionalDetailTab}
      </>
    </Stack>
  );
};

export default PlanDetailTabs;
