import { LayoutMode } from '@types';
import { DisplayOptions, WebNavigationConfig } from '../types';
import { options } from './options';

/**
 * Note: this is where a lot of assumptions about context happen, FYI
 */
export const translateScreenOptions = (
  displayOptions: DisplayOptions = {},
  themeColors: object = {},
  data: object = {},
  layout: LayoutMode,
): WebNavigationConfig => {
  const title = options.getTitle(displayOptions.title, data);
  const subtitle = options.getTitle(displayOptions.subtitle, data);

  // note: we want this to ALWAYS compute the value of the nav color,
  // so we overwrite draw behind nav as false, and handle that down below instead
  const navHex =
    options.getNavColor(displayOptions, themeColors, data) ||
    options.getPageColor({ layout }, themeColors);

  const isTransparent =
    (!!displayOptions.drawBehindNav && !displayOptions.scrolled) ||
    displayOptions.navMode === 'split';

  const bgStyle = isTransparent ? {} : { backgroundColor: navHex };

  return {
    title,
    subtitle,
    navHex,
    themeColor: navHex,
    isTransparent,
    bgStyle,
    navMode: displayOptions?.navMode || 'root',
  };
};
