import gql from 'graphql-tag';

const GET_DOCUMENT = gql`
  mutation GetGeneratedDocumentURL($input: GetGeneratedDocumentURLInput!) {
    getGeneratedDocumentURL(input: $input)
  }
`;

export default {
  document: GET_DOCUMENT,
};
