import React from 'react';
import { Text } from '@uikit/components/Text';
import { FontSize, FontWeight } from '@app/types';

/**
 * Two types of variants:
 * One that should be used on root pages (a bit bigger)
 * One that should be used on flow pages (a bit smaller)
 */
export type SectionVariant = 'root' | 'flow';

export interface SectionTitleProps {
  testID?: string;
  loading?: boolean;
  variant?: SectionVariant;
  children: React.ReactNode;
}

const variants: Record<SectionVariant, { size: FontSize }> = {
  root: {
    size: FontSize.h4,
  },
  flow: {
    size: FontSize.h5,
  },
};

export const SectionTitle = React.forwardRef(
  ({ testID, loading, variant = 'flow', children }: SectionTitleProps, ref) => {
    const config = variants[variant];

    return (
      <Text
        ref={ref}
        testID={testID}
        loading={loading}
        size={config.size}
        weight={FontWeight.medium}
      >
        {children}
      </Text>
    );
  },
);

SectionTitle.displayName = 'SectionTitle';
export default SectionTitle;
