import { Auth0Provider } from '@auth0/auth0-react';
import { env } from '@global';
import { PassedParams, useSignupParams } from './useSignupParams';
import { useLocale } from '@app/utils/language/locale';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { setParams } = useSignupParams();
  const { locale } = useLocale();

  return (
    <Auth0Provider
      domain={env.AUTH0_DOMAIN}
      clientId={env.AUTH0_CLIENT_ID}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env.AUTH0_AUDIENCE,
        ui_locales: locale,
      }}
      onRedirectCallback={(appState) => {
        setParams(appState as PassedParams, true);
      }}
    >
      {children}
    </Auth0Provider>
  );
}
