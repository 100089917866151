import React, { useMemo, useState, useCallback } from 'react';
import { Button, Divider, Dialog } from '@uikit';
import { optionPresets } from '@app/forms';
import { useCopy } from '@app/utils';
import { SheetBlueprint } from '@app/blueprints';

interface Props {
  type: 'income' | 'deduction';
  append: (item: any) => void;
}

const IncomeDetailsFooter: React.FC<Props> = ({ type, append, isLast }) => {
  const { c, locale } = useCopy('catch.ede.enums');

  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);

  const options = useMemo(() => {
    return optionPresets[type === 'income' ? 'INCOME_TYPES' : 'DEDUCTION_TYPES'].map(
      ({ value }) => ({
        testID: value,
        label: c(value),
        onAction: () => {
          append({ type: value });
          setOpen(false);
        },
      }),
    );
  }, [type, locale]);

  return (
    <>
      <Dialog isOpen={isOpen} onRequestClose={close}>
        <SheetBlueprint actions={options} />
      </Dialog>
      <Button
        onPress={open}
        icon="Plus"
        size="small"
        testID={type === 'income' ? 'add-income-btn' : 'add-deductions-btn'}
      >
        {type === 'income' ? c('incomeSource') : c('deduction')}
      </Button>
      {!isLast && <Divider spacing />}
    </>
  );
};

export default IncomeDetailsFooter;
