import createIconComponent from '../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const PageError = createIconComponent({
  content: [
    {
      type: 'title',
      children: 'PageError',
    },
    {
      type: 'path',
      d: 'M52.6124 43.5267L32.1612 7.46585C30.6499 4.80097 26.8264 4.75475 25.2511 7.38231L3.63129 43.4432C2.03285 46.1093 3.95339 49.5 7.06196 49.5H49.133C52.1985 49.5 54.1247 46.1933 52.6124 43.5267Z',
      fill: '#FFCB9E',
    },
    {
      type: 'path',
      'fill-rule': 'evenodd',
      'clip-rule': 'evenodd',
      d: 'M28.5 41.001C29.6046 41.001 30.5 40.1055 30.5 39.001L30.5 38.001C30.5 36.8964 29.6046 36.001 28.5 36.001L27.5 36.001C26.3954 36.001 25.5 36.8964 25.5 38.001L25.5 39.001C25.5 40.1055 26.3954 41.001 27.5 41.001L28.5 41.001ZM28 33.501C29.3807 33.501 30.5 32.3817 30.5 31.001L30.5 23.501C30.5 22.1203 29.3807 21.001 28 21.001C26.6193 21.001 25.5 22.1203 25.5 23.501L25.5 31.001C25.5 32.3817 26.6193 33.501 28 33.501Z',
      fill: '#1F2533',
    },
  ],
  contentDark: [
    {
      type: 'title',
    },
    {
      type: 'path',
      opacity: '0.5',
      d: 'M52.6124 43.5267L32.1612 7.46585C30.6499 4.80097 26.8264 4.75475 25.2511 7.38231L3.63129 43.4432C2.03285 46.1093 3.95339 49.5 7.06196 49.5H49.133C52.1985 49.5 54.1247 46.1933 52.6124 43.5267Z',
      fill: '#914300',
    },
    {
      type: 'path',
      d: 'M28 41.001C26.6193 41.001 25.5 39.8817 25.5 38.501C25.5 37.1203 26.6193 36.001 28 36.001C29.3807 36.001 30.5 37.1203 30.5 38.501C30.5 39.8817 29.3807 41.001 28 41.001Z',
      fill: 'white',
    },
    {
      type: 'path',
      d: 'M28 33.501C26.6193 33.501 25.5 32.3817 25.5 31.001L25.5 23.501C25.5 22.1203 26.6193 21.001 28 21.001C29.3807 21.001 30.5 22.1203 30.5 23.501L30.5 31.001C30.5 32.3817 29.3807 33.501 28 33.501Z',
      fill: 'white',
    },
  ],
  id: '9005%3A17840',
  name: 'PageError',
  width: '56',
  height: '56',
  viewBox: '0 0 56 56',
});
PageError.displayName = 'PageError';

/** @deprecated */
export default PageError;
