import React from 'react';
import styled from 'styled-components';
import * as RUITooltip from '@radix-ui/react-tooltip';
import { Text } from '../Text';
import { FontSize, FontWeight } from '@app/types';

const TTContent = styled(RUITooltip.Content)`
  background: ${({ theme }) => theme.colors.bgSheet};
  position: relative;
  padding: 6px 10px;
  line-height: 1;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0px #0000001a;
  display: flex;
  align-items: center;
  margin-top: -6px;
  z-index: 5;
`;

const TTArrow = styled(RUITooltip.Arrow)`
  fill: ${({ theme }) => theme.colors.bgSheet};
  filter: drop-shadow(0 2px 6px 0px #0000001a);
  clip-path: inset(0 -10px -10px -10px);
`;

export interface IconLabelProps {
  label?: string;
  children: React.ReactNode;
}

export const IconLabel = ({ label, children }: IconLabelProps) => {
  if (!label) return children;

  return (
    <RUITooltip.Provider delayDuration={0}>
      <RUITooltip.Root>
        <RUITooltip.Trigger asChild>{children}</RUITooltip.Trigger>
        <RUITooltip.Portal>
          <TTContent>
            <Text size={FontSize.sm} weight={FontWeight.medium}>
              {label}
            </Text>
            <TTArrow />
          </TTContent>
        </RUITooltip.Portal>
      </RUITooltip.Root>
    </RUITooltip.Provider>
  );
};
