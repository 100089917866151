export const questions = [
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '240',
    name: 'isCoveredDependentChild',
    title: { id: '240_L' },
    label: { id: '240_Q' },
    filterMembers: (member) => member?.relation === 'CHILD' && !member?.isRequestingCoverage,
    field: {
      name: 'members',
      type: 'array',
      subfields: [
        {
          name: 'isCoveredDependentChild',
          bare: true,
          type: 'person',
          dependencies: ['fullName'],
          label: (values) => values.fullName,
        },
      ],
    },
  },
];
