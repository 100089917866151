import {
  HealthApplicationQuery,
  UpsertHealthApplicationInput,
  VoterRegistrationAnswerType,
} from '@app/data/types';

export interface VoterRegistrationValues {
  voterRegistrationAnswerType?: VoterRegistrationAnswerType;
}

export const getInitialValues = (data?: HealthApplicationQuery): VoterRegistrationValues => {
  return {
    voterRegistrationAnswerType:
      data?.viewerTwo?.health?.application?.voterRegistrationAnswerType || undefined,
  };
};

export const formatPayload = (
  applicationID: string,
  values: VoterRegistrationValues,
): UpsertHealthApplicationInput => {
  return {
    id: applicationID,
    voterRegistrationAnswerType: values.voterRegistrationAnswerType,
  };
};
