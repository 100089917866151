/**
 * Given a value, ensures the value is string
 * - If empty/not a string type, just return empty string
 * - Otherwise, the value is OK
 */
export const ensureString = (value) => {
  if (!!value && typeof value === 'string') return value;
  if (!!value && typeof value === 'number') return String(value);
  return '';
};

/**
 * Removes any non a-z characters
 * so something like 9j32=sd,cm -> jsdcm
 */
export const onlyLetters = (value = '') => {
  return ensureString(value).replace(/[^a-zA-Z]/g, '');
};

/**
 * Removes any non-digit characters
 */
export const onlyNums = (value = '') => {
  return ensureString(value).replace(/[^\d]/g, '');
};

/**
 * Converts string to all lowercase
 * - Given a value, returns string w/all lowercase letters
 */
export const lowercase = (value = '') => ensureString(value)?.toLowerCase();

/**
 * Capitalizes a string
 * - Given a value, returns the first letter capitzlied
 */
export function capitalize(value = '', forceLowercase = true) {
  const string = ensureString(value);
  const rest = string.slice(1);

  return string.charAt(0).toUpperCase() + (forceLowercase ? rest.toLowerCase() : rest);
}

/**
 * Capitalizes all words in a strings
 * - Given a value, returns string with each word capitalized
 */
export const capitalizeAll = (value = '') => {
  const useLowercase = ['or'];

  const words = ensureString(value).split(' ');

  const capitalized = words.map((w) => {
    const shouldLowerCase = useLowercase.includes(w.toLowerCase());
    return shouldLowerCase ? w.toLowerCase() : capitalize(w);
  });

  return capitalized.join(' ');
};

/**
 * Given an enum, typically in SCREAMING_SNAKE_CASE,
 * formats it as Screaming Snake Case
 */
export const formatEnum = (value = '') => {
  return capitalizeAll(value?.split('_').join(' '));
};

/**
 * Converts string to all uppervcase
 * - Given a value, returns string w/all uppercase letters
 */
export const uppercase = (value = '') => ensureString(value)?.toUpperCase();

/**
 * Converts a string to camelcase
 * - Given a value (and optionally, a separator), splits by the separator,
 *   returns the string put back together in camel case:
 * - e.g. "it should look like this" -> "itShouldLookLikeThis"
 *
 * - You can override the separator so that you can split by other chars:
 * - e.g. HEALTH_INSURANCE => healthInsurance (when split by _)
 */
export const camelcase = (value = '', separator = ' ') => {
  const [first, ...words] = ensureString(value).split(separator);
  const result = [lowercase(first), ...words.map(capitalize)].join('');
  return onlyLetters(result);
};

/**
 * Given a string and characters to find and replace,
 * returns the string with replaced characters
 */
export const replaceAll = (initial, find, replace) => {
  if (!initial) return '';

  let replaced = '';

  for (let i = 0; i < initial.length; i++) {
    const char = initial.charAt(i);
    replaced += char === find ? replace : char;
  }

  return replaced;
};

// TRANSFER_AMOUNT to transfer-amount
export const ensnake = (str) => {
  return replaceAll(str.toLowerCase(), '_', '-');
};

// transfer-amount to TRANSFER_AMOUNT
export const desnake = (str) => {
  return replaceAll(str.toUpperCase(), '-', '_');
};

// my-awesome-goal to My Awesome Goal
export const deslug = (slug) => {
  if (!slug) return '';
  const name = slug.split('-').join(' ');
  return capitalizeAll(name?.replace('timeoff', 'Time Off'));
};

export const deenum = (str) => {
  return deslug(ensnake(str));
};
