import React, { useMemo } from 'react';
import { Copy } from '@types';
import { FormConfig, Fields, useForm } from '@f';
import { Loading, Button } from '@uikit';
import { pop } from '@navigate';
import { HelpTextInline } from '@app/_ui-kit/components/HelpText';

import { default as SplitLayout } from '@app/layouts/SplitLayout';
import { default as Layout } from '@app/layouts/Layout';
import { default as Toolbar } from '@app/layouts/Toolbar';
import { useDependentValues } from '@app/forms/utils/useDependentValues';

interface SplitFormBlueprintProps<TFormValues> {
  loading: boolean; // shows a loader during initial load
  submitting: boolean; // while submitting the form, disable all buttons and inputs
  title: Copy;
  subtitle?: Copy;
  dependencies?: Array<string>;
  formConfig: FormConfig<TFormValues>;
  precontent?: React.ReactNode;
  postcontent?: React.ReactNode;
  shouldWait?: (values: TFormValues) => boolean;
  onWait: (values: TFormValues, options: { handleNext: () => void }) => void;
}

const SplitFormBlueprint = <TFormValues extends Record<string, any> = Record<string, any>>({
  loading, // for initial load
  submitting, // for example when submitting the form
  title,
  subtitle,
  subtitles,
  dependencies = [],
  formConfig,
  precontent,
  postcontent,
  shouldWait,
  onWait,
}: SplitFormBlueprintProps<TFormValues>) => {
  // checks if submission should be delayed
  const handleSubmit = (values, methods) => {
    const formSubmit = () => formConfig.onSubmit(values, methods);

    if (shouldWait && shouldWait(values) && onWait) {
      onWait(values, { handleNext: formSubmit });
    } else {
      formSubmit();
    }
  };

  const form = useForm<TFormValues>({
    loading,
    disabled: submitting,
    fields: formConfig.fields,
    initialValues: formConfig.initialValues,
    onError: formConfig.onError,
    onSubmit: handleSubmit,
    autoSubmit: formConfig.autoSubmit,
  });

  const dependentValues = useDependentValues({
    dependencies,
    control: form.methods.control,
  });

  const computedTitle = useMemo(() => {
    return typeof title === 'function' ? title(dependentValues) : title;
  }, [title, dependentValues]);

  const computedSubtitles = useMemo(() => {
    const sub = typeof subtitle === 'function' ? subtitle(dependentValues) : subtitle;
    const subs = typeof subtitles === 'function' ? subtitles(dependentValues) : subtitles;
    return subs || [sub];
  }, [subtitle, subtitles, dependentValues]);

  return (
    <SplitLayout
      gradient="coverageLight"
      handleContent="HIDDEN"
      toolbar={
        <Toolbar onBack={() => pop()}>
          <Button inherit testID="next" onPress={form.submitForm} disabled={form.disableSubmit}>
            Next
          </Button>
        </Toolbar>
      }
    >
      <>
        <Layout.Header
          title={computedTitle}
          subtitles={computedSubtitles}
          titleSize="form"
          bottomSpace
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            {precontent}
            <Fields form={form} fields={formConfig.fields} />
            {postcontent}
          </>
        )}
      </>
      {form.currentHelpText && (
        <HelpTextInline
          content={form.currentHelpText}
          label={form.currentField?.label}
          offset={form?.layout?.y}
        />
      )}
    </SplitLayout>
  );
};

SplitFormBlueprint.options = SplitLayout.options;
export default SplitFormBlueprint;
