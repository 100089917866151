import { gql } from '../generated';

export const GetConsentRequest = gql(`
  query ConsentRequest($token: String!) {
    consentRequest(token: $token) {
      id
      status
      consentType
      givenName
      familyName
    }
  }
`);
