import React, { useMemo } from 'react';
import { FontSize, FontWeight, TextAlign, TextColor } from '@types';
import { useTheme } from '../hooks/useTheme';
import { useHover } from '../hooks/useHover';
import { colors } from '../theme/colors';
import { Skeleton } from './Skeleton';
import { fontFamilyFix } from '../theme/themes';
import { TextProvider, useTextContext } from './TextContext';

export interface TextProps {
  testID?: string;
  size?: FontSize | number;
  maxSize?: FontSize;
  weight?: FontWeight;
  color?: TextColor;
  align?: TextAlign;
  fluid?: boolean;
  group?: boolean;
  label?: boolean;
  inline?: boolean;
  mono?: boolean;
  tabular?: boolean;
  technical?: boolean;
  underline?: boolean;
  strike?: boolean;
  children?: any;
  gutter?: any;
  style?: any;
  qaName?: string;
  loading?: boolean;
  skeletonWidth?: any;
  numberOfLines?: any;
  onPress?: any;
  hex?: any;
  className?: string;
  sensitive?: boolean;
}

const semanticMarkup = (size, testID) => {
  let props = {};
  if (/h/.test(size)) {
    props['role'] = 'heading';
    props['aria-level'] = size.split('')[1];
  }

  if (size === FontSize.label) {
    props['id'] = `${testID}-label`;
  }

  return props;
};

const handleTags = (string) => {
  /* Early return to prevent unnecessary wrapping in <span> element*/
  if (!string.includes('<bold>') && !string.includes('<medium>')) {
    return null;
  }

  const words = string.split(/<bold>|<medium>/);

  return words.reduce((acc, word) => {
    if (word.includes('</bold>')) {
      const [pre, post] = word.split('</bold>');
      return [...acc, { weight: 'semibold', text: pre }, { weight: null, text: post }];
    }

    if (word.includes('</medium>')) {
      const [pre, post] = word.split('</medium>');
      return [...acc, { weight: 'medium', text: pre }, { weight: null, text: post }];
    }

    return [...acc, { weight: null, text: word }];
  }, []);
};

React.createContext({
  size: undefined,
  weight: undefined,
  color: undefined,
});

export const Text = React.forwardRef(
  (
    {
      testID,
      size,
      maxSize,
      fluid,
      color,
      weight,
      children,
      align = 'left',
      label,
      style,
      group,
      inline,
      tabular,
      technical,
      underline,
      strike,
      qaName,
      mono,
      loading,
      skeletonWidth = '100%',
      numberOfLines,
      onPress,
      gutter,
      hex,
      sensitive,
      className,
    }: TextProps,
    ref,
  ) => {
    const [isHovered, handlers] = useHover();
    const { theme, themeColors } = useTheme();
    const context = useTextContext();

    let textString = children;
    let textPieces = [];

    /**
     * Handles splitting out bold pieces
     */
    if (typeof children === 'string') {
      textString = children.replace(/<br\s?\/>/g, '\n');
      textPieces = handleTags(textString);
    }

    // all of these properties should fall back to the context values
    const properties = useMemo(() => {
      return {
        color: color || context.color || 'text',
        weight: weight || context.weight,
        size: size || context.size || 'p',
      };
    }, [color, weight, size, context]);

    const Element = useMemo(() => {
      if (size === FontSize.label) {
        return 'label';
      }

      return 'span';
    }, [size]);

    return (
      <TextProvider size={properties.size} weight={properties.weight} color={properties.color}>
        <Skeleton loading={loading} fontSize={properties.size} width={skeletonWidth} align={align}>
          <Element
            data-testid={testID}
            {...handlers}
            onClick={onPress}
            data-private={sensitive}
            className={className}
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              ...(properties.size && theme[properties.size]
                ? theme[properties.size]
                : typeof properties.size === 'number'
                ? { fontSize: `${properties.size}px` }
                : {}),
              ...(properties.size && !fluid ? theme[`${properties.size}Max`] : {}),
              ...(fluid ? { flex: 1, flexWrap: 'wrap' } : {}),
              ...(maxSize ? theme[`${maxSize}Max`] || { maxWidth: maxSize } : {}),
              ...(properties.weight ? theme[`${properties.weight}Text`] : {}),
              ...(properties.color
                ? theme[`${properties.color}Color`] ||
                  theme[`${properties.color}Text`] || {
                    color: colors[properties.color] || themeColors[properties.color + 'Color'],
                  }
                : {}),
              ...(hex ? { color: hex } : {}),
              ...(align ? theme[`${align}Text`] : {}),
              ...(group ? theme.bottomGutter0b : {}),
              ...(inline ? theme.bottomGutter0 : {}),
              ...(gutter ? theme[`${gutter}Gutter0b`] : {}),
              ...(tabular ? theme.tabular : {}),
              ...(technical ? theme.technical : {}),
              ...(mono ? theme.mono : {}),
              ...(strike ? theme.strikethrough : {}),
              ...(label ? theme.label : {}),
              ...(underline ? (isHovered ? theme.underlineTextHover : theme.underlineText) : {}),
              // ...style,
              ...fontFamilyFix(properties.size),
            }}
            {...semanticMarkup(properties.size, testID)}
          >
            {textPieces?.length > 0
              ? textPieces.map(({ text, _weight }) => (
                  <span
                    key={text}
                    style={_weight ? theme[`${_weight}Text`] : undefined}
                    onClick={onPress}
                  >
                    {text}
                  </span>
                ))
              : children}
          </Element>
        </Skeleton>
      </TextProvider>
    );
  },
);

Text.displayName = 'Text';
export default Text;
