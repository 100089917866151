import React, { useMemo } from 'react';
import {
  GET_GOAL_SETUP_DETAILS,
  GET_GOAL_ESTIMATOR_PAGE,
  UPDATE_GOAL,
  GoalType,
  useFintechQuery,
  useQuery,
  useFintechMutation,
} from '@data';
import { BasicFormBlueprint } from '@blueprints';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { useCopy } from '@app/utils';
import { GoalSlug, Route } from '@types';
import { Link } from '@uikit';
import { GOAL_TYPE_DEFAULT_PERCENTAGE } from '@app/constants/goals';
import useTaxRecommendations from '@app/features/goals/hooks/useTaxRecommendations';
import useTotalPaycheckPercentage from '@app/features/goals/hooks/useTotalPaycheckPercentage';
import { sheets, useSheet } from '@app/navigate';
import { WithholdingBanner } from '../components/WithholdingBanner';

interface EstimatorProps {
  slug: GoalSlug;
  handleNext: () => void;
}

export interface EstimatorValues {
  grossIncome: number;
  workState?: string;
  paycheckPercentage: number;
  totalPaycheckPercentage: number;
}

/**
 * Couple of things to note:
 * 1) This Estimator is used in the payroll onboarding section
 * 2) Tax uses a recommended withholding value that is calculated using a util called calculateTaxes
 * 3) Retirement has an initial value of 5%, and the other non-tax goals 1% and no recommended value
 */
const GoalEstimator = ({ slug, handleNext }: EstimatorProps) => {
  const { c } = useCopy('catch.goal.withholding');
  const { open: openSheet } = useSheet();

  const setup = useQuery(GET_GOAL_SETUP_DETAILS);

  const estimator = useFintechQuery(GET_GOAL_ESTIMATOR_PAGE, {
    variables: { slug },
    skip: !slug,
  });

  const [updateGoal, { loading: updating }] = useFintechMutation(UPDATE_GOAL, {
    onCompleted: handleNext,
  });

  const loading = setup.loading || estimator.loading;
  const goals = estimator?.data?.goals || [];
  const goal = estimator?.data?.goal;
  const user = setup?.data?.me?.user;

  const taxRecommendations = useTaxRecommendations(user);
  const totalPaycheckPercentage = useTotalPaycheckPercentage(goals, goal);

  const maxPercentage = 100 - totalPaycheckPercentage;
  const grossIncome = user?.income?.estimatedGrossIncome;

  const recommendedPercentage = useMemo(() => {
    const goalType = goal?.type;

    if (goalType === GoalType.TaxSavings && taxRecommendations) {
      return Math.round(taxRecommendations?.roundedPaycheckPercentageFederal * 100);
    } else {
      return goalType ? GOAL_TYPE_DEFAULT_PERCENTAGE[goalType] : 0;
    }
  }, [goal, taxRecommendations]);

  const initialValues = {
    workState: user?.workState,
    grossIncome,
    paycheckPercentage: Math.min(
      goal?.withholdingPercentage || recommendedPercentage,
      maxPercentage,
    ),
    totalPaycheckPercentage,
  };

  const formConfig: FormConfig<EstimatorValues> = {
    initialValues,
    fields: [
      {
        ...fields.PAYCHECK_PERCENTAGE,
        fieldAddition: ({ grossIncome, paycheckPercentage }) => ({
          component: 'CUSTOM',
          props: {
            Component: (
              <WithholdingBanner
                grossIncome={grossIncome}
                paycheckPercentage={paycheckPercentage}
              />
            ),
          },
        }),
      },
    ],
    watch: [fields.PAYCHECK_PERCENTAGE.name],
    onSubmit: (values) => {
      if (!goal) {
        console.warn('Goal not defined when updating withholding');
      } else {
        updateGoal({
          variables: {
            input: {
              id: goal?.id || '',
              withholdingPercentage: values.paycheckPercentage,
            },
          },
        });
      }
    },
  };

  const openTaxBreakdown = () => {
    openSheet(sheets.TAX_BREAKDOWN_GUIDE, {
      calculation: taxRecommendations,
    });
  };

  const subtitle = c(`${goal?.type}.subtitle`, {
    link: (
      <Link testID="show-tax-breakdown" onPress={openTaxBreakdown}>
        {c(`${goal?.type}.link`)}
      </Link>
    ),
  });

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={updating}
      title={c('title', { goal: goal?.name?.toLowerCase() })}
      subtitles={[subtitle]}
      formConfig={formConfig}
    />
  );
};

export const GoalEstimatorView = {
  name: Route.GOAL_ESTIMATOR,
  component: GoalEstimator,
  options: {},
};
