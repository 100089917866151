import { useDeprecatedMutation } from '@data';
import { Segment, SegmentEvent } from '@app/utils';

// relative imports
import { useNudge } from './useNudge';

/**
 * useHealthHandoff handles updates for handing off to non-EDE
 *
 *
 * Types of handoff types:
 * 1) STATE_EXCHANGE
 * 2) MEDICARE
 * 3) MEDICAID
 * 4) HEALTHCARE_GOV
 * 5) HEALTHSHERPA
 * 6) CALIFORNIA
 *
 * Options:
 *
 * Options for handoff:
 * externalUrl: if set, a url to navigate the user to
 * coverageYear: the year the person is applying for
 * onHandoff: function to be invoked after handoff
 *
 * Options for cleamup:
 * enrollId: if set, the enrollment to remove on cleanup
 * onCleanup: function to be invoked after cleanup
 */
export const useHealthHandoff = ({ handoffType, options }) => {
  const redirectUrls = {
    HEALTHSHERPA: 'https://www.healthsherpa.com/?_agent_id=catch',
    CALIFORNIA: 'https://apply.coveredca.com',
    HEALTHCARE_GOV: 'https://www.healthcare.gov/',
  };

  const [terminate, { loading: cleaningUp }] = useDeprecatedMutation('terminateHealthEnrollment', {
    onCompleted: options?.onCleanup,
  });

  const { dismissNudge } = useNudge(`healthEnrollmentIsNotExited${options?.coverageYear}`);

  // sets exit stage + did enroll to null
  const handoff = () => {
    // if an external URL is set, we direct the person there
    const url = options?.externalUrl || redirectUrls[handoffType];

    if (!!url) {
      window.open(url);
      Segment.track(SegmentEvent.HEALTH_HANDOFF_INITIATED, { handoff_type: handoffType });
    }

    // if the coverage year is set, sets the handoff nudge for that year
    if (options?.coverageYear) {
      dismissNudge();
    }

    if (options?.onHandoff) {
      options?.onHandoff();
    }
  };

  // handles cleanup of previous enrollment
  const cleanup = () => {
    // terminate users who have been handed off no longer need their previous enrollment
    if (!!options?.enrollId) {
      terminate({ variables: { id: options?.enrollId } });
    } else if (!!options?.onCleanup) {
      options.onCleanup();
    }
  };

  return {
    handoff,
    cleanup,
    cleaningUp,
  };
};
