import { SEPIneligible } from '@common';
import { Route } from '@types';

export const SEPOutDateView = {
  name: Route.EDE_SEP_OUT_OF_DATE,
  component: SEPIneligible,
  options: {
    title: 'Special Enrollment',
  },
};
