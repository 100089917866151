import React, { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { open, useSheet, sheets, SheetComponentProps } from '@navigate';
import { useDeprecatedMutation, useQuery, getMostRecentEDN, GET_COVERAGE_DETAIL } from '@data';
import { SheetBlueprint } from '@blueprints';
import { Actions } from '@app/config';
import { capitalizeAll } from '@app/utils';
import { Route } from '@types';

const CoverageDetailMenuSheet: React.FC<SheetComponentProps['COVERAGE_DETAIL_MENU']> = ({
  type,
  policyID,
}) => {
  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);

  const { data } = useQuery(GET_COVERAGE_DETAIL, {
    variables: { policyID },
    skip: !policyID,
  });

  const { applicationID, isPartner, pathwayType, dsrsIdentifier } = useMemo(() => {
    const app = data?.viewerTwo?.health?.policy?.application;

    console.log(app);

    return {
      applicationID: app?.id,
      pathwayType: app?.pathwayType,
      isPartner: /HEALTH_SHERPA|OSCAR/.test(app?.pathwayType || ''),
      dsrsIdentifier: getMostRecentEDN(app?.ednDocuments)?.dsrsIdentifier,
    };
  }, [data]);

  const [getDocument, { loading: gettingNotice }] = useDeprecatedMutation(
    'getGeneratedDocumentURL',
    {
      onCompleted: (data) => window.open(data.getGeneratedDocumentURL),
    },
  );

  const handleCoverageUpdate = () => {
    if (isPartner) {
      // either managed with hc.gov or healthsherpa
      openSheet(sheets.COVERAGE_MANAGED_OUTSIDE, {
        managedBy: pathwayType,
        reason: pathwayType,
      });
    } else {
      open(Route.CIC_MANAGE_COVERAGE_INTRO, { applicationID });
    }
  };

  return (
    <SheetBlueprint
      accentColor="coverage"
      loading={!data}
      title={capitalizeAll(type)}
      actions={[
        {
          ...Actions.GET_EDN,
          onAction: () => getDocument({ variables: { input: { dsrsIdentifier } } }),
          disabled: gettingNotice,
        },
        {
          ...Actions.MANAGE_COVERAGE,
          onAction: handleCoverageUpdate,
        },
        Actions.REPORT_CHANGE,
        Actions.HELP,
      ]}
    />
  );
};

export default CoverageDetailMenuSheet;
