import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useQuery, UserQuery } from '@data';
import { useResponsive, SimpleRow } from '@uikit';
import { Page, Layout, Stack } from '@layouts';
import { useCopy } from '@app/utils';
import { handleAction, links, useCurrentRoute } from '@navigate';
import { useAuth } from '@auth';
import { date } from '@app/utils/format/date';
import { VersionLabel } from '@app/dev/VersionLabel';
import { Route } from '@types';

import User from '@svg/user.svg';
import Settings from '@svg/settings.svg';
import Docs from '@svg/file.svg';
import Help from '@svg/help-circle.svg';
import Avatar from '../components/Avatar';

const sidebarTitles = [
  { title: 'info', icon: User, action: { type: 'ROUTE', route: Route.ACCOUNT_INFO } },
  { title: 'settings', icon: Settings, action: { type: 'ROUTE', route: Route.ACCOUNT_SETTINGS } },
  { title: 'documents', icon: Docs, action: { type: 'ROUTE', route: Route.STATEMENTS } },
  { title: 'help', icon: Help, action: { type: 'HELP' } },
];

const StyledProfile = styled.div`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    width: ${({ theme }) => theme.constants.promptWidthSm}px;
  }
`;

const ProfileMenu = () => {
  const { c } = useCopy('catch.module.me');
  const { loading, data } = useQuery(UserQuery);

  const { avatarImage, joinDate, fullName, userID } = useMemo(() => {
    return {
      userID: data?.viewer?.user?.id,
      fullName: data?.viewer?.user?.legalName,
      avatarImage: data?.viewer?.user?.avatarImage,
      joinDate: data?.viewer?.user?.createdOn,
    };
  }, [data]);

  const { logout } = useAuth();

  // ts-ignore
  const { longRange } = useResponsive();
  const isMobile = longRange === 'mobile';
  const route = useCurrentRoute();

  return (
    // @ts-ignore
    <Page>
      {/* @ts-ignore */}
      <Layout.Scroll margins topSpace bottomSpace>
        <StyledProfile>
          <Layout topSpace bottomSpace>
            <Stack spacing="1">
              <Avatar img={avatarImage} id={userID} loading={loading} size="xl" />
              <Layout.Header
                bottomSpace={isMobile}
                loading={loading}
                titleSize="form"
                title={fullName}
                subtitleSize="fp"
                subtitle={`Joined ${date(joinDate, 'FULL', { addSuffix: true })}`}
              />
            </Stack>
          </Layout>

          <Stack separatorComponent>
            {sidebarTitles.map(({ title, icon, action }) => (
              <SimpleRow
                light={!icon}
                key={title}
                label={c(`MeView.${title}Link`)}
                onPress={() => handleAction(action)}
                interaction={isMobile && !!icon ? 'navigation' : undefined}
                testID="navigation-row"
                textColor={
                  !icon ? 'subtler' : isMobile || route === action.route ? 'text' : 'subtle'
                }
                asset={{
                  svg: icon,
                  size: 'xs',
                  containerSize: 'xs',
                  color: route === action.route || isMobile ? 'text' : 'subtler',
                }}
              />
            ))}
          </Stack>
          <SimpleRow
            light
            key={'disclosures'}
            label={c(`MeView.disclosuresLink`)}
            onPress={() => handleAction({ type: 'LINK', route: links.LEGAL })}
            testID="navigation-row"
            textColor={'subtler'}
            asset={{ size: 'xs', containerSize: 'xs' }}
          />
          <SimpleRow
            light
            key={'sign-out'}
            label={c(`SignOutButton`)}
            onPress={() => logout()}
            testID="sign-out"
            textColor="credit"
            asset={{ size: 'xs', containerSize: 'xs' }}
          />

          <Layout centerAll topSpace>
            <VersionLabel align={isMobile ? 'center' : 'flex-start'} always />
          </Layout>
        </StyledProfile>
      </Layout.Scroll>
    </Page>
  );
};

export default ProfileMenu;
