import React from 'react';
import { View } from 'react-native';

import { s } from '@uikit/theme/static';
import { useTheme } from '@uikit/hooks/useTheme';
import { useHover } from '@uikit/hooks/useHover';

import { Text } from './Text';
import { Pressable } from './Pressable';

/**
 * Le Flag
 * @param { string } type : status(default)|slug|chicklet|tag|banner
 * @param { string } level : urgent|important|consider|neutral|confirmed|disabled
 * @param { string } shape : rounded|pill|circle
 * @todo remove inline styles
 */
export const Flag = ({
  children,
  level = 'neutral',
  type = 'status',
  style,
  textStyle,
  onPress,
  disabled,
  href,
  color,
  bgColor,
  hoverable,
}) => {
  const { theme, themeColors } = useTheme();
  const [isHovered, handlers] = useHover(disabled);
  const hasBackground = /status|tag|banner/.test(type);

  const containerStyles = [
    s.transition,

    styles.container,
    !hasBackground && styles.noPadding,
    hasBackground && {
      backgroundColor:
        isHovered && hoverable
          ? themeColors.hoverFunction(0.025, themeColors[`${level}Color`])
          : themeColors[`${level}Color`],
    },

    styles[`${type}Container`],
    color && theme[`${color}Color`],
    bgColor && theme[`${bgColor}Bg`],
    style,
    { borderRadius: 99 },
  ];

  const content = (
    <View style={type === 'banner' && theme.flex1}>
      <Text
        size="ffp"
        weight="medium"
        align="center"
        inline
        style={[
          /slug|status/.test(type) && styles.statusText,
          color && theme[`${color}Color`],
          textStyle,
        ]}
      >
        {children}
      </Text>
    </View>
  );

  if (onPress || href) {
    return (
      <Pressable
        handleOnPress={onPress}
        disabled={disabled}
        style={containerStyles}
        href={href}
        accessibilityRole={href ? 'link' : 'button'}
        target="_blank"
        {...handlers}
      >
        {content}
      </Pressable>
    );
  }
  return <View style={containerStyles}>{content}</View>;
};

const styles = {
  container: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  noPadding: {
    paddingHorizontal: 0,
  },
  statusText: {
    textTransform: 'capitalize',
  },
};

export default Flag;
