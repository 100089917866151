import styled, { keyframes } from 'styled-components';

import { Action } from '@types';
import { Asset, ContextMenu } from '@uikit';
import { Actions } from '@app/config';

import More from '@svg/dots-horizontal.svg';
import { BottomActions } from '@app/_common';

export interface PromptCardProps {
  index: number;
  children: React.ReactNode;
  actions?: Array<Action>;
}

const fadeKeyframes = keyframes`
  0% {
    transform: translateX(72px) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
`;

const StyledCard = styled.div<{ index: number }>`
  border-radius: ${(p) => p.theme.corners.round}px;
  width: ${(p) => p.theme.constants.promptWidthSm}px;
  border: 1px solid ${(p) => p.theme.colors.border};
  padding: 16px;
  min-height: 64px;
  background-color: ${(p) => p.theme.colors.bgCard};

  animation-delay: ${(p) => 250 + 50 * p.index}ms;
  animation-duration: ${(p) => 500 + 75 * p.index}ms;

  animation-fill-mode: both;
  transform-origin: right;
  animation-name: ${fadeKeyframes};
`;

const StyledMenu = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

/**
 * Displays a home page prompt card
 * - This takes into account sizing, overflow menu etc
 * - There are custom variations of each of these
 */
export function PromptCard({ index, children, actions }: PromptCardProps) {
  return (
    <StyledCard index={index}>
      <StyledMenu>
        <ContextMenu options={[Actions.HELP]}>
          <Asset containerSize="xs" size="xs" svg={More} bg="skeleton" shape="circle" />
        </ContextMenu>
      </StyledMenu>
      <StyledContent>{children}</StyledContent>
      <BottomActions actions={actions?.map((action, idx) => ({ ...action }))} />
    </StyledCard>
  );
}
