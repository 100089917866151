import gql from 'graphql-tag';

const REF = gql`
  query PlatformReference {
    viewer {
      id
      user {
        id
      }
    }
  }
`;

const formatter = (data) => ({
  ref: data,
});

export default {
  document: REF,
  formatter,
};
