type StorageType = 'localStorage' | 'sessionStorage';

function getStorage(storageType: StorageType): Storage {
  if (global[storageType] != null) {
    return global[storageType];
  }

  let data = {};

  return {
    key: (index: number) => Object.keys(data)?.[index] || null,
    length: Object.keys(data).length,
    setItem: (key, val) => (data[key] = String(val)),
    getItem: (key: string): string | null => (data.hasOwnProperty(key) ? data[key] : undefined),
    removeItem: (key) => delete data[key],
    clear: () => (data = {}),
  };
}

// Support for web and native cache storage
const Storage = getStorage('localStorage');

export { Storage };
