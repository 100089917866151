import { precisionRound } from '@app/utils';

interface FormValues {
  expenses: number;
  [goalID: string]: number; // number between 0 and 100
}

/**
 * Given an income amount and the current form values,
 * returns the computed amounts (total percentage, withholding amount, etc)
 */
export const calculatePaycheck = (incomeAmount: number, formValues: FormValues) => {
  const { expenses, ...percentages } = formValues;

  const appliedExpenses = expenses > incomeAmount ? incomeAmount : expenses;
  const totalPercentage = Object.values(percentages).reduce((total, p) => total + p, 0);
  const withholdFrom = precisionRound(incomeAmount - appliedExpenses, 2);

  const breakdowns = Object.keys(percentages).map((goalId) => {
    return {
      goalId,
      amount: Math.round(withholdFrom * percentages[goalId]),
    };
  });

  const withholdingAmount =
    breakdowns.reduce((total, breakdown) => total + breakdown.amount, 0) / 100;

  return {
    percentage: totalPercentage,
    withholdingAmount,
    net: withholdFrom - withholdingAmount,
    expenses: appliedExpenses || 0,
    breakdowns,
  };
};
