import { Navigate } from 'react-router';
import { stacks, Stack, StackComponent, useCurrentRoute, paths } from '@navigate';
import { OnboardingConsentView } from './routes/OnboardingConsentView';
import { useStackAccentColor } from '@app/hooks/useStackAccentColor';

const config = {
  stackName: stacks.ONBOARDING_STACK,
  options: {},
  screens: [OnboardingConsentView],
};

const OnboardingStack: StackComponent = () => {
  // note: if we don't actually match a pageview, we should redirect to the first screen
  const route = useCurrentRoute();
  const accentColor = useStackAccentColor(config.stackName);

  if (!route) return <Navigate to={paths.ONBOARDING_CONSENT} />;

  const stackOptions = {
    ...config.options,
    ...(accentColor && { accentColor }),
  };

  return <Stack stackName={config.stackName} screens={config.screens} options={stackOptions} />;
};

OnboardingStack.config = config;
export default OnboardingStack;
