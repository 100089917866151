import React from 'react';

import { Text } from '../../_ui-kit/components/Text';
import { Stack } from '@layouts';
import { LineItem } from '@uikit';
import { Currency, capitalize, useCopy } from '@app/utils';

const PREFIX = 'catch.ede.IncomeConfirm';

/**
 *
 */
const HealthMemberIncomeRow = ({ incomeSource, expense = false }) => {
  const { employerName, incomeSourceType, incomeAmount, incomeFrequencyType, type } = incomeSource;
  const { c } = useCopy(PREFIX);

  const incomeSourceName =
    employerName ||
    capitalize(incomeSourceType?.replace(/[_]/g, ' ')) ||
    capitalize(type?.replace(/[_]/g, ' '));

  return (
    <LineItem
      thin
      noBorder
      left={incomeSourceName}
      right={
        <Stack>
          <Text weight="medium" color="subtle" size="fp" align="right">
            {expense ? '-' : null}
            <Currency whole>{incomeAmount}</Currency>
          </Text>
          <Text weight="medium" color="subtle" size="fp" align="right">
            {` ${c('frequency', { frequency: incomeFrequencyType })}`}
          </Text>
        </Stack>
      }
    />
  );
};

export default HealthMemberIncomeRow;
