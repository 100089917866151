import { gql } from '@apollo/client';

export const clientFields = gql`
  extend type Query {
    transferEvents: [TransferEvent]
  }
  extend type User {
    legalName: String
    age: Int
  }
  extend type HealthPlan {
    title: String
    metal: String
    individualDeductible: Float
    familyDeductible: Float
    individualMax: Float
    familyMax: Float
  }
  extend type HealthApplication {
    isGeorgiaAccess: Boolean
    progress: Float
  }
  extend type HealthPolicy {
    coverageYear: Int
    shorthand: String
    dueDate: String
  }
  extend type ApplicationMember {
    legalName: String
  }
  extend type DocumentMetadata {
    name: String
  }
  extend type OpenEnrollmentDates {
    isWindowShopping: Boolean
    isOpenEnrollment: Boolean
    sepCoverageYear: Int
    sepYear: Int
    oeYear: Int
    lastOEYear: Int
    nextOEYear: Int
    coverageYears: [Int]
  }
  extend type Viewer {
    healthApplicationID: String
  }
  extend interface Goal {
    title: String
  }

  extend type Issuer {
    title: String
  }
  extend type CustomGoal {
    title: String
  }
  extend type TaxGoal {
    title: String
  }
  extend type PTOGoal {
    title: String
  }
  extend type RetirementGoal {
    title: String
  }
  extend type HealthExpensesGoal {
    title: String
  }
  extend type FamilyLeaveGoal {
    title: String
  }
  extend type EmergencySavingsGoal {
    title: String
  }
`;
