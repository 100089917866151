import React, { useState, useMemo } from 'react';

import { SheetBlueprint } from '@app/blueprints';
import { Dialog, Pressable } from '@app/_ui-kit';
import type { ContextMenuProps } from './types';

const ContextMenu = ({ id, title, options = [], children }: ContextMenuProps) => {
  const [open, setOpen] = useState(false);

  const actions = useMemo(() => {
    return options.map((opt) => ({
      ...opt,
      onAction: () => {
        if (opt?.onAction) opt?.onAction();
        setOpen(false);
      },
    }));
  }, [options]);

  return (
    <>
      <Pressable handleOnPress={() => setOpen(true)}>{children}</Pressable>
      <Dialog isOpen={open} onRequestClose={() => setOpen(false)}>
        <SheetBlueprint title={title} actions={actions} />
      </Dialog>
    </>
  );
};

export default ContextMenu;
