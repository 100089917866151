import React from 'react';
import { View } from 'react-native';
import { AccessoryType, TextColor } from '@types';
import { Asset } from './Asset';

import { Spinner } from '../components/Spinner';

import Edit from '@svg/edit.svg';
import ArrowRight from '@svg/chevron-right.svg';
import ArrowDown from '@svg/arrow-down.svg';
import ArrowUp from '@svg/arrow-up.svg';
import ChevronDown from '@svg/chevron-down.svg';
import ChevronUp from '@svg/chevron-up.svg';
import Selected from '@svg/check-circle.svg';
import Selectable from '@svg/stop.svg';
import More from '@svg/dots-horizontal.svg';
import Plus from '@svg/plus.svg';
import Lock from '@svg/lock.svg';
import Checkmark from '@svg/checkmark.svg';
import Error from '@svg/warning-triangle.svg';
import Close from '@svg/close.svg';
import Link from '@svg/link.svg';
import Radio from '@svg/radio-circle.svg';
import Circle from '@svg/circle.svg';

interface Props {
  loading?: boolean;
  size?: 'mini' | 'glyph' | 'xs'; // restrict what types of sizes
  accessory?: AccessoryType;
  color?: TextColor;
}

const accessoryPresets: Record<AccessoryType, { color?: string; svg: any }> = {
  down: { svg: ArrowDown },
  up: { svg: ArrowUp },
  arrow: { svg: ArrowRight },
  chevron: { svg: ChevronDown },
  chevronUp: { svg: ChevronUp },
  edit: { svg: Edit },
  check: { svg: Checkmark },
  x: { svg: Close },
  lock: { svg: Lock },
  menu: { svg: More },
  selected: { svg: Selected },
  unselected: { svg: Selectable },
  warning: { svg: Error },
  error: { color: 'error', svg: Error }, // same but colored in red
  loading: { svg: Spinner },
  add: { svg: Plus },
  link: { svg: Link },
  radio: { svg: Radio },
  circle: { svg: Circle },
  // other: { svg: Plus },
};

/**
 * Accessory -- provides presets for glyphs that should live on core
 * components like Rows, Cards as indicators/actions. The accessory may be
 * interactive via its parent component
 */
export const Accessory = ({ loading, accessory, size = 'sm', color }: Props) => {
  if (!accessory || loading) return null;

  if (accessory === 'loading') return <Spinner />;

  return accessoryPresets[accessory] ? (
    <Asset
      svg={accessoryPresets[accessory].svg}
      size="xs"
      color={color || accessoryPresets[accessory].color || 'switchOff'}
    />
  ) : (
    <View>{accessory}</View>
  );
};

export default Accessory;
