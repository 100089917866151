import { FetchResult } from '@apollo/client';
import { gql } from '../generated';
import { CreateGoalMutation } from '../types';

gql(`
  fragment Goal on Goal {
    id
    name
    slug
    status
    type
    withholdingPercentage
    availableBalance
    pendingBalance
    totalBalance
  }
`);

export const GET_GOAL_BY_ID = gql(`
  query GetGoalByID($id: ID!) {
    goal(id: $id) {
      ...Goal
    }
  }
`);

export const GET_GOAL_BY_SLUG = gql(`
  query GetGoalBySlug($slug: String!) {
    goal: goalBySlug(slug: $slug) {
      ...Goal
    }
  }
`);

export const GET_GOALS = gql(`
  query GetGoals {
    goals {
      ...Goal
    }
  }
`);

export const CREATE_GOAL = gql(`
  mutation CreateGoal($input: CreateGoalInput!) {
    createGoal(input: $input) {
      ...Goal
    }
  }
`);

export const UPDATE_GOAL = gql(`
  mutation UpdateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      ...Goal
    }
  }  
`);

export const ACTIVATE_GOALS = gql(`
  mutation ActivateGoals($input: ActivateGoalsInput!) {
    activateGoals(input: $input) {
      goals {
        id
        status
      }
    }
  }  
`);

/**
 * Consolidates the data we need for the goal estimator
 * page into a single query
 */
export const GET_GOAL_ESTIMATOR_PAGE = gql(`
  query GetGoalEstimatorPage($slug: String!) {
    goal: goalBySlug(slug: $slug) {
      ...Goal
    }
    goals {
      ...Goal
    }
  }
`);

export const CREATE_GOAL_CACHE_UPDATE = (cache, { data }: FetchResult<CreateGoalMutation>) => {
  const goalID = data?.createGoal?.id;
  const goalSlug = data?.createGoal?.slug;

  if (goalID) {
    cache.modify({
      fields: {
        goals(existing = [], { toReference }) {
          return [...existing, toReference({ __typename: 'Goal', id: goalID })];
        },
      },
    });
  }

  if (goalSlug) {
    cache.writeQuery({
      query: GET_GOAL_BY_SLUG,
      data: { goal: data?.createGoal },
      variables: { slug: goalSlug },
    });
  }
};
