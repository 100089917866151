import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Address, Text } from '@uikit';
import access from 'safe-access';
import { formatCurrency } from '@app/utils/format/currency';
import { ootIntl } from '../language/LanguageProvider';
import { toDate } from '../format_old/toDate';
import { toGQLDate, safeFormatDate } from '../time';

import STATES from '../const/STATES';
import constants from './constants';
import { enumCopy } from './enumCopy';

export const getFullName = (person, fallback = '') => {
  if (!person) return fallback;

  if (person.length > 1) {
    return person[0].familyName
      ? `${person[0].givenName} and ${person[1].givenName} ${person[0].familyName}`
      : `${person[0].givenName} and ${person[1].givenName}`;
  }

  if (person.length === 1) {
    return `${person[0].givenName} ${person[0].familyName}`;
  }

  return person.familyName
    ? `${person.givenName} ${person.familyName}`
    : person.givenName || fallback;
};

// Add boolean fields here so they don't get changed into trinary
const boolReg = /isSmoker|isPregnant|isParent/;

export const formatDocument = (values) => {
  if (!values) return {};
  return values?.lawfulPresenceDocumentation?.documentType ? (
    <FormattedMessage id={`catch.ede.enums.${values?.lawfulPresenceDocumentation?.documentType}`} />
  ) : undefined;
};

export const formatPayload = (values) => {
  if (!values) return {};
  if (typeof values === 'string') return values;
  return Object.keys(values).reduce((acc, k, i, arr) => {
    if (values[k] === true && !boolReg.test(k)) {
      acc[k] = 'YES';
    } else if (values[k] === false && !boolReg.test(k)) {
      acc[k] = 'NO';
    } else if (Array.isArray(values[k])) {
      acc[k] = values[k].map(formatPayload);
      // TODO: recursively format any nested object
    } else if (typeof values[k] === 'object' && values[k] !== null && 'incomeAmount' in values[k]) {
      acc[k] = formatPayload(values[k]);
    } else if (!/phone|Policy|ssn/.test(k) && /^[0-9]*$/.test(values[k])) {
      acc[k] = Number(values[k]);
    } else if (/Date|when|dob/.test(k)) {
      acc[k] = toGQLDate(values[k]);
    } else if (k === 'ethnicity') {
      if (values[k] === 'OTHER') {
        acc[k] = values.otherEthnicity;
      } else {
        acc[k] = constants.specificOrigins.find((o) => o.value === values[k]).value;
      }
    } else if (k === 'isRenewalAgreement') {
      if (values[k] === 'YES') {
        acc['renewEligibilityYearQuantity'] = 5;
      }
      acc[k] = values[k];
    } else {
      acc[k] = values[k];
    }
    return acc;
  }, {});
};

export const combineNames = (values, paths) =>
  paths.reduce((acc, s, i) => {
    const val = access(values, s);
    const space = i > 0 ? ' ' : '';
    if (val) return acc + space + val;
    return acc;
  }, '');
export const getLifeEvent = (values, path) => {
  const PREFIX = 'catch.ede.SEP';
  const lifeEvents = {
    isLostCoverageLast60Days: <FormattedMessage id={`${PREFIX}.lost_title`} />,
    isWillLoseCoverageNext60Days: <FormattedMessage id={`${PREFIX}.willlose_title`} />,
    isMarriedLast60Days: <FormattedMessage id={`${PREFIX}.marriage_title`} />,
    isGainDependent: <FormattedMessage id={`${PREFIX}.custody_title`} />,
    isMoved: <FormattedMessage id={`${PREFIX}.relocation_title`} />,
    isReleasedFromIncarceration: <FormattedMessage id={`${PREFIX}.incarceration_title`} />,
  };
  return lifeEvents[path];
};
export const formatAddress = (values, paths) => {
  const props = paths.reduce((acc, p) => {
    const val = access(values, p);
    const parts = p.split('.');
    const key = parts[parts.length - 1];
    return {
      ...acc,
      [key]: val,
    };
  }, {});
  return <Address {...props} />;
};
export const formatLocation = (values, path) => {
  return STATES[access(values, path)];
};
export const getLabelFromList = (options, value) => {
  return (options.find((o) => o.value === value) || {}).label;
};
export const formatLanguage = (values, path) => {
  return getLabelFromList(constants.languages, access(values, path));
};
export const formatCom = (values, path) => {
  return getLabelFromList(constants.contactMethods, access(values, path));
};
export const formatMStat = (values, path) => {
  return getLabelFromList(constants.maritalStatus, access(values, path));
};

export const formatBooleanToYesNo = (values, path) => {
  return access(values, path) === true
    ? enumCopy.YES
    : access(values, path) === false
    ? enumCopy.NO
    : null;
};

export const formatApplicants = (values, path) => {
  const PREFIX = 'catch.ede.HealthEnrollment';
  const members = access(values, path);
  const relationMap = {
    SELF: (values) => <FormattedMessage id={`${PREFIX}.self`} values={values} />,
    CHILD: (values) => <FormattedMessage id={`${PREFIX}.child`} values={values} />,
    SPOUSE: (values) => <FormattedMessage id={`${PREFIX}.spouse`} values={values} />,
  };
  return members
    ?.filter((m) => m.isRequestingCoverage)
    ?.map((m) => {
      const givenName = m?.givenName;
      const middleName = m?.middleName;
      const familyName = m?.familyName;
      return (
        <>
          {relationMap[m.relation]({ givenName, middleName, familyName })}
          <Text>{'\n'}</Text>
        </>
      );
    });
};
export const _oldHealthFormatDate = (values, path) => {
  const d = access(values, path);
  if (!d) {
    return null;
  }
  return safeFormatDate(toDate(d), 'MM/dd/yyyy');
};
export const formatSocial = (values, path) => {
  const val = access(values, path);
  return val ? `••• - •• - ${val.substring(7)}` : null;
};
export const formatRace = (values, path) => {
  return getLabelFromList(constants.races, access(values, path));
};
export const formatIncome = (values, path) => {
  return formatCurrency(access(values, path));
};
export const formatSex = (values, path) => {
  return getLabelFromList(constants.sex, access(values, path));
};
export const formatMedicaidDenial = (values, paths) => {
  return paths.find((p) => access(values, p) === true)
    ? enumCopy.YES
    : paths.find((p) => access(values, p) === false)
    ? enumCopy.NO
    : null;
};
export const formatInsuranceType = (values, paths) => {
  const val = access(values, `${paths[0]}.map()`, [
    (coverage, i) => {
      const type = access(coverage, paths[1]);
      return ootIntl.formatMessage({ id: `catch.ede.enums.${type}` });
    },
  ]);
  return Array.isArray(val) ? val.join(', ') : val;
};

export const formatCoverageRequest = (values, path) => {
  const val = access(values, path);
  return val ? 'Yes' : 'No';
};
