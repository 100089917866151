import { nextYear, currentYear } from '../time';

export const getCurrentYearEnrollment = (insuranceEnrollments) =>
  insuranceEnrollments?.find((e) => e?.year === currentYear && e?.status === 'ACTIVE');

export const getNextYearEnrollment = (insuranceEnrollments) =>
  insuranceEnrollments?.find((e) => e?.year === nextYear);

export function getDisplayEnrollment(insuranceEnrollments) {
  const enrollmentCurrentYear = Array.isArray(insuranceEnrollments)
    ? insuranceEnrollments?.find((e) => {
        return (!/INACTIVE/.test(e?.status) && e?.year === currentYear) || e?.year === currentYear;
      })
    : insuranceEnrollments;
  const enrollmentNextYear = Array.isArray(insuranceEnrollments)
    ? insuranceEnrollments?.find((e) => e?.year === nextYear)
    : insuranceEnrollments;
  return !!enrollmentCurrentYear ? enrollmentCurrentYear : enrollmentNextYear;
}

export const getActiveOrPendingEnrollments = (insuranceEnrollments) =>
  Array.isArray(insuranceEnrollments)
    ? insuranceEnrollments?.filter(
        (e) => e?.year === currentYear && (e?.status === 'ACTIVE' || /PENDING/.test(e?.status)),
      )
    : [];

export const getActiveEnrollments = (insuranceEnrollments) =>
  Array.isArray(insuranceEnrollments)
    ? insuranceEnrollments?.filter((e) => e?.year === currentYear && e?.status === 'ACTIVE')
    : [];

export function sortEnrollmentsByYear(insuranceEnrollments) {
  /** currently platform returns these with the year this is a placeholder function */
  const sortedEnrollments = insuranceEnrollments;
  return sortedEnrollments;
}

export function sortDisplayEnrollments(insuranceEnrollments) {
  /** sortEnrollments for only currentYear and nextYear
   * and that are active (pending, active, explorer)
   * to show on Plan View */
  const sortedEnrollments = insuranceEnrollments?.filter(
    (e) =>
      e?.status !== 'INACTIVE' || e.coverageYear === currentYear || e.coverageYear === nextYear,
  );
  return sortedEnrollments;
}

export function sortEnrollmentsByStatus(insuranceEnrollments) {
  /** placeholder for sorting enrollments for status */
  const sortedEnrollments = insuranceEnrollments;
  return sortedEnrollments;
}

const weights = {
  enrollmentStatus: {
    ACTIVE: 10,
    PENDING_START_DATE: 9,
    PENDING: 8,
    PENDING_SUPPORT_TASK: 7,
    INITIAL_OEP: 6,
    PREFILL: 6,
    INITIAL_SEP: 5,
  },
  applicationStatus: {
    IN_PROGRESS: 6,
  },
};

const enrollmentStatus = (e) => weights.enrollmentStatus?.[e?.status] || 0;
/**
 * Helper function to sort enrollments for OE
 * This is used to determine which enrollment to use for health hub status
 * This should be applied to enrollments that are filtered for OE year
 *
 * Sorts by  1? 2) enrollment progress 3) application progress (?)
 */
export const sortEnrollmentsForOE = (a, b) => {
  return enrollmentStatus(a) > enrollmentStatus(b) ? -1 : 1;
};
