import React, { useMemo, useState, useCallback } from 'react';
import { Button, Divider, Dialog } from '@uikit';
import { useCopy } from '@app/utils';
import { SheetBlueprint } from '@app/blueprints';
import Plus from '@svg/plus.svg';

interface HealthDocsUploadFooterProps {
  fieldName: string;
  docOptions?: Array<{ value: string }>;
  append: (item: any) => void;
  isLast: boolean;
}

const HealthDocsUploadFooter = ({
  fieldName,
  docOptions,
  append,
  isLast,
}: HealthDocsUploadFooterProps) => {
  const { c } = useCopy('catch.ede.enums');

  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);

  const options = useMemo(
    () =>
      (docOptions || []).map((o) => ({
        label: c(o.value),
        value: o.value,
        onAction: () => {
          append({ [fieldName]: o.value });
          close();
        },
      })),
    [docOptions],
  );

  return (
    <>
      <Dialog isOpen={isOpen} onRequestClose={close}>
        <SheetBlueprint actions={options} />
      </Dialog>
      <Button testID="add-document" onPress={open} small svg={Plus}>
        Add document
      </Button>
      {!isLast && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
    </>
  );
};

export default HealthDocsUploadFooter;
