import React, { useMemo } from 'react';
import { Layout, Split } from '@layouts';
import { useCopy, constants, getFullName } from '@app/utils';
import { mutations, useMutation } from '@data';
import { Fields, useForm } from '@app/forms';
import { Button } from '@app/_ui-kit';

const MedicaidDetermination = ({
  stateDeterminesMedicaidEligibility,
  members,
  applicationID,
  state,
}) => {
  const { c } = useCopy('catch.ede.EligibilityResults');
  const [upsert, { loading: submitting }] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const handleSubmit = (values) => {
    const applicationMembersInput = Object.keys(values)
      .filter((memberId) => !!values[memberId])
      .map((memberId) => ({
        id: memberId,
        isRequestingMedicaidDetermination: 'YES',
      }));

    upsert({ variables: { input: { applicationID, applicationMembersInput } } });
  };

  const fields = useMemo(() => {
    return members.map((m) => ({
      type: 'checkbox',
      name: m.id,
      plain: true,
      label: getFullName(m),
    }));
  }, [members]);

  const form = useForm({
    loading: false,
    disabled: submitting,
    fields,
    initialValues: {},
    onSubmit: handleSubmit,
  });

  return (
    <Split>
      <Layout.Header
        title={c('fullMedicaidTitle')}
        subtitles={[
          c('fullMedicaidText', {
            agencyName: constants.medicaidCHIPNames[state]['medicaid'],
          }),
          c('fullMedicaidQuestion', {
            agencyName: constants.medicaidCHIPNames[state]['medicaid'],
            stateDeterminesMedicaidEligibility,
          }),
        ]}
      />

      <div>
        <Fields form={form} fields={fields} />
        <Button
          testID="send-to-medicaid"
          alt
          gutter="bottom"
          onPress={form.submitForm}
          disabled={submitting}
        >
          {c('sendToMedicaidButton')}
        </Button>
      </div>
    </Split>
  );
};

export default MedicaidDetermination;
