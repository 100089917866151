import { useCurrentUser } from '@app/auth/useCurrentUser';
import { WhiteLabelOption } from '@data';
import { AETNA_ENABLED_STACKS } from '@app/constants/aetna';
import { UIAccentColor } from '@uikit/types';

export const useStackAccentColor = (stackName: string): UIAccentColor | undefined => {
  const { user } = useCurrentUser();

  if (user?.whiteLabel === WhiteLabelOption.Aetna && AETNA_ENABLED_STACKS.includes(stackName)) {
    return 'aetna';
  }

  // Default to undefined for all non-Aetna cases
  return undefined;
};
