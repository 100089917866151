import { DeductionsInput, IncomeSourceInput } from '@app/data';
import { parsePhone } from '@app/forms/inputs/PhoneInput';
import { floatize, getFullName, normalize } from '@app/utils';

export const formatIncomeSources = (incomeSources) =>
  (incomeSources || []).map((source) => ({
    ...source,
    employerPhoneNumber: parsePhone(source?.employerPhoneNumber),
  }));

export const formatDeductions = (deductions) =>
  (deductions || []).map((deduction) => ({
    ...deduction,
    type: deduction?.incomeSourceType,
  }));

export const getInitialValues = (member, options) => {
  return {
    id: member?.id,
    slasher: !!options?.slasher,
    relation: member?.relation,
    fullName: getFullName(member),
    incomeThisMonth: member?.incomeThisMonth,
    hasDeductions: member?.hasDeductions,
    incomeSources: formatIncomeSources(member?.incomeSources),
    deductions: formatDeductions(member?.deductions),
  };
};

const formatIncomeSource = (values): IncomeSourceInput => {
  return {
    incomeID: values.incomeID,
    type: values.type,
    incomeAmount: floatize(values.incomeAmount),
    incomeFrequencyType: values.incomeFrequencyType,
    employerName: normalize(values.employerName),
    employerPhoneNumber: normalize(values.employerPhoneNumber),
    employerAddress: values.employerAddress?.street1
      ? {
          street1: values.employerAddress.street1,
          street2: values.employerAddress.street2,
          city: values.employerAddress.city,
          state: values.employerAddress.state,
          zip: values.employerAddress.zip,
        }
      : undefined,
    employerIdentificationNumber: normalize(values.employerIdentificationNumber),
    averageWeeklyWorkHours: normalize(values.averageWeeklyWorkHours),
    averageWeeklyWorkDays: normalize(values.averageWeeklyWorkDays),
    selfEmploymentIncomeDescription: normalize(values.selfEmploymentIncomeDescription),
    incomeDescription: normalize(values.incomeDescription),
    otherIncomeDescription: normalize(values.otherIncomeDescription),
    educationExpenses: normalize(values.educationExpenses),
    expirationDate: normalize(values.expirationDate),
  };
};

const formatDeduction = (values): DeductionsInput => {
  return {
    incomeID: values.incomeID,
    incomeSourceType: values.type,
    incomeAmount: floatize(values.incomeAmount),
    incomeFrequencyType: values.incomeFrequencyType,
    otherDeductionDescription: values.otherDeductionDescription,
  };
};

export const formatPayload = ({ applicationID, values, context }) => {
  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: [
          {
            id: values?.id,
            applicationContext: context || undefined,
            // unsets the variable/unknown income indicators
            // the user will then answer these on the confirm page
            annualTaxIncome: {
              isVariableIncome: 'NIL',
              isUnknownIncome: 'NIL',
              // only upserts this as part of ARP changes for all members
              // when they receive any UNEMPLOYMENT benefit
              hasReceivedUnemploymentCompensation: values.incomeSources.some(
                (source) => source.type === 'UNEMPLOYMENT',
              )
                ? 'YES'
                : undefined,
            },
            // sets the values
            incomeSources: (values?.incomeSources || []).map((s) => formatIncomeSource(s)),
            deductions: (values?.deductions || []).map((s) => formatDeduction(s)),
          },
        ],
      },
    },
  };
};
