import React, { useMemo } from 'react';
import { SimpleRow } from '@uikit';
import { Stack } from '@layouts';
import { useCopy } from '@app/utils';
import {
  useQuery,
  NotificationSettingsQuery,
  useMutation,
  UpdateNotificationSettings,
  UpdateNotificationsCache,
} from '@data';

const EmailNotifications = () => {
  const { c } = useCopy('catch.profile.AccountSettings');
  const { data } = useQuery(NotificationSettingsQuery);

  const { myBenefits, recommendedBenefits, featureAnnouncements } = useMemo(() => {
    return {
      myBenefits: !!data?.viewer?.notificationSettings?.email?.myBenefits,
      recommendedBenefits: !!data?.viewer?.notificationSettings?.email?.recommendedBenefits,
      featureAnnouncements: !!data?.viewer?.notificationSettings?.email?.featureAnnouncements,
    };
  }, [data]);

  const [update] = useMutation(UpdateNotificationSettings, {
    update: UpdateNotificationsCache,
  });

  function handleChange(name, value) {
    update({
      variables: {
        input: {
          emailNotificationSettings: {
            myBenefits,
            recommendedBenefits,
            featureAnnouncements,
            [name]: value,
          },
        },
      },
    });
  }

  return (
    <Stack separatorComponent>
      <SimpleRow
        interaction="switch"
        label={c('myBenefits.title')}
        selected={!!myBenefits}
        selectedByDefault={!!myBenefits}
        onPress={(val) => handleChange('myBenefits', !myBenefits)}
      />
      <SimpleRow
        interaction="switch"
        label={c('recBenefits.title')}
        selected={!!recommendedBenefits}
        selectedByDefault={!!recommendedBenefits}
        onPress={(val) => handleChange('recommendedBenefits', !recommendedBenefits)}
      />
      <SimpleRow
        interaction="switch"
        label={c('features.title')}
        selected={!!featureAnnouncements}
        selectedByDefault={!!featureAnnouncements}
        onPress={(val) => handleChange('featureAnnouncements', !featureAnnouncements)}
      />
    </Stack>
  );
};

export default EmailNotifications;
