import { DocumentNode } from 'graphql';

// keep these in alphabetical order
import { AnswerIdentityQuestions } from './mutations/AnswerIdentityQuestions';
import { ConfirmIdentity } from './mutations/ConfirmIdentity';
import { ConfirmIdentityOffline } from './mutations/ConfirmIdentityOffline';
import { DeleteEnrollmentGroup } from './mutations/DeleteEnrollmentGroup';
import { EdeApplicationSearch } from './mutations/EdeApplicationSearch';
import { EnsureHealthApplication } from './mutations/EnsureHealthApplication';
import { FetchEligibility } from './mutations/FetchEligibility';
import { ImportAllExistingApplications } from './mutations/ImportAllExistingApplications';
import { ImportExplorerFromApp } from './mutations/ImportExplorerFromApplication';
import { RemoveApplicationMember } from './mutations/RemoveApplicationMember';
import { RemoveExplorerApplicant } from './mutations/RemoveExplorerApplicant';
import { ReportHealthEnrollmentChanges } from './mutations/ReportHealthEnrollmentChanges';
import { UpsertApplicationMembers } from './mutations/UpsertApplicationMembers';
import { UpsertExplorer } from './mutations/UpsertExplorer';
import { UpsertExplorerApplicants } from './mutations/UpsertExplorerApplicants';
import { UpsertHealthPreference } from './mutations/UpsertHealthPreference';
import { SubmitHealthApplication } from './mutations/SubmitHealthApplication';
import { UpsertEnrollmentGroups } from './mutations/UpsertEnrollmentGroups';
import { UpsertHealthPrivacy } from './mutations/UpsertHealthPrivacy';

// group these by section
type MutationName =
  // health link
  | 'HEALTH_LINK_LOOKUP'
  | 'HEALTH_LINK_IMPORT'
  | 'HEALTH_INITIAL_AGREE'
  | 'HEALTH_IDP'
  | 'HEALTH_IDP_FARS'
  | 'HEALTH_IDP_QUESTIONS'

  // health explorer
  | 'UPSERT_EXPLORER'
  | 'UPSERT_EXPLORER_APPLICANTS'
  | 'REMOVE_EXPLORER_APPLICANT'
  | 'IMPORT_EXPLORER_FROM_APP'
  | 'UPSERT_HEALTH_PREFERENCE'

  // health application
  | 'SUBMIT_HEALTH_APPLICATION'
  | 'UPSERT_APPLICATION_MEMBERS'
  | 'REMOVE_APPLICATION_MEMBER'
  | 'FETCH_ELIGIBILITY'
  | 'UPSERT_ENROLLMENT_GROUPS'
  | 'DELETE_ENROLLMENT_GROUP'

  // slasher
  | 'REPORT_HEALTH_ENROLLMENT_CHANGES'
  | 'ENSURE_HEALTH_APPLICATION';

// keep these in same order as MutationName definition
export const mutations: Record<MutationName, DocumentNode> = {
  // health link
  HEALTH_LINK_LOOKUP: EdeApplicationSearch,
  HEALTH_LINK_IMPORT: ImportAllExistingApplications,
  HEALTH_INITIAL_AGREE: UpsertHealthPrivacy,
  HEALTH_IDP: ConfirmIdentity,
  HEALTH_IDP_FARS: ConfirmIdentityOffline,
  HEALTH_IDP_QUESTIONS: AnswerIdentityQuestions,

  // health explorer
  UPSERT_EXPLORER: UpsertExplorer,
  UPSERT_EXPLORER_APPLICANTS: UpsertExplorerApplicants,
  REMOVE_EXPLORER_APPLICANT: RemoveExplorerApplicant,
  IMPORT_EXPLORER_FROM_APP: ImportExplorerFromApp,
  UPSERT_HEALTH_PREFERENCE: UpsertHealthPreference,

  // health application
  SUBMIT_HEALTH_APPLICATION: SubmitHealthApplication,
  UPSERT_APPLICATION_MEMBERS: UpsertApplicationMembers,
  REMOVE_APPLICATION_MEMBER: RemoveApplicationMember,
  FETCH_ELIGIBILITY: FetchEligibility,
  UPSERT_ENROLLMENT_GROUPS: UpsertEnrollmentGroups,
  DELETE_ENROLLMENT_GROUP: DeleteEnrollmentGroup,

  // premium slasher
  REPORT_HEALTH_ENROLLMENT_CHANGES: ReportHealthEnrollmentChanges,
  ENSURE_HEALTH_APPLICATION: EnsureHealthApplication,
};

/**
 * generated mutations
 */
export * from './mutations/ImportPreviousApplication';
export * from './mutations/ResendConsentRequest';
export * from './mutations/SignConsentRequest';
export * from './mutations/UpdateNotificationSettings';
export * from './mutations/UpdateUser';
export * from './mutations/UploadHealthIssueDocument';
export * from './mutations/UpsertHealthApplication';
export * from './mutations/UpsertNudge';
