import React from 'react';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { Theme } from '@radix-ui/themes';

// Internal Modules
import { AuthProvider } from '@auth';
import { appClient } from '@client';
import { LanguageProvider } from '@app/utils';
import { ThemeProvider as UIThemeProvider, ToastContainer } from '@uikit';
import { history } from '@navigate';
import { useTheme } from '@app/styles';

import 'react-toastify/dist/ReactToastify.css';

/**
 * AppProvider is our top level component that aggregates all necessary external
 * context providers and renders our App.  It is also in charge of setting up
 * any global style context that might be needed in various libraries.
 */
const AppProvider = ({ messages, children }) => {
  const { theme, colorScheme } = useTheme();

  return (
    <Theme appearance={colorScheme} style={{ height: '100%' }}>
      <ThemeProvider theme={theme}>
        <UIThemeProvider>
          <AuthProvider>
            <ApolloProvider client={appClient}>
              <Router history={history}>
                <LanguageProvider messages={messages}>
                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {children}
                    <div id="portal" />
                    <ToastContainer />
                  </div>
                </LanguageProvider>
              </Router>
            </ApolloProvider>
          </AuthProvider>
        </UIThemeProvider>
      </ThemeProvider>
    </Theme>
  );
};
export default AppProvider;
