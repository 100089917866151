import { RenderedIcon, SemanticColor, GoalSlug } from '@app/types';

export const benefitColorLookup = ({ slug, goalType }): SemanticColor => {
  if (slug) {
    switch (slug?.toLowerCase()) {
      case 'hsa':
        return 'coverage';
      case 'taxes':
      case 'tax':
        return 'taxes';
      case 'retirement':
        return 'retirement';
      case 'health':
        return 'coverage';
      default:
        return 'saved';
    }
  }

  if (goalType) {
    switch (goalType) {
      case 'TAX':
        return 'taxes';
      case 'RETIREMENT':
        return 'retirement';
      case 'DENTAL_INSURANCE':
        return 'coverage';
      case 'HEALTH':
      case 'DENTAL':
      case 'VISION':
      case 'LIFE':
        return 'coverage';
      default:
        return 'saved';
    }
  }
};

const _ICONS: Record<string, RenderedIcon> = {
  TAX: 'taxes',
  RETIREMENT: 'retirement',
  PTO: 'timeOff',
  FAMILY_LEAVE: 'familyLeave',
  HEALTH_EXPENSES: 'healthExpenses',
  EMERGENCY_SAVINGS: 'emergencySavings',
  DENTAL_INSURANCE: 'dental',
  DENTAL: 'dental',
  HEALTH: 'coverage',
  LIFE: 'life',
  VISION: 'vision',

  // custom savings
  TARGET: 'target',
  HOUSE: 'house',
  LUGGAGE: 'luggage',
  CAKE: 'cake',
  TOOLS: 'tools',
  CAR: 'car',
  LAPTOP: 'laptop',
  PRICE_TAG: 'priceTag',
  PET: 'pet',
  GIFT: 'gift',
  BRIEFCASE: 'bag',
  SAFE: 'safe',
};

const SlugToIcon: Record<GoalSlug, RenderedIcon> = {
  taxes: 'taxes',
  timeoff: 'timeOff',
  retirement: 'retirement',
  'family-leave': 'familyLeave',
  'health-expenses': 'healthExpenses',
  'emergency-savings': 'emergencySavings',
};

export const benefitIconLookup = ({ slug, goalType, customIcon }): RenderedIcon => {
  return SlugToIcon[slug] || _ICONS[goalType] || _ICONS[customIcon] || 'saved';
};
