import React from 'react';
import { LineItem } from '@uikit';
import { MembersAvatars } from '@app/partial';

/**
 * EnrollingMembersRow that shows a set of health members with a label
 * Currently used in:
 * 1) enrollment confirmation page (the members enrolling in a plan)
 * 2) enrollment group cart (selected plans)
 */
const EnrollingMembersRow = ({ label, members, noSpace = true, noBorder = true, ...rest }) => {
  return (
    <LineItem
      noSpace={noSpace}
      noBorder={noBorder}
      left={label}
      right={<MembersAvatars members={members} />}
    />
  );
};

export default EnrollingMembersRow;
