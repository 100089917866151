import { CSRLevel } from '@types';
import { EligibilityMember } from '@data';

type EligibilityBucket =
  | 'MARKETPLACE_ELIGIBLE'
  | 'NOT_ELIGIBLE_SEP'
  | 'MEDICAID'
  | 'CHIP'
  | 'NOT_ELIGIBLE';

interface EligibilityResult extends EligibilityMember {
  results: {
    eligibility: EligibilityBucket;
    hasAPTC: boolean;
    aptcAmount: number;
    hasCSR: boolean;
    csrLevel?: Omit<CSRLevel, 'NONE'>;
    hasPlanRestriction: boolean;
  };
}

interface EligibilityGroup {
  type: EligibilityBucket | Omit<CSRLevel, 'NONE'>;
  members: Array<EligibilityResult>;
  csr?: {
    isSubset?: boolean;
    members: Array<EligibilityResult>;
  };
}

interface ApplicationIssue {
  type: 'DMI' | 'SVI';
  date?: Date;
  qle?: string;
}

export const getResultsForMembers = (
  members: Array<EligibilityMember>,
  isOpenEnrollment: boolean,
): Array<EligibilityResult> => {
  // filter out members not requesting coverage
  return members
    .filter((m) => m?.isRequestingCoverage)
    .map((m) => getMemberEligibilityResult(m, isOpenEnrollment));
};

export const getMemberEligibilityResult = (
  member: EligibilityMember,
  isOpenEnrollment: boolean,
): EligibilityResult => {
  let eligibility: EligibilityBucket = 'NOT_ELIGIBLE';

  if (isOpenEnrollment && !!member.exchangeEligibility) {
    // eligible during OEP
    eligibility = 'MARKETPLACE_ELIGIBLE';
  } else if (!!member.sepEligibility && !!member.exchangeEligibility) {
    // eligible during SEP
    eligibility = 'MARKETPLACE_ELIGIBLE';
  } else if (!!member.exchangeEligibility && !member.sepEligibility) {
    eligibility = 'NOT_ELIGIBLE_SEP';
  } else if (!!member.medicaidEligibility) {
    eligibility = 'MEDICAID';
  } else if (!!member.chipEligibility) {
    eligibility = 'CHIP';
  }

  const aptc = member.aptcEligibility?.amount;
  const hasAPTC = !!aptc;
  const csr = member.csrEligibility?.csrLevel;
  const hasCSR = !!csr && csr !== 'NONE';

  return {
    ...member,
    results: {
      eligibility,
      hasAPTC,
      aptcAmount: hasAPTC ? aptc : 0,
      hasCSR,
      csrLevel: hasCSR ? csr : undefined,
      hasPlanRestriction: member?.metalLevelOption?.currentPlanOnlyIndicator,
    },
  };
};

const weights = {
  MARKETPLACE_ELIGIBLE: 5,
  NOT_ELIGIBLE_SEP: 4,
  MEDICAID: 3,
  CHIP: 2,
  NOT_ELIGIBLE: 1,
};

export const getEligibilityGroups = (
  members: Array<EligibilityResult>,
): Array<EligibilityGroup> => {
  const membersByResult = members.reduce((groups, m) => {
    const type = m.results.eligibility;
    const existing = groups[type] || [];
    return { ...groups, [type]: [...existing, m] };
  }, {});

  // @ts-ignore
  const resultsGroups = Object.keys(membersByResult).map((type: EligibilityBucket) => {
    // check which members are eligible for CSR
    const csrMembers = membersByResult[type].filter((m) => /SILVER/.test(m.results.csrLevel));
    const hasAnyCSR = csrMembers.length > 0;
    const csr = { members: csrMembers, isSubset: csrMembers.length < membersByResult[type].length };

    return {
      type,
      members: membersByResult[type],
      csr: hasAnyCSR ? csr : undefined,
    };
  });

  return resultsGroups.sort((a, b) => (weights[a.type] < weights[b.type] ? 1 : -1));

  // below: a way to show CSR groups separately

  // const membersByCSR = members.reduce((groups, m) => {
  //   const csr = m.results.csrLevel;
  //   if (!csr) return groups;
  //   const existing = groups[csr] || [];
  //   return { ...groups, [csr]: [...existing, m] };
  // }, {});

  // // @ts-ignore
  // const savingsGroups = Object.keys(membersByCSR).map((type: CSRLevel) => {
  //   return { type, members: membersByCSR[type] };
  // });

  // return [...resultsGroups, ...savingsGroups];
};

export const getApplicationIssues = (application): Array<ApplicationIssue> => {
  const dmis = application.dmis ? [...application.dmis] : [];
  const svis = application.svis ? [...application.svis] : [];

  const sortedDMIs = dmis.sort((a, b) => (a?.resolveBy > b?.resolveBy ? 1 : -1));
  const earliestDMI = sortedDMIs?.[0];

  const issues: Array<ApplicationIssue> = [];

  if (dmis?.length > 0) {
    issues.push({ type: 'DMI', date: new Date(earliestDMI?.resolveBy) });
  }

  if (svis?.length > 0) {
    issues.push({ type: 'SVI', qle: svis[0]?.statusCode });
  }

  return issues;
};

export const getEligibilityNextSteps = (members: Array<EligibilityResult>) => {
  const eligible = members.filter((m) => m.results.eligibility === 'MARKETPLACE_ELIGIBLE');

  const medicaidOrCHIP = members.filter(
    (m) => m.results.eligibility === 'MEDICAID' || m.results.eligibility === 'CHIP',
  );

  const notEligibleForSEP = members.filter((m) => m.results.eligibility === 'NOT_ELIGIBLE_SEP');

  return {
    numCanEnroll: eligible.length,
    allCanEnroll: members.length === eligible.length,
    someCanEnroll: eligible.length > 0,
    noneCanEnroll: eligible.length === 0,
    allMedicaidOrChip: members.length === medicaidOrCHIP.length,
    someMedicaidOrChip: medicaidOrCHIP.length > 0,
    noMedicaidOrChip: medicaidOrCHIP.length === 0,
    someNotEligibeForSEP: notEligibleForSEP.length > 0,
    splitEligibility: eligible.length > 0 && medicaidOrCHIP.length > 0,
  };
};
