import { useState, useEffect } from 'react';
import { useColorScheme } from './useColorScheme';
import { themes } from '../config/theme';
import { Theme } from '../types';

export const useTheme = () => {
  const { colorScheme } = useColorScheme();
  const [theme, setTheme] = useState<Theme>(themes[colorScheme]);

  useEffect(() => {
    setTheme(themes[colorScheme]);
  }, [colorScheme]);

  return { theme, colorScheme };
};
