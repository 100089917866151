import { useQuery, NudgesQuery, useMutation, UpsertNudge } from '@data';
import { formatISO } from 'date-fns';
import { useMemo } from 'react';

/**
 * useNudge handles checking whether a nudge is dismissed or not
 * - if nudge is listed and has been dismissed, don't show
 * - otherwise, if nudge isn't listed or hasn't been
 */
export function useNudge(nudgeIdentifier) {
  const { loading, data } = useQuery(NudgesQuery);
  const nudges = useMemo(() => data?.viewer?.nudges || [], [data]);

  const index = nudges.findIndex((n) => n.nudgeIdentifier === nudgeIdentifier);
  const isDismissed = index > -1 ? nudges[index].isDismissed : false;

  // Handles up[d]
  const [update, { loading: updating }] = useMutation(UpsertNudge);

  const handleUpdate = (n, show) =>
    update({
      variables: {
        input: {
          nudgeIdentifier: nudgeIdentifier || n,
          isDismissed: !show,
        },
      },
      optimisticResponse: {
        upsertNudge: {
          nudgeIdentifier: nudgeIdentifier || n,
          isDismissed: !show,
          lastUpdated: formatISO(new Date()),
          __typename: 'Nudge',
        },
      },
    });

  const showNudge = (n) => handleUpdate(n, true);
  const dismissNudge = (n) => handleUpdate(n, false);

  return {
    loading,
    show: !isDismissed,
    lastDismissed: nudges[index]?.lastDismissed,
    isDismissed,
    showNudge,
    dismissNudge,
    updating,
  };
}
