import React from 'react';
import { Button } from '@uikit';
import { ScreenDefinition } from '@navigate';
import { useCopy } from '@app/utils';
import { CalloutLayout, Toolbar } from '@layouts';
import { Route } from '@types';

const ExplorerSEP = ({ handleNext }) => {
  const { c } = useCopy('catch');

  return (
    <CalloutLayout
      loading={false}
      render="coverage"
      title={c('sep-title')}
      subtitles={[c('sep-subtitle')]}
      toolbar={
        <Toolbar>
          <Button inherit testID="get-started" onPress={handleNext}>
            {c('get-started')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const ExplorerSEPView: ScreenDefinition = {
  name: Route.EXPLORER_SEP,
  component: ExplorerSEP,
  options: {},
};
