import React, { useMemo } from 'react';
import { LineItem, Button, Loading, Text } from '@uikit';
import { Toolbar } from '@layouts';
import { safeFormatDate, useCopy } from '@app/utils';
import { Layout, Stack, Page } from '@layouts';
import { useForm, Fields, FormValues } from '@app/forms';
import { ConfirmationBlueprint } from '@app/blueprints';
import { FontSize, FontWeight, Route, TextAlign } from '@app/types';
import {
  APPROVE_INCOME,
  GET_GOALS,
  Goal,
  GoalStatus,
  GoalType,
  useFintechMutation,
  useFintechQuery,
} from '@app/data';
import { IncomeHeader } from '@app/components/income';
import { ApprovalType } from '../types';
import { calculatePaycheck } from './utils';

const shouldSkipGoal = (goal: Goal, approvalType: ApprovalType) => {
  return (
    goal?.status === GoalStatus.Inactive ||
    (approvalType === ApprovalType.W2 && goal.type === GoalType.TaxSavings)
  );
};

const getBreakdownLabel = (goal: Goal, approvalType: ApprovalType) => {
  if (approvalType === ApprovalType.W2 && goal.type === GoalType.TaxSavings) {
    return 'Already withheld';
  }

  if (goal?.status === GoalStatus.Inactive) {
    return 'Paused';
  }

  return undefined;
};

const PaycheckBreakdown = ({ income, approvalType, handleNext }) => {
  const { c, $ } = useCopy('catch.sequences.Paycheck.breakdown');

  const amount = Math.abs(income.amount);

  // fetch current goals
  const { loading, data } = useFintechQuery(GET_GOALS);
  const goals = (data?.goals || []).filter((goal) =>
    [GoalStatus.Active, GoalStatus.Pending, GoalStatus.Inactive].includes(goal?.status),
  );

  const [approveIncome, processing] = useFintechMutation(APPROVE_INCOME);

  const fields = useMemo(() => {
    return goals
      .sort((a: Goal, b: Goal) => {
        const withholdingA = shouldSkipGoal(a, approvalType) ? 0 : a.withholdingPercentage;
        const withholdingB = shouldSkipGoal(b, approvalType) ? 0 : b.withholdingPercentage;
        return withholdingA > withholdingB ? -1 : 1;
      })
      .map((goal) => ({
        name: goal.id,
        type: 'stepper',
        stepperType: 'percentage',
        inline: true,
        grouped: true,
        headerText: goal.name,
        label: getBreakdownLabel(goal, approvalType),
      }));
  }, [data]);

  const initialValues = useMemo(() => {
    return goals.reduce(
      (acc, goal: Goal) => {
        return {
          ...acc,
          [goal.id]: shouldSkipGoal(goal, approvalType) ? 0 : goal.withholdingPercentage,
        };
      },
      { expenses: 0 },
    );
  }, [data]);

  const form = useForm({
    loading,
    disabled: false,
    initialValues,
    fields,
    onSubmit: (values) => {
      const { expenses, breakdowns } = calculatePaycheck(amount, values);

      approveIncome({
        variables: {
          input: {
            incomeId: income.id,
            breakdowns: breakdowns,
            expenses,
          },
        },
      });
    },
  });

  if (loading) return <Loading />;

  return (
    <ConfirmationBlueprint
      loading={processing.loading}
      called={processing.called}
      error={!!processing.error}
      titles={{ done: 'Paycheck approved' }}
      onSuccess={handleNext}
    >
      <Page>
        <Layout.Scroll>
          <Layout margins>
            <IncomeHeader income={income} />
          </Layout>
          <Layout margins bottomSpace>
            <Stack spacing="1">
              <Fields
                fields={fields}
                form={form}
                stackProps={{ spacing: '0', separatorComponent: true }}
              />
              <FormValues control={form.methods.control}>
                {(values) => {
                  const amounts = calculatePaycheck(amount, values);

                  return (
                    <Stack spacing="1b" topSpace>
                      <LineItem
                        loading={loading}
                        testID="total"
                        left={`${c('totalTitle')} (${amounts.percentage}%)`}
                        right={
                          <Text color="text" weight={FontWeight.medium} align={TextAlign.right}>
                            {$(amounts.withholdingAmount)}
                          </Text>
                        }
                      />
                      <LineItem
                        left="Date"
                        right={
                          <Stack>
                            <Text color="text" weight={FontWeight.medium} align={TextAlign.right}>
                              {safeFormatDate(new Date())}
                            </Text>
                            <Text weight={FontWeight.light} align={TextAlign.right}>
                              Funds will be available in 3-5 days
                            </Text>
                          </Stack>
                        }
                      />
                    </Stack>
                  );
                }}
              </FormValues>
            </Stack>
          </Layout>
        </Layout.Scroll>
        <FormValues control={form.methods.control}>
          {(values) => {
            const amounts = calculatePaycheck(amount, values);
            const disabled = amounts.percentage > 100;

            return (
              <Toolbar type="stack">
                <Text size={FontSize.xs} color="subtle">
                  By clicking the “Authorize” button, I authorize Catch to debit the following
                  amount from my bank account and on the date as set forth above.
                </Text>
                <Button
                  accentColor="income"
                  disabled={form.disableSubmit || disabled}
                  onPress={form.submitForm}
                >
                  Authorize
                </Button>
              </Toolbar>
            );
          }}
        </FormValues>
      </Page>
    </ConfirmationBlueprint>
  );
};

export const PaycheckBreakdownView = {
  name: Route.PAYCHECK_BREAKDOWN,
  component: PaycheckBreakdown,
  options: {},
};
