// import chroma from 'chroma-js';

/** @deprecated */
export const colorgen = (themeColors, colors) => {
  return {
    // buttons
    primaryButton: { backgroundColor: themeColors.primaryButtonColor },
    primaryButtonText: { color: themeColors.primaryButtonTextColor },
    secondaryButton: { backgroundColor: themeColors.secondaryButtonColor },
    secondaryButtonText: { color: themeColors.secondaryButtonTextColor },
    pageBg: { backgroundColor: themeColors.pageColor },
    sheetBg: { backgroundColor: themeColors.sheetColor },
    setupBaseBg: { backgroundColor: themeColors.setupBaseColor },
    brandBaseBg: { backgroundColor: themeColors.brandBaseColor },
    redBaseBg: { backgroundColor: themeColors.redBaseColor },
    todoBaseBg: { backgroundColor: themeColors.todoBaseColor },
    incomeBaseBg: { backgroundColor: themeColors.incomeBaseColor },
    recBaseBg: { backgroundColor: themeColors.recBaseColor },
    disabledBaseBg: { backgroundColor: themeColors.disabledBaseColor },
    confirmBaseBg: { backgroundColor: themeColors.confirmBaseColor },
    transparentBg: { backgroundColor: 'transparent' },
    confirmedBg: { backgroundColor: themeColors.confirmedBgColor },
    skeletonBg: { backgroundColor: themeColors.skeletonColor },
    guideBg: { backgroundColor: themeColors.guideColor },
    gLightBg: { backgroundColor: themeColors.gLightColor },
    whiteBg: { backgroundColor: themeColors.pageColor },
    snowBg: { backgroundColor: colors.snow },
    primaryBg: { backgroundColor: themeColors.textColor },
    textBg: { backgroundColor: themeColors.textColor },
    darkBg: { backgroundColor: themeColors.alwaysDarkBgColor },
    assetActiveBg: { backgroundColor: themeColors.assetActiveBgColor },
    assetActiveText: { color: themeColors.assetActiveTextColor },
    assetActiveBorder: { borderColor: themeColors.assetActiveBorderColor },
    assetCompletedBg: { backgroundColor: themeColors.assetCompletedBgColor },
    assetCompletedText: { color: themeColors.assetCompletedTextColor },
    assetCompletedBorder: { borderColor: themeColors.assetCompletedBorderColor },
    assetIncompleteBg: { backgroundColor: themeColors.assetIncompleteBgColor },
    assetIncompleteText: { color: themeColors.assetIncompleteTextColor },
    assetIncompleteBorder: { borderColor: themeColors.assetIncompleteBorderColor },
    chipBg: { backgroundColor: themeColors.chipColor },
    loginPageBg: { backgroundColor: themeColors.loginPageColor },
    cardBg: { backgroundColor: themeColors.cardColor },
    cardHomeBg: { backgroundColor: themeColors.cardHomeColor },
    cardFooterBg: { backgroundColor: themeColors.cardFooterColor },
    confirmationBg: { backgroundColor: themeColors.confirmationColor },
    toolbarBg: { backgroundColor: themeColors.toolbarColor },
    borderBg: { backgroundColor: themeColors.borderColor },
    borderLightBg: { backgroundColor: themeColors.borderLightColor },
    borderHoverBg: { backgroundColor: themeColors.borderHoverColor },
    planBg: { backgroundColor: themeColors.planColor },
    planCardBg: { backgroundColor: themeColors.planCardColor },
    guideCardBg: { backgroundColor: themeColors.guideCardColor },
    checkupBg: { backgroundColor: themeColors.checkupColor },
    pageBg1: { backgroundColor: themeColors.pageLightColor },
    page1Bg: { backgroundColor: themeColors.pageLightColor },
    pageLightBg: { backgroundColor: themeColors.pageLightColor },
    homeHeaderBg: { backgroundColor: themeColors.homeHeaderColor },
    homeHeaderLoadingBg: { backgroundColor: themeColors.homeHeaderLoadingColor },
    navbarBg: { backgroundColor: themeColors.navbarColor },
    navbarLinkActiveBg: { backgroundColor: themeColors.navbarLinkActiveColor },
    flowNavBg: { backgroundColor: themeColors.bgGrayColor },
    flowNavOverlayBg: { backgroundColor: themeColors.flowNavOverlayColor },
    sageBannerBg: { backgroundColor: themeColors.sageBannerColor },
    sageBannerLightBg: { backgroundColor: themeColors.sageBannerLightColor },
    considerCalloutBg: {
      backgroundColor: themeColors.considerPlanCardColor,
    },
    nudgePeachBg: { backgroundColor: themeColors.nudgePeachColor },
    nudgeHoverBg: { backgroundColor: themeColors.nudgeHoverBgColor },
    planNudgeColor: { backgroundColor: themeColors.planNudgeColor },
    calloutBg: { backgroundColor: themeColors.calloutBgColor },
    healthCalloutBg: { backgroundColor: themeColors.healthCalloutBgColor },
    disabledCalloutBg: { backgroundColor: themeColors.disabledCalloutBgColor },
    referralBg: { backgroundColor: themeColors.referralBgColor },
    actionItemBg: { backgroundColor: themeColors.actionItemColor },
    actionItemIconBg: { backgroundColor: themeColors.actionItemIconColor },
    creditBg: { backgroundColor: themeColors.creditColor },
    creditLightBg: { backgroundColor: themeColors.creditLightColor },
    creditAltBg: { backgroundColor: themeColors.creditAltColor },
    deleteAltBg: { backgroundColor: themeColors.deleteAltColor },
    debitBg: { backgroundColor: themeColors.positive },
    debitLightBg: { backgroundColor: themeColors.debitLightColor },
    focusLightBg: { backgroundColor: themeColors.focusLightColor },
    paidLightBg: { backgroundColor: themeColors.paidLightColor },
    paycheckCardBgBg: { backgroundColor: themeColors.paycheckCardBgColor },
    paycheckCardBg: { backgroundColor: themeColors.paycheckCardBgColor },
    paycheckCardDividerColorBg: {
      backgroundColor: themeColors.paycheckCardDividerColor,
    },
    paycheckCardPillBg: { backgroundColor: themeColors.paycheckCardPillColor },
    switchOnBg: { backgroundColor: themeColors.switchOnColor },
    switchOffBg: { backgroundColor: themeColors.switchOffColor },
    disabledTrackBg: { backgroundColor: themeColors.textColor },
    promptBg: { backgroundColor: themeColors.promptColor },
    linkBg: { backgroundColor: themeColors.linkColor },
    devBg: { backgroundColor: themeColors.devColor },

    rulesBannerBg: { backgroundColor: themeColors.rulesBannerColor },

    moneyMovementBgBg: { backgroundColor: themeColors.moneyMovementBgColor },
    moneyMovementBgBgBg: { backgroundColor: themeColors.moneyMovementBgColor },
    selectedButtonBg: { backgroundColor: themeColors.selectedButtonBgColor },
    latestPaycheckBg: { backgroundColor: themeColors.latestPaycheckBgColor },
    latestPaycheckBgBg: { backgroundColor: themeColors.latestPaycheckBgColor },
    flagSubtleBg: { backgroundColor: themeColors.flagSubtleBgColor },
    grayBg: { backgroundColor: themeColors.grayColor },
    grayBgBg: { backgroundColor: themeColors.grayBgColor },
    darkGrayBg: { backgroundColor: themeColors.darkGrayBgColor },
    devBgBg: { backgroundColor: themeColors.devBgColor },
    nudgeHoverBgBg: { backgroundColor: themeColors.nudgeHoverBgColor },
    neutralBg: { backgroundColor: themeColors.neutral },
    nameBlockBg: { backgroundColor: themeColors.nameBlockBgColor },
    peachBtnBg: { backgroundColor: themeColors.peachBtnColor },
    autopilotBg: { backgroundColor: themeColors.autopilotBgColor },
    disabledBadgeBg: { backgroundColor: themeColors.disabledBadgeBgColor },
    activeBadgeBg: { backgroundColor: themeColors.activeBadgeBgColor },
    checkboxSelectedBg: { backgroundColor: themeColors.bgGrayColor },
    checkboxDeselectedBg: {
      backgroundColor: themeColors.skeletonColor,
    },
    progressBg: {
      backgroundColor: themeColors.fgColor,
    },

    progressTrackBg: {
      backgroundColor: themeColors.progressTrackBgColor,
    },

    checkupBubbleBg: {
      backgroundColor: themeColors.checkupBubbleBgColor,
    },
    paidBg: {
      backgroundColor: themeColors.paidColor,
    },
    paidText: {
      color: themeColors.paidTextColor,
    },

    confirmedIconBg: {
      backgroundColor: themeColors.confirmedIconBgColor,
    },

    primaryCalloutBg: {
      backgroundColor: themeColors.primaryCalloutBgColor,
    },
    importantCalloutBg: {
      backgroundColor: themeColors.importantCalloutBgColor,
    },
    urgentCalloutBg: {
      backgroundColor: themeColors.urgentCalloutBgColor,
    },
    subtleCalloutBg: {
      backgroundColor: themeColors.subtleCalloutBgColor,
    },
    successToast: {
      backgroundColor: themeColors.successToastColor,
    },
    neutralToast: {
      backgroundColor: themeColors.neutralToastColor,
    },
    neutralStatusBg: {
      backgroundColor: themeColors.neutralToastColor,
    },
    errorToast: {
      backgroundColor: themeColors.errorToastColor,
    },
    importantToast: {
      backgroundColor: themeColors.importantToastColor,
    },
    recommendToast: {
      backgroundColor: themeColors.recommendToastColor,
    },
    primaryCalloutBorder: {
      borderColor: themeColors.primaryCalloutBorderColor,
    },
    importantCalloutBorder: {
      borderColor: themeColors.importantCalloutBorderColor,
    },
    urgentCalloutBorder: {
      borderColor: themeColors.urgentCalloutBorderColor,
    },
    subtleCalloutBorder: {
      borderColor: themeColors.subtleCalloutBorderColor,
    },
    flowNavOverlayBorder: {
      borderColor: themeColors.flowNavOverlayColor,
    },

    pageBorder: {
      borderColor: themeColors.pageColor,
    },

    snowBorder: {
      borderColor: colors.snow,
    },
    secondaryBorder: {
      borderColor: themeColors.secondaryColor,
    },
    subtleBorder: {
      borderColor: themeColors.subtleColor,
    },
    textBorder: {
      borderColor: themeColors.textColor,
    },
    inkBorder: {
      borderColor: colors.ink,
    },
    snowColor: { color: colors.snow },
    whiteColor: { color: themeColors.pageColor },
    pageColor: { color: themeColors.pageColor },
    toolbarColor: { color: themeColors.toolbarColor },
    borderColor: { color: themeColors.borderColor },

    optionColor: { color: themeColors.optionTextColor },
    planCardColor: { backgroundColor: themeColors.planCardColor },
    pageColor1: { color: themeColors.pageLightColor },
    page1Color: { color: themeColors.pageLightColor },
    pageLightColor: { color: themeColors.pageLightColor },
    homeHeaderColor: { color: themeColors.homeHeaderColor },
    navbarLinkActiveColor: { color: themeColors.navbarLinkActiveColor },
    navbarLinkColor: { color: themeColors.navbarLinkColor },
    linkColor: { color: themeColors.linkColor },
    textColor: { color: themeColors.textColor },
    reverseTextColor: { color: themeColors.reverseTextColor },
    textSubtleColor: { color: themeColors.textSubtleColor },
    secondaryColor: { color: themeColors.secondaryColor },
    sageSubtleColor: { color: themeColors.sageSubtleColor },
    subtleColor: { color: themeColors.subtleColor },
    subtlerColor: { color: themeColors.subtlerColor },
    subtle: { color: themeColors.subtleColor },
    creditColor: { color: themeColors.creditColor },
    creditAltColor: { color: themeColors.creditAltColor },
    deleteAltColor: { color: themeColors.deleteAltColor },
    positive: { color: themeColors.positive },

    // why am i adding to the problem...
    positiveBg: { backgroundColor: themeColors.positiveColor },
    negativeBg: { backgroundColor: themeColors.negativeColor },
    positiveLightBg: { backgroundColor: themeColors.positiveLightColor },
    negativeLightBg: { backgroundColor: themeColors.negativeLightColor },

    paycheckCardColor: { color: themeColors.paycheckCardColor },
    paycheckCardTextColor: { color: themeColors.paycheckCardTextColor },
    latestPaycheckColor: { color: themeColors.latestPaycheckColor },
    errorColor: { color: themeColors.errorColor },
    errorTextColor: { color: themeColors.errorColor },
    flagSubtleColor: { color: themeColors.flagSubtleColor },
    promptColor: { color: themeColors.promptTextColor },
    autopilotColor: { color: themeColors.autopilotTextColor },
    confirmationColor: { color: themeColors.confirmationColor },
    moneyMovementColor: { color: themeColors.moneyMovementColor },
    focusColor: { color: themeColors.focusColor },
    borderBorderColor: { borderColor: themeColors.borderColor },
    borderHoverBorderColor: { borderColor: themeColors.borderHoverColor },
    whiteBorderColor: { borderColor: themeColors.pageColor },
    navbarLinkActiveBorderColor: {
      borderBottomColor: themeColors.navbarLinkActiveColor,
    },
    focusBorderColor: {
      borderColor: themeColors.focusColor,
    },

    // Flag
    primaryColor: { color: themeColors.primaryTextColor },
    urgentColor: { color: themeColors.urgentTextColor },
    urgentLiteColor: { color: themeColors.urgentLiteTextColor },
    vitalColor: { color: themeColors.vitalTextColor },
    importantColor: { color: themeColors.importantTextColor },
    confirmedColor: { color: themeColors.confirmedTextColor },
    disabledColor: { color: themeColors.disabledColor },
    neutralColor: { color: themeColors.neutralTextColor },
    considerColor: { color: themeColors.considerTextColor },
    coveredColor: { color: themeColors.coveredTextColor },
    recommendedColor: { color: themeColors.recommendedTextColor },

    // outside of flags...
    successColor: { color: themeColors.successColor },
    successBg: { backgroundColor: themeColors.successColor },
    successBaseBg: { backgroundColor: themeColors.successBaseColor },
    successLightBg: { backgroundColor: themeColors.successLightColor },
    primaryBackground: { backgroundColor: themeColors.primaryColor },
    urgentBackground: { backgroundColor: themeColors.urgentColor },
    urgentLiteBackground: { backgroundColor: themeColors.urgentLiteColor },
    importantBackground: { backgroundColor: themeColors.importantColor },
    confirmedBackground: { backgroundColor: themeColors.confirmedColor },
    disabledBackground: { backgroundColor: themeColors.disabledColor },
    neutralBackground: { backgroundColor: themeColors.neutralColor },
    considerBackground: { backgroundColor: themeColors.considerColor },
    urgentBg: { backgroundColor: themeColors.urgentColor },
    urgentLiteBg: { backgroundColor: themeColors.urgentLiteColor },
    importantBg: { backgroundColor: themeColors.importantColor },
    disabledBg: { backgroundColor: themeColors.disabledColor },
    considerBg: { backgroundColor: themeColors.considerColor },
    defaultBg: { backgroundColor: themeColors.skeletonColor },
    recommendedBg: { backgroundColor: themeColors.recommendedColor },
    sageDarkBg: { backgroundColor: themeColors.sageDarkColor },
  };
};
