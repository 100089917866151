import React from 'react';
import { View } from 'react-native';

import { Layout, Stack } from '@layouts';
import { Button, Link, useTheme } from '@uikit';
import { useCopy, formatPeople } from '@app/utils';
import { MembersAvatars } from '@app/partial';
import { Color } from '@app/styles';
import { TextAlign } from '@app/types';

/**
 * PlanSelectedSheet shows an action sheet after a plan has been selected for an enrollment group
 */
const PlanSelectedSheet = ({
  handleNextGroup,
  handleSkipGroup,
  handleDentalGroup,
  planName,
  currentMembers,
  orphanedMembers,
  nextGroup,
  hasNoDental,
}) => {
  const { theme } = useTheme();
  const { c } = useCopy('catch.health');

  /**
   * show the option to continue if there are more members
   * (these can be either in another group or orphaned)
   */
  const showNextGroup =
    orphanedMembers?.length ||
    (nextGroup?.members?.length > 0 && nextGroup?.enrollmentType !== 'DENTAL_INSURANCE');

  /**
   * Prompts users with the full dental prompt
   * whenever we're at the end
   */
  const showDentalPrompt =
    (nextGroup?.members?.length === 0 ||
      !nextGroup ||
      nextGroup?.enrollmentType === 'DENTAL_INSURANCE') &&
    !hasNoDental;

  const selected = (
    <Stack spacing="2">
      <Layout.Header
        color="covered"
        title={c('planSelected')}
        subtitle={planName}
        graphic={<MembersAvatars members={currentMembers} currentIndex={currentMembers.length} />}
      />
      {!showNextGroup && !showDentalPrompt && (
        <Button testID="continue" wide onPress={() => handleSkipGroup({ goToDental: false })}>
          {c('continue')}
        </Button>
      )}
    </Stack>
  );

  const next = (
    <View>
      <Layout.Header
        title={c('nextUp')}
        subtitle={c('chooseNext', {
          people: formatPeople({ people: nextGroup?.members || orphanedMembers }),
        })}
        graphic={
          <MembersAvatars members={nextGroup?.members || orphanedMembers} currentIndex={-1} />
        }
      />
      <View style={[theme.centered, theme.topGutter4]}>
        <Button testID="view-plans" wide onPress={handleNextGroup}>
          {c('viewPlans')}
        </Button>
        <Stack>
          <Link
            testID="continue-without-coverage"
            color={Color.text}
            align={TextAlign.center}
            onPress={() => handleSkipGroup({ goToDental: false })}
          >
            {c('continueWithoutCoverage')}
          </Link>
          <Link
            testID="go-to-dental"
            align={TextAlign.center}
            color={Color.text}
            onPress={() => handleSkipGroup({ goToDental: true })}
          >
            {c('skipToDental')}
          </Link>
        </Stack>
      </View>
    </View>
  );

  const dental = (
    <View>
      <Layout.Header title={c('dentalPromptTitle')} subtitle={c('dentalPromptSubtitle')} />
      <View style={[theme.centered, theme.topGutter4]}>
        <Stack spacing="2">
          {hasNoDental ? (
            <Button testID="continue" wide onPress={() => handleSkipGroup({ goToDental: false })}>
              {c('continue')}
            </Button>
          ) : (
            <>
              <Button testID="go-to-dental" wide onPress={handleDentalGroup}>
                {c('viewDental')}
              </Button>
              <Link
                testID="handle-skip"
                color={Color.text}
                align={TextAlign.center}
                onPress={() => handleSkipGroup({ goToDental: false })}
              >
                {c('noThanks')}
              </Link>
            </>
          )}
        </Stack>
      </View>
    </View>
  );

  return (
    <Layout margins topSpace bottomSpace>
      <Stack separatorComponent spacing="4">
        {selected}
        {showNextGroup ? next : null}
        {showDentalPrompt ? dental : null}
      </Stack>
    </Layout>
  );
};

export default PlanSelectedSheet;
