export const questions = [
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '239',
    name: 'isUnpaidBill',
    title: { id: '239_L' },
    label: { id: '239_Q' },
    help: { id: '239_T' },
    filterMembers: (member) => member?.preliminaryMedicaidStatus === 'YES',
    field: {
      name: 'members',
      type: 'array',
      subfields: [
        {
          name: 'isUnpaidBill',
          bare: true,
          type: 'person',
          dependencies: ['fullName'],
          label: (values) => values.fullName,
        },
      ],
    },
  },
];
