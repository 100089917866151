import { useCalendlyEventListener } from 'react-calendly';
import { useTheme } from 'styled-components';
import { useQuery, UserQuery } from '@app/data';
import { Segment, SegmentEvent } from '@app/utils/analytics';

export function useCalendly(props?: { onScheduled?: () => void }) {
  const { data } = useQuery(UserQuery);
  const theme = useTheme();

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      if (props?.onScheduled) props.onScheduled();
      Segment.simpleTrack(SegmentEvent.CALENDLY_SCHEDULED);
    },
  });

  return {
    prefill: {
      name: data?.viewer?.user?.legalName,
      firstName: data?.viewer?.user?.givenName,
      lastName: data?.viewer?.user?.familyName,
      email: data?.viewer?.user?.email,
    },
    pageSettings: {
      primaryColor: theme.colors.brand,
      textColor: theme.colors.fg,
      backgroundColor: theme.colors.bgSheet,
    },
  };
}
