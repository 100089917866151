import React, { useEffect } from 'react';
import {
  Segment,
  SegmentEvent,
  useCopy,
  Env,
  renderQuestions,
  handleHealthErrors,
} from '@app/utils';
import { navigate } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { Route } from '@types';
import {
  HealthApplicationQuery,
  mutations,
  useDeprecatedMutation,
  useMutation,
  useQuery,
} from '@app/data';
import { Button, popToast, ToastType } from '@app/_ui-kit';

/**
 * This page gets shown when we have an ErrorCode.AlreadyExists error from the ensure mutation
 * This is a special case for GA applicants - it indicates the contact email entered
 * on the ID Info page is already in use. So we show them this view to update to an alternate email address
 */

const HealthAlternateEmail = ({ isGA, applicationID, enrollId }) => {
  const { c } = useCopy('catch.ede.AlternateEmailView');

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  //don't want to route back to member info view so we call ensure here too
  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    ...handleHealthErrors,
    enrollId,
    onCompleted: ({ uiQuestionsToDisplay }) => {
      const { renderMemberQuestions, renderImmigrationQuestions } = renderQuestions({
        uiQuestionsToDisplay,
      });

      const isRequestingFinancialAssistance =
        data?.viewerTwo.health.application?.screening?.isRequestingFinancialAssistance;

      if (renderMemberQuestions) {
        navigate(Route.EDE_MEMBER_QUESTIONS);
      } else if (renderImmigrationQuestions) {
        navigate(Route.EDE_IMMIGRATION_DETAILS);
      } else if (isRequestingFinancialAssistance) {
        navigate(Route.EDE_MEDICAID_DENIAL);
      } else {
        navigate(Route.EDE_SEP);
      }
    },
  });

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS, {
    refetchQueries: [HealthApplicationQuery],
    awaitRefetchQueries: true,
    onCompleted: () => {
      Segment.simpleTrack(SegmentEvent.EMAIL_ADJUSTED);

      ensure({ variables: { applicationID } });

      Segment.track(SegmentEvent.APPLICATION_MEMBER_INFO_COMPLETED, {
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
      });
    },
  });

  const fields = [
    {
      testID: '9',
      copy: 'catch.ede.HealthEnrollment',
      name: 'email',
      type: 'email',
      required: true,
      label: { id: '9_L' },
      validate: (value) => {
        if (
          !/^(?![._-])(?!.*[._-]{2,})([a-zA-Z0-9]+[._-]?)*[a-zA-Z0-9]+@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/.test(
            value,
          )
        ) {
          return Env.isProd
            ? 'Emails can only contain numbers, letters, and . _ or -'
            : 'Emails can only contain numbers, letters, and . _ or -. (dev only msg: this was a rule implemented by CMS in SES R37.1 which ignored guidance on best practices)';
        }
      },
    },
  ];

  const handleSubmit = async (values) => {
    const id = data?.viewerTwo?.health?.application?.applicant?.id;

    if (id && values?.email) {
      await upsert({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: [
              {
                id: id,
                email: values?.email,
              },
            ],
          },
        },
      });
    } else {
      popToast({
        type: ToastType.error,
        msg: 'You must enter a valid email',
        title: 'Invalid email',
        autoCloseIn: 5000,
      });
    }
  };

  useEffect(() => {
    //shouldn't be here if not part of GA
    if (!isGA) {
      navigate(Route.EDE_HELP);
    }
  }, []);

  return (
    <BasicFormBlueprint
      title={c('title')}
      subtitle={c('subtitle')}
      loading={loading || upserting || !!ensuring}
      actions={({ handleSubmit, disabled }) => [
        <Button
          key="submit"
          testID="ede-ga-alternate-email"
          onPress={handleSubmit}
          disabled={disabled}
        >
          Next
        </Button>,
      ]}
      formConfig={{
        fields,
        onSubmit: (values) => handleSubmit(values),
      }}
    />
  );
};

export const HealthAlternateEmailView = {
  name: Route.EDE_ALTERNATE_EMAIL,
  component: HealthAlternateEmail,
  options: {},
};
