import React, { useCallback, useMemo } from 'react';
import {
  useDeprecatedMutation,
  useQuery,
  HealthApplicationQuery,
  getMembers,
  useMutation,
  mutations,
} from '@data';
import { navigate, sheets, useSheet } from '@navigate';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { getInitialValues, formatPayload } from './incomeDetailsUtils';
import { fields } from './incomeDetailsFields';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { shallow } from 'zustand/shallow';
import { loaders } from '@app/config';
import { Route } from '@app/types';

/**
 * IncomeDetailsView
 *
 * Page where users can add their itemized income and deductions
 */
const IncomeDetails = ({ applicationID, coverageYear }) => {
  const { c } = useCopy('catch.ede.IncomeDetails');

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const members = useMemo(() => {
    return getMembers(data)?.filter((m) => m.incomeThisMonth || m.hasDeductions);
  }, [data?.viewerTwo?.health?.application]);

  const { openLoader } = useSheet(
    (state) => ({ openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT) }),
    shallow,
  );

  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    onCompleted: () => {
      Segment.track(SegmentEvent.APPLICATION_INCOME_DETAILS_ENTERED, {
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
      });

      navigate(Route.EDE_MEMBER_INCOME_CONFIRM);
    },
    // ...handleHealthErrors,
  });

  const onNext = useCallback(
    (values) => upsert(formatPayload({ applicationID, values })),
    [applicationID, upsert, formatPayload],
  );

  const onComplete = useCallback(() => {
    openLoader();
    ensure({ variables: { applicationID } });
  }, [applicationID]);

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      submitting={ensuring}
      getInitialValues={getInitialValues}
      fields={fields}
      data={{ coverageYear }}
      title={(m) => c('loadingTitle', { loading, relation: m?.relation })}
      subtitle={(m) => c('subtitle', { givenName: m?.givenName })}
      members={members}
      onNext={onNext}
      onComplete={onComplete}
    />
  );
};

export const IncomeDetailsView = {
  name: Route.EDE_MEMBER_INCOME_DETAILS,
  component: IncomeDetails,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Income',
  },
};
