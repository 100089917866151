import { Income } from '@app/data';
import { Layout } from '@app/layouts';
import { date } from '@app/utils/format/date';
import { getIncomeLabel, getIncomeLogo } from './helpers';

export function IncomeHeader({ income }: { income: Income }) {
  return (
    <Layout.Header
      align="center"
      bottomSpace
      asset={{
        size: 'md',
        shape: 'circle',
        uri: getIncomeLogo(income),
      }}
      title={`$${Math.abs(income.amount)} from ${getIncomeLabel(income)}`}
      subtitle={date(income.transactionDate, 'RELATIVE')}
    />
  );
}
