import React, { useMemo } from 'react';
import { useQuery, AliasQuery } from '@data';
import { useCopy } from '@app/utils';
import { TouchableField } from '@uikit';
import { Section, Stack } from '@layouts';
import { SettingsLayout, LabelText } from '@common';
import { default as EmailNotifications } from './partials/EmailNotifications';

/**
 * @todo SMS prefs
 */
const AccountSettings = () => {
  const { c } = useCopy('catch.profile.AccountSettings');
  const { c: me } = useCopy('catch.module.me.UserDetailsView');
  const { loading, data } = useQuery(AliasQuery);

  const { email, phone } = useMemo(() => {
    return {
      email: data?.viewer?.user?.email,
      phone: data?.viewer?.user?.phoneNumber,
    };
  }, [data]);

  return (
    <SettingsLayout title="Settings">
      <Stack mobileGap>
        <Section>
          <Stack separatorComponent>
            <TouchableField
              link={!email}
              value={email || me('addInfo')}
              loading={loading}
              label={<LabelText fieldName="EmailField" />}
            />
            <TouchableField
              link={!phone}
              value={phone || me('addInfo')}
              loading={loading}
              label={<LabelText fieldName="PhoneNumberField" />}
            />
          </Stack>
        </Section>
        <Section title={c('emailPrefTitle')}>
          <EmailNotifications />
        </Section>
      </Stack>
    </SettingsLayout>
  );
};

export const AccountSettingsView = {
  name: 'ACCOUNT_SETTINGS',
  component: AccountSettings,
  options: {
    title: 'Account Settings',
    largeTitle: true,
  },
};
