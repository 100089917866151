import { gql } from '../generated';

gql(`
  fragment WalletItem on WalletItem {
    id
    vertical
    carrier
    planName
    source
    groupNumber
    policyNumber
    notes
  }
`);

export const GET_WALLET = gql(`
  query GetWallet {
    viewer {
      id
      wallet {
        id
        ...WalletItem
      }
    }
  }
`);
