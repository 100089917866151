import { gql } from '../generated';

// @todo always use current year + next year
export const GET_COVERAGE = gql(`
  query GetCoverage($coverageYears: [Int!]) {
    viewer {
      id
      user {
        id
        supportedPathways
      }
      wallet {
        id
        ...WalletItem
      }
    }
    viewerTwo {
      id
      health {
        applications(
          input: {
            coverageYearNumber: $coverageYears
            statuses: [IN_PROGRESS, SUBMITTED, DRAFT, PENDING_ENROLLMENT, ENROLLMENT_STUCK]
          }
        ) {
          id
          applicationContext
          lastUsedRoute
          progress @client
          status
          coverageYearNumber
          coverageState
          pathwayType
          isGeorgiaAccess @client
          lastUserUpdate
          enrollment {
            id
          }
        }
        policies(input: { coverageYearNumber: $coverageYears }) {
          id
          startDate
          endDate
          dueDate @client
          coverageYear @client
          planName
          carrier
          premium
          premiumEffective
          policyStatus
          enrollmentID
          productType
          providerPlan {
            id
            metalLevel
            issuer {
              id
              name
            }
          }
        }
      }
      healthExplorerData {
        id
        state
        pathwayType
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
`);

export const GET_COVERAGE_DETAIL = gql(`
  query GetCoverageDetail($policyID: ID!) {
    viewerTwo {
      id
      health {
        policy(id: $policyID) {
          id
          startDate
          endDate
          dueDate @client
          coverageYear @client
          planName
          carrier
          premium
          premiumEffective
          policyStatus
          productType
          phoneNumber
          policyNumber
          exchangePolicyNumber
          subscriberID
          exchangeSubscriberID
  
          application {
            id
            pathwayType
            asyncStatus
            enrollment {
              id
              hasAssociatedDentalPlan
            }
            ednDocuments {
              id
              ...EDNDocument
            }
            ...ApplicationIssues
          }
  
          providerPlan {
            planID: id
            ...HealthPlanDetails
          }
  
          enrolledDependents {
            id
            givenName
            middleName
            familyName
            relation
          }
  
          enrollmentID
        }
      }
    }
  }
`);
