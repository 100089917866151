import React, { useState } from 'react';
import { View } from 'react-native';
import { useSpring, animated } from 'react-spring';
import { FormLabel, Text, Input, useTheme, Asset } from '@uikit';
import Checkmark from '@svg/checkmark.svg';
import { InputProps } from '../types';
import * as pwValidation from '../utils/validation/password';

interface PasswordInputProps<T> extends InputProps<T> {
  passwordType: 'existing' | 'new';
  requirements?: boolean;
}

const Requirement = ({ description, isValid }) => {
  const { theme } = useTheme();

  return (
    <View style={[theme.row, theme.bottomGutter1]}>
      <Asset svg={Checkmark} size="xs" color={isValid ? 'positive' : 'subtle'} />
      <Text
        gutter="left"
        size="fp"
        color={isValid ? 'positive' : 'subtle'}
        weight={isValid ? 'medium' : 'regular'}
      >
        {description}
      </Text>
    </View>
  );
};

const PasswordInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      passwordType,
      name,
      value,
      label,
      sublabel,
      help,
      optional,
      required,
      disabled,
      placeholder,
      onChange,
      onBlur,
      error,
      requirements,
    }: PasswordInputProps<T>,
    ref,
  ) => {
    const [focused, setFocused] = useState<boolean>(false);

    const [heightStyle] = useSpring(
      () => ({
        from: { height: 0, overflow: 'hidden' },
        to: { height: focused ? 164 : 0, overflow: 'hidden' },
      }),
      [focused],
    );

    const [opacityStyle] = useSpring(
      () => ({
        from: { opacity: 0 },
        to: { opacity: focused ? 1 : 0 },
      }),
      [focused],
    );

    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
          required={required}
        />
        <Input
          ref={ref}
          keyboard={passwordType === 'existing' ? 'existingPassword' : 'newPassword'}
          testID={testID}
          disabled={disabled}
          autoFocus={autoFocus}
          name={name}
          value={value}
          onFocus={() => setFocused(true)}
          onChange={(val) => onChange(val)}
          onBlur={() => {
            onBlur();
            setFocused(false);
          }}
          placeholder={placeholder}
          error={!focused ? error : undefined}
          fullWidth
          secure
          sub={
            requirements !== false && (
              <animated.div style={heightStyle}>
                <animated.div style={opacityStyle}>
                  <Requirement
                    description="8 or more characters"
                    isValid={pwValidation.testLength(value)}
                  />
                  <Requirement
                    description="Capital letter"
                    isValid={pwValidation.testUpperCase(value)}
                  />
                  <Requirement
                    description="Lowercase letter"
                    isValid={pwValidation.testLowerCase(value)}
                  />
                  <Requirement description="Number" isValid={pwValidation.testNumber(value)} />
                  <Requirement
                    description="Symbol/special character"
                    isValid={pwValidation.testSymbol(value)}
                  />
                </animated.div>
              </animated.div>
            )
          }
        />
      </>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';
export default PasswordInput;
