import gql from 'graphql-tag';

export interface StateReferenceQueryData {
  reference: {
    stateHealthReference: {
      url: string;
      name: string;
      oeEndTime: Date;
    };
  };
}

export interface StateReferenceQueryVars {
  state: string;
}

export const StateReferenceQuery = gql`
  query StateHealthReference($state: String!) {
    reference {
      stateHealthReference(state: $state) {
        url
        name
        oeEndTime
      }
    }
  }
`;
