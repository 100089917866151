import React, { useMemo } from 'react';
import { DisplayComponent } from '@types';
import Section from '@layouts/Section';
import Stack from '@layouts/Stack';
import PolicyRow, { Policy } from './PolicyRow';
import PolicyCard from './PolicyCard';

interface PoliciesListProps {
  policies?: Array<Policy>;
  onPress?: (policy: Policy) => void;
}

const statusWeights = {
  // potentially active
  ACTIVE: 2,
  PENDING_START_DATE: 2,
  PENDING: 2,
  INITIAL_ENROLLMENT: 2,
  IMPORT_PENDING: 2,
  ACTIVE_PENDING_PAYMENT: 2,
  // not active
  TERMINATED: 1,
  CANCELLED: 1,
  EXPIRED: 1,
};

// only display if policies exist
export const shouldDisplay = ({ policies }: PoliciesListProps) => {
  return !!(policies && policies.length > 0);
};

// policies should be ordered by premium
export const sortPolicies = ({ policies }: PoliciesListProps) => {
  // sort health above dental; then by premiums (highest to lowest effective)
  return policies
    ? [...policies].sort((a, b) =>
        statusWeights[b.policyStatus] > statusWeights[a.policyStatus]
          ? 1
          : a.coverageYear < b.coverageYear
          ? 1
          : a.coverageYear > b.coverageYear
          ? -1
          : a.productType < b.productType
          ? 1
          : a.productType > b.productType
          ? -1
          : a.premiumEffective < b.premiumEffective
          ? 1
          : -1,
      )
    : [];
};

/**
 * PoliciesList
 * Renders a list of rows for each policy
 */
const PoliciesList: DisplayComponent<PoliciesListProps> = ({ policies, onPress }) => {
  const sortedPolicies = useMemo(() => sortPolicies({ policies }), [policies]);

  const groupedByYear = useMemo(() => {
    const policiesByYear = sortedPolicies.reduce((group, policy) => {
      if (!policy.coverageYear) return group;
      group[policy.coverageYear] = group[policy.coverageYear] ?? [];
      group[policy.coverageYear].push(policy);
      return group;
    }, {});

    const years = Object.keys(policiesByYear).sort((a, b) => (a > b ? -1 : 1));

    return years.map((year) => ({
      year,
      policies: policiesByYear[year],
    }));
  }, [sortedPolicies]);

  if (!shouldDisplay({ policies })) return null;

  return (
    <>
      {groupedByYear.map((group) => (
        <Section key={group.year} title={group.year}>
          <Stack spacing="1">
            {group.policies.map((policy, idx) => {
              if (/TERMINATED|CANCELLED/.test(policy?.policyStatus)) {
                return <PolicyRow key={policy.id} policy={policy} />;
              }

              return (
                <PolicyCard
                  key={policy.id}
                  testID={`policy-${idx}`}
                  onPress={onPress ? () => onPress(policy) : undefined}
                  badgeType="POLICY_STATUS"
                  footerType="DATES"
                  policyStatus={policy.policyStatus}
                  type={policy.productType}
                  carrier={policy.carrier || policy.providerPlan?.issuer?.name}
                  plan={policy.planName}
                  metalLevel={policy.providerPlan?.metalLevel}
                  premium={policy.premiumEffective}
                  strikeoutPremium={policy.premium}
                  startDate={policy.startDate}
                  endDate={policy.endDate}
                />
              );
            })}
          </Stack>
        </Section>
      ))}
    </>
  );
};

PoliciesList.shouldDisplay = shouldDisplay;
export default PoliciesList;
export type { Policy } from './PolicyRow';
