enum State {
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  ID = 'ID',
  KY = 'KY',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MN = 'MN',
  NV = 'NV',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  PA = 'PA',
  RI = 'RI',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
}

export const STATE_BASED_EXCHANGES = ['MA', 'NV', 'NM', 'NY', 'RI', 'VT'];

interface Instructions {
  concierge?: boolean;
  steps: Array<{
    title: string;
    subtitle?: string;
  }>;
}

export const STATE_INSTRUCTIONS: Record<State, Instructions> = {
  CA: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.add-broker',
        subtitle: 'handoff.CA-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.broker-plan',
      },
    ],
  },
  CO: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.CO-add-broker',
        subtitle: 'handoff.CO-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.agent-plan',
      },
    ],
  },
  CT: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.CT-add-broker',
        subtitle: 'handoff.CT-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.agent-plan',
      },
    ],
  },
  DC: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.add-broker',
        subtitle: 'handoff.DC-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.broker-plan',
      },
    ],
  },
  ID: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.ID-add-agent',
        subtitle: 'handoff.ID-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.agent-plan',
      },
    ],
  },
  KY: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.KY-add-agent',
        subtitle: 'handoff.KY-todo',
      },
      {
        title: 'handoff.complete-application',
        subtitle: 'handoff.KY-confirm',
      },
    ],
  },
  ME: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.plan-details',
      },
      {
        title: 'handoff.send-email',
        subtitle: 'handoff.email-todo',
      },
    ],
  },
  MD: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.MD-add-broker',
        subtitle: 'handoff.MD-todo',
      },
      {
        title: 'handoff.complete-application',
        subtitle: 'handoff.agent-plan',
      },
    ],
  },
  MA: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.plan-details',
      },
      {
        title: 'handoff.send-email',
        subtitle: 'handoff.email-todo',
      },
    ],
  },
  MN: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.MN-add-assistor',
        subtitle: 'handoff.MN-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.MN-confirm',
      },
    ],
  },
  NV: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.plan-details',
      },
      {
        title: 'handoff.send-email',
        subtitle: 'handoff.email-todo',
      },
    ],
  },
  NJ: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.NJ-add-agent',
        subtitle: 'handoff.NJ-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.agent-plan',
      },
    ],
  },
  NM: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.plan-details',
      },
      {
        title: 'handoff.send-email',
        subtitle: 'handoff.email-todo',
      },
    ],
  },
  NY: {
    concierge: true,
    steps: [
      {
        title: 'handoff.NY-create',
        subtitle: 'handoff.NY-create-details',
      },
      {
        title: 'handoff.NY-login',
        subtitle: 'handoff.NY-login-details',
      },
      {
        title: 'handoff.add-broker',
        subtitle: 'handoff.NY-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.broker-plan',
      },
    ],
  },
  PA: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.PA-add-agent',
        subtitle: 'handoff.PA-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.agent-plan',
      },
    ],
  },
  RI: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.plan-details',
      },
      {
        title: 'handoff.send-email',
        subtitle: 'handoff.email-todo',
      },
    ],
  },
  VT: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.plan-details',
      },
      {
        title: 'handoff.send-email',
        subtitle: 'handoff.email-todo',
      },
    ],
  },
  VA: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.plan-details',
      },
      {
        title: 'handoff.send-email',
        subtitle: 'handoff.email-todo',
      },
    ],
  },
  WA: {
    concierge: true,
    steps: [
      {
        title: 'handoff.create-account',
      },
      {
        title: 'handoff.WA-add-broker',
        subtitle: 'handoff.WA-todo',
      },
      {
        title: 'handoff.apply-and-enroll',
        subtitle: 'handoff.plan-details',
      },
    ],
  },
};
