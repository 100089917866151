import React, { useMemo } from 'react';

import { Stack, BasicLayout, Toolbar } from '@layouts';
import {
  useDeprecatedQuery,
  useDeprecatedMutation,
  useQuery,
  ApplicationIssuesQuery,
  getApplicationIssues,
  GET_HOME,
  GET_COVERAGE,
} from '@data';
import {
  useCopy,
  safeFormatDate,
  handleHealthErrorsWithProps,
  formatList,
  capitalize,
} from '@app/utils';
import { navigate, pop } from '@navigate';
import { EnrollingMembersRow } from '@common';
import { useEnrollmentGroups } from '@hooks';
import { BaseRow, Text, Button } from '@uikit';
import { PolicyCard } from '@app/partial';
import { Route } from '@types';

const Confirmation = ({ applicationID }) => {
  const { c } = useCopy('catch.ede.Confirmation');

  const { enrollingMembers, notEnrollingMembers, splitMembers } = useDeprecatedQuery(
    'membersEligibility',
    {
      variables: { applicationID },
      skip: !applicationID,
      fetchPolicy: 'network-only',
    },
  );

  const hasNonEnrollingMembers = notEnrollingMembers?.length > 0 || splitMembers?.length > 0;

  const { enrollmentGroups, loading } = useEnrollmentGroups({ applicationID });

  const selectedPlans = useMemo(() => {
    return enrollmentGroups.map((group) => {
      const plan = group?.sortedPlans?.plans?.find(
        (plan) => plan.healthPlan.planID === group?.selectedProviderPlanID,
      );

      // apply the premium + premium effective directly from enrollment group
      return {
        ...group,
        selectedPlan: {
          ...plan,
          healthPlan: {
            ...plan.healthPlan,
            premium: group.planPremium,
            premiumWithCredit: group.planPremiumEffective,
          },
        },
      };
    });
  }, [enrollmentGroups]);

  const handleNext = () => navigate(Route.EDE_SUCCESS);

  const { data } = useQuery(ApplicationIssuesQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { healthDocs, asyncStatus } = useMemo(() => {
    return {
      healthDocs: getApplicationIssues(data?.viewerTwo?.health?.application || undefined)
        .healthDocs,
      asyncStatus: data?.viewerTwo?.health?.application?.asyncStatus,
    };
  }, [data]);

  const [submitEnrollment, { loading: submitting }] = useDeprecatedMutation('submitEnrollment', {
    onCompleted: handleNext,
    ...handleHealthErrorsWithProps({ skipHandoff: true }),
    refetchQueries: [GET_COVERAGE, GET_HOME],
  });

  return (
    <BasicLayout
      loading={loading || submitting}
      title={c('title')}
      toolbar={
        <Toolbar onBack={pop}>
          <Button
            inherit
            onPress={() => submitEnrollment({ variables: { applicationID } })}
            disabled={loading || submitting}
          >
            {c('nextButton')}
          </Button>
        </Toolbar>
      }
    >
      <Stack spacing="1">
        {selectedPlans?.map((g) => {
          return (
            <PolicyCard
              key={g.id}
              type={g.enrollmentType}
              plan={g?.selectedPlan?.healthPlan?.title}
              carrier={g?.selectedPlan?.healthPlan?.issuer?.title}
              metalLevel={g?.selectedPlan?.healthPlan?.metal}
              planType={g?.selectedPlan?.healthPlan?.type}
              premium={g?.planPremiumEffective}
              strikeoutPremium={g?.planPremium}
              familyDeductible={g?.selectedPlan?.healthPlan?.familyDeductible}
              individualDeductible={g?.selectedPlan?.healthPlan?.individualDeductible}
              hsaEligible={g?.selectedPlan?.healthPlan?.hsaEligible}
              annotations={[
                {
                  id: g.id,
                  title: `${capitalize(
                    g?.enrollmentType?.replace('_INSURANCE', ''),
                  )} coverage for ${formatList(g?.members, (m) => m?.givenName)}`,
                  scheme: 'positive',
                },
              ]}
            />
          );
        })}

        {hasNonEnrollingMembers && (
          <Stack spacing="1">
            <EnrollingMembersRow
              noSpace={false}
              noBorder={false}
              label={c('enrollingNow')}
              members={enrollingMembers}
            />
            {splitMembers?.length > 0 && (
              <EnrollingMembersRow
                noSpace={false}
                noBorder={false}
                label="Medicaid/CHIP"
                members={splitMembers}
              />
            )}
            {notEnrollingMembers?.length > 0 && (
              <EnrollingMembersRow
                noSpace={false}
                noBorder={false}
                label={c('notCovered')}
                members={notEnrollingMembers}
                color="subtle"
                bg="grayBg"
              />
            )}
          </Stack>
        )}
      </Stack>

      <Stack group spacing="+gap">
        {asyncStatus !== 'NOT_APPLICABLE' && (
          <BaseRow
            testID="async-status"
            content={<Text size="fp">{c(`async.${asyncStatus}`)}</Text>}
          />
        )}
        {healthDocs?.map((doc, i) => {
          return (
            <BaseRow
              key={doc?.id}
              testID={doc?.id}
              content={
                <Text size="fp">
                  Additional documents required by{' '}
                  {safeFormatDate(new Date(doc?.resolveBy), 'MMM d')}
                </Text>
              }
            />
          );
        })}
      </Stack>
    </BasicLayout>
  );
};

export const ConfirmationView = {
  name: Route.EDE_ENROLL,
  component: Confirmation,
  options: {
    title: 'Enrollment',
  },
};
