import { DocFileType } from '@app/data';
import { Accept } from 'react-dropzone';

type MimeType =
  | 'image/bmp'
  | 'image/gif'
  | 'image/heic'
  | 'image/heif'
  | 'image/jpeg'
  | 'image/png'
  | 'image/pdf'
  | 'image/tiff'
  | 'application/pdf';

type FileExtension =
  | '.bmp'
  | '.gif'
  | '.heic'
  | '.heif'
  | '.jpeg'
  | '.jpg'
  | '.png'
  | '.pdf'
  | '.tiff'
  | '.tif';

const acceptanceMapping: Record<DocFileType, { mime: MimeType; ext: Array<FileExtension> }> = {
  [DocFileType.Bmp]: {
    mime: 'image/bmp',
    ext: ['.bmp'],
  },
  [DocFileType.Gif]: {
    mime: 'image/gif',
    ext: ['.gif'],
  },
  [DocFileType.Heic]: {
    mime: 'image/heic',
    ext: ['.heic'],
  },
  [DocFileType.Heif]: {
    mime: 'image/heif',
    ext: ['.heif'],
  },
  [DocFileType.Jpeg]: {
    mime: 'image/jpeg',
    ext: ['.jpeg'],
  },
  [DocFileType.Jpg]: {
    mime: 'image/jpeg',
    ext: ['.jpg'],
  },
  [DocFileType.Png]: {
    mime: 'image/png',
    ext: ['.png'],
  },
  [DocFileType.Pdf]: {
    mime: 'application/pdf',
    ext: ['.pdf'],
  },
  [DocFileType.Tiff]: {
    mime: 'image/tiff',
    ext: ['.tiff', '.tif'],
  },
};

export const generateAcceptedFiles = (types: Array<DocFileType>): Accept => {
  return types.reduce((acc, type) => {
    const { mime, ext } = acceptanceMapping[type];
    const existing = acc[mime] || [];

    return {
      ...acc,
      [mime]: [...existing, ...ext],
    };
  }, {});
};
