import { useState, useCallback } from 'react';

/**
 * The "new" API uses an object rather than array
 */
export const useHover = (disabled, { useNewObjectApi } = {}) => {
  const [isHovered, setHover] = useState(false);
  const [isPressed, setPressed] = useState(false);
  const [showHover, setShowHover] = useState(true);
  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);
  const handleMouseDown = useCallback(() => {
    setPressed(true);
  }, []);
  const handleMouseUp = useCallback(() => {
    setPressed(false);
  }, []);
  const handleGrant = useCallback(() => {
    setShowHover(false);
  }, []);
  const handleRelease = useCallback(() => {
    setShowHover(true);
  }, []);

  return useNewObjectApi
    ? {
        isHovered: isHovered && showHover && !disabled,
        isPressed: isPressed && showHover && !disabled,
        handlers: disabled
          ? {}
          : {
              onMouseEnter: handleMouseEnter,
              onMouseLeave: handleMouseLeave,
              onMouseDown: handleMouseDown,
              onMouseUp: handleMouseUp,
            },
      }
    : [
        isHovered && showHover && !disabled,
        disabled
          ? {}
          : {
              onMouseEnter: handleMouseEnter,
              onMouseLeave: handleMouseLeave,
              onMouseDown: handleMouseDown,
              onMouseUp: handleMouseUp,
            },

        disabled
          ? {}
          : {
              // prevent hover showing while responder
              onResponderGrant: handleGrant,
              onResponderRelease: handleRelease,
            },
        disabled
          ? {}
          : {
              // if child is Touchable
              onPressIn: handleGrant,
              onPressOut: handleRelease,
            },
      ];
};
