import React from 'react';
import { useCopy } from '@app/utils';
import { navigate } from '@navigate';
import { nextCICRoute } from '@navigate/CICNavigationGuards';
import { BasicFormBlueprint } from '@app/blueprints';

/**
 * collect ssn in the context so we have it on hand when creating
 * a new application
 * @todo check whether cic comes down
 */
const CICSSNEntry = ({ cic }) => {
  const { c } = useCopy(`catch.ede.CIC.ssnEntry`);

  const onSubmit = (values) => {
    navigate(`${nextCICRoute({ hasSSN: !!values.ssn }, { cic })}`);
  };

  return (
    <BasicFormBlueprint
      title={c(`title`)}
      subtitle={c(`subtitle`)}
      formConfig={{
        initialValues: {},
        fields: [{ name: 'ssn', type: 'ssn', required: true }],
        onSubmit,
      }}
    />
  );
};
export const CICSSNEntryView = {
  name: 'CIC_SSN_ENTRY',
  component: CICSSNEntry,
  options: {},
};
