import { gql } from '../generated';

gql(`
  fragment DocUpload on DocumentMetadata {
    id
    url
    documentType
    name @client 
    createdOn
  }
`);

export const CreatePresignedUrl = gql(`
  mutation CreatePresignedUrl($input: CreatePresignedUrlInput!) {
    createPresignedUrl(input: $input) {
      url
    }
  }
`);

export const UploadAvatar = gql(`
  mutation UploadAvatar($input: UploadAvatarRequest!) {
    uploadAvatar(input: $input)
  }
`);

export const UploadDocument = gql(`
  mutation UploadDocument($input: DocumentMetadataInput!) {
    uploadDocument(input: $input) {
      id
      createdOn
      ext
      documentType
      filePath
    }
  }
`);

export const DocumentUploads = gql(`
  query DocumentUploads {
    viewerTwo {
      id
      documentUploads {
        ...DocUpload
      }
    }
  }
`);
