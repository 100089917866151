import { gql } from '../generated';

gql(`
  fragment OEDates on OpenEnrollmentDates {
    windowShoppingOpenTime
    startTime
    endTime
    startDate
    endDate
    isWindowShopping @client
    isOpenEnrollment @client
    coverageYears @client
    sepCoverageYear @client
    oeCoverageYear
    oeYear @client
    sepYear @client
    nextOEYear @client
    lastOEYear @client
  }
`);
