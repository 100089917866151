import React from 'react';
import { SheetBlueprint } from '@blueprints';
import { Link } from '@app/_ui-kit';
import { sheets, useSheet } from '@app/navigate';
import { useCopy } from '@app/utils';

const MissingHealthSSNSheet = ({ onContinue, closeSheet }) => {
  const { c } = useCopy('catch.ede.HealthEnrollment');
  const { open } = useSheet();

  return (
    <SheetBlueprint
      title={c('35_L')}
      subtitle={c('35_Q', {
        link: (
          <Link
            testID="important-link"
            onPress={() => open(sheets.HELP_TEXT, { subtitle: c('35_T') })}
          >
            important
          </Link>
        ),
      })}
      actions={[
        {
          type: 'CUSTOM',
          label: 'Continue without SSN',
          onAction: () => {
            onContinue();
            closeSheet();
          },
        },
        {
          type: 'CUSTOM',
          label: 'Add info',
          onAction: closeSheet,
        },
      ]}
    />
  );
};

export default MissingHealthSSNSheet;
