import React, { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import type { Action } from '@types';
import { useSheet, SheetName } from '@navigate';
import { CatchTheme, Loading } from '@uikit';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { useDebug } from '@app/hooks/useDebug';
import { Layout } from './Layout';
import Stack from './Stack';
import Page from './Page';
import FlowHeader from './FlowHeader';
import HeaderGradient from './HeaderGradient';
import { Split, Container } from './GridPreset';

interface RootLayoutProps {
  title: string;
  subtitle: string;
  color: string;
  content: any;
  controls: any;
  extra: any;
  loading: boolean;
  refreshing: boolean;
  full: boolean;
  dynamicContentHeight?: boolean;
  menuItems?: Array<Action>;
  onRefresh: () => void;
  sheetConfig?: {
    name: SheetName;
    data?: object;
  };
}

/**
 *
 */
const RootLayout: React.FC<RootLayoutProps> = ({
  title,
  subtitle,
  color,
  content,
  controls,
  extra,
  children,
  loading,
  menuItems,
  refreshing,
  onRefresh,
  sheetConfig,
  full,
  dynamicContentHeight = false,
}) => {
  const { showLayout } = useDebug();
  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);
  const onMore = useMemo(() => {
    if (!sheetConfig) return undefined;
    return () => openSheet(sheetConfig.name, sheetConfig.data);
  }, [openSheet, sheetConfig]);

  const { isMobile } = useResponsive();
  const Cont = full ? Stack : Split;

  const Top = (
    <Stack spacing="1">
      <Stack
        flexItem={0}
        spacing="2"
        style={{
          height:
            !!content && !!controls && !dynamicContentHeight
              ? CatchTheme.constants.rootHeaderHeight
              : 'auto',
          border: showLayout ? '1px solid blue' : 'none',
        }}
      >
        {!!content && (
          <Layout debuggable style={{ flex: 1 }}>
            {content}
          </Layout>
        )}
        {!!controls && <Layout debuggable>{controls}</Layout>}
      </Stack>
      {!!extra && <Layout debuggable>{extra}</Layout>}
    </Stack>
  );

  return (
    <Page color={color}>
      <Layout.Scroll refreshing={refreshing} onRefresh={onRefresh} bottomSpace>
        <HeaderGradient color={color}>
          <Layout margins debuggable>
            {!!title && (
              <Layout debuggable>
                <FlowHeader
                  root
                  title={title}
                  loading={loading}
                  subtitle={subtitle}
                  menuItems={menuItems}
                  onMore={onMore}
                />
              </Layout>
            )}
            {!!isMobile && (!!content || !!controls || !!extra) && (
              <Layout debuggable topSpace>
                <Container>{Top}</Container>
              </Layout>
            )}
          </Layout>
        </HeaderGradient>
        <Layout>
          <Container>
            <Cont sticky>
              {!isMobile && (!!content || !!controls || !!extra) ? Top : <></>}
              {loading ? <Loading /> : children}
            </Cont>
          </Container>
        </Layout>
      </Layout.Scroll>
    </Page>
  );
};

export default RootLayout;
