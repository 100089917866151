import React from 'react';
import { CSRLevel } from '@types';
import { EligibilityMember } from '@data';
import { Stack } from '@layouts';
import { Link } from '@uikit';
import { getFullName, useCopy } from '@app/utils';
import { useSheet, sheets } from '@app/navigate';
import PeopleList from '@common/components/PeopleList';
import EligibilityItem from './EligibilityItem';
import Dollar from '@svg/dollar-2.svg';
import Check from '@svg/checkmark.svg';
import X from '@svg/close.svg';
import Plus from '@svg/plus.svg';
import { shallow } from 'zustand/shallow';

type EligibilityBucket =
  | 'MARKETPLACE_ELIGIBLE'
  | 'NOT_ELIGIBLE_SEP'
  | 'MEDICAID'
  | 'CHIP'
  | 'NOT_ELIGIBLE';

interface EligibilityResult extends EligibilityMember {
  results: {
    eligibility: EligibilityBucket;
    hasAPTC: boolean;
    aptcAmount?: number;
    hasCSR: boolean;
    csrLevel?: Omit<CSRLevel, 'NONE'>;
    hasPlanRestriction: boolean;
  };
}

interface EligibilityGroup {
  type: EligibilityBucket;
  members: Array<EligibilityResult>;
}

interface EligibilityGroupListProps {
  applicationID: string;
  groups?: Array<EligibilityGroup>;
  determinationType?: 'ASSESSMENT' | 'DETERMINATION';
  coverageYear?: number;
  appliedAPTC?: number;
  possibleAPTC?: number;
}

const EligibilityGroupList = ({
  applicationID,
  groups,
  determinationType,
  coverageYear,
  appliedAPTC = 0,
  possibleAPTC = 0,
}: EligibilityGroupListProps) => {
  const { c, $ } = useCopy('catch.ede.EligibilityResults');
  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);

  return (
    <Stack spacing="3" separatorComponent>
      {groups?.map((group) => (
        <Stack key={group.type} testID={group.type} spacing="1">
          <Stack>
            <PeopleList
              members={group?.members}
              sublabel={(m) =>
                m.results.hasPlanRestriction ? 'Must enroll in existing plan' : undefined
              }
            />
          </Stack>

          <Stack>
            <EligibilityItem
              icon={/NOT_ELIGIBLE/.test(group.type) ? X : Check}
              title={c(`${group.type}.banner`) || group.type}
              description={c(`${group.type}.description`, {
                determinationType,
                coverageYear,
                count: group.members.length,
              })}
            />

            {/* only show APTC for the marketplace eligible group IF theres an aptc amount  */}
            {group.type === 'MARKETPLACE_ELIGIBLE' && possibleAPTC > 0 && (
              <EligibilityItem
                icon={Dollar}
                title={c('monthlySavingsTitle')}
                description={c('monthlySavingsText', {
                  amount: $(possibleAPTC),
                  link: (
                    <Link
                      testID="adjust-aptc"
                      onPress={() =>
                        openSheet(sheets.APPLIED_APTC, {
                          applicationID,
                          appliedAmount: appliedAPTC,
                          maxAmount: possibleAPTC,
                        })
                      }
                    >
                      {c('monthlySavingsApply', { appliedAmount: $(appliedAPTC) })}
                    </Link>
                  ),
                })}
              />
            )}

            {group.csr && (
              <EligibilityItem
                icon={Plus}
                title={c('addlSavingsTitle')}
                description={
                  group?.csr.isSubset
                    ? c('addlSavingsDescriptionGroup', {
                        people: getFullName(group?.csr?.members),
                        count: group?.csr?.members?.length,
                      })
                    : c('addlSavingsDescription')
                }
              />
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default EligibilityGroupList;
