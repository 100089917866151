import React, { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { Segment, SegmentEvent, triToBool, useCopy } from '@app/utils';
import {
  useQuery,
  HealthApplicationQuery,
  useMutation,
  UpsertHealthApplication,
  UpsertConversionStatus,
  ConversionStatusType,
  ConversionStatusEnum,
  HealthPathwayType,
} from '@data';
import { useSheet, sheets, navigate } from '@navigate';
import { SplitFormBlueprint } from '@app/blueprints';
import { Route } from '@types';
import { getInitialValues, formatPayload, FormValues } from './screeningUtils';
import { fields } from './screeningFields';

const Screening = ({ applicationID, coverageYear, pathway, householdRevisit }) => {
  const { open } = useSheet((state) => ({ open: state.open }), shallow);
  const { c } = useCopy('catch.ede');

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(() => getInitialValues(data), [data?.viewerTwo.health.application]);

  const [upsert, { loading: upserting }] = useMutation(UpsertHealthApplication);
  const [upsertConversion] = useMutation(UpsertConversionStatus);

  const onSubmit = (values: FormValues) => {
    const input = formatPayload(values, applicationID);
    upsert({ variables: { input } });

    Segment.track(SegmentEvent.APPLICATION_FINANCIAL_ASSISTANCE_ANSWERED, {
      financial_assistance: triToBool(values.isRequestingFinancialAssistance),
      qualifies_for_financial_help: !!values.qualifiesForFinancialHelp,
      household_income: values.householdIncome,
    });

    Segment.track(SegmentEvent.SCREENING_QUESTIONS_PASSED, {
      coverage_year: coverageYear,
    });

    upsertConversion({
      variables: {
        input: {
          year: coverageYear,
          type: ConversionStatusType.Health,
          status: ConversionStatusEnum.AppStarted,
        },
      },
    });

    navigate(householdRevisit ? Route.EDE_MEMBER_INFO : Route.EDE_PRO_ASSISTANCE);
  };

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={upserting}
      title={c('Screening.title', { householdRevisit: !!householdRevisit })}
      subtitle={c('Screening.subtitle', { householdRevisit: !!householdRevisit })}
      formConfig={{
        onError: (fields, errors, values) => {
          const failedScreeners = fields.filter((f) => {
            const errorType = errors[f].type;
            return f !== 'agreesToFullPrice' && errorType !== 'required';
          });

          if (failedScreeners.length > 0) {
            if (pathway === HealthPathwayType.StateApp) {
              onSubmit(values);
            } else {
              open(sheets.HEALTH_SCREENED_OUT, {
                handoffUrl: data?.viewerTwo?.health?.application?.handoffUrl,
                failedQuestion: fields?.[0],
                coverageYear,
              });
            }
          }
        },
        initialValues,
        fields,
        onSubmit,
      }}
    />
  );
};

export const ScreeningView = {
  name: 'EDE_SCREENING',
  component: Screening,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Background info',
  },
};
