import React from 'react';
import { Button } from '@uikit';
import { BasicLayout, Toolbar } from '@layouts';
import { pop } from '@navigate';
import { useForm, Fields } from '@app/forms';
import { createLogger } from '@app/utils';
import { useMutation, mutations, updates } from '@data';
import { forms } from '@app/config';
import { Route } from '@types';

const Log = createLogger('health-link');

/**
 * PrivacyAgreements
 * (CMS Privacy Agreements)
 */
const PrivacyAgreements = ({ handleNext }) => {
  const [agree, { loading: agreeing }] = useMutation(mutations.HEALTH_INITIAL_AGREE, {
    update: updates.UPDATE_AGREEMENTS,
    onCompleted: (res) => {
      Log.debug('Health agreements complete.');
      handleNext();
    },
    variables: {
      input: {
        isPrivacyDataUseAgree: 'YES',
        isPrivacyTruthfulAgree: 'YES',
        isPrivacyAuthorizeAgree: 'YES',
      },
    },
  });

  const form = useForm<{
    isPrivacyDataUseAgree: boolean;
    isPrivacyTruthfulAgree: boolean;
    isPrivacyAuthorizeAgree: boolean;
  }>({
    loading: false,
    disabled: agreeing,
    initialValues: {},
    fields: forms.CMS_PRIVACY,
    onSubmit: () => {
      agree();
    },
  });

  return (
    <BasicLayout
      title="Privacy Agreements"
      toolbar={
        <Toolbar onBack={pop}>
          <Button inherit testID="continue" disabled={form.disableSubmit} onPress={form.submitForm}>
            Continue
          </Button>
        </Toolbar>
      }
    >
      <Fields form={form} fields={forms.CMS_PRIVACY} />
    </BasicLayout>
  );
};

export const HealthLinkAgreementsView = {
  name: Route.HEALTH_LINK_AGREEMENTS,
  component: PrivacyAgreements,
  options: {},
};
