import React from 'react';
import { ActionType, Route } from '@types';
import { Layout, Stack } from '@layouts';
import { HiccupBlueprint } from '@blueprints';
import { FinePrint, SimpleRow } from '@uikit';
import { useCopy } from '@app/utils';

import UserSvg from '@svg/user.svg';
import CalendarSvg from '@svg/calendar.svg';
import HomeSvg from '@svg/home.svg';
import WallpaperSvg from '@svg/wallpaper.svg';
import ContractSvg from '@svg/contract.svg';

export const BankingSetupIntro = ({ handleNext }) => {
  const { c } = useCopy('catch');

  const items = [
    { id: 'name', svg: UserSvg, label: c('user.LegalNameField.label') },
    { id: 'dob', svg: CalendarSvg, label: c('user.DobField.label') },
    { id: 'address', svg: HomeSvg, label: c('user.LegalAddressField.label') },
    { id: 'ssn', svg: WallpaperSvg, label: c('user.SSNField.label') },
    { id: 'agreements', svg: ContractSvg, label: c('util.LegalInfoModal.sectionTitle') },
  ];

  const actions = [
    {
      testID: 'continue',
      type: 'CUSTOM' as ActionType,
      onAction: handleNext,
      label: c('basics.next'),
    },
  ];

  return (
    <HiccupBlueprint
      asset={{ render: 'pay', gradient: undefined }}
      title={c('banking.intro.title')}
      subtitles={[c('banking.intro.subtitle')]}
      actions={actions}
    >
      <Stack separatorComponent border>
        {items.map((item) => (
          <SimpleRow
            key={item.id}
            testID={item.id}
            inset
            asset={{
              svg: item.svg,
              bg: 'bg',
              size: 'xs',
              shape: 'circle',
            }}
            label={item.label}
          />
        ))}
      </Stack>
      <Layout center margins>
        <FinePrint items={[c('banking.intro.disclosure')]} />
      </Layout>
    </HiccupBlueprint>
  );
};

export const BankingSetupIntroView = {
  name: Route.BANKING_SETUP_INTRO,
  component: BankingSetupIntro,
  options: {},
};
