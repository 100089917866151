import React from 'react';
import { Asset } from '@uikit/components/Asset';
import Help from '@svg/help.svg';
import { handleAction } from '../utils/handleAction';

const HelpButton = () => {
  return <Asset svg={Help} size="xs" onPress={() => handleAction({ type: 'HELP' })} color="text" />;
};

export default HelpButton;
