import React from 'react';
import { Copy, BackgroundColor, TextColor } from '@types';
import { Stack, useLayoutContext } from '../../layouts';
import { ComplexRow } from './Row';

export interface ExplainerBullet {
  id: string;
  glyph?: any;
  svg?: any;
  text?: string; // displays bullet with text, e.g. numbered bullets
  label: Copy;
  sublabel?: Copy;
  bg?: BackgroundColor;
  color?: TextColor;
}

interface Props {
  bullets?: Array<ExplainerBullet>;
}

export const Explainer: React.FC<Props> = ({ bullets = [] }) => {
  const { accentColor } = useLayoutContext();

  return (
    <Stack>
      {bullets?.map((explainer, idx) => {
        const assetProps = !!explainer.text
          ? {
              text: explainer.text,
              bg: accentColor || explainer.bg,
              textSize: 'p',
              color: explainer.color || 'snow',
              shape: 'squircle',
            }
          : {
              icon: explainer.glyph,
              svg: explainer.svg,
              color: explainer.color || 'text',
              bg: explainer.bg || 'border',
              shape: 'circle',
            };
        return (
          <ComplexRow
            light
            key={explainer.id || idx}
            testID={explainer.id}
            label={explainer.label}
            sublabel={explainer.sublabel}
            asset={{
              size: 'xs',
              containerSize: 'sm',
              ...assetProps,
            }}
          />
        );
      })}
    </Stack>
  );
};

export default Explainer;
