import constants from './constants';

export const getSviDocTypes = (sviType) => {
  return constants.sviDocsByCategory[sviType].map((e) => ({ value: e }));
};

export const getDmiDocTypes = (verificationType, subType) => {
  let docs;
  if (/NON_ESC_MEC/.test(subType)) {
    docs = constants.dmiDocsByCategory[subType.substring(12)];
  } else {
    docs = verificationType
      ? constants.dmiTypesToCategory[verificationType]
      : constants.dmiDocsByCategory[subType];
  }
  if (Array.isArray(docs)) {
    return docs.map((k) => ({ value: k }));
  }
  return docs;
};

export const getDmiDocSubTypes = (verificationType) => {
  return constants.subDocumentTypes[verificationType];
};
