import React from 'react';
import { stacks, Stack, StackComponent } from '@navigate';
import { LoginView } from './SignInView';
import { RegisterView } from './RegisterView';
import { ConsentView } from './ConsentView';

const config = {
  stackName: stacks.AUTH_STACK,
  options: {},
  screens: [ConsentView, RegisterView, LoginView],
};

const AuthStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

AuthStack.config = config;
export default AuthStack;
