import {
  DmiDocumentType,
  DmiVerificationType,
  SviDocumentType,
  SviStatusCode,
} from '@app/data/generated/graphql';

/**
 * @deprecated
 * These constants have been moved to the @util health folder
 */
const binary = [
  {
    id: 'yes',
    value: 'YES',
  },
  {
    id: 'no',
    value: 'NO',
  },
];

const proAssistanceBinary = [
  {
    value: 'YES',
    id: 'PRO_ASSISTANCE_YES',
  },
  {
    value: 'NO',
    id: 'PRO_ASSISTANCE_NO',
  },
];

const immigrationDocumentTypes = {
  CERTIFICATE_OF_NATURALIZATION: 'CERTIFICATE_OF_NATURALIZATION',
  CERTIFICATE_OF_CITIZENSHIP: 'CERTIFICATE_OF_CITIZENSHIP',
  NONE_OF_THESE: 'NONE_OF_THESE',
};

const lawfulPresenceDocumentTypes = {
  PERMANENT_RESIDENCE_CARD: [
    'PERMANENT_RESIDENT_CARD_I_551',
    'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A',
    'REENTRY_PERMIT',
  ],
  ARRIVAL_DEPARTURE_RECORD: [
    'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94',
    'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
  ],
  STUDENT_EXCHANGE_RECORD: [
    'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019',
    'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20',
  ],
  MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE:
    'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
  EMPLOYMENT_AUTHORIZATION_CARD_I_766: 'EMPLOYMENT_AUTHORIZATION_CARD_I_766',
  REFUGEE_TRAVEL_DOCUMENT_I_571: 'REFUGEE_TRAVEL_DOCUMENT_I_571',
  NOTICE_OF_ACTION_I_797: 'NOTICE_OF_ACTION_I_797',
  FOREIGN_PASSPORT: 'FOREIGN_PASSPORT',
  NONE_OF_THESE: 'NONE_OF_THESE',
};

const proAssistantTypes = [
  {
    value: 'NAVIGATOR',
  },
  {
    value: 'CERTIFIED_APPLICATION_COUNSELOR',
  },
  {
    value: 'AGENT_OR_BROKER',
  },
  {
    value: 'OTHER_ASSISTOR',
  },
];

const applicationAssistorTypes = {
  NAVIGATOR: 'ASSISTOR_TYPE_NAVIGATOR',
  CERTIFIED_APPLICATION_COUNSELOR: 'ASSISTOR_TYPE_CERTIFIED_APPLICATION_COUNSELOR',
  AGENT_OR_BROKER: 'ASSISTOR_TYPE_AGENT_BROKER',
  OTHER_ASSISTOR: 'ASSISTOR_TYPE_IN_PERSON_ASSISTANT_PERSONNEL',
};

const languages = [
  {
    value: 'ENGLISH',
    label: 'English',
  },
  {
    value: 'SPANISH',
    label: 'Espanol',
  },
  {
    value: 'VIETNAMESE',
    label: 'Vietnamese',
  },
  {
    value: 'TAGALOG',
    label: 'Tagalog',
  },
  {
    value: 'RUSSIAN',
    label: 'Russian',
  },
  {
    value: 'PORTUGUESE',
    label: 'Portuguese',
  },
  {
    value: 'ARABIC',
    label: 'Arabic',
  },
  {
    value: 'CHINESE',
    label: 'Chinese',
  },
  {
    value: 'FRENCH',
    label: 'French',
  },
  {
    value: 'FRENCH_CREOLE',
    label: 'French Creole',
  },
  {
    value: 'GERMAN',
    label: 'German',
  },
  {
    value: 'GUJARATI',
    label: 'Gujarati',
  },
  {
    value: 'HINDI',
    label: 'Hindi',
  },
  {
    value: 'KOREAN',
    label: 'Korean',
  },
  {
    value: 'POLISH',
    label: 'Polish',
  },
  {
    value: 'URDU',
    label: 'Urdu',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];
const races = [
  {
    label: 'American Indian or Alaska Native',
    value: 'AMERICAN_INDIAN_OR_ALASKAN_NATIVE',
  },
  { label: 'Asian Indian', value: 'ASIAN_INDIAN' },
  { label: 'Black or African American', value: 'BLACK_OR_AFRICAN_AMERICAN' },
  { label: 'Chinese', value: 'CHINESE' },
  { label: 'Filipino', value: 'FILIPINO' },
  { label: 'Guamanian or Chamorro', value: 'GUAMANIAN_OR_CHAMORRO' },
  { label: 'Japanese ', value: 'JAPANESE' },
  { label: 'Korean', value: 'KOREAN' },
  { label: 'Native Hawaiian', value: 'NATIVE_HAWAIIAN' },
  { label: 'Samoan', value: 'SAMOAN' },
  { label: 'Vietnamese ', value: 'VIETNAMESE' },
  { label: 'Caucasian ', value: 'WHITE' },
  { label: 'An Asian race not listed above', value: 'OTHER_ASIAN' },
  {
    label: 'A Pacific Islander race not listed above',
    value: 'OTHER_PACIFIC_ISLANDER',
  },
  { label: 'Another race not listed here', value: 'OTHER' },
];
const specificOrigins = [
  { label: 'Cuban', value: 'CUBAN' },
  { label: 'Mexican, Mexican American, or Chicano/a', value: 'MEXICAN' },
  { label: 'Puerto Rican', value: 'PUERTO_RICAN' },
  { label: 'Other:', value: 'OTHER' },
];
const sex = [
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
];
const maritalStatus = [
  { label: 'Single', value: 'SINGLE' },
  { label: 'Married', value: 'MARRIED' },
];

const incomeTypes = [
  { label: 'Job (like salary, wages, commissions, or tips)', value: 'JOB' },
  {
    label: 'Self-employment (like your own business, consulting, or freelance work)',
    value: 'SELF_EMPLOYMENT',
  },
  { label: 'Farming or fishing', value: 'FARMING_OR_FISHING_INCOME' },
  { label: 'Unemployment', value: 'UNEMPLOYMENT' },
  {
    label: 'Social Security benefits (retirement and disability)',
    value: 'SOCIAL_SECURITY_BENEFIT',
  },
  {
    label: 'Retirement (like IRA and 401(k) withdrawals)',
    value: 'RETIREMENT',
  },
  { label: 'Pension benefits', value: 'PENSION' },
  {
    label: 'Investment (including interest and dividend income)',
    value: 'INVESTMENT_INCOME',
  },
  { label: 'Capital gains', value: 'CAPITAL_GAINS' },
  { label: 'Rental or royalty', value: 'RENTAL_OR_ROYALTY_INCOME' },
  { label: 'Alimony received', value: 'ALIMONY_RECEIVED' },
  { label: 'Scholarship', value: 'SCHOLARSHIP' },
  { label: 'Canceled debt', value: 'CANCELED_DEBT' },
  { label: 'Court awards', value: 'COURT_AWARDS' },
  {
    label: 'Gambling, prizes, or awards',
    value: 'PRIZES_AWARDS_GAMBLING_WINNINGS',
  },
  { label: 'Jury duty pay', value: 'JURY_DUTY_PAY' },
  { label: 'Other income', value: 'OTHER_INCOME' },
];

const deductionTypes = [
  { label: 'Alimony', value: 'ALIMONY_PAYMENT', id: 'ALIMONY_PAYMENT' },
  {
    label: 'Student loan interest',
    value: 'STUDENT_LOAN_INTEREST',
    id: 'STUDENT_LOAN_INTEREST',
  },
  {
    label: 'Other deduction',
    value: 'OTHER_DEDUCTION',
    id: 'OTHER_DEDUCTION_4',
  },
];

const frequencyTypes = [
  { value: 'WEEKLY', id: 'WEEKLY' },
  { value: 'BI_WEEKLY', id: 'BI_WEEKLY' },
  { value: 'MONTHLY', id: 'MONTHLY' },
  { value: 'QUARTERLY', id: 'QUARTERLY' },
  { value: 'ANNUALLY', id: 'ANNUALLY' },
  { value: 'SEMI_MONTHLY', id: 'SEMI_MONTHLY' },
  { value: 'ONE_TIME', id: 'ONE_TIME' },
];

// only hourly/daily frequency options are for JOB income type
const jobFrequencyTypes = [
  ...frequencyTypes,
  { value: 'DAILY', id: 'DAILY' },
  { value: 'HOURLY', id: 'HOURLY' },
];

const medicaidCHIPNames = {
  AL: {
    state: 'Alabama',
    medicaid: 'Alabama Medicaid',
    chip: 'Alabama ALL Kids (CHIP)',
  },
  AK: {
    state: 'Alaska',
    medicaid: 'Alaska Medicaid',
    chip: 'Denali KidCare (CHIP)',
  },
  AZ: {
    state: 'Arizona',
    medicaid: 'Arizona Health Care Cost Containment System (AHCCS) (Medicaid)',
    chip: 'AHCCCS-KidsCare (CHIP)',
  },
  AR: {
    state: 'Arkansas',
    medicaid: 'Arkansas Medicaid',
    chip: 'ARKids B and Unborn Child (CHIP)',
  },
  CA: {
    state: 'California',
    medicaid: 'Medi-Cal (Medicaid)',
    chip: 'Medi-Cal (CHIP)',
  },
  CO: {
    state: 'Colorado',
    medicaid: 'Health First Colorado (Medicaid)',
    chip: 'Health First Colorado or CHP+ (CHIP)',
  },
  CT: {
    state: 'Connecticut',
    medicaid: 'Connecticut Medicaid',
    chip: 'HUSKY Program )CHIP)',
  },
  DE: {
    state: 'Delaware',
    medicaid: 'Delaware Medicaid',
    chip: 'Delaware Healthy Children Program (CHIP)',
  },
  DC: {
    state: 'District Of Columbia',
    medicaid: 'DC Medicaid',
    chip: 'Healthy Families (CHIP)',
  },
  FL: {
    state: 'Florida',
    medicaid: 'Florida Medicaid',
    chip: 'Florida KidCare (CHIP)',
  },
  GA: {
    state: 'Georgia',
    medicaid: 'Georgia Medical Assistance',
    chip: 'PeachCare for Kids®',
  },
  HI: {
    state: 'Hawaii',
    medicaid: 'Med Quest (Medicaid)',
    chip: 'Med Quest (CHIP)',
  },
  ID: {
    state: 'Idaho',
    medicaid: 'Medicaid',
    chip: 'Idaho CHIP',
  },
  IL: {
    state: 'Illinois',
    medicaid: 'Illinois Medicaid',
    chip: 'Illinois All Kids (CHIP)',
  },
  IN: {
    state: 'Indiana',
    medicaid: 'Indiana Medicaid',
    chip: 'Indiana Children’s Health Insurance Plan (CHIP)',
  },
  IA: {
    state: 'Iowa',
    medicaid: 'Iowa Medicaid',
    chip: 'Hawk-I (CHIP)',
  },
  KS: {
    state: 'Kansas',
    medicaid: 'KanCare program (Medicaid)',
    chip: 'KanCare program (CHIP)',
  },
  KY: {
    state: 'Kentucky',
    medicaid: 'Kentucky Medicaid',
    chip: 'Kentucky Children’s Health Insurance Program (KCHIP)',
  },
  LA: {
    state: 'Louisiana',
    medicaid: 'Healthy Louisiana (Medicaid)',
    chip: 'Louisiana Children’s Health Insurance Program (LaCHIP)',
  },
  ME: {
    state: 'Maine',
    medicaid: 'MaineCare (Medicaid)',
    chip: 'CubCare (CHIP)',
  },
  MD: {
    state: 'Maryland',
    medicaid: 'Maryland Medicaid',
    chip: 'Maryland Children’s Health Program (MCHIP)',
  },
  MA: {
    state: 'Massachusetts',
    medicaid: 'MassHealth (Medicaid)',
    chip: '	MassHealth (CHIP)',
  },
  MI: {
    state: 'Michigan',
    medicaid: 'Michigan Department of Health and Human Service (Medicaid)',
    chip: 'Michigan MIChild (CHIP)',
  },
  MN: {
    state: 'Minnesota',
    medicaid: 'Minnesota Medical Assistance (Medicaid)',
    chip: 'Minnesota Medical Assistance (CHIP)',
  },
  MS: {
    state: 'Mississippi',
    medicaid: 'Mississippi Medicaid',
    chip: 'Mississippi Children’s Health Insurance Program (CHIP)',
  },
  MO: {
    state: 'Missouri',
    medicaid: 'MO HealthNet (Medicaid)',
    chip: 'Missouri Children’s Health Insurance Program (CHIP)',
  },
  MT: {
    state: 'Montana',
    medicaid: 'Montana Medicaid',
    chip: 'Healthy Montana Kids (CHIP)',
  },
  NE: {
    state: 'Nebraska',
    medicaid: 'Nebraska Medicaid',
    chip: 'Nebraska CHIP',
  },
  NV: {
    state: 'Nevada',
    medicaid: 'Nevada Medicaid',
    chip: 'Nevada Check Up (CHIP)',
  },
  NH: {
    state: 'New Hampshire',
    medicaid: 'New Hampshire Medicaid',
    chip: 'New Hampshire CHIP',
  },
  NJ: {
    state: 'New Jersey',
    medicaid: 'New Jersey FamilyCar (Medicaid)',
    chip: '	New Jersey FamilyCare (CHIP)',
  },
  NM: {
    state: 'New Mexico',
    medicaid: 'New Mexico Medicaid',
    chip: 'New Mexi-Kids (CHIP)',
  },
  NY: {
    state: 'New York',
    medicaid: 'New York Medicaid',
    chip: 'Child Health Plus (CHIP)',
  },
  NC: {
    state: 'North Carolina',
    medicaid: 'North Carolina Medicaid',
    chip: 'North Carolina Health Choice (CHIP)',
  },
  ND: {
    state: 'North Dakota',
    medicaid: 'North Dakota Medicaid',
    chip: '	Healthy Steps (CHIP)',
  },
  OH: {
    state: 'Ohio',
    medicaid: 'Ohio Medicaid',
    chip: 'Healthy Start (CHIP)',
  },
  OK: {
    state: 'Oklahoma',
    medicaid: 'SoonerCare (Medicaid)',
    chip: 'SoonerCare (CHIP)',
  },
  OR: {
    state: 'Oregon',
    medicaid: 'Oregon Health Plan (Medicaid)',
    chip: 'Oregon Health Plan (CHIP)',
  },
  PA: {
    state: 'Pennsylvania',
    medicaid: 'Pennsylvania Medical Assistance (MA) (Medicaid)',
    chip: 'Pennsylvania CHIP',
  },
  RI: {
    state: 'Rhode Island',
    medicaid: 'Rhode Island Medical Assistance (Medicaid)',
    chip: 'Rite Care (CHIP)',
  },
  SC: {
    state: 'South Carolina',
    medicaid: 'Healthy Connections (Medicaid)',
    chip: 'Healthy Connections Children (CHIP)',
  },
  SD: {
    state: 'South Dakota',
    medicaid: 'South Dakota Medicaid',
    chip: 'South Dakota CHIP Program',
  },
  TN: {
    state: 'Tennessee',
    medicaid: 'TennCare (Medicaid)',
    chip: 'CoverKids (CHIP)',
  },
  TX: {
    state: 'Texas',
    medicaid: 'Texas Medicaid',
    chip: 'CHIP',
  },
  UT: {
    state: 'Utah',
    medicaid: 'Utah Medicaid',
    chip: 'CHIP',
  },
  VT: {
    state: 'Vermont',
    medicaid: 'Vermont Medicaid',
    chip: 'Dr Dynasaur (CHIP)',
  },
  VA: {
    state: 'Virginia',
    medicaid: 'Virginia Medicaid',
    chip: 'Family Access to Medical Insurance Security (FAMIS) (CHIP)',
  },
  WA: {
    state: 'Washington',
    medicaid: 'Apple Health (Medicaid)',
    chip: 'Apple Health for Kids (CHIP)',
  },
  WV: {
    state: 'West Virginia',
    medicaid: 'West Virginia Medicaid',
    chip: 'West Virginia Children’s Health Insurance Program (WVCHIP)',
  },
  WI: {
    state: 'Wisconsin',
    medicaid: 'BadgerCare Plus (Medicaid)',
    chip: 'BadgerCare Plus (CHIP)',
  },
  WY: {
    state: 'Wyoming',
    medicaid: 'Wyoming Medicaid',
    chip: 'Kid Care CHIP',
  },
};

// The state medicaid and chips will be merged dynamically
const coverageTypes = [
  {
    label: 'Individual insurance (including Marketplace or other non-group coverage)',
    value: 'MARKETPLACE',
  },
  { label: 'Medicaid', value: 'STATE_MEDICAID' },
  { label: 'Children’s Health Insurance Program (CHIP)', value: 'STATE_CHIP' },
  { label: 'Medicare', value: 'MEDICARE' },
  {
    label: 'TRICARE (Don’t check if this person has Direct Care or Line of Duty)',
    value: 'TRICARE',
  },
  { label: 'Veterans Affairs (VA) Health Care Program', value: 'VA' },
  { label: 'Peace Corps', value: 'PEACE_CORPS' },
  { label: 'Other full benefit coverage', value: 'OTHER_FULL_BENEFIT' },
  {
    label: 'Other limited benefit coverage (like a school accident policy)',
    value: 'OTHER_LIMITED_BENEFIT',
  },
];

const roles = [
  { label: 'I am applying for coverage', value: 'YES', id: 'IS_APPLYING' },
  {
    label: 'I am preparing this application for someone else',
    value: 'NO',
    id: 'IS_NOT_APPLYING',
  },
];

const phoneTypes = [
  { label: 'Mobile', value: 'MOBILE' },
  { label: 'Home', value: 'HOME' },
  { label: 'Office', value: 'OFFICE' },
  // { label: 'Other', value: 'OTHER' }, = other is not allowed on cms and breaks shit
];

const contactMethods = [
  { id: 'EMAIL', label: 'Digital', value: 'EMAIL' },
  { id: 'E_TEXT', label: 'Paper', value: 'MAIL' },
];

const mailingAddressOptions = [
  { label: 'Same as home address', value: true, id: 'SAME_AS_HOME' },
  { label: 'Different address', value: false, id: 'DIFFERENT_ADDRESS' },
];

const renewalYears = [
  { value: 0, id: 'RENEW_YEAR' },
  { value: 1, id: 'RENEW_YEAR' },
  { value: 2, id: 'RENEW_YEAR' },
  { value: 3, id: 'RENEW_YEAR' },
  { value: 4, id: 'RENEW_YEAR' },
  { value: 5, id: 'RENEW_YEAR' },
];

/**
 * These document types are sorted based on
 * https://www.healthcare.gov/help/how-do-i-resolve-an-inconsistency/
 */
const dmiDocsByCategory = {
  CITIZENSHIP: [
    DmiDocumentType.Passport,
    DmiDocumentType.CertificateOfNaturalization,
    DmiDocumentType.CertificateOfCitizenship,
    DmiDocumentType.DriverSLicense,
    DmiDocumentType.DocumentFromAFederallyRecognizedIndianTribe,
    DmiDocumentType.Other,
  ],
  IMMIGRATION_STATUS: [
    DmiDocumentType.PermanentResidentGreenCard,
    DmiDocumentType.ReentryPermit,
    DmiDocumentType.RefugeeTravelDocument,
    DmiDocumentType.EmploymentAuthorizationCard,
    DmiDocumentType.MachineReadableImmigrantVisa,
    DmiDocumentType.TemporaryI_551StampOnPassportOrI_94I_94A,
    DmiDocumentType.ArrivalDepartureRecord,
    DmiDocumentType.ArrivalDepartureRecordInForeignPassport,
    DmiDocumentType.Other,
  ],
  HOUSEHOLD_INCOME: [
    DmiDocumentType.Form_1040TaxReturn,
    DmiDocumentType.W2AndOr_1099S,
    DmiDocumentType.PayStubDocumentation,
    DmiDocumentType.SocialSecurityStatements,
    DmiDocumentType.UnemploymentBenefitsLetter,
    DmiDocumentType.SelfEmploymentDocumentation,
    DmiDocumentType.Other,
  ],
  INCARCERATION: [
    DmiDocumentType.OfficialReleasePapers,
    DmiDocumentType.DocumentShowingEmploymentProofOfResidence,
    DmiDocumentType.SignedNotarizedAffidavit,
    DmiDocumentType.Other,
  ],
  NATIVE: [
    DmiDocumentType.TribalEnrollmentMembershipCard,
    DmiDocumentType.DocumentIssuedByBia,
    DmiDocumentType.AuthenticDocumentFromATribeDeclaringMembership,
    DmiDocumentType.CertificateOfDegreeOfIndianBlood,
    DmiDocumentType.CertificateOfIndianStatusCard,
    DmiDocumentType.FormI_872AmericanIndianCard,
    DmiDocumentType.USAmericanIndianAlaskaNativeTribalEnrollmentOrShareholderDocumentation,
    DmiDocumentType.LetterFromTheMarketplaceGrantingATribalExemption,
  ],
  MEC: [
    DmiDocumentType.LetterFromHealthInsurer,
    DmiDocumentType.StatementOfHealthBenefits,
    DmiDocumentType.Other,
  ],
  ESCMEC: [
    // letter from employer???
    DmiDocumentType.StatementOfHealthBenefits,
    DmiDocumentType.Other,
  ],
  MEDICAID_CHIP: [
    DmiDocumentType.LetterFromMedicaid,
    DmiDocumentType.LetterFromChip,
    DmiDocumentType.Other,
  ],
  TRICARE: [DmiDocumentType.LetterFromTricare, DmiDocumentType.Other],
  VA: [DmiDocumentType.LetterFromVa, DmiDocumentType.Other],
  MEDICARE: [DmiDocumentType.LetterFromMedicare, DmiDocumentType.Other],
  PEACE_CORPS: [DmiDocumentType.LetterFromPeaceCorps, DmiDocumentType.Other],
  SSN: [
    DmiDocumentType.SocialSecurityCard,
    DmiDocumentType.TaxFormS,
    DmiDocumentType.BenefitOrIncomeStatement,
    DmiDocumentType.Other,
  ],
};

const toObject = (k) => ({ value: k });

const subDocumentTypes = {
  CITIZENSHIP: {
    DOCUMENT_FROM_A_FEDERALLY_RECOGNIZED_INDIAN_TRIBE: [
      DmiDocumentType.TribalEnrollmentMembershipCard,
      DmiDocumentType.CertificateOfDegreeOfIndianBlood,
      DmiDocumentType.NativeAmericanTribalDocument,
      DmiDocumentType.LetterFromTheMarketplaceGrantingATribalExemption,
    ].map(toObject),
  },
  SSN: {
    // `-` suffixes are for reusing the same enum with different copy
    // we remove it before saving
    TAX_FORM_S: ['TAX_FORM_S-W4', 'TAX_FORM_S-1095', 'FORM_1040_TAX_RETURN', 'W2_AND_OR_1099S'].map(
      toObject,
    ),
    BENEFIT_OR_INCOME_STATEMENT: [
      DmiDocumentType.SocialSecurityStatements,
      DmiDocumentType.PayStubs,
      DmiDocumentType.UnemploymentBenefitsLetter,
    ].map(toObject),
    OTHER: [
      'U_S_MILITARY_CARD_OR_DRAFT_RECORD-RECORD',
      'U_S_MILITARY_CARD_OR_DRAFT_RECORD-CARD',
      'MILITARY_DEPENDENT_S_IDENTIFICATION_CARD',
      DmiDocumentType.CourtOrder,
      DmiDocumentType.DivorceDecree,
    ].map(toObject),
  },
};

/**
 * Here we map dmiTypes to document categories
 */
const dmiTypesToCategory = {
  INCARCERATION: dmiDocsByCategory.INCARCERATION,
  TRIBE_MEMBERSHIP: dmiDocsByCategory.NATIVE,
  NONESCMEC: dmiDocsByCategory.MEC,
  ANNUAL_INCOME: dmiDocsByCategory.HOUSEHOLD_INCOME,
  CITIZENSHIP: dmiDocsByCategory.CITIZENSHIP,
  QHP_LAWFUL_PRESENCE: dmiDocsByCategory.IMMIGRATION_STATUS,
  MEDICAID_LAWFUL_PRESENCE: dmiDocsByCategory.IMMIGRATION_STATUS,
  ESCMEC: dmiDocsByCategory.ESCMEC,
  SSN: dmiDocsByCategory.SSN,
  IMMIGRATION_STATUS: dmiDocsByCategory.IMMIGRATION_STATUS,
};

/**
 * DMI notice types
 */

const dmiNoticeTypes = {
  CLOCK_EXPIRATION_NOTICE: 'CLOCK_EXPIRATION_NOTICE',
};

/**
 * sviTypes are already cleanly mapped to categories
 */
const sviDocsByCategory = {
  MOVED_TO_NEW_SERVICE_AREA: [
    SviDocumentType.BillsFinancialStatementAtNewAddress,
    SviDocumentType.USPostalServiceChangeOfAddressLetter,
    SviDocumentType.MortgageRentalDocumentAtNewAddress,
    SviDocumentType.LetterFromAGovernmentOrganization,
    SviDocumentType.DocumentFromAnEmployer,
    SviDocumentType.HealthCareProgramDocument,
    SviDocumentType.LetterOfExplanation,
    SviDocumentType.ArrivalDepartureRecordI_94I_94A,
    SviDocumentType.Passport,
    SviDocumentType.LetterFromAnInsuranceCompany,
    SviDocumentType.Other,
  ],
  MEC_LOSS: [
    SviDocumentType.LetterFromAnInsuranceCompany,
    SviDocumentType.LetterFromAnEmployer,
    SviDocumentType.LetterAboutCobraCoverage,
    SviDocumentType.HealthCareProgramDocument,
    SviDocumentType.PayStub,
    SviDocumentType.LetterForLossOfStudentCoverage,
    SviDocumentType.DocumentForCoverageLossDueToDivorceSeparation,
    SviDocumentType.DocumentForCoverageLossDueToDeath,
    SviDocumentType.LetterOfExplanation,
    SviDocumentType.Other,
  ],
  ADOPTION: [
    SviDocumentType.AdoptionLetterOrRecord,
    SviDocumentType.FosterCarePapers,
    SviDocumentType.CourtOrder,
    SviDocumentType.LegalGuardianshipDocument,
    SviDocumentType.MedicalSupportOrder,
    SviDocumentType.DhsImmigrationDocument,
    SviDocumentType.LetterOfExplanation,
    SviDocumentType.Other,
  ],
  MARRIAGE: [
    SviDocumentType.MarriageCert,
    SviDocumentType.MarriageLicense,
    SviDocumentType.OfficialPublicRecord,
    SviDocumentType.Affidavit,
    SviDocumentType.ReligiousDocument,
    SviDocumentType.LetterOfExplanation,
    SviDocumentType.Other,
  ],
  SEP_GRANTED_FOR_MDCAID_DENIAL: [
    SviDocumentType.DenialLetterFromYourState,
    SviDocumentType.LetterStatingLostCoverage,
    SviDocumentType.MarketplaceLetter,
    SviDocumentType.ScreenshotOfResults,
    SviDocumentType.LetterOfExplanation,
    SviDocumentType.Other,
  ],
};

const taxIncomeDiffReasons = [
  {
    id: 'INCOME_DIFF_REASON_HOUSEHOLD_MEMBER_CHANGE',
    value: 'HOUSEHOLD_MEMBER_CHANGE',
  },
  {
    id: 'INCOME_DIFF_REASON_SELF_EMPLOYMENT',
    value: 'SELF_EMPLOYMENT_INCOME_FLUCTUATION',
  },
  { id: 'INCOME_DIFF_REASON_JOB_CHANGE', value: 'JOB_CHANGE' },
  {
    id: 'INCOME_DIFF_REASON_WORK_LOCATION_CHANGE',
    value: 'WORK_LOCATION_CHANGE',
  },
  { id: 'INCOME_DIFF_REASON_OTHER', value: 'OTHER' },
];

const taxIncomeLowerReasons = [
  {
    id: 'INCOME_DIFF_REASON_HOUSEHOLD_MEMBER_CHANGE',
    value: 'HOUSEHOLD_MEMBER_CHANGE',
  },
  {
    id: 'INCOME_DIFF_REASON_SELF_EMPLOYMENT',
    value: 'SELF_EMPLOYMENT_INCOME_FLUCTUATION',
  },
  { id: 'INCOME_DIFF_REASON_JOB_CHANGE_LOWER', value: 'JOB_CHANGE' },
  { id: 'INCOME_DIFF_REASON_OTHER', value: 'OTHER' },
];

const employmentIncomeDiffReasons = [
  { value: 'STOP_WORKING', id: 'EMPLOYER_INCOME_DIFF_REASON_STOP_WORKING' },
  { value: 'DECREASE_HOURS', id: 'EMPLOYER_INCOME_DIFF_REASON_DECREASE_HOURS' },
  { value: 'CHANGE_JOBS', id: 'EMPLOYER_INCOME_DIFF_REASON_CHANGE_JOBS' },
  { value: 'WAGE_CUT', id: 'EMPLOYER_INCOME_DIFF_REASON_WAGE_CUT' },
  { value: 'OTHER', id: 'EMPLOYER_INCOME_DIFF_REASON_OTHER' },
];

const coverageEndReasons = [
  { value: 'INSURANCE_TOO_EXPENSIVE' },
  { value: 'PARENT_NO_LONGER_WORKS_FOR_THE_COMPANY' },
  { value: 'COMPANY_STOPPED_OFFERING_INSURANCE' },
  { value: 'COMPANY_STOPPED_OFFERING_INSURANCE_TO_DEPENDENTS' },
  { value: 'MEDICAL_NEEDS_NOT_COVERED' },
  { value: 'OTHER' },
];

const helpUrls: Record<DmiVerificationType | SviStatusCode, string> = {
  INCARCERATION: 'https://help.catch.co/en/articles/3383248-incarceration-status',
  TRIBE_MEMBERSHIP:
    'https://help.catch.co/en/articles/3383253-american-indian-or-alaska-native-status',
  NONESCMEC: 'https://help.catch.co/en/articles/3383261-no-minimal-essential-job-based-coverage',
  ANNUAL_INCOME: 'https://help.catch.co/en/articles/3383245-household-income',
  CITIZENSHIP: 'https://help.catch.co/en/articles/3383197-citizenship',
  QHP_LAWFUL_PRESENCE: 'https://help.catch.co/en/articles/3383237-immigration-status',
  MEDICAID_LAWFUL_PRESENCE:
    'https://help.catch.co/en/articles/3383264-no-coverage-through-medicaid-or-the-children-s-health-insurance-program-chip',
  ESCMEC: 'https://help.catch.co/en/articles/3383321-losing-qualifying-health-coverage',
  SSN: 'https://help.catch.co/en/articles/3383278-social-security-number-ssn',
  MOVED_TO_NEW_SERVICE_AREA:
    'https://help.catch.co/en/articles/3383313-permanently-moving-to-a-new-home-and-had-prior-coverage',
  MEC_LOSS: 'https://help.catch.co/en/articles/3383321-losing-qualifying-health-coverage',
  ADOPTION:
    'https://help.catch.co/en/articles/3383326-adopting-a-child-foster-care-placement-or-court-order',
  MARRIAGE: 'https://help.catch.co/en/articles/3383325-getting-married',
  SEP_GRANTED_FOR_MDCAID_DENIAL:
    'https://help.catch.co/en/articles/3383331-medicaid-or-chip-denial',
};

const constants = {
  languages,
  races,
  specificOrigins,
  sex,
  roles,
  binary,
  phoneTypes,
  maritalStatus,
  incomeTypes,
  deductionTypes,
  frequencyTypes,
  jobFrequencyTypes,
  medicaidCHIPNames,
  coverageTypes,
  contactMethods,
  mailingAddressOptions,
  dmiDocsByCategory,
  dmiNoticeTypes,
  sviDocsByCategory,
  dmiTypesToCategory,
  subDocumentTypes,
  renewalYears,
  taxIncomeDiffReasons,
  taxIncomeLowerReasons,
  employmentIncomeDiffReasons,
  coverageEndReasons,
  proAssistanceBinary,
  proAssistantTypes,
  helpUrls,

  applicationAssistorTypes,
  immigrationDocumentTypes,
  lawfulPresenceDocumentTypes,
};
export default constants;
