import React from 'react';
import { Button } from '@uikit';
import { StateReferenceQueryData, StateReferenceQueryVars, useQuery } from '@data';
import { Toolbar, CalloutLayout } from '@layouts';
import { useCopy, STATES } from '@app/utils';
import { navigate } from '@navigate';
import { Route } from '@types';

const PREFIX = 'catch.ede.CIC.stateHandoff';

/**
 *
 * @todo support other assets in Callout
 */
const CICStateHandoff = ({ exchangeState: state }) => {
  const { c } = useCopy(PREFIX);

  const { data: stateData } = useQuery<StateReferenceQueryData, StateReferenceQueryVars>(
    queries.STATE_REFERENCE,
    {
      variables: { state },
      skip: !state,
    },
  );

  const { url, name } = stateData?.reference?.stateHealthReference || {};

  const handleHandoff = () => {
    navigate(Route.CIC_STATE_PICKUP);
  };

  return (
    <CalloutLayout
      title={c('title', { state: STATES[state] })}
      subtitles={[c('subtitle1', { exchange: name }), c('subtitle2')]}
      toolbar={
        <Toolbar>
          <Button testID="shop-state-exchange" href={url} onPress={handleHandoff}>
            {c('button')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const CICStateHandoffView = {
  name: 'CIC_STATE_HANDOFF',
  component: CICStateHandoff,
  options: {},
};
