import React from 'react';
import { Badge, EmptyState, SimpleRow, Text } from '@uikit';
import { Stack } from '@app/layouts';
import { Copy } from '@app/types';

export interface SearchSelectionProps<T extends any> {
  title: Copy;
  empty: Copy;
  icon: any; // svg
  data?: Array<T>;
  getKey: (item: T) => Copy;
  getLabel: (item: T) => Copy;
  getSublabel?: (item: T) => Copy;
  handleRemove: (item: T) => void;
}

export function SearchSelection<T extends any>({
  title,
  empty,
  icon,
  data,
  getKey,
  getLabel,
  getSublabel,
  handleRemove,
}: SearchSelectionProps<T>): React.ReactNode {
  return (
    <Stack>
      <Text size="p" weight="medium">
        {title}
      </Text>
      {!data || data.length === 0 ? (
        <EmptyState title={empty} icon={icon} />
      ) : (
        <Stack spacing="1" topSpace>
          {data.map((item, i) => (
            <SimpleRow
              key={getKey(item)}
              asset={{
                shape: 'round',
                svg: icon,
                bg: 'mediumLight',
                color: 'medium',
                size: 'xs',
                containerSize: 'sm',
              }}
              raised
              inset
              label={getLabel(item)}
              sublabel={getSublabel ? getSublabel(item) : undefined}
              accessory={<Badge label="Remove" onPress={() => handleRemove(item)} />}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
