import gql from 'graphql-tag';

const REVOKE_PERMISSIONS = gql`
  mutation RevokePermissions($applicationID: ID!) {
    revokePermission(id: $applicationID)
  }
`;

export default {
  document: REVOKE_PERMISSIONS,
};
