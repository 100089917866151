import React from 'react';
import styled from 'styled-components';
import Circle from '@svg/circle.svg';
import { IconSvg } from '../Asset';
import Text from '../Text';

export interface RadioProps {
  testID: string;
  label?: string;
  checked: boolean;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string;
  bare?: boolean; // when set, show ONLY the radio (no labels, no card)
  plain?: boolean; // when set, show radio + label (but no card styling)
}

const Label = styled.label<{ $checked: boolean; $option: boolean; $disabled: boolean }>`
  display: flex;
  align-items: center;
  line-height: 24px;

  ${({ theme, $option, $checked, $error }) =>
    $option &&
    `
    background-color: red;
    border: 1.5px solid;
    border-radius: 12px;
    padding: 16px;
    background-color: ${
      $error ? theme.colors.negativeLight : $checked ? theme.colors.bg : theme.colors.bg3
    };
    border-color: ${$error ? theme.colors.negative : $checked ? theme.colors.fg : 'transparent'};
    box-shadow: ${$checked ? '0 2px 6px 0px #0000001A' : ''};
  `}

  ${({ $disabled }) =>
    $disabled
      ? `
    opacity: 0.3;
  `
      : `
    &:hover {
      cursor: pointer;
    }
  `}
`;

const Container = styled.div<{ $disabled: boolean }>`
  display: inline-block;
  vertical-align: middle;
  ${({ $disabled }) => $disabled && 'opacity: 0.5;'}
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledRadio = styled.div<{ $checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 2px solid ${({ theme }) => theme.colors.fg2};
  transition: all 150ms;

  ${({ theme, $checked }) =>
    $checked &&
    `
    background-color: ${theme.colors.bg};
    border-color: ${theme.colors.fg};
  `}

  ${HiddenRadio}:focus + & {
    box-shadow:
      0 0 0 1px ${({ theme }) => theme.colors.bg},
      0 0 0 3px ${({ theme }) => theme.colors.system};
  }

  ${HiddenRadio}:hover:not(:focus) + & {
    background-color: ${({ theme }) => theme.colors.bg3};
    cursor: pointer;
  }

  svg {
    height: 21px;
    width: 21px;
    circle {
      fill: ${({ theme }) => theme.colors.fg};
      stroke: ${({ theme }) => theme.colors.fg};
      stroke-width: 2px;
    }
  }
`;

const Relative = styled.div`
  padding-left: 12px;
`;

export function Radio({
  testID,
  label,
  value,
  checked,
  onChange,
  bare,
  plain,
  error,
  disabled,
}: RadioProps) {
  return (
    <Label
      $checked={checked}
      $disabled={disabled}
      $option={!bare && !plain}
      $error={!!error}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Container>
        <HiddenRadio
          data-testid={testID}
          id={testID}
          checked={checked}
          disabled={disabled}
          onChange={(e) => {
            if (onChange) {
              onChange(value);
            }
          }}
          onKeyPress={({ key }) => {
            if (key === 'Enter' && onChange) {
              onChange(value);
            }
          }}
        />
        <StyledRadio $checked={checked}>
          {checked && <IconSvg color="bg" svgComponent={Circle} />}
        </StyledRadio>
      </Container>
      {!bare && (
        <Relative>
          <Text size="fp" weight="medium" style={{ lineHeight: 24 }}>
            {label}
          </Text>
        </Relative>
      )}
    </Label>
  );
}
