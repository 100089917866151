import createIconComponent from '../../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Colorado = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      d: 'M18.405 48.0865L236.982 47.9219V206.072L18.405 206.128V48.0865Z',
      colorable: true,
      fill: '#1F2533',
    },
  ],
  name: 'Colorado',
  width: '255',
  height: '254',
  viewBox: '0 0 255 254',
});
Colorado.displayName = 'Colorado';

/** @deprecated */
export default Colorado;
