import React from 'react';
import { View } from 'react-native';
import { colors } from '../theme/colors';
import { animation } from '../theme/_constants';
import { useTheme } from '../hooks/useTheme';
import { Pressable } from '..';
import { useLayoutContext } from '@layouts';

const SWITCH_HEIGHT = 20;
const HANDLE_INSET = 2;
const SWITCH_WIDTH = 36;

export const Switch = ({ isOn = false, onToggle, disabled, style, color, headColor, ...rest }) => {
  const { theme, themeColors } = useTheme();
  const context = useLayoutContext();
  return (
    <Pressable handleOnPress={() => (disabled ? null : onToggle(!isOn))} {...rest}>
      <View
        style={[
          styles.track,
          {
            backgroundColor: !!isOn
              ? context?.accentColor
                ? themeColors[context?.accentColor + 'Color']
                : color
                ? themeColors[color + 'Color']
                : themeColors.brandColor
              : themeColors.borderColor,
          },
          !!disabled && styles.disabled,
          style,
        ]}
      >
        <View
          style={[
            styles.handle,
            theme.buttonShadow,
            !!isOn && !!headColor && theme[headColor + 'Bg'],
            !!isOn && styles.handleOn,
          ]}
        ></View>
      </View>
    </Pressable>
  );
};

const styles = {
  track: {
    height: SWITCH_HEIGHT,
    marginTop: -HANDLE_INSET,
    width: SWITCH_WIDTH,
    borderRadius: 999,
    padding: HANDLE_INSET,
    transitionDuration: `${animation.durationShort}ms`,
  },
  handle: {
    backgroundColor: colors.snow,
    height: SWITCH_HEIGHT - HANDLE_INSET * 2,
    width: SWITCH_HEIGHT - HANDLE_INSET * 2,

    borderRadius: 999,
    transitionDuration: `${animation.durationShort}ms`,
    transitionTimingFunction: animation.curve,
    left: 0,
  },
  handleOn: {
    left: SWITCH_WIDTH - SWITCH_HEIGHT,
  },
  disabled: {
    opacity: 0.25,
  },
};

export default Switch;
