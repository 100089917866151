import createIconComponent from '../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Error = createIconComponent({
  content: [
    {
      type: 'title',
      children: 'Error',
    },
    {
      type: 'circle',
      cx: '12',
      cy: '12',
      r: '12',
      fill: '#FEEEED',
    },
    {
      type: 'path',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M12.0045 14.668C11.2681 14.668 10.6711 14.071 10.6711 13.3346L10.6711 6.66797C10.6711 5.93159 11.2681 5.33463 12.0045 5.33463C12.7409 5.33463 13.3378 5.93159 13.3378 6.66797L13.3378 13.3346C13.3378 14.071 12.7409 14.668 12.0045 14.668Z',
      fill: '#AD3C39',
    },
    {
      type: 'circle',
      cx: '12.0045',
      cy: '18.669',
      r: '0.833333',
      fill: '#AD3C39',
      stroke: '#AD3C39',
    },
  ],
  id: '1358%3A20',
  name: 'Error',
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
});
Error.displayName = 'Error';

/** @deprecated */
export default Error;
