import { DMI, HealthDocType, SVI } from '@app/data';
import HealthDocsUploadCard from './HealthDocsUploadCard';
import HealthDocsUploadFooter from './HealthDocsUploadFooter';
import { getDmiDocSubTypes, getDmiDocTypes, getSviDocTypes } from '@app/utils';

export const docUploadfields = (issue: DMI | SVI) => {
  const typeFieldName = `${issue.docType.toLowerCase()}DocumentType`;
  const subTypeFieldName = `${issue.docType.toLowerCase()}DocumentSubType`;

  const docOptions =
    issue.docType === HealthDocType.DMI
      ? getDmiDocTypes(issue.verificationType, issue.subType)
      : getSviDocTypes(issue.statusCode);
  const subDocOptions =
    issue.docType === HealthDocType.DMI ? getDmiDocSubTypes(issue.verificationType) : [];

  return [
    {
      name: 'documents',
      type: 'array',
      required: true,
      grouped: true,
      dependencies: ['issueType', `SUBFIELD:${typeFieldName}`],
      Component: HealthDocsUploadCard,
      Footer: (props) => (
        <HealthDocsUploadFooter {...props} fieldName={typeFieldName} docOptions={docOptions} />
      ),
      subfields: [
        {
          copy: 'catch.ede',
          name: subTypeFieldName,
          type: 'dropdown',
          label: { id: 'HealthEnrollment.264_L' },
          dependencies: [typeFieldName],
          display: (values) => subDocOptions && values[typeFieldName] in subDocOptions,
          options: (values) => {
            return subDocOptions[values[typeFieldName]].map((o) => ({
              label: { id: `enums.${o.value}` },
              value: o.value,
            }));
          },
          required: true,
        },
        {
          name: 'upload',
          type: 'file',
          label: 'Upload your document',
          dependencies: ['documentType', typeFieldName, subTypeFieldName],
          required: true,
          documentType: issue.docType === HealthDocType.DMI ? 'HEALTH_DMI' : 'HEALTH_SVI',
          skipUpload: true,
          filePrefix: (vals) => {
            return vals[subTypeFieldName] || vals[typeFieldName];
          },
        },
      ],
    },
  ];
};
