import React from 'react';
import { TextColor } from '@types';

interface Props {
  icon: any;
  size?: number;
  color?: TextColor;
  width?: boolean;
  height?: boolean;
}

/**
 * @deprecated
 */
const Icon = ({ icon, size, color, width = true, height = true }: Props) => {
  const dimensions: any = {};
  if (!!width) dimensions.width = size;
  if (!!height) dimensions.height = size;

  return React.createElement(icon, {
    ...dimensions,
    color,
  });
};

export default Icon;
