import React from 'react';
import { RootLayout, Stack } from '@layouts';
import { useSearch } from '@hooks';
import { SimpleRow } from '@uikit';
import { replace, ScreenDefinition } from '@navigate';
import { useDebug } from '@app/hooks/useDebug';
import { routeMatch } from './utils';
import { replaceAll } from '@app/utils';

const DevMenuComponent = () => {
  const { search, isSearching } = useSearch({ placeholder: 'Routes' });
  const { showLayout, showTestIds, hideNav, toggle } = useDebug();
  const m = routeMatch(search);

  return (
    // @ts-ignore
    <RootLayout title="Dev" full>
      {/*  @ts-ignore */}
      {!!isSearching ? (
        <Stack separatorComponent>
          {m?.map((rt, i) => (
            <SimpleRow
              key={rt}
              testID={rt}
              onPress={() => replace(rt)}
              label={replaceAll(rt, '_', ' ')}
            />
          ))}
        </Stack>
      ) : (
        <Stack separatorComponent>
          {/*  @ts-ignore */}
          <SimpleRow
            selectedByDefault={showLayout}
            label="Show Layout"
            interaction="switch"
            onPress={() => toggle('showLayout')}
          />
          {/*  @ts-ignore */}
          <SimpleRow
            selectedByDefault={showTestIds}
            label="Show TestIDs"
            interaction="switch"
            onPress={() => toggle('showTestIds')}
          />
          {/*  @ts-ignore */}
          <SimpleRow
            selectedByDefault={hideNav}
            label="Hide Navbar"
            interaction="switch"
            onPress={() => toggle('hideNav')}
          />
        </Stack>
      )}
    </RootLayout>
  );
};

export const DevView: ScreenDefinition = {
  name: 'DEV',
  component: DevMenuComponent,
  options: {
    title: 'Dev',
    largeTitle: true,
  },
};

export default DevView;
