import React, { useRef } from 'react';
import { View } from 'react-native';

import { TextAlign, AssetProps, AccessoryType, Copy } from '@types';

import { Pressable } from './Pressable';
import { Text } from './Text';
import { useTheme } from '../hooks/useTheme';
import { Asset, IconSvg } from './Asset';
import Info from '../icons/Info';
import { Accessory } from './Accessory';
import { themeColorsDark } from '../theme/colors';
import TestID from './TestID';

import Checkmark from '@svg/checkmark.svg';

interface Props {
  testID: string;
  label: Copy;
  description?: string;
  asset?: AssetProps;
  tooltip?: boolean;

  // selection
  onPress: any;
  onHelp?: any;
  onFocus?: () => void;
  disabled?: boolean;
  selected?: boolean;
  sensitive?: boolean;
  showCheckmark?: boolean;

  // for handling grouping of options
  isTop?: boolean;
  isBottom?: boolean;
  isLeft?: boolean;
  isRight?: boolean;

  // style props
  full?: boolean;
  align?: TextAlign;
  accessory?: AccessoryType;
  small?: boolean;
}

/**
 * option
 * a pressable used for selection from a set of options

 * Note: If you are looking for the way to group a set of options,
 * place them in a Stack with the group prop set.  It will automatically
 * apply the isFirst/isLast props to the child options so that the border
 * radiuses are set accordingly.
 */
export const Option = ({
  testID,

  // content of the option
  label,
  description,
  asset,
  tooltip,

  // alignment of content
  full,
  align = 'left',

  // for selection props
  onPress,
  onFocus,
  onHelp,
  disabled,
  selected,
  showCheckmark,
  error,
  accessory,
  small,
}: Props) => {
  const ref = useRef();
  const { theme } = useTheme();
  const textAlign = !!asset ? 'left' : align;

  return (
    <Pressable
      ref={ref}
      testID={testID}
      handleOnPress={onPress}
      onFocus={onFocus}
      disabled={disabled}
      selected={selected}
      hoveredStyle={theme.optionHover}
      style={[
        theme.mdCorners,
        theme.optionButton,
        small && theme.optionButtonSmall,
        full && theme.fullWidth,
        selected && theme.optionSelected,
        selected && error && { borderColor: themeColorsDark.creditColor },
      ]}
    >
      {({ intrx }) => (
        <View style={[theme.row, theme.flex, theme.fullWidth]}>
          <View style={[theme.row, { flex: 1 }]}>
            {asset && <Asset {...asset} containerStyle={[theme.rightGutter2]} />}

            <View
              style={[
                intrx?.pressed && theme.pressedText,
                theme.flex,
                theme.flex1,
                {
                  alignItems: textAlign === 'center' ? 'center' : 'flex-start',
                },
              ]}
            >
              <Text color="text" weight="medium" align={textAlign}>
                <TestID>{testID}</TestID>
                {label}
              </Text>
              {!!description && (
                <Text color="subtle" size="fp" align={textAlign} fluid>
                  {description}
                </Text>
              )}
            </View>
            {showCheckmark && (
              <IconSvg svgComponent={Checkmark} color={selected ? 'text' : 'transparent'} />
            )}

            <Accessory accessory={accessory} />
          </View>

          {!!tooltip && (
            <View style={[theme.leftGutter2]}>
              {/* @ts-ignore */}
              <Info onPress={onHelp} />
            </View>
          )}
        </View>
      )}
    </Pressable>
  );
};

export default Option;
