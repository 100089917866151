import { useState, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import useFetch from 'use-http';
import { Env } from '@app/utils';
import { currentYear } from '@app/utils';

const BASE_URL = 'https://marketplace.api.healthcare.gov/api/v1/';

//marketplace gives an error when the drug searchTerm is only 2 characters (no fetching if only 1 char) so we only fetch if drug searchTerms have 3 or more characters
//but doctors can have last names with 2 letters so we keep that at 2 (no marketplace issues there)
const MIN_DRUG_SEARCH_LEN = 3;
const MIN_DOC_SEARCH_LEN = 2;
const minSearchTermLogic = (type, term) =>
  (type === 'drugs' && term?.length >= MIN_DRUG_SEARCH_LEN) ||
  (type === 'providers' && term?.length >= MIN_DOC_SEARCH_LEN);

const bases = {
  drugs: 'drugs/autocomplete',
  providers: 'providers/search',
};

const format = {
  /**
   * Returns drugs grouped by name/route
   * for example ADDERALL Oral Pill is indexed as ADDERALL-Oral Pill
   * returns : {
   *   groupID: "ADDERAL-Oral Pill"
   *   name:
   * }
   *
   */
  drugs: (data) => {
    if (!data) return [];

    const groupedDrugs = data?.reduce((acc, drug) => {
      const groupID = `${drug?.name}-${drug?.route}`;

      if (groupID in acc) {
        return {
          ...acc,
          [groupID]: {
            ...acc?.[groupID],
            strengths: [...acc?.[groupID]?.strengths, drug],
          },
        };
      } else {
        return {
          ...acc,
          [groupID]: {
            groupID,
            name: drug?.name,
            route: drug?.route,
            strengths: [drug],
          },
        };
      }
    }, {});

    return Object.values(groupedDrugs);
  },
  providers: (data) => {
    if (!data) return [];

    return (data?.providers || []).map(({ provider, address }) => ({
      ...provider,
      address,
    }));
  },
};

// type is either "drugs" or "providers"
export const useMarketplace = ({ type = 'drugs', zip = '02339' }) => {
  // immediately update value
  const [searchTerm, setSearchTerm] = useState(''); // stores immediate value
  const [debounced, setDebounced] = useState(''); // stores debounced value

  const { get, data, loading } = useFetch(
    `${BASE_URL}/${bases[type]}?apikey=${Env.marketplaceKey}&year=${currentYear}&q=${debounced}&zipcode=${zip}`,
  );

  const minSearchTermLength = minSearchTermLogic(type, debounced);

  useEffect(() => {
    if (minSearchTermLength) get();
  }, [debounced]);

  const handleDebounce = useRef(debounce((value) => setDebounced(value), 400));

  const handleSearch = (val: string) => {
    setSearchTerm(val);
    handleDebounce.current(val);
  };

  const clear = () => handleSearch('');

  const results = useMemo(() => {
    return format[type](data);
  }, [data]);

  return {
    searchTerm,
    handleSearch,
    clear,
    data: minSearchTermLength ? data : null,
    results: minSearchTermLength ? results : [],
    loading,
    searching: (!!searchTerm && searchTerm !== debounced) || loading,
  };
};
