import { HealthApplicationQuery, Trinary, UpsertHealthApplicationInput } from '@app/data/types';
import { boolToTri, calcPovertyLine } from '@app/utils';
import { formatCurrency } from '@app/utils/format/currency';

export interface FormValues {
  isRequestingFinancialAssistance?: Trinary;
  areNotSameAddress?: Trinary; // note: flipped value
  isPlanningToFileTax?: Trinary | 'JOINTLY' | 'SEPARATELY';
  isResponsibleForChildNotOnTaxReturn?: Trinary;
  isIndianOrAlaskan?: Trinary;
  isCoveredThroughJobOrCobra?: Trinary;
  isOfferedIchra?: Trinary;
  notClaimingAllDependentsOnTax?: Trinary; // note: flipped value
  areDependentsLivingWithParentNotOnTaxReturn?: Trinary;
  isDependentSingleChild25OrYounger?: Trinary;

  applicationID?: string;
  coverageState?: string | null;
  coverageYear?: number | null;
  maritalStatus?: any; // todo
  householdIncome: number;
  numTaxDependents?: number;
  fpls420?: number;
  qualifiesForFinancialHelp?: boolean;
  numApplicants?: number;
  householdCategory?: 'many' | 'one';
  applicantsCategory?: 'many' | 'two' | 'one';
  premium?: string;
  premiumWithCredit?: string;
  hasSavings: boolean;
}

const flipTrinary = (val?: Trinary) => {
  if (val === Trinary.Yes) return Trinary.No;
  if (val === Trinary.No) return Trinary.Yes;
  return val;
};

// forces a fallback trinary value if not explicitly a trinary already
const forceTrinary = (val: Trinary | string | undefined, fallback: Trinary) => {
  if (val === Trinary.Yes) return Trinary.Yes;
  if (val === Trinary.No) return Trinary.No;
  if (val === Trinary.Nil) return Trinary.Nil;
  if (!!val) return fallback;
  return undefined;
};

export const getInitialValues = (data?: HealthApplicationQuery): FormValues => {
  const householdIncome = data?.viewerTwo?.health?.application?.householdIncome || 0;
  const numTaxDependents = data?.viewerTwo?.health?.application?.numTaxDependents || 0;
  const maritalStatus = data?.viewerTwo?.health?.application?.maritalStatus;
  const coverageYear = data?.viewerTwo?.health?.application?.coverageYearNumber || undefined;
  const state = data?.viewerTwo?.health?.application?.coverageState;

  const { fpls420 } = calcPovertyLine({
    coverageYear,
    numTaxDependents,
    maritalStatus,
    state,
  });

  const numApplicants =
    (data?.viewerTwo?.health?.application?.numDependentsRequestCoverage || 0) +
    (data?.viewerTwo?.health?.application?.isSelfCoverage ? 1 : 0) +
    (data?.viewerTwo?.health?.application?.isSpouseCoverage ? 1 : 0);

  const premium = data?.viewerTwo?.healthExplorerData?.healthPlanDetails?.premium;
  const premiumWithCredit =
    data?.viewerTwo?.healthExplorerData?.healthPlanDetails?.premiumWithCredit;

  const screening = data?.viewerTwo?.health?.application?.screening || {};

  return {
    applicationID: data?.viewerTwo?.health?.application?.id,
    coverageState: data?.viewerTwo?.health?.application?.coverageState,
    coverageYear: data?.viewerTwo?.health?.application?.coverageYearNumber,
    maritalStatus,
    householdIncome,
    numTaxDependents,
    fpls420,
    qualifiesForFinancialHelp: householdIncome < fpls420,
    numApplicants,
    householdCategory: numTaxDependents > 0 || maritalStatus === 'MARRIED' ? 'many' : 'one',
    applicantsCategory: numApplicants > 2 ? 'many' : numApplicants === 2 ? 'two' : 'one',
    dependentsCategory: numTaxDependents > 1 ? 'many' : 'one',
    premium: formatCurrency(premium || 0),
    premiumWithCredit: formatCurrency(premiumWithCredit || 0),
    hasSavings:
      premium !== undefined && premiumWithCredit !== undefined && premiumWithCredit < premium,

    // screening values
    isRequestingFinancialAssistance: boolToTri(screening?.isRequestingFinancialAssistance),
    areNotSameAddress: flipTrinary(boolToTri(screening?.areAllSameAddress)),
    isPlanningToFileTax: boolToTri(screening?.isPlanningToFileTax),
    isResponsibleForChildNotOnTaxReturn: boolToTri(screening?.isResponsibleForChildNotOnTaxReturn),
    isIndianOrAlaskan: boolToTri(screening?.isIndianOrAlaskan),
    isCoveredThroughJobOrCobra: boolToTri(screening?.isCoveredThroughJobOrCobra),
    isOfferedIchra: boolToTri(screening?.isOfferedIchra),
    notClaimingAllDependentsOnTax: flipTrinary(boolToTri(screening?.isClaimingAllDependentsOnTax)),
    areDependentsLivingWithParentNotOnTaxReturn: boolToTri(
      screening?.areDependentsLivingWithParentNotOnTaxReturn,
    ),
    isDependentSingleChild25OrYounger: flipTrinary(
      boolToTri(screening?.isDependentSingleChild25OrYounger),
    ),
  };
};

export const formatPayload = (
  values: FormValues,
  applicationID: string,
): UpsertHealthApplicationInput => {
  return {
    id: applicationID,
    isRequestingFinancialAssistance: values.isRequestingFinancialAssistance,
    areAllSameAddress: flipTrinary(values.areNotSameAddress),
    isPlanningToFileTax: forceTrinary(values.isPlanningToFileTax, Trinary.No),
    isResponsibleForChildNotOnTaxReturn: values.isResponsibleForChildNotOnTaxReturn,
    isIndianOrAlaskan: values.isIndianOrAlaskan,
    isCoveredThroughJobOrCobra: values.isCoveredThroughJobOrCobra,
    isOfferedIchra: values.isOfferedIchra,
    isClaimingAllDependentsOnTax: flipTrinary(values.notClaimingAllDependentsOnTax),
    areDependentsLivingWithParentNotOnTaxReturn: values.areDependentsLivingWithParentNotOnTaxReturn,
    isDependentSingleChild25OrYounger: flipTrinary(values.isDependentSingleChild25OrYounger),
  };
};
