import React, { useEffect } from 'react';
import { useCopy, Segment, SegmentEvent, currentYear } from '@app/utils';
import { useQuery, GET_COVERAGE } from '@data';
import { exit } from '@navigate';
import { HiccupBlueprint } from '@blueprints';
import { Route } from '@types';
import { DEFAULT_COVERAGE_ASSET } from '@app/utils';

/**
 * This page is shown when a user gets to stopping point
 * in the week before
 */
const ExplorerWindowShopping = () => {
  const { c } = useCopy('catch.health.windowShopping');
  const { c: basics } = useCopy('catch.basics');

  const { loading, data: coverageData } = useQuery(GET_COVERAGE, {
    variables: { coverageYears: [currentYear, currentYear + 1] },
  });

  const nextOEYear = coverageData?.reference?.health?.openEnrollmentDates?.oeCoverageYear;

  useEffect(() => {
    Segment.track(SegmentEvent.WINDOW_SHOPPING_COMPLETED, { coverageYear: nextOEYear });
  }, []);

  return (
    <HiccupBlueprint
      loading={loading}
      asset={DEFAULT_COVERAGE_ASSET}
      title={c('title')}
      subtitles={[c('subtitle')]}
      actions={[
        {
          type: 'ROUTE',
          onAction: () => exit(Route.HOME),
          label: basics('soundsGood'),
        },
      ]}
    />
  );
};

export const ExplorerWindowShoppingView = {
  name: Route.EXPLORER_WINDOW_SHOPPING,
  component: ExplorerWindowShopping,
  options: {
    title: 'Window Shopping',
  },
};
