import { BasicFormBlueprint, BasicFormBlueprintProps } from '@app/blueprints';
import { DocumentRequestType } from '@app/data';
import { useLayoutContext } from '@app/layouts';
import { ScreenDefinition } from '@navigate';
import Upload from '@svg/upload.svg';
import { Route } from '@types';

// @todo extend FileInputProps
export interface DocumentUploadField {
  documentType: DocumentRequestType;
  filePrefix: 'DocumentRequest';
  inputKey: 'filePath';
  label: string;
  name: string;
  required: boolean;
  type: 'file';
}

interface DocumentUploadProps extends BasicFormBlueprintProps<any> {
  fields: DocumentUploadField[];
  loading: boolean;
  onSubmit: (values) => void;
}

export const DocumentUpload = ({
  fields = [],
  loading,
  onSubmit,
  title = 'Upload document',
  ...props
}: DocumentUploadProps) => {
  const { accentColor } = useLayoutContext();

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const formConfig = {
    fields,
    initialValues: {},
    loading,
    onSubmit: handleSubmit,
  };

  return (
    <BasicFormBlueprint
      {...props}
      title={title}
      icon={{ gradient: accentColor, svg: Upload }}
      button={fields.length === 1 ? 'Upload document' : 'Upload documents'}
      formConfig={formConfig}
    />
  );
};

export const DocumentUploadView: ScreenDefinition = {
  name: Route.DOCUMENT_UPLOAD,
  component: DocumentUpload,
  options: {},
};
