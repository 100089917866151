import React from 'react';
import styled from 'styled-components';
import { Stack } from '@app/layouts';
import { Copy } from '@app/types';
import Text from '../Text';
import { Segment, SegmentEvent } from '@app/utils';

export interface InstructionsProps {
  state: string;
  steps: Array<{
    id: string;
    title: Copy;
    subtitle: Copy;
  }>;
  onClick: () => void;
}

const Row = styled.div<{ subtitle: boolean }>`
  display: flex;
  align-items: ${(p) => (p.subtitle ? 'flex-start' : 'center')};
`;

const Number = styled.div`
  background-color: #141414;
  color: white;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 8px;
`;

const Content = styled.div`
  display: block;
  flex: 1;
`;

export function Instructions({ steps, state, onClick }: InstructionsProps) {
  const handleClick = (e) => {
    if (e.target.href) {
      Segment.track(SegmentEvent.HX_STATE_INSTRUCTIONS_CLICKED, {
        link: e.target.text,
        url: e.target.href,
        state,
      });
    }
    onClick();
  };

  return (
    <div id="instructions" onClick={handleClick}>
      <Stack spacing="2">
        {steps.map((step, index) => (
          <Row key={index} subtitle={!!step.subtitle}>
            <div>
              <Number>{index + 1}</Number>
            </div>
            <Content>
              <div>
                <Text weight="medium">{step.title}</Text>
              </div>
              {step.subtitle ? (
                <Text size="fp" color="subtle">
                  {step.subtitle}
                </Text>
              ) : null}
            </Content>
          </Row>
        ))}
      </Stack>
    </div>
  );
}
