import { Income } from '@app/data';

const DEFAULT_LOGO = 'https://s.catch.co/img/sources/default.png';

export const getIncomeLabel = (income: Income): string => {
  return (
    income?.incomeSource?.subtext ||
    income?.incomeSource?.text ||
    income?.name ||
    income?.originalDescription ||
    ''
  );
};

export const getIncomeLogo = (income: Income): string => {
  return income?.incomeSource?.transactionTag?.logoUrl || DEFAULT_LOGO;
};
