import { useWatch } from 'react-hook-form';

//wrapper used in OnboardingFormBlueprint that tracks changes in dependencies and re-renders accordingly
const FormValues = ({ dependencies, control, children }) => {
  const values = useWatch({ name: dependencies, control });

  return children(values);
};

export default FormValues;
