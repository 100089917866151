import gql from 'graphql-tag';
import { ApolloEnrollmentGroup, EnrollmentGroupFragment, PlanSearchFragment } from '../fragments';

export interface UpsertEnrollmentGroupsVars {
  input: any;
}

export interface UpsertEnrollmentGroupsResponse {
  upsertEnrollmentGroups: {
    enrollmentGroups: Array<ApolloEnrollmentGroup>;
    error: {
      code?: string;
      field?: string;
      message?: string;
    };
  };
}

export const UpsertEnrollmentGroups = gql`
  mutation UpsertEnrollmentGroups($input: UpsertEnrollmentGroupsInput!) {
    upsertEnrollmentGroups(input: $input) {
      enrollmentGroups {
        id
        ...EnrollmentGroup
      }
      error {
        code
        field
        message
      }
    }
  }
  ${EnrollmentGroupFragment}
  ${PlanSearchFragment}
`;
