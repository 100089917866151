import gql from 'graphql-tag';

const GET_PAYMENT_REDIRECT = gql`
  mutation GetPaymentRedirect($policyID: ID!) {
    getPaymentRedirect(id: $policyID) {
      url
      saml
      error {
        code
        field
        message
      }
    }
  }
`;

export default {
  document: GET_PAYMENT_REDIRECT,
};
