import styled from 'styled-components';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import { CloseButtonProps } from 'react-toastify/dist/components';
import { Asset } from '../Asset';
import { applyGradient } from '@app/styles';
import { ToastType } from './Toast';
import Close from '@svg/close.svg';

const StyledContainer = styled(ToastifyContainer)`
  .Toastify__toast {
    box-shadow: rgba(16, 15, 19, 0.2) 0px 3px 21px;
    background-color: ${({ theme }) => theme.colors.bgCard};
    @media screen and (min-width: 768px) {
      border-radius: 12px;
    }
  }

  .Toastify__progress-bar--default {
    background: ${({ theme }) => applyGradient({ $colors: theme.gradients.brand })};
  }

  .CatchToast__${ToastType.coverage} .Toastify__progress-bar--default {
    background: ${({ theme }) => applyGradient({ $colors: theme.gradients.coverage })};
  }

  .CatchToast__${ToastType.important} {
    background: ${({ theme }) => applyGradient({ $colors: theme.gradients.brand })};
    span {
      color: ${({ theme }) => theme.colors.snow} !important;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.snow} !important;
    }
  }
`;

function CloseButton({ closeToast, ...rest }: CloseButtonProps) {
  return (
    <Asset
      onPress={closeToast}
      svg={Close}
      size="glyph"
      containerSize="xs"
      shape="circle"
      bg="skeleton"
      absolute
      containerStyle={{ top: 12, right: 12 }}
    />
  );
}

export function ToastContainer() {
  return <StyledContainer closeButton={CloseButton} />;
}
