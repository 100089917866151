import React, { useState } from 'react';
import { Route } from '@types';
import { useCopy } from '@app/utils';
import { useFintechMutation, SKIP_INCOME } from '@data';
import { ConfirmationBlueprint, ItemTriageBlueprint } from '@blueprints';
import { IncomeHeader } from '@app/components/income';

const PaycheckSkipped = ({ income, handleNext }) => {
  const { c } = useCopy('catch.sequences.Paycheck.skipped');
  const [copy, setCopy] = useState('Done');

  const [skipIncome, processing] = useFintechMutation(SKIP_INCOME);

  const handleSkip = (countTowardsIncome: boolean) => {
    setCopy(countTowardsIncome ? 'Skipped' : 'Not Income');

    skipIncome({
      variables: {
        input: {
          incomeId: income.id,
          countTowardsIncome,
        },
      },
    });
  };

  return (
    <ConfirmationBlueprint
      loading={processing.loading}
      called={processing.called}
      error={!!processing.error}
      titles={{ done: copy }}
      onSuccess={handleNext}
    >
      <ItemTriageBlueprint
        // loading={loading}
        question={c('title')}
        header={<IncomeHeader income={income} />}
        onPress={(val: boolean) => handleSkip(val)}
        options={[
          {
            key: 'skip',
            label: c('skipTitle'),
            description: c('skipSubtitle'),
            value: true,
          },
          {
            key: 'not-income',
            label: c('notIncomeTitle'),
            description: c('notIncomeSubtitle'),
            value: false,
          },
        ]}
      />
    </ConfirmationBlueprint>
  );
};

export const PaycheckSkippedView = {
  name: Route.PAYCHECK_SKIPPED,
  component: PaycheckSkipped,
  options: {},
};
