import React, { useMemo } from 'react';

import { AssetProps, Copy, Action } from '@types';
import { Toolbar, BasicLayout, useLayoutContext } from '@layouts';
import { Button } from '@uikit/components/Button';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  title: Copy;
  subtitles: Array<Copy>;
  titleSize?: string;
  subtitleSize?: string;
  actions: Array<Action>;
  toolbarTitle?: Element;
  onPress?: () => void;
  buttonText?: Copy;
  cancelButtonText?: Copy;
  cancelButtonOnPress?: () => void;
  icon?: Element;
  asset?: AssetProps;

  children?: any;
}

export const HiccupBlueprint = ({
  loading,
  asset,
  title,
  titleSize,
  subtitles,
  subtitleSize,
  actions = [],
  toolbarTitle,
  // deprecate these in favor of using actions
  onPress, // @deprecated
  buttonText, // @deprecated
  cancelButtonText, // @deprecated
  cancelButtonOnPress, // @deprecated
  disabled,
  children,
}: Props) => {
  const { accentColor } = useLayoutContext();

  const showToolbar = useMemo(() => {
    return !!buttonText || actions?.length > 0;
  }, [buttonText, actions]);

  return (
    <BasicLayout
      loading={loading}
      icon={{ gradient: accentColor, ...asset }}
      title={title}
      titleSize={titleSize}
      subtitles={subtitles}
      subtitleSize={subtitleSize}
      toolbar={
        showToolbar && (
          /* @ts-ignore */
          <Toolbar type="stack">
            {toolbarTitle}
            {actions.map((action) => (
              <Button
                wide
                key={action.testID}
                testID={action.testID}
                onPress={action.onAction}
                onHover={action.onHover}
                loading={action.loading}
                disabled={action.disabled || loading || disabled}
                scheme={action.color || accentColor}
                inherit={action.inherit}
                alt={action.alt}
                svg={action.svg}
                reverse
              >
                {action.label}
              </Button>
            ))}

            {!!cancelButtonText && (
              <Button
                disabled={loading || disabled}
                wide
                gutter="top"
                alt
                onPress={cancelButtonOnPress}
                testID="cancel-action-button"
              >
                {cancelButtonText}
              </Button>
            )}
            {buttonText && (
              <Button
                disabled={loading || disabled}
                wide
                inherit
                gutter="top"
                onPress={onPress}
                testID="action-button"
              >
                {buttonText}
              </Button>
            )}
          </Toolbar>
        )
      }
    >
      {children}
    </BasicLayout>
  );
};

HiccupBlueprint.options = {
  layout: 'page',
};

export default HiccupBlueprint;
