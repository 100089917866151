/** @deprecated use $ from useCopy hook*/
/* formatCurrency takes a value and ensures it's properly formatted to appear like currency by including the $ sign and not allowing multiple periods in the number. In its current state it does not handle internationalization and if we want to format currencies for other countries we'll have to look into this further. */
export const formatCurrency = (v: string | number) => {
  if (!v && v !== 0 && v !== '0') return '';
  const value = v.toString();
  const handleNegative = () => (value.charAt(0) === '-' ? '-' : '');

  const cleanedValue = value.replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .
  const sections = cleanedValue.split('.');

  // Remove any leading 0s apart from single 0
  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }

  const addCommas = (num: string) => num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // If numbers exist after first .
  if (sections[1]) {
    return handleNegative() + '$' + addCommas(sections[0]) + '.' + sections[1].slice(0, 2);
    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf('.') !== -1) {
    return handleNegative() + '$' + addCommas(sections[0]) + '.';
    // Otherwise, return only section
  } else {
    return handleNegative() + '$' + addCommas(sections[0]);
  }
};

// normalizeCurrency removes all '$' characters so the values that get saved in
// the store can be in a more usable form i.e. '12.34' vs. '$ 12.34'
export const normalizeCurrency = (value: string) => {
  if (!value) return value;
  return String(value).replace(/[^0-9.-]/g, '');
};

// preceisionRound allows you to round a number to a specific decimal place.
export const precisionRound = (number: number, precision: number) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

// toCents takes a floating point dollar value and converts it to pennies to be
// sent accross the wire via GraphQL.
export const toCents = (value: string) => {
  if (typeof value === 'undefined') {
    return 0;
  }
  const rounded = precisionRound(parseFloat(value), 2);
  return parseInt((rounded * 100).toFixed(), 10);
};

// toDollars takes a cents representation of a number and converts it to the
// floating point dollar representation
export const toDollars = (value: number) => {
  if (!value) return 0;
  return value / 100;
};
