import gql from 'graphql-tag';

export interface ReportHealthEnrollmentChangesVars {}

export interface ReportHealthEnrollmentChangesResponse {
  uploadSignature: boolean;
}

export const ReportHealthEnrollmentChanges = gql`
  mutation ReportHealthEnrollmentChanges($input: ReportHealthEnrollmentChangesInput!) {
    reportHealthEnrollmentChanges(input: $input) {
      insuranceEnrollment {
        id
        status
        applicationID
        healthApplication {
          id
        }
      }
      error {
        field
        message
        code
      }
    }
  }
`;
