import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Pressable } from '../Pressable';
import { useTheme } from '@uikit/hooks/useTheme';
import { Switch } from '../Switch';
import { Asset } from '../Asset';
import { Accessory } from '../Accessory';
import { Layout, useLayoutContext } from '@layouts';
import { CatchTheme, theme as _theme } from '@app/_ui-kit/themes/catch';
import {
  BackgroundColor,
  AssetProps,
  InteractionAccessoryType,
  AccessoryType,
  SemanticColor,
} from '@types';
import { Block } from '../Block';

/**
 * To avoid passing interactive components to components like  this function
 * takes a type of interaction like "select" and returns the selected or
 * unselected glyph based on the state of the parent.
 *
 * If the accessory type is not interactive, then the function
 * returns undefined and the accessory does not display.  In this case,
 * the "accessory" prop is preferred over "interaction"
 */
const getActionAccessory = (
  type?: InteractionAccessoryType,
  selected?: boolean,
): AccessoryType | undefined => {
  switch (type) {
    case 'caret':
      return selected ? 'up' : 'down';
    case 'select':
      return selected ? 'check' : undefined;
    case 'radio':
      return selected ? 'radio' : 'circle';
    case 'navigation':
      return 'arrow';
    case 'loading':
      return 'loading';
    default:
      // @ts-ignore
      return undefined;
  }
};

interface Props {
  loading?: boolean;
  testID: string;
  asset?: AssetProps;
  content: any;
  accessory?: AccessoryType;
  interaction?: InteractionAccessoryType;
  accessoryStyle?: any;
  disabled?: boolean;
  selectedBg?: BackgroundColor;
  selectedByDefault?: boolean;
  onPress?: (currentlySelected?: boolean) => any;
  onLabelPress: () => void;
  inset?: boolean;
  squared?: boolean;
  raised?: boolean;
  accentColor?: SemanticColor;
  bg?: string;
  sensitive?: boolean;
  noPadding?: boolean;
  topAlign?: boolean;
}

const Flex = styled(Block)<{
  $topAlign: boolean;
  $noPadding: boolean;
  $inset: boolean;
  $disabled: boolean;
}>`
  padding: ${(p) => (p.$noPadding ? '0px' : '16px')} ${(p) => (p.$inset ? '0px' : '24px')};
  display: flex;
  flex-direction: row;
  align-items: ${(p) => (p.$topAlign ? 'align-start' : 'center')};
  justify-content: space-between;
  ${(p) => p.$disabled && 'cursor: default;'}
`;

/**
 * The base row only hold the common aspects of different types of row.
 * Not designed be used in UI Kit, only use as base for other row components.
 * - 16px vertical padding
 */
const BaseRow = ({
  loading,
  testID,
  asset,
  content,
  accessory,
  interaction,
  selectedBg,
  selectedByDefault,
  disabled = false,
  inset,
  onPress,
  onLabelPress,
  squared,
  raised,
  accentColor: accentColorProp,
  bg,
  sensitive,
  noPadding,
  topAlign,
}: Props) => {
  const selectable =
    interaction === 'caret' || interaction === 'select' || interaction === 'switch';

  const [selected, setSelected] = useState<boolean>(selectedByDefault || false);
  const { theme, themeColors } = useTheme();
  const { accentColor: inheritAccentColor } = useLayoutContext();
  const accentColor = accentColorProp || inheritAccentColor;

  const handlePress = () => {
    if (onPress) onPress(!selected);
    if (selectable) setSelected((val) => !val);
  };

  useEffect(() => {
    setSelected(!!selectedByDefault);
  }, [selectedByDefault]);

  return (
    <>
      <Pressable
        testID={testID}
        disabled={disabled}
        handleOnPress={onPress ? handlePress : undefined}
        hoveredStyle={!onPress ? null : selectedBg ? theme[`${selectedBg}Bg`] : theme.skeletonBg}
        pressedStyle={!onPress ? null : theme.borderLightBg}
        corners={inset ? undefined : 'sm'}
        style={[
          inset ? {} : { marginHorizontal: -1 * _theme?.spacing?.mobile?.margins },
          !squared && theme.mdCorners,
          raised && theme.cardShadow,
          bg && {
            backgroundColor: themeColors[bg] || themeColors[`${bg}Color`] || bg,
            borderRadius: CatchTheme.corners.md,
          },
        ]}
      >
        {/* @ts-ignore */}
        <Layout margins={inset}>
          <Flex
            dataSet={sensitive ? { private: 'redact' } : {}}
            $topAlign={!!topAlign}
            $noPadding={!!noPadding}
            $inset={!!inset}
            $disabled={!!disabled}
          >
            {asset && (
              <Asset
                loading={loading}
                containerStyle={theme.rightGutter2}
                textSize="sm"
                {...asset}
              />
            )}
            <Pressable handleOnPress={onLabelPress} style={[theme.flex1]}>
              {content}
            </Pressable>

            <Accessory loading={loading} accessory={accessory} />

            {interaction === 'switch' ? (
              <Switch
                isOn={selected}
                onToggle={handlePress}
                disabled={disabled}
                color={accentColor}
              />
            ) : (
              <Accessory
                loading={loading}
                accessory={getActionAccessory(interaction, selected)}
                color={selected ? accentColor : undefined}
              />
            )}
          </Flex>
        </Layout>
      </Pressable>
    </>
  );
};

export default BaseRow;
