import React, { useEffect } from 'react';
import { useCopy, Segment, SegmentEvent, currentYear } from '@app/utils';
import { useQuery, GET_COVERAGE, useMutation, UpsertHealthApplication } from '@data';
import { exit } from '@navigate';
import { HiccupBlueprint } from '@blueprints';
import { Route } from '@types';
import { DEFAULT_COVERAGE_ASSET } from '@app/utils';

/**
 * This page is shown when a user gets to stopping point
 * in the week before
 */
const WindowShopping = ({ applicationID }) => {
  const { c } = useCopy('catch.health.windowShopping');
  const { c: basics } = useCopy('catch.basics');

  const { loading, data: coverageData } = useQuery(GET_COVERAGE, {
    variables: { coverageYears: [currentYear, currentYear + 1] },
  });

  /**@todo use new data definitions format and update this mutation! */
  const [upsert, { loading: upserting }] = useMutation(UpsertHealthApplication, {
    onCompleted: () => exit(Route.HOME),
  });

  const nextOEYear = coverageData?.reference?.health?.openEnrollmentDates?.oeCoverageYear;

  const handleNext = () => {
    upsert({
      variables: {
        input: {
          id: applicationID,
          lastUsedRoute: 'confirm-applicants',
        },
      },
    });
  };

  useEffect(() => {
    Segment.track(SegmentEvent.WINDOW_SHOPPING_COMPLETED, { coverageYear: nextOEYear });
  }, []);

  return (
    <HiccupBlueprint
      loading={loading || upserting}
      asset={DEFAULT_COVERAGE_ASSET}
      title={c('title')}
      subtitles={[c('subtitle')]}
      actions={[
        {
          type: 'ROUTE',
          onAction: () => handleNext(),
          label: basics('soundsGood'),
        },
      ]}
    />
  );
};

export const WindowShoppingView = {
  name: Route.EDE_WINDOW_SHOPPING,
  component: WindowShopping,
  options: {
    title: 'Window Shopping',
  },
};
