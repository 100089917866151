import gql from 'graphql-tag';
import { ApolloEnrollmentGroup, EnrollmentGroupFragment, PlanSearchFragment } from '../fragments';

export interface EnrollmentGroupsQueryData {
  viewerTwo: {
    id: string;
    healthExplorerData: {
      id: string;
      providerPlanID?: string;
    };
    health: {
      application: {
        id: string;
        selectedPlanID?: string;
        exchangeEligibleMembers?: Array<{
          id: string;
          givenName: string;
          familyName: string;
          healthEnrollmentGroupID: string;
        }>;
        enrollmentGroups: ApolloEnrollmentGroup;
      };
    };
  };
}

export const EnrollmentGroupsQuery = gql`
  query EnrollmentGroups($applicationID: ID!) {
    viewerTwo {
      id
      healthExplorerData {
        id
        providerPlanID
      }
      health {
        application(id: $applicationID) {
          id
          selectedPlanID
          coverageState
          exchangeEligibleMembers {
            id
            givenName
            familyName
            healthEnrollmentGroupID
          }
          enrollmentGroups {
            id
            ...EnrollmentGroup
          }
        }
      }
    }
  }
  ${EnrollmentGroupFragment}
  ${PlanSearchFragment}
`;
