import { createLogger } from '../logger';
import Env from '../env';
import { AgreementType, HealthMaritalStatus, HealthPathwayType, Trinary } from '@app/types';
import { env } from '@app/global';

const Log = createLogger('analytics:');

/**
 * Definitions for readable event names in segment
 * Please maintain "noun verbed" wording
 */
export enum SegmentEvent {
  BROWSER_REPLAY = 'SessionReplay',
  LOGROCKET = 'LogRocket',
  REGISTRATION_SUBMITTED = 'Submit Registration',
  USER_CREATED = 'User Created',
  HEALTH_HANDOFF_INITIATED = 'Health Handoff Initiated',
  EXPLORER_UPDATED = 'Explorer Updated',
  HX_ZIP_COMPLETED = 'HX Zip Completed',
  HX_APPLICANTS_COMPLETED = 'HX Applicants Completed',
  HX_INCOME_COMPLETED = 'HX Income Completed',
  HX_PRIORITY_COMPLETED = 'HX Priority Completed',
  HX_USAGE_COMPLETED = 'HX Usage Completed',
  HX_PRESCRIPTIONS_COMPLETED = 'HX Prescriptions Completed',
  HX_PROVIDERS_COMPLETED = 'HX Providers Completed',
  HX_PLAN_CHOSEN = 'HX Plan Chosen',
  HX_CONCIERGE_REQUESTED = 'HX Concierge Requested',
  HX_STATE_HANDOFF_REQUESTED = 'HX State Handoff Requested',
  HX_STATE_INSTRUCTIONS_CLICKED = 'HX State Instructions Clicked',
  CALENDLY_OPENED = 'Calendly Opened',
  CALENDLY_SCHEDULED = 'Calendly Scheduled',
  WINDOW_SHOPPING_COMPLETED = 'Window Shopping Completed',
  HEALTH_LINK_LOOKUP = 'Health Link Lookup',
  HEALTH_LINK_MISMATCHED_ATTESTION = 'Health Link Mismatched Attestation',
  HEALTH_LINK_EXITED = 'Health Link Exited',
  EDE_SCREENED_OUT = 'EDE Screened Out',
  EDE_IDENTITY_DOCS_UPLOADED = 'EDE Identity Docs Uploaded',
  EDE_IDENTITY_DOCS_FOUND = 'EDE Identity Docs Found',
  EXISTING_EDE_APP_FOUND = 'Existing EDE Application Found',
  EXISTING_EDE_APP_IMPORTED = 'Existing EDE Application Imported',
  APPLICATION_PRIVACY_AGREED = 'Application Privacy Agreed',
  APPLICATION_HOUSEHOLD_COMPLETED = 'Application Household Completed',
  APPLICATION_FINANCIAL_ASSISTANCE_ANSWERED = 'Application Financial Assistance Answered',
  SCREENING_QUESTIONS_STARTED = 'Screening Questions Started',
  SCREENING_QUESTIONS_PASSED = 'Screening Questions Passed',
  SCREENING_QUESTIONS_FAILED = 'Screening Questions Failed',
  SCREENING_QUESTIONS_REVIEWED = 'Screening Questions Reviewed',
  SCREENING_HANDOFF_CLICKED = 'Screening Handoff Clicked',
  APPLICATION_ASSISTANCE_COMPLETED = 'Application Assistance Completed',
  APPLICATION_APPLICANT_ADDED = 'Application Applicant Added',
  APPLICATION_APPLICANTS_CONFIRMED = 'Application Applicants Confirmed',
  APPLICATION_MEMBER_INFO_COMPLETED = 'Application Member Info Completed',
  APPLICATION_MEMBER_QUESTIONS_COMPLETED = 'Application Member Questions Completed',
  APPLICATION_IMMIGRATION_COMPLETED = 'Application Immigration Completed',
  APPLICATION_MEDICAID_COMPLETED = 'Application Medicaid Completed',
  APPLICATION_INCOME_ASKED = 'Application Income Asked',
  APPLICATION_DEDUCTIONS_ASKED = 'Application Deductions Asked',
  APPLICATION_INCOME_DETAILS_ENTERED = 'Application Income Details Entered',
  APPLICATION_INCOME_CONFIRMED = 'Application Income Confirmed',
  APPLICATION_INCOME_DISCREPANCY_ANSWERED = 'Application Income Discrepancy Answered',
  APPLICATION_SEP_STARTED = 'Application SEP Started',
  APPLICATION_SEP_COMPLETED = 'Application SEP Completed',
  APPLICATION_MEDICAL_BILLS_COMPLETED = 'Application Medical Bills Completed',
  APPLICATION_CURRENT_COVERAGE_COMPLETED = 'Application Dependent Current Coverage Completed',
  APPLICATION_OUTSIDE_HOUSEHOLD_COMPLETED = 'Application Outside Household Completed',
  APPLICATION_WORK_HOURS_COMPLETED = 'Application Work Hours Completed',
  APPLICATION_OTHER_COVERAGE_COMPLETED = 'Application Other Coverage Completed',
  APPLICATION_REVIEWED = 'Application Reviewed',
  APPLICATION_AGREEMENTS_CONFIRMED = 'Application Agreements Confirmed',
  APPLICATION_RESULTS_VIEWED = 'Application Results Viewed',
  APPLICATION_ERROR_OCCURRED = 'Application Error Occurred',
  AGREEMENT_SIGNED = 'Agreement Signed',
  EMAIL_ADJUSTED = 'Email Adjusted',

  EDE_SAVE_AND_EXIT = 'EDE Save and Exit',
  IDENTITY_PROOFING_STARTED = 'Identity Proofing Started',
  IDENTITY_PROOFING_QUESTIONS = 'Identity Proofing Questions Asked',
  IDENTITY_PROOFING_OFFLINE = 'Identity Proofing Offline Verification Required',
  IDENTITY_PROOFING_CALLED = 'Identity Proofing Experian Call Attested',
  IDENTITY_PROOFING_TIMEOUT = 'Identity Proofing Timed Out',
  IDENTITY_PROOFING_UPLOAD = 'Identity Proofing Upload Requested',
  IDENTITY_PROOFING_UPLOADED = 'Identity Proofing Doc Uploaded',
  IDENTITY_PROOFING_DENIED = 'Identity Proofing Denied',
  IDENTITY_PROOFING_VERIFIED = 'Identity Proofing Passed',
  IDENTITY_PROOFING_ERROR = 'Identity Proofing Error Encountered',
}

/**
 * For events requiring data to be passed, add their data definition here.
 * This ensures that we are sending all expected values to Segment
 *
 * For events without defined data, use simpleTrack instead of track
 *
 * Note: please use snake case for event properties:
 * e.g. coverage_year instead of coverageYear
 */
type SegmentData = {
  [SegmentEvent.BROWSER_REPLAY]: {
    sessionURL: string;
  };
  [SegmentEvent.LOGROCKET]: {
    sessionURL: string;
  };
  [SegmentEvent.REGISTRATION_SUBMITTED]: {
    alias: string;
  };
  [SegmentEvent.USER_CREATED]: {
    alias: string;
    signup_context: string;
  };
  [SegmentEvent.HEALTH_HANDOFF_INITIATED]: {
    handoff_type: string;
  };
  [SegmentEvent.EXPLORER_UPDATED]: {
    zip?: string;
    state?: string;
    fips?: string;
    income?: number;
    members?: Array<{
      age: number;
      relation: string;
      sex?: 'MALE' | 'FEMALE';
      isSmoker?: boolean;
    }>;
    drugsWanted?: Array<{
      name: string;
      rxcui: string;
    }>;
    providersWanted?: Array<{
      name: string;
      npi: string;
    }>;
    financialPreference?: 'LOW_PREMIUM' | 'LOW_DEDUCTIBLE' | 'BALANCED';
    plannedUsage?: 'REC_LOW' | 'REC_MEDIUM' | 'REC_HIGH';
  };

  [SegmentEvent.HX_ZIP_COMPLETED]: {
    zip: string;
    fips: string;
    state: string;
    pathway: HealthPathwayType;
    coverage_year: number;
  };
  [SegmentEvent.HX_APPLICANTS_COMPLETED]: {
    applicant_age: number;
    applicant_sex: 'MALE' | 'FEMALE';
    total_applicants: number;
    has_spouse: boolean;
    num_dependents: number;
    has_any_smoker: boolean;
    coverage_year: number;
  };
  [SegmentEvent.HX_INCOME_COMPLETED]: {
    income?: number;
    skipped: boolean;
    coverage_year: number;
  };
  [SegmentEvent.HX_PRIORITY_COMPLETED]: {
    financial_preference?: 'LOW_PREMIUM' | 'BALANCED' | 'LOW_DEDUCTIBLE';
    skipped: boolean;
    coverage_year: number;
  };
  [SegmentEvent.HX_USAGE_COMPLETED]: {
    planned_usage?: 'REC_LOW' | 'REC_MEDIUM' | 'REC_HIGH';
    skipped: boolean;
    coverage_year: number;
  };
  [SegmentEvent.HX_PRESCRIPTIONS_COMPLETED]: {
    skipped: boolean;
    total_prescriptions: number;
    prescriptions_not_found: number;
    coverage_year: number;
  };
  [SegmentEvent.HX_PROVIDERS_COMPLETED]: {
    skipped: boolean;
    total_providers: number;
    providers_not_found: number;
    coverage_year: number;
  };
  [SegmentEvent.HX_PLAN_CHOSEN]: {
    plan_name: string;
    plan_id: string;
    plan_metal_level: string;
    plan_premium: string;
    plan_premium_with_credit: string;
    plan_carrier: string;
    plan_type: string;
    pathway: HealthPathwayType;
    coverage_year: number;
  };
  [SegmentEvent.HX_CONCIERGE_REQUESTED]: {
    preference: 'call' | 'email';
  };
  [SegmentEvent.HX_STATE_HANDOFF_REQUESTED]: {
    type: 'concierge' | 'diy';
  };
  [SegmentEvent.HX_STATE_INSTRUCTIONS_CLICKED]: {
    link: string;
    url: string;
    state: string;
  };
  [SegmentEvent.CALENDLY_OPENED]: {
    context: 'hx_concierge' | 'sbe_concierge';
  };
  [SegmentEvent.WINDOW_SHOPPING_COMPLETED]: {
    coverageYear?: number;
  };
  [SegmentEvent.APPLICATION_RESULTS_VIEWED]: {
    can_enroll: boolean;
    coverage_year: number;
  };
  [SegmentEvent.HEALTH_LINK_MISMATCHED_ATTESTION]: {
    resultsType: 'SAVINGS' | 'ACTIVE' | 'INACTIVE';
  };
  [SegmentEvent.SCREENING_QUESTIONS_STARTED]: {
    coverage_year: number;
    marital_status?: HealthMaritalStatus;
    num_dependents: number;
    household_revisit: boolean;
  };
  [SegmentEvent.APPLICATION_FINANCIAL_ASSISTANCE_ANSWERED]: {
    financial_assistance: boolean;
    qualifies_for_financial_help: boolean;
    household_income: number;
  };
  [SegmentEvent.SCREENING_QUESTIONS_FAILED]: {
    coverage_year: number;
    failed_question: string;
    phase: number;
  };
  [SegmentEvent.SCREENING_QUESTIONS_REVIEWED]: {
    coverage_year: number;
    failed_question: string;
    phase: number;
  };
  [SegmentEvent.SCREENING_HANDOFF_CLICKED]: {
    coverage_year: number;
  };
  [SegmentEvent.SCREENING_QUESTIONS_PASSED]: {
    coverage_year: number;
  };
  [SegmentEvent.EDE_SCREENED_OUT]: {
    question: string;
    phase: number;
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_PRIVACY_AGREED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_HOUSEHOLD_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_ASSISTANCE_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_APPLICANT_ADDED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_APPLICANTS_CONFIRMED]: {
    coverage_year: number;
  };
  [SegmentEvent.EXISTING_EDE_APP_FOUND]: {
    coverage_year: number;
  };
  [SegmentEvent.EXISTING_EDE_APP_IMPORTED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_MEMBER_INFO_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_MEMBER_QUESTIONS_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_IMMIGRATION_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_MEDICAID_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_INCOME_ASKED]: {
    has_any_income: boolean;
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_DEDUCTIONS_ASKED]: {
    has_any_deductions: boolean;
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_INCOME_DETAILS_ENTERED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_INCOME_CONFIRMED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_INCOME_DISCREPANCY_ANSWERED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_SEP_STARTED]: {
    isGainDependent: Trinary;
    isGainedLawfulPresence: Trinary;
    isLostCoverageLast60Days: Trinary;
    isMarriedLast60Days: Trinary;
    isMoved: Trinary;
    isReleasedFromIncarceration: Trinary;
    isWillLoseCoverageNext60Days: Trinary;
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_SEP_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_MEDICAL_BILLS_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_CURRENT_COVERAGE_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_OUTSIDE_HOUSEHOLD_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_WORK_HOURS_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_OTHER_COVERAGE_COMPLETED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_REVIEWED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_AGREEMENTS_CONFIRMED]: {
    coverage_year: number;
  };
  [SegmentEvent.APPLICATION_ERROR_OCCURRED]: {
    coverage_year: number;
  };
  [SegmentEvent.AGREEMENT_SIGNED]: {
    agreement_type: AgreementType;
  };
};

/**
 * @note on Segment:
 *
 * By default, the dev and stage envs are disabled
 * so that they don't take up visitor #s
 *
 * If you are working on Segment tracking, you should
 * verify that events are being properly tracked by enabling
 * the web dev source in Segment + making sure your event is there
 */
export function initAnalytics(segmentKey, debug) {
  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || []);

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from their CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://analytics.catch.co/analytics.js/v1/' + key + '/analytics.min.js';

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.1.0';

  // Load Analytics.js with proper environment key, which will automatically
  // load the tools we've enabled.
  analytics.load(segmentKey);

  // Debug should display some useful messages
  if (debug) {
    analytics.debug();
  }

  // Make the first page call to load the integrations.
  analytics.page();
}

const handleValues = (vals = {}) => {
  return {
    ...vals,
    app_version: env.VERSION_NUMBER,
    app_build: env.BUILD_NUMBER,
    app_platform: 'Web',
  };
};

/**
 *
 */
export const Segment = {
  get analytics() {
    if (Env.isLocal) {
      return {
        page: console.log,
        track: console.log,
        identify: console.log,
      };
    }

    return window.analytics;
  },
  identifyUser(userID, traits = {}) {
    Log.debug({ userID, ...traits });
    if (!this.analytics) return;
    this._userID = userID;
    this.analytics.identify(userID, {
      ...traits,
    });
  },
  pageView() {
    if (!this.analytics) return;
    this.analytics.page();
  },

  /**
   * Segment.track(event, {})
   * Event tracking for complex events with specified data
   *
   * @param name
   * @param data
   * @returns
   */
  track<Event extends keyof SegmentData>(name: Event, data: SegmentData[Event]) {
    if (!this.analytics) return;
    this.analytics.track(name, handleValues(data));
  },

  /**
   * Segment.simpleTrack(event)
   * Event tracking for simple events without any additional data
   * @param name
   * @returns
   */
  simpleTrack(name: Exclude<SegmentEvent, keyof SegmentData>) {
    if (!this.analytics) return;
    this.analytics.track(name, handleValues({}));
  },

  /**
   * ----------------------------------------------
   *
   * Note: all functions below are helpers for specific events
   * They should simply format data and invoke this.track
   * For naming, use camel case of the corresponding event type
   */

  explorerUpdated(data: any) {
    this.track(SegmentEvent.EXPLORER_UPDATED, {
      zip: data?.viewerTwo?.healthExplorerData?.zipcode,
      fips: data?.viewerTwo?.healthExplorerData?.countyfips,
      state: data?.viewerTwo?.healthExplorerData?.state,
      income: data?.viewerTwo?.healthExplorerData?.income,
      members: data?.viewerTwo?.healthExplorerData?.dependents?.map((m) => ({
        age: m.age,
        sex: m.sex,
        isSmoker: m.isSmoker,
        relation: m.relation,
      })),
      drugsWanted: data?.viewerTwo?.health?.healthPreference?.drugsWanted?.map((d) => ({
        name: d.name,
        rxcui: d.rxcui,
      })),
      providersWanted: data?.viewerTwo?.health?.healthPreference?.providersWanted?.map((p) => ({
        name: p.name,
        npi: p.npi,
      })),
      financialPreference: data?.viewerTwo?.health?.healthPreference?.financialPreference,
      plannedUsage: data?.viewerTwo?.health?.healthPreference?.plannedUsage,
    });
  },

  conciergeRequested({ userID, preference }: { userID: string; preference: 'email' | 'call' }) {
    this.track(SegmentEvent.HX_CONCIERGE_REQUESTED, { preference }); // track the event
    this.identifyUser(userID, { requested_concierge_preference: preference }); // also update user
  },

  stateHandoff({ userID, type }: { userID: string; type: 'concierge' | 'diy' }) {
    this.track(SegmentEvent.HX_STATE_HANDOFF_REQUESTED, { type }); // track the event
    this.identifyUser(userID, { state_handoff_type: type }); // also update user
  },
};
