export type SupportedPathway = 'EDE' | 'OSCAR' | 'HEALTH_SHERPA' | 'STATE_EXCHANGE' | 'CALIFORNIA';

export type HealthApplicationStatus =
  | 'SUBMITTED'
  | 'IN_PROGRESS'
  | 'DELETED'
  | 'DRAFT'
  | 'PENDING_ENROLLMENT'
  | 'ENROLLMENT_STUCK'
  | 'ENROLLED'
  | 'CANCELED'
  | 'EXPLORER'
  | 'RENEWAL';

export type HealthApplicationAssistorType =
  | 'ASSISTOR_TYPE_NAVIGATOR'
  | 'ASSISTOR_TYPE_CERTIFIED_APPLICATION_COUNSELOR'
  | 'ASSISTOR_TYPE_IN_PERSON_ASSISTANT_PERSONNEL'
  | 'ASSISTOR_TYPE_AGENT_BROKER'
  | 'ASSISTOR_TYPE_NO_ASSISTANCE';

export type HealthApplicationContext = 'FULL_APPLICATION' | 'FASTLINK' | 'CIC' | 'SLASHER';

export type HealthPathwayType = 'EDE' | 'OSCAR' | 'HEALTH_SHERPA' | 'STATE_EXCHANGE' | 'CALIFORNIA';

export type HealthMaritalStatus = 'SINGLE' | 'MARRIED' | 'UNKNOWN';

export type HealthTaxReturnFilingStatusType =
  | 'SINGLE_FILER'
  | 'MARRIED_FILING_JOINTLY'
  | 'MARRIED_FILING_SEPARATELY'
  | 'HEAD_OF_HOUSEHOLD';

export type HealthSignatureType = 'APPLICANT' | 'AUTHORIZED_REPRESENTATIVE' | 'AGENT_BROKER';

export type HealthApplicationErrorCode =
  | 'UNKNOWN_ERROR'
  | 'NOT_SUPPORTED'
  | 'PROVIDER_INTERNAL'
  | 'PROVIDER_NOT_AUTH'
  | 'PROVIDER_FAILURE'
  | 'DATA_PREFILL_FAILURE'
  | 'INVALID_ARGUMENT'
  | 'IMAGE_CONVERSION_FAILURE'
  | 'NOT_FOUND';

export type DMITypes = 'ACTION_NEEDED' | 'PROCESSING' | 'EXPIRED' | 'COMPLETE';

export type ResolutionStatus =
  | 'RESOLVED'
  | 'EXPIRED'
  | 'UNDER_REVIEW'
  | 'PENDING_DOCUMENTATION'
  | 'CONTACTING_EMPLOYER_BECAUSE_SUBJECT_TO_ESC_SAMPLING'
  | 'PENDING_ADDITIONAL_DOCUMENTATION';

export type EDEValidationErrorCode =
  | 'ERR_UNKNOWN'
  | 'ERROR_CANNOT_BE_NULL'
  | 'ERR_CANNOT_BE_EMPTY'
  | 'ERR_INVALID_COVERAGE_YEAR'
  | 'ERR_CANNOT_BE_FALSE'
  | 'ERR_NOT_NUMERIC'
  | 'ERR_MAX_LENGTH_EXCEEDED'
  | 'ERR_MIN_LENGTH_NOT_REACHED'
  | 'ERR_INVALID_INCOME_NOT_VARIABLE';

export type MetalLevel =
  | 'BRONZE'
  | 'SILVER'
  | 'GOLD'
  | 'PLATINUM'
  | 'CATASTROPHIC'
  | 'LOW'
  | 'HIGH'
  | 'EXPANDED_BRONZE';

export type CSRLevel =
  | 'NONE'
  | 'TRIBE_BELOW_300_FPL'
  | 'TRIBE_ABOVE_300_FPL'
  | 'SILVER_73AV'
  | 'SILVER_87AV'
  | 'SILVER_94AV';

type NameSuffix = 'JR' | 'SR' | 'II' | 'III' | 'IV' | 'V';

type HealthRelation =
  | 'PARENT'
  | 'SIBLING'
  | 'CHILD'
  | 'SPOUSE'
  | 'EX_SPOUSE'
  | 'LIFE_PARTNER'
  | 'GRANDPARENT'
  | 'GRANDCHILD'
  | 'AUNT'
  | 'UNCLE'
  | 'NIECE'
  | 'NEPHEW'
  | 'COUSIN'
  | 'STEPPARENT'
  | 'STEPCHILD'
  | 'PARENT_IN_LAW'
  | 'SIBLING_IN_LAW'
  | 'CHILD_IN_LAW'
  | 'OTHER_RELATIVE'
  | 'OTHER_DEPENDENT'
  | 'SELF';

interface Address {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  zip4?: string;
  country?: string; // 3 digit country code
  countyfips?: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
}

interface Phone {
  number: string;
  type: 'MOBILE' | 'HOME' | 'OFFICE' | 'OTHER';
  ext?: String;
}

export type LanguageType =
  | 'ENGLISH'
  | 'SPANISH'
  | 'VIETNAMESE'
  | 'TAGALOG'
  | 'RUSSIAN'
  | 'PORTUGUESE'
  | 'ARABIC'
  | 'CHINESE'
  | 'FRENCH'
  | 'FRENCH_CREOLE'
  | 'GERMAN'
  | 'GUJARATI'
  | 'HINDI'
  | 'KOREAN'
  | 'POLISH'
  | 'URDU'
  | 'OTHER';

type Race =
  | 'AMERICAN_INDIAN_OR_ALASKAN_NATIVE'
  | 'ASIAN_INDIAN'
  | 'BLACK_OR_AFRICAN_AMERICAN'
  | 'CHINESE'
  | 'FILIPINO'
  | 'GUAMANIAN_OR_CHAMORRO'
  | 'JAPANESE'
  | 'KOREAN'
  | 'NATIVE_HAWAIIAN'
  | 'OTHER_ASIAN'
  | 'OTHER_PACIFIC_ISLANDER'
  | 'SAMOAN'
  | 'VIETNAMESE'
  | 'WHITE'
  | 'OTHER';

type Ethnicity = 'NOT_HISPANIC' | 'CUBAN' | 'MEXICAN' | 'PUERTO_RICAN' | 'OTHER' | 'HISPANIC';

export enum SexAssignedAtBirth {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
  DONT_KNOW = 'DONT_KNOW',
  PREFER_NOT_TO_ANSWER = 'PREFER_NOT_TO_ANSWER',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  TRANSGENDER_FEMALE = 'TRANSGENDER_FEMALE',
  TRANSGENDER_MALE = 'TRANSGENDER_MALE',
  DIFFERENT_TERM = 'DIFFERENT_TERM',
  DONT_KNOW = 'DONT_KNOW',
  PREFER_NOT_TO_ANSWER = 'PREFER_NOT_TO_ANSWER',
}

export enum SexualOrientation {
  LESBIAN_OR_GAY = 'LESBIAN_OR_GAY',
  STRAIGHT = 'STRAIGHT',
  BISEXUAL = 'BISEXUAL',
  DIFFERENT_TERM = 'DIFFERENT_TERM',
  DONT_KNOW = 'DONT_KNOW',
  PREFER_NOT_TO_ANSWER = 'PREFER_NOT_TO_ANSWER',
}

export type IncomeSourceType =
  | 'RETIREMENT'
  | 'RENTAL_OR_ROYALTY_INCOME'
  | 'PENSION'
  | 'FARMING_OR_FISHING_INCOME'
  | 'SOCIAL_SECURITY_BENEFIT'
  | 'CAPITAL_GAINS'
  | 'ALIMONY_PAYMENT'
  | 'INVESTMENT_INCOME'
  | 'OTHER_INCOME'
  | 'SCHOLARSHIP'
  | 'PRIZES_AWARDS_GAMBLING_WINNINGS'
  | 'COURT_AWARDS'
  | 'JURY_DUTY_PAY'
  | 'CANCELED_DEBT'
  | 'UNEMPLOYMENT'
  | 'JOB'
  | 'SELF_EMPLOYMENT'
  | 'STUDENT_LOAN_INTEREST'
  | 'ALIMONY_RECEIVED'
  | 'OTHER_DEDUCTION'
  | 'CASH_SUPPORT';

export type IncomeFrequencyType =
  | 'WEEKLY'
  | 'BI_WEEKLY'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'ANNUALLY'
  | 'SEMI_MONTHLY'
  | 'HOURLY'
  | 'ONE_TIME'
  | 'DAILY';

type JobIncomeDifferenceReason =
  | 'STOP_WORKING'
  | 'DECREASE_HOURS'
  | 'CHANGE_JOBS'
  | 'WAGE_CUT'
  | 'OTHER';

interface IncomeStateExchangeFields {
  isPaidBeforeJan1: Boolean;
  dateIncomeBegan: Date;
  isPresentlyEmployed: Boolean;
  dateLastGotIncome: Date;
  isExpectedToEndIn4Months: Boolean;
  dateIncomeEnded: Date;
  incomeName: String;
}

interface IncomeSource {
  type: IncomeSourceType;
  incomeAmount: number;
  incomeFrequencyType: IncomeFrequencyType;
  employerPhoneNumber: string;
  employerAddress: Address;
  employerIdentificationNumber: string;
  averageWeeklyWorkHours: number;
  averageWeeklyWorkDays: number;
  selfEmploymentIncomeDescription: string;
  incomeDescription: string;
  expirationDate: Date;
  tribalIncomeAmount: number;
  isEstimatedForAptc: boolean;
  incomeDifferenceReason: JobIncomeDifferenceReason;
  otherIncomeDifferenceReason: string;
  otherIncomeDescription: string;
  employerName: string;
  otherDeductionDescription: string;
  jobIncomeExplanationRequired: boolean;
  educationExpenses: number;
  incomeID: number;
  incomeStateExchangeFields: IncomeStateExchangeFields;
}

interface Deductions {
  incomeSourceType: IncomeSourceType;
  incomeFrequencyType: IncomeFrequencyType;
  incomeAmount: number;
  otherDeductionDescription: string;
  incomeID: number;
}

interface AnnualTaxIncome {
  isVariableIncome: boolean;
  isUnknownIncome: boolean;
  incomeAmount: number;
  isIncomeLessExplained: boolean;
  taxHouseholdIncomeDiscrepancyDescriptionText: string;
  taxHouseholdIncomeDifferenceReasonType: Array<
    | 'HOUSEHOLD_MEMBER_CHANGE'
    | 'SELF_EMPLOYMENT_INCOME_FLUCTUATION'
    | 'JOB_CHANGE'
    | 'WORK_LOCATION_CHANGE'
    | 'OTHER'
  >;
  variableIncomeDescriptionText: string;
  attestedAnnualizedAPTCIndividualIncomeAmount: number;
  annualIncomeExplanationRequired: boolean;
  hasReceivedUnemploymentCompensation: boolean;
}

export type InsuranceMarketType =
  | 'MARKETPLACE'
  | 'STATE_MEDICAID'
  | 'STATE_CHIP'
  | 'MEDICARE'
  | 'TRICARE'
  | 'VA'
  | 'PEACE_CORPS'
  | 'OTHER_FULL_BENEFIT'
  | 'OTHER_LIMITED_BENEFIT'
  | 'NONE';

interface OtherCoverage {
  insuranceMarketType: InsuranceMarketType;
  insurancePolicyNumber: string;
  insurancePolicyMemberID: string;
  insurancePlanName: string;
}

export type PreliminaryStatus = 'NOT_APPLICABLE' | 'YES' | 'NO';

interface HRAOffer {
  hraType: 'ICHRA' | 'QSEHRA';
  startDate: Date;
  endDate: Date;
  noticeDate: Date;
  isEnrolledInOfferFromSameEmployer: Boolean;
  isEnrolledInOfferFromSameEmployerUntilStartDate: Boolean;
  primaryInsuredMemberIdentifier: String;
  isPrimaryInsuredMemberNotInTaxHh: Boolean;
  employeeSelfOnlyOfferAmount: number;
  employeeSelfOnlyOfferFrequencyType:
    | 'WEEKLY'
    | 'BI_WEEKLY'
    | 'MONTHLY'
    | 'QUARTERLY'
    | 'ANNUALLY'
    | 'SEMI_MONTHLY'
    | 'PRORATED';
}

export type SEPType =
  | 'NONE'
  | 'ADOPTION'
  | 'NEWBORN'
  | 'LOSS_OF_COVERAGE'
  | 'MEDICAID_CHIP_DENIAL'
  | 'MOVE'
  | 'MARRIAGE'
  | 'TRIBE_MEMBERSHIP'
  | 'RELEASE_FROM_INCARCERATION'
  | 'LAWFUL_PRESENCE'
  | 'APTC'
  | 'CSR'
  | 'EXCEPTIONAL_CIRCUMSTANCE'
  | 'ADMIN_CUSTOM_EFFECTIVE_DATE'
  | 'ACCELERATED'
  | 'ADMIN_NON_CUSTOM_EFFECTIVE_DATE'
  | 'EXTENDED_OE'
  | 'PLAN_NO_LONGER_AVAILABLE'
  | 'PLAN_APTC_CSR_ELIGIBILITY_CHANGE'
  | 'ACCESS_ICHRA'
  | 'ACCESS_QSEHRA';

export type HealthAsyncStatus =
  | 'NOT_APPLICABLE'
  | 'PENDING'
  | 'COMPLETED_HAS_DMI'
  | 'COMPLETED_NO_DMI';

export type CoverageEndedReason =
  | 'PARENT_NO_LONGER_WORKS_FOR_THE_COMPANY'
  | 'COMPANY_STOPPED_OFFERING_INSURANCE'
  | 'COMPANY_STOPPED_OFFERING_INSURANCE_TO_DEPENDENTS'
  | 'INSURANCE_TOO_EXPENSIVE'
  | 'MEDICAL_NEEDS_NOT_COVERED'
  | 'OTHER';

export interface ApplicationMember {
  id: string;
  age: number;
  relation: HealthRelation;
  filerRelation: HealthRelation;
  spouseRelation: HealthRelation;
  isSmoker: boolean;
  isPregnant: boolean;
  isParent: boolean;
  givenName: String;
  middleName: String;
  familyName: String;
  nameSuffix: NameSuffix;
  sex: 'FEMALE' | 'MALE';
  dob: Date;
  email: string;
  mailingAddress: Address;
  homeAddress: Address;
  idProofAddress: Address;
  phone: Phone;
  secondaryPhone: Phone;
  ssn: string;
  birthCountry: string;
  isRequestingCoverage: Boolean;
  writtenLanguageType: LanguageType;
  spokenLanguageType: LanguageType;
  contactMethod: 'E_TEXT' | 'EMAIL' | 'MAIL';
  race: Array<Race>;
  otherRaceText: String;
  isHispanicOrigin: Boolean;
  ethnicity: Array<Ethnicity>;
  otherEthnicityText: String;
  isLostCoverageLast60Days: Boolean;
  coverageLostDate: Date;
  planName: String;
  isWillLoseCoverageNext60Days: Boolean;
  coverageLostDateFuture: Date;
  isMarriedLast60Days: Boolean;
  isGainDependent: Boolean;
  isMoved: Boolean;
  isReleasedFromIncarceration: Boolean;
  isNoChanges: Boolean;
  whenMarried: Date;
  isQualifyingCoverageLast60Days: Boolean;
  isLivedOutsideUsLast60Days: Boolean;
  whenRelocated: Date;
  isMovedFromOutsideUs: Boolean;
  previousZipcode: String;
  whenBecomeADependent: Date;
  whenReleasedFromIncarceration: Date;
  hasCoverage60DaysBeforeMarriage: Boolean;
  hasLivedOutsideUSLast60DaysBeforeMarriage: Boolean;
  previousCountyLivedName: String;
  hasCoverageLast60DaysBeforeRelocation: Boolean;
  previousStateCode: String;
  isBlindOrDisabled: Boolean;
  isLongTermCare: Boolean;

  incomeThisMonth: Boolean;
  hasDeductions: boolean;
  incomeSources: Array<IncomeSource>;
  deductions: Array<Deductions>;
  annualTaxIncome: AnnualTaxIncome;

  isDeniedMedicaidLast90Days: Boolean;
  isMedicaidEndingSoon: Boolean;
  isMedicaidDenied: Boolean;
  medicaidDeniedDate: Date;
  isAppliedDuringOeOrLifeChange: Boolean;
  isEnrolledInHealthCoverage: Boolean;

  otherCoverages: Array<OtherCoverage>;
  parent1WeeklyWorkHourQuantity: number;
  parent2WeeklyWorkHourQuantity: number;
  isUnpaidBill: Boolean;
  isCoveredDependentChild: Boolean;
  isCoverageEnded: Boolean;
  isStateHealthBenefit: Boolean;
  coverageEndedReason: CoverageEndedReason;
  coverageEndedReasonText: String;
  applicationMemberID: String;
  isAttestedHeadOfHousehold: Boolean;
  isTaxFiler: Boolean;
  taxReturnFilingStatusType: HealthTaxReturnFilingStatusType;

  isClaimsDependent: Boolean;
  isClaimingTaxFilerNotOnApplication: Boolean;
  hasAbsentParent: Boolean;
  babyDueQuantity: number;

  isFosterCare1825: Boolean;

  fosterCareState: String;

  isMedicaidDuringFosterCare: Boolean;

  fosterCareEndAge: number;

  incarcerationType: 'NOT_INCARCERATED' | 'PENDING_DISPOSITION' | 'INCARCERATED';

  isFullTimeStudentStatus: Boolean;

  ssnAlternateName: {
    firstName: string;
    middleName: string;
    lastName: string;
    prefix: string;
    suffix: string;
  };

  isCitizen: Boolean;

  isNaturalizedCitizen: Boolean;

  lawfulPresenceDocumentation: {
    documentType:
      | 'PERMANENT_RESIDENT_CARD_I_551'
      | 'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A'
      | 'REENTRY_PERMIT'
      | 'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE'
      | 'EMPLOYMENT_AUTHORIZATION_CARD_I_766'
      | 'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94'
      | 'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94'
      | 'REFUGEE_TRAVEL_DOCUMENT'
      | 'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20'
      | 'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019'
      | 'NOTICE_OF_ACTION_I_797'
      | 'FOREIGN_PASSPORT'
      | 'CERTIFICATE_OF_CITIZENSHIP'
      | 'CERTIFICATE_OF_NATURALIZATION';
    documentExpirationDate: Date;
    alienNumber: String;
    employmentAuthorizationCategoryIdentifier: String;
    sevisID: String;
    i94Number: String;
    passportNumber: String;
    naturalizationCertificateNumber: String;
    citizenshipNumber: String;
    documentAlternativeName: {
      firstName: string;
      middleName: string;
      lastName: string;
      prefix: string;
      suffix: string;
    };
    isNoIdentifiersProvided: Boolean;
    otherDocumentTypeText: String;
    passportIssuingCountry: String;
    cardNumber: String;
  };

  isLawfulPresenceStatus: Boolean;

  lawfulPresenceGrantDate: Date;

  whenLawfulPresence: Date;

  isGainedLawfulPresence: Boolean;

  hasNoAlienNumber: Boolean;

  hasLivedInUS5Years: Boolean;

  isMedicaidDeniedDueToImmigration: Boolean;

  isImmigrationStatusChangeSinceDenied: Boolean;

  isVeteran: Boolean;

  isVeteranSelf: Boolean;

  nonMemberVeteranRelationshipTypes: Array<
    'DECEASED_SPOUSE' | 'DECEASED_PARENT_STEP_PARENT' | 'PARENT_STEP_PARENT' | 'SPOUSE'
  >;
  isNoHomeAddress: Boolean;
  isTemporaryOutOfState: Boolean;
  personTrackingNumber: String;
  isTaxDependent: Boolean;

  aptcEligibility: {
    startDate: Date;
    endDate: Date;
    amount: number;
  };

  csrEligibility: {
    startDate: Date;
    endDate: Date;
    csrLevel: CSRLevel;
  };

  exchangeEligibility: {
    startDate: Date;
    endDate: Date;
  };

  initialEnrollmentEligibility: {
    startDate: Date;
    endDate: Date;
    earliestQhpEffective: Date;
    latestQhpEffective: Date;
  };

  sepEligibility: {
    startDate: Date;
    endDate: Date;
    earliestQhpEffective: Date;
    latestQhpEffective: Date;
    sepReason: SEPType;
    verificationPending: boolean;
  };

  chipEligibility: {
    startDate: Date;
    endDate: Date;
  };

  medicaidEligibility: {
    startDate: Date;
    endDate: Date;
  };

  metalLevelOption: Array<string>; // stronger type?
  isRequestingMedicaidDetermination: Boolean;

  preliminaryMedicaidStatus: PreliminaryStatus;
  preliminaryEmergencyMedicaidStatus: PreliminaryStatus;
  preliminaryAPTCStatus: PreliminaryStatus;
  preliminaryCHIPStatus: PreliminaryStatus;
  qhpResidencyStatus: PreliminaryStatus;
  preliminaryEmergencyMedicaidStatusReason:
    | 'NOT_MEET_REQUIREMENTS_FOR_EMERGENCY_MDCAID'
    | 'DEPENDENT_CHILD_DOESNT_HAVE_MEC'
    | 'RESIDENCY_INCOME_PENDING'
    | 'APPLICANT_RECENTLY_DENIED_MEDICAID'
    | 'N_A_RULE_INDICATOR_IS_Y'
    | 'N_A_RULE_DOES_NOT_APPLY'
    | 'NOT_REQUESTING_FINANCIAL_ASSISTANCE'
    | 'INDIVIDUAL_NOT_REQUESTING_COVERAGE';

  qhpLawfulPresenceStatus: 'NOT_APPLICABLE' | 'YES' | 'NO' | 'DELAY' | 'ERROR' | 'PENDING';

  chipWaitingPeriodStatus: 'NOT_APPLICABLE' | 'YES' | 'NO' | 'TEMP';

  chipWaitingPeriodStatusReason:
    | 'CHIP_WAITING_PERIOD_IN_EFFECT'
    | 'N_A_RULE_DOES_NOT_APPLY'
    | 'NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE'
    | 'APPLICANT_NOT_PRELIM_CHIP_ELIGIBLE'
    | 'APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS'
    | 'N_A_RULE_INDICATOR_IS_Y'
    | 'APPLICATION_NOT_REQUESTING_FA';

  ssnStatus:
    | 'YES'
    | 'NO'
    | 'INCONSISTENT'
    | 'PENDING'
    | 'NOT_APPLICABLE'
    | 'HOLD'
    | 'TEMPORARY'
    | 'DELAY'
    | 'ERROR'
    | 'HUB_CALL_COMPLETED';

  ssnStatusReason:
    | 'DEATH_DATA_PRESENT'
    | 'EDS_NOT_AVAILABLE'
    | 'NO_EDS_DATA_FOUND'
    | 'SSN_WAS_NOT_MATCHED_WITH_ATTESTED_INFORMATION'
    | 'SSN_NOT_VERIFIED'
    | 'HUB_DID_NOT_RESPOND'
    | 'NO_SSN_PROVIDED'
    | 'DMI_RESOLVED_BY_ESW_ADJUDICATION'
    | 'DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
    | 'ESW_EXPIRED_DMI'
    | 'ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE'
    | 'N_A_RULE_DOES_NOT_APPLY'
    | 'SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE'
    | 'SSA_DATA_MISMATCH'
    | 'N_A_QHP_STOPPED_BY_OFFLINE_PROCESS'
    | 'N_A_RULE_INDICATOR_IS_Y';

  citizenshipStatus:
    | 'NOT_APPLICABLE'
    | 'YES'
    | 'NO'
    | 'DELAY'
    | 'ERROR'
    | 'PENDING'
    | 'INCONSISTENT'
    | 'HOLD'
    | 'TEMPORARY'
    | 'HUB_CALL_COMPLETED';

  citizenshipStatusReason:
    | 'CITIZENSHIP_NOT_VERIFIED'
    | 'DEATH_DATA_PRESENT'
    | 'DHS_DATA_NOT_MATCH_ATTESTATION'
    | 'EDS_NOT_AVAILABLE'
    | 'NO_EDS_DATA_FOUND'
    | 'SSN_NOT_VERIFIED'
    | 'HUB_DID_NOT_RESPOND'
    | 'NO_SSN_PROVIDED'
    | 'DID_NOT_ATTEST_TO_NATURALIZED_CITIZENSHIP_OR_LP'
    | 'DID_NOT_PROVIDE_SSN_WITH_CITIZENSHIP_ATTESTATION'
    | 'ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS'
    | 'NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS'
    | 'VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN'
    | 'DMI_RESOLVED_BY_ESW_ADJUDICATION'
    | 'DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
    | 'NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP'
    | 'HUB_CALL_RETURNED_TRANSACTIONAL_ERROR'
    | 'ESW_EXPIRED_DM'
    | 'ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE'
    | 'ATSTD_NON_CITIZEN'
    | 'N_A_RULE_DOES_NOT_APPLY'
    | 'NO_CITIZENSHIP_ATTESTATION'
    | 'INDIVIDUAL_NOT_REQUESTING_COVERAGE'
    | 'NOT_VERIFIED_PENDING_PAPER_VERIFICATION'
    | 'SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE'
    | 'SSA_DATA_MISMATCH'
    | 'DHS_BIRTHDATE_MISMATCH'
    | 'DHS_DOCUMENT_NUMBER_MISMATCH'
    | 'APPLICATION_REQUIRES_STEP_3'
    | 'APPLICATION_PUSHED_TO_STEP_2_BUT_HUB_CALL_FAILED'
    | 'N_A_QHP_STOPPED_BY_OFFLINE_PROCESS'
    | 'N_A_RULE_INDICATOR_IS_Y'
    | 'N_A';

  fiveYearBarStatus: 'YES' | 'NO' | 'NOT_APPLICABLE' | 'HOLD';

  fiveYearBarStatusReason:
    | 'FIVE_YEAR_BAR_IN_EFFECT'
    | 'SAVE_VERIFICATION_PENDING'
    | 'EDS_NOT_AVAILABLE'
    | 'DID_NOT_ATTEST_TO_NATURALIZED_CITIZENSHIP_OR_LP'
    | 'ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS'
    | 'NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS'
    | 'DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
    | 'HUB_CALL_RETURNED_TRANSACTIONAL_ERROR'
    | 'FIVE_YEAR_BAR_IS_PENDING'
    | 'N_A_RULE_DOES_NOT_APPLY'
    | 'APPLICANT_ATSTD_CITIZEN'
    | 'HUB_INDICATES_NOT_LPV_OR_QNC'
    | 'HUB_CALL_SUCCESSFUL_NO_DATA_FOUND'
    | 'NOT_ATSTD_IMMIGRANT_OR_CITIZEN'
    | 'ELGBL_UNDER_CHIPRA'
    | 'HUB_QNC_INDICATOR_IS_PENDING'
    | 'HUB_QNC_INDICATOR_IS_N'
    | 'SUBJECT_TO_7_YEAR_LIMIT_RULE'
    | 'ATSTD_VETERAN'
    | 'FIVE_YEAR_BAR_DOES_NOT_APPLY'
    | 'NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB'
    | 'FIVE_YEAR_BAR_NOT_MET_WITH_ATSTD_GRANT_DATE'
    | 'FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE'
    | 'NOT_MDCAID_OR_CHIP_INCOME_ELGBL'
    | 'N_A_RULE_INDICATOR_IS_Y';

  incarcerationStatus: PreliminaryStatus;

  incarcerationStatusReason:
    | 'NOT_COMPATIBLE_WITH_IDS_DATA_SOURCE'
    | 'ONLY_SSA_DATA_AVAILABLE_BUT_NOT_COMPATIBLE'
    | 'LP_REQUIREMENTS_NOT_MET'
    | 'NO_SSA_DATA_FOUND_LEGACY'
    | 'EDS_NOT_AVAILABLE'
    | 'NO_SSA_DATA_FOUND_RESULT_BASED_ON_ATTESTATION'
    | 'HUB_DID_NOT_RESPOND'
    | 'DMI_RESOLVED_BY_ESW_ADJUDICATION'
    | 'DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
    | 'ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE'
    | 'ESW_EXPIRED_DMI'
    | 'ATSTD_NON_CITIZEN'
    | 'HUB_CALL_HELD_DUE_TO_SSN'
    | 'SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE'
    | 'N_A_RULE_DOES_NOT_APPLY'
    | 'SSA_INDICATES_APPLICANT_INCARCERATED'
    | 'HUB_NOT_CALLED_ESD_RESOLVED_WITHIN_LAST_2_YEARS'
    | 'N_A_QHP_STOPPED_BY_OFFLINE_PROCESS'
    | 'N_A';

  preliminaryQHPStatus: PreliminaryStatus;
  isInfoChangeSinceMedicaidEnded: Boolean;
  isMedicaidEnd: Boolean;
  medicaidEndDate: Date;
  isEnrolledInIchra: Boolean;
  isOfferedIchra: Boolean;
  hraOffers: Array<HRAOffer>;
  isPrefill: boolean;
  healthInsuranceEnrollmentID: string;
  healthEnrollmentGroupID: string;
  dentalEnrollmentGroupID: string;
  lastTobaccoUseDate: Date;
  uiQuestionsToDisplay: JSON;
  memberStateExchangeFields: {
    isPrimary: boolean;
    isMedicaidPartAEligible: boolean;
    isPrimaryTaxFiler: boolean;
    isRequiredToFileTaxes: boolean;
    isClaimedAsTaxDependent: boolean;
  };
}
