import React, { useState, useMemo } from 'react';
import {
  useQuery,
  PreviousApplicationsQuery,
  ExistingApplicationStatus,
  HealthApplicationQuery,
  useMutation,
  ImportPreviousApplication,
  HealthApplicationStatus,
} from '@data';
import {
  STATES,
  Segment,
  SegmentEvent,
  goToHealthApp,
  handleHealthErrors,
  safeFormatDate,
  useCopy,
} from '@app/utils';
import { SplitFormBlueprint } from '@blueprints';
import { navigate, replace } from '@navigate';
import { FontSize, Route } from '@types';
import { Link } from '@app/_ui-kit';
import { Donut } from '@app/charts/Donut';

const ApplicationImport = ({ applicationID, coverageYear, coverageState }) => {
  const [manual, setManual] = useState(false);
  const { c } = useCopy('catch.ede.ApplicationImport');

  const { data: app } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { loading, data } = useQuery(PreviousApplicationsQuery, {
    skip: !coverageYear,
    variables: {
      input: {
        coverageYear: coverageYear || 0,
        state: coverageState || '',
      },
    },
    onCompleted: () => {
      Segment.track(SegmentEvent.EXISTING_EDE_APP_FOUND, {
        coverage_year: coverageYear,
      });
    },
  });

  const { priorApplications, priorApplication, isSameYear } = useMemo(() => {
    const apps =
      data?.viewerTwo?.health?.previousApplications?.existingApplicationsInformation || [];

    return {
      priorApplications: apps,
      priorApplication: apps?.[0],
      isSameYear: apps?.[0]?.coverageYearNumber === coverageYear,
    };
  }, [data]);

  const [importApplication, { loading: importing }] = useMutation(ImportPreviousApplication, {
    onCompleted: ({ importPreviousApplication }) => {
      if (importPreviousApplication?.coverageYearNumber) {
        Segment.track(SegmentEvent.EXISTING_EDE_APP_IMPORTED, {
          coverage_year: importPreviousApplication?.coverageYearNumber,
        });
      }

      const lastUsedRoute =
        importPreviousApplication.status === HealthApplicationStatus.Submitted
          ? 'review'
          : 'confirm-applicants';

      goToHealthApp({ ...importPreviousApplication, lastUsedRoute }, { goTo: replace });
    },
    ...handleHealthErrors,
  });

  const onSubmit = (values) => {
    const selectedAppID = manual ? values.manualApplicationID : values.applicationID;
    const { isWindowShopping, oeCoverageYear } = app?.reference?.health?.openEnrollmentDates || {};
    const previousApp = priorApplications?.find(
      (app) => app.applicationIdentifier === selectedAppID,
    );

    if (!previousApp) {
      console.log('Something went wrong');
      return;
    }

    // when applying for OE year, but it's still window shopping period
    if (coverageYear === oeCoverageYear && isWindowShopping) {
      navigate(Route.EDE_WINDOW_SHOPPING);
    } else {
      importApplication({
        refetchQueries: ['PreviousApplications'],
        variables: {
          id: selectedAppID,
          isPriorYear: coverageYear > previousApp?.coverageYearNumber,
          enrollmentId: app?.viewerTwo?.health?.application?.enrollment?.id,
          coverageYear,
        },
      });
    }
  };

  const fields = [
    manual
      ? {
          type: 'text',
          name: 'manualApplicationID',
          required: true,
          label: 'Application identifier',
          help: 'After you apply for Marketplace coverage, you’ll get a notice with your eligibility results that contains your Application ID.',
        }
      : {
          type: 'option',
          name: 'applicationID',
          required: true,
          label: 'Prior application',
          options: priorApplications?.map((app) => ({
            asset: {
              element: (
                <Donut
                  percent={
                    app.applicationStatus === ExistingApplicationStatus.InProgress ? 0.35 : 0.95
                  }
                  color="coverage"
                />
              ),
            },
            label: `${app.coverageYearNumber} ${STATES[app.state]}`,
            description: `Updated ${safeFormatDate(app.lastUpdated)}`,
            value: app?.applicationIdentifier,
          })),
        },
  ];

  return (
    <SplitFormBlueprint
      title={isSameYear ? c('currentTitle') : c('pastTitle')}
      subtitle={isSameYear ? c('currentSubtitle') : c('pastSubtitle')}
      loading={loading}
      submitting={!!importing}
      formConfig={{
        autoSubmit: false,
        fields,
        initialValues: { applicationID: priorApplication?.applicationIdentifier },
        onSubmit,
      }}
      postcontent={
        <Link testID="manual-import" size={FontSize.fp} onPress={() => setManual(!manual)}>
          {manual ? 'Back' : c('manualLink') || 'Not seeing your application?'}
        </Link>
      }
    />
  );
};

export const ApplicationImportView = {
  name: 'EDE_APPLICATION_IMPORT',
  component: ApplicationImport,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Application',
  },
};
