import React, { useMemo, useContext } from 'react';
import { generatePath, useLocation, Location } from 'react-router-dom';
import { Route } from '@types';
import { paths } from '../paths';

interface LocationOverrideProps {
  route: Route;
}

const LocationOverrideContext = React.createContext({
  pathname: '',
  override: false,
});

/**
 * Provides a way to override the return from useLocation hook
 * Especially helpful for cases where we want routing based on something else
 * e.g. for modals, we want to take the URL search params and convert them into a "route"
 */
const LocationOverride: React.FC<LocationOverrideProps> = ({ route, children }) => {
  const location: Location = useLocation();

  const pathname = useMemo(() => {
    return route ? generatePath(paths[route], location.state) : location.pathname;
  }, [route]);

  const value = {
    ...location,
    pathname,
    override: !!route,
    layout: route ? 'sheet' : 'page',
  };

  return (
    <LocationOverrideContext.Provider value={value}>{children}</LocationOverrideContext.Provider>
  );
};

export const useLocationOverride = () => {
  const location = useLocation();
  const override = useContext(LocationOverrideContext);
  return override.override ? override : location;
};

export default LocationOverride;
