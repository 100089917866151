import { UniversalTextProps } from '@app/types';
import { Theme } from '../types';

export interface ApplyFontStylesProps extends UniversalTextProps {
  theme: Theme;
}

export interface ApplyGradientProps {
  $colors: [string, string];
  $angle?: number;
  $locations?: Array<number>;
}

export const applyFontStyles = ({
  theme,
  weight,
  size,
  align,
  color,
  family,
}: ApplyFontStylesProps) => `
  ${align ? `text-align: ${align};` : ''}
  ${size ? `font-size: ${theme.fonts.sizes[size]}px;` : ''}
  ${weight ? `font-weight: ${theme.fonts.weights[weight]};` : ''}
  ${color ? `color: ${theme.colors[color]};` : ''}
  ${family ? `font-family: ${family};` : ''}
`;

export const applyGradient = ({ $angle = 160, $locations, $colors }: ApplyGradientProps) => {
  const angle = `${$angle}deg`;

  const colors = $colors.reduce((acc, c, idx) => {
    const loc = $locations?.[idx];
    const color = `${c}${loc ? ` ${loc}%` : ''}`;
    return acc ? `${acc}, ${color}` : color;
  }, '');

  return `linear-gradient(${angle}, ${colors})`;
};
