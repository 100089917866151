import React, { useMemo } from 'react';
import { Leaves, Link } from '@uikit';
import { useCopy, openIntercom, formatISO } from '@app/utils';
import { currentYear } from '@app/utils';
import { exit } from '@navigate';
import { HiccupBlueprint } from '@blueprints';
import { queries, useQuery } from '@data';
import { Route } from '@app/types';

const SEPIneligible = ({ handleNext, explorer = false }) => {
  const { c } = useCopy('catch.ede.sepOutDate');
  const { c: explorerCopy } = useCopy('catch.healthExplorer.SEPIneligible');
  const { c: basics } = useCopy('catch.basics');
  const { c: healthBasics } = useCopy('catch.healthBasics');

  const { data } = useQuery(queries.OPEN_ENROLLMENT);

  const { isOpenEnrollment, startDate, endDate, nextOEYear } = useMemo(() => {
    const oeDates = data?.reference?.health?.openEnrollmentDates;

    return {
      isOpenEnrollment: oeDates?.isOpenEnrollment,
      startDate: oeDates?.startDate,
      endDate: oeDates?.endDate,
      nextOEYear: oeDates?.nextOEYear,
    };
  }, [data?.reference?.health?.openEnrollmentDates]);

  return (
    <HiccupBlueprint
      asset={{ icon: Leaves }}
      onPress={handleNext ? handleNext : () => exit(Route.COVERAGE)}
      buttonText={!isOpenEnrollment ? basics('gotIt') : healthBasics('applyNow')}
      title={c('title', {
        startDate: formatISO(startDate, 'MMMM d'),
        isOpenEnrollment,
        nextYear: nextOEYear,
      })}
      subtitles={[
        explorer
          ? explorerCopy('p1')
          : c('p1', {
              isOpenEnrollment,
              endDate: formatISO(endDate, 'MMMM d'),
              currentYear,
            }),
        explorer
          ? explorerCopy('p2')
          : c('p2', {
              isOpenEnrollment,
              nextYear: nextOEYear,
              link: (
                <Link testID="support" onPress={openIntercom}>
                  {c('p2.link')}
                </Link>
              ),
            }),
      ]}
    />
  );
};

export default SEPIneligible;
