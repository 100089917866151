import React from 'react';
import { View } from 'react-native';
import DonutChart from '@app/charts/base/Donut';
import { useCopy } from '@app/utils';
import { GuideBlueprint } from '@blueprints';
import { Stack } from '@layouts';
import { SheetComponentProps, sheets } from '@navigate';
import { useTheme, LineItem, Loading, FinePrint } from '@uikit';

const DIGITS = 1;
const NAME = sheets.TAX_BREAKDOWN_GUIDE;

const TaxBreakdownGuideSheet: React.FC<SheetComponentProps['TAX_BREAKDOWN_GUIDE']> = ({
  calculation,
}) => {
  const { c, p } = useCopy('catch.module.taxes.TaxesBreakdown');
  const { theme } = useTheme();

  const loading = (
    <GuideBlueprint name={NAME} subtitle={c('description')} title={c('title')}>
      <Loading accentColor="taxes" />
    </GuideBlueprint>
  );

  if (!calculation) {
    return loading;
  }

  const { estimatedPaycheckPercentage, totalLiability, userResponsibilities } = calculation;

  if (!userResponsibilities) {
    return loading;
  }

  const { federalLiability, medicareLiability, socialSecurityLiability } = userResponsibilities;

  let chartData: number[] = [];
  let legendData: {
    label: string;
    percentage: number;
    qaName: string;
  }[] = [];

  if (federalLiability > 0) {
    const percentage = (federalLiability / totalLiability) * estimatedPaycheckPercentage;
    chartData.push(percentage);
    legendData.push({
      label: 'federalTax',
      percentage,
      qaName: 'federalLiability',
    });
  }

  if (medicareLiability > 0) {
    const percentage = (medicareLiability / totalLiability) * estimatedPaycheckPercentage;
    chartData.push(percentage);
    legendData.push({
      label: 'medicare',
      percentage,
      qaName: 'medicareLiability',
    });
  }

  if (socialSecurityLiability > 0) {
    const percentage = (socialSecurityLiability / totalLiability) * estimatedPaycheckPercentage;
    chartData.push(percentage);
    legendData.push({
      label: 'socialSecurity',
      percentage,
      qaName: 'socialSecurityLiability',
    });
  }

  const sum = chartData.reduce((acc, val) => acc + val, 0);
  const remainder = 1 - sum;
  const chartDataWithRemainder = [...chartData, remainder];

  return (
    <GuideBlueprint name={NAME} subtitle={c('description')} title={c('title')}>
      <Stack spacing="2">
        <View style={theme.alignCenter}>
          <DonutChart
            data={chartDataWithRemainder.map((num, idx) => ({
              color: idx < chartDataWithRemainder?.length - 1 ? 'taxes' : 'border',
              x: idx,
              y: num * 100,
            }))}
            size={140}
            text={p(sum, { minimumFractionDigits: DIGITS, maximumFractionDigits: DIGITS })}
          />
        </View>
        <Stack spacing="1" separatorComponent>
          {legendData.map((ld, i) => (
            <LineItem
              key={i}
              left={c('label.' + ld.label)}
              right={p(ld.percentage, {
                minimumFractionDigits: DIGITS,
                maximumFractionDigits: DIGITS,
              })}
              prominent
              testID={ld.qaName}
            />
          ))}
        </Stack>
        <FinePrint items={[c('disclaimer1'), c('disclaimer2')]} />
      </Stack>
    </GuideBlueprint>
  );
};

export default TaxBreakdownGuideSheet;
