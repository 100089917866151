// Lazy loading all the components so no user needs to load all the 50 states in order to see theirs
import Alabama from './states/Alabama';
import Alaska from './states/Alaska';
import Arizona from './states/Arizona';
import Arkansas from './states/Arkansas';
import California from './states/California';
import Colorado from './states/Colorado';
import Connecticut from './states/Connecticut';
import Delaware from './states/Delaware';
import DistrictofColumbia from './states/DistrictofColumbia';
import Florida from './states/Florida';
import Georgia from './states/Georgia';
import Hawaii from './states/Hawaii';
import Idaho from './states/Idaho';
import Illinois from './states/Illinois';
import Indiana from './states/Indiana';
import Iowa from './states/Iowa';
import Kansas from './states/Kansas';
import Kentucky from './states/Kentucky';
import Louisiana from './states/Louisiana';
import Maine from './states/Maine';
import Maryland from './states/Maryland';
import Massachusetts from './states/Massachusetts';
import Michigan from './states/Michigan';
import Minnesota from './states/Minnesota';
import Mississippi from './states/Mississippi';
import Missouri from './states/Missouri';
import Montana from './states/Montana';
import Nebraska from './states/Nebraska';
import Nevada from './states/Nevada';
import NewHampshire from './states/NewHampshire';
import NewJersey from './states/NewJersey';
import NewMexico from './states/NewMexico';
import NewYork from './states/NewYork';
import NorthCarolina from './states/NorthCarolina';
import NorthDakota from './states/NorthDakota';
import Ohio from './states/Ohio';
import Oklahoma from './states/Oklahoma';
import Oregon from './states/Oregon';
import Pennsylvania from './states/Pennsylvania';
import PuertoRico from './states/PuertoRico';
import RhodeIsland from './states/RhodeIsland';
import SouthCarolina from './states/SouthCarolina';
import SouthDakota from './states/SouthDakota';
import Tennessee from './states/Tennessee';
import Texas from './states/Texas';
import Utah from './states/Utah';
import Vermont from './states/Vermont';
import Virginia from './states/Virginia';
import Washington from './states/Washington';
import WestVirginia from './states/WestVirginia';
import Wisconsin from './states/Wisconsin';
import Wyoming from './states/Wyoming';

export const stateIcons = {
  AL: Alabama,
  AK: Alaska,
  AZ: Arizona,
  AR: Arkansas,
  CA: California,
  CO: Colorado,
  CT: Connecticut,
  DE: Delaware,
  DC: DistrictofColumbia,
  FL: Florida,
  GA: Georgia,
  HI: Hawaii,
  ID: Idaho,
  IL: Illinois,
  IN: Indiana,
  IA: Iowa,
  KS: Kansas,
  KY: Kentucky,
  LA: Louisiana,
  ME: Maine,
  MD: Maryland,
  MA: Massachusetts,
  MI: Michigan,
  MN: Minnesota,
  MS: Mississippi,
  MO: Missouri,
  MT: Montana,
  NE: Nebraska,
  NV: Nevada,
  NH: NewHampshire,
  NJ: NewJersey,
  NM: NewMexico,
  NY: NewYork,
  NC: NorthCarolina,
  ND: NorthDakota,
  OH: Ohio,
  OK: Oklahoma,
  OR: Oregon,
  PA: Pennsylvania,
  PR: PuertoRico,
  RI: RhodeIsland,
  SC: SouthCarolina,
  SD: SouthDakota,
  TN: Tennessee,
  TX: Texas,
  UT: Utah,
  VT: Vermont,
  VA: Virginia,
  WA: Washington,
  WV: WestVirginia,
  WI: Wisconsin,
  WY: Wyoming,
};
