import { useMemo } from 'react';
import {
  useQuery,
  NotificationSettingsQuery,
  useMutation,
  UpdateNotificationSettings,
  UpdateNotificationsCache,
} from '@data';

export const useTextOptIn = () => {
  const { loading, data } = useQuery(NotificationSettingsQuery);
  const [upsert] = useMutation(UpdateNotificationSettings, {
    update: UpdateNotificationsCache,
  });

  function update() {
    upsert({
      variables: {
        input: {
          textNotificationSettings: {
            healthInsurance: true,
          },
        },
      },
    });
  }

  const healthInsuranceTexts = useMemo(() => {
    return data?.viewer?.notificationSettings?.text?.healthInsurance;
  }, [data]);

  return {
    loading,
    update,
    showOptIn: !healthInsuranceTexts,
  };
};
