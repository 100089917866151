import React from 'react';
import styled from 'styled-components';
import { View } from 'react-native';
import { Card, Stack } from '@app/layouts';
import { Text, Link, HelpText } from '@uikit';
import { useCopy } from '@app/utils';

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IncomeDetailsCard = ({ children, values, remove }) => {
  const { c } = useCopy('catch.ede.enums');

  const help = c(`${values.type}.help`, { fullName: values.fullName });

  return (
    <Card>
      <Flex>
        <Text size="lg">
          {c(values.type)}
          <Text size="lg" color="subtle" gutter="left">
            {values.employerName}
          </Text>
        </Text>

        {help && <HelpText content={help} />}
      </Flex>

      <Stack spacing="2" style={{ paddingTop: 16 }}>
        <View>{children}</View>
      </Stack>
      <Link testID="remove" onPress={remove}>
        {c('remove')}
      </Link>
    </Card>
  );
};

export default IncomeDetailsCard;
