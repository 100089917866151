import { LineItem, Text } from '@uikit';
import { useCopy } from '@app/utils';
import { Section, Stack } from '@app/layouts';
import { GoalFragment } from '@app/data';

export interface GoalDetailsTabProps {
  goal: GoalFragment;
}

export function GoalDetailsTab({ goal }: GoalDetailsTabProps) {
  const { $ } = useCopy('catch');

  // @todo allow goal edit
  const handleEdit = () => {}; // open(routes.GOAL_EDIT, { slug: goal.slug });

  return (
    <Stack spacing="1" mobileGap>
      <Section>
        <Stack spacing="1">
          <LineItem left="Pending" right={$(goal?.pendingBalance)} />
          <LineItem
            left="Setting aside"
            right={
              <Text underline onPress={handleEdit} color="text">
                {goal?.withholdingPercentage}%
              </Text>
            }
          />
        </Stack>
      </Section>
    </Stack>
  );
}
