import React from 'react';
import { View } from 'react-native';
import { Card, Stack } from '@app/layouts';
import { Text, Link } from '@uikit';
import { useCopy } from '@app/utils';

const OtherCoverageCard = ({ children, values, remove }) => {
  const { c } = useCopy('catch.ede.enums');

  return (
    <View style={{ marginBottom: 16 }}>
      <Card border style={{ paddingVertical: 16 }}>
        <Text size="lg">{c(values.insuranceMarketType)}</Text>
        <Stack spacing="2" style={{ paddingTop: 16 }}>
          <View>{children}</View>
        </Stack>
        <Link onPress={remove}>Remove</Link>
      </Card>
    </View>
  );
};

export default OtherCoverageCard;
