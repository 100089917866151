import React, { useMemo } from 'react';
import { Button } from '@uikit';
import Plus from '@svg/plus.svg';
import { Stack } from '@app/layouts';
import { useCopy } from '@app/utils';

const ExplorerApplicantsFooter: React.FC = ({ values, append, disabled }) => {
  const { c } = useCopy();
  const hasSpouse = useMemo(() => {
    return values?.applicants?.some((m) => m.relation === 'SPOUSE');
  }, [values]);

  return (
    <Stack horizontal spacing="1">
      {!hasSpouse && (
        <Button
          onPress={() => append({ relation: 'SPOUSE' })}
          small
          testID="add-spouse"
          svg={Plus}
          alt
          disabled={disabled}
        >
          {c('add-spouse')}
        </Button>
      )}
      <Button
        onPress={() => append({ relation: 'CHILD' })}
        small
        testID="add-child"
        svg={Plus}
        alt
        disabled={disabled}
      >
        {c('add-child')}
      </Button>
    </Stack>
  );
};

export default ExplorerApplicantsFooter;
