import { useEffect, useMemo } from 'react';
import {
  useQuery,
  useDeprecatedMutation,
  HealthProgressLookupQuery,
  HealthPathwayType,
  GET_CURRENT_USER,
} from '@data';
import { navigate } from '@navigate';
import { chooseHealthApp, currentYear, goToHealthApp, hasAgreedToPrivacy } from '@app/utils';
import { Partner, Route } from '@types';
import { useHealthStore } from './useHealthStore';

export const useHealthStart = ({ defaultRoute }: { defaultRoute: Route }) => {
  const { setHealthContext } = useHealthStore();
  const user = useQuery(GET_CURRENT_USER);

  const lookup = useQuery(HealthProgressLookupQuery, {
    variables: { coverageYears: [currentYear, currentYear + 1] },
  });

  const hasAgreed = useMemo(() => {
    return hasAgreedToPrivacy(lookup?.data?.viewerTwo?.agreements);
  }, [lookup?.data?.viewerTwo?.agreements]);

  const [createEnrollment, { loading: creating }] = useDeprecatedMutation(
    'upsertEnrollmentFromExplorer',
    {
      onCompleted: (enrollment) => {
        goToHealthApp(enrollment.healthApplication, { hasAgreed });
      },
    },
  );

  const healthApplications = useMemo(() => {
    const { isOpenEnrollment, oeCoverageYear } =
      lookup?.data?.reference?.health?.openEnrollmentDates || {};
    const apps = lookup?.data?.viewerTwo?.health?.applications || [];

    if (isOpenEnrollment) {
      // only OE apps are eligible for auto resuming
      return apps.filter((app) => app.coverageYearNumber === oeCoverageYear);
    } else {
      return apps;
    }
  }, [lookup?.data?.viewerTwo?.health?.applications]);

  const { healthExplorerData } = lookup?.data?.viewerTwo || {};
  const bestApp = chooseHealthApp(healthApplications);

  useEffect(() => {
    // when there is no application, we need to rely on the explorer data to set health context
    if (healthExplorerData && !bestApp) {
      if (healthExplorerData?.pathway === HealthPathwayType.StateApp && healthExplorerData?.state) {
        setHealthContext(healthExplorerData?.state);
      } else if (healthExplorerData?.state === 'GA') {
        setHealthContext('GA');
      } else {
        setHealthContext('FFM');
      }
    }
  }, [lookup]);

  /**
   * - If app ID is set, go to the next screen of application
   * - Otherwise, default to a new application from list or create new
   */
  const handleNext = () => {
    const sendToHealthsherpa =
      user?.data?.me?.user?.signupCode === Partner.decisely &&
      healthExplorerData?.pathway === HealthPathwayType.Ffm;

    if (bestApp) {
      goToHealthApp(bestApp);
    } else if (sendToHealthsherpa) {
      navigate(Route.EXPLORER_PARTNER_HANDOFF);
    } else if (healthExplorerData?.canCreateEnrollment) {
      // @ts-ignore
      createEnrollment();
    } else if (healthExplorerData?.pathway === HealthPathwayType.Sbm) {
      navigate(Route.EXPLORER_ZIPCODE);
    } else {
      // navigate to the intro screen unless we're already there
      navigate(defaultRoute || Route.EDE_INTRO);
    }
  };

  const coverageState = bestApp?.coverageState || healthExplorerData?.state;

  return {
    loading: lookup.loading,
    creating,
    handleNext,
    foundApp: healthApplications?.length > 0,
    coverageState,
  };
};
