import { gql } from '../generated';

export const GET_MONEY_ACTIVITIES = gql(`
  query GetMoneyActivities {
    activities {
      activityData {
        ... on BankTransferActivity {
          id
          amount
          dateInitialized
          direction
          status
        }
        ... on IncomeApprovedActivity {
          id
          amount
          dateInitialized
          direction
          fromAccount
          goalBreakdown {
            amount
            name
          }
          incomeSource {
            logoUrl
            subtag
            text
          }
          memo
          status
          toAccount
          transferId
        }
        ... on IncomeSkippedActivity {
          id
          amount
          dateSkipped
          incomeSource {
            logoUrl
            subtag
            text
          }
        }
      }
    }
  }
`);

/**
 * Note: unlike some of the other definitions, this one
 * is page specific instead of model specific
 */
export const GET_MONEY_PAGE = gql(`
  query GetMoneyPage {
    bankAccount {
      ...BankAccount
    }
    bankApplication {
      id
      status
    }
    goals {
      ...Goal
    }
    linkedAccounts {
      ...LinkedAccount
    }
  }
`);
