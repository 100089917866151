import React from 'react';
import styled from 'styled-components';
import { Text, Link } from '@uikit';
import { Narrow, Page } from '@layouts';
import { useCopy } from '@app/utils';
import { navigate } from '@navigate';
import { Route } from '@types';

const Container = styled.div`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function NotFoundPage() {
  const { c } = useCopy('catch.web.notFound');
  return (
    <Page color="page">
      <Narrow>
        <Container>
          <Text size="h1"> {c('title')}</Text>
          <Link testID="home" onPress={() => navigate(Route.HOME)}>
            {c('redirect')}
          </Link>
        </Container>
      </Narrow>
    </Page>
  );
}
