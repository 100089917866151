import React from 'react';

import { Text } from '@uikit';
import { useCopy } from '@app/utils';
import { FontSize, TextColor, TextAlign, FontWeight } from '@types';

interface Props {
  premium: number;
  size?: FontSize;
  color?: TextColor;
  align?: TextAlign;
  weight?: FontWeight;
  additionalComponents: any;
}

/** Renders a health plan premium */
const HealthPlanPremium = ({
  premium,
  size = 'p',
  color = 'text',
  align,
  weight = 'medium',
  additionalComponents,
}: Props) => {
  const { c, $ } = useCopy('catch.period');

  return (
    <Text size={size} color="subtle" align={align}>
      <Text size={size} color={color} weight={weight}>
        {$(premium)}
      </Text>
      {c('monthly')}
      {additionalComponents}
    </Text>
  );
};

export default HealthPlanPremium;
