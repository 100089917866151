import React from 'react';
import { styled } from 'styled-components';

export interface PageProps {
  children?: React.ReactNode;
}

const StyledPage = styled.div`
  display: flex;
  flex: 1;
  padding-top: 0;
  flex-direction: column;
  overflow-x: hidden;
  padding-top: 64px;
  margin-top: -64px;
`;

const Page: React.FC<PageProps> = ({ children }) => {
  return <StyledPage>{children}</StyledPage>;
};

export default Page;
