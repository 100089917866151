import createIconComponent from '../../tools/createIconComponent';

/**
 * @deprecated
 * use iconic svg icons
 */
const Wyoming = createIconComponent({
  content: [
    {
      type: 'title',
    },
    {
      type: 'path',
      d: 'M234.358 50.4277H20.9463V203.674H234.358V50.4277Z',
      colorable: true,
      fill: '#1F2533',
    },
  ],
  name: 'Wyoming',
  width: '255',
  height: '254',
  viewBox: '0 0 255 254',
});
Wyoming.displayName = 'Wyoming';

/** @deprecated */
export default Wyoming;
