import React from 'react';
import { View } from 'react-native';
import { Text } from './Text';

export const Address = ({ street1, street2, city, state, zip, zip4, size, align, textProps }) => {
  const hasAddress = !!street1;
  return hasAddress ? (
    <View>
      <Text {...textProps} size={size} align={align}>
        {street1}
      </Text>
      {street2 && (
        <Text {...textProps} size={size} align={align}>
          {street2}
        </Text>
      )}
      <Text {...textProps} size={size}>
        {city ? `${city}, ${state} ${zip}` : null}
        {zip4 ? `-${zip4}` : null}
      </Text>
    </View>
  ) : (
    <View>
      <Text {...textProps} color="urgent" size="fp">
        Please fill in missing address information
      </Text>
    </View>
  );
};

export default Address;
