import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTheme } from '../hooks/useTheme';
import { CatchTheme } from '../themes/catch';
import { UIGradientName } from '../types';
import { applyGradient } from '@app/styles';

type GradientType = 'toolbar' | 'icon' | 'button';

export interface GradientProps {
  type?: GradientType;
  angle?: number;
  color?: string;
  color2?: string;
  gradient?: UIGradientName;
  style?: Array<object> | object;
  show?: boolean;
  children?: React.ReactNode;
}

const locations: Record<GradientType, Array<number>> = {
  toolbar: [0, 35],
  icon: [0, 100],
  button: [0, 100],
};

const angles: Record<GradientType, number> = {
  toolbar: 180,
  button: 180,
  icon: 315, // top right to bottom left
};

const LinearGradient = styled.div<{
  $angle: number;
  $colors: [string, string];
  $locations?: Array<number>;
}>`
  background-image: ${({ $angle, $locations, $colors }) =>
    applyGradient({ $angle, $locations, $colors })};
`;

type Style = object | boolean | undefined;

const translateStylesForDiv = (styles: Array<Style> | Style) => {
  const defaultStyles = { display: 'flex' };

  if (Array.isArray(styles)) {
    return styles.reduce((acc, s) => {
      const isValid = typeof s === 'object';
      return isValid ? { ...acc, ...s } : acc;
    }, defaultStyles);
  }

  if (typeof styles === 'object') {
    return { ...defaultStyles, ...styles };
  }

  return defaultStyles;
};

export const Gradient: React.FC<GradientProps> = ({
  type,
  angle = 160,
  color,
  color2,
  gradient,
  style,
  children,
  show = true,
}) => {
  const { themeColors, colorScheme } = useTheme();
  const _color = themeColors[`${color}Color`];
  const _color2 = themeColors[`${color2}Color`];

  const _gradientColors = useMemo(() => {
    const gradients = CatchTheme.gradients[colorScheme];

    // prefers the icon gradient, if defined
    if ((type === 'icon' || type === 'button') && `${gradient}Small` in gradients) {
      return gradients[`${gradient}Small`];
    }

    // otherwise, lookup the gradient by name
    return gradients[gradient];
  }, [colorScheme, gradient, type]);

  const hasColor = !!_color;
  const hasGradient = !!_gradientColors;

  const showGradient = (hasColor || hasGradient) && show;
  const styles = translateStylesForDiv(style);

  return showGradient ? (
    <LinearGradient
      $angle={!!gradient ? (type ? angles[type] : angle) : 180}
      $locations={type ? locations[type] : undefined}
      $colors={!!gradient ? _gradientColors : [_color, _color2 || themeColors.pageColor]}
      style={styles}
    >
      {children}
    </LinearGradient>
  ) : (
    <div style={styles}>{children}</div>
  );
};

export default Gradient;
