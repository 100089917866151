import React from 'react';
import { FormattedDate } from 'react-intl';
import { toDate } from '../../format_old/toDate';

const LongFormDate = ({ children, shortMonth }) => {
  return (
    <FormattedDate
      value={toDate(children)}
      year="numeric"
      month={shortMonth ? 'short' : 'long'}
      day="2-digit"
    />
  );
};

export default LongFormDate;
