import gql from 'graphql-tag';

export const AnswerIdentityQuestions = gql`
  mutation CheckIdentity($input: CheckIdentityInput!) {
    checkIdentity(input: $input) {
      identity {
        id
        providerType
        transactionID
        sessionID
        confirmationID
        isTransactionIDExpired
        finalDecisionCode
        status
        quizAttempts
        identityAttempts
      }
    }
  }
`;
