import React, { useCallback } from 'react';
import {
  navigate,
  useCurrentRoute,
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  exit,
} from '@navigate';
import { useHealthLink } from '@app/hooks';
import { HealthLinkAgreementsView } from './routes/HealthLinkAgreementsView';
import { HealthLinkIdentityView } from './routes/HealthLinkIdentityView';
import { HealthLinkIntroView } from './routes/HealthLinkIntroView';
import { HealthLinkImportView } from './routes/HealthLinkImportView';
import { Route } from '@types';

const config: StackDefinition = {
  stackName: stacks.HEALTH_LINK_STACK,
  options: { layout: 'page', navMode: 'flow', accentColor: 'coverage' },
  screens: [
    HealthLinkIntroView,
    HealthLinkAgreementsView,
    HealthLinkIdentityView,
    HealthLinkImportView,
  ],
};

/**
 *
 *
 * F A S T L I N K ~~>
 * Health Link Stack
 *
 * @see https://www.figma.com/file/CJexcruPziVz3e8B219TSP/Health-Link
 * @see https://www.notion.so/catchco/Fastlink-Beta-133739dc638944599fe5721aefc76909
 *
 */
const HealthLinkStack: StackComponent = () => {
  const route = useCurrentRoute();
  const {
    agree,
    agreeing,
    needsAgreements,
    needsIdentity,
    importAll,
    importing,
    loading,
    confirmIdentity,
    canSearch,
    hasSearched,
    lookup,
    isSlasherFlow,
    explorerIncome,
    isOpenEnrollment,
    ...rest
  } = useHealthLink();

  // Handlers
  const handleExit = () => exit(Route.HOME);

  // Navigation
  const handleNext = useCallback(() => {
    // during OE, use the renewal import flow instead
    const importRoute = Route.HEALTH_LINK_IMPORT;
    const identityRoute = Route.HEALTH_LINK_IDENTITY;

    switch (route) {
      case Route.HEALTH_LINK_INTRO:
        navigate(
          needsAgreements
            ? Route.HEALTH_LINK_AGREEMENTS
            : !canSearch
            ? Route.ACCOUNT_SETUP_DOB
            : needsIdentity
            ? identityRoute
            : importRoute,
          { slug: 'health' },
        );
        break;
      case Route.HEALTH_LINK_AGREEMENTS:
        navigate(
          !canSearch ? Route.ACCOUNT_SETUP_DOB : needsIdentity ? identityRoute : importRoute,
          { slug: 'health' },
        );
        break;
      case Route.HEALTH_LINK_IDENTITY:
        navigate(importRoute);
        break;
      case Route.HEALTH_LINK_IMPORT:
        exit(Route.HOME);
        break;
      default:
        break;
    }
  }, [route, needsAgreements, needsIdentity, isSlasherFlow, isOpenEnrollment]);

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={{
        // @todo shared type for health link screen props (like health slasher screen props)
        loading,
        agree,
        agreeing,
        handleNext,
        handleSkip: handleExit,
        needsAgreements,
        needsIdentity,
        confirmIdentity,
        importAll,
        importing,
        canSearch,
        hasSearched,
        lookup,
        isSlasherFlow,
        explorerIncome,
        ...rest,
      }}
    />
  );
};

HealthLinkStack.config = config;
export default HealthLinkStack;
