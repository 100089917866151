import { DirectSignup, Route, UrlParams } from '@app/types';
import { create } from 'zustand';

export interface SessionStore {
  // store raw params
  params?: UrlParams;

  // computed fields
  hid?: string;
  partner?: string;
  isConcierge: boolean;
  isFastlane: boolean;
  initialRoute: Route;
  skipIntake: boolean;

  // session handlers
  setSession: (params: UrlParams) => void;
  reset: () => void;
}

export const useSession = create<SessionStore>((set, get) => ({
  params: undefined,
  hid: undefined,
  partner: undefined,
  isConcierge: false,
  isFastlane: false,
  initialRoute: Route.HOME,
  skipIntake: false,

  setSession: (params) => {
    const isConcierge = params.direct === DirectSignup.BenefitsAdvisor;
    const isFastlane = params.direct === DirectSignup.Fastlane;

    set({
      params,
      hid: params.hid,
      partner: params.r,
      isConcierge,
      isFastlane,
      skipIntake: params.skipIntake,
      initialRoute: isConcierge ? Route.EXPLORER_INTRO : Route.HOME,
    });
  },

  reset: () => {
    set({
      params: undefined,
      hid: undefined,
      partner: undefined,
      isConcierge: false,
      initialRoute: Route.HOME,
      skipIntake: false,
    });
  },
}));
