import { ExplorerEligibilityQuery, HealthPathway } from '@app/data';

export enum EligibilityScenario {
  Savings = 'Savings',
  Enroll = 'Enroll',
  Medicaid = 'Medicaid',
  Medicare = 'Medicare',
  NoPlans = 'NoPlans',
}

export enum EligibilityCallout {
  CSR = 'CSR',
  StateSubsidies = 'StateSubsidies',
  Medicaid = 'Medicaid',
  MedicareSelf = 'MedicareSelf',
  MedicareSpouse = 'MedicareSpouse',
  CHIP = 'CHIP',
}

export interface EligibilityResponse {
  scenario: EligibilityScenario;
  savings?: number;
  callouts?: Array<EligibilityCallout>;
}

const MEDICARE_AGE = 65;

export function getEligibility(data: ExplorerEligibilityQuery): EligibilityResponse {
  const pathwayType = data?.viewerTwo?.healthExplorerData?.pathwayType;
  const members = data?.viewerTwo?.healthExplorerData?.dependents || [];
  const eligibility = data?.viewerTwo?.healthExplorerData?.marketplaceEligibility || [];
  const plans = data?.viewerTwo?.healthExplorerData?.healthPlans?.plans || [];

  if (members.length !== eligibility.length) {
    throw new Error('Mismatched members and eligibility');
  }

  const joined = members.map((item, index) => ({ ...item, ...eligibility[index] }));

  // logic for special callouts
  const allMedicare = joined?.every((m) => m.age > MEDICARE_AGE);
  const allMedicaid = joined?.every((m) => m.isMedicaidChip);
  const hasAptc = joined?.some((m) => m.aptc > 0);
  const hasCSR = joined?.some((m) => !!m.csr && m.csr !== 'NONE');
  const hasMedicareSelf = joined?.some((m) => m.age >= MEDICARE_AGE && m.relation === 'SELF');
  const hasMedicareSpouse = joined?.some((m) => m.age >= MEDICARE_AGE && m.relation === 'SPOUSE');
  const hasAnyMedicaid = joined?.some((m) => m.isMedicaidChip && m.relation !== 'CHILD');
  const hasAnyChip = joined?.some((m) => m.isMedicaidChip && m.relation === 'CHILD');
  const hasStateSubsidies = pathwayType === HealthPathway.StateExchange;

  if (allMedicare) {
    return {
      scenario: EligibilityScenario.Medicare,
      callouts: [],
    };
  }

  if (allMedicaid) {
    return {
      scenario: EligibilityScenario.Medicaid,
      callouts: [],
    };
  }

  if (plans.length === 0) {
    return {
      scenario: EligibilityScenario.NoPlans,
      callouts: [],
    };
  }

  const callouts: Array<EligibilityCallout> = [];
  if (hasCSR) callouts.push(EligibilityCallout.CSR);
  if (hasStateSubsidies) callouts.push(EligibilityCallout.StateSubsidies);
  if (hasAnyMedicaid) callouts.push(EligibilityCallout.Medicaid);
  if (hasMedicareSelf) callouts.push(EligibilityCallout.MedicareSelf);
  if (hasMedicareSpouse) callouts.push(EligibilityCallout.MedicareSpouse);
  if (hasAnyChip) callouts.push(EligibilityCallout.CHIP);

  return {
    scenario: hasAptc ? EligibilityScenario.Savings : EligibilityScenario.Enroll,
    savings: joined[0].aptc || 0,
    callouts,
  };
}
