import React, { useMemo, useState } from 'react';
import { formatCostCoverage, useCopy } from '@app/utils';
import { Stack } from '@app/layouts';
import { Accordion, LineItem, DefinitionList, SegmentedControl, Text } from '@uikit';

interface CostItem {
  value: string;
  label: string;
  info?: string;
}

const BrochureSection: React.FC<{ isOpen?: boolean; title: string; items: Array<CostItem> }> = ({
  isOpen,
  title,
  items,
}) => {
  const { c } = useCopy('catch.health');
  if (items.length === 0) return null;

  return (
    <Accordion title={c(`HealthPlanDetailPage.${title}`)} initOpen={isOpen}>
      <DefinitionList>
        {items.map((item) => (
          <LineItem
            key={item?.label}
            left={<Text>{c(`benefitLabels.${item?.label}`)}</Text>}
            right={<Text>{item?.value}</Text>}
            asDefinitionList
          />
        ))}
      </DefinitionList>
    </Accordion>
  );
};

const HealthPlanCosts = ({ benefits, ratings, isDental }) => {
  const { c } = useCopy();
  const [index, setIndex] = useState<number>(0);

  const costCoverage = useMemo(() => formatCostCoverage(benefits), [benefits]);

  // gets costs before/after deductible (based on index)
  const costs = useMemo(() => {
    if (!costCoverage) return {};

    return Object.keys(costCoverage).reduce((costs, key) => {
      return {
        ...costs,
        [key]: costCoverage[key][index],
      };
    }, {});
  }, [costCoverage, index]);

  // lookup common costs from different secitons
  const commonCosts = useMemo(() => {
    const common = [];

    const primary = costs?.docVisits?.find((d) => d.label === 'PRIMARY_VISIT');
    const emergency = costs?.treatAndServices?.find((d) => d.label === 'ER_SERVICES');
    const specialist = costs?.docVisits?.find((d) => d.label === 'SPECIALIST_VISIT');
    const generic = costs?.drugs?.find((d) => d.label === 'GENERIC_DRUGS');

    if (primary) common.push(primary);
    if (emergency) common.push(emergency);
    if (specialist) common.push(specialist);
    if (generic) common.push(generic);

    return common;
  }, [costs]);

  return (
    <Stack spacing="0b">
      <SegmentedControl
        controls={[{ title: c('before-deductible') }, { title: c('after-deductible') }]}
        index={index}
        setIndex={setIndex}
      />

      <Stack spacing="1">
        {commonCosts.length > 0 && (
          <BrochureSection isOpen title="costsLabel" items={commonCosts} />
        )}

        {ratings?.available && (
          <BrochureSection
            title="ratingsLabel"
            items={[
              { label: 'overall-rating', value: `${ratings.globalRating}/5 ☆` },
              { label: 'member-experience', value: `${ratings.enrolleeExperienceRating}/5 ☆` },
              { label: 'medical-care', value: `${ratings.clinicalQualityManagementRating}/5 ☆` },
              { label: 'plan-administration', value: `${ratings.planEfficiencyRating}/5 ☆` },
            ]}
          />
        )}

        {costs.docVisits?.length > 0 && (
          <BrochureSection title="docLabel" items={costs.docVisits} />
        )}
        {costs.drugs?.length > 0 && <BrochureSection title="prescripLabel" items={costs.drugs} />}
        {costs.treatAndServices?.length > 0 && (
          <BrochureSection title="generalLabel" items={costs.treatAndServices} />
        )}
        {costs.visionDental?.length > 0 && (
          <BrochureSection title={isDental ? 'costsLabel' : 'vdLabel'} items={costs.visionDental} />
        )}
      </Stack>
    </Stack>
  );
};

export default HealthPlanCosts;
