import React, { useEffect, useState } from 'react';
import { SheetComponentProps } from '@navigate';
import { SheetBlueprint } from '@blueprints';
import { useDeprecatedMutation } from '@data';
import { getCarrierAsset, useCopy, safeFormatDate } from '@app/utils';
import { LineItem, Link, Text } from '@uikit';
import PaymentRedirectForm from '@common/sections/PaymentRedirectForm';
import { Layout, Stack } from '@app/layouts';

type RedirectStatus = 'REDIRECT' | 'CONFIRMATION' | 'NOT_SUPPORTED';

interface RedirectParams {
  url: string;
  saml: string;
  error?: {
    code?: string;
    field?: string;
    message?: string;
  };
}

const IdLineItem = ({ label, value }) => (
  <LineItem
    mono
    left={
      <Text size="sm" color="subtle">
        {label}
      </Text>
    }
    right={<Text size="sm">{value}</Text>}
  />
);

const PaymentRedirectSheet: React.FC<SheetComponentProps['PAYMENT_REDIRECT']> = ({
  policy,
  closeSheet,
}) => {
  const { c } = useCopy('catch.health.PaymentRedirect');
  const [hasReturned, setReturn] = useState<boolean>(false);
  const [params, setParams] = useState<RedirectParams | undefined>();

  const [getPaymentRedirectURL, { loading: gettingURL }] = useDeprecatedMutation(
    'getPaymentRedirect',
    {
      variables: { policyID: policy.id },
      onCompleted: ({ getPaymentRedirect }) => {
        setParams(getPaymentRedirect);
      },
    },
  );

  useEffect(() => {
    if (!params && !!policy.id) {
      // @ts-ignore
      getPaymentRedirectURL();
    }
  }, []);

  const paymentSheetStatus: RedirectStatus =
    !!params?.error && !params?.url ? 'NOT_SUPPORTED' : hasReturned ? 'CONFIRMATION' : 'REDIRECT';

  return (
    <PaymentRedirectForm
      saml={params?.saml}
      url={params?.url}
      onReturn={() => setReturn(true)}
      loading={false}
    >
      {({ redirect }) => (
        <>
          <SheetBlueprint
            loading={gettingURL}
            asset={getCarrierAsset(policy?.carrier || policy?.provider, policy?.productType)}
            title={policy.planName}
            subtitles={[
              c(`${paymentSheetStatus}.title`, {
                date: safeFormatDate(policy?.dueDate, 'MMM d'),
              }),
              <Text key="opinion" size="sm" color="subtler">
                Wish this was easier? We do too. Send us a message and we will talk to the agencies
              </Text>,
            ]}
            actions={[
              {
                button: true,
                onAction: redirect,
                disabled: gettingURL,
                testID: 'pay-now',
                label: 'Pay online',
                hidden: params?.error,
              },
              {
                label: 'Mark as paid',
                onAction: closeSheet,
                testID: 'attestPayment-btn',
              },
            ]}
          >
            <Layout margins bottomSpace>
              <Stack spacing="2">
                <Text align="center" size="fp" color="subtle">
                  {c(`${paymentSheetStatus}.subtitle`, {
                    redirect: <Link onPress={redirect}>{c('payOnline')}</Link>,
                    date: (
                      <Text weight="bold" size="fp">
                        {safeFormatDate(policy?.dueDate, 'MMMM d')}
                      </Text>
                    ),
                    carrier: policy?.carrier || '',
                  })}
                </Text>
                <Stack spacing="1" separatorComponent>
                  {policy?.policyNumber ? (
                    <IdLineItem label="Policy Number" value={policy?.policyNumber} />
                  ) : (
                    <IdLineItem
                      label="Marketplace Policy ID"
                      value={policy?.exchangePolicyNumber}
                    />
                  )}
                  {policy?.subscriberID ? (
                    <IdLineItem label="Subscriber ID" value={policy?.subscriberID} />
                  ) : (
                    <IdLineItem
                      label="Marketplace Subscriber ID"
                      value={policy?.exchangeSubscriberID}
                    />
                  )}
                </Stack>
              </Stack>
            </Layout>
          </SheetBlueprint>
        </>
      )}
    </PaymentRedirectForm>
  );
};

export default PaymentRedirectSheet;
