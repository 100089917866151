import { useRef } from 'react';

const printStyles = `
  body {
    padding: 64px;
  }
    
  @media print {
    .no-print {
      display: none;
    }
  }
`;

export const usePrint = () => {
  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    if (printRef && printRef.current) {
      const printWindow = window.open('', '', 'width=800,height=600');

      if (printWindow) {
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Preview</title>
              <style>
                ${Array.from(document.querySelectorAll('style'))
                  .map((style) => style.innerHTML)
                  .join('')}
                ${printStyles}  
              </style>
            </head>
            <body>
              ${printRef.current.innerHTML}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print();
      }
    }
  };

  return {
    printRef,
    handlePrint,
  };
};
