import React from 'react';

import { Copy, SemanticColor } from '@types';
import { Page, Layout, Narrow, HeaderGradient } from '@layouts';
import { Asset, Explainer, ExplainerBullet, FinePrint, Loading, useResponsive } from '@uikit';

interface Props {
  title: Copy;
  subtitle?: Copy;
  subtitles?: Copy[];
  bullets?: Array<ExplainerBullet>;
  align?: 'left' | 'right' | 'center';
  toolbar: any;
  context?: 'sheet' | 'page';
  children?: any;
  image?: any;
  loading?: boolean;
  color: SemanticColor;
  detail: any;
  render?: SemanticColor;
  gradient?: SemanticColor;
}

export const ExplainerIntroBlueprint: React.FC<Props> = ({
  title,
  subtitle,
  subtitles,
  detail,
  bullets,
  align = 'center',
  toolbar,
  context = 'sheet',
  children,
  loading,
  color,
  gradient,
  render,
}) => {
  const { isMobile } = useResponsive();
  const Cont = isMobile || context === 'sheet' ? React.Fragment : Narrow;
  const iconSize = 'jumbo';

  return (
    <Page color={context}>
      <Layout.Scroll align={align} bottomSpace>
        <HeaderGradient
          inherit
          hasChildren
          centered
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Asset size={iconSize} render={render} />
        </HeaderGradient>
        <Layout topSpace margins>
          <Cont>
            <Layout.Header
              align={align}
              title={title}
              subtitle={subtitle}
              titleSize="page"
              subtitles={subtitles}
              spaced
              bottomSpace
              loading={loading}
            />

            {!!loading ? (
              <Loading accentColor={color} />
            ) : (
              <>
                <Explainer bullets={bullets} />
                {children}
                {!!detail && <FinePrint items={[detail]} />}
              </>
            )}
          </Cont>
        </Layout>
      </Layout.Scroll>

      {toolbar}
    </Page>
  );
};

export default ExplainerIntroBlueprint;
