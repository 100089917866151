import React from 'react';
import styled, { css } from 'styled-components';
import { Action } from '@types';
import { handleAction } from '@navigate';
import { Text, useTheme, CatchTheme } from '@uikit';
import { Pressable } from '@uikit/components/Pressable';

interface NavLinkProps {
  active: boolean;
  action: Action;
  stacked?: boolean;
  hide?: boolean;
  children: React.ReactNode;
  backgroundColor?: string;
  focusedStyle?: React.CSSProperties;
  ariaLabel?: string;
}

const Container = styled.div`
  display: flex;
  margin: 0 16px;
  transition: ${CatchTheme.animation.duration}ms ${CatchTheme.animation.curve};
`;

const StyledPressable = styled(Pressable)<{
  backgroundColor?: string;
  active?: boolean;
  stacked?: boolean;
  focusedStyle?: React.CSSProperties;
}>`
  background-color: ${({ active, backgroundColor, theme }) =>
    active ? backgroundColor || theme.skeletonBg : 'transparent'};

  /** Focused state styles can be passed as CSS or fallback to default */
  &:focus-visible {
    ${({ focusedStyle }) => focusedStyle && css(focusedStyle)}
  }
`;

const NavLink: React.FC<NavLinkProps> = ({
  action,
  stacked,
  active,
  hide,
  children,
  backgroundColor,
  focusedStyle,
  ariaLabel,
}) => {
  const { theme } = useTheme();

  /** Focused state styles can be passed as CSS or fallback to default */
  const defaultFocusedStyle = focusedStyle || {
    boxShadow: `0 0 0 2px ${theme.focusBorderColor.borderColor || '#007AFF'}`,
  };

  if (hide) return null;

  return (
    <Container>
      <StyledPressable
        handleOnPress={() => handleAction(action)}
        hoveredStyle={[backgroundColor ? { backgroundColor } : theme.skeletonBg]}
        focusedStyle={defaultFocusedStyle}
        style={[
          styles.link,
          stacked && styles.linkStacked,
          active && (backgroundColor ? { backgroundColor } : theme.skeletonBg),
        ]}
        ariaLabel={ariaLabel}
      >
        <Text weight="medium" color={active ? 'text' : 'subtle'} size="fp">
          {children}
        </Text>
      </StyledPressable>
    </Container>
  );
};

const styles = {
  link: {
    display: 'flex',
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginHorizontal: -16,
    borderRadius: 99,
    lineHeight: 1,
    flexDirection: 'row',
    alignItems: 'center',
    outline: 'none',
  },
  linkStacked: {
    borderRadius: 0,
    flex: 1,
    opacity: 1,
    paddingVertical: 8,
    paddingHorizontal: 24,
    marginHorizontal: -40,
  },
};

export default NavLink;
