import React, { useState, useEffect } from 'react';
import { Segment, SegmentEvent } from '@app/utils';
import { SheetBlueprint } from '@app/blueprints';
import {
  useMutation,
  UpsertConversionStatus,
  ConversionStatusType,
  ConversionStatusEnum,
} from '@app/data';

const phases = {
  // phase 2 screening questions
  isCanProvideSsn: 2,
  isNaturalizedOrDerivedCitizenship: 2,
  areCitizens: 2,
  isFormerFosterCareAndUnder25: 2,
  isAnyoneFullTimeStudent1822: 2,
  isPregnantOrHadChildLast60Days: 2,
  isIncarcerated: 2,
  isStepchildOrGrandchild: 2,

  // phase 3 screening questions
  areAllSameAddress: 3,
  isPlanningToFileTax: 3,
  isResponsibleForChildNotOnTaxReturn: 3,
  isIndianOrAlaskan: 3,
  isDependentSingleChild25OrYounger: 3,
  isClaimingAllDependentsOnTax: 3,
  isCoveredThroughJobOrCobra: 3,
  isOfferedIchra: 3,
  areDependentsLivingWithParentNotOnTaxReturn: 3,
  areDependentsChildOrStepchildSingleUnder26: 3, // screens out grandchildren, other dependent issues
};

const HealthScreenedOutSheet = ({ handoffUrl, failedQuestion, coverageYear, closeSheet }) => {
  const [showInstructions, setShowInstructions] = useState(false);
  const [upsertConversion] = useMutation(UpsertConversionStatus);

  useEffect(() => {
    Segment.track(SegmentEvent.EDE_SCREENED_OUT, {
      question: failedQuestion,
      phase: phases[failedQuestion],
      coverage_year: coverageYear,
    });

    Segment.track(SegmentEvent.SCREENING_QUESTIONS_FAILED, {
      failed_question: failedQuestion,
      phase: phases[failedQuestion],
      coverage_year: coverageYear,
    });
  }, []);

  const exit = () => {
    Segment.track(SegmentEvent.SCREENING_QUESTIONS_REVIEWED, {
      failed_question: failedQuestion,
      phase: phases[failedQuestion],
      coverage_year: coverageYear,
    });

    closeSheet();
  };

  const handoff = () => {
    window.open(handoffUrl);

    Segment.track(SegmentEvent.SCREENING_HANDOFF_CLICKED, {
      coverage_year: coverageYear,
    });

    upsertConversion({
      variables: {
        input: {
          year: coverageYear,
          type: ConversionStatusType.Health,
          status: ConversionStatusEnum.Handoff,
        },
      },
    });
  };

  if (showInstructions) {
    return (
      <SheetBlueprint
        title="Here's what to expect"
        subtitle="Once directed to our partner site, view your selected plan in the cart and start your application."
        actions={[{ type: 'CUSTOM', onAction: handoff, label: 'Continue' }]}
      />
    );
  }

  return (
    <SheetBlueprint
      title="Based on your answer, you'll need to finish your application on our partner website."
      actions={[
        { type: 'CUSTOM', onAction: exit, label: 'Review my answer' },
        {
          type: 'CUSTOM',
          onAction: () => setShowInstructions(true),
          label: 'Proceed to partner website',
        },
      ]}
    />
  );
};

export default HealthScreenedOutSheet;
