import { BasicFormBlueprint } from '@blueprints';
import {
  useQuery,
  useMutation,
  GET_CURRENT_USER,
  UpdateUser,
  SIGN_AGREEMENTS,
  AgreementType,
} from '@data';
import { ScreenDefinition } from '@navigate';
import { Button } from '@uikit';
import { safeFormatDate } from '@util';
import { Route } from '@types';
import { useMemo } from 'react';
import { formatAgreement, useAgreement } from '@app/hooks';

const OnboardingConsent = () => {
  const { loading, data } = useQuery(GET_CURRENT_USER);
  const [updateUser] = useMutation(UpdateUser);
  const [signAgreements] = useMutation(SIGN_AGREEMENTS);

  const consent = useAgreement(AgreementType.BrokerConsent);

  const initialValues = useMemo(() => {
    return {
      givenName: data?.me.user?.givenName,
      familyName: data?.me.user?.familyName,
    };
  }, [data]);

  const handleSubmit = (values) => {
    updateUser({
      variables: {
        input: {
          givenName: values.givenName,
          familyName: values.familyName,
        },
      },
    });

    const input = [
      {
        agreementType: consent.agreement?.agreementType as AgreementType,
        version: consent.agreement?.version as string,
      },
    ];

    signAgreements({ variables: { input } });
  };

  const consentFields = [
    {
      name: 'name',
      type: 'legalName',
      label: 'Name',
      required: true,
      subfields: {
        givenName: true,
        middleName: false,
        familyName: true,
        nameSuffix: false,
      },
    },
    {
      type: 'checkbox',
      name: 'brokerConsent',
      dependencies: ['name'],
      required: true,
      scroll: 105,
      plain: true,
      label: ({ name }) => {
        const fullName = name.givenName
          ? `${name?.givenName}${name.familyName ? ` ${name.familyName}` : ''}`
          : '';

        return formatAgreement(consent?.agreement, {
          legalName: fullName ? `, ${fullName},` : '',
          date: safeFormatDate(new Date(), 'MMMM d, yyyy'),
        });
      },
    },
  ];

  return (
    <BasicFormBlueprint
      title="Opt-in to working with Catch"
      subtitle="Sign your broker consent"
      actions={({ handleSubmit, disabled }) => [
        <Button key="submit" testID="sign-consent" onPress={handleSubmit} disabled={disabled}>
          Submit
        </Button>,
      ]}
      loading={loading || consent.loading}
      formConfig={{
        initialValues,
        fields: consentFields,
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const OnboardingConsentView: ScreenDefinition = {
  name: Route.ONBOARDING_CONSENT,
  component: OnboardingConsent,
  guest: true,
  options: {
    title: 'Opt-in to working with Catch',
  },
};
