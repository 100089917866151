import { generatePath } from 'react-router';
import queryString from 'query-string';
import { ensnake } from '@app/utils';
import { paths, Route } from '../paths';
import { history } from '../history';

/**
 * Same as push,
 * but different method on history
 */
export const replace = (screenName: Route, data: object) => {
  // @ts-ignore
  const parsed = queryString.parse(history.location.search);

  // if there is already a flow open, continue in flow
  if (parsed.flow) {
    // @ts-ignore
    const path = `${history.location.pathname}?flow=${ensnake(screenName)}`;

    // @ts-ignore
    history.replace(path, data);
  } else {
    // otherwise, push the new url to the stack
    const path = generatePath(paths[screenName], data);

    // @ts-ignore
    history.replace(path, data);
  }
};
