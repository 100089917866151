import { gql } from '../generated';

export const REQUEST_MANUAL_REVIEW = gql(`
  mutation RequestManualReview($identityId: ID!) {
    requestManualReview(id: $identityId) {
      id
      status
      providerType
    }
  }
`);

export const GET_IDENTITY_UPLOADS = gql(`
  query GetIdentityUploads {
    viewerTwo {
      id
      documentUploads {
        ...DocUpload
      }
      identity(providerType: CMS) {
        id
      }
    }
  }
`);
