import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { popReviewToast } from './popReviewToast';

export function ReviewPrompt() {
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    const shouldAsk = search.get('stars') === 'five';

    if (shouldAsk) {
      search.delete('stars');
      popReviewToast();
      setSearch(search);
    }
  }, []);

  return null;
}
