import React from 'react';
import { FontWeight, Route } from '@types';
import { useCopy } from '@app/utils';
import { BasicFormBlueprint } from '@blueprints';
import { navigate, pop, ScreenDefinition } from '@app/navigate';
import { Button, Text, Link } from '@app/_ui-kit';
import { Stack } from '@app/layouts';
import { AgreementType, SIGN_AGREEMENTS, useMutation } from '@app/data';
import { useAgreement } from '@app/hooks';

const AetnaFinancialOnboarding = () => {
  const { c } = useCopy('catch.aetnaAuthorizePrefill');

  const [signAgreements] = useMutation(SIGN_AGREEMENTS, {
    onCompleted: () => navigate(Route.GOAL_EMPLOYMENT_TYPE),
  });

  const consent = useAgreement(AgreementType.EdePrefill);

  const consentFields = [
    {
      type: 'fieldset',
      name: 'agreementFieldset',
      fields: [
        {
          type: 'checkbox',
          name: 'edePrefill',
          required: true,
          plain: true,
          label: c('checkboxLabel'),
        },
      ],
    },
  ];

  const handleSubmit = () => {
    const input = [
      {
        agreementType: consent.agreement?.agreementType as AgreementType,
        version: consent.agreement?.version as string,
      },
    ];
    signAgreements({ variables: { input } });
  };

  return (
    <BasicFormBlueprint
      title={c('title')}
      subtitle={c('subtitle')}
      postContent={
        <Stack horizontal spacing="0b">
          <Text color="subtler">{c('fineprint')}</Text>
          <Link weight={FontWeight.regular} onPress={() => navigate(Route.GOALS)}>
            {c('skipText')}
          </Link>
        </Stack>
      }
      actions={({ handleSubmit, disabled }) => [
        <Button key="submit" testID="aetna-ede-prefill" onPress={handleSubmit} disabled={disabled}>
          Next
        </Button>,
      ]}
      onBack={pop}
      formConfig={{
        fields: consentFields,
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const AetnaFinancialOnboardingView: ScreenDefinition = {
  name: Route.AETNA_EDE_PREFILL_ATTESTATION,
  component: AetnaFinancialOnboarding,
  options: {},
};
