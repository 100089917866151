import React, { useEffect, useMemo, useState, useRef } from 'react';
import { FieldsConfig, IdentityStatus } from '@types';
import { BasicLayout, Layout, Stack, Toolbar } from '@layouts';
import { Button, OptionGroup, Text, Link, ComplexRow } from '@uikit';
import { createLogger, Env, safeFormatDate, Segment, SegmentEvent } from '@app/utils';
import { HiccupBlueprint } from '@app/blueprints';
import { useHealthIdentity } from '@app/hooks/useHealthIdentity';
import { exit, replace } from '@app/navigate';
import { Fields, useForm } from '@app/forms';
import { Route } from '@types';

import Phone from '@svg/phone.svg';
import Clock from '@svg/clock.svg';
import Checkmark from '@svg/checkmark.svg';
import ShieldCross from '@svg/shield-cross.svg';
import Upload from '@svg/upload.svg';
import OfflineVerification from './OfflineVerification';
import { useHealthHandoff } from '@app/hooks/useHealthHandoff';

const Log = createLogger('identity-proofing');

interface ProofingProps {
  disabled?: boolean;
  canSearch?: boolean;
  shouldHandoff?: boolean;
  applicantID?: string; // for cases where a specific health applicant should be used
  handleSuccess: () => void;
  coverageYear?: number;
}

enum ProofingScenario {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  QUESTIONS = 'QUESTIONS',
  OFFLINE = 'OFFLINE',
  CALLED = 'CALLED',
  TIMEOUT = 'TIMEOUT',
  UPLOAD = 'UPLOAD',
  UPLOADED = 'UPLOADED',
  DENIED = 'DENIED',
  VERIFIED = 'VERIFIED',
}

interface CopyConfig {
  icon?: any; // svg component
  title: string;
  subtitle?: string;
}

const scenarios: Record<IdentityStatus, ProofingScenario> = {
  INITIAL: ProofingScenario.LOADING,
  PENDING_PROVIDER: ProofingScenario.LOADING,

  UNKNOWN: ProofingScenario.ERROR,
  STUCK: ProofingScenario.ERROR,
  SERVER_ISSUE: ProofingScenario.TIMEOUT,
  NEEDS_DOCS: ProofingScenario.ERROR, // shouldn't be handled in this flow

  NEEDS_QUIZ: ProofingScenario.QUESTIONS,
  PENDING_OFFLINE_VERIFICATION: ProofingScenario.OFFLINE,
  LOCKED_OUT: ProofingScenario.UPLOAD,
  DENIED: ProofingScenario.UPLOAD,
  PENDING_MANUAL_REVIEW: ProofingScenario.UPLOADED,
  VERIFIED: ProofingScenario.VERIFIED,
};

const uploadFields = [
  {
    name: 'documentType',
    type: 'dropdown',
    label: 'Select document type',
    required: true,
    options: [
      { label: "Driver's license", value: 'PHOTO_ID_FRONT' },
      { label: 'Passport', value: 'PASSPORT' },
    ],
  },
  {
    name: 'upload',
    type: 'file',
    label: 'Upload your document',
    dependencies: ['documentType'],
    required: true,
    documentType: ({ documentType }) => documentType,
  },
];

const events: Record<ProofingScenario, SegmentEvent> = {
  [ProofingScenario.LOADING]: SegmentEvent.IDENTITY_PROOFING_STARTED,
  [ProofingScenario.QUESTIONS]: SegmentEvent.IDENTITY_PROOFING_QUESTIONS,
  [ProofingScenario.OFFLINE]: SegmentEvent.IDENTITY_PROOFING_OFFLINE,
  [ProofingScenario.CALLED]: SegmentEvent.IDENTITY_PROOFING_CALLED,
  [ProofingScenario.TIMEOUT]: SegmentEvent.IDENTITY_PROOFING_TIMEOUT,
  [ProofingScenario.UPLOAD]: SegmentEvent.IDENTITY_PROOFING_UPLOAD,
  [ProofingScenario.UPLOADED]: SegmentEvent.IDENTITY_PROOFING_UPLOADED,
  [ProofingScenario.DENIED]: SegmentEvent.IDENTITY_PROOFING_DENIED,
  [ProofingScenario.VERIFIED]: SegmentEvent.IDENTITY_PROOFING_VERIFIED,
  [ProofingScenario.ERROR]: SegmentEvent.IDENTITY_PROOFING_ERROR,
};

/**
 * Handles the set of options for proofing
 *
 */
const Proofing: React.FC<ProofingProps> = ({
  disabled,
  applicantID,
  canSearch,
  shouldHandoff,
  handleSuccess,
  coverageYear,
}) => {
  const [hasCalled, setHasCalled] = useState(false);
  const [shouldUpload, setShouldUpload] = useState(false);
  const [hasUploads, setHasUploads] = useState(false);
  const hasRunConfirmID = useRef(false); //prevents confirm identity call from running indefinitely

  const {
    loading,
    status,
    questions = [],
    referenceNumber,
    retryAfter,
    confirmIdentity,
    confirmVerifiedOffline,
    requestManualReview,
    answerQuestions,
    answering,
    hasIdentityUploads,
    identityUploads,
  } = useHealthIdentity(applicantID);

  /**
   * calls confirm identity for initial subsets
   */

  useEffect(() => {
    //handle logic for when to call confirm identity
    const canConfirmIdentity = !loading && !!canSearch && status !== 'SERVER_ISSUE';

    if (canConfirmIdentity && !hasRunConfirmID.current) {
      confirmIdentity();
      hasRunConfirmID.current = true;
    }
  }, [loading, status, canSearch]);

  /**
   * Creates fields from the question and answer key
   */
  const fields: FieldsConfig = useMemo(() => {
    return questions?.map((question) => ({
      type: 'option',
      name: question?.key,
      label: question?.text,
      required: true,
      options: (question?.choices || question?.answers)?.map((answer) => ({
        label: answer.text,
        value: answer.key,
      })),
    }));
  }, [questions]);

  const form = useForm<{ '01': string; '02': string; '03': string }>({
    loading,
    disabled: answering,
    initialValues: {},
    fields,
    onSubmit: (values) => {
      answerQuestions(
        Object.entries(values)?.map((question) => ({
          questionKey: question[0],
          answerKey: question[1],
        })),
      );
    },
  });

  const uploadForm = useForm({
    loading,
    disabled: answering,
    initialValues: {
      documentType: '',
    },
    fields: uploadFields,
    onSubmit: () => {
      Segment.simpleTrack(SegmentEvent.EDE_IDENTITY_DOCS_UPLOADED);
      requestManualReview();
      setHasUploads(true);
    },
  });

  const { handoff } = useHealthHandoff({
    handoffType: Env.isDevLike ? 'HEALTHCARE_GOV' : 'HEALTHSHERPA',
    options: {
      coverageYear,
      onHandoff: () => {
        replace(Route.COVERAGE);
      },
    },
  });

  const config: Record<ProofingScenario, CopyConfig> = {
    [ProofingScenario.LOADING]: { title: 'Verify identity' },
    [ProofingScenario.ERROR]: {
      icon: Clock,
      title: 'We’re reviewing your information',
      subtitle:
        'We’re reviewing your information and will be in touch soon with next steps. Look out for an email from Catch when it’s time to resume your application',
    },
    [ProofingScenario.QUESTIONS]: {
      title: 'Verify identity',
    },
    [ProofingScenario.OFFLINE]: {
      icon: Phone,
      title: 'Couldn’t verify your identity',
      subtitle:
        'Please call the Experian help desk at (866) 578-5409 and give them the code below to verify your identity, or select “Next” below.',
    },
    [ProofingScenario.CALLED]: {
      icon: Phone,
      title: 'What did they say?',
      subtitle: (
        <Text color="subtle">
          If you haven&apos;t spoken with Experian yet, see how to call the Experian help desk{' '}
          <Link testID="experian-info" onPress={() => setHasCalled(false)}>
            here
          </Link>
          .
        </Text>
      ),
    },
    [ProofingScenario.UPLOAD]: {
      icon: Upload,
      title: 'Identity documentation',
      subtitle: "Please provide a driver's license or passport to verify your identity.",
    },
    [ProofingScenario.UPLOADED]: {
      icon: Checkmark,
      title: 'Your documents are in review',
      subtitle:
        "Catch Support is verifying your identity and will reach out with next steps. Once you're approved, you'll need to return to continue.",
    },
    [ProofingScenario.TIMEOUT]: {
      icon: Clock,
      title: 'Couldn’t verify your identity',
      subtitle: retryAfter
        ? `You will need to try again later. Please come back after ${safeFormatDate(
            new Date(retryAfter),
            'MMMM dd, yyyy H:mm a',
          )}.`
        : 'Please try again later.',
    },
    [ProofingScenario.DENIED]: {
      icon: ShieldCross,
      title: shouldHandoff
        ? 'Unfortunately, it doesn’t look like we’ll be able to verify your identity. You’ll need to continue your application using our preferred partner.'
        : "We're unable to verify your identity",
    },
    [ProofingScenario.VERIFIED]: {
      icon: Checkmark,
      title: 'Identity verified',
      subtitle: '',
    },
  };

  // maps the status to the scenario we want to handle for
  const scenario: ProofingScenario = useMemo(() => {
    if (!status) return ProofingScenario.LOADING;
    if (hasUploads) return ProofingScenario.UPLOADED;
    if (shouldUpload) return ProofingScenario.UPLOAD;
    if (hasCalled) return ProofingScenario.CALLED;
    Log.debug(`Handling status:${status}`);
    return scenarios[status];
  }, [status, hasCalled, shouldUpload, hasUploads]);

  useEffect(() => {
    Segment.simpleTrack(events[scenario]);
  }, [scenario]);

  const copy = useMemo(() => {
    return config[scenario];
  }, [scenario]);

  const content =
    scenario === ProofingScenario.CALLED ? (
      <Layout>
        <OptionGroup
          testID="result"
          onPress={(val) => {
            if (val === 'verified') {
              confirmVerifiedOffline();
            } else {
              if (hasIdentityUploads) {
                Segment.simpleTrack(SegmentEvent.EDE_IDENTITY_DOCS_FOUND);
                requestManualReview();
                setHasUploads(true);
              } else {
                setShouldUpload(true);
              }
            }
          }}
          options={[
            {
              key: 'verified',
              label: 'Verified',
              description: 'Experian successfully verified me',
              value: 'verified',
            },
            {
              key: 'not-verified',
              label: 'Unable to verify',
              description: 'Experian said to contact Catch support',
              value: 'not-verified',
            },
            {
              key: 'upload-id',
              label: 'Upload my ID instead',
              description: 'I’m not able to call Experian',
              value: 'upload-id',
            },
          ]}
        />
      </Layout>
    ) : scenario === ProofingScenario.OFFLINE ? (
      <OfflineVerification loading={loading} referenceNumber={referenceNumber || ''} />
    ) : scenario === ProofingScenario.QUESTIONS ? (
      <Fields form={form} fields={fields} stackProps={{ spacing: 3 }} />
    ) : scenario === ProofingScenario.UPLOAD ? (
      <Fields form={uploadForm} fields={uploadFields} />
    ) : scenario === ProofingScenario.UPLOADED ? (
      <>
        <Stack separatorComponent>
          {identityUploads?.map((upload) => (
            <ComplexRow
              key={upload.id}
              testID={`upload-${upload.id}`}
              asset={{ img: upload.url, shape: 'squircle' }}
              label={upload?.name}
              sublabel={`Uploaded ${safeFormatDate(upload.createdOn)}`}
            />
          ))}
        </Stack>
        <Layout center>
          <Link
            testID="upload-more"
            onPress={() => {
              setShouldUpload(true);
              setHasUploads(false);
            }}
          >
            Upload another document
          </Link>
        </Layout>
      </>
    ) : null;

  const actions = {
    [ProofingScenario.QUESTIONS]: [
      {
        testID: 'answer',
        label: 'Next',
        disabled: form.disableSubmit,
        onAction: form.submitForm,
      },
    ],
    [ProofingScenario.OFFLINE]: [
      {
        testID: 'call',
        label: 'Call (866) 578-5409',
        color: 'coverageLight',
      },
      {
        testID: 'verify',
        label: 'Next',
        onAction: () => setHasCalled(true),
      },
    ],
    [ProofingScenario.DENIED]: [
      {
        testID: shouldHandoff ? 'apply-now' : 'exit',
        label: shouldHandoff ? 'Apply now' : 'Exit',
        onAction: shouldHandoff ? handoff : exit,
      },
    ],
    [ProofingScenario.TIMEOUT]: [
      {
        testID: 'try-later',
        label: 'Try Later',
        onAction: confirmIdentity,
      },
    ],
    [ProofingScenario.ERROR]: [
      {
        testID: 'exit',
        label: 'Exit',
        onAction: exit,
      },
    ],
    [ProofingScenario.UPLOAD]: [
      {
        testID: 'answer',
        label: 'Upload',
        disabled: uploadForm.disableSubmit,
        onAction: uploadForm.submitForm,
      },
    ],
    [ProofingScenario.VERIFIED]: [
      {
        testID: 'success',
        label: 'Continue',
        onAction: handleSuccess,
      },
    ],
  };

  if (copy?.icon) {
    return (
      <HiccupBlueprint
        loading={loading}
        disabled={disabled}
        asset={{ svg: copy?.icon, gradient: 'coverage' }}
        title={copy?.title}
        subtitles={[copy?.subtitle]}
        actions={actions[scenario] || []}
      >
        {content}
      </HiccupBlueprint>
    );
  } else {
    return (
      <BasicLayout
        loading={loading || scenario === 'LOADING'}
        title={copy?.title}
        subtitles={[copy?.subtitle]}
        toolbar={
          <Toolbar>
            {(actions[scenario] || []).map((action) => (
              <Button
                inherit
                key={action.testID}
                testID={action.testID}
                disabled={action.disabled || disabled}
                onPress={action.onAction}
              >
                {action.label}
              </Button>
            ))}
          </Toolbar>
        }
      >
        {content}
      </BasicLayout>
    );
  }
};

export default Proofing;
