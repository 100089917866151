import { isEmpty } from 'lodash';
import React from 'react';
import { ComplexRow, EmptyState } from '@app/_ui-kit';
import { BankTransferDirection, BankTransferStatus, useFintechQuery } from '@app/data';
import { GET_MONEY_ACTIVITIES } from '@app/data/definitions/money';
import { useCopy } from '@app/utils';
import { toDollars } from '@app/utils/format/currency';
import { date } from '@app/utils/format/date';
import ArrowDownRightSvg from '@svg/arrow-down-right.svg';
import ArrowUpLeftSvg from '@svg/arrow-up-left.svg';
import ClockSvg from '@svg/clock.svg';
import UnorderedListSvg from '@svg/unordered-list.svg';

function getActivityIcon(direction: BankTransferDirection) {
  return (
    {
      [BankTransferDirection.DepositIntoCatch]: ArrowDownRightSvg,
      [BankTransferDirection.WithdrawFromCatch]: ArrowUpLeftSvg,
    }[direction] ?? UnorderedListSvg
  );
}

function getActivityVerb(direction: BankTransferDirection, isPending: boolean) {
  return {
    [BankTransferDirection.DepositIntoCatch]: isPending ? 'deposit pending' : 'deposited',
    [BankTransferDirection.WithdrawFromCatch]: isPending ? 'withdrawal pending' : 'withdrawal',
  }[direction];
}

export function MoneyActivity() {
  const { c, $ } = useCopy('catch.money.activity');
  const { data, loading } = useFintechQuery(GET_MONEY_ACTIVITIES);

  if (loading || isEmpty(data?.activities)) {
    return <EmptyState icon={ClockSvg} loading={loading} title={c('empty')} />;
  }

  // arbitrarily showing only 6 until we implement pagination/view all/whatever functionality
  return data?.activities?.slice(0, 5).map((activity) => {
    const activityData = activity?.activityData;
    const direction: BankTransferDirection = activityData?.direction;
    const isPending = activityData?.status === BankTransferStatus.Pending;

    return (
      <ComplexRow
        asset={{
          bg: 'grayBg',
          borderColor: isPending ? 'textColor' : undefined,
          color: 'text',
          containerSize: 'md',
          shape: 'circle',
          size: 'sm',
          style: isPending ? { borderStyle: 'dashed', borderWidth: 1, opacity: 0.25 } : {},
          svg: getActivityIcon(direction),
        }}
        key={activityData?.id}
        label={`${$(
          toDollars(
            (direction === BankTransferDirection.WithdrawFromCatch
              ? -activityData?.amount
              : activityData?.amount) as number,
          ),
        )} ${getActivityVerb(direction, isPending)}`}
        sublabel={date(activityData?.dateInitialized, 'SHORT')}
        testID="money-activity"
      />
    );
  });
}
