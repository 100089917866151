import { boolToTri, bornBefore, formatMaskedPayload } from '@app/utils';

const translateName = (name) => ({
  firstName: name?.givenName || undefined,
  givenName: name?.firstName || undefined,
  middleName: name?.middleName || undefined,
  lastName: name?.familyName || undefined,
  familyName: name?.lastName || undefined,
  suffix: name?.nameSuffix || undefined,
  nameSuffix: name?.suffix || undefined,
});

export const getInitialValues = (member) => {
  return {
    id: member?.id,
    givenName: member?.givenName,
    middleName: member?.middleName,
    familyName: member?.familyName,
    nameSuffix: member?.nameSuffix,
    fullName: member?.legalName,
    relation: member?.relation,
    age: member?.age,
    uiQuestionsToDisplay: member?.uiQuestionsToDisplay,
    isLawfulPresenceStatus: boolToTri(member?.isLawfulPresenceStatus),
    isNaturalizedCitizen: boolToTri(member?.isNaturalizedCitizen),
    hasLivedInUS5Years: boolToTri(member?.hasLivedInUS5Years),
    lawfulPresenceGrantDate: member?.lawfulPresenceGrantDate,
    bornBeforeAug1996: bornBefore(member?.dob, '8/22/1996'),

    // documentation
    lawfulPresenceDocumentation: member?.lawfulPresenceDocumentation?.documentType,
    documentExpirationDate: member?.lawfulPresenceDocumentation?.documentExpirationDate,
    sevisID: member?.lawfulPresenceDocumentation?.sevisID,
    alienNumber: member?.lawfulPresenceDocumentation?.alienNumber,
    i94Number: member?.lawfulPresenceDocumentation?.i94Number,
    cardNumber: member?.lawfulPresenceDocumentation?.cardNumber,
    passportNumber: member?.lawfulPresenceDocumentation?.passportNumber,
    naturalizationCertificateNumber:
      member?.lawfulPresenceDocumentation?.naturalizationCertificateNumber,
    citizenshipNumber: member?.lawfulPresenceDocumentation?.citizenshipNumber,
    passportIssuingCountry: member?.lawfulPresenceDocumentation?.passportIssuingCountry,
    employmentAuthorizationCategoryIdentifier:
      member?.lawfulPresenceDocumentation?.employmentAuthorizationCategoryIdentifier,
    otherDocumentTypeText: member?.lawfulPresenceDocumentation?.otherDocumentTypeText,

    isSameNameApplicationAndDocument: !!member?.lawfulPresenceDocumentation?.documentAlternativeName
      ?.firstName
      ? 'NO'
      : undefined,
    documentAlternativeName: translateName(
      member?.lawfulPresenceDocumentation?.documentAlternativeName,
    ),

    // veteran
    isVeteran:
      member?.isVeteranSelf || member?.nonMemberVeteranRelationshipTypes
        ? (member?.nonMemberVeteranRelationshipTypes || []).concat(
            member?.isVeteranSelf ? ['SELF'] : [],
          )
        : undefined,
  };
};

export const formatPayload = ({ applicationID, values, followUp }) => {
  const relations = values?.isVeteran
    ?.map((type) => (type.includes('PARENT_STEP_PARENT') ? 'PARENT_STEP_PARENT' : type))
    ?.filter((type) => ['DECEASED_SPOUSE', 'PARENT_STEP_PARENT', 'SPOUSE'].includes(type));

  const lawfulPresence = values?.isLawfulPresenceStatus === 'YES';
  const notLawfulPresence = values?.isLawfulPresenceStatus === 'NO';

  const addDocument =
    (values?.isLawfulPresenceStatus === 'YES' || values?.isNaturalizedCitizen === 'YES') &&
    values?.lawfulPresenceDocumentation &&
    values?.lawfulPresenceDocumentation !== 'NONE_OF_THESE' &&
    values?.lawfulPresenceDocumentation !== 'PREFER_NOT_TO_ANSWER';

  const hasSomeAlternateDocumentation =
    !!values?.lawfulPresenceAlternateDocumentation &&
    !values?.lawfulPresenceAlternateDocumentation.includes('ALIEN_NUM') &&
    !values?.lawfulPresenceAlternateDocumentation.includes('I-94_NUM');

  let input = {};

  if (values?.uiQuestionsToDisplay?.['45']) {
    input = {
      id: values.id,
      isLawfulPresenceStatus: values?.isLawfulPresenceStatus,
      isVeteran: notLawfulPresence
        ? 'NIL'
        : values?.isVeteran
        ? boolToTri(values?.isVeteran?.length > 0)
        : undefined,
      isVeteranSelf: notLawfulPresence
        ? 'NIL'
        : values?.isVeteran
        ? boolToTri(values?.isVeteran?.includes('SELF'))
        : undefined,
      nonMemberVeteranRelationshipTypes: notLawfulPresence
        ? []
        : relations?.length > 0
        ? [...new Set(relations)]
        : undefined,
      hasLivedInUS5Years: lawfulPresence ? values?.hasLivedInUS5Years : 'NIL',
      lawfulPresenceGrantDate: values?.lawfulPresenceGrantDate,
      lawfulPresenceDocumentation: addDocument
        ? {
            documentType: values?.lawfulPresenceDocumentation,
            documentExpirationDate: values?.documentExpirationDate || undefined,
            sevisID: formatMaskedPayload(values?.sevisID || undefined),
            i94Number: formatMaskedPayload(
              values?.i94Number || values?.otherDocumentI94Number || undefined,
            ),
            passportNumber: formatMaskedPayload(values?.passportNumber || undefined),
            alienNumber: formatMaskedPayload(
              values?.alienNumber || values?.otherDocumentAlienNumber || undefined,
            ),
            cardNumber: values?.cardNumber || undefined,
            passportIssuingCountry: values?.passportIssuingCountry || undefined,
            otherDocumentTypeText:
              values?.otherTypeText || values?.otherDocumentTypeText || undefined,
            employmentAuthorizationCategoryIdentifier:
              values?.employmentAuthorizationCategoryIdentifier || undefined,
            documentAlternativeName:
              values?.isSameNameApplicationAndDocument === 'NO'
                ? translateName(values?.documentAlternativeName)
                : undefined,
            isNoIdentifiersProvided: hasSomeAlternateDocumentation ? true : undefined,
          }
        : undefined,
    };
  }

  if (values?.uiQuestionsToDisplay?.[40]) {
    input = {
      id: values.id,
      isNaturalizedCitizen: values?.isNaturalizedCitizen,
      lawfulPresenceDocumentation: addDocument
        ? {
            documentType: values?.lawfulPresenceDocumentation,
            naturalizationCertificateNumber: formatMaskedPayload(
              values?.naturalizationCertificateNumber,
            ),
            citizenshipNumber: formatMaskedPayload(values?.citizenshipNumber),
            alienNumber: formatMaskedPayload(values?.alienNumber),
          }
        : undefined,
    };
  }

  if (followUp) {
    input = {
      id: values.id,
      lawfulPresenceGrantDate: values?.lawfulPresenceGrantDate,
    };
  }

  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: input,
      },
    },
  };
};
