import { Action } from '@types';
import { openIntercom } from '@app/utils/analytics';
import { toggleLanguage } from '@app/utils/language/locale';
import { open } from '../actions/open';
import { Env } from '@app/utils';

export const handleAction = (action: Action, routeProps = {}, props) => {
  const sanitizedAction = { ...action, icon: undefined, glyph: undefined };

  if (Env.isDevLike && action.onPress) {
    alert('this onPress is deprecated. please remove');
  }

  // @ts-ignore
  switch (action?.type) {
    case 'ROUTE':
      // @ts-ignore
      open(action.route, { ...routeProps, ...action.routeData, ...sanitizedAction });
      break;
    case 'LINK':
      // @ts-ignore
      window.open(action.link || action.route);
      break;
    case 'HELP':
      // @ts-ignore
      openIntercom();
      break;
    case 'TOGGLE_LANGUAGE':
      toggleLanguage();
      break;
    case 'CUSTOM':
      if (!!action.onAction) action.onAction(props);
      else if (!!action.onPress) action.onPress();
      break;

    default:
      if (!!action.onAction) action.onAction(props);
      else if (!!action.onPress) action.onPress();
      break;
  }
};
