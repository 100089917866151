import styled from 'styled-components';

import { Income } from '@data';
import { PromptCard, Stack } from '@layouts';
import { IconSvg, Text } from '@uikit';
import { open } from '@navigate';
import { FontSize, FontWeight, Route, TextAlign } from '@types';
import { date } from '@util/format/date';

import SkipSvg from '@svg/catch-skip.svg';
import SaveSvg from '@svg/catch-roll.svg';

import { IncomeLogo } from './IncomeLogo';

export interface IncomePromptCardProps {
  income: Income;
  index: number;
}

const StyledAmount = styled.div`
  padding: 32px 0px;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 12px;
  border-radius: ${(p) => p.theme.corners.sm}px;
  text-align: center;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.bg2};
  }

  &:focus-visible {
    border: 2px solid ${(p) => p.theme.colors.system};
  }
`;

export function IncomePromptCard({ income, index }: IncomePromptCardProps) {
  const handleSkip = () => {
    open(Route.PAYCHECK_SKIPPED, { incomeId: income.id });
  };

  const handleSave = () => {
    open(Route.PAYCHECK_INCOME, { incomeId: income.id });
  };

  return (
    <PromptCard index={index}>
      <IncomeLogo income={income} />
      <StyledAmount>
        <Stack align={TextAlign.center} spacing="0b">
          <Text size={FontSize.xxl} align={TextAlign.center}>
            ${Math.abs(income.amount).toFixed(2)}
          </Text>
          <Text>{date(income.transactionDate, 'RELATIVE')}</Text>
        </Stack>
      </StyledAmount>
      <Stack horizontal style={{ justifyContent: 'space-between' }}>
        <StyledButton onClick={handleSkip}>
          <IconSvg svgComponent={SkipSvg} size="sm" />
          <Text weight={FontWeight.medium}>Skip</Text>
        </StyledButton>
        <StyledButton onClick={handleSave}>
          <IconSvg svgComponent={SaveSvg} size="sm" />
          <Text weight={FontWeight.medium}>Save</Text>
        </StyledButton>
      </Stack>
    </PromptCard>
  );
}
