import { gql } from '../generated';

export const HealthProgressLookupQuery = gql(`
  query HealthProgressLookupQuery($coverageYears: [Int!]!) {
    viewerTwo {
      id
      health {
        applications(
          input: {
            coverageYearNumber: $coverageYears
            statuses: [IN_PROGRESS, DRAFT, SUBMITTED, ENROLLMENT_STUCK]
          }
        ) {
          id
          status
          coverageYearNumber
          coverageState
          isGeorgiaAccess @client
          pathwayType
          pathway
          applicationContext
          lastUsedRoute
          enrollment {
            id
          }
        }
      }
      healthExplorerData {
        id
        canCreateEnrollment
        state
        pathwayType
        pathway
      }
      agreements {
        id
        isPrivacyTruthfulAgree
        isPrivacyAuthorizeAgree
        isPrivacyDataUseAgree
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
`);
