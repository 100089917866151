import React, { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { loaders } from '@app/config';
import { navigate, useSheet, sheets } from '@navigate';
import {
  HealthApplicationQuery,
  getMembers,
  mutations,
  useDeprecatedMutation,
  useMutation,
  useQuery,
} from '@data';
import {
  Log,
  useCopy,
  renderQuestions,
  handleHealthErrors,
  getFullName,
  Segment,
  SegmentEvent,
} from '@app/utils';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { getInitialValues, formatPayload } from './memberInfoUtils';
import { fields } from './memberInfoFields';
import { Route } from '@types';

const PREFIX = 'catch.ede.MemberInfo';

const MemberInfo = ({ isGA, applicationID, enrollId }) => {
  const { c } = useCopy(PREFIX);
  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { isRequestingFinancialAssistance, members, filerName, spouseName } = useMemo(() => {
    if (data?.viewerTwo.health.application) {
      const members = getMembers(data);
      const { screening } = data?.viewerTwo.health.application || {};

      const names = members.reduce(
        (names, m) => {
          if (m.relation === 'SELF') return { ...names, filerName: getFullName(m) };
          if (m.relation === 'SPOUSE') return { ...names, spouseName: getFullName(m) };
          return names;
        },
        { filerName: undefined, spouseName: undefined },
      );

      return {
        members,
        isRequestingFinancialAssistance: screening.isRequestingFinancialAssistance,
        filerName: names.filerName,
        spouseName: names.spouseName,
      };
    } else {
      return {
        members: [],
        filerName: undefined,
        spouseName: undefined,
        isRequestingFinancialAssistance: undefined,
      };
    }
  }, [data?.viewerTwo.health.application]);

  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    ...handleHealthErrors,
    enrollId,
    onCompleted: ({ uiQuestionsToDisplay }) => {
      const { renderMemberQuestions, renderImmigrationQuestions } = renderQuestions({
        uiQuestionsToDisplay,
      });

      if (renderMemberQuestions) {
        navigate(Route.EDE_MEMBER_QUESTIONS);
      } else if (renderImmigrationQuestions) {
        navigate(Route.EDE_IMMIGRATION_DETAILS);
      } else if (isRequestingFinancialAssistance) {
        navigate(Route.EDE_MEDICAID_DENIAL);
      } else {
        navigate(Route.EDE_SEP);
      }
    },
  });

  const titleMap = {
    SELF: c('title'),
    SPOUSE: c('spouseTitle'),
    CHILD: c('dependentTitle'),
  };

  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  Log.debug(members);

  // wait for SSN confirmation when non-applicants dont supply SSN
  const shouldWaitForSSNConfirmation = (values) => {
    return values.isRequestingCoverage === 'NO' && !values.ssn;
  };

  const showMissingSSN = (_, { handleNext }) => {
    openSheet(sheets.MISSING_HEALTH_SSN, { onContinue: handleNext });
  };

  const handleNext = () => {
    const { isWindowShopping, oeCoverageYear } = data?.reference?.health?.openEnrollmentDates || {};
    const appCoverageYear = data?.viewerTwo?.health?.application?.coverageYearNumber;

    // when applying for OE year, but it's still window shopping period
    if (appCoverageYear === oeCoverageYear && isWindowShopping) {
      navigate(Route.EDE_WINDOW_SHOPPING);
    } else {
      openSheet(sheets.LOADER, loaders.HEALTH_LONG_WAIT);
      ensure({ variables: { applicationID } });

      Segment.track(SegmentEvent.APPLICATION_MEMBER_INFO_COMPLETED, {
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
      });
    }
  };

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      submitting={ensuring}
      title={(values) =>
        values?.givenName ? c('nameTitle', { name: values?.givenName }) : titleMap[values?.relation]
      }
      members={members}
      fields={fields}
      data={{
        coverageYear: data?.viewerTwo?.health?.application?.coverageYearNumber,
        isGA,
        filerName,
        spouseName,
        isRequestingFinancialAssistance,
        SSNs: members?.map((member) => ({
          id: member.id,
          ssn: member.ssn,
        })),
      }}
      getInitialValues={getInitialValues}
      onNext={async (values) => {
        await upsert(formatPayload({ applicationID, values }));
      }}
      onComplete={handleNext}
      onWait={showMissingSSN}
      shouldWait={shouldWaitForSSNConfirmation}
    />
  );
};

export const MemberInfoView = {
  name: Route.EDE_MEMBER_INFO,
  component: MemberInfo,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Personal details',
  },
};
