import { GET_BANK_ACCOUNT, useFintechQuery } from '@app/data';
import { FontSize, FontWeight } from '@app/types';
import { useCopy } from '@app/utils';
import { ButtonGroup } from '@layouts';
import { Button, Text } from '@uikit';

export interface GoalActionsProps {
  loading?: boolean;
  isLocked?: boolean;
  percentage?: number;
  editPercentage?: () => void;
  openTransfer: () => void;
}

/**
 * GoalActions
 *
 */
export const GoalActions = ({
  loading,
  isLocked,
  percentage,
  editPercentage,
  openTransfer,
}: GoalActionsProps) => {
  const { c, $ } = useCopy('catch');
  const { data, loading: bankAccountLoading } = useFintechQuery(GET_BANK_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
  });

  const isBankingSetup = !!data?.bankAccount;

  const balances = [
    {
      name: 'Available',
      value: data?.bankAccount?.availableBalance,
    },
    {
      name: 'Pending',
      value: data?.bankAccount?.pendingBalance,
    },
  ];

  if (!bankAccountLoading && !isBankingSetup) {
    return null;
  }

  return (
    <ButtonGroup
      align="end"
      loading={bankAccountLoading || loading}
      textLeft={
        <>
          {balances.map((balance, index) => (
            <div key={balance.name}>
              <Text
                color={index !== 0 ? 'subtle' : undefined}
                size={index !== 0 ? FontSize.h3 : FontSize.h2}
                weight={index !== 0 ? FontWeight.medium : undefined}
              >
                {$(balance.value)} {balance.name}
              </Text>
            </div>
          ))}
        </>
      }
      textRight={percentage ? `${percentage}%` : undefined}
    >
      <Button testID="start-transfer" disabled={isLocked} full onPress={openTransfer}>
        {c('basics.transfer')}
      </Button>
      {editPercentage ? (
        <Button testID="edit-percentage" disabled={isLocked} full onPress={editPercentage} alt>
          {c('basics.edit')}
        </Button>
      ) : (
        <span />
      )}
    </ButtonGroup>
  );
};
