import gql from 'graphql-tag';

export interface UpsertHealthPreferenceVars {
  input: {
    providersWanted?: Array<{ name: string; npi: string }>;
    drugsWanted?: Array<{ name: string; rxcui: string }>;
    financialPreference?: 'LOW_PREMIUM' | 'LOW_DEDUCTIBLE' | 'BALANCED';
    plannedUsage?: 'REC_LOW' | 'REC_MEDIUM' | 'REC_HIGH';
  };
}

export interface UpsertHealthPreferenceResponse {
  upsertHealthPreference: {
    ownerID: string;
    providersWanted?: Array<{ name: string; npi: string }>;
    drugsWanted?: Array<{ name: string; rxcui: string }>;
    financialPreference?: 'LOW_PREMIUM' | 'LOW_DEDUCTIBLE' | 'BALANCED';
    plannedUsage?: 'REC_LOW' | 'REC_MEDIUM' | 'REC_HIGH';
  };
}

export const UpsertHealthPreference = gql`
  mutation UpsertHealthPreference($input: HealthPreferenceInput!) {
    upsertHealthPreference(input: $input) {
      ownerID
      financialPreference
      plannedUsage
      drugsWanted {
        name
        rxcui
      }
      providersWanted {
        name
        npi
      }
    }
  }
`;
