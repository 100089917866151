// Our translations
import enTranslationMessages from './translations/en.json';
import esTranslationMessages from './translations/es.json';
import disclosureMessages from './translations/disclosures.json';
import edeMessages from './translations/ede.json';
import edeEsMessages from './translations/ede.es.json';

export const DEFAULT_LOCALE = 'en';

// This is copy that deserves special treatment i.e. disclosures
const specialLanguage = {
  ...disclosureMessages,
};

const defaultTranslationMessages = {
  ...enTranslationMessages,
  ...edeMessages,
};

/**
 * @param {string} locale the locale messages should be formated for
 * @param {Object} messages the messages to format
 * @param {Object} fallback any language we cover fully that we can
 * fall back on if we are missing some, should avoid usage
 * @param {Object} special included copy no matter the locale
 */
export const formatTranslationMessages = (locale, messages, fallback, special = {}) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, fallback) : {};
  // We reduce the keys of our main language to know all the available keys
  // so we can fallback instead of not providing anything
  const mainMessages = Object.keys(
    locale !== DEFAULT_LOCALE ? defaultFormattedMessages : messages,
  ).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
  return {
    ...mainMessages,
    ...special,
  };
};

export const translationMessages = {
  en: formatTranslationMessages('en', defaultTranslationMessages, {}, specialLanguage),
  es: formatTranslationMessages(
    'es',
    { ...edeEsMessages, ...esTranslationMessages },
    defaultTranslationMessages,
    specialLanguage,
  ),
};

export const SUPPORTED_LOCALES = Object.keys(translationMessages);
