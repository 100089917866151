import React from 'react';
import styled from 'styled-components';
import { AssetSize } from '@app/types';
import { CatchTheme } from '@uikit/themes/catch';

interface Props {
  alt: string;
  uri?: string;
  img?: any;
  size?: AssetSize;
}

const StyledImage = styled.img<{ $size: AssetSize }>`
  height: ${({ $size }) => CatchTheme.assets[$size]}px;
  width: ${({ $size }) => CatchTheme.assets[$size]}px;
`;

const Image = ({ uri, img, alt, size }: Props) => {
  return <StyledImage alt={alt} src={uri || img || undefined} $size={size} />;
};

export default Image;
