import React from 'react';
import { OptionGroup } from '@uikit';
import { Option } from '@types';
import { InputProps } from '../types';

interface OptionInputProps<T> extends InputProps<T> {
  options: Array<Option>;
}

const OptionInput = <T extends any>({
  testID,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  optional,
  disabled,
  options,
  label,
  sublabel,
  help,
  horizontal,
  children,
  error,
}: OptionInputProps<T>) => {
  return (
    <OptionGroup
      testID={testID}
      name={name}
      input={undefined}
      options={options}
      selectedOption={value}
      label={label}
      sublabel={sublabel}
      optional={optional}
      help={help}
      horizontal={horizontal}
      onPress={(val) => onChange(val)}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      disabled={disabled}
      error={error}
    >
      {children}
    </OptionGroup>
  );
};

export default OptionInput;
