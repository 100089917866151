import { gql } from '../generated';

gql(`
  fragment LinkedAccount on LinkedAccount {
    id
    name
    accountMask
  }
`);

gql(`
  fragment BankLink on BankLink {
    id
    status
  }
`);

export const CREATE_LINK_TOKEN = gql(`
  mutation CreateLinkToken {
    createLinkToken(input: {}) {
      expiration
      linkToken
    }
  }
`);

export const CREATE_BANK_LINK = gql(`
  mutation CreateBankLink($input: CreateBankLinkInput!) {
    createBankLink(input: $input) {
      ...BankLink
    }
  }
`);

export const GET_BANK_LINK = gql(`
  query GetBankLink($id: ID!) {
    bankLink(id: $id) {
      ...BankLink
    }
  }  
`);
