import { Trinary } from '@app/types';
import { boolToTri } from '@app/utils';

interface FormValues {
  id: string;
  fullName: string;
  isUnpaidBill: boolean;
}

interface PayloadValues {
  variables: {
    input: {
      applicationID: string;
      applicationMembersInput: Array<{
        id: string;
        isUnpaidBill: Trinary;
      }>;
    };
  };
}

export const getInitialValues = (member): FormValues => {
  return {
    id: member?.id,
    fullName: member?.legalName,
    isUnpaidBill: member?.isUnpaidBill,
  };
};

// updated to handle new indicators
export const formatPayload = ({ applicationID, values }): PayloadValues => {
  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: values.members.map((m) => ({
          id: m?.id,
          isUnpaidBill: boolToTri(m?.isUnpaidBill),
        })),
      },
    },
  };
};
