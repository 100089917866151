import React from 'react';
import { SheetComponentProps } from '@navigate';
import { HelpSheetBlueprint } from '@blueprints';

/** Extend  interface SheetComponentProps['HELP_TEXT'] */
type HelpTextSheetProps = SheetComponentProps['HELP_TEXT'] & {
  closeSheet?: () => void;
};

const HelpTextSheet: React.FC<HelpTextSheetProps> = ({ title, subtitle, closeSheet }) => {
  return <HelpSheetBlueprint title={title} subtitle={subtitle} onClose={closeSheet} />;
};

export default HelpTextSheet;
