import React, { useState } from 'react';
import { stacks, Stack, StackComponent, StackDefinition, navigate } from '@navigate';
import { TransferAmountView, TransferConfirmView } from './routes';
import { BankTransferDirection, GoalFragment, LinkedAccountFragment } from '@app/data';
import { useLocation } from 'react-router';
import { Route } from '@app/types';

const config: StackDefinition = {
  stackName: stacks.TRANSFER_STACK,
  options: { layout: 'sheet' },
  screens: [TransferAmountView, TransferConfirmView],
};

export type TransferAccount = GoalFragment | LinkedAccountFragment;

const TransferStack: StackComponent = () => {
  const {
    state: { goal, direction },
  } = useLocation();

  const [source, setSource] = useState<TransferAccount>(
    direction === BankTransferDirection.DepositIntoCatch ? goal : undefined,
  );

  const [destination, setDestination] = useState<TransferAccount>(
    direction === BankTransferDirection.WithdrawFromCatch ? goal : undefined,
  );

  const [amount, setAmount] = useState<number>();

  const handleNext = (values: {
    source: TransferAccount;
    destination: TransferAccount;
    amount: number;
  }) => {
    // stashes values
    setSource(values.source);
    setDestination(values.destination);
    setAmount(values.amount);

    // navigates to next page
    navigate(Route.TRANSFER_CONFIRM);
  };

  const sharedData = {
    direction,
    source,
    destination,
    amount,
    handleNext,
  };

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={sharedData}
    />
  );
};

TransferStack.config = config;
export default TransferStack;
