import { boolToTri } from '@app/utils';
import { introOptions, SEPType } from './sepFields';

export const getIntroInitialValues = (members = []) => {
  const sep = [];

  introOptions.forEach(({ value }) => {
    if (members.some((m) => m[value])) {
      sep.push(value);
    }
  });

  return { sep };
};

export const getInitialValues = (member) => {
  return {
    ...member,

    // married
    hasCoverage60DaysBeforeMarriage: boolToTri(member?.hasCoverage60DaysBeforeMarriage),
    hasLivedOutsideUSLast60DaysBeforeMarriage: boolToTri(
      member?.hasLivedOutsideUSLast60DaysBeforeMarriage,
    ),

    // relocated
    hasCoverageLast60DaysBeforeRelocation: boolToTri(member?.hasCoverageLast60DaysBeforeRelocation),
    isMovedFromOutsideUs: boolToTri(member?.isMovedFromOutsideUs),
    previousZipcode: {
      zip: member.previousZipcode || '',
      county: member?.previousCountyLivedName || '',
    },
  };
};

export const formatPayload = ({ applicationID, members, question }) => {
  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: members.map((values) => {
          const data = {
            [SEPType.isLostCoverageLast60Days]: {
              isLostCoverageLast60Days: boolToTri(values?.isLostCoverageLast60Days),
              coverageLostDate: values?.coverageLostDate,
              planName: values?.planName,
            },
            [SEPType.isWillLoseCoverageNext60Days]: {
              isWillLoseCoverageNext60Days: boolToTri(values?.isWillLoseCoverageNext60Days),
              coverageLostDateFuture: values?.coverageLostDateFuture,
            },
            [SEPType.isMarriedLast60Days]: {
              isMarriedLast60Days: boolToTri(values?.isMarriedLast60Days),
              whenMarried: values?.whenMarried,
              hasCoverage60DaysBeforeMarriage: values?.hasCoverage60DaysBeforeMarriage,
              hasLivedOutsideUSLast60DaysBeforeMarriage:
                values?.hasLivedOutsideUSLast60DaysBeforeMarriage,
            },
            [SEPType.isGainedLawfulPresence]: {
              isGainedLawfulPresence: boolToTri(values?.isGainedLawfulPresence),
              whenLawfulPresence: values?.whenLawfulPresence,
            },
            [SEPType.isMoved]: {
              isMoved: boolToTri(values?.isMoved),
              whenRelocated: values?.whenRelocated,
              hasCoverageLast60DaysBeforeRelocation: values?.hasCoverageLast60DaysBeforeRelocation,
              isMovedFromOutsideUs: values?.isMovedFromOutsideUs,
              previousZipcode: values?.previousZipcode?.zip,
              previousCountyLivedName: values?.previousZipcode?.county,
            },
            [SEPType.isReleasedFromIncarceration]: {
              isReleasedFromIncarceration: boolToTri(values?.isReleasedFromIncarceration),
              whenReleasedFromIncarceration: values?.whenReleasedFromIncarceration,
            },
            [SEPType.isGainDependent]: {
              isGainDependent: boolToTri(values?.isGainDependent),
              whenBecomeADependent: values?.whenBecomeADependent,
            },
          };

          const answers = data[question] || {};
          return { id: values?.id, ...answers };
        }),
      },
    },
  };
};
