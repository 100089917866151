export const fields = [
  {
    testID: '153',
    name: 'members',
    type: 'array',
    Component: ({ children }) => children,
    Footer: () => null,
    copy: 'catch.ede.HealthEnrollment',
    help: { id: '153_T' },
    subfields: [
      {
        name: 'incomeThisMonth',
        bare: true,
        type: 'person',
        dependencies: ['fullName'],
        label: ({ fullName }) => fullName,
        secondary: {
          unchecked: 'No income',
          checked: 'Earning income',
        },
      },
    ],
  },
];
