import React from 'react';

import { s } from '@app/_ui-kit/theme/static';
import Stack from './Stack';
import { Text } from '@app/_ui-kit';
import { FontSize, FontWeight, TextAlign } from '@app/types';

interface Props {
  children?: any;
  alt?: boolean;
  textLeft?: any;
  textRight?: any;
  loading?: boolean;
  align?: 'end' | 'start';
}

const ButtonGroup = ({ alt, children, textLeft, textRight, loading, align = 'start' }: Props) => {
  const buttons = React.Children.toArray(children);
  const buttonProps = !!alt ? { alt: true } : {};

  return (
    <Stack
      spacing="1"
      horizontal
      wide={buttons.length === 1}
      full={buttons.length > 1}
      itemStyle={[s.flex1, s?.centered]}
      align={align === 'end' ? 'flex-end' : 'flex-start'}
    >
      {buttons.map((button: any, i) => (
        <Stack key={i} spacing="0b">
          {(!!textLeft || !!textRight) && (
            <Text
              key={`btn-${button.key || i}`}
              align={TextAlign.center}
              size={FontSize.h2}
              weight={FontWeight.medium}
              loading={loading}
            >
              {i === 0 ? textLeft : textRight}
            </Text>
          )}
          {React.cloneElement(button, { key: button.key, full: true, ...buttonProps })}
        </Stack>
      ))}
    </Stack>
  );
};

export default ButtonGroup;
