interface LoaderProps {
  accentColor?: string; // todo
  title?: string;
  subtitle?: string;
}

type LoaderName = 'HEALTH_LONG_WAIT' | 'HEALTH_UPDATING_PLANS';

export const loaders: Record<LoaderName, LoaderProps> = {
  HEALTH_LONG_WAIT: {
    accentColor: 'coverage',
    title: 'Updating your application',
    subtitle: 'This may take up to a minute',
  },
  HEALTH_UPDATING_PLANS: {
    accentColor: 'coverage',
    title: 'Updating your plans...',
  },
};
