import gql from 'graphql-tag';

export interface RemoveApplicationMemberVars {
  input: {
    id: string;
    removeDate: Date;
    removeReason: 'DEATH' | 'DIVORCE' | 'OTHER';
  };
}

export interface RemoveApplicationMemberResponse {
  removeApplicationMember: boolean;
}

export const RemoveApplicationMember = gql`
  mutation RemoveApplicationMember($input: RemoveApplicationMemberInput!) {
    removeApplicationMember(input: $input)
  }
`;
