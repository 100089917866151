// on web, we should probably keep track of the initial referrer
// in case we want to return to the original page on close
import { history } from '../history';

/**
 * Removes any query params and returns to normal
 */
export const close = () => {
  // @ts-ignore
  history.push(history.location.pathname, history.location.state);
};
