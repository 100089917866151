import React, { useMemo } from 'react';
import { useCopy, createLogger, boolToTri } from '@app/utils';
import { useMutation, useQuery, HealthApplicationQuery, getMembers } from '@data';
import { navigate } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { Route } from '@types';
import { UpsertApplicationMembers } from '@app/data/mutations/UpsertApplicationMembers';

const Log = createLogger('CiC:MemberStatus');

const CICMemberStatusChange = ({ applicationID, screenStep, change }) => {
  const isLaterPhase = screenStep === 'memberStatusChange';

  const fieldName =
    screenStep === 'memberFutureCoverageLoss'
      ? 'isWillLoseCoverageNext60Days'
      : 'isLostCoverageLast60Days';

  const { c } = useCopy(`catch.ede.CIC.${screenStep}`);

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(
    () => ({
      members: getMembers(data)?.map((m) => ({
        id: m?.id,
        legalName: m?.legalName,
      })),
    }),
    [data],
  );

  const [upsert, { loading: reporting }] = useMutation(UpsertApplicationMembers, {
    onCompleted: () => {
      navigate(Route.CIC_APP_REVIEW_INTRO, {
        change: screenStep,
        applicationID,
      });
    },
  });

  const onSubmit = async ({ members }) => {
    try {
      const applicationMembersInput = members.map((m) => ({
        ...m,
        [fieldName]: boolToTri(m[fieldName]),
      }));
      upsert({ variables: { input: { applicationMembersInput } } });
    } catch (e) {
      Log.debug(e);
    }
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={reporting}
      title={c(`title`, { change })}
      formConfig={{
        initialValues,
        fields: [
          {
            name: 'members',
            type: 'array',
            Component: ({ children }) => children,
            Footer: () => null,
            subfields: [
              {
                name: fieldName,
                type: 'person',
                bare: !isLaterPhase,
                dependencies: ['legalName'],
                label: ({ legalName }) => legalName,
                secondary: {
                  unchecked: '',
                  checked: '',
                },
              },

              {
                name: 'whenLostCoverage',
                type: 'date',
                format: 'YYYY-MM-DD',
                dependencies: ['legalName', fieldName],
                display: (values) => isLaterPhase && values[fieldName],
                label: c('dateLabel'),
                required: true,
              },
            ],
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const CICMemberStatusChangeView = {
  name: Route.CIC_MEMBER_STATUS_CHANGE,
  component: CICMemberStatusChange,
  options: {},
};
