import gql from 'graphql-tag';

export interface UpsertExplorerVars {
  input: {
    pathwayType?: string; // @todo
    zipcode?: string;
    countyfips?: string;
    state?: string;
    coverageYear?: number;
    providerPlanID?: string;
    income?: number;
  };
}

export interface UpsertExplorerResponse {
  upsertHealthExplorerData: {
    id: string;
    pathwayType?: string; // @todo
    zipcode?: string;
    countyfips?: string;
    state?: string;
    coverageYear?: number;
    providerPlanID?: string;
    income?: number;
  };
}

export const UpsertExplorer = gql`
  mutation UpsertExplorerOld($input: HealthExplorerDataInput!) {
    upsertHealthExplorerData(input: $input) {
      id
      pathwayType
      zipcode
      countyfips
      state
      coverageYear
      providerPlanID
      income
      healthPlans {
        plans {
          id
        }
      }
    }
  }
`;
