import styled from 'styled-components';
import { SegmentedControl as RadixSegment } from '@radix-ui/themes';
import { FontSize, FontWeight } from '@app/types';
import { Text } from './Text';

const MARGIN = 3;
const HEIGHT = 40;

export interface SegmentedControlProps {
  index: number;
  setIndex: (i: number) => void;
  controls: Array<{ title: string }>;
}

const StyledRoot = styled(RadixSegment.Root)<{ $num: number }>`
  height: ${HEIGHT + MARGIN * 2}px;
  background-color: ${(p) => p.theme.colors.bg3};
  background-image: none;

  .rt-SegmentedControlIndicator::before {
    inset: ${MARGIN}px;
    box-shadow: 0 0 0 1.5px ${({ theme }) => theme.colors.border};
  }

  .rt-SegmentedControlItemSeparator {
    display: none;
  }
`;

const StyledItem = styled(RadixSegment.Item)`
  border-radius: 50px;
  padding: ${MARGIN}px;

  outline-color: ${({ theme }) => theme.colors.system};

  &:hover {
    cursor: pointer;
  }
`;

export function SegmentedControl({ index, setIndex, controls }: SegmentedControlProps) {
  const handleChange = (val: string) => {
    setIndex(parseInt(val));
  };

  return (
    <StyledRoot
      $num={controls.length}
      value={`${index}`}
      onValueChange={handleChange}
      radius="full"
    >
      {controls.map((control, index) => (
        <StyledItem key={control.title} value={`${index}`}>
          <Text size={FontSize.fp} weight={FontWeight.medium}>
            {control.title}
          </Text>
        </StyledItem>
      ))}
    </StyledRoot>
  );
}
