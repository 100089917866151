import React from 'react';

import { ComplexRow } from '@uikit';
import { Stack } from '@layouts';
import { getFullName, personInitials } from '@app/utils';

// interface PeopleListProps {
//   members: Array<{
//     id: string,
//     givenName?: string,
//     familyName?: string,
//   }>;
//   sublabel: (member) => string;
// }

const PeopleList = ({ members, sublabel }) => {
  if (!members) return null;

  return (
    <Stack separatorComponent>
      {members.map((member) => (
        <ComplexRow
          key={member.id}
          label={getFullName(member)}
          sublabel={sublabel(member)}
          asset={{
            text: personInitials(member),
            gradient: 'coverageLight',
            shape: 'circle',
            size: 'md',
          }}
        />
      ))}
    </Stack>
  );
};

export default PeopleList;
