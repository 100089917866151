import { ootIntl } from '../language';
import { uppercase, lowercase, capitalize } from './strings';

/**
 * Given a value, gets the initials
 * - Returns first letter of given and first letter of last
 */
export const personInitials = (value = {}) => {
  // stringify both values
  const givenName = value?.givenName || '';
  const familyName = value?.familyName || '';

  const initials = givenName?.charAt(0) + familyName?.charAt(0);

  return uppercase(initials);
};

/**
 * Given a person, gets their full name
 * Returns concatenated first + last
 * Previous filing name may be different from current name
 */
export const personFullName = (value = {}, prevFilingName) => {
  // stringify both values
  const givenName = value?.givenName || '';
  const familyName = prevFilingName ? prevFilingName : value?.familyName || '';

  // to prevent weird extra space if something is not set
  if (!givenName || !familyName) return `${givenName}${familyName}`;

  return `${givenName} ${familyName}`;
};

/**
 * Given a person, gets their full name
 * Falls back to relation if name doesn't exist
 */
export const personFullNameWithFallback = ({
  person = {},
  pronoun = 'your',
  capitalizePronoun = true,
} = {}) => {
  const fullName = personFullName(person);
  if (fullName && fullName.length) {
    return fullName;
  }

  const relation = person?.relation || '';
  return `${capitalizePronoun ? capitalize(pronoun) : pronoun} ${lowercase(relation)}`;
};

/**
 * formatPeople
 * Given a list of people and option to enuemrate, returns copy for their names
 *
 * Single person:
 * Betty Draper
 *
 * Two people
 * Betty Draper and Sally Draper
 *
 * 3+ people
 * enumerated: Betty Draper, Sally Draper, and Eugene Draper
 * not enumerated: Betty Draper and 2 others
 */

export const formatPeople = (props = { people: [], enumerated: false }) => {
  const people = props?.people || [];
  const enumerated = props?.enumerated || false;

  const num = people?.length;

  // if no people, return empty string
  if (num <= 0) return '';

  // just return the users full name if only one person
  if (num === 1) return personFullName(people[0]);

  // return "person and person" when there are two people
  const and = ootIntl.formatMessage({
    id: 'catch.basics.and',
    defaultMessage: 'and',
  });

  if (num === 2) {
    return `${personFullName(people[0])} ${and} ${personFullName(people[1])}`;
  }

  const others = ootIntl.formatMessage(
    {
      id: 'catch.basics.numOthers',
      defaultMessage: '{num} others',
    },
    { num: people?.length - 1 },
  );

  // otherwise, if not enumerated, just return + 2 others, etc.
  if (!enumerated) {
    return `${personFullName(people?.[0])} ${and} ${others}`;
  }

  return people.reduce((str, person, i) => {
    return i < people?.length - 1
      ? `${str}${personFullName(person)}, `
      : `${str}${and} ${personFullName(person)}`;
  }, '');
};
