import React from 'react';

import { Asset } from '@uikit';
import { useModal } from '@hooks';

import Camera from '@svg/photo-plus.svg';
import { useSheet } from '@app/navigate';

const Avatar = ({ loading, id, img, type = 'user', size = 'sm', onPress }) => {
  const { isOpen } = useModal();
  const { open } = useSheet();

  return (
    <Asset
      loading={loading}
      base64={img}
      svg={!img && Camera}
      size={!!img ? size : 'sm'}
      containerSize={size}
      bg="skeleton"
      bgHover="border"
      color="subtle"
      shape="circle"
      onPress={
        onPress ? onPress : () => open('UPLOAD_AVATAR', { id, isOpen, type, hasImage: !!img })
      }
    />
  );
};

export default Avatar;
