import { GOAL_TYPE_TO_COLOR, READY_GOAL_STATUSES } from '@app/constants/goals';
import { GoalFragment, GoalStatus } from '@app/data';
import { navigate, open } from '@app/navigate';
import { FontSize, FontWeight, Route } from '@app/types';
import { useCopy } from '@app/utils';
import { ComplexRow, Text } from '@uikit';

export function GoalRow({ goal }: { goal: GoalFragment }) {
  const { $, c } = useCopy('catch');

  const handlePress = () => {
    if (goal.status && READY_GOAL_STATUSES.includes(goal.status)) {
      navigate(Route.GOAL_DETAIL, { slug: goal.slug });
    } else {
      open(Route.GOAL_INTRO, { slug: goal.slug });
    }
  };

  return (
    <ComplexRow
      testID={goal.id}
      interaction="navigation"
      onPress={handlePress}
      asset={{
        render: goal.type,
        bg: `${GOAL_TYPE_TO_COLOR[goal?.type]}Light`,
        size: 'sm',
        containerSize: 'lg',
        shape: 'circle',
      }}
      label={<Text weight={FontWeight.semibold}>{goal.name}</Text>}
      sublabel={
        <Text>
          <Text weight={FontWeight.medium}>
            {goal.status === GoalStatus.Draft ? 'Setting up' : $(goal?.totalBalance || 0)}
          </Text>
          <Text color="subtle" weight={FontWeight.light}>
            {' '}
            {c(`goal.enums.${goal.status}`)}
          </Text>
        </Text>
      }
      accessory={
        <Text weight={FontWeight.regular} size={FontSize.fp}>
          {goal.withholdingPercentage}%
        </Text>
      }
    />
  );
}
